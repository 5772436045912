/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { NavigationItem } from '@viterbit/web-app/routes'
import groupBy from 'lodash/groupBy'
import * as React from 'react'

import { useRouteFilter } from './useRouteEnabled'

type NavigationGrouped = {
    key: string
    label: string
    bold?: boolean
    items: NavigationItem[]
    icon?: React.ReactNode
}

export const routeGroup = (item: NavigationItem, disabled: boolean, filter: (NavigationItem) => boolean): NavigationGrouped[] => {
    if (disabled) {
        return []
    }

    const { dropDownGroups } = item.handle

    if (!dropDownGroups) {
        return [{
            key: item.handle.key || 'default',
            label: item.handle.title!,
            bold: true,
            items: item.children?.filter(filter) || [{
                ...item,
            }],
            icon: item.handle.icon,
        }]
    }

    const groups = groupBy(item.children?.filter(filter), 'handle.dropDownGroup')
    const childrenGrouped = Object.keys(dropDownGroups).reduce<Record<string, NavigationGrouped>>((acc, key) => {
        acc[key].items = groups[key] || []
        return acc
    }, dropDownGroups as any)
    const childrenGroupedArray = Object.values(childrenGrouped)
    const filteredGrouped = childrenGroupedArray.filter((group) => group.items.length > 0)

    return filteredGrouped.map((group, index) => ({
        ...group,
        key: group.key || String(index),
    }))
}

export const useRouteGroup = (item: Parameters<typeof routeGroup>[0], disabled: Parameters<typeof routeGroup>[1]) => {
    const routeFilter = useRouteFilter()
    const routes = React.useMemo(() => routeGroup(item, disabled, routeFilter), [item, disabled, routeFilter])

    return routes
}

