import { WEEK_DAYS } from '@viterbit/web-app/dataAccess/model/Week.model'
import { useCurrentEmployee } from '@viterbit/web-app/dataAccess/useCase/employee/useCurrentEmployee'
import * as React from 'react'

const initialTrackingData = {
    workingDays: []
}

const getWorkingDays = (employee) => {
    const daysRange = employee?.leavePolicy?.daysRange || []

    const workingDays = WEEK_DAYS.reduce<number[]>((output, current, index) => {
        if (daysRange.includes(String(current))) {
            output.push(index)
        }

        return output
    }, [])

    return {
        workingDays
    }
}

const useTimeTrackingData = () => {
    const employee = useCurrentEmployee()

    return React.useMemo(() => {
        if (!employee) {
            return initialTrackingData
        }

        return getWorkingDays(employee)
    }, [employee])
}

export default useTimeTrackingData