import { useQuery } from '@tanstack/react-query'

import { PENDING_FILES_QUERY_KEY } from './pendingFilesQueryKeys'
import { pendingFileBulkInfo } from '../../service/pendingFilesService'

type EmployeeDetailConfig = {
    query: Parameters<typeof pendingFileBulkInfo>[0]
    enabled?: boolean
}

export const usePendingFileBulkInfo = ({ enabled, query }: EmployeeDetailConfig) =>
    useQuery({
        queryFn: () => pendingFileBulkInfo(query), 
        queryKey: [PENDING_FILES_QUERY_KEY, 'bulk-info', query],
        enabled
    })

