/* eslint-disable react/no-array-index-key */
import * as React from 'react'

import CalendarItemSkeleton from './CalendarItemSkeleton'
import clsxm from '../../lib'

type CalendarSkeletonProps = {
    className?: string
}

const styles = {
    root: 'flex flex-wrap w-full gap-y-6 gap-x-6 xl:gap-x-10',
}

const CalendarSkeleton = (props: CalendarSkeletonProps) => {
    const { className } = props

    return (
        <div className={clsxm(styles.root ,className)}>
            {Array.from({ length: 12 }).map((_, index) =>
                <CalendarItemSkeleton key={index} className="flex-1" header />
            )}
        </div>

    )
}

export default CalendarSkeleton
