import * as React from 'react'
import Alert from 'ui/src/components/Alert'
import clsxm from 'ui/src/lib'

type GlobalErrorsMessageProps = {
    children?: React.ReactNode
    className?: string
    error: Record<string, string>
}

const GlobalErrorsMessage = ({ className, error }: GlobalErrorsMessageProps) => {
    if (!error) {
        return null
    }
    
    return (
        <Alert type='danger' title="Error on form submission" className={clsxm('mb-4', className)} showIcon>
            {Object.values(error).map(errorMessage => <div key={errorMessage}>{errorMessage}</div>)}
        </Alert>
    )
}

export default GlobalErrorsMessage
