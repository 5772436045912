/* eslint-disable @typescript-eslint/no-non-null-assertion */
import EmbeddedContent from '@viterbit/web-app/shared/behaviour/EmbeddedContent'
import SettingsLayoutBody from '@viterbit/web-app/shared/layout/SettingsLayoutBody'
import * as React from 'react'
import ButtonSkeleton from 'ui/src/components/Button/ButtonSkeleton'
import FormControlSkeleton from 'ui/src/components/Form/FormControlSkeleton'
import FormSkeleton from 'ui/src/components/Form/FormSkeleton'
import Skeleton from 'ui/src/components/Skeleton'

const SettingsOrganizationLoading = () => (
    <>
        <EmbeddedContent>
            <ButtonSkeleton />
        </EmbeddedContent>

        <SettingsLayoutBody
            left={
                <div className='m-6'>
                    <Skeleton className='rounded-full w-[160px] h-[160px] m-auto' />
                    <FormControlSkeleton label />
                    <FormControlSkeleton label />
                </div>
            }
        >
            <FormSkeleton />
        </SettingsLayoutBody>
    </>
)


export default SettingsOrganizationLoading
