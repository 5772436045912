
import { Role } from '@viterbit/web-app/dataAccess/model/Role.model'
import { roleListPaginated } from '@viterbit/web-app/dataAccess/service/roleService'
import i18n from '@viterbit/web-app/i18n'
import { GroupBase } from 'react-select'
import {
    FormSelectAsyncPaginate,
    FormSelectAsyncPaginateProps,
} from 'ui/src/components/Form'
import { FormBaseProps } from 'ui/src/components/Form/shared'

import { useNotification } from '../feedback/useNotification'

const availableTypes = [
    'ROLE_ADMIN',
    'ROLE_HEAD',
    'ROLE_EMPLOYEE',
    'ROLE_MANAGER',
]

type FormSelectRoleProps<IsMulti extends boolean> = FormBaseProps &
    Omit<
        FormSelectAsyncPaginateProps<
            Role,
            IsMulti,
            GroupBase<Role>,
            { page: number }
        >,
        'loadOptions'
    >

type LoadOptions = FormSelectAsyncPaginateProps<
    Role,
    boolean,
    GroupBase<Role>,
    { page: number }
>['loadOptions']

const FormSelectRole = <IsMulti extends boolean>({
    name,
    control,
    cacheOptions = true,
    isSearchable = false,
    ...rest
}: FormSelectRoleProps<IsMulti>) => {
    const notification = useNotification()
    const fetchRoles = roleListPaginated
    const loadOptions: LoadOptions = async (search, loadedOptions, _meta) =>
        fetchRoles({ page: _meta?.page })
            .then(({ data, meta }) => ({
                options: data,
                hasMore: meta.hasMore,
                additional: {
                    page: meta.page + 1,
                },
            }))
            .catch((err) => {
                notification.push(
                    'danger',
                    i18n.t('components.formSelectRole.errors.fetching_error'),
                    err.errorCode
                        ? i18n.t(`components.formSelectRole.errors.api.${err.errorCode}`)
                        : i18n.t('components.formSelectRole.errors.error_at_fetch')
                )
                return {
                    options: [],
                    hasMore: false,
                }
            })
    return (

        <FormSelectAsyncPaginate
            name={name}
            control={control}
            cacheOptions={cacheOptions}
            loadOptions={loadOptions}
            isSearchable={isSearchable}
            getOptionLabel={(x) => 
                availableTypes.includes(x.name)
                    ? i18n.t(`components.formSelectRole.type.${x.name}`)
                    : x.name
            }
            getOptionValue={(x) => x.id}
            loadOptionsOnMenuOpen
            additional={{
                page: 1,
            }}
            {...rest}
        />

    )
}

export default FormSelectRole
