import {
    IconChevronsLeft,
    IconChevronsRight,
    IconDots,
} from '@tabler/icons-react'
import * as React from 'react'

import { clsxm } from '../../lib/clsxm'

const PAGER_COUNT = 7

type MoreButtonProps = {
    className?: string
    onArrow: (arg: string) => void
    type: 'next' | 'prev'
}

const MoreButton = ({ className, onArrow, type }: MoreButtonProps) => {
    const [quickNextArrowIcon, setQuickNextArrowIcon] = React.useState(false)
    const dataTestId = `pagination-pagers-${type}-more`
    const icon = {
        next: <IconChevronsRight />,
        prev: <IconChevronsLeft />,
    }
    return (
        <li
            data-testid={dataTestId}
            className={className}
            onMouseEnter={() => {
                setQuickNextArrowIcon(true)
            }}
            onMouseLeave={() => {
                setQuickNextArrowIcon(false)
            }}
            onClick={() => onArrow(`${type}More`)}
        >
            {quickNextArrowIcon ? icon[type] : <IconDots />}
        </li>
    )
}

const NextMore: React.FC<Omit<MoreButtonProps, 'type'>> = (props) => (
    <MoreButton {...props} type='next' />
)

const PrevMore: React.FC<Omit<MoreButtonProps, 'type'>> = (props) => (
    <MoreButton {...props} type='prev' />
)

type PagerProps = {
    pagerClass: Record<'default' | 'inactive' | 'active' | 'disabled', string>
    currentPage: number
    pageCount: number
    onChange?: (page: number) => void
}

const Pagers: React.FC<PagerProps> = (props) => {
    const { pageCount, currentPage, onChange, pagerClass } = props

    const [showPrevMore, setShowPrevMore] = React.useState(false)
    const [showNextMore, setShowNextMore] = React.useState(false)

    React.useEffect(() => {
        if (pageCount > PAGER_COUNT) {
            if (currentPage > PAGER_COUNT - 2) {
                setShowPrevMore(true)
            }
            if (currentPage < pageCount - 2) {
                setShowNextMore(true)
            }
            if (currentPage >= pageCount - 3 && currentPage <= pageCount) {
                setShowNextMore(false)
            }
            if (currentPage >= 1 && currentPage <= 4) {
                setShowPrevMore(false)
            }
        } else {
            setShowPrevMore(false)
            setShowNextMore(false)
        }
    }, [currentPage, pageCount])

    const onPagerClick = (
        value: number,
        e: React.MouseEvent<HTMLLIElement>
    ) => {
        e.preventDefault()
        let newPage = value

        if (newPage < 1) {
            newPage = 1
        }
        if (newPage > pageCount) {
            newPage = pageCount
        }

        if (newPage !== currentPage) {
            onChange?.(newPage)
        }
    }

    const onArrowClick = React.useCallback(
        (e: string) => {
            let newPage = currentPage
            if (e === 'nextMore') {
                newPage = currentPage + 5
            }
            if (e === 'prevMore') {
                newPage = currentPage - 5
            }
            onChange?.(newPage)
        },
        [currentPage, onChange]
    )

    const getPages = React.useMemo(() => {
        const pagerArray = new Array<number>()
        if (showPrevMore && !showNextMore) {
            const startPage = pageCount - (PAGER_COUNT - 2)
            for (let i = startPage; i < pageCount; i++) {
                pagerArray.push(i)
            }
        } else if (!showPrevMore && showNextMore) {
            for (let i = 2; i < PAGER_COUNT; i++) {
                pagerArray.push(i)
            }
        } else if (showPrevMore && showNextMore) {
            const offset = Math.floor(PAGER_COUNT / 2) - 1
            const maxRange =
                currentPage >= pageCount - 2 && currentPage <= pageCount
            for (
                let i = currentPage - offset;
                i <= currentPage + (maxRange ? 0 : offset);
                i++
            ) {
                pagerArray.push(i)
            }
        } else {
            for (let i = 2; i < pageCount; i++) {
                pagerArray.push(i)
            }
        }
        if (pagerArray.length > PAGER_COUNT - 2) {
            return []
        }

        return pagerArray
    }, [showPrevMore, showNextMore, currentPage, pageCount])

    const getPagerClass = (index: number) =>
        clsxm(
            pagerClass.default,
            currentPage === index ? pagerClass.active : pagerClass.inactive
        )

    return (
        <ul data-testid='pagination-pagers' className='flex items-center'>
            {pageCount > 0 && (
                <li
                    className={getPagerClass(1)}
                    onClick={(e) => onPagerClick(1, e)}
                    tabIndex={0}
                    data-testid='pagination-page'
                >
                    1
                </li>
            )}
            {showPrevMore && (
                <PrevMore
                    onArrow={(arrow) => onArrowClick(arrow)}
                    className={clsxm(
                        pagerClass.default,
                        pagerClass.inactive,
                        'hover:border-t-0'
                    )}
                />
            )}
            {getPages.map((pager) => (
                <li
                    key={pager}
                    className={getPagerClass(pager)}
                    onClick={(e) => onPagerClick(pager, e)}
                    tabIndex={0}
                    data-testid='pagination-page'
                >
                    {pager}
                </li>
            ))}
            {showNextMore && (
                <NextMore
                    onArrow={(arrow) => onArrowClick(arrow)}
                    className={clsxm(
                        pagerClass.default,
                        pagerClass.inactive,
                        'hover:border-t-0'
                    )}
                />
            )}
            {pageCount > 1 && (
                <li
                    className={getPagerClass(pageCount)}
                    onClick={(e) => onPagerClick(pageCount, e)}
                    tabIndex={0}
                    data-testid='pagination-page'
                >
                    {pageCount}
                </li>
            )}
        </ul>
    )
}

export default Pagers
