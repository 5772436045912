import { useMutation } from '@tanstack/react-query'

import { teamUnassignEmployee } from '../../service/teamService'

type TeamUnassignEmployee = {
    onSuccess?: () => void
    onError?: () => void
}

export const useTeamUnassignEmployee = (config: TeamUnassignEmployee) =>
    useMutation({
        ...config,
        mutationFn: teamUnassignEmployee,
        onSuccess: config.onSuccess,
        onError: config.onError,
    })
