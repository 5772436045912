import {
    IconArrowDown,
    IconArrowLeft,
    IconArrowRight,
    IconArrowUp,
} from '@tabler/icons-react'
import * as React from 'react'

import { DropdownPlacementType } from './sharedTypes'
import { clsxm } from '../../lib/clsxm'

const classes = {
    default: 'cursor-pointer p-2 inline-block',
    disabled: 'opacity-60',
}

type DropdownToggleDefaultContentProps = {
    placement?: DropdownPlacementType
    children?: React.ReactNode
}

const DropdownToggleDefaultContent = ({
    placement,
    children,
}: DropdownToggleDefaultContentProps) => {
    if (placement && placement.includes('middle-start')) {
        return (
            <>
                {children}
                <IconArrowRight />
            </>
        )
    }

    if (placement && placement.includes('middle-end')) {
        return (
            <>
                <IconArrowLeft />
                {children}
            </>
        )
    }

    if (placement && placement.includes('top')) {
        return (
            <>
                {children}
                <IconArrowUp />
            </>
        )
    }

    return (
        <>
            {children}
            <IconArrowDown />
        </>
    )
}

type DropdownToggleProps = DropdownToggleDefaultContentProps & {
    id?: string
    className?: string
    renderTitle?: React.ReactNode
    // inSidenav?: boolean
    disabled?: boolean
    toggleClassName?: string
}

const DropdownToggle = React.forwardRef<HTMLDivElement, DropdownToggleProps>(
    (props, ref) => {
        const {
            className,
            renderTitle,
            children,
            placement,
            // inSidenav,
            disabled,
            toggleClassName,
            ...rest
        } = props

        const dropdownToggleClass = clsxm(
            className,
            toggleClassName,
            disabled && classes.disabled
        )

        const dropdownToggleDefaultClass = clsxm(
            dropdownToggleClass,
            classes.default
        )

        if (renderTitle) {
            return (
                <div className={dropdownToggleClass} {...rest} ref={ref}>
                    {renderTitle}
                </div>
            )
        }

        return (
            <div ref={ref} className={dropdownToggleDefaultClass} {...rest}>
                <span className='flex items-center'>
                    <DropdownToggleDefaultContent placement={placement}>
                        {children}
                    </DropdownToggleDefaultContent>
                </span>
            </div>
        )
    }
)

export default DropdownToggle
