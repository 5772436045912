
import { clearPermissions } from '@viterbit/web-app/shared/state/permissionState'
import { clearUser, setUser } from '@viterbit/web-app/shared/state/userState'
import { AuthApi,Configuration } from 'viterbit-api-sdk'

import { commonConfig, noMiddlewareConfig } from './config'

const api = new AuthApi(new Configuration(commonConfig))
const apiNoMiddleware = new AuthApi(new Configuration(noMiddlewareConfig))

export const accountLogin = (body: Parameters<typeof api.accountLogin>[0]) => 
    apiNoMiddleware.accountLogin(body).then(async (data) => {
        await setUser(data)
        return data
    })

export const createLead = (body: Parameters<typeof api.createLead>[0]) =>
    api.createLead(body).then((data) => data)

export const redeemForgotPassword = (body: Parameters<typeof api.redeemForgotPassword>[0]) =>
    api.redeemForgotPassword(body).then((data) => data)

export const forgotPassword = (body: Parameters<typeof api.forgotPassword>[0]) =>
    api.forgotPassword(body).then((data) => data)

export const accountSignUp = (body: Parameters<typeof api.accountSignUp>[0]) =>
    api.accountSignUp(body).then((data) => data)

export const refreshToken = async () => api.refreshToken().then(async (data) => {
    await setUser(data)
    return data
})

export const initialRefreshToken = async () => apiNoMiddleware.refreshToken().then(async (data) => {
    await setUser(data)
    return data
})

export const authPermissions = (body: Parameters<typeof api.fetchPermissions>[0]) =>
    api.fetchPermissions(body)

export const signOut = () => {
    api.invalidateToken().then(() => {
        clearUser()
        clearPermissions()
        window.location.href = '/auth/signin'
    })
}
