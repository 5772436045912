import { IconArrowNarrowRight } from '@tabler/icons-react'
import React from 'react'

import Button from './Button'

const classes = {
    paginationPagerNext: 'mr-1 rtl:rotate-180',
}

type Props = {
    pagerClass: Record<'default' | 'inactive' | 'active' | 'disabled', string>
    currentPage: number
    pageCount?: number
    onNext?: React.MouseEventHandler<HTMLSpanElement>
    customNextIcon?: React.ReactNode
    t?: (key: string) => string
}

const Next: React.FC<Props> = ({ onNext, customNextIcon, t, ...rest }) => (
    <Button
        type='next'
        buttonClass={classes.paginationPagerNext}
        defaultIcon={
            <div className='flex items-center gap-x-2'>
                { t?.('pagination.next') || 'Next'}
                <IconArrowNarrowRight data-testid='pagination-next-icon-default' />
            </div>
        }
        customIcon={customNextIcon}
        onClick={onNext}
        {...rest}
    />
)

export default Next
