import * as React from 'react'
import CalendarSkeleton from 'ui/src/components/Calendar/CalendarSkeleton'
import Skeleton from 'ui/src/components/Skeleton'

const CalendarLoading = () => (
    <div className='p-4 bg-white border rounded-md'>
        <Skeleton className='w-40 h-6 mb-4'/>
        <CalendarSkeleton />
    </div>
)

export default CalendarLoading
