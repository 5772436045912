/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Button, Modal } from '@mantine/core'
import { Subscription, SubscriptionStatus } from '@viterbit/web-app/dataAccess/model/Subscription.model'
import i18n from '@viterbit/web-app/i18n'
import HeaderActions from '@viterbit/web-app/shared/layout/HeaderActions'
import * as React from 'react'

import BillingSummary from './BillingSummary'
import LabelSeparator from './LabelSeparator'
import OfferChip from './OfferChip'
import PlanItemFeatures from './PlanItemFeatures'
import PriceList from './PriceList'

type SubscriptionItemProps = {
    children?: React.ReactNode
    className?: string
    subscription: Subscription
}

const styles = {
    root: 'overflow-hidden lg:flex lg:max-w-none',
    left: 'flex flex-col gap-5 py-0 pr-4 pl-0',
    description: 'text-base leading-7 text-black',
    right: 'flex flex-col gap-6 px-10 py-5 text-center bg-gray-50 min-w-[360px]',
    priceLabel: 'text-base font-semibold text-black',
    footer: 'text-xs leading-5',
}

const CHIP_STYLES: Record<SubscriptionStatus, string> = {
    FUTURE: 'bg-gray-200 text-black',
    IN_TRIAL: 'bg-yellow-200 text-yellow-600',
    ACTIVE:  'bg-green-200 text-green-600',
    NON_RENEWING: 'bg-gray-200 text-black',
    PAUSED: 'bg-gray-200 text-black',
    CANCELLED: 'bg-gray-200 text-black',
}

const SubscriptionItem = (props: SubscriptionItemProps) => {
    const { subscription } = props

    const [modalOpened, setModalOpened] = React.useState(false)

    const onUpgrade = () => {
        setModalOpened(true)
    }

    return (
        <>
            <div className={styles.root} data-testid="subscription-item">
                <div className={styles.left}>
                    <HeaderActions className='pb-0'>
                        <HeaderActions.Header>
                            <HeaderActions.Header.Title>
                                {i18n.t('billing:subscriptionName')}
                            </HeaderActions.Header.Title>
                        </HeaderActions.Header>
                        <HeaderActions.Actions>
                            <OfferChip
                                className={CHIP_STYLES[subscription.status]}
                                data-testid={`subscription-status-${subscription.status}`}
                            >
                                {i18n.t('billing:status.' + subscription.status)}
                            </OfferChip>
                        </HeaderActions.Actions>
                    </HeaderActions>
                    <p className={styles.description}>
                        {i18n.t('billing:premiumPlanDescription')}
                    </p>
                    <LabelSeparator />
                    <PlanItemFeatures />
                </div>

                <BillingSummary subscription={subscription}>
                    {subscription.shouldUpgrade && (
                        // <a href={subscription.paymentPageUrl} target="_blank" rel="noreferrer">
                        // </a>
                        <Button onClick={onUpgrade} data-testid="subscription-item-button">
                            {i18n.t('billing:upgrade')}
                        </Button>
                    )}
                </BillingSummary>
            </div>

            <Modal
                centered
                size="sm"
                opened={modalOpened}
                onClose={() => setModalOpened(false)}
                withCloseButton={false}
            >
                <Modal.Body>
                    <h1 className="mb-4 text-xl font-bold text-center">
                        {i18n.t('billing:pricing.title')}
                    </h1>
                    <PriceList
                        defaultValue={subscription.prices[0].periodUnit}
                        prices={subscription.prices}
                        onItemClick={(price) => {
                            window.location.href = price.paymentPageUrl!
                        }}
                    />
                    {/* <BillingSummary subscription={subscription} /> */}
                </Modal.Body>

            </Modal>
        </>

    )
}

export default SubscriptionItem
