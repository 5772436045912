
import FormSkeleton from 'ui/src/components/Form/FormSkeleton'

import DeleteLocationAction from './DeleteLocationAction'
import LocationForm from './LocationForm'
import { useLocation } from '../useLocation'

const LocationView = () => {
    const { data: location, isLoading } = useLocation()

    if (isLoading || !location) {
        return <FormSkeleton className='mt-[100px]'/>
    }

    return (
        <>
            <LocationForm key={location.id} location={location!}/>
            <DeleteLocationAction location={location} />
        </>
    )
}

export default LocationView
