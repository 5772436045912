import { useMutation } from '@tanstack/react-query'
import { companyDelete } from '@viterbit/web-app/dataAccess/service/companyService'

type CompanyDeleteConfig = {
    onSuccess?: () => void
    onError?: () => void
}

export const useCompanyDelete = (config: CompanyDeleteConfig) =>
    useMutation({
        ...config,
        mutationFn: companyDelete,
        onSuccess: config.onSuccess,
        onError: config.onError,
    })
