import * as React from 'react'

import Next from './Next'
import Pager from './Pagers'
import Prev from './Prev'
import Total from './Total'
import { clsxm } from '../../lib/clsxm'

const classes = {
    pagination: 'relative z-0 inline-flex items-center rounded-md',
    paginationPager:
        'cursor-pointer relative mx-1 inline-flex items-center h-8 w-8 justify-center text-sm font-semibold select-none',
    paginationPagerInactive:
        'text-gray-500 dark:text-gray-400 dark:hover:text-gray-100',
    paginationPagerDisabled:
        'cursor-not-allowed text-gray-300 dark:text-black',
    paginationButton: 'cursor-pointer inline-flex items-center h-8 justify-center text-sm font-semibold',
    variant: {
        default: 'rounded',
        tabs: 'py-5'
    }
}

type Props = {
    className?: string
    total?: number
    displayTotal?: boolean
    pageSize?: number
    currentPage?: number
    onChange?: (page: number) => void
    customNextIcon?: React.ReactNode
    customPrevIcon?: React.ReactNode
    pagerVariant?: 'default' | 'tabs',
    t?: (x: string) => string
}

const Pagination: React.FC<Props> = ({
    className,
    currentPage = 1,
    displayTotal = false,
    onChange,
    pageSize = 1,
    total = 5,
    customNextIcon,
    customPrevIcon,
    pagerVariant = 'default',
    t,
}) => {
    const [paginationTotal, setPaginationTotal] = React.useState(total)
    const [internalPageSize, setInternalPageSize] = React.useState(pageSize)

    const themeColor = 'primary'
    const primaryColorLevel = '600'

    const getInternalPageCount = React.useMemo(() => {
        if (typeof paginationTotal === 'number') {
            return Math.ceil(paginationTotal / internalPageSize)
        }
        return 0
    }, [paginationTotal, internalPageSize])

    const getValidCurrentPage = React.useCallback(
        (count: number) => {
            const value = parseInt(count.toString(), 10)
            const internalPageCount = getInternalPageCount
            let resetValue
            if (!internalPageCount) {
                if (isNaN(value) || value < 1) {
                    resetValue = 1
                }
            } else {
                if (value < 1) {
                    resetValue = 1
                }
                if (value > internalPageCount) {
                    resetValue = internalPageCount
                }
            }

            if (
                (resetValue === undefined && isNaN(value)) ||
                resetValue === 0
            ) {
                resetValue = 1
            }

            return resetValue === undefined ? value : resetValue
        },
        [getInternalPageCount]
    )

    const [internalCurrentPage, setInternalCurrentPage] = React.useState(
        currentPage ? getValidCurrentPage(currentPage) : 1
    )

    React.useEffect(() => {
        if (total !== paginationTotal) {
            setPaginationTotal(total)
        }

        if (pageSize !== internalPageSize) {
            setInternalPageSize(pageSize)
        }

        if (currentPage !== internalCurrentPage) {
            setInternalCurrentPage(currentPage)
        }
        // * avoid some dependencies is necessary to correct work
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [total, pageSize, currentPage])

    const onPaginationChange = (val: number) => {
        setInternalCurrentPage(getValidCurrentPage(val))
        onChange?.(getValidCurrentPage(val))
    }

    const onPrev = React.useCallback(() => {
        const newPage = internalCurrentPage - 1
        setInternalCurrentPage(getValidCurrentPage(newPage))
        onChange?.(getValidCurrentPage(newPage))
    }, [onChange, internalCurrentPage, getValidCurrentPage])

    const onNext = React.useCallback(() => {
        const newPage = internalCurrentPage + 1
        setInternalCurrentPage(getValidCurrentPage(newPage))
        onChange?.(getValidCurrentPage(newPage))
    }, [onChange, internalCurrentPage, getValidCurrentPage])

    const pagerClass = {
        default: clsxm(classes.paginationPager, classes.variant[pagerVariant]),
        inactive: clsxm(
            classes.paginationPagerInactive,
            pagerVariant == 'default' ? 'hover:bg-gray-100 dark:hover:bg-gray-600' : 'hover:border-t-2 hover:border-gray-300'
        ),
        active: clsxm(
            `text-${themeColor}-${primaryColorLevel} dark:text-gray-100`,
            pagerVariant == 'default' ? `bg-${themeColor}-50 hover:bg-${themeColor}-50 dark:bg-${themeColor}-${primaryColorLevel}` : `border-t-2 border-${themeColor}-${primaryColorLevel}`
        ),
        disabled: classes.paginationPagerDisabled,
    }

    const buttonClass = {
        ...pagerClass,
        default: classes.paginationButton,
        inactive: classes.paginationPagerInactive
    }

    const paginationClass = clsxm(classes.pagination, className)

    return (
        <div className={paginationClass}>
            {displayTotal && <Total total={total} />}
            <Prev
                currentPage={internalCurrentPage}
                pagerClass={buttonClass}
                onPrev={onPrev}
                customPrevIcon={customPrevIcon}
                pageCount={getInternalPageCount}
                t={t}
            />
            <Pager
                onChange={onPaginationChange}
                pageCount={getInternalPageCount}
                currentPage={internalCurrentPage}
                pagerClass={pagerClass}
            />
            <Next
                currentPage={internalCurrentPage}
                pageCount={getInternalPageCount}
                pagerClass={buttonClass}
                onNext={onNext}
                customNextIcon={customNextIcon}
                t={t}
            />
        </div>
    )
}

export default Pagination
