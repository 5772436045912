import { JobPositionLevel } from '@viterbit/web-app/dataAccess/model/JobPositionLevel.model'
import { IsNotEmpty, IsString } from '@viterbit/web-app/shared/utils/ClassValidators'


class DetailsJobPositionLevelValidator implements JobPositionLevel {
    id: string
    @IsNotEmpty()
    @IsString()
    name: string
    @IsString()
    description: string
    jobPositionId: string
}

export default DetailsJobPositionLevelValidator
