/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DataFindLeaveQueryResponse } from './DataFindLeaveQueryResponse';
import {
    DataFindLeaveQueryResponseFromJSON,
    DataFindLeaveQueryResponseFromJSONTyped,
    DataFindLeaveQueryResponseToJSON,
} from './DataFindLeaveQueryResponse';
import type { EmployeeAddress } from './EmployeeAddress';
import {
    EmployeeAddressFromJSON,
    EmployeeAddressFromJSONTyped,
    EmployeeAddressToJSON,
} from './EmployeeAddress';
import type { EmployeeBankInformation } from './EmployeeBankInformation';
import {
    EmployeeBankInformationFromJSON,
    EmployeeBankInformationFromJSONTyped,
    EmployeeBankInformationToJSON,
} from './EmployeeBankInformation';
import type { EmployeeContractInformation } from './EmployeeContractInformation';
import {
    EmployeeContractInformationFromJSON,
    EmployeeContractInformationFromJSONTyped,
    EmployeeContractInformationToJSON,
} from './EmployeeContractInformation';
import type { EmployeeDeleteInformation } from './EmployeeDeleteInformation';
import {
    EmployeeDeleteInformationFromJSON,
    EmployeeDeleteInformationFromJSONTyped,
    EmployeeDeleteInformationToJSON,
} from './EmployeeDeleteInformation';
import type { EmployeeEmergencyInformation } from './EmployeeEmergencyInformation';
import {
    EmployeeEmergencyInformationFromJSON,
    EmployeeEmergencyInformationFromJSONTyped,
    EmployeeEmergencyInformationToJSON,
} from './EmployeeEmergencyInformation';
import type { EmployeePersonalInformation } from './EmployeePersonalInformation';
import {
    EmployeePersonalInformationFromJSON,
    EmployeePersonalInformationFromJSONTyped,
    EmployeePersonalInformationToJSON,
} from './EmployeePersonalInformation';
import type { EstimationReportData } from './EstimationReportData';
import {
    EstimationReportDataFromJSON,
    EstimationReportDataFromJSONTyped,
    EstimationReportDataToJSON,
} from './EstimationReportData';
import type { FindAccountQueryResponse } from './FindAccountQueryResponse';
import {
    FindAccountQueryResponseFromJSON,
    FindAccountQueryResponseFromJSONTyped,
    FindAccountQueryResponseToJSON,
} from './FindAccountQueryResponse';
import type { FindCompanyQueryResponse } from './FindCompanyQueryResponse';
import {
    FindCompanyQueryResponseFromJSON,
    FindCompanyQueryResponseFromJSONTyped,
    FindCompanyQueryResponseToJSON,
} from './FindCompanyQueryResponse';
import type { FindFileQueryResponse } from './FindFileQueryResponse';
import {
    FindFileQueryResponseFromJSON,
    FindFileQueryResponseFromJSONTyped,
    FindFileQueryResponseToJSON,
} from './FindFileQueryResponse';
import type { FindJobPositionLevelQueryResponse } from './FindJobPositionLevelQueryResponse';
import {
    FindJobPositionLevelQueryResponseFromJSON,
    FindJobPositionLevelQueryResponseFromJSONTyped,
    FindJobPositionLevelQueryResponseToJSON,
} from './FindJobPositionLevelQueryResponse';
import type { FindJobPositionQueryResponse } from './FindJobPositionQueryResponse';
import {
    FindJobPositionQueryResponseFromJSON,
    FindJobPositionQueryResponseFromJSONTyped,
    FindJobPositionQueryResponseToJSON,
} from './FindJobPositionQueryResponse';
import type { FindLeavePolicyQueryResponse } from './FindLeavePolicyQueryResponse';
import {
    FindLeavePolicyQueryResponseFromJSON,
    FindLeavePolicyQueryResponseFromJSONTyped,
    FindLeavePolicyQueryResponseToJSON,
} from './FindLeavePolicyQueryResponse';
import type { FindLocationQueryResponse } from './FindLocationQueryResponse';
import {
    FindLocationQueryResponseFromJSON,
    FindLocationQueryResponseFromJSONTyped,
    FindLocationQueryResponseToJSON,
} from './FindLocationQueryResponse';
import type { LeaveAdjustmentData } from './LeaveAdjustmentData';
import {
    LeaveAdjustmentDataFromJSON,
    LeaveAdjustmentDataFromJSONTyped,
    LeaveAdjustmentDataToJSON,
} from './LeaveAdjustmentData';
import type { PublicEmployee } from './PublicEmployee';
import {
    PublicEmployeeFromJSON,
    PublicEmployeeFromJSONTyped,
    PublicEmployeeToJSON,
} from './PublicEmployee';
import type { WorkScheduleWrapper } from './WorkScheduleWrapper';
import {
    WorkScheduleWrapperFromJSON,
    WorkScheduleWrapperFromJSONTyped,
    WorkScheduleWrapperToJSON,
} from './WorkScheduleWrapper';

/**
 * 
 * @export
 * @interface FindEmployeeQueryResponse
 */
export interface FindEmployeeQueryResponse {
    /**
     * Employee id
     * @type {string}
     * @memberof FindEmployeeQueryResponse
     */
    id: string;
    /**
     * Employee id
     * @type {string}
     * @memberof FindEmployeeQueryResponse
     */
    name: string;
    /**
     * Employee surname
     * @type {string}
     * @memberof FindEmployeeQueryResponse
     */
    surname?: string | null;
    /**
     * Employee email
     * @type {string}
     * @memberof FindEmployeeQueryResponse
     */
    email: string;
    /**
     * Employee has account
     * @type {boolean}
     * @memberof FindEmployeeQueryResponse
     */
    hasAccount: boolean;
    /**
     * Employee is active
     * @type {boolean}
     * @memberof FindEmployeeQueryResponse
     */
    isActive: boolean;
    /**
     * Employee is deleted
     * @type {boolean}
     * @memberof FindEmployeeQueryResponse
     */
    isDeleted: boolean;
    /**
     * Employee job position alias
     * @type {string}
     * @memberof FindEmployeeQueryResponse
     */
    jobPositionAlias?: string | null;
    /**
     * 
     * @type {EmployeePersonalInformation}
     * @memberof FindEmployeeQueryResponse
     */
    personalInformation?: EmployeePersonalInformation;
    /**
     * 
     * @type {EmployeeAddress}
     * @memberof FindEmployeeQueryResponse
     */
    address?: EmployeeAddress;
    /**
     * 
     * @type {EmployeeBankInformation}
     * @memberof FindEmployeeQueryResponse
     */
    bankInformation?: EmployeeBankInformation;
    /**
     * 
     * @type {EmployeeDeleteInformation}
     * @memberof FindEmployeeQueryResponse
     */
    deleteInformation?: EmployeeDeleteInformation;
    /**
     * 
     * @type {EmployeeEmergencyInformation}
     * @memberof FindEmployeeQueryResponse
     */
    emergencyInformation?: EmployeeEmergencyInformation;
    /**
     * 
     * @type {EmployeeContractInformation}
     * @memberof FindEmployeeQueryResponse
     */
    contractInformation?: EmployeeContractInformation;
    /**
     * 
     * @type {object}
     * @memberof FindEmployeeQueryResponse
     */
    fiscalInformation?: object;
    /**
     * Employee leave adjustments
     * @type {Array<LeaveAdjustmentData>}
     * @memberof FindEmployeeQueryResponse
     */
    leaveAdjustments?: Array<LeaveAdjustmentData>;
    /**
     * 
     * @type {FindAccountQueryResponse}
     * @memberof FindEmployeeQueryResponse
     */
    account?: FindAccountQueryResponse;
    /**
     * 
     * @type {PublicEmployee}
     * @memberof FindEmployeeQueryResponse
     */
    manager?: PublicEmployee;
    /**
     * 
     * @type {FindFileQueryResponse}
     * @memberof FindEmployeeQueryResponse
     */
    avatar?: FindFileQueryResponse;
    /**
     * 
     * @type {FindFileQueryResponse}
     * @memberof FindEmployeeQueryResponse
     */
    idFile?: FindFileQueryResponse;
    /**
     * 
     * @type {FindLocationQueryResponse}
     * @memberof FindEmployeeQueryResponse
     */
    location?: FindLocationQueryResponse;
    /**
     * 
     * @type {FindLeavePolicyQueryResponse}
     * @memberof FindEmployeeQueryResponse
     */
    leavePolicy?: FindLeavePolicyQueryResponse;
    /**
     * 
     * @type {FindCompanyQueryResponse}
     * @memberof FindEmployeeQueryResponse
     */
    company?: FindCompanyQueryResponse;
    /**
     * 
     * @type {FindJobPositionQueryResponse}
     * @memberof FindEmployeeQueryResponse
     */
    jobPosition?: FindJobPositionQueryResponse;
    /**
     * 
     * @type {WorkScheduleWrapper}
     * @memberof FindEmployeeQueryResponse
     */
    workSchedules?: WorkScheduleWrapper;
    /**
     * 
     * @type {FindJobPositionLevelQueryResponse}
     * @memberof FindEmployeeQueryResponse
     */
    jobPositionLevel?: FindJobPositionLevelQueryResponse;
    /**
     * 
     * @type {DataFindLeaveQueryResponse}
     * @memberof FindEmployeeQueryResponse
     */
    leaves?: DataFindLeaveQueryResponse;
    /**
     * 
     * @type {EstimationReportData}
     * @memberof FindEmployeeQueryResponse
     */
    estimation?: EstimationReportData;
}

/**
 * Check if a given object implements the FindEmployeeQueryResponse interface.
 */
export function instanceOfFindEmployeeQueryResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "hasAccount" in value;
    isInstance = isInstance && "isActive" in value;
    isInstance = isInstance && "isDeleted" in value;

    return isInstance;
}

export function FindEmployeeQueryResponseFromJSON(json: any): FindEmployeeQueryResponse {
    return FindEmployeeQueryResponseFromJSONTyped(json, false);
}

export function FindEmployeeQueryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindEmployeeQueryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
        'email': json['email'],
        'hasAccount': json['hasAccount'],
        'isActive': json['isActive'],
        'isDeleted': json['isDeleted'],
        'jobPositionAlias': !exists(json, 'jobPositionAlias') ? undefined : json['jobPositionAlias'],
        'personalInformation': !exists(json, 'personalInformation') ? undefined : EmployeePersonalInformationFromJSON(json['personalInformation']),
        'address': !exists(json, 'address') ? undefined : EmployeeAddressFromJSON(json['address']),
        'bankInformation': !exists(json, 'bankInformation') ? undefined : EmployeeBankInformationFromJSON(json['bankInformation']),
        'deleteInformation': !exists(json, 'deleteInformation') ? undefined : EmployeeDeleteInformationFromJSON(json['deleteInformation']),
        'emergencyInformation': !exists(json, 'emergencyInformation') ? undefined : EmployeeEmergencyInformationFromJSON(json['emergencyInformation']),
        'contractInformation': !exists(json, 'contractInformation') ? undefined : EmployeeContractInformationFromJSON(json['contractInformation']),
        'fiscalInformation': !exists(json, 'fiscalInformation') ? undefined : json['fiscalInformation'],
        'leaveAdjustments': !exists(json, 'leaveAdjustments') ? undefined : ((json['leaveAdjustments'] as Array<any>).map(LeaveAdjustmentDataFromJSON)),
        'account': !exists(json, 'account') ? undefined : FindAccountQueryResponseFromJSON(json['account']),
        'manager': !exists(json, 'manager') ? undefined : PublicEmployeeFromJSON(json['manager']),
        'avatar': !exists(json, 'avatar') ? undefined : FindFileQueryResponseFromJSON(json['avatar']),
        'idFile': !exists(json, 'idFile') ? undefined : FindFileQueryResponseFromJSON(json['idFile']),
        'location': !exists(json, 'location') ? undefined : FindLocationQueryResponseFromJSON(json['location']),
        'leavePolicy': !exists(json, 'leavePolicy') ? undefined : FindLeavePolicyQueryResponseFromJSON(json['leavePolicy']),
        'company': !exists(json, 'company') ? undefined : FindCompanyQueryResponseFromJSON(json['company']),
        'jobPosition': !exists(json, 'jobPosition') ? undefined : FindJobPositionQueryResponseFromJSON(json['jobPosition']),
        'workSchedules': !exists(json, 'workSchedules') ? undefined : WorkScheduleWrapperFromJSON(json['workSchedules']),
        'jobPositionLevel': !exists(json, 'jobPositionLevel') ? undefined : FindJobPositionLevelQueryResponseFromJSON(json['jobPositionLevel']),
        'leaves': !exists(json, 'leaves') ? undefined : DataFindLeaveQueryResponseFromJSON(json['leaves']),
        'estimation': !exists(json, 'estimation') ? undefined : EstimationReportDataFromJSON(json['estimation']),
    };
}

export function FindEmployeeQueryResponseToJSON(value?: FindEmployeeQueryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'surname': value.surname,
        'email': value.email,
        'hasAccount': value.hasAccount,
        'isActive': value.isActive,
        'isDeleted': value.isDeleted,
        'jobPositionAlias': value.jobPositionAlias,
        'personalInformation': EmployeePersonalInformationToJSON(value.personalInformation),
        'address': EmployeeAddressToJSON(value.address),
        'bankInformation': EmployeeBankInformationToJSON(value.bankInformation),
        'deleteInformation': EmployeeDeleteInformationToJSON(value.deleteInformation),
        'emergencyInformation': EmployeeEmergencyInformationToJSON(value.emergencyInformation),
        'contractInformation': EmployeeContractInformationToJSON(value.contractInformation),
        'fiscalInformation': value.fiscalInformation,
        'leaveAdjustments': value.leaveAdjustments === undefined ? undefined : ((value.leaveAdjustments as Array<any>).map(LeaveAdjustmentDataToJSON)),
        'account': FindAccountQueryResponseToJSON(value.account),
        'manager': PublicEmployeeToJSON(value.manager),
        'avatar': FindFileQueryResponseToJSON(value.avatar),
        'idFile': FindFileQueryResponseToJSON(value.idFile),
        'location': FindLocationQueryResponseToJSON(value.location),
        'leavePolicy': FindLeavePolicyQueryResponseToJSON(value.leavePolicy),
        'company': FindCompanyQueryResponseToJSON(value.company),
        'jobPosition': FindJobPositionQueryResponseToJSON(value.jobPosition),
        'workSchedules': WorkScheduleWrapperToJSON(value.workSchedules),
        'jobPositionLevel': FindJobPositionLevelQueryResponseToJSON(value.jobPositionLevel),
        'leaves': DataFindLeaveQueryResponseToJSON(value.leaves),
        'estimation': EstimationReportDataToJSON(value.estimation),
    };
}

