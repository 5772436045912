import * as React from 'react'
import {
    ToastContainer as ToastifyToastContainer,
    ToastContainerProps,
} from 'react-toastify'

import CloseButton from '../CloseButton'

const ToastContainer = React.forwardRef<HTMLDivElement, ToastContainerProps>(
    (props, ref) => (
        <ToastifyToastContainer
            closeButton={({ closeToast }) => (
                <CloseButton onClick={closeToast} />
            )}
            ref={ref}
            {...props}
        />
    )
)

export default ToastContainer
