import { IMapper } from './__common'
import integrationMapper from './integration.mapper'
import { IntegrationOptionGroupResponse } from '../dto/integration'
import { IntegrationOptionAuthType, IntegrationOptionGroup } from '../model/IntegrationOption.model'

const integrationOptionMapper: IMapper<IntegrationOptionGroup, IntegrationOptionGroupResponse> = {
    toModel: ({kind, options}: IntegrationOptionGroupResponse): IntegrationOptionGroup => ({
        kind,
        options: options.data.map(({authType, ...option}) => ({
            ...option,
            authType: authType as IntegrationOptionAuthType,
            available: !!option.available,
            integrations: option.integrations.data.map(integrationMapper.toModel)
        })),
    }),
    toDto: () => {
        throw new Error('Not implemented')
    },
}

export default integrationOptionMapper
