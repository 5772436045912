import { IsEmail, IsNotEmpty } from '@viterbit/web-app/shared/utils/ClassValidators'


class ForgotPasswordRequestValidator {
    @IsEmail()
    @IsNotEmpty()
    email: string
}

export default ForgotPasswordRequestValidator
