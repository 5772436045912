/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react'

export function useOnScreen(ref) {
    const [isOnScreen, setIsOnScreen] = React.useState(false)
    const observerRef: any = React.useRef(null)

    React.useEffect(() => {
        observerRef.current = new IntersectionObserver((entries) => {
            const isIntersecting = entries.some((entry) => entry.isIntersecting)
            setIsOnScreen(isIntersecting)
        })
    }, [])

    React.useEffect(() => {
        observerRef.current.observe(ref.current)

        return () => {
            observerRef.current.disconnect()
        }
    }, [ref])

    return isOnScreen
}
