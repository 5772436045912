import { LeavePolicy } from '@viterbit/web-app/dataAccess/model/LeavePolicy.model'
import { IWeekDays, WEEK_DAYS } from '@viterbit/web-app/dataAccess/model/Week.model'
import { ArrayMinSize, ArrayNotEmpty, IsArray, IsIn, IsNotEmpty, IsString } from '@viterbit/web-app/shared/utils/ClassValidators'


class DetailsLeavePoliciesValidator implements Omit<LeavePolicy, 'reasons'> {
    id: string
    default: boolean

    @IsNotEmpty()
    @IsString()
    name: string

    @IsString()
    description: string

    daysPerYear: number

    @IsArray()
    @ArrayNotEmpty()
    @ArrayMinSize(1)
    @IsIn(
        WEEK_DAYS,
        {
            each: true,
        }
    )
    daysRange: IWeekDays[]
}

export default DetailsLeavePoliciesValidator
