import { Summary } from '@viterbit/web-app/dataAccess/model/Summary.model'
import i18n from '@viterbit/web-app/i18n'
import Small from '@viterbit/web-app/shared/typography/Small'
import * as React from 'react'
import clsxm from 'ui/src/lib'
import { LeaveAdjustmentData } from 'viterbit-api-sdk'

import SummaryDetailsItem from './SummaryDetailsItem'

type SummaryDetailsProps = {
    summary: Summary
    extraDays?: LeaveAdjustmentData
    className?: string
}

const getExtraDaysText = (extraDays = 0) => {
    if (!extraDays) {
        return null
    }

    const sign = extraDays > 0 ? '+' : '-'
    const absExtraDaysCount = Math.abs(extraDays)

    return `${sign}${i18n.t('daysSummary:extraDays', { count: absExtraDaysCount })}`
}

const SummaryDetails = ({
    className,
    summary,
    extraDays,
}: SummaryDetailsProps) => {
    const extraText = React.useMemo(() => getExtraDaysText(extraDays?.operation), [extraDays])

    return (
        <div className={clsxm('px-4 py-2.5 w-[300px]', className)}>
            <SummaryDetailsItem
                label={i18n.t('daysSummary:daysByPolicy')}
                used={summary.usedLeavePolicyDays}
                total={summary.totalDays}
            >
                <Small opacity>
                    {extraText}
                </Small>
            </SummaryDetailsItem>
            <SummaryDetailsItem
                label={i18n.t('daysSummary:carried')}
                used={summary.usedCarriedDays}
                total={summary.carriedDays}
                className='mb-0'
            >
                <Small opacity>
                    {i18n.t('daysSummary:validUntilJanuary')}
                </Small>
            </SummaryDetailsItem>
        </div>
    )
}

export default SummaryDetails
