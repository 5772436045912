/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FindFileQueryResponse
 */
export interface FindFileQueryResponse {
    /**
     * 
     * @type {string}
     * @memberof FindFileQueryResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof FindFileQueryResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof FindFileQueryResponse
     */
    mimeType: string;
    /**
     * 
     * @type {string}
     * @memberof FindFileQueryResponse
     */
    type: string;
    /**
     * 
     * @type {number}
     * @memberof FindFileQueryResponse
     */
    size: number;
    /**
     * 
     * @type {string}
     * @memberof FindFileQueryResponse
     */
    extension?: string;
    /**
     * 
     * @type {string}
     * @memberof FindFileQueryResponse
     */
    url: string;
}

/**
 * Check if a given object implements the FindFileQueryResponse interface.
 */
export function instanceOfFindFileQueryResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "mimeType" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "size" in value;
    isInstance = isInstance && "url" in value;

    return isInstance;
}

export function FindFileQueryResponseFromJSON(json: any): FindFileQueryResponse {
    return FindFileQueryResponseFromJSONTyped(json, false);
}

export function FindFileQueryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindFileQueryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'mimeType': json['mimeType'],
        'type': json['type'],
        'size': json['size'],
        'extension': !exists(json, 'extension') ? undefined : json['extension'],
        'url': json['url'],
    };
}

export function FindFileQueryResponseToJSON(value?: FindFileQueryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'mimeType': value.mimeType,
        'type': value.type,
        'size': value.size,
        'extension': value.extension,
        'url': value.url,
    };
}

