import * as React from 'react'

import Skeleton from '../Skeleton'
import clsxm from '../../lib'

export type FormControlSkeletonProps = {
    label?: boolean
    area?: boolean
}

const FormControlSkeleton = ({ label, area }: FormControlSkeletonProps) => (
    <>
        {label && <Skeleton className='w-[20%] h-[14px] mb-2 mt-2' />}
        <Skeleton className={clsxm('w-full h-8 mb-4', area && 'h-20')} />
    </>
)

export default FormControlSkeleton
