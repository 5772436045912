import { IntegrationType } from '@viterbit/web-app/dataAccess/model/Integration.model'
import ViterbitLogo from '@viterbit/web-assets/images/logo_blue.svg'
import GoogleLogo from '@viterbit/web-assets/logos/google.svg'
import MicrosoftLogo from '@viterbit/web-assets/logos/microsoft.svg'
import SlackLogo from '@viterbit/web-assets/logos/slack.svg'
import ZapierLogo from '@viterbit/web-assets/logos/zapier-rounded.svg'

export const integrationTypeIconMapper = {
    [IntegrationType.Google]: GoogleLogo,
    [IntegrationType.Microsoft]: MicrosoftLogo,
    [IntegrationType.Slack]: SlackLogo,
    [IntegrationType.Zapier]: ZapierLogo,
    [IntegrationType.Viterbit]: ViterbitLogo,
}