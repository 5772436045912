import isNil from 'lodash/isNil'
import * as React from 'react'
import { ControllerRenderProps, FieldValues } from 'react-hook-form'

import { CONTROL_SIZES } from '../../constants/constant'
import { clsxm } from '../../lib/clsxm'

const classes = {
    base: 'border border-gray-200 w-full py-2 px-3 focus:outline-none appearance-none transition rounded-md duration-150 dark:text-gray-100 ease-in-out placeholder:text-gray-400',
    isInvalid: 'ring-1 ring-danger-500 border-danger-500',
    lg: 'text-lg',
    addon: 'flex items-center px-4 rounded-md border border-gray-300 bg-gray-50',
    'text-area': '7rem min-h-[80px]',
    wrapper: 'relative w-full flex',
    disabled:
        'bg-gray-100 dark:bg-gray-700  text-gray-400 cursor-not-allowed placeholder: opacity-70',
    'suffix-start': 'absolute top-2/4 transform -translate-y-2/4 left-2.5',
    'suffix-end': 'absolute top-2/4 transform -translate-y-2/4 right-2.5 flex',
}

export type InputProps =
    | {
          className?: string
          type?: string
          size?: 'sm' | 'md' | 'lg'
          isInvalid?: boolean
          suffix?: React.ReactNode | string
          prefix?: React.ReactNode | string
          children?: React.ReactNode
          unstyle?: boolean
          textArea?: boolean
          'data-testid'?: string
          field?: ControllerRenderProps<FieldValues, string>
      } & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size' | 'prefix'>

const Input = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
    const {
        size = 'md',
        className,
        disabled,
        isInvalid,
        suffix,
        prefix,
        unstyle,
        type,
        textArea,
        style,
        field,
        ...rest
    } = props

    const [prefixGutter, setPrefixGutter] = React.useState(0)
    const [suffixGutter, setSuffixGutter] = React.useState(0)

    const inputSize = size

    const inputDefaultClass = classes.base
    const inputSizeClass = clsxm(
        size === 'lg' && classes.lg,
        `h-${CONTROL_SIZES[inputSize]}`
    )
    const inputFocusClass =
        'focus:ring-primary-600 focus-within:ring-primary-600 focus-within:border-primary-600 focus:border-primary-600'

    const inputWrapperClass = clsxm(
        classes.wrapper,
        prefix || suffix ? className : ''
    )
    const inputClass = clsxm(
        inputDefaultClass,
        !textArea && inputSizeClass,
        !isInvalid && inputFocusClass,
        !prefix && !suffix ? className : '',
        disabled && classes.disabled,
        isInvalid && classes.isInvalid,
        textArea && classes['text-area']
    )

    const prefixNode = React.useRef<HTMLElement | null>()
    const suffixNode = React.useRef<HTMLElement | null>()

    const getAffixSize = () => {
        if (!prefixNode.current && !suffixNode.current) {
            return
        }
        const prefixNodeWidth = prefixNode?.current?.offsetWidth
        const suffixNodeWidth = suffixNode?.current?.offsetWidth

        if (isNil(prefixNodeWidth) && isNil(suffixNodeWidth)) {
            return
        }

        if (prefixNodeWidth) {
            setPrefixGutter(prefixNodeWidth)
        }

        if (suffixNodeWidth) {
            setSuffixGutter(suffixNodeWidth)
        }
    }

    React.useEffect(() => {
        getAffixSize()
    }, [prefix, suffix])

    const affixGutterStyle = () => {
        const remToPxConversion = (pixel: number) => 0.0625 * pixel

        const leftGutter = `${remToPxConversion(prefixGutter) + 1}rem`
        const rightGutter = `${remToPxConversion(suffixGutter) + 1}rem`
        const gutterStyle: React.CSSProperties = {}

        if (prefix) {
            gutterStyle.paddingLeft = leftGutter
        }

        if (suffix) {
            gutterStyle.paddingRight = rightGutter
        }

        return gutterStyle
    }

    const fixControlledValue = (value?: string) =>
        typeof value === 'undefined' || value === null ? '' : value

    if ('value' in props) {
        rest.value = fixControlledValue(props.value as unknown as string)
        delete rest.defaultValue
    }

    const inputProps = {
        className: !unstyle ? inputClass : '',
        disabled,
        type,
        ref,
        field,
        ...rest,
    }

    const renderInput = (
        <input style={{ ...affixGutterStyle(), ...style }} {...inputProps} data-testid={rest['data-testid']}/>
    )

    const renderAffixInput = (
        <span className={inputWrapperClass}>
            {prefix ? (
                <div
                    className={classes['suffix-start']}
                    ref={(node) => (prefixNode.current = node)}
                >
                    {' '}
                    {prefix}{' '}
                </div>
            ) : null}
            {renderInput}
            {suffix ? (
                <div
                    className={classes['suffix-end']}
                    ref={(node) => (suffixNode.current = node)}
                >
                    {suffix}
                </div>
            ) : null}
        </span>
    )

    const renderChildren = () => {
        if (textArea) {
            const textAreaProps =
                inputProps as unknown as React.TextareaHTMLAttributes<HTMLTextAreaElement>
            return <textarea style={style} {...textAreaProps}></textarea>
        }

        if (prefix || suffix) {
            return renderAffixInput
        } else {
            return renderInput
        }
    }

    return renderChildren()
})
export default Input
