import { useInfiniteQuery } from '@tanstack/react-query'

import { WORK_SCHEDULE_KEY } from '../../cacheKeys'
import { workScheduleListPaginated } from '../../service/workScheduledService'

type WorkScheduleListPaginatedParams = {
    query?: Parameters<typeof workScheduleListPaginated>[0]
    enabled?: boolean
    keepPreviousData?: boolean
}

export const useWorkScheduleListPaginated = (config: WorkScheduleListPaginatedParams = {}) => {
    const { query, enabled, keepPreviousData } = config || {}

    return useInfiniteQuery(
        [WORK_SCHEDULE_KEY, 'list', query],
        ({ pageParam }) => workScheduleListPaginated({
            ...query,
            page: pageParam || 1,
        }),
        {
            getNextPageParam: (lastPage) => lastPage ? lastPage.meta.nextPage : 1,
            initialData: () => ({
                pageParams: [1],
                pages: [],
            }),
            enabled,
            keepPreviousData,
        }
    )
}

