
import { classValidatorResolver } from '@hookform/resolvers/class-validator'
import i18n from '@viterbit/web-app/i18n'
import ButtonCreate from '@viterbit/web-app/shared/form/ButtonCreate'
import Form from '@viterbit/web-app/shared/form/Form'
import FormSelectCountry from '@viterbit/web-app/shared/form/FormSelectCountry'
import FormSelectState from '@viterbit/web-app/shared/form/FormSelectState'
import SubmitButton from '@viterbit/web-app/shared/form/SubmitButton'
import { TIME_ZONE } from '@viterbit/web-app/shared/utils/timeZone'
import useLocale from '@viterbit/web-app/shared/utils/useLocale'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import Button from 'ui/src/components/Button'
import Dialog from 'ui/src/components/Dialog'
import { FormInput } from 'ui/src/components/Form'

import CreateLocationValidator from './CreateLocationValidator'
import FormGeoData from '../FormGeoData'
import { locationCreate } from '../../../../dataAccess/service/locationService'

type CreateLocationFields = {
    name: string
    country: string
    state: string
}

const defaultResolver = classValidatorResolver(CreateLocationValidator)

const CreateLocationDialog = () => {
    const [open, setOpen] = React.useState(false)
    const locale = useLocale()
    const navigate = useNavigate()

    const onClose = () => {
        setOpen(false)
    }

    return <>
        <ButtonCreate collapsed title={i18n.t('settingLocations:addLocation')} onClick={() => setOpen(true)} />

        <Dialog open={open} onClose={onClose} title={i18n.t('settingLocations:addLocation')}>
            <Form<CreateLocationFields> 
                withTransition
                formConfig={{
                    resolver: defaultResolver,
                    mode: 'all',
                }}
                mutationConfig={{
                    mutationKey: ['create-location'],
                    onSuccess: (data) => {
                        navigate(`/settings/locations/${data.id}/details`)
                        onClose()
                    },
                    mutationFn: (data) => locationCreate({
                        createLocationCommand: {
                            ...data,
                            timezone: TIME_ZONE
                        }
                    })
                }}
            >
                {
                    (form) => (
                        <>
                            <FormInput
                                asterisk
                                name='name'
                                label={i18n.t('settingLocations:name')}
                                placeholder={i18n.t('settingLocations:enterLocationName')}
                                control={form.control}
                            />

                            <FormGeoData>
                                <FormSelectCountry
                                    asterisk
                                    isSearchable
                                    locale={locale}
                                    name='country'
                                    control={form.control}
                                    label={i18n.t('settingLocations:country')}
                                    placeholder={i18n.t('settingLocations:selectCountry')}
                                />
                                <FormSelectState
                                    asterisk
                                    isSearchable
                                    name='state'
                                    control={form.control}
                                    label={i18n.t('settingLocations:state')}
                                    placeholder={i18n.t('settingLocations:selectState')}
                                />
                            </FormGeoData>

                            <Button onClick={onClose}>{i18n.t('settingLocations:cancel')}</Button>
                            <SubmitButton>{i18n.t('settingLocations:create')}</SubmitButton>
                        </>
                    )
                }
            </Form>
        </Dialog>
    </>
}

export default CreateLocationDialog
