/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FindWorkScheduleQueryResponse, Schedule2, ToFromData2, WorkSchedulePeriod2 } from 'viterbit-api-sdk'

import { IMapper } from './__common'
import dateMapper from './date.mapper'
import { WorkSchedule, WorkScheduleDayPeriod, WorkSchedulePeriod, WorkScheduleWeekPeriod } from '../model/WorkSchedule.model'

const workSchedulePeriodDayMapper : IMapper<WorkScheduleDayPeriod, ToFromData2> = {
    toModel: (dto) => ({
        from: dto.from,
        to: dto.to,
        fixed: dto.fixed,
    }),
    toDto: (model) => ({
        from: model.from,
        to: model.to,
        fixed: model.fixed,
    }),
}

const workSchedulePeriodWeekMapper : IMapper<WorkScheduleWeekPeriod, Schedule2> = {
    toModel: (dto) => ({
        monday: dto.monday ? dto.monday.map(workSchedulePeriodDayMapper.toModel) : null,
        tuesday: dto.tuesday ? dto.tuesday.map(workSchedulePeriodDayMapper.toModel): null,
        wednesday: dto.wednesday ? dto.wednesday.map(workSchedulePeriodDayMapper.toModel): null,
        thursday: dto.thursday ? dto.thursday.map(workSchedulePeriodDayMapper.toModel): null,
        friday: dto.friday ? dto.friday.map(workSchedulePeriodDayMapper.toModel): null,
        saturday: dto.saturday ? dto.saturday.map(workSchedulePeriodDayMapper.toModel): null,
        sunday: dto.sunday ? dto.sunday.map(workSchedulePeriodDayMapper.toModel): null,
    }),
    toDto: (model) => ({
        monday: model.monday ? model.monday.map(workSchedulePeriodDayMapper.toDto) : null,
        tuesday: model.tuesday ? model.tuesday.map(workSchedulePeriodDayMapper.toDto): null,
        wednesday: model.wednesday ? model.wednesday.map(workSchedulePeriodDayMapper.toDto): null,
        thursday: model.thursday ? model.thursday.map(workSchedulePeriodDayMapper.toDto): null,
        friday: model.friday ? model.friday.map(workSchedulePeriodDayMapper.toDto): null,
        saturday: model.saturday ? model.saturday.map(workSchedulePeriodDayMapper.toDto): null,
        sunday: model.sunday ? model.sunday.map(workSchedulePeriodDayMapper.toDto): null,
    } as any),
}

const workSchedulePeriodMapper : IMapper<WorkSchedulePeriod, WorkSchedulePeriod2> = {
    toModel: (dto) => ({
        startDate: dateMapper.fromDayWithMonth(dto.startDate)!.toDate(),
        endDate: dateMapper.fromDayWithMonth(dto.endDate)!.toDate(),
        schedule: workSchedulePeriodWeekMapper.toModel(dto.schedule),  
        
    }),
    toDto: (model) => ({
        startDate: dateMapper.toDayWithMonth(model.startDate)!,
        endDate: dateMapper.toDayWithMonth(model.endDate)!,
        schedule: workSchedulePeriodWeekMapper.toDto(model.schedule as any) as any,
    }),
}

const workScheduleMapper: IMapper<WorkSchedule, FindWorkScheduleQueryResponse> = {
    toModel: (dto) => ({
        id: dto.id,
        name: dto.name,
        type: dto.type as any,
        mainPeriod: workSchedulePeriodMapper.toModel(dto.mainPeriod),
        periods: dto.periods ? dto.periods.map(workSchedulePeriodMapper.toModel) : [],
    
    }),
    toDto: (model) => ({
        id: model.id,
        name: model.name,
        mainPeriod: workSchedulePeriodMapper.toDto(model.mainPeriod as any) as any,
        periods: model.periods ? model.periods.map(workSchedulePeriodMapper.toDto) : [] as any,
    }),
}

export default workScheduleMapper
