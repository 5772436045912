import { useTeamDetail } from '@viterbit/web-app/dataAccess/useCase/team/useTeamDetail'
import throwException from '@viterbit/web-app/shared/utils/throwException'
import { useParams } from 'react-router-dom'
import FormSkeleton from 'ui/src/components/Form/FormSkeleton'

import TeamDetailForm from './TeamDetailForm'
import TeamDeleteAction from '../delete/TeamDeleteAction'

const TeamDetailView = () => {
    const params = useParams()

    const { data, isLoading } = useTeamDetail({
        query: {
            id: params.teamId!
        },
        enabled: !!params.teamId
    })

    if (!params.teamId) throwException('Team id is required')

    if (isLoading || !data) return <FormSkeleton count={2} />

    return (
        <>
            <TeamDetailForm key={data.id} team={data} />
            <TeamDeleteAction team={data} />
        </>
    )
}

export default TeamDetailView