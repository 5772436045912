
import { IsNotEmpty, IsString, IsStrongPassword } from '@viterbit/web-app/shared/utils/ClassValidators'
import { Match } from '@viterbit/web-app/shared/utils/match.decorator'

import { ForgotPasswordFormFields } from './ForgotPasswordRedeemDetail'

class ForgotPasswordRedeemValidator implements ForgotPasswordFormFields {
    @IsNotEmpty()
    @IsStrongPassword()
    password: string

    @IsString()
    @Match('password')
    confirmPassword: string
}

export default ForgotPasswordRedeemValidator
