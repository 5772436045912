import * as React from 'react'

type AnimatePopProps = {
    children?: React.ReactNode
    open?: boolean
}

// TODO: create a better animation?
const AnimatePop = ({ children, open }: AnimatePopProps) => {
    if (!open) return null
    return <>{children}</>
}

export default AnimatePop
