/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-array-index-key */
import { Button, Indicator, Table } from '@mantine/core'
import { WorkSchedule,WorkScheduleDays, WorkScheduleWeekPeriod } from '@viterbit/web-app/dataAccess/model/WorkSchedule.model'
import i18n from '@viterbit/web-app/i18n'
import ButtonDelete from '@viterbit/web-app/shared/form/ButtonDelete'
import * as React from 'react'
import { useFieldArray,useFormContext,UseFormReturn } from 'react-hook-form'
import { FormTimePicker } from 'ui/src/components/Form'
import clsxm from 'ui/src/lib'

import useScheduleDetailPeriodTotal from './useScheduleDetailPeriodTotal'

type WorkScheduleDetailBasicFieldsProps = {
    children?: React.ReactNode
    className?: string
    form: UseFormReturn<WorkSchedule>
    name: string
    showActions?: boolean
}

const styles = {
    root: 'flex flex-col',
    row: 'flex [&>*]:flex-1',
}

const WorkScheduleDetailPeriodFixedFieldDayCell = (props: WorkScheduleDetailBasicFieldsProps) => {
    const { form, name, showActions, className } = props

    const values = form.watch(name as any)

    if (!values) return null
    
    const sumDay = values.from && values.to && values.from >= values.to && '+1d'

    return (
        <div className={clsxm(styles.row, className)} data-testid="work-schedulke-detail-period-field-cell">
            <FormTimePicker
                readOnly={!showActions}
                data-testid={`${name}.from`}
                className='mt-2 mb-0'
                hideIcon
                name={`${name}.from`}
                control={form.control}
                sx={{ input: { borderTopRightRadius: 0, borderBottomRightRadius: 0,  }, '&:focus-within': { zIndex: 1 } }}
            />
            <Indicator color='grey' offset={7} disabled={!sumDay} label={sumDay} size={16}>
                <FormTimePicker
                    readOnly={!showActions}
                    data-testid={`${name}.to`}
                    className='mt-2 mb-0'
                    hideIcon
                    name={`${name}.to`}
                    control={form.control}
                    sx={{ input: { borderTopLeftRadius: 0, borderBottomLeftRadius: 0, marginLeft: -1 } }}
                />
            </Indicator>
        </div>
    )
}

const WorkScheduleDetailPeriodFlexibleFieldDayCell = (props: WorkScheduleDetailBasicFieldsProps) => {
    const { form, name, showActions, className } = props

    return (
        <div className={clsxm(styles.row, className)} data-testid="work-schedulke-detail-period-field-cell" >
            <FormTimePicker
                readOnly={!showActions}
                data-testid={`${name}.fixed`}
                className='mt-2 mb-0'
                hideIcon
                name={`${name}.fixed`}
                control={form.control}
            />
        </div>
    )
}

const WorkScheduleDetailPeriodFieldDay = (props: WorkScheduleDetailBasicFieldsProps) => {
    const { form, name, className, showActions } = props

    const { fields } = useFieldArray({
        control: form.control,
        name: name as any,
    })

    const type: WorkSchedule['type'] = form.watch('type')
    const FieldComponent = type === 'FIXED' ? WorkScheduleDetailPeriodFixedFieldDayCell : WorkScheduleDetailPeriodFlexibleFieldDayCell
    const showedFields = React.useMemo(() => type === 'FIXED' ? fields : [fields[0]], [fields, type])

    return (
        <div className={clsxm(styles.root, className)}>
            {showedFields.map((field, index) => (
                <>
                    <FieldComponent
                        showActions={showActions}
                        name={`${name}.${index}`}
                        form={form}
                    />
                </>
            ))}

        </div>
    )
}

const WorkScheduleDayPeriodsTableFields = (props: WorkScheduleDetailBasicFieldsProps) => {
    const { form, name, showActions } = props

    const methods = useFormContext()
    const total = useScheduleDetailPeriodTotal(name)
    const type = form.watch('type')
    const items = form.watch(name as any).monday

    const addPeriod = () => {
        WorkScheduleDays.forEach(day => {
            const currentValue = methods.getValues(`${name}.${day}`)
            methods.setValue(`${name}.${day}`, [...currentValue, { from: '', to: '', fixed: '' }])
        })
    }

    const removePeriod = (index) => () => {
        const currentValues: WorkScheduleWeekPeriod = methods.getValues(name)
        const newValues = WorkScheduleDays.reduce<WorkScheduleWeekPeriod>((acc, dayName: typeof WorkScheduleDays[number]) => {
            acc[dayName] = acc[dayName]!.filter((_, i) => i !== index)
            return acc
        }, currentValues)
        methods.setValue(name, newValues, {})
    }

    return (
        <>
            <div className="overflow-y-auto" >
                <Table captionSide="bottom" horizontalSpacing={5}>
                    <thead>
                        <tr>
                            {WorkScheduleDays.map((day, index) => (
                                <th key={day} >
                                    <span className={{
                                        0: 'pl-3',
                                        6: type === 'FIXED' ? '': 'mr-3'
                                    }[index]}>
                                        {i18n.t(`settingWorkSchedule:fields.days.title.${day}`)}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {WorkScheduleDays.map((day, index) => (
                                <td key={day}>
                                    <WorkScheduleDetailPeriodFieldDay
                                        showActions={showActions}
                                        className={{
                                            0: 'ml-3',
                                            6: type === 'FIXED' ? '': 'mr-3'
                                        }[index]}
                                        name={`${name}.${day}`}
                                        form={form}
                                    />
                                </td>
                            ))}

                            {type === 'FIXED' && items.length > 1 && (
                                <aside className="sticky right-0 z-10 flex pt-[13px] pb-[11px] gap-[3.5px] flex-col px-2 bg-white border-t mt-[-1px] shadow-md border-grey">
                                    {items.map((item, index) => (
                                        <ButtonDelete
                                            size='xl'
                                            variant='light'
                                            // sx={{ width: 40, height: 40 }}
                                            key={index}
                                            collapsed
                                            onClick={removePeriod(index)}
                                        />
                                    ))}
                                </aside>

                            )}

                        </tr>
                    </tbody>
                </Table>
            </div>

            <div className='flex justify-between border-t'>
                <div className='flex gap-2 py-2.5 px-4'>
                    <label className='font-semibold'>Total:</label>
                    <span 
                        data-testid='work-schedule-detail-period-field-item-total'>
                        {i18n.t('settingWorkSchedule:detail.totalHours', total)}
                    </span>
                </div>
                {showActions && type === 'FIXED' &&  (
                    <Button
                        variant='subtle'
                        color='blue'
                        size='xs'
                        onClick={addPeriod}
                        data-testid='work-schedule-detail-period-field-add'
                    >
                        {i18n.t('settingWorkSchedule:fields.days.add')}
                    </Button>
                )}
            </div>
        </>
    )
}

export default WorkScheduleDayPeriodsTableFields
