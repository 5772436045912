import { rem,Tabs, TabsProps } from '@mantine/core'
import * as React from 'react'

import splitComponents from '../utils/splitComponents'


const StyledTabs = (props: Omit<TabsProps, 'unstyled' | 'styles'>) => (
    <Tabs
        unstyled
        styles={(theme) => ({
            tab: {
                ...theme.fn.focusStyles(),
                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
                color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[9],
                border: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[4]}`,
                padding: `6px ${theme.spacing.lg}`,
                cursor: 'pointer',
                fontSize: theme.fontSizes.sm,
                display: 'flex',
                alignItems: 'center',
                fontWeight: 600,

                '&:disabled': {
                    opacity: 0.5,
                    cursor: 'not-allowed',
                },

                '&:not(:first-of-type)': {
                    borderLeft: 0,
                },

                '&:first-of-type': {
                    borderTopLeftRadius: theme.radius.xl,
                    borderBottomLeftRadius: theme.radius.xl,
                },

                '&:last-of-type': {
                    borderTopRightRadius: theme.radius.xl,
                    borderBottomRightRadius: theme.radius.xl,
                },

                '&[data-active]': {
                    backgroundColor: theme.colors.primary[6],
                    borderColor: theme.colors.primary[6],
                    color: theme.white,
                },
            },

            tabIcon: {
                marginRight: theme.spacing.xs,
                display: 'flex',
                alignItems: 'center',
            },

            tabsList: {
                display: 'flex',
            },
        })}
        {...props}
    />
)

const FormButtonGroup = (props: Omit<TabsProps, 'unstyled' | 'styles'>) => {
    const { children, ...rest } = props

    const { tabs, others } = React.useMemo(() => splitComponents(children, [
        { name: 'tabs', component: FormButtonGroup.Item },
        { name: 'tabs', component: Tabs.Tab },
    ]), [children])

    return (
        <StyledTabs {...rest}>
            <Tabs.List>
                {tabs}
                {others}
            </Tabs.List>
        </StyledTabs>
    )

}

FormButtonGroup.Item = function(props: React.ComponentPropsWithoutRef<typeof Tabs.Tab>) {
    return <Tabs.Tab data-testid={`form-button-group-item-${props.value}`} {...props} />
}

export default FormButtonGroup