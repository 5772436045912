/* eslint-disable @typescript-eslint/no-explicit-any */
import { ConditionFieldType, ConditionFieldTypes } from '@viterbit/web-app/dataAccess/model/Condition/ConditionBase.model'
import traverse from 'json-schema-traverse'
import * as React from 'react'
import { useMap } from 'react-use'
export type SchemaType = Parameters<typeof traverse>[0]

const getName = (jsonPointer) => {
    if (!jsonPointer) return null
    
    const name = jsonPointer.match(/(\/properties\/([a-zA-Z]*))/g).map(item => item.replace('/properties/', '')).join('.')
    return name
}

export type DynamicField = {
    type: ConditionFieldType
    name: string
    required?: boolean
    default?: any
    additionalProperties?: {
        label?: string | null
        placeholder?: string
    }
    options?: string[]
    pattern?: string
}

type MapDynamicField = {
    [key: string]: DynamicField
}

const useDynamicForm = (schema: SchemaType) => {
    const [map, { set, reset }] = useMap<MapDynamicField>()

    React.useEffect(() => {
        reset()
        schema && traverse(schema, (schema, jsonPointer, rootSchema, parentJson, parentKeyword, parentSchema, key) => {
            const { type, additionalProperties, pattern } = schema
            
            const name = getName(jsonPointer)

            if (!name) {
                return
            }
            
            if (!ConditionFieldTypes.includes(type)) {
                console.error(`Type ${type} is not supported`)
                return
            }


            const required = parentSchema?.required?.includes(name)
            
            set(name, { type, name, additionalProperties, required, pattern })  
        })
    }, [schema])

    const list = React.useMemo(() => Object.values(map), [map])

    return list
}

export default useDynamicForm