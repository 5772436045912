import { Tooltip } from '@mantine/core'
import { IWeekDays } from '@viterbit/web-app/dataAccess/model/Week.model'
import i18n from '@viterbit/web-app/i18n'
import { isSameDate, parseDateUTC } from '@viterbit/web-app/shared/utils/dateParse'
import dayjs from 'dayjs'
import * as React from 'react'

import { RelevantDayType } from './helpers'
import Holiday from './Holiday'
import LeaveFullDay from './LeaveFullDay'
import LeaveHalfDay from './LeaveHalfDay'
import LeavePendingDay from './LeavePendingDay'
import ActionSpan from '../ActionSpan'
import { RelevantDate, StatusType } from '../../types'

const getRelevantDayComponent = (relevantDate: RelevantDate) => {
    const getRelevantComponentName = () => {
        if (relevantDate.isHoliday) return RelevantDayType.Holiday
        if (relevantDate.halfDay) return RelevantDayType.LeaveHalfDay
        if (relevantDate.status === StatusType.PENDING) {
            return RelevantDayType.LeavePendingDay
        }

        return RelevantDayType.LeaveFullDay
    }

    const componentName = getRelevantComponentName()

    const componentDict = {
        [RelevantDayType.Holiday]: <Holiday relevantDate={relevantDate} />,
        [RelevantDayType.LeaveHalfDay]: (
            <LeaveHalfDay relevantDate={relevantDate} />
        ),
        [RelevantDayType.LeavePendingDay]: (
            <LeavePendingDay relevantDate={relevantDate} />
        ),
        [RelevantDayType.LeaveFullDay]: (
            <LeaveFullDay relevantDate={relevantDate} />
        ),
        [RelevantDayType.LeavePendingDay]: (
            <LeavePendingDay relevantDate={relevantDate} />
        ),
    }

    return componentDict[componentName]
}

type RelevantDayProps = {
    date: string
    relevantDates: RelevantDate[]
    year: number
    month: number
    workableDays: IWeekDays[]
    clickable?: boolean
    employeeId: string
}

const RelevantDay = ({
    date,
    relevantDates,
    year,
    month,
    workableDays,
    clickable,
    employeeId,
}: RelevantDayProps) => {
    const day = dayjs(date).format('D')
    const monthDate = new Date(year, month, parseInt(day, 10))
    const isRelevantDate = relevantDates.find((rd) =>
        isSameDate(rd.date, monthDate)
    )

    const dayOfWeek = dayjs(date).format('dddd').toUpperCase() as IWeekDays
    const isNotWorkableDay = !workableDays?.includes(dayOfWeek)
    if (isNotWorkableDay && !isRelevantDate) {
        return <span className='text-gray-400'>{day}</span>
    }

    const content = <span className='flex items-center justify-center w-full h-full'>{day}</span>

    if (!isRelevantDate) {
        return clickable ? (
            <ActionSpan
                employeeId={employeeId}
                initialDate={parseDateUTC(date)}
            >
                <Tooltip
                    label={i18n.t('leaves:relevantDays.requestDay')}
                >
                    {content}
                </Tooltip>
            </ActionSpan>
        ) : (
            content
        )
    }

    const relevantDate = getRelevantDayComponent(isRelevantDate)

    return clickable ? (
        <ActionSpan
            leaveId={isRelevantDate.leaveId}
            employeeId={employeeId}
            initialDate={parseDateUTC(date)}
            status={isRelevantDate.status}
        >
            {relevantDate}
        </ActionSpan>
    ) : (
        relevantDate
    )
}

export default RelevantDay
