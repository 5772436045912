import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import resources from 'virtual:i18next-loader'
import './i18n.d'



declare module 'i18next' {
    interface CustomTypeOptions {
        returnNull: false
    }
}

export type Language = 'es' | 'en'
export const DEFAULT_LANGUAGE: Language = 'en'
export const SUPPORTED_LANGUAGES: Language[] = ['es', 'en']

export const DEFAULT_NAMESPACE = 'common'

export const namespaces = [
    'auth' ,
    'common',
    'dashboard' ,
    'daysSummary' ,
    'employees',
    'emptyState' ,
    'leaves' ,
    'navigation' ,
    'personalArea' ,
    'serverValidation',
    'settingCompanies',
    'settingLeaves',
    'settingLocations',
    'settings',
    'ui',
    'billing',
    'billingAddressForm',
    'validation',
    'settingsComplaints',
    'workflow',
    'integrations',

] as const

export type LocaleNamespaceType = typeof namespaces[number]

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources,
        defaultNS: 'common',
        ns: namespaces,
    })


export default i18n