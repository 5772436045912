import { IMapper } from './__common'
import { RoleResponse } from '../dto/role'
import { Role } from '../model/Role.model'

const roleMapper: IMapper<Role, RoleResponse> = {
    toModel: (dto) => ({
        id: dto.id,
        name: dto.name,
        status: dto.status
    }),
    toDto: (dto) => ({
        id: dto.id,
        name: dto.name,
        status: dto.status
    }),
}

export default roleMapper
