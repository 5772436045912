/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react'
import clsxm from 'ui/src/lib'

import HeaderActions from '../../layout/HeaderActions'
import HeaderActionsTitle from '../../layout/HeaderActions/HeaderActionsTitle'

const STYLES = {
    root: '',
    direction: {
        vertical: 'md:gap-6 md:grid md:grid-cols-12 md:gap-6',
        horizontal: '',
    },
    headerContent: {
        root: 'mb-0',
        direction: {
            vertical: 'col-span-3 pb-4 ',
            horizontal: 'mb-4',
        },
    },
    childrenContent: {
        root: '',
        direction: {
            vertical: 'col-span-9',
            horizontal: '',
        },
    },
}

type AsideCardInfoProps = {
    children?: React.ReactNode
    className?: string
    title?: string
    actions?: any
    description?: string | React.ReactNode
    direction?: 'horizontal' | 'vertical'
    disabled?: boolean
    titleStyle?: boolean
}

const AsideCardInfo = ({
    children,
    title,
    actions,
    direction = 'horizontal',
    description,
    className = '',
    disabled,
    titleStyle,
}: AsideCardInfoProps) => (
    <div
        className={clsxm(
            STYLES.root,
            STYLES.direction[direction],

            className
        )}
    >
        <div
            className={`${STYLES.headerContent.root} ${STYLES.headerContent.direction[direction]}`}
        >
            <HeaderActions className='pb-0'>
                {title && (
                    <HeaderActions.Header
                    >
                        <HeaderActionsTitle>{title}</HeaderActionsTitle>
                    </HeaderActions.Header>
                )}
                {actions && (
                    <HeaderActions.Actions>{actions}</HeaderActions.Actions>
                )}
            </HeaderActions>
            {description && (
                <div className='text-sm text-black '>{description}</div>
            )}
        </div>
        <div
            className={clsxm(
                STYLES.childrenContent.root,
                STYLES.childrenContent.direction[direction],
                disabled && 'pointer-events-none opacity-50'
            )}
        >
            {children}
        </div>
    </div>
)

export default AsideCardInfo
