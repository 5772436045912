/* eslint-disable react/jsx-key */
import Logo from '@viterbit/web-app/shared/display/Logo'
import * as React from 'react'
import { Outlet } from 'react-router-dom'
import SideLayout from 'ui/src/components/SideLayout'

import { useComplaintOrganization } from './useComplaintOrganization'
import Skeleton from 'ui/src/components/Skeleton'
import ComplaintTermsDialog from './terms/ComplaintTermsDialog'
import i18n from '@viterbit/web-app/i18n'
import ComplaintCompanyLogo from './ComplaintCompanyLogo'

const SKELETON = {
    name: <Skeleton className='w-48 h-2 mb-2'/>,
}

const ComplaintLayout = () => {
    const { data, isLoading } = useComplaintOrganization()
    const loading = !data || isLoading

    return (
        <SideLayout
            logo={data && <ComplaintCompanyLogo organization={data} />}
            author={loading ? SKELETON.name : data?.name}
            authorPosition={i18n.t('complaint:layout.subtitle')}
            sidebarContent={
                <>
                    <p className='whitespace-pre-line'>{i18n.t('complaint:layout.description')}</p>
                    <ComplaintTermsDialog />
                </>
            }
        >
            <Outlet />
        </SideLayout>
    )
}


export default ComplaintLayout
