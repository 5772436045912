import { PermissionAction } from '@viterbit/web-app/dataAccess/model/PermissionAction.model'
import { useEmployeeDetail } from '@viterbit/web-app/dataAccess/useCase/employee/useEmployeeDetail'
import LeaveProvider, { LeaveCreateButton } from '@viterbit/web-app/feature/leave/LeaveProvider'
import CalendarHeader from '@viterbit/web-app/feature/leave/list/Calendar/CalendarHeader'
import CalendarView from '@viterbit/web-app/feature/leave/list/CalendarView'
import LeaveClusterList from '@viterbit/web-app/feature/leave/list/LeavesClusters/LeaveClusterList'
import LeaveClusterTabs from '@viterbit/web-app/feature/leave/list/LeavesClusters/LeavesClusterTabs'
import SideView from '@viterbit/web-app/feature/leave/list/SideView'
import DaysSummary from '@viterbit/web-app/feature/leave/list/Summary/DaysSummary'
import SummaryLoading from '@viterbit/web-app/feature/leave/list/Summary/SummaryLoading'
import { LeavesClusters } from '@viterbit/web-app/feature/leave/list/types'
import i18n from '@viterbit/web-app/i18n'
import EmbeddedContent from '@viterbit/web-app/shared/behaviour/EmbeddedContent'
import ForbiddenContent from '@viterbit/web-app/shared/display/ForbiddenContent'
import { useCurrentEmployeeId } from '@viterbit/web-app/shared/employee/useCurrentEmployeeId'
import HeaderActions from '@viterbit/web-app/shared/layout/HeaderActions'
import MultiColumnLayout from '@viterbit/web-app/shared/layout/MultiColumnLayout'
import * as React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ListSkeleton from 'ui/src/components/List/ListSkeleton'

import EmployeeContext from '../employee/EmployeeContext'
import NavigationTabsContentLoading from '../../shared/navigation/Tabs/NavigationTabsContentLoading'

const LeaveView = () => {
    const { hasPermission, basePath } = React.useContext(EmployeeContext)
    const navigate = useNavigate()
    const { year: yearParam } = useParams()
    const employeeId = useCurrentEmployeeId()

    const year = parseInt(yearParam!, 10) || new Date().getFullYear()

    const { data: employee, isLoading } = useEmployeeDetail({
        query: {
            id: employeeId!,
        },
        enabled: !!employeeId
    })

    const hasPermissionToSeeTab = hasPermission(PermissionAction.CORE_HR_LEAVE_LIST, employeeId ?? '')

    React.useEffect(() => {
        !isLoading && !employee && navigate('/employees/list')
        !hasPermissionToSeeTab && navigate(basePath)
    }, [hasPermissionToSeeTab, employee, employeeId])

    if (!employeeId || !employee || !hasPermissionToSeeTab) {
        return <NavigationTabsContentLoading />
    }    

    return (
        <LeaveProvider>
            <EmbeddedContent>
                <HeaderActions.Actions>
                    <React.Suspense fallback={<SummaryLoading />}>
                        <DaysSummary employee={employee} year={year} />
                    </React.Suspense>

                    <ForbiddenContent allow={!employee?.isDeleted}>
                        <LeaveCreateButton />
                    </ForbiddenContent>
                </HeaderActions.Actions>
            </EmbeddedContent>

            <MultiColumnLayout
                left={
                    <SideView
                        topSectionTitle={i18n.t('leaves:pendingForApproval')}
                        topSection={
                            <React.Suspense fallback={<ListSkeleton icon primary secondary />}>
                                <LeaveClusterList
                                    employeeId={employeeId}
                                    year={year}
                                    leaveCluster={LeavesClusters.PENDING}
                                />
                            </React.Suspense>
                        }
                        tabSection={<LeaveClusterTabs />}
                        bottomSection={
                            <React.Suspense fallback={<ListSkeleton icon primary secondary />}>
                                <LeaveClusterList
                                    employeeId={employeeId}
                                    year={year}
                                />
                            </React.Suspense>
                        }
                    />
                }
            >

                <CalendarView
                    header={
                        <CalendarHeader
                            title='Calendar'
                            year={year}
                            nextYearHref={`/employees/list/${employeeId}/leaves/${
                                year + 1
                            }`}
                            previousYearHref={`/employees/list/${employeeId}/leaves/${
                                year - 1
                            }`}
                        />
                    }
                    employeeId={employeeId}
                    year={year}
                />
            </MultiColumnLayout>
        </LeaveProvider>
    )
}

export default LeaveView
