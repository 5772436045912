import { useInfiniteQuery } from '@tanstack/react-query'
import { LOCATION_QUERY_KEY } from '@viterbit/web-app/dataAccess/cacheKeys'
import { locationList } from '@viterbit/web-app/dataAccess/service/locationService'

type locationListConfig = {
    query: Parameters<typeof locationList>[0]
    enabled?: boolean
}

export const useLocationListPaginated = (config?: locationListConfig) => {
    const { query = { page: 1 }, enabled = true } = config || {}

    return useInfiniteQuery(
        [LOCATION_QUERY_KEY, 'list-paginated', query],
        ({ pageParam }) => locationList({ page: pageParam }),
        {
            getNextPageParam: (lastPage) => lastPage ? lastPage.meta.nextPage : 1,
            initialData: () => ({
                pageParams: [1],
                pages: [],
            }),
            enabled,
        }
    )
}

