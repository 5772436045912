
import paginationMetaMapper from '@viterbit/web-app/dataAccess/mapper/paginationMeta.mapper'
import pendingFileMapper from '@viterbit/web-app/dataAccess/mapper/pendingFile.mapper'
import publicEmployeeMapper from '@viterbit/web-app/dataAccess/mapper/publicEmployee.mapper'
import { queryClient } from '@viterbit/web-app/shared/lib/ReactQueryProvider'
import { Configuration, FileApi } from 'viterbit-api-sdk'

import { commonConfig } from './config'
import { PENDING_FILES_QUERY_KEY } from '../useCase/pendingFile/pendingFilesQueryKeys'

const api = new FileApi(new Configuration(commonConfig))

export const pendingFileInvalidate = () =>
    queryClient.invalidateQueries([PENDING_FILES_QUERY_KEY])


export const pendingFileCreate = (body: Parameters<typeof api.uploadPendingFile>[0]) =>
    api.uploadPendingFile(body).then((data) => {
        pendingFileInvalidate()
        return data
    })

export const pendingFileRespond = (body: Parameters<typeof api.respondPendingFile>[0]) =>
    api.respondPendingFile(body).then((data) => {
        pendingFileInvalidate()
        return data
    })

export const pendingFileListPaginated = (data: Parameters<typeof api.listPendingFiles>[0]) =>
    api.listPendingFiles(data).then(response => ({
        meta: paginationMetaMapper(response.meta!),
        data: response.data!.map(pendingFileMapper.toModel),
    }))

export const pendingFileDelete = (body: Parameters<typeof api.deleteFile>[0]) =>
    api.deleteFile(body).then((data) => {
        pendingFileInvalidate()
        return data
    })

export const pendingFileSignedUrl = (data: Parameters<typeof api.signedFileUrl>[0]) =>
    api.signedFileUrl(data)

export const listEmployeesOfPendingFile = (body: Parameters<typeof api.listEmployeesOfPendingFile>[0]) =>
    api.listEmployeesOfPendingFile(body).then(({data, meta}) => ({
        meta: paginationMetaMapper(meta),
        data: data.map(publicEmployeeMapper.toModel)
    }))

export const pendingFileDetail = (body: Parameters<typeof api.findPendingFiles>[0]) =>
    api.findPendingFiles(body).then(pendingFileMapper.toModel)

export const pendingFileBulkInfo = (body: Parameters<typeof api.bulkInfoPendingFile>[0]) =>
    api.bulkInfoPendingFile(body)

    
export const pendingFileAssignBulkInfo = (body: Parameters<typeof api.assignBulkPendingFile>[0]) =>
    api.assignBulkPendingFile(body).then((data) => {
        pendingFileInvalidate()
        return data
    })
export const pendingFileReset = (body: Parameters<typeof api.resetPendingFile>[0]) =>
    api.resetPendingFile(body).then((data) => {
        pendingFileInvalidate()
        return data
    })