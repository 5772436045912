
import { LeavePolicy } from '@viterbit/web-app/dataAccess/model/LeavePolicy.model'
import MarkDefaultButton from '@viterbit/web-app/shared/form/MarkDefaultButton'
import SegmentNavLink from '@viterbit/web-app/shared/navigation/SegmentNavLink'
import * as React from 'react'
import List from 'ui/src/components/List'

export type LeavePoliciesItemType = {
    policy: LeavePolicy
}

const LeavePoliciesItem = (props: LeavePoliciesItemType) => {
    const { policy} = props
    return (
        <SegmentNavLink path={`/settings/policies/${policy.id}/details`}>
            <List.Item clickable>
                <List.ItemText primary={policy.name} secondary={policy.description} />
                <MarkDefaultButton show={policy.default} />
            </List.Item>
        </SegmentNavLink>
    )
}
export default LeavePoliciesItem
