import i18n from '@viterbit/web-app/i18n'
import * as React from 'react'
import Dialog from 'ui/src/components/Dialog'

type DocumentBulkUploadDialogProps = {
    onClose?: () => void
    open: boolean
    children?: React.ReactNode
}

const DocumentBulkUploadDialog = ({
    open,
    onClose,
    children,
}: DocumentBulkUploadDialogProps) => (
    <Dialog
        open={open}
        onClose={onClose}
        title={i18n.t('employees:bulkUpload.bulkAssign.modal.title')}
    >
        <h5 className='flex flex-col gap-2 pb-4 text-sm '>
            <span className='font-normal'>
                {i18n.t('employees:bulkUpload.bulkAssign.modal.description')}
            </span>
        </h5>
        {children}
    </Dialog>
)

export default DocumentBulkUploadDialog
