import { useMutation } from '@tanstack/react-query'

import { leaveDelete } from '../../service/leavesService'

type LeaveDeleteConfig = {
    onSuccess?: () => void
    onError?: () => void
}

export const useLeaveDelete = (config: LeaveDeleteConfig) =>
    useMutation({
        ...config,
        mutationFn: leaveDelete,
        onSuccess: config.onSuccess,
        onError: config.onError,
    })
