import { Account } from '@viterbit/web-app/dataAccess/model/Account.model'
import { refreshToken } from '@viterbit/web-app/dataAccess/service/authService'
import i18n from '@viterbit/web-app/i18n'
import AsideCardInfo from '@viterbit/web-app/shared/display/AsideCardInfo'
import * as React from 'react'
import { useSearchParams } from 'react-router-dom'

import AccountSettingsPasswordForm from './AccountSettingsPasswordForm'
import ProfilePersonalSettingsForm from './AccountSettingsPersonalForm'
import AccountSettingsIntegrations from './Integrations/AccountSettingsIntegrations'

type AccountSettingsDetailProps = {
    account: Account
}

const AccountSettingsDetail = ({ account }: AccountSettingsDetailProps) => {    
    const [params] = useSearchParams()
    const onSuccess = async () => {
        await refreshToken()
    }
    
    return (
        <>
            <div className='space-y-4'>
                <AsideCardInfo
                    title={i18n.t('settings:personalSection.title')}
                    description={i18n.t('settings:personalSection.description')}
                    direction='vertical'
                >
                    <ProfilePersonalSettingsForm account={account} onSuccessUpdate={onSuccess}/>
                </AsideCardInfo>

                <AsideCardInfo
                    title={i18n.t('settings:passwordSection.title')}
                    description={i18n.t('settings:passwordSection.description')}
                    direction='vertical'
                    className='pb-4 border-b'
                >
                    <AccountSettingsPasswordForm account={account} onSuccessUpdate={onSuccess}/>
                </AsideCardInfo>
                {
                    params.get('show_integrations') === '1' && (
                        <AsideCardInfo
                            title={i18n.t('integrations:title')}
                            description={i18n.t('integrations:subtitle')}
                            direction='vertical'
                        >
                            <AccountSettingsIntegrations />
                        </AsideCardInfo>
                    )
                }
                
            </div>
        </>
    )}

export default AccountSettingsDetail
