
import jwtDecode, { JwtPayload } from 'jwt-decode'
import * as React from 'react'

import useInterval from '../behaviour/useInterval'
import { isTokenExpired } from '../utils/token'

export interface JwtTokenHook {
    decode: (token: string) => void
    data?: JwtPayload
    error: unknown
    isExpired: boolean
}

const useJwtToken = (token: string, initialData?: JwtPayload): JwtTokenHook => {
    const [data, setDecoded] = React.useState(initialData ?? undefined)
    const [error, setError] = React.useState(false)
    const [isExpired, setIsExpired] = React.useState(false)
    const [isIntervalRunning, setIsIntervalRunning] = React.useState(false)

    const decode = (token: string) => {
        try {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const decoded: any = jwtDecode(token)
            setDecoded(decoded)
            setError(false)
            setIsExpired(false)
            setIsIntervalRunning(true)
        } catch (error) {
            setDecoded(undefined)
            setError(error)
            setIsExpired(false)
            setIsIntervalRunning(true)
        }
    }

    useInterval(
        () => {
            const isExpired = isTokenExpired(data!.exp!)
            setIsExpired(isExpired)
            isExpired && setIsIntervalRunning(false)
        },
        isIntervalRunning ? 1000 : null
    )

    React.useEffect(() => {
        decode(token)
    }, [token])

    return {
        decode,
        data,
        error,
        isExpired,
    }
}

export default useJwtToken
