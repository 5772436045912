
import { LeaveReason } from '@viterbit/web-app/dataAccess/model/LeaveReason.model'
import { IsNotEmpty, ValidateIf } from '@viterbit/web-app/shared/utils/ClassValidators'
import DateIsGreaterThan from '@viterbit/web-app/shared/utils/decorators/DateIsGreaterThan'

import { DaysRequestsEnum, LeaveFormFields } from './types'

class LeaveValidator implements Omit<LeaveFormFields, 'files'> {
    daysText: string
    @IsNotEmpty()
    initialDate: string
    id: string
    status: string
    @ValidateIf((o) => o.daysOption === DaysRequestsEnum.SeveralDays)
    @IsNotEmpty()
    @DateIsGreaterThan('initialDate')
    endDate?: string | undefined
    comment?: string | undefined

    halfDay?: boolean
    leaveReason?: LeaveReason | undefined
    calendar: { day: string }[]
    daysOption: DaysRequestsEnum
    @IsNotEmpty({
        message: 'required',
    })
    leaveReasonId: string
    @ValidateIf((o) => o.daysOption === DaysRequestsEnum.HalfDay)
    @IsNotEmpty({
        message: 'required',
    })
    turn?: 'MORNING' | 'AFTERNOON'

    files?: string[]
}

export default LeaveValidator
