import { Button } from '@mantine/core'
import { useTimeTrackingApprove } from '@viterbit/web-app/dataAccess/useCase/timeTracking/useTimeTrackingReportApprove'
import { useTimeTrackingDisapprove } from '@viterbit/web-app/dataAccess/useCase/timeTracking/useTimeTrackingReportDisapprove'
import i18n from '@viterbit/web-app/i18n'
import ApiErrorMessage from '@viterbit/web-app/shared/feedback/ApiErrorMessage'
import FormConfirmDialog from '@viterbit/web-app/shared/form/FormConfirmDialog'
import { MRT_RowSelectionState } from 'mantine-react-table'
import * as React from 'react'

type TimeTrackingReview = {
    startDate: string
    endDate: string
    type: 'pending' | 'approved'
}

const getSelectedEmployees = (employees: MRT_RowSelectionState) => {
    const selectedEmployees = Object.keys(employees).reduce<string[]>((acc, key) => {
        if (employees[key]) {
            acc.push(key)
        }
        return acc
    }, [])

    return selectedEmployees
}

export const useTimeTrackingReview = (config: TimeTrackingReview) => {
    const { startDate, endDate, type } = config
    const [employees, setEmployees] = React.useState<MRT_RowSelectionState>({})

    const { mutate: approve, isLoading: approveLoading, error: approveError } = useTimeTrackingApprove()
    const { mutate: disapprove, isLoading: disapproveLoading, error: disapproveError } = useTimeTrackingDisapprove()

    const employeeIds = React.useMemo(() => getSelectedEmployees(employees), [employees])

    React.useEffect(() => {
        setEmployees({})
    }, [type])

    const button = React.useMemo(() => (
        <TimeTrackingTeamReview
            key={type}
            type={type}
            isLoading={approveLoading || disapproveLoading}
            disabled={!Object.values(employees).length}
            error={approveError || disapproveError}
            onApprove={() =>
                approve({
                    approveDayCommand: {
                        employeeIds,
                        startDate,
                        endDate,
                    }
                })}
            onDisapprove={() =>
                disapprove({
                    rejectDayCommand: {
                        employeeIds,
                        startDate,
                        endDate
                    }
                })}
        />
    ), [employees, approveLoading, disapproveLoading, type, startDate, endDate, approveError, disapproveError])

    return {
        button,
        employees,
        setEmployees,
        employeeIds,
    }
}


export const TimeTrackingTeamReview = (props) => {
    const { onApprove, onDisapprove, isLoading, type, disabled, error } = props

    const [open, setOpen] = React.useState(false)

    React.useEffect(() => {
        if (!isLoading && !error) {
            setOpen(false)
        }
    }, [isLoading])
    

    if (disabled){
        return null
    }

    const typeKey = type === 'pending' ? 'approve' : 'disapprove'

    return (
        <>
            <FormConfirmDialog
                isOpen={open}
                onClose={() => setOpen(false)}
                onSubmit={async () => {
                    type === 'pending' ? await onApprove() : await onDisapprove()
                }}
                title={i18n.t(`employees:teamTimeline.review.${typeKey}`)}
                isLoading={isLoading}
                buttonConfirmColor={type === 'pending' ? 'success': 'danger'}
            >
                <ApiErrorMessage error={error} />
            </FormConfirmDialog>

            {type === 'pending' && (
                <Button
                    sx={{ marginTop: 4 }}
                    color='success'
                    disabled={disabled}
                    variant='light'
                    loading={isLoading}
                    onClick={() => setOpen(true)}
                >
                    {i18n.t('employees:teamTimeline.review.approve')}
                </Button>
            )}
            {type === 'approved' && (
                <Button
                    sx={{ marginTop: 4 }}
                    color='danger'
                    disabled={disabled}
                    variant='light'
                    loading={isLoading}
                    onClick={() => setOpen(true)}
                >
                    {i18n.t('employees:teamTimeline.review.disapprove')}
                </Button>
            )}
        </>
    )

}