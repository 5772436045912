/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { IconUpload } from '@tabler/icons-react'
import * as React from 'react'

import FileItem from './FileItem'
import FileList, { FileListProps } from './FileList'
import { IFile } from './sharedTypes'
import { fileToIFile } from './utils'
import Button from '../Button'
import Notification from '../Notification'
import { toast } from '../Toast'
import { clsxm } from '../../lib/clsxm'

const classes = {
    root: '',
    upload: 'mb-4',
    uploadDisabled: 'cursor-not-allowed opacity-60',
    uploadDisabledInput: 'cursor-not-allowed',
    uploadDraggableHeight: {
        minHeight: '7rem',
    },
    uploadDraggable:
        'border-2 relative border-dashed border-gray-300 dark:border-gray-600 mb-0 rounded-lg cursor-pointer flex items-center justify-center p-4 gap-x-2',
    uploadInput: 'absolute inset-0 hidden',
    uploadInputDraggable: 'block opacity-0 w-full cursor-pointer',
}

export type UploadProps = {
    uploadLimit?: number
    draggable?: boolean
    disabled?: boolean
    showList?: boolean
    multiple?: boolean
    accept?: string
    tip?: string | React.ReactNode
    beforeUpload?: (
        newFilesList: IFile[],
        oldFilesList: IFile[]
    ) => string | undefined
    files?: IFile[]
    onChange?: (files: IFile[], e: React.ChangeEvent<HTMLInputElement>) => void
    onFileRemove?: (id: string, file?: IFile) => void
    children?: React.ReactNode
    className?: string
    buttonText?: string
    showToastMsj?: boolean
    onRetryUpload?: (file: IFile) => void
    t?: (key: string) => string
    canDelete?: boolean
} & FileListProps

const Upload = React.forwardRef<HTMLDivElement, UploadProps>((props, ref) => {
    const {
        accept,
        beforeUpload,
        disabled = false,
        draggable = false,
        files = [],
        multiple,
        onChange,
        onFileRemove,
        showList = true,
        tip,
        uploadLimit,
        children,
        className,
        buttonText = props.t?.('upload.button_default_text') || 'Upload',
        showToastMsj,
        onRetryUpload,
        columns,
        t,
        canDelete,
        ...rest
    } = props

    const fileInputField = React.useRef(null)
    const [dragOver, setDragOver] = React.useState(false)

    const triggerMessage = (msg?: any) => {
        showToastMsj &&
            toast.push(
                <Notification type='danger'>
                    {
                        msg ||
                        t?.('upload.errors.upload_failed') || 'Upload Failed!'
                    }
                </Notification>,
                {}
            )
    }

    const onNewFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const _newFiles = Array.from<File>(e.target.files ?? [])
        let result

        const newFiles = _newFiles
            .map(fileToIFile)
            .slice(0, Math.min(uploadLimit ?? _newFiles.length, _newFiles.length))

        if (beforeUpload) {
            result = beforeUpload(newFiles, files)

            if (result) {
                triggerMessage(result)
                return
            }
        }
        onChange?.(newFiles, e)
    }

    const removeFile = (id: string) => onFileRemove?.(id, files.find(x => x.id === id))

    const triggerUpload = (e: any) => {
        if (!disabled) {
            // eslint-disable-next-line @typescript-eslint/no-extra-semi
            ; (fileInputField.current as any)?.click()
        }
        e.stopPropagation()
    }

    const renderChildren = () => {
        if (!draggable && !children) {
            return (
                <Button disabled={disabled} onClick={(e) => e.preventDefault()}>
                    {buttonText}
                </Button>
            )
        }

        return children
    }

    const handleDragLeave = React.useCallback(() => {
        if (draggable) {
            setDragOver(false)
        }
    }, [draggable])

    const handleDragOver = React.useCallback(() => {
        if (draggable && !disabled) {
            setDragOver(true)
        }
    }, [draggable, disabled])

    const handleDrop = React.useCallback(() => {
        if (draggable) {
            setDragOver(false)
        }
    }, [draggable])

    const draggableProp = {
        onDragLeave: handleDragLeave,
        onDragOver: handleDragOver,
        onDrop: handleDrop,
    }

    const draggableEventFeedbackClass = 'border-primary-600'

    const uploadClass = clsxm(
        classes.upload,
        disabled && classes.uploadDisabled && classes.uploadDisabledInput,
        draggable && classes.uploadDraggable,
        draggable && !disabled && `hover:${draggableEventFeedbackClass}`,
        draggable && disabled && 'disabled',
        dragOver && draggableEventFeedbackClass,
    )

    const uploadInputClass = clsxm(
        classes.uploadInput,
        draggable && classes.uploadInputDraggable
    )

    return (
        <div className={className}>
            <div
                ref={ref}
                className={uploadClass}
                {...(draggable ? draggableProp : { onClick: triggerUpload })}
                {...rest}
            >

                <IconUpload size={20} />
                <p className='font-semibold'>
                    <span className='text-gray-800 dark:text-white'>
                        {t?.('upload.drop_files_here') || 'Drop your files here, or'}{' '}
                    </span>
                    <span className='text-blue-500'>{t?.('upload.browse') ||'browse'}</span>
                </p>


                <input
                    data-testid='upload-input'
                    className={uploadInputClass}
                    type='file'
                    ref={fileInputField}
                    onChange={onNewFileUpload}
                    disabled={disabled}
                    multiple={multiple}
                    accept={accept}
                    {...rest}
                />
            </div>

            {showList && !!files.length &&(
                <FileList
                    className='mt-4'
                    columns={columns}
                >
                    {tip}
                    {showList && files.map(file => (
                        <FileItem
                            canDelete={canDelete}
                            key={file.id}
                            file={file}
                            onDelete={({ id }) => removeFile(id)}
                            t={t}
                        />
                    ))
                    }
                    {renderChildren()}
                </FileList>
            )}
        </div>
    )
})

export default Upload
