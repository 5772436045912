
import { classValidatorResolver } from '@hookform/resolvers/class-validator'
import { accountInvite } from '@viterbit/web-app/dataAccess/service/accountService'
import i18n from '@viterbit/web-app/i18n'
import Form from '@viterbit/web-app/shared/form/Form'
import FormSelectRole from '@viterbit/web-app/shared/form/FormSelectRole'
import SubmitButton from '@viterbit/web-app/shared/form/SubmitButton'

import { EmployeeProfileSendInvitationValidator } from './EmployeeProfileSendInvitationValidator'

type EmployeeInvitationViewProps = {
    onSuccess?: (data) => void
    employeeId: string
}

export type EmployeeProfileSendInvitationFields = {
    roleId: string,
}

const resolver = classValidatorResolver(EmployeeProfileSendInvitationValidator)

const EmployeeProfileSendInvitationForm = ({
    employeeId,
    onSuccess,
}: EmployeeInvitationViewProps) => (
    <Form<EmployeeProfileSendInvitationFields>
        withTransition
        mutationConfig={{
            mutationKey: ['send-invitation-employee'],
            mutationFn: ({ roleId }) => accountInvite({inviteCommand: {
                employeeId,
                roleId
            }}),
            onSuccess,
        }}
        formConfig={{
            resolver,
            mode: 'all'
        }}
    >
        {(form) => (
            <>
                <FormSelectRole
                    name='roleId'
                    control={form.control}
                    asterisk
                    label={i18n.t('employees:employee.profile.sendInvitation.dialog.form.role.label')}
                    placeholder={i18n.t('employees:employee.profile.sendInvitation.dialog.form.role.placeholder')}
                />

                <SubmitButton>
                    {i18n.t('employees:employee.profile.sendInvitation.dialog.button')}
                </SubmitButton>
            </>
        )}
    </Form>

)

export default EmployeeProfileSendInvitationForm