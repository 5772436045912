import { LeaveContext } from '@viterbit/web-app/feature/leave/LeaveProvider'
import * as React from 'react'

import { DaysRequestsEnum } from '../Forms/types'

type ActionSpanProps = {
    initialDate: string
    employeeId: string
    leaveId?: string
    children: React.ReactNode
    status?: string
}

const ActionSpan = ({ initialDate, children, employeeId, leaveId, status }: ActionSpanProps) => {
    const { onItemClicked } = React.useContext(LeaveContext)

    const handleOnClick = () => {
        onItemClicked(employeeId, {
            id: leaveId,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            initialDate: new Date(initialDate) as any,
            daysOption: DaysRequestsEnum.OneDay,
            status,
        })
    }

    return (
        <span className='flex items-center justify-center w-full h-full' onClick={handleOnClick}>
            {children}
        </span>
    )
}
export default ActionSpan
