/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateOrganizationCommand
 */
export interface UpdateOrganizationCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationCommand
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationCommand
     */
    domain?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationCommand
     */
    terms?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationCommand
     */
    logoFileId?: string | null;
}

/**
 * Check if a given object implements the UpdateOrganizationCommand interface.
 */
export function instanceOfUpdateOrganizationCommand(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateOrganizationCommandFromJSON(json: any): UpdateOrganizationCommand {
    return UpdateOrganizationCommandFromJSONTyped(json, false);
}

export function UpdateOrganizationCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateOrganizationCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'domain': !exists(json, 'domain') ? undefined : json['domain'],
        'terms': !exists(json, 'terms') ? undefined : json['terms'],
        'logoFileId': !exists(json, 'logoFileId') ? undefined : json['logoFileId'],
    };
}

export function UpdateOrganizationCommandToJSON(value?: UpdateOrganizationCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'domain': value.domain,
        'terms': value.terms,
        'logoFileId': value.logoFileId,
    };
}

