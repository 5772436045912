import { useLocationDetail } from '@viterbit/web-app/dataAccess/useCase/location/useLocationDetail'
import { useParams } from 'react-router-dom'

export const useLocation = () => {
    const { locationId } = useParams()

    const response = useLocationDetail({
        query: {
            id: locationId!
        },
        enabled: !!locationId
    })

    return response
}
    