/* eslint-disable @typescript-eslint/no-explicit-any */
import { clsxm } from '@viterbit/web-app/shared/utils/clsxm'
import * as React from 'react'

const classes = {
    root: 'flex gap-x-12 flex-wrap gap-y-4 mt-4 md:mt-0 empty:hidden',
}

type HeaderActionsActionsProps = {
    id?: string
    children?: React.ReactNode
    className?: string
    ref?: React.Ref<any>
}

const HeaderActionsActions = React.forwardRef(
    ({ children, className, id }: HeaderActionsActionsProps, ref) => (
        <aside
            id={id}
            ref={ref as any}
            className={clsxm(classes.root, className)}
        >
            {children}
        </aside>
    )
)

export default HeaderActionsActions
