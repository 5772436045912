/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useLeavePolicyDetail } from '@viterbit/web-app/dataAccess/useCase/leavePolicy/useLeavePolicyDetail'
import { useParams } from 'react-router-dom'

export const useLeavePolicy = () => {
    const { id } = useParams()

    const response = useLeavePolicyDetail({
        query: {
            id: id!
        },
        enabled: !!id
    })

    return response
}
    