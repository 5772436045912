/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { IconNoteOff } from '@tabler/icons-react'
import { ComplaintStatus } from '@viterbit/web-app/dataAccess/model/Complaint.model'
import { useComplaintPrivateListPaginated } from '@viterbit/web-app/dataAccess/useCase/complaint/useComplaintPrivateListPaginated'
import i18n from '@viterbit/web-app/i18n'
import { useOnScreenElement } from '@viterbit/web-app/shared/behaviour/useOnScreenElement'
import EmptyState from '@viterbit/web-app/shared/display/EmptyState/EmptyState'
import BreakpointRedirection from '@viterbit/web-app/shared/navigation/BreakpointRedirection'
import * as React from 'react'
import List from 'ui/src/components/List'
import ListSkeleton from 'ui/src/components/List/ListSkeleton'

import ComplaintListItem from './ComplaintListItem'

export type ComplaintListViewProps = {
    children?: React.ReactNode
    activeTab: typeof ComplaintStatus[number]
}

const ComplaintList = ({ children, activeTab }: ComplaintListViewProps) => {
    const { ref } = useOnScreenElement({
        onVisible: () => {
            hasNextPage && fetchNextPage()
        }
    })
    
    const {
        data,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isFetched,
    } = useComplaintPrivateListPaginated({
        query: { status: activeTab },
        enabled: true
    })

    const firstComplaint = React.useMemo(() => data?.pages[0]?.data[0], [data])

    const lastPage = data?.pages[data.pages.length - 1]

    return (
        <>
            {firstComplaint && <BreakpointRedirection matcherPath="/core-hr/complaints" redirectPath={`/core-hr/complaints/${firstComplaint?.id}/details`} />}            

            <List data-testid="complaint-list" className=''>
                {data!.pages.map(({ data: _data }) =>
                    _data.map((complaint) => (
                        <ComplaintListItem key={complaint.id} complaint={complaint} />
                    ))
                )}
            </List>

            {!firstComplaint && isFetched && (
                <EmptyState
                    image={<IconNoteOff size={64} stroke={1} />}
                    className='my-20'
                    title={i18n.t(`complaint:list:emptyState.${activeTab}.title`)}
                    description={i18n.t(`complaint:list:emptyState.${activeTab}.description`)}
                />
            )}

            {isFetchingNextPage || !isFetched && (
                <ListSkeleton count={lastPage?.meta.nextPageTotal} />
            )}
        
            {children}

            <div ref={ref} />
        </>
    )
}

export default ComplaintList
