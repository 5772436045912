/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AccountOrderByQueryParam,
  ChangePasswordCommand,
  ChangeRoleCommand,
  CreateAccountCommand,
  FilterGroups,
  FindAccountQueryResponse,
  FindMeAccountQueryResponse,
  InviteCommand,
  ListAccountQueryResponse,
  ListAllAccountQueryResponse,
  RedeemInviteCommand,
  ResendInvitationCommand,
  ResourceCreated,
  ResourceDeleted,
  UpdateAccountCommand,
} from '../models/index';
import {
    AccountOrderByQueryParamFromJSON,
    AccountOrderByQueryParamToJSON,
    ChangePasswordCommandFromJSON,
    ChangePasswordCommandToJSON,
    ChangeRoleCommandFromJSON,
    ChangeRoleCommandToJSON,
    CreateAccountCommandFromJSON,
    CreateAccountCommandToJSON,
    FilterGroupsFromJSON,
    FilterGroupsToJSON,
    FindAccountQueryResponseFromJSON,
    FindAccountQueryResponseToJSON,
    FindMeAccountQueryResponseFromJSON,
    FindMeAccountQueryResponseToJSON,
    InviteCommandFromJSON,
    InviteCommandToJSON,
    ListAccountQueryResponseFromJSON,
    ListAccountQueryResponseToJSON,
    ListAllAccountQueryResponseFromJSON,
    ListAllAccountQueryResponseToJSON,
    RedeemInviteCommandFromJSON,
    RedeemInviteCommandToJSON,
    ResendInvitationCommandFromJSON,
    ResendInvitationCommandToJSON,
    ResourceCreatedFromJSON,
    ResourceCreatedToJSON,
    ResourceDeletedFromJSON,
    ResourceDeletedToJSON,
    UpdateAccountCommandFromJSON,
    UpdateAccountCommandToJSON,
} from '../models/index';

export interface ChangePasswordRequest {
    id: string;
    changePasswordCommand?: ChangePasswordCommand;
}

export interface ChangeRoleRequest {
    id: string;
    changeRoleCommand?: ChangeRoleCommand;
}

export interface CreateAccountRequest {
    createAccountCommand?: CreateAccountCommand;
}

export interface DeleteAccountRequest {
    id: string;
}

export interface FetchAccountsRequest {
    page?: number;
}

export interface FetchAllAccountsRequest {
    search?: string;
    page?: number;
    pageSize?: number;
    filters?: FilterGroups;
    orderBy?: AccountOrderByQueryParam;
    include?: Array<FetchAllAccountsIncludeEnum>;
}

export interface FindAccountsRequest {
    id: string;
}

export interface InviteAccountRequest {
    inviteCommand?: InviteCommand;
}

export interface RedeemInviteAccountRequest {
    redeemInviteCommand?: RedeemInviteCommand;
}

export interface ResendInvitationAccountRequest {
    resendInvitationCommand?: ResendInvitationCommand;
}

export interface UpdateAccountRequest {
    id: string;
    updateAccountCommand?: UpdateAccountCommand;
}

/**
 * 
 */
export class AccountApi extends runtime.BaseAPI {

    /**
     * Change password
     * Change password
     */
    async changePasswordRaw(requestParameters: ChangePasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changePassword.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/accounts/{id}/change-password`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChangePasswordCommandToJSON(requestParameters.changePasswordCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Change password
     * Change password
     */
    async changePassword(requestParameters: ChangePasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.changePasswordRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Change role
     * Change role
     */
    async changeRoleRaw(requestParameters: ChangeRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeRole.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/accounts/{id}/change-role`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeRoleCommandToJSON(requestParameters.changeRoleCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Change role
     * Change role
     */
    async changeRole(requestParameters: ChangeRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.changeRoleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create an account
     * Create an account
     */
    async createAccountRaw(requestParameters: CreateAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/accounts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateAccountCommandToJSON(requestParameters.createAccountCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Create an account
     * Create an account
     */
    async createAccount(requestParameters: CreateAccountRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.createAccountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete account by its id
     * Delete account
     */
    async deleteAccountRaw(requestParameters: DeleteAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceDeleted>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteAccount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/accounts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceDeletedFromJSON(jsonValue));
    }

    /**
     * Delete account by its id
     * Delete account
     */
    async deleteAccount(requestParameters: DeleteAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceDeleted> {
        const response = await this.deleteAccountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch all account for the given organization
     * List accounts
     */
    async fetchAccountsRaw(requestParameters: FetchAccountsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListAccountQueryResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/accounts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListAccountQueryResponseFromJSON(jsonValue));
    }

    /**
     * Fetch all account for the given organization
     * List accounts
     */
    async fetchAccounts(requestParameters: FetchAccountsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListAccountQueryResponse> {
        const response = await this.fetchAccountsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch all accounts
     * List all accounts
     */
    async fetchAllAccountsRaw(requestParameters: FetchAllAccountsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListAllAccountQueryResponse>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.include) {
            queryParameters['include'] = requestParameters.include;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/accounts/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListAllAccountQueryResponseFromJSON(jsonValue));
    }

    /**
     * Fetch all accounts
     * List all accounts
     */
    async fetchAllAccounts(requestParameters: FetchAllAccountsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListAllAccountQueryResponse> {
        const response = await this.fetchAllAccountsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * find account
     * find accounts
     */
    async findAccountsRaw(requestParameters: FindAccountsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAccountQueryResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling findAccounts.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/accounts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAccountQueryResponseFromJSON(jsonValue));
    }

    /**
     * find account
     * find accounts
     */
    async findAccounts(requestParameters: FindAccountsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAccountQueryResponse> {
        const response = await this.findAccountsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * find me account
     * find me account
     */
    async findMeAccountRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindMeAccountQueryResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/accounts/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindMeAccountQueryResponseFromJSON(jsonValue));
    }

    /**
     * find me account
     * find me account
     */
    async findMeAccount(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindMeAccountQueryResponse> {
        const response = await this.findMeAccountRaw(initOverrides);
        return await response.value();
    }

    /**
     * Invite a employee to promote to account
     * Invite a employee to promote to account
     */
    async inviteAccountRaw(requestParameters: InviteAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/accounts/invite`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InviteCommandToJSON(requestParameters.inviteCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Invite a employee to promote to account
     * Invite a employee to promote to account
     */
    async inviteAccount(requestParameters: InviteAccountRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.inviteAccountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Redeem an invitation to promote to account
     */
    async redeemInviteAccountRaw(requestParameters: RedeemInviteAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/accounts/redeem-invite`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RedeemInviteCommandToJSON(requestParameters.redeemInviteCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Redeem an invitation to promote to account
     */
    async redeemInviteAccount(requestParameters: RedeemInviteAccountRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.redeemInviteAccountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Resend invitation a employee to promote to account
     * Resend invitation a employee to promote to account
     */
    async resendInvitationAccountRaw(requestParameters: ResendInvitationAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/accounts/resend-invitation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResendInvitationCommandToJSON(requestParameters.resendInvitationCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Resend invitation a employee to promote to account
     * Resend invitation a employee to promote to account
     */
    async resendInvitationAccount(requestParameters: ResendInvitationAccountRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.resendInvitationAccountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update an account
     * Update an account
     */
    async updateAccountRaw(requestParameters: UpdateAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateAccount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/accounts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateAccountCommandToJSON(requestParameters.updateAccountCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Update an account
     * Update an account
     */
    async updateAccount(requestParameters: UpdateAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.updateAccountRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const FetchAllAccountsIncludeEnum = {
    Role: 'role',
    Employee: 'employee',
    Company: 'company',
    Organization: 'organization'
} as const;
export type FetchAllAccountsIncludeEnum = typeof FetchAllAccountsIncludeEnum[keyof typeof FetchAllAccountsIncludeEnum];
