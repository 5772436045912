import { IntegrationType } from '@viterbit/web-app/dataAccess/model/Integration.model'
import { IntegrationOptionGroup } from '@viterbit/web-app/dataAccess/model/IntegrationOption.model'
import { useSearchParams } from 'react-router-dom'

type Response = {
    type: IntegrationType
    group: IntegrationOptionGroup
    success: boolean
    errorCode: string | null
}

const useIntegrationFeedbackResponse = (): Response => {
    const [params] = useSearchParams()
    return {
        type: params.get('integration_type') as IntegrationType,
        group: params.get('integration_kind') as unknown as IntegrationOptionGroup,
        success: params.get('success') === '1',
        errorCode: params.get('error_code')
    }
}

export default useIntegrationFeedbackResponse