import { useMutation } from '@tanstack/react-query'

import { workScheduleUnassign } from '../../service/workScheduledService'

type DeleteConfig = {
    onSuccess?: () => void
    onError?: () => void
}

export const useWorkScheduleUnassign = (config: DeleteConfig = {}) =>
    useMutation({
        ...config,
        mutationFn: workScheduleUnassign,
        onSuccess: config.onSuccess,
        onError: config.onError,
    })
