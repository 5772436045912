/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import dayjs from 'dayjs'
import * as React from 'react'

import BasePicker from './BasePicker'
import { capitalize } from './utils/utils'
import Calendar from '../Calendar'
import { DayProps } from '../Calendar/sharedTypes'
import useControllableState from '../../hooks/useControllableState'
import useMergedRef from '../../hooks/useMergedRef'

const DEFAULT_INPUT_FORMAT = 'YYYY-MM-DD'

export type DatePickerProps = {
    className?: string
    clearable?: boolean
    clearButton?: React.ReactNode
    closePickerOnChange?: boolean
    dateViewCount?: number
    dayClassName?: (date?: Date, dayProps?: DayProps) => string | string
    dayStyle?: (date?: Date, dayProps?: DayProps) => React.CSSProperties
    defaultMonth?: Date
    defaultOpen?: boolean
    defaultValue?: Date
    defaultView?: 'month' | 'year'
    disabled?: boolean
    disableDate?: (date: Date) => boolean
    enableHeaderLabel?: boolean
    disableOutOfMonth?: boolean
    firstDayOfWeek?: 'monday' | 'sunday'
    hideOutOfMonthDates?: boolean
    hideWeekdays?: boolean
    inputFormat?: string
    inputPrefix?: React.ReactNode
    inputSuffix?: React.ReactNode
    inputtable?: boolean
    labelFormat?:
        | string
        | {
              month: string
              year: string
          }
    locale?: 'en' | 'es'
    maxDate?: Date
    minDate?: Date
    name?: string
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
    onChange?: (date: Date) => void
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
    onDropdownClose?: () => void
    onDropdownOpen?: () => void
    openPickerOnClear?: boolean
    renderDay?: (value: Date | undefined) => React.ReactNode
    size?: 'sm' | 'md' | 'lg'
    style?: React.CSSProperties
    type?: 'date' | 'datetime'
    value?: Date
    weekendDays?: number[]
    yearLabelFormat?: string
    placeholder?: string
    openCalendarOnFocus?: boolean
}

const DatePicker = React.forwardRef<HTMLInputElement, DatePickerProps>(
    (props, ref) => {
        const {
            className,
            clearable = true,
            clearButton,
            closePickerOnChange = true,
            dateViewCount,
            dayClassName,
            dayStyle,
            defaultMonth,
            defaultOpen,
            defaultValue,
            defaultView,
            disabled,
            disableDate,
            enableHeaderLabel,
            disableOutOfMonth,
            firstDayOfWeek = 'monday',
            hideOutOfMonthDates,
            hideWeekdays,
            inputFormat,
            inputPrefix,
            inputSuffix,
            inputtable,
            labelFormat,
            locale = 'es',
            maxDate,
            minDate,
            name,
            onBlur,
            onChange,
            onFocus,
            onDropdownClose,
            onDropdownOpen,
            openPickerOnClear,
            renderDay,
            size,
            style,
            type,
            value,
            weekendDays,
            yearLabelFormat,
            openCalendarOnFocus,
            ...rest
        } = props

        const dateFormat =
            type === 'date'
                ? DEFAULT_INPUT_FORMAT
                : inputFormat || DEFAULT_INPUT_FORMAT

        const [dropdownOpened, setDropdownOpened] = React.useState(defaultOpen)

        const inputRef = React.useRef()

        const [lastValidValue, setLastValidValue] = React.useState(
            defaultValue ?? null
        )

        const [_value, setValue] = useControllableState({
            prop: value,
            defaultProp: defaultValue as any,
            onChange,
        })

        const [calendarMonth, setCalendarMonth] = React.useState(
            _value || defaultMonth || new Date()
        )

        const [focused, setFocused] = React.useState(false)

        const [inputState, setInputState] = React.useState(
            _value instanceof Date
                ? capitalize(dayjs(_value).locale(locale).format(dateFormat))
                : ''
        )

        const closeDropdown = () => {
            setDropdownOpened(false)
            onDropdownClose?.()
        }

        const openDropdown = () => {
            setDropdownOpened(true)
            onDropdownOpen?.()
        }

        React.useEffect(() => {
            if (value === null && !focused) {
                setInputState('')
            }

            if (value instanceof Date && !focused) {
                setInputState(
                    capitalize(dayjs(value).locale(locale).format(dateFormat))
                )
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [value, focused])

        const handleValueChange = (date: any) => {
            setValue(date)
            setInputState(
                capitalize(dayjs(date).locale(locale).format(dateFormat))
            )
            closePickerOnChange && closeDropdown()
            if (!openCalendarOnFocus && !date) {
                window.setTimeout(() => (inputRef.current as any)?.focus(), 0)
            }
        }

        const handleClear = () => {
            setValue(null as any)
            setLastValidValue(null)
            setInputState('')
            openPickerOnClear && openDropdown()
            const reference = inputRef.current as any
            reference?.focus()
        }

        const parseDate = (date: any) =>
            dayjs(date, dateFormat, locale).toDate()

        const setDateFromInput = () => {
            let date = typeof _value === 'string' ? parseDate(_value) : _value

            if (maxDate && dayjs(date).isAfter(maxDate)) {
                date = maxDate
            }

            if (minDate && dayjs(date).isBefore(minDate)) {
                date = minDate
            }

            if (dayjs(date).isValid()) {
                setValue(date)
                setLastValidValue(date)
                setInputState(
                    capitalize(dayjs(date).locale(locale).format(dateFormat))
                )
                setCalendarMonth(date)
            } else {
                setValue(lastValidValue as any)
            }
        }

        const handleInputBlur = (event: any) => {
            typeof onBlur === 'function' && onBlur(event as any)
            setFocused(false)

            if (inputtable) {
                setDateFromInput()
            }
        }

        const handleKeyDown = (event: any) => {
            if (event.key === 'Enter' && inputtable) {
                closeDropdown()
                setDateFromInput()
            }
        }

        const handleInputFocus = (event: any) => {
            typeof onFocus === 'function' && onFocus(event)
            if (openCalendarOnFocus) {
                setDropdownOpened(true)
            }
            setFocused(true)
        }

        const handleChange = (event: any) => {
            openDropdown()

            const date = parseDate(event.target.value)
            if (dayjs(date).isValid()) {
                setValue(date)
                setLastValidValue(date)
                setInputState(event.target.value)
                setCalendarMonth(date)
            } else {
                setInputState(event.target.value)
            }
        }

        return (
            <BasePicker
                inputtable={inputtable}
                dropdownOpened={dropdownOpened}
                setDropdownOpened={setDropdownOpened}
                ref={useMergedRef(ref, inputRef)}
                size={size}
                style={style}
                className={className}
                onChange={handleChange}
                onBlur={handleInputBlur}
                onFocus={handleInputFocus}
                onKeyDown={handleKeyDown}
                name={name}
                inputLabel={inputState}
                clearable={
                    type === 'date' ? false : clearable && !!_value && !disabled
                }
                clearButton={clearButton}
                onClear={handleClear}
                disabled={disabled}
                onDropdownClose={onDropdownClose}
                onDropdownOpen={onDropdownOpen}
                type={type}
                inputPrefix={inputPrefix}
                inputSuffix={inputSuffix}
                {...rest}
            >
                <Calendar
                    locale={locale}
                    month={inputtable ? calendarMonth : undefined}
                    defaultMonth={
                        defaultMonth ||
                        minDate ||
                        (_value instanceof Date ? _value : new Date())
                    }
                    onMonthChange={setCalendarMonth}
                    value={
                        _value instanceof Date
                            ? _value
                            : _value && dayjs(_value).toDate()
                    }
                    onChange={handleValueChange}
                    labelFormat={labelFormat}
                    dayClassName={dayClassName}
                    dayStyle={dayStyle}
                    disableOutOfMonth={disableOutOfMonth}
                    minDate={minDate}
                    maxDate={maxDate}
                    disableDate={disableDate}
                    firstDayOfWeek={firstDayOfWeek}
                    preventFocus={inputtable}
                    dateViewCount={dateViewCount}
                    enableHeaderLabel={enableHeaderLabel}
                    defaultView={defaultView}
                    hideOutOfMonthDates={hideOutOfMonthDates}
                    hideWeekdays={hideWeekdays}
                    renderDay={renderDay}
                    weekendDays={weekendDays}
                    yearLabelFormat={yearLabelFormat}
                />
            </BasePicker>
        )
    }
)

export default DatePicker
