/* eslint-disable @typescript-eslint/no-non-null-assertion */
import SimpleCard from '@viterbit/web-app/shared/display/SimpleCard'
import MultiColumnLayout from '@viterbit/web-app/shared/layout/MultiColumnLayout'
import SectionLayout from '@viterbit/web-app/shared/layout/SectionLayout'
import React from 'react'


type TwoColumnsLayoutProps = {
    title?: string
    leftTitle?: React.ReactNode
    leftActions?: React.ReactNode
    actions?: React.ReactNode
} & Pick<React.ComponentProps<typeof MultiColumnLayout>, 'left' | 'children'>

const TwoColumnsLayout =  ({ title, left, children, leftActions, leftTitle, actions }: TwoColumnsLayoutProps) => (
    <SectionLayout
        title={title}
        actions={actions}
    >
        <MultiColumnLayout
            left={<SimpleCard title={leftTitle} actions={leftActions}>{left}</SimpleCard>}
        >
            <SimpleCard className='p-4'>
                {children}
            </SimpleCard>
        </MultiColumnLayout>
    </SectionLayout>
)

export default TwoColumnsLayout
