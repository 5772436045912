import dayjs from 'dayjs'

const formatYear = (year: number, format?: string): string =>
    dayjs(new Date(year, 1, 1)).format(format)

const getEndOfWeek = (date: Date | string, firstDayOfWeek = 'monday'): Date => {
    const value = new Date(date)
    const day = value.getDay()
    const isSunday = firstDayOfWeek === 'sunday'

    const clampToLastDay = 7 - (isSunday ? day + 1 : day)

    if ((isSunday && day !== 6) || day !== 0) {
        value.setDate(value.getDate() + clampToLastDay)
    }
    return value
}

const getStartOfWeek = (
    date: Date | string,
    firstDayOfWeek = 'monday'
): Date => {
    const value = new Date(date)
    const day = value.getDay() || 7
    const isSunday = firstDayOfWeek === 'sunday'

    const clampToFirstDay = isSunday ? day : day - 1

    if ((isSunday && day !== 0) || day !== 1) {
        value.setHours(-24 * clampToFirstDay)
    }

    return value
}

const getWeekdaysNames = (
    locale: string,
    firstDayOfWeek = 'monday',
    format = 'dd'
): string[] => {
    const names: string[] = []
    const date = getStartOfWeek(new Date(), firstDayOfWeek)
    for (let i = 0; i < 7; i += 1) {
        names.push(dayjs(date).locale(locale).format(format))
        date.setDate(date.getDate() + 1)
    }

    return names
}

const isWeekend = (date: Date, weekendDays = [0, 6]): boolean =>
    weekendDays.includes(date.getDay())

const getMonthDays = (date: Date, firstDayOfWeek = 'monday'): Date[][] => {
    const currentMonth = date.getMonth()
    const startOfMonth = new Date(date.getFullYear(), currentMonth, 1)
    const endOfMonth = new Date(date.getFullYear(), currentMonth + 1, 0)

    const startOfWeek = getStartOfWeek(startOfMonth, firstDayOfWeek)
    const endOfWeek = getEndOfWeek(endOfMonth, firstDayOfWeek)

    const weeks: Date[][] = []

    while (startOfWeek <= endOfWeek) {
        const days: Date[] = []

        for (let i = 0; i < 7; i += 1) {
            days.push(new Date(startOfWeek))
            startOfWeek.setDate(startOfWeek.getDate() + 1)
        }

        weeks.push(days)
    }

    return weeks
}

type monthRange = {
    date: Date
    minDate?: Date
    maxDate?: Date
}

const isMonthInRange = ({ date, minDate, maxDate }: monthRange): boolean => {
    const hasMinDate = minDate instanceof Date
    const hasMaxDate = maxDate instanceof Date

    if (!hasMaxDate && !hasMinDate) {
        return true
    }

    const endOfMonth = dayjs(date).endOf('month')
    const startOfMonth = dayjs(date).startOf('month')
    const maxInRange = hasMaxDate ? startOfMonth.isBefore(maxDate) : true
    const minInRange = hasMinDate ? endOfMonth.isAfter(minDate) : true
    return maxInRange && minInRange
}

const isSameMonth = (dateA: Date, dateB: Date): boolean =>
    dateA.getFullYear() === dateB.getFullYear() &&
    dateA.getMonth() === dateB.getMonth()

const isSameDate = (dateA: Date, dateB: Date): boolean =>
    isSameMonth(dateA, dateB) && dateA.getDate() === dateB.getDate()

const MIN_YEAR = 1900
const MAX_YEAR = 2100

const noop = () => false

export {
    formatYear,
    getEndOfWeek,
    getMonthDays,
    getStartOfWeek,
    getWeekdaysNames,
    isMonthInRange,
    isSameDate,
    isSameMonth,
    isWeekend,
    MAX_YEAR,
    MIN_YEAR,
    noop,
}
