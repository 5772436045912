/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
/* eslint-disable turbo/no-undeclared-env-vars */
/* eslint-disable @typescript-eslint/ban-types */
import i18n from '@viterbit/web-app/i18n'
import throwException from '@viterbit/web-app/shared/utils/throwException'
import { isObject } from 'class-validator'
import { Api } from 'types/Api'

type MappedErrors = Object | null

const getFieldsErrorsJSON = (response: Api.ResponseError): MappedErrors => {
    try {
        if (response.status !== 400) {
            return null
        }
        
        if (!response.data) {
            throwException('Response has not body')
            return null
        }
        
        if (!isObject(response.data)) {
            throwException('Response has not JSON body')
            return null
        }
        
        const { errorData } = response.data
        
        if (!errorData) {
            throwException('Response has not errorData field')
            return null
        }
        
        if (!Array.isArray(errorData)) {
            throwException('Error data is not an array')
            return errorData
        }
        
        const mappedErrors = errorData.reduce<any>((mappedErrors, error) => {
            Object.entries(error as any).forEach(([field, message]) => {
                if (!message || typeof message !== 'object') {
                    throwException('Error message is not an object')
                }
                
                const value = Object.keys(message as any)[0]
                
                if (!value) {
                    throwException('Error message is empty')
                }
                
                mappedErrors[field] = i18n.t(`serverValidation:${value}`)
            })
            
            return mappedErrors
        }, {})
        
        return mappedErrors
    }
    catch (error) {
        if (import.meta.env.VITE_THROW_EXCEPTION_ON_ERROR === 'true') {
            throw error
        } else {
            console.error(error)
        } 

        return null
    }
}
    
export default getFieldsErrorsJSON
    