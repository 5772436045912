import { useQuery } from '@tanstack/react-query'
import { FetchEmployeeIncludeEnum } from 'viterbit-api-sdk'

import { EMPLOYEE_QUERY_KEY } from '../../cacheKeys'
import { employeeDetail } from '../../service/employeeService'

type EmployeeDetailConfig = {
    query: Parameters<typeof employeeDetail>[0]
    enabled?: boolean
}

const defaultInclude: FetchEmployeeIncludeEnum[] = [
    'account',
    'address',
    'avatar',
    'bankInformation',
    'company',
    'contractInformation',
    'fiscalInformation',
    'idFile',
    'jobPosition',
    'jobPositionLevel',
    'leaveAdjustments',
    'leavePolicy',
    'location',
    'manager',
    'personalInformation',
    'emergencyInformation',
    'deleteInformation',
    'workSchedules',
] 

export const useEmployeeDetail = ({ query, enabled }: EmployeeDetailConfig) => {
    const payload = {
        ...query,
        include: query.include || defaultInclude,
    }

    return useQuery({
        queryFn: () => employeeDetail(payload), 
        queryKey: [EMPLOYEE_QUERY_KEY, 'detail', payload],
        enabled
    })
}

