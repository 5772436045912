
import TwoColumnsLayout from '@viterbit/web-app/shared/layout/TwoColumnsLayout'
import { Outlet } from 'react-router-dom'

import ComplaintListView from './list/ComplaintListView'

const ComplaintView =  () => (
    <>
        <TwoColumnsLayout
            left={<ComplaintListView />}
        >
            <Outlet />
        </TwoColumnsLayout>
    </>
)

export default ComplaintView
