import * as React from 'react'

import DropdownComp, { DropdownProps } from './Dropdown'
import DropdownItem, { DropdownItemProps } from './DropdownItem'
import DropdownMenu, { DropdownMenuProps } from './DropdownMenu'

type DropdownCmp = React.FunctionComponent<DropdownProps> & {
    Item: React.FunctionComponent<DropdownItemProps>
    Menu: React.FunctionComponent<DropdownMenuProps>
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Dropdown: DropdownCmp = DropdownComp as any
Dropdown.Item = DropdownItem
Dropdown.Menu = DropdownMenu

export default Dropdown
