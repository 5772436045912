/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeleteEmployeeCommand
 */
export interface DeleteEmployeeCommand {
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeCommand
     */
    endDate?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeCommand
     */
    reason?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeCommand
     */
    observations?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeCommand
     */
    newManagerId?: string | null;
}

/**
 * Check if a given object implements the DeleteEmployeeCommand interface.
 */
export function instanceOfDeleteEmployeeCommand(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DeleteEmployeeCommandFromJSON(json: any): DeleteEmployeeCommand {
    return DeleteEmployeeCommandFromJSONTyped(json, false);
}

export function DeleteEmployeeCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeleteEmployeeCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'endDate': !exists(json, 'endDate') ? undefined : json['endDate'],
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
        'observations': !exists(json, 'observations') ? undefined : json['observations'],
        'newManagerId': !exists(json, 'newManagerId') ? undefined : json['newManagerId'],
    };
}

export function DeleteEmployeeCommandToJSON(value?: DeleteEmployeeCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'endDate': value.endDate,
        'reason': value.reason,
        'observations': value.observations,
        'newManagerId': value.newManagerId,
    };
}

