/* eslint-disable @typescript-eslint/no-explicit-any */
import get from 'lodash/get'
import * as React from 'react'
import { Controller } from 'react-hook-form'
import { GroupBase } from 'react-select'

import ControlledFormControl, { ControlledFormControlProps } from './ControlledFormControl'
import { FormBaseProps } from './shared'
import { SelectAsyncPaginate, SelectAsyncPaginateProps } from '../Select'

export type FormSelectAsyncPaginateProps<
    Option = unknown,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>,
    Additional = unknown
> = SelectAsyncPaginateProps<Option, IsMulti, Group, Additional> &
    ControlledFormControlProps &
    FormBaseProps

const FormSelectAsyncPaginate = <
    Option = unknown,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>,
    Additional = unknown
>({
        name,
        control,

        label,
        labelWidth,
        labelClass,
        labelStyle,
        htmlFor,
        extra,
        style,
        asterisk,
        rules,

        isMulti,
        getOptionValue = (option: Option) => get(option, 'value') as any,
        onSelectItem,
        ...rest
    }: FormSelectAsyncPaginateProps<Option, IsMulti, Group, Additional>) => {
    const handleChange = (
        newValue: any,
        controllerOnChange: (v: any) => void
    ) => {
        const fixedValue = isMulti
            ? Array.from(newValue).map((x: any) => getOptionValue(x))
            : newValue
                ? getOptionValue(newValue)
                : null
        controllerOnChange(fixedValue)
        onSelectItem?.(newValue)
    }


    return (
        <ControlledFormControl
            control={control}
            name={name}
            label={label}
            labelWidth={labelWidth}
            asterisk={asterisk}
            labelClass={labelClass}
            labelStyle={labelStyle}
            htmlFor={htmlFor}
            extra={extra}
            style={style}
        >
            <Controller
                control={control}
                name={name}
                rules={rules}
                render={({ field, fieldState: { error } }) => (
                    <SelectAsyncPaginate
                        showDropDownIndicator
                        {...rest}
                        isMulti={isMulti}
                        name={name}
                        getOptionValue={getOptionValue}
                        onChange={(x: any) =>
                            handleChange(x, field.onChange)
                        }
                        isInvalid={Boolean(error)}
                    />
                )}
            />
        </ControlledFormControl>
    )
}

export default FormSelectAsyncPaginate
