/* eslint-disable react/no-array-index-key */
import EmployeeItemSkeleton from '@viterbit/web-app/feature/employee/item/EmployeeItemSkeleton'
import * as React from 'react'

import LeaveTeamLayout, { LeaveTeamLayoutProps } from './LeaveTeamLayout'

type LeaveTeamRowListSkeletonProps = {
    count?: number
} & Omit<LeaveTeamLayoutProps, 'header'>

const LeaveTeamRowListSkeleton = ({count = 10, ...rest}: LeaveTeamRowListSkeletonProps) => (
    <>
        {Array.from({length: count}).map((_, index) => (
            <LeaveTeamLayout key={index} header={<EmployeeItemSkeleton name email size='sm' />} {...rest} />
        ))}
    </>
)

export default LeaveTeamRowListSkeleton
