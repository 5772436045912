/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CityOrderByQueryParam,
  FilterGroups,
  ListCityQueryResponse,
  ListStateQueryResponse,
  StateOrderByQueryParam,
} from '../models/index';
import {
    CityOrderByQueryParamFromJSON,
    CityOrderByQueryParamToJSON,
    FilterGroupsFromJSON,
    FilterGroupsToJSON,
    ListCityQueryResponseFromJSON,
    ListCityQueryResponseToJSON,
    ListStateQueryResponseFromJSON,
    ListStateQueryResponseToJSON,
    StateOrderByQueryParamFromJSON,
    StateOrderByQueryParamToJSON,
} from '../models/index';

export interface ListCitiesRequest {
    country: string;
    search?: string;
    page?: number;
    pageSize?: number;
    filters?: FilterGroups;
    orderBy?: CityOrderByQueryParam;
    state?: string;
}

export interface ListStatesRequest {
    country: string;
    search?: string;
    page?: number;
    pageSize?: number;
    filters?: FilterGroups;
    orderBy?: StateOrderByQueryParam;
    include?: Array<ListStatesIncludeEnum>;
}

/**
 * 
 */
export class GeoDataApi extends runtime.BaseAPI {

    /**
     * List cities
     * List cities
     */
    async listCitiesRaw(requestParameters: ListCitiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListCityQueryResponse>> {
        if (requestParameters.country === null || requestParameters.country === undefined) {
            throw new runtime.RequiredError('country','Required parameter requestParameters.country was null or undefined when calling listCities.');
        }

        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.country !== undefined) {
            queryParameters['country'] = requestParameters.country;
        }

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/geo-data/cities`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListCityQueryResponseFromJSON(jsonValue));
    }

    /**
     * List cities
     * List cities
     */
    async listCities(requestParameters: ListCitiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListCityQueryResponse> {
        const response = await this.listCitiesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List states
     * List states
     */
    async listStatesRaw(requestParameters: ListStatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListStateQueryResponse>> {
        if (requestParameters.country === null || requestParameters.country === undefined) {
            throw new runtime.RequiredError('country','Required parameter requestParameters.country was null or undefined when calling listStates.');
        }

        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.include) {
            queryParameters['include'] = requestParameters.include;
        }

        if (requestParameters.country !== undefined) {
            queryParameters['country'] = requestParameters.country;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/geo-data/states`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListStateQueryResponseFromJSON(jsonValue));
    }

    /**
     * List states
     * List states
     */
    async listStates(requestParameters: ListStatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListStateQueryResponse> {
        const response = await this.listStatesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const ListStatesIncludeEnum = {
    Holidays: 'holidays'
} as const;
export type ListStatesIncludeEnum = typeof ListStatesIncludeEnum[keyof typeof ListStatesIncludeEnum];
