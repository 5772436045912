import * as React from 'react'
import clsxm from 'ui/src/lib'

export type TypographyProps = {
    children?: React.ReactNode
    className?: string
    bold?: boolean
    opacity?: boolean
    truncate?: boolean
    size?: 'small' | 'text' | 'subtitle' | 'title'
    Component?: React.ElementType
} & React.HTMLAttributes<HTMLSpanElement | HTMLAnchorElement>

export type TypographySizedProps = Omit<TypographyProps, 'size'>

export const TypographyStyles = {
    root: 'text-dark',
    bold: 'font-semibold',
    opacity: 'opacity-60',
    color: {
        primary: 'text-primary',
        danger: 'text-danger',
        success: 'text-success',
        warning: 'text-warning',
    },
    truncate: 'truncate',
} as const

export const TypographySizesStyles = {
    small: 'text-xs',
    text: 'text-sm',
    subtitle: 'text-lg',
    title: 'text-xl',
} as const

const Typography = (props: TypographyProps) => {
    const { children, className, bold, opacity, size = 'text', truncate, Component = 'div', ...rest } = props

    return (
        <Component
            className={clsxm(
                TypographyStyles.root,
                bold && TypographyStyles.bold,
                opacity && TypographyStyles.opacity,
                truncate && TypographyStyles.truncate,
                TypographySizesStyles[size],
                className
            )}
            {...rest}
        >
            {children}
        </Component>
    )
}

export default Typography
