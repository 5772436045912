import * as React from 'react'

type EmbeddedContentContextType = {
    container: HTMLElement | null
    setContainer: (container: HTMLElement | null) => void
} | null

const EmbeddedContentContext = React.createContext<EmbeddedContentContextType>(null)

export const useEmbeddedContent = () => {
    const context = React.useContext(EmbeddedContentContext)

    if (!context) {
        throw new Error('EmbeddedContentContext is not provided')
    }

    return context.setContainer
}

export default EmbeddedContentContext