/* eslint-disable @typescript-eslint/no-explicit-any */
import { LeavePolicy } from '@viterbit/web-app/dataAccess/model/LeavePolicy.model'
import { leavePolicyList } from '@viterbit/web-app/dataAccess/service/leavePolicyService'
import { GroupBase } from 'react-select'
import {
    FormSelectAsyncPaginate,
    FormSelectAsyncPaginateProps,
} from 'ui/src/components/Form'
import { FormBaseProps } from 'ui/src/components/Form/shared'

import { useNotification } from '../feedback/useNotification'


type FormSelectLeavePolicyProps<IsMulti extends boolean> = FormBaseProps &
    Omit<
        FormSelectAsyncPaginateProps<
            LeavePolicy,
            IsMulti,
            GroupBase<LeavePolicy>,
            { page: number }
        >,
        'loadOptions'
    >

type LoadOptions = FormSelectAsyncPaginateProps<
    LeavePolicy,
    false,
    GroupBase<LeavePolicy>,
    { page: number }
>['loadOptions']

const FormSelectLeavePolicy = <IsMulti extends boolean>({
    name,
    control,
    cacheOptions = true,
    isSearchable = false,
    ...rest
}: FormSelectLeavePolicyProps<IsMulti>) => {
    const notification = useNotification()

    const loadOptions: LoadOptions = async (search, loadedOptions, _meta) =>
        leavePolicyList({ page: _meta?.page })
            .then(({ data, meta }) => ({
                options: data,
                hasMore: meta.hasMore,
                additional: {
                    page: meta.page + 1,
                },
            }))
            .catch((err) => {
                notification.push(
                    'danger',
                    'Fetching error',
                    err.errorCode ?? 'Error at fetch leave policies'
                )
                return {
                    options: [],
                    hasMore: false,
                }
            })
    return (
        <FormSelectAsyncPaginate
            name={name}
            control={control}
            cacheOptions={cacheOptions}
            loadOptions={loadOptions}
            isSearchable={isSearchable}
            getOptionLabel={(x) => x.name}
            getOptionValue={(x) => x.id}
            loadOptionsOnMenuOpen
            additional={{
                page: 1,
            }}
            {...rest}
        />
    )
}

export default FormSelectLeavePolicy
