/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateAssignedWorkScheduledCommand
 */
export interface UpdateAssignedWorkScheduledCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateAssignedWorkScheduledCommand
     */
    workScheduleRecordId: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAssignedWorkScheduledCommand
     */
    employeeId: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAssignedWorkScheduledCommand
     */
    startDate: string;
}

/**
 * Check if a given object implements the UpdateAssignedWorkScheduledCommand interface.
 */
export function instanceOfUpdateAssignedWorkScheduledCommand(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "workScheduleRecordId" in value;
    isInstance = isInstance && "employeeId" in value;
    isInstance = isInstance && "startDate" in value;

    return isInstance;
}

export function UpdateAssignedWorkScheduledCommandFromJSON(json: any): UpdateAssignedWorkScheduledCommand {
    return UpdateAssignedWorkScheduledCommandFromJSONTyped(json, false);
}

export function UpdateAssignedWorkScheduledCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateAssignedWorkScheduledCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'workScheduleRecordId': json['workScheduleRecordId'],
        'employeeId': json['employeeId'],
        'startDate': json['startDate'],
    };
}

export function UpdateAssignedWorkScheduledCommandToJSON(value?: UpdateAssignedWorkScheduledCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'workScheduleRecordId': value.workScheduleRecordId,
        'employeeId': value.employeeId,
        'startDate': value.startDate,
    };
}

