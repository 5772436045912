/* eslint-disable @typescript-eslint/no-non-null-assertion */
import dayjs from 'dayjs'
import { TimeTrackingEstimation } from 'viterbit-api-sdk'

import { IMapper } from './__common'
import { Estimation } from '../model/Estimation.model'

const estimationMapper: IMapper<Estimation, TimeTrackingEstimation> = {
    toModel: (dto) => {
        const estimatedMinutes = parseInt(dto.estimation)
        const workedMinutes = parseInt(dto.worked)
        const estimatedFormatted = dayjs.duration(estimatedMinutes * 60 * 1000).format('HH:mm')
        const workedFormatted = dayjs.duration(workedMinutes * 60 * 1000).format('HH:mm')

        return {
            date: dto.date,
            estimatedMinutes,
            estimatedFormatted,
            workedFormatted,
            workedMinutes,
        }
    },
    toDto: (dto) => {
        throw new Error('Not implemented')
    },
}

export default estimationMapper
