/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AssignWorkScheduleCommand,
  CreateWorkScheduleCommand,
  FindWorkScheduleQueryResponse,
  ListWorkScheduleQueryResponse,
  ResourceCreated,
  UnassignWorkScheduleCommand,
  UpdateAssignedWorkScheduledCommand,
  UpdateWorkScheduleCommand,
} from '../models/index';
import {
    AssignWorkScheduleCommandFromJSON,
    AssignWorkScheduleCommandToJSON,
    CreateWorkScheduleCommandFromJSON,
    CreateWorkScheduleCommandToJSON,
    FindWorkScheduleQueryResponseFromJSON,
    FindWorkScheduleQueryResponseToJSON,
    ListWorkScheduleQueryResponseFromJSON,
    ListWorkScheduleQueryResponseToJSON,
    ResourceCreatedFromJSON,
    ResourceCreatedToJSON,
    UnassignWorkScheduleCommandFromJSON,
    UnassignWorkScheduleCommandToJSON,
    UpdateAssignedWorkScheduledCommandFromJSON,
    UpdateAssignedWorkScheduledCommandToJSON,
    UpdateWorkScheduleCommandFromJSON,
    UpdateWorkScheduleCommandToJSON,
} from '../models/index';

export interface AssignWorkScheduleRequest {
    assignWorkScheduleCommand?: AssignWorkScheduleCommand;
}

export interface CreateWorkScheduleRequest {
    createWorkScheduleCommand?: CreateWorkScheduleCommand;
}

export interface DeleteWorkScheduleRequest {
    id: string;
}

export interface FindWorkScheduleRequest {
    id: string;
}

export interface ListWorkScheduleRequest {
    page?: string;
    pageSize?: string;
}

export interface UnassignWorkScheduleRequest {
    unassignWorkScheduleCommand?: UnassignWorkScheduleCommand;
}

export interface UpdateAssignedWorkScheduleRequest {
    updateAssignedWorkScheduledCommand?: UpdateAssignedWorkScheduledCommand;
}

export interface UpdateWorkScheduleRequest {
    id: string;
    updateWorkScheduleCommand?: UpdateWorkScheduleCommand;
}

/**
 * 
 */
export class WorkScheduleApi extends runtime.BaseAPI {

    /**
     * Assign work schedule
     * Work schedule
     */
    async assignWorkScheduleRaw(requestParameters: AssignWorkScheduleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core-hr/work-schedule/assign-employee`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AssignWorkScheduleCommandToJSON(requestParameters.assignWorkScheduleCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Assign work schedule
     * Work schedule
     */
    async assignWorkSchedule(requestParameters: AssignWorkScheduleRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.assignWorkScheduleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a work schedule
     * Create a work schedule
     */
    async createWorkScheduleRaw(requestParameters: CreateWorkScheduleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core-hr/work-schedules`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateWorkScheduleCommandToJSON(requestParameters.createWorkScheduleCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Create a work schedule
     * Create a work schedule
     */
    async createWorkSchedule(requestParameters: CreateWorkScheduleRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.createWorkScheduleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a work schedule
     * Delete a work schedule
     */
    async deleteWorkScheduleRaw(requestParameters: DeleteWorkScheduleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteWorkSchedule.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core-hr/work-schedules/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Delete a work schedule
     * Delete a work schedule
     */
    async deleteWorkSchedule(requestParameters: DeleteWorkScheduleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.deleteWorkScheduleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find a work schedule
     * Find a work schedule
     */
    async findWorkScheduleRaw(requestParameters: FindWorkScheduleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindWorkScheduleQueryResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling findWorkSchedule.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core-hr/work-schedules/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindWorkScheduleQueryResponseFromJSON(jsonValue));
    }

    /**
     * Find a work schedule
     * Find a work schedule
     */
    async findWorkSchedule(requestParameters: FindWorkScheduleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindWorkScheduleQueryResponse> {
        const response = await this.findWorkScheduleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List work schedule
     * List work schedule
     */
    async listWorkScheduleRaw(requestParameters: ListWorkScheduleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListWorkScheduleQueryResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core-hr/work-schedules`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListWorkScheduleQueryResponseFromJSON(jsonValue));
    }

    /**
     * List work schedule
     * List work schedule
     */
    async listWorkSchedule(requestParameters: ListWorkScheduleRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListWorkScheduleQueryResponse> {
        const response = await this.listWorkScheduleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Unassign work schedule
     * Work schedule
     */
    async unassignWorkScheduleRaw(requestParameters: UnassignWorkScheduleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core-hr/work-schedule/unassign-employee`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UnassignWorkScheduleCommandToJSON(requestParameters.unassignWorkScheduleCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Unassign work schedule
     * Work schedule
     */
    async unassignWorkSchedule(requestParameters: UnassignWorkScheduleRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.unassignWorkScheduleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update assigned work schedule
     * Work schedule
     */
    async updateAssignedWorkScheduleRaw(requestParameters: UpdateAssignedWorkScheduleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core-hr/work-schedule/update-assigned-employee`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateAssignedWorkScheduledCommandToJSON(requestParameters.updateAssignedWorkScheduledCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Update assigned work schedule
     * Work schedule
     */
    async updateAssignedWorkSchedule(requestParameters: UpdateAssignedWorkScheduleRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.updateAssignedWorkScheduleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a work schedule
     * Update a work schedule
     */
    async updateWorkScheduleRaw(requestParameters: UpdateWorkScheduleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateWorkSchedule.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core-hr/work-schedules/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateWorkScheduleCommandToJSON(requestParameters.updateWorkScheduleCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Update a work schedule
     * Update a work schedule
     */
    async updateWorkSchedule(requestParameters: UpdateWorkScheduleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.updateWorkScheduleRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
