import * as React from 'react'

import Info from './Info'
import { getClasses } from './style'
import { BaseProgressProps } from './types'
import { clsxm } from '../../lib/clsxm'

type ProgressCircleProps = {
    strokeWidth?: number
    strokeLinecap?: 'round' | 'square'
    gapDegree?: number
    gapPosition?: 'top' | 'bottom' | 'left' | 'right'
    children?: React.ReactNode
    width?: number | string
} & BaseProgressProps

const ProgressCircle = React.forwardRef<HTMLDivElement, ProgressCircleProps>(
    (props: ProgressCircleProps, ref) => {
        const {
            strokeWidth = 6,
            color = 'primary-600',
            strokeLinecap = 'round',
            width = 120,
            gapDegree = 0,
            gapPosition = 'top',
            percent,
            customInfo,
            showInfo = true,
            className,
        } = props

        const strokeColor = color

        const classes = getClasses(strokeColor)

        const getPathStyles = React.useCallback(() => {
            const radius = 50 - strokeWidth / 2

            let beginPositionX = 0
            let beginPositionY = -radius
            let endPositionX = 0
            let endPositionY = -2 * radius

            switch (gapPosition) {
            case 'left':
                beginPositionX = -radius
                beginPositionY = 0
                endPositionX = 2 * radius
                endPositionY = 0
                break
            case 'right':
                beginPositionX = radius
                beginPositionY = 0
                endPositionX = -2 * radius
                endPositionY = 0
                break
            case 'bottom':
                beginPositionY = radius
                endPositionY = 2 * radius
                break
            default:
            }

            const pathString = `M 50,50 m ${beginPositionX},${beginPositionY} a ${radius},${radius} 0 1 1 ${endPositionX},${-endPositionY} a ${radius},${radius} 0 1 1 ${-endPositionX},${endPositionY}`

            const len = Math.PI * 2 * radius
            const trailPathStyle = {
                strokeDasharray: `${len - gapDegree}px ${len}px`,
                strokeDashoffset: `-${gapDegree / 2}px`,
            }

            const strokePathStyle = {
                strokeDasharray: `${
                    (percent / 100) * (len - gapDegree)
                }px ${len}px`,
                strokeDashoffset: `-${gapDegree / 2}px`,
            }

            return {
                pathString,
                trailPathStyle,
                strokePathStyle,
            }
        }, [gapDegree, gapPosition, percent, strokeWidth])

        const { pathString, trailPathStyle, strokePathStyle } = getPathStyles()

        const progressStrokeClass = clsxm(
            `text-${strokeColor}`,
            classes['progress-circle-stroke']
        )

        const progressClass = clsxm(classes.progress, className)

        return (
            <div ref={ref} className={progressClass}>
                <div
                    ref={ref}
                    className={classes['progress-circle']}
                    style={{ width: width }}
                >
                    <span className={classes['progress-circle-info']}>
                        {showInfo ? (
                            <Info className='flex-shrink' percent={percent}>
                                {customInfo}
                            </Info>
                        ) : null}
                    </span>
                    <svg viewBox='0 0 100 100'>
                        <path
                            d={pathString}
                            strokeWidth={strokeWidth}
                            fillOpacity='0'
                            style={trailPathStyle}
                            className={classes['progress-circle-trail']}
                        />
                        <path
                            d={pathString}
                            strokeLinecap={strokeLinecap}
                            strokeWidth={percent === 0 ? 0 : strokeWidth}
                            fillOpacity='0'
                            style={strokePathStyle}
                            className={progressStrokeClass}
                        />
                    </svg>
                </div>
            </div>
        )
    }
)

export default ProgressCircle
