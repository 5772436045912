import { ActionIcon, Indicator } from '@mantine/core'
import { IconEdit } from '@tabler/icons-react'
import { Organization } from '@viterbit/web-app/dataAccess/model/Organization.model'
import { organizationUpdate } from '@viterbit/web-app/dataAccess/service/organizationService'
import { useUser } from '@viterbit/web-app/shared/employee/useUser'
import FormUploadDialog from '@viterbit/web-app/shared/form/FormUploadDialog'
import * as React from 'react'

type OrganizationAvatarUploadProps = {
    organization: Organization
    children?: React.ReactNode
}

const OrganizationAvatarUpload = (props: OrganizationAvatarUploadProps) => {
    const { organization, children } = props

    const [editMode, setEditMode] = React.useState(false)
    const user = useUser()

    return (
        <>
            <Indicator
                inline
                label={(
                    <ActionIcon variant='light' color='primary' sx={{ display: 'contents' }}>
                        <IconEdit className='text-primary-600' size={16} stroke={2} />
                    </ActionIcon>
                )}
                size={30}
                offset={22}
                position="bottom-end"
                color='blue.1'
                onClick={() => setEditMode(true)}
            >
                {children}
            </Indicator>

            <FormUploadDialog
                open={editMode}
                onClose={() => setEditMode(false)}
                uploadProps={{
                    employeeId: user?.id,
                    kind: 'logo',
                    draggable: true,
                    multiple: false,
                    accept: 'image/png, image/jpeg',
                }}
                onConfirm={async (filesId) => {
                    await organizationUpdate({
                        id: user?.organizationId,
                        updateOrganizationCommand: {
                            name: organization.name,
                            domain: organization.domain,
                            terms: organization.terms,
                            logoFileId: filesId as string,
                        }
                    })
                    setEditMode(false)
                }}
            />
        </>

    )
}

export default OrganizationAvatarUpload
