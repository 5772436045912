
import i18n from '@viterbit/web-app/i18n'
import { UseFormReturn } from 'react-hook-form'
import { FormInput, FormPasswordInput } from 'ui/src/components/Form'

import { ComplaintLoginFields } from './ComplaintLoginFields'

type ComplaintLoginFormFieldsProps = {
    form: UseFormReturn<ComplaintLoginFields>
}

const ComplaintLoginFormFields = (props: ComplaintLoginFormFieldsProps) => {
    const { form } = props

    return (
        <>
            <FormInput
                asterisk
                name='id'
                control={form.control}
                label={i18n.t('complaint:login.form.id')}
                placeholder={i18n.t('complaint:login.form.idPlaceholder')}
                data-testid="complaint-id"
            />

            <FormPasswordInput
                asterisk
                name='password'
                control={form.control}
                label={i18n.t('complaint:login.form.password')}
                placeholder={i18n.t('complaint:login.form.passwordPlaceholder')}
                data-testid="complaint-password"
            />
        </>
    )
}

export default ComplaintLoginFormFields
