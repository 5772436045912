import { IsNotEmpty } from '@viterbit/web-app/shared/utils/ClassValidators'

import { PeriodCreateFields } from './WorkScheduleDetailPeriodCreateDialog'

class WorkScheduleDetailPeriodCreateValidation implements PeriodCreateFields {
    @IsNotEmpty()
    startDate: Date
    @IsNotEmpty()
    endDate: Date
}

export default WorkScheduleDetailPeriodCreateValidation