import { Overlay } from '@mantine/core'
import LogoImage from '@viterbit/web-assets/images/logo_white.svg'
import React from 'react'

import classes from './SpinnerLogo.module.scss'

const SpinnerLogo = () => (
    <Overlay opacity={1}>
        <div className={classes.root}>
            <svg className={classes.spinner} width="100%" height="100%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                <circle className={classes.path} fill="none" strokeWidth="4" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
            </svg>

            <div className={classes['logo-container']}>
                <img src={LogoImage} alt="logo" className={classes.logo} />
            </div>
        </div>

    </Overlay>
)

export default SpinnerLogo