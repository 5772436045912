/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateLocationCommand,
  FindLocationQueryResponse,
  ImportHolidaysCommand,
  ListLocationQueryResponse,
  ResourceCreated,
  ResourceUpdated,
  UpdateLocationCommand,
} from '../models/index';
import {
    CreateLocationCommandFromJSON,
    CreateLocationCommandToJSON,
    FindLocationQueryResponseFromJSON,
    FindLocationQueryResponseToJSON,
    ImportHolidaysCommandFromJSON,
    ImportHolidaysCommandToJSON,
    ListLocationQueryResponseFromJSON,
    ListLocationQueryResponseToJSON,
    ResourceCreatedFromJSON,
    ResourceCreatedToJSON,
    ResourceUpdatedFromJSON,
    ResourceUpdatedToJSON,
    UpdateLocationCommandFromJSON,
    UpdateLocationCommandToJSON,
} from '../models/index';

export interface CreateLocationRequest {
    createLocationCommand?: CreateLocationCommand;
}

export interface DeleteLocationRequest {
    id: string;
}

export interface FetchLocationRequest {
    id: string;
}

export interface ImportHolidaysLocationRequest {
    id: string;
    importHolidaysCommand?: ImportHolidaysCommand;
}

export interface ListLocationRequest {
    page?: number;
}

export interface UpdateLocationRequest {
    id: string;
    updateLocationCommand?: UpdateLocationCommand;
}

/**
 * 
 */
export class LocationApi extends runtime.BaseAPI {

    /**
     * Create a location
     * Create a location
     */
    async createLocationRaw(requestParameters: CreateLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/locations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateLocationCommandToJSON(requestParameters.createLocationCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Create a location
     * Create a location
     */
    async createLocation(requestParameters: CreateLocationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.createLocationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a location
     * Delete a location
     */
    async deleteLocationRaw(requestParameters: DeleteLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteLocation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/locations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Delete a location
     * Delete a location
     */
    async deleteLocation(requestParameters: DeleteLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.deleteLocationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch location by its id
     * Fetch location
     */
    async fetchLocationRaw(requestParameters: FetchLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindLocationQueryResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetchLocation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/locations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindLocationQueryResponseFromJSON(jsonValue));
    }

    /**
     * Fetch location by its id
     * Fetch location
     */
    async fetchLocation(requestParameters: FetchLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindLocationQueryResponse> {
        const response = await this.fetchLocationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Import holidays for a location
     * Import holidays for a location
     */
    async importHolidaysLocationRaw(requestParameters: ImportHolidaysLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceUpdated>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling importHolidaysLocation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/locations/{id}/import-holidays`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ImportHolidaysCommandToJSON(requestParameters.importHolidaysCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceUpdatedFromJSON(jsonValue));
    }

    /**
     * Import holidays for a location
     * Import holidays for a location
     */
    async importHolidaysLocation(requestParameters: ImportHolidaysLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceUpdated> {
        const response = await this.importHolidaysLocationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List locations
     * List locations
     */
    async listLocationRaw(requestParameters: ListLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListLocationQueryResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/locations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListLocationQueryResponseFromJSON(jsonValue));
    }

    /**
     * List locations
     * List locations
     */
    async listLocation(requestParameters: ListLocationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListLocationQueryResponse> {
        const response = await this.listLocationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a location
     * Update a location
     */
    async updateLocationRaw(requestParameters: UpdateLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateLocation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/locations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateLocationCommandToJSON(requestParameters.updateLocationCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Update a location
     * Update a location
     */
    async updateLocation(requestParameters: UpdateLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.updateLocationRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
