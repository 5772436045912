/* eslint-disable no-useless-escape */
import { Leave } from '@viterbit/web-app/dataAccess/model/Leave.model'
import partition from 'lodash/partition'

import { RelevantDate, StatusType } from './types'
import { LeavesClusters } from './types'

export const flatLeavesDates = (leaves: Leave[]): RelevantDate[] =>
    leaves
        .filter((leave) => leave.status !== StatusType.REJECT)
        .reduce<RelevantDate[]>((acc, leave) => {
            const relevantDates = leave.calendar.map<RelevantDate>((d) => ({
                label: leave?.leaveReason?.name || '',
                date: d.day,
                color: leave?.leaveReason?.color || '#000',
                isHoliday: false,
                halfDay: Boolean(leave?.halfDay),
                turn: leave?.turn,
                status: leave?.status,
                total: leave.calendar.length,
                leaveId: leave.id,
            }))

            return acc.concat(relevantDates)
        }, [])

export const hexToRgba = (hex: string, opacity: number) => {
    const r = parseInt(hex.slice(1, 3), 16)
    const g = parseInt(hex.slice(3, 5), 16)
    const b = parseInt(hex.slice(5, 7), 16)

    return `rgba(${r}, ${g}, ${b}, ${opacity})`
}

export const filterLeaves = (leaves: Leave[], leaveStatus: LeavesClusters) => {
    if (leaveStatus.toString() === StatusType.REJECT.toString()) {
        return leaves.filter((l) => l.status === StatusType.REJECT)
    }
    const [pastLeaves, futureLeaves] = partition(
        leaves,
        (l) => new Date(l.initialDate) < new Date()
    )

    const cleanPast = pastLeaves.filter(
        (l) => l.status !== StatusType.REJECT && l.status !== StatusType.PENDING
    )
    const cleanFuture = futureLeaves.filter(
        (l) => l.status !== StatusType.REJECT && l.status !== StatusType.PENDING
    )

    return LeavesClusters.UPCOMING === leaveStatus ? cleanFuture : cleanPast
}

const fixQueryStringParams = (url: string) =>
    url
        .replace(new RegExp(/\?&/), '?')
        .replace(new RegExp(/&&/), '&')
        .replace(new RegExp(/[?&]$/), '')


export const removeParamToUrl = (url: string, param: string) => {
    const regex = new RegExp(`[?&]${param}=[^&]*`)
    return fixQueryStringParams(url.replace(regex, url.match(regex)?.[0].startsWith('?') ? '?' : ''))
}

export const addParamToUrl = (url: string, param: string, value: string) => {
    const newUrl = removeParamToUrl(url, param)
    const separator = newUrl.indexOf('?') !== -1 ? '&' : '?'
    return fixQueryStringParams(`${newUrl}${separator}${param}=${value}`)
}

export const handleParamToUrl = (url: string, param: string, value: string) => {
    if (value) {
        return addParamToUrl(url, param, value)
    }

    return removeParamToUrl(url, param)
}


export const getDefaultTab = (pathname: string | null) => {
    if (!pathname) return LeavesClusters.UPCOMING

    if (pathname.includes(LeavesClusters.PAST)) {
        return LeavesClusters.PAST
    }
    if (pathname.includes('reject')) {
        return LeavesClusters.REJECT
    }
    return LeavesClusters.UPCOMING
}
