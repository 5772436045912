import { Leave } from '@viterbit/web-app/dataAccess/model/Leave.model'

export enum DaysRequestsEnum {
    HalfDay = 'Half Day',
    OneDay = 'One Day',
    SeveralDays = 'Several Days',
}

export enum HalfDayOptions {
    'Morning' = 'MORNING',
    'Afternoon' = 'AFTERNOON',
}

export type LeaveFormFields = Leave & {
    leaveReasonId: string
}
