
import { classValidatorResolver } from '@hookform/resolvers/class-validator'
import { Employee } from '@viterbit/web-app/dataAccess/model/Employee.model'
import { accountChangeRole } from '@viterbit/web-app/dataAccess/service/accountService'
import i18n from '@viterbit/web-app/i18n'
import SimpleCard from '@viterbit/web-app/shared/display/SimpleCard'
import Form from '@viterbit/web-app/shared/form/Form'
import FormSelectRole from '@viterbit/web-app/shared/form/FormSelectRole'
import SubmitButton from '@viterbit/web-app/shared/form/SubmitButton'
import * as React from 'react'

import { EmployeeProfileAccountValidator } from './EmployeeProfileAccountValidator'


export type EmployeeProfileAccountFormFields = {
    roleId: string
}

export type EmployeeProfileAccountFormProps = {
    employee: Employee
}

const resolver = classValidatorResolver(EmployeeProfileAccountValidator)

const getFormDefaultValues = ({account}: Employee): EmployeeProfileAccountFormFields => ({
    roleId: account?.role?.id || '',
})

const EmployeeProfileAccountForm = (props: EmployeeProfileAccountFormProps) => {
    const { employee } = props

    const defaultValues = React.useMemo<EmployeeProfileAccountFormFields>(() => getFormDefaultValues(employee), [employee])

    return (
        <SimpleCard padding>
            <Form<EmployeeProfileAccountFormFields>
                smallButtons
                withTransition
                mutationConfig={{
                    mutationFn: (changeRoleCommand) => accountChangeRole({id: employee.id, changeRoleCommand}),
                }}
                formConfig={{
                    resolver,
                    mode: 'all',
                    defaultValues: defaultValues,
                }}
            >
                {(form) => (
                    <>
                        <FormSelectRole
                            name='roleId'
                            control={form.control}
                            asterisk
                            label={i18n.t('employees:employee.profile.accountInfo.form.role.label')}
                            placeholder={i18n.t('employees:employee.profile.accountInfo.form.role.placeholder')}
                            defaultValue={employee.account?.role}
                        />


                        <SubmitButton>{i18n.t('employees:employee.buttons.save')}</SubmitButton>
                    </>
                )}
            </Form>
        </SimpleCard>

    )
}

export default EmployeeProfileAccountForm
