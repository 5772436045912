import { useQuery } from '@tanstack/react-query'

import { EMPLOYEE_QUERY_KEY } from '../../cacheKeys'
import { employeeList } from '../../service/employeeService'

export type UseEmployeeListConfig = {
    query: Parameters<typeof employeeList>[0]
    enabled?: boolean
    keepPreviousData?: boolean
    refetchOnWindowFocus?: boolean
}

export const useEmployeeList = (config?: UseEmployeeListConfig) => {
    const { query = { page: 1 }, enabled, keepPreviousData, refetchOnWindowFocus } = config || {}

    return useQuery({
        queryKey: [EMPLOYEE_QUERY_KEY, 'list-paginated', query],
        queryFn: () => employeeList(query),
        enabled,
        keepPreviousData,
        refetchOnWindowFocus
    })
}

