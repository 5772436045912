import { AssignEmployeesToTeamPayload } from '@viterbit/web-app/dataAccess/dto/team'
import * as React from 'react'

import TeamAssignEmployeeDialogForm, { TeamAssignEmployeeDialogFormProps } from './TeamAssignEmployeeDialogForm'

export type TeamAssignEmployeeDialogProps = {
    onCancel?: () => void
    onConfirm?: () => void
    onSubmit: TeamAssignEmployeeDialogFormProps['onSubmit']
    assignAs?: AssignEmployeesToTeamPayload['assignAs']
    teamId?: string
    complaintManager?: boolean
}

const TeamAssignEmployeeDialog = ({
    onCancel,
    onConfirm,
    onSubmit,
    teamId,
    complaintManager,
}: TeamAssignEmployeeDialogProps) => (
    <TeamAssignEmployeeDialogForm
        onSubmit={onSubmit}
        onCancel={onCancel}
        onConfirm={onConfirm}
        teamId={teamId}
        complaintManager={complaintManager}
    />
)

export default TeamAssignEmployeeDialog
