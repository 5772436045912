/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UpdateCalendarDay } from './UpdateCalendarDay';
import {
    UpdateCalendarDayFromJSON,
    UpdateCalendarDayFromJSONTyped,
    UpdateCalendarDayToJSON,
} from './UpdateCalendarDay';

/**
 * 
 * @export
 * @interface UpdateCalendarDayWrapper
 */
export interface UpdateCalendarDayWrapper {
    /**
     * 
     * @type {number}
     * @memberof UpdateCalendarDayWrapper
     */
    year: number;
    /**
     * 
     * @type {Array<UpdateCalendarDay>}
     * @memberof UpdateCalendarDayWrapper
     */
    publicHolidays?: Array<UpdateCalendarDay> | null;
}

/**
 * Check if a given object implements the UpdateCalendarDayWrapper interface.
 */
export function instanceOfUpdateCalendarDayWrapper(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "year" in value;

    return isInstance;
}

export function UpdateCalendarDayWrapperFromJSON(json: any): UpdateCalendarDayWrapper {
    return UpdateCalendarDayWrapperFromJSONTyped(json, false);
}

export function UpdateCalendarDayWrapperFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateCalendarDayWrapper {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'year': json['year'],
        'publicHolidays': !exists(json, 'publicHolidays') ? undefined : (json['publicHolidays'] === null ? null : (json['publicHolidays'] as Array<any>).map(UpdateCalendarDayFromJSON)),
    };
}

export function UpdateCalendarDayWrapperToJSON(value?: UpdateCalendarDayWrapper | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'year': value.year,
        'publicHolidays': value.publicHolidays === undefined ? undefined : (value.publicHolidays === null ? null : (value.publicHolidays as Array<any>).map(UpdateCalendarDayToJSON)),
    };
}

