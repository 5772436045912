/* eslint-disable @typescript-eslint/no-explicit-any */
import { timeTrackingReportListPaginated } from '@viterbit/web-app/dataAccess/service/timeTrackingService'
import { useTimeTrackingReportListPaginated } from '@viterbit/web-app/dataAccess/useCase/timeTracking/useTimeTrackingReportListPaginated'
import * as React from 'react'

type TimeTrackingTeamTotal = {
    APPROVED: number
    PENDING: number
}

const useTimeTrackingTeam = (query: Parameters<typeof timeTrackingReportListPaginated>[0]) => {
    const currentTypeList = useTimeTrackingReportListPaginated({
        query,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
    })

    const otherListType = query.status === 'APPROVED' ? 'PENDING' : 'APPROVED'

    const otherTypeList = useTimeTrackingReportListPaginated({
        query: {
            ...query,
            status: otherListType,
            page: 1,
        },
        keepPreviousData: true,
        refetchOnWindowFocus: false,
    })

    return React.useMemo(() => ({
        ...currentTypeList,
        total: {
            [query.status as string]: currentTypeList.data?.pages[0]?.meta?.total || 0,
            [otherListType]: otherTypeList.data?.pages[0]?.meta?.total || 0,
        } as TimeTrackingTeamTotal,
    }), [currentTypeList])
}

export default useTimeTrackingTeam