/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import paginationMetaMapper from '@viterbit/web-app/dataAccess/mapper/paginationMeta.mapper'
import { queryClient } from '@viterbit/web-app/shared/lib/ReactQueryProvider'
import { Configuration, IntegrationApi } from 'viterbit-api-sdk'

import { commonConfig } from './config'
import { INTEGRATION_QUERY_KEY } from '../cacheKeys'
import integrationMapper from '../mapper/integration.mapper'
import integrationOptionMapper from '../mapper/integrationOption.mapper'

const api = new IntegrationApi(new Configuration(commonConfig))

export const integrationInvalidate = () =>
    queryClient.invalidateQueries([INTEGRATION_QUERY_KEY])

export const integrationListPaginated = (body: Parameters<typeof api.listIntegrations>[0]) =>
    api.listIntegrations(body).then(({data, meta}) => ({
        meta: paginationMetaMapper(meta),
        data: data.map(integrationMapper.toModel)
    }))

export const integrationOptionListPaginated = (body: Parameters<typeof api.listIntegrationOptions>[0]) =>
    api.listIntegrationOptions(body).then(({data, meta}) => ({
        meta: paginationMetaMapper(meta),
        data: data.map(integrationOptionMapper.toModel)
    }))

export const integrationDelete = (body: Parameters<typeof api.deleteIntegration>[0]) =>
    api.deleteIntegration(body).then((data) => {
        integrationInvalidate()
        return data
    })
