import { MRT_FilterOption } from 'mantine-react-table'
import { FilterOperator } from 'viterbit-api-sdk'

export const FIELD_COLUMN_FILTER_MODE_OPTIONS: MRT_FilterOption[] = [
    'equals',
    'notEquals',
]

export const STRING_COLUMN_FILTER_MODE_OPTIONS: MRT_FilterOption[] = FIELD_COLUMN_FILTER_MODE_OPTIONS.concat([
    'contains',
    'startsWith',
    'endsWith',
])

export const QUANTITATIVE_COLUMN_FILTER_MODE_OPTIONS: MRT_FilterOption[] = FIELD_COLUMN_FILTER_MODE_OPTIONS.concat([
    'lessThan',
    'lessThanOrEqualTo',
    'greaterThan',
    'greaterThanOrEqualTo',
])

export const FILTER_MODE_OPTIONS_TO_OPERATOR: Record<
    MRT_FilterOption | string,
    FilterOperator
> = {
    'equals': 'equals',
    'notEquals': 'notEquals',
    'contains': 'contains',
    'startsWith': 'startsWith',
    'endsWith': 'endsWith',
    'lessThan': 'lowerThan',
    'lessThanOrEqualTo': 'lowerThanOrEquals',
    'greaterThan': 'greaterThan',
    'greaterThanOrEqualTo': 'greaterThanOrEquals',
}