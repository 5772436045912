import * as React from 'react'

import { BaseProps } from './util'
import { clsxm } from '../../lib/clsxm'

const classes = {
    tr: 'px-6 py-3 text-left font-semibold text-gray-500 dark:text-gray-100',
    hover: 'hover:bg-gray-100/50 hover:dark:bg-gray-700/40',
    variants: {
        default: '',
        flex: '',
    },
}

export type TrProps = BaseProps & React.HTMLAttributes<HTMLTableRowElement>

const Tr = ({
    variant = 'default',
    isHoverable = true,
    className,
    children,
    ...props
}: TrProps) => (
    <tr
        className={clsxm(
            classes.tr,
            isHoverable && classes.hover,
            classes.variants[variant],
            className
        )}
        {...props}
    >
        {children}
    </tr>
)

export default Tr
