import organizationMapper from '@viterbit/web-app/dataAccess/mapper/organization.mapper'
import { queryClient } from '@viterbit/web-app/shared/lib/ReactQueryProvider'
import { Configuration, OrganizationApi } from 'viterbit-api-sdk'

import { commonConfig } from './config'
import { ORGANIZATION_QUERY_KEY } from '../cacheKeys'

const api = new OrganizationApi(new Configuration(commonConfig))

export const organizationInvalidate = () => {
    queryClient.invalidateQueries([ORGANIZATION_QUERY_KEY])
}

export const organizationDetail = (body: Parameters<typeof api.fetchOrganization>[0]) =>
    api.fetchOrganization(body).then(organizationMapper.toModel)

export const organizationPublicDetail = (body: Parameters<typeof api.publicFetchOrganization>[0]) => 
    api.publicFetchOrganization(body).then(organizationMapper.toModel)

export const organizationUpdate = (body: Parameters<typeof api.updateOrganization>[0]) =>
    api.updateOrganization(body).then((data) => {
        organizationInvalidate()
        return data
    })

export const organizationBillingInfoUpdate = (body: Parameters<typeof api.updateOrganizationBillingInfo>[0]) =>
    api.updateOrganizationBillingInfo(body).then(async (data) => {
        organizationInvalidate()
        return data
    })
        