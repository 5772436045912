import * as React from 'react'

import { clsxm } from '../../lib/clsxm'

const classes = {
    card: 'bg-white rounded-lg dark:bg-gray-800',
    border: 'border border-gray-200 dark:border-gray-600',
    shadow: 'border border-gray-200 dark:border-gray-600 dark:border-none',
    header: 'py-3 px-5',
    'header-border': 'border-b border-gray-200 dark:border-gray-600',
    'header-extra': 'flex justify-between items-center',
    footer: 'py-3 px-5 rounded-bl-lg rounded-br-lg',
    'footer-border': 'border-t border-gray-200 dark:border-gray-600',
    body: 'p-5',
}

type CardProps = {
    className?: string
    bordered?: boolean
    clickable?: boolean
    bodyClass?: string
    header?: React.ReactNode | string
    headerClass?: string
    headerBorder?: boolean
    headerExtra?: React.ReactNode | string
    footer?: React.ReactNode | string
    footerClass?: string
    footerBorder?: boolean
    onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
    children?: React.ReactNode
}

const Card = React.forwardRef<HTMLDivElement, CardProps>(
    (
        {
            className,
            bordered,
            clickable,
            bodyClass,
            header,
            headerExtra,
            headerClass,
            headerBorder = true,
            footer,
            footerClass,
            footerBorder = true,
            onClick,
            children,
        },
        ref
    ) => {
        const cardClass = clsxm(
            classes.card,
            className,
            bordered ? classes.border : classes.shadow,
            clickable && 'cursor-pointer user-select-none',
            'h-full'
        )

        const cardBodyClass = clsxm(classes.body, bodyClass)

        const cardHeaderClass = clsxm(
            classes.header,
            headerBorder && classes['header-border'],
            headerExtra && classes['header-extra'],
            headerClass
        )
        const cardFooterClass = clsxm(
            classes.footer,
            footerBorder && classes['footer-border'],
            footerClass
        )

        const renderHeader =
            typeof header === 'string' ? <h4>{header}</h4> : <>{header}</>

        const onCardClick = (e: React.MouseEvent<HTMLDivElement>) => {
            onClick?.(e)
        }

        return (
            <div className={cardClass} ref={ref} onClick={onCardClick}>
                {header && (
                    <div className={cardHeaderClass}>
                        {renderHeader}
                        {headerExtra && <span>{headerExtra}</span>}
                    </div>
                )}
                <div className={cardBodyClass}>{children}</div>
                {footer && <div className={cardFooterClass}>{footer}</div>}
            </div>
        )
    }
)

export default Card
