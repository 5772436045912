import { useInfiniteQuery } from '@tanstack/react-query'

import { TIME_TRACKING_QUERY_KEY } from '../../cacheKeys'
import { timeTrackingReportListPaginated } from '../../service/timeTrackingService'

type TimeTrackingReportListPaginatedConfig = {
    query: Parameters<typeof timeTrackingReportListPaginated>[0]
    enabled?: boolean
    keepPreviousData?: boolean
    refetchOnWindowFocus?: boolean
}

export const useTimeTrackingReportListPaginated = (config: TimeTrackingReportListPaginatedConfig) => {
    const { query, enabled, keepPreviousData, refetchOnWindowFocus } = config || {}

    return useInfiniteQuery(
        [TIME_TRACKING_QUERY_KEY, 'report', 'list-paginated', query],
        ({ pageParam }) => timeTrackingReportListPaginated({
            ...query,
            page: pageParam || 1,
        }),
        {
            getNextPageParam: (lastPage) => lastPage ? lastPage.meta.nextPage : 1,
            initialData: () => ({
                pageParams: [1],
                pages: [],
            }),
            enabled,
            keepPreviousData,
            refetchOnWindowFocus,
        }
    )
}

