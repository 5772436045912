/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { Alert, Badge, Divider } from '@mantine/core'
import { Complaint } from '@viterbit/web-app/dataAccess/model/Complaint.model'
import i18n from '@viterbit/web-app/i18n'
import AsideCardInfo from '@viterbit/web-app/shared/display/AsideCardInfo'
import Chat from '@viterbit/web-app/shared/feedback/Chat/Chat'
import ChatBarForm, { ChatBarFormProps } from '@viterbit/web-app/shared/feedback/Chat/ChatBarForm'
import ChatMessages from '@viterbit/web-app/shared/feedback/Chat/ChatMessages'
import { ComplaintJWT } from '@viterbit/web-app/shared/state/complaintState'
import { formatDateTime } from '@viterbit/web-app/shared/utils/dateFormat'
import * as React from 'react'

import ComplaintDetailItem from './public/detail/ComplaintDetailItem'

type ComplaintDetailViewProps = {
    complaint: Complaint
    jwtInfo?: ComplaintJWT
    onSendMessage: ChatBarFormProps['onSubmit']
}

const renderDay = (date: string | null) => {
    if (!date) {
        return i18n.t('complaint:detail.dateEmpty')
    }
    return formatDateTime(date)
}


const ComplaintDetailView = (props: ComplaintDetailViewProps) => {
    const { complaint, jwtInfo, onSendMessage } = props

    const canSendMessage = ['CREATED', 'RECEIVED'].includes(complaint.status)

    return (
        <div className='flex flex-col gap-6' data-testid="complaint-detail-view">
            <AsideCardInfo
                title={i18n.t('complaint:detail.info.title')}
                description={i18n.t('complaint:detail.description.title')}
                actions={
                    <Badge
                        data-testid="complaint-status"
                        variant='filled'
                        color={{
                            'CREATED': 'gray',
                            'RECEIVED': 'success',
                            'CLOSED': 'danger',
                        }[complaint.status]}
                    >
                        {i18n.t(`complaint:status.${complaint.status}`)}
                    </Badge>
                }
            >
                {complaint.authorConfirmed && (
                    <>
                        <p className='mt-10 mb-4'>{i18n.t('complaint:detail.authorTitle')}</p>
                        <dl className="flex flex-col">
                            {complaint.authorName && (
                                <ComplaintDetailItem data-testid="detail-item-authorName" label={i18n.t('complaint:detail.item.authorName')} value={complaint.authorName} />
                            )}
                            {complaint.authorPhone && (
                                <ComplaintDetailItem data-testid="detail-item-authorPhone" label={i18n.t('complaint:detail.item.authorPhone')} value={complaint.authorPhone} />
                            )}
                            {complaint.authorEmail && (
                                <ComplaintDetailItem data-testid="detail-item-authorEmail" label={i18n.t('complaint:detail.item.authorEmail')} value={complaint.authorEmail} />
                            )}
                            {complaint.authorRelationship && (
                                <ComplaintDetailItem data-testid="detail-item-authorRelationship" label={i18n.t('complaint:detail.item.authorRelationship')} value={complaint.authorRelationship} />
                            )}
                        </dl>
                    </>
                )}

                    
                <p className='mt-10 mb-4'>{i18n.t('complaint:detail.summaryInfo')}</p>
                <dl className="flex flex-col">
                    {jwtInfo?.password && (<ComplaintDetailItem data-testid="detail-item-password" label={i18n.t('complaint:detail.item.password')} value={jwtInfo.password} />)}
                    <ComplaintDetailItem data-testid="detail-item-reference" label={i18n.t('complaint:detail.item.reference')} value={complaint.id} />
                </dl>
                {jwtInfo?.password && (
                    <Alert
                        className='mt-4'
                        color='blue'
                    >
                        {i18n.t('complaint:detail.credentialsDescription')}
                    </Alert>
                )}
            </AsideCardInfo>

            <Divider />

            <AsideCardInfo
                title={i18n.t('complaint:detail.activity.title')}
                description={i18n.t('complaint:detail.activity.description')}
            >
                <p className='mt-10 mb-4'>{i18n.t('complaint:detail.additionalInfo')}</p>
                <dl className="flex flex-col">
                    <ComplaintDetailItem label={i18n.t('complaint:detail.item.createdAt')} value={renderDay(complaint.createdAt)} />
                    {complaint.updatedAt && <ComplaintDetailItem data-testid="detail-item-updatedAt" label={i18n.t('complaint:detail.item.updatedAt')} value={renderDay(complaint.updatedAt)} />}
                    {complaint.receivedAt && <ComplaintDetailItem data-testid="detail-item-receivedAt" label={i18n.t('complaint:detail.item.receivedAt')} value={renderDay(complaint.receivedAt)} />}
                    {complaint.finishedAt && <ComplaintDetailItem data-testid="detail-item-finishedAt" label={i18n.t('complaint:detail.item.finishedAt')} value={renderDay(complaint.finishedAt)} />}
                </dl>  
                    
                <Chat className='mt-12' >
                    <ChatMessages items={complaint.records} />

                    {canSendMessage &&(
                        <ChatBarForm
                            onSubmit={(data) => onSendMessage!(data)}
                        />
                    )}

                    {!canSendMessage && (
                        <Alert
                            className='mx-4 text-center'
                            color='warning'
                        >
                            {i18n.t('complaint:detail.chatDisabled')}
                        </Alert>
                    )}
                </Chat>
            </AsideCardInfo>
        </div>
    )
}

export default ComplaintDetailView
