
import i18n from '@viterbit/web-app/i18n'
import BasicCreateFormDialog from '@viterbit/web-app/shared/form/BasicCreateFormDialog'
import ButtonCreate from '@viterbit/web-app/shared/form/ButtonCreate'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import { leavePolicyCreate } from '../../../../dataAccess/service/leavePolicyService'


const CreateLeavePoliciesDialog = () => {
    const [open, setOpen] = React.useState(false)
    const navigate = useNavigate()

    const onSuccess = async (result: any) => {
        onClose()
        navigate(`/settings/policies/${result.id}/details`)
    }

    const onClose = () => {
        setOpen(false)
    }

    return <>
        <ButtonCreate collapsed title={i18n.t('settingLeaves:addPolicy')} onClick={() => setOpen(true)} />

        <BasicCreateFormDialog
            isOpen={open}
            withTransition
            title={i18n.t('settingLeaves:addLeavePolicy')}
            onClose={onClose}
            label={i18n.t('settingLeaves:leavePolicy')}
            placeholder={i18n.t('settingLeaves:enterLeavePolicyName')}
            mutationConfig={{
                onSuccess,
                mutationFn: (data) => leavePolicyCreate({
                    createLeavePolicyCommand: data
                }),
            }}
        />

    </>
}

export default CreateLeavePoliciesDialog
