import { WorkSchedule } from '@viterbit/web-app/dataAccess/model/WorkSchedule.model'
import { useWorkScheduleDelete } from '@viterbit/web-app/dataAccess/useCase/workSchedule/useWorkScheduleDelete'
import i18n from '@viterbit/web-app/i18n'
import ApiErrorMessage from '@viterbit/web-app/shared/feedback/ApiErrorMessage'
import DeleteAlert from '@viterbit/web-app/shared/feedback/DeleteAlert'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import FormDeleteDialog from '../../../../shared/form/FormDeleteDialog'

type WorkScheduleDeleteActionProps = {
    workSchedule: WorkSchedule
}

const WorkScheduleDeleteAction = (props: WorkScheduleDeleteActionProps) => {
    const { workSchedule } = props

    const navigate = useNavigate()
    const [isDeleting, setIsDeleting] = React.useState(false)

    const onSuccess = async () => {
        setIsDeleting(false)
        navigate('/settings/work-schedule/', { replace: true })
    }

    const { mutate, isLoading, error } = useWorkScheduleDelete({ onSuccess })

    return (
        <>
            <DeleteAlert
                border
                buttonText={i18n.t('settingWorkSchedule:delete.alertButtonText')}
                onConfirm={() => setIsDeleting(true)}
            >
                {i18n.t('settingWorkSchedule:delete.alertMessage')}
         
            </DeleteAlert>

            <FormDeleteDialog
                isOpen={isDeleting}
                isLoading={isLoading}
                onClose={() => setIsDeleting(false)}
                onSubmit={() => {
                    mutate({ id: workSchedule.id })
                }}
                title={i18n.t('settingWorkSchedule:delete.alertButtonText')}
            >
                <ApiErrorMessage error={error} />
            </FormDeleteDialog>
        </>
    )
}

export default WorkScheduleDeleteAction
