import * as React from 'react'

import { BaseProps } from './util'
import { clsxm } from '../../lib/clsxm'

const classes = {
    td: 'break-words font-normal text-grey-500 truncate text-sm',
    variants: {
        default: {
            tbody: 'px-5 py-2',
            tfoot: 'px-5 py-2 border-t border-gray-200 dark:border-gray-700',
        },
        flex: {
            tbody: 'px-5 py-2',
            tfoot: 'px-5 py-2 border-t dark:border-gray-700',
        },
    },
    compact: {
        tbody: 'px-3 py-2',
        tfoot: '',
    },
}

export type TdProps = {
    container?: 'tbody' | 'tfoot'
    compact?: boolean
} & BaseProps &
    React.HTMLAttributes<HTMLTableCellElement>

const Td = React.forwardRef<HTMLTableCellElement, TdProps>(
    (
        {
            variant = 'default',
            container = 'tbody',
            className,
            compact,
            children,
            ...props
        },
        ref
    ) => (
        <td
            className={clsxm(
                classes.td,
                classes.variants[variant][container],
                compact && classes.compact[container],
                props.onClick && 'cursor-pointer',
                className
            )}
            ref={ref}
            {...props}
        >
            {children}
        </td>
    )
)

export default Td
