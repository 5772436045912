import * as React from 'react'

import { ExtendedModeType } from '../../Layout/sharedTypes'

type Props = {
    onSelect?: (eventKey: string, e: React.MouseEvent) => void
    variant: ExtendedModeType
    menuItemHeight: number
    defaultActiveKeys: string[]
    defaultExpandedKeys: string[]
    sideCollapsed: boolean
}

const MenuContext = React.createContext<Props>({
    variant: 'light',
    defaultActiveKeys: [],
    defaultExpandedKeys: [],
    sideCollapsed: false,
    menuItemHeight: 40,
})

export const MenuContextProvider = MenuContext.Provider

export const MenuContextConsumer = MenuContext.Consumer

export default MenuContext
