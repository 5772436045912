import { IMapper } from './__common'
import { JobPositionLevelResponse } from '../dto/jobPositionLevel'
import { JobPositionLevel } from '../model/JobPositionLevel.model'

const jobPositionLevelMapper: IMapper<JobPositionLevel, JobPositionLevelResponse> = {
    toModel: (dto) => ({
        id: dto.id,
        name: dto.name,
        description: dto.description ?? undefined
    }),

    toDto: (model) => ({
        id: model.id,
        name: model.name,
        description: model.description,
    }),
}

export default jobPositionLevelMapper