import CreateCompany from '@viterbit/web-app/feature/settings/companies/create/CreateCompany'
import useCompany from '@viterbit/web-app/feature/settings/companies/detail/useCompany'
import CompaniesList from '@viterbit/web-app/feature/settings/companies/list/CompaniesList'
import i18n from '@viterbit/web-app/i18n'
import SettingsLayout from '@viterbit/web-app/shared/layout/SettingsLayout'
import { Outlet } from 'react-router-dom'

const CompaniesView =  () => {
    const { data } = useCompany()
    const title = data?.name || i18n.t('navigation:navbar.settings.companies')

    return (
        <SettingsLayout
            leftTitle={i18n.t('settingCompanies:companies')}
            leftActions={<CreateCompany />}
            title={title}
            left={<CompaniesList />}
        >
            <Outlet />
        </SettingsLayout>
    )
}

export default CompaniesView
