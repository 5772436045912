
import { DaysRequestsEnum } from '@viterbit/web-app/feature/leave/list/Forms/types'
import i18n from '@viterbit/web-app/i18n'
import { parseDateUTC } from '@viterbit/web-app/shared/utils/dateParse'

import fileMapper from './file.mapper'
import leaveReasonMapper from './leaveReason.mapper'
import { LeaveResponse } from '../dto'
import { Leave } from '../model/Leave.model'

const getDayOption = (config: { halfDay: boolean,initialDate: string,endDate?: string | null }): DaysRequestsEnum => {
    if (config.halfDay) return DaysRequestsEnum.HalfDay

    const isOneDay = config.initialDate === config.endDate || !config.endDate
    return isOneDay ? DaysRequestsEnum.OneDay : DaysRequestsEnum.SeveralDays
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getLeaveDayText = (config: { halfDay: boolean, calendar: any[]}) => {
    if (config.calendar.length === 0) {
        return `0 ${i18n.t('leaves:relevantDays.days') || 'Days'}`
    }

    if (config.halfDay) {
        return i18n.t('leaves:dayTabs.halfDay') || 'Half Day'
    }

    if (config.calendar.length === 1) {
        return `1 ${i18n.t('leaves:relevantDays.day') || 'Day'}`
    }

    return `${config.calendar.length} ${i18n.t('leaves:relevantDays.days') || 'Days'}`
}

const LeaveMapper = {
    toModel: (dto: LeaveResponse): Leave => {
        const id = dto?.id || `${dto.initialDate}-${dto.endDate}}`
        const halfDay = Boolean(dto.halfDay)
        const daysText = getLeaveDayText(dto)
        const daysOption = getDayOption(dto)

        return {
            id,
            status: dto.status,
            initialDate: dto.initialDate,
            halfDay,
            endDate: dto.endDate ?? undefined,
            comment: dto.comment ?? undefined,
            leaveReason:
                dto.leaveReason && leaveReasonMapper.toModel(dto.leaveReason),
            // TODO: implements files
            files: dto.files?.data?.map(fileMapper.toModel) ?? [],
            calendar: dto.calendar ?? [],
            turn: dto.turn as 'MORNING' | 'AFTERNOON' | undefined,
            daysOption,
            daysText,
        }
    },

    toDto: (model: Leave): LeaveResponse => ({
        id: model.id,
        status: model.status,
        initialDate: parseDateUTC(model.initialDate),
        endDate:
            model.daysOption === DaysRequestsEnum.SeveralDays
                ? model.endDate && parseDateUTC(model.endDate)
                : undefined,
        comment: model.comment || undefined,
        leaveReason: model.leaveReason,
        calendar: model.calendar,
        halfDay: model.daysOption === DaysRequestsEnum.HalfDay,
        // TODO: implements files
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        files: model.files as any,
        turn: model.turn as string,
    }),
    getLeaveDayText
}

export default LeaveMapper
