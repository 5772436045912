import { IMapper } from './__common'
import leaveReasonMapper from './leaveReason.mapper'
import { LeavePolicyResponse } from '../dto'
import { LeavePolicy } from '../model/LeavePolicy.model'

const leavePolicyMapper: IMapper<LeavePolicy, LeavePolicyResponse> = {
    toModel: (dto) => ({
        id: dto.id,
        name: dto.name,
        description: dto.description ?? undefined,
        default: dto._default ?? undefined,
        daysPerYear: dto.daysPerYear ?? undefined,
        daysRange: dto.dayRange,
        businessDays: Boolean(dto?.businessDays),

        reasons: dto.leaveReasons?.data?.map(leaveReasonMapper.toModel),
    }),

    toDto: (model) => ({
        id: model.id,
        name: model.name,
        description: model.description,
        default: model.default,
        daysPerYear: model.daysPerYear,
        dayRange: model.daysRange,
        businessDays: model.businessDays,

        // todo remove this
        // leaveReasons: { data: model.reasons?.map(leaveReasonMapper.toDto) },
    }),
}

export default leavePolicyMapper
