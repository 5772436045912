import { IconChevronRight, IconTrashX } from '@tabler/icons-react'
import i18n from '@viterbit/web-app/i18n'
import * as React from 'react'
import Button from 'ui/src/components/Button'
import List from 'ui/src/components/List'

import DateTimeItemRangeDate from './DateTimeItemRangeDate'
import DateTimeItemTitle from './DateTimeItemTitle'

type DateTimeItemProps = {
    id: string
    initialDate: string
    endDate?: string
    color?: string
    title: string
    onClick?: (id: string) => void
    className?: string
    content: string
    onDelete?: (id: string) => void
}

const DateTimeItem = (props: DateTimeItemProps) => {
    const { id, initialDate, endDate, color, title, content, onClick, className, onDelete} = props
    return (
        <List.Item
            onClick={() => onClick?.(id)}
            className={className}
            clickable={!!onClick}
        >
            <DateTimeItemRangeDate initialDate={initialDate} endDate={endDate} locale={i18n.resolvedLanguage} />

            <List.ItemText
                primary={<DateTimeItemTitle title={title} color={color} />}
                secondary={content}
            />

            {onClick && <IconChevronRight className='w-5 h-5 text-gray-500' />}
            {onDelete && (
                <Button
                    type='button'
                    shape='circle'
                    variant='plain'
                    size='xs'
                    onClick={() =>onDelete(id)}
                    icon={<IconTrashX />}
                    className='inline-flex items-center justify-center text-gray-400 bg-transparent bg-white rounded-full hover:text-gray-500'
                />
            )}

        </List.Item>
    )
}

export default DateTimeItem
