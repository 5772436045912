import { Employee } from '@viterbit/web-app/dataAccess/model/Employee.model'
import { useEmployeeCounterList } from '@viterbit/web-app/dataAccess/useCase/employee/useEmployeeCounterList'
import i18n from '@viterbit/web-app/i18n'

import DaysCounters from './DaysCounters'
import SummaryDetailsTooltip from './SummaryDetailsTooltip'
import SummaryLoading from './SummaryLoading'
import SummaryTotalsTooltip from './SummaryTotalsTooltip'

type DaysSummaryProps = {
    employee: Employee
    year: number
}

const DaysSummary = ({ employee, year }: DaysSummaryProps) => {
    const { data: summary, isLoading } = useEmployeeCounterList({
        query: {
            id: employee.id,
            year: String(year),
        }
    })

    const extraDays = employee.leaveAdjustments.find((leaveAdjustment) => leaveAdjustment.year === year)

    if (isLoading || !summary) return <SummaryLoading />

    return (
        <div className='flex items-center gap-x-6'>
            <div>
                <SummaryDetailsTooltip
                    employee={employee}
                    summary={summary}
                    extraDays={extraDays}
                >
                    <DaysCounters
                        count={summary.availableDays}
                        label={i18n.t('daysSummary:daysAvailable')}
                        placeholder={i18n.t('daysSummary:summaryDays')}
                    />
                </SummaryDetailsTooltip>
            </div>

            <SummaryTotalsTooltip summary={summary}>
                <DaysCounters
                    count={summary.usedTotalDays}
                    label={i18n.t('daysSummary:daysUsed')}
                    placeholder={i18n.t('daysSummary:details')}
                />
            </SummaryTotalsTooltip>
        </div>
    )
}

export default DaysSummary
