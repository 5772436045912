import { useQuery } from '@tanstack/react-query'
import { Api } from 'types/Api'

import { COMPLAINT_KEY } from '../../cacheKeys'
import { complaintPublicDetail } from '../../service/complaintService'

type ComplaintDetailConfig = {
    enabled?: boolean
}

export const useComplaintPublicDetail = ({ enabled }: ComplaintDetailConfig = {}) =>
    useQuery<Awaited<ReturnType<typeof complaintPublicDetail>>, {response: Api.ResponseError}>({
        queryFn: () => complaintPublicDetail(), 
        queryKey: [COMPLAINT_KEY, 'detail'],
        enabled
    })

