/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react'

import MenuContext from './context/menuContext'
import DropdownItem from './DropdownItem'
import Menu from './Menu'
import { DropdownPlacementType, DropdownTriggerType } from './sharedTypes'
import { clsxm } from '../../lib/clsxm'

const classes = {
    menu: 'min-w-[160px] rounded-md shadow-lg bg-white dark:bg-gray-700 dark:shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none p-1.5  absolute z-30',
    submenu: 'left-full top-0 origin-top',
    placement: {
        'top-start': 'left-0 rtl:right-0 bottom-full origin-bottom',
        'top-center': 'bottom-full left-1/2 origin-bottom',
        'top-end': 'right-0 rtl:left-0 bottom-full origin-bottom',
        'bottom-start': 'left-0 rtl:right-0 top-full origin-top',
        'bottom-center': 'left-1/2 top-full origin-top',
        'bottom-end': 'right-0 rtl:left-0 top-full origin-top',
        'middle-start-top': 'left-full top-0 origin-top',
        'middle-start-bottom': 'left-full bottom-0 origin-bottom',
        'middle-end-top': 'right-full top-0 origin-top',
        'middle-end-bottom': 'right-full bottom-0 origin-bottom',
    },
}

export type DropdownMenuProps = {
    id?: string
    className?: string
    placement?: DropdownPlacementType
    title?: React.ReactNode | string
    eventKey?: string
    icon?: React.ReactNode
    trigger?: DropdownTriggerType
    children?: React.ReactNode
    style?: unknown
    activeKey?: string
    hidden?: boolean
    onToggle?: (eventKey: string, e: React.MouseEvent) => void
    onSelect?: (eventKey: string, e: React.MouseEvent) => void
}

const DropdownMenu = React.forwardRef<HTMLDivElement, DropdownMenuProps>(
    (props, ref) => {
        const {
            onToggle,
            eventKey = '',
            title,
            className,
            placement = 'bottom-start',
            ...rest
        } = props

        const parentMenu = React.useContext(MenuContext)

        const handleToggleSubmenu = React.useCallback(
            (_: any, e: any) => {
                onToggle?.(eventKey, e)
            },
            [eventKey, onToggle]
        )

        const dropdownMenuDefaultClass = classes.menu
        const dropdownMenuPositionClass = classes.placement[placement]

        const dropdownMenuClass = clsxm(
            dropdownMenuDefaultClass,
            dropdownMenuPositionClass,
            className
        )

        const dropdownSubmenuClass = clsxm(
            dropdownMenuDefaultClass,
            classes.submenu
        )

        const dropdownSubmenu = (
            <Menu
                menuClass={dropdownSubmenuClass}
                ref={ref}
                onToggle={handleToggleSubmenu}
                placement={placement}
                {...rest}
            />
        )

        if (parentMenu) {
            const { icon, trigger } = props
            const itemClassName = clsxm(className)

            return (
                <DropdownItem
                    icon={icon}
                    trigger={trigger}
                    className={itemClassName}
                    submenu={dropdownSubmenu}
                    eventKey={eventKey}
                >
                    {title}
                </DropdownItem>
            )
        }

        return (
            <Menu
                menuClass={dropdownMenuClass}
                placement={placement}
                ref={ref}
                {...rest}
            />
        )
    }
)

export default DropdownMenu
