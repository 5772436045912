import { Button, ButtonProps, Loader } from '@mantine/core'
import { IconCheck } from '@tabler/icons-react'
import * as React from 'react'

import ToggleIcon from '../ToogleIcon'
import clsxm from '../../lib'

export type FormButtonProps = {
    children?: React.ReactNode
    className?: string
    success?: boolean
    form?: string
} & ButtonProps & React.HTMLAttributes<HTMLButtonElement>

const styles = {
    root: '',
    icon: ''
}

const FormButton = React.forwardRef<HTMLButtonElement, FormButtonProps>((props, ref) => {
    const { children, className, success, loading, variant = 'filled', type = 'submit', ...rest } = props
    const classes = clsxm(styles.root, className)

    const overriddenColor = success ? 'success' : 'primary'

    return (
        <Button
            {...rest}
            type={type}
            className={classes}
            leftIcon={(
                <ToggleIcon
                    open={loading || success}
                    toggled={!!success}
                    icon={(
                        <Loader
                            size={18}
                            color={variant === 'filled' ? '#fff' : undefined}
                        />
                    )}
                    toggledIcon={(
                        <IconCheck className={styles.icon} />
                    )}
                />
            )}
            color={overriddenColor}
        >
            {children}
        </Button>
    )
})


export default FormButton
