import { Account } from './Account.model'
import { Company } from './Company.model'
import { FileModel } from './File.model'
import { JobPosition } from './JobPosition.model'
import { JobPositionLevel } from './JobPositionLevel.model'
import { Leave } from './Leave.model'
import { LeaveAdjustment } from './LeaveAdjustment.model'
import { LeavePolicy } from './LeavePolicy.model'
import { Location } from './Location.model'
import { Planning } from './Planning.model'
import { IWeekDays } from './Week.model'

export interface Manager {
    id: string
    name: string
    email: string
    surname?: string
    avatar?: FileModel
}

export interface EmployeeEmergencyInformation {
    name: string
    email: string
    phoneNumber: string
}

export const EmployeeContractHoursPeriod = ['HOURLY', 'DAILY', 'WEEKLY', 'MONTHLY', 'YEARLY'] as const
export const EmployeeContractModality = ['ON_SITE', 'HYBRID', 'REMOTE'] as const
export const EmployeeContractDayType = ['FULL', 'PARTIAL'] as const

export interface Employee {
    id: string
    color: string
    email: string
    name: string
    surname?: string
    pronouns?: string
    gender?: string
    nationality?: string
    phone?: string
    birthday?: string
    address?: string
    city?: string
    country?: string
    postalCode?: string
    state?: string
    salary?: number
    startDate?: string
    endDate?: string
    hasAccount: boolean
    manager?: Manager
    location?: Location
    leavePolicy?: LeavePolicy
    company?: Company
    account?: Account
    leaveAdjustments: LeaveAdjustment[]
    leaves?: Leave[]
    bankName?: string
    bankSwift?: string
    bankNumber?: string
    fiscalInformation: object
    jobPosition?: JobPosition
    jobPositionLevel?: JobPositionLevel
    jobPositionAlias?: string
    avatar?: FileModel
    idFile?: FileModel
    emergencyInformation?: EmployeeEmergencyInformation
    reporters?: number
    deleteInformation?: {
        endDate: string
        observations: string
        reason: string
        newManagerId: string
        newManagerName: string
    },
    isDeleted: boolean
    contractHoursPeriod?: typeof EmployeeContractHoursPeriod[number]
    contractHoursAmount?: number
    contractModality?: typeof EmployeeContractModality[number]
    contractDayType?: typeof EmployeeContractDayType[number]
    contractDays: IWeekDays[]
    planning: Planning[]
    estimation: {
        estimation: number
        worked: number
        balance: number
    }
    headline: string
    isActive: boolean
    status: 'active' | 'inactive' | 'pending'
}
