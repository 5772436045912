import Text from '@viterbit/web-app/shared/typography/Text'
import * as React from 'react'
import { NavLink } from 'react-router-dom'
import { clsxm } from 'ui/src/lib/clsxm'


type BreadcrumbsItemProps = {
    children: React.ReactNode
    href?: string
    current?: boolean
}

const STYLES = {
    root: 'max-w-[180px] truncate',
}

const BreadcrumbsItem = (props: BreadcrumbsItemProps) => {
    const { href, current, children } = props

    if (href) {
        return (
            <NavLink
                to={href}
                className={
                    clsxm(STYLES.root)
                }
                aria-current={current ? 'page' : undefined}
            >
                <Text bold={current}>
                    {children}
                </Text>
            </NavLink>
        )
    }

    return (
        <Text className={clsxm(STYLES.root)}>
            {children}
        </Text>
    )
}

export default BreadcrumbsItem
