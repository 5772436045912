/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ListIntegrationOptionQueryResponse,
  ListIntegrationQueryResponse,
  ResourceCreated,
} from '../models/index';
import {
    ListIntegrationOptionQueryResponseFromJSON,
    ListIntegrationOptionQueryResponseToJSON,
    ListIntegrationQueryResponseFromJSON,
    ListIntegrationQueryResponseToJSON,
    ResourceCreatedFromJSON,
    ResourceCreatedToJSON,
} from '../models/index';

export interface CheckIntegrationOauthRequest {
    type: CheckIntegrationOauthTypeEnum;
}

export interface ConnectIntegrationOauthRequest {
    type: ConnectIntegrationOauthTypeEnum;
    kind: ConnectIntegrationOauthKindEnum;
}

export interface DeleteIntegrationRequest {
    id: string;
    body?: object;
}

export interface ListIntegrationOptionsRequest {
    page?: number;
    perPage?: number;
}

export interface ListIntegrationsRequest {
    type?: ListIntegrationsTypeEnum;
    kind?: ListIntegrationsKindEnum;
    page?: number;
    perPage?: number;
}

/**
 * 
 */
export class IntegrationApi extends runtime.BaseAPI {

    /**
     * Check integration OAuth
     * Check integration OAuth
     */
    async checkIntegrationOauthRaw(requestParameters: CheckIntegrationOauthRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling checkIntegrationOauth.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/automation/integrations/oauth/check/{type}`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Check integration OAuth
     * Check integration OAuth
     */
    async checkIntegrationOauth(requestParameters: CheckIntegrationOauthRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.checkIntegrationOauthRaw(requestParameters, initOverrides);
    }

    /**
     * Connect integration OAuth
     * Connect integration OAuth
     */
    async connectIntegrationOauthRaw(requestParameters: ConnectIntegrationOauthRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling connectIntegrationOauth.');
        }

        if (requestParameters.kind === null || requestParameters.kind === undefined) {
            throw new runtime.RequiredError('kind','Required parameter requestParameters.kind was null or undefined when calling connectIntegrationOauth.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/automation/integrations/oauth/connect/{type}/{kind}`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))).replace(`{${"kind"}}`, encodeURIComponent(String(requestParameters.kind))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Connect integration OAuth
     * Connect integration OAuth
     */
    async connectIntegrationOauth(requestParameters: ConnectIntegrationOauthRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.connectIntegrationOauthRaw(requestParameters, initOverrides);
    }

    /**
     * Delete a integration
     * Delete a integration
     */
    async deleteIntegrationRaw(requestParameters: DeleteIntegrationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteIntegration.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/automation/integrations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Delete a integration
     * Delete a integration
     */
    async deleteIntegration(requestParameters: DeleteIntegrationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.deleteIntegrationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * list a integration options
     * list a integration options
     */
    async listIntegrationOptionsRaw(requestParameters: ListIntegrationOptionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListIntegrationOptionQueryResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.perPage !== undefined) {
            queryParameters['perPage'] = requestParameters.perPage;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/automation/integrations/options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListIntegrationOptionQueryResponseFromJSON(jsonValue));
    }

    /**
     * list a integration options
     * list a integration options
     */
    async listIntegrationOptions(requestParameters: ListIntegrationOptionsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListIntegrationOptionQueryResponse> {
        const response = await this.listIntegrationOptionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * list a integrations
     * list a integration
     */
    async listIntegrationsRaw(requestParameters: ListIntegrationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListIntegrationQueryResponse>> {
        const queryParameters: any = {};

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.kind !== undefined) {
            queryParameters['kind'] = requestParameters.kind;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.perPage !== undefined) {
            queryParameters['perPage'] = requestParameters.perPage;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/automation/integrations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListIntegrationQueryResponseFromJSON(jsonValue));
    }

    /**
     * list a integrations
     * list a integration
     */
    async listIntegrations(requestParameters: ListIntegrationsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListIntegrationQueryResponse> {
        const response = await this.listIntegrationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const CheckIntegrationOauthTypeEnum = {
    Google: 'google'
} as const;
export type CheckIntegrationOauthTypeEnum = typeof CheckIntegrationOauthTypeEnum[keyof typeof CheckIntegrationOauthTypeEnum];
/**
 * @export
 */
export const ConnectIntegrationOauthTypeEnum = {
    Google: 'google'
} as const;
export type ConnectIntegrationOauthTypeEnum = typeof ConnectIntegrationOauthTypeEnum[keyof typeof ConnectIntegrationOauthTypeEnum];
/**
 * @export
 */
export const ConnectIntegrationOauthKindEnum = {
    Google: 'google'
} as const;
export type ConnectIntegrationOauthKindEnum = typeof ConnectIntegrationOauthKindEnum[keyof typeof ConnectIntegrationOauthKindEnum];
/**
 * @export
 */
export const ListIntegrationsTypeEnum = {
    Google: 'google',
    Microsoft: 'microsoft',
    Slack: 'slack',
    Zapier: 'zapier',
    Viterbit: 'viterbit'
} as const;
export type ListIntegrationsTypeEnum = typeof ListIntegrationsTypeEnum[keyof typeof ListIntegrationsTypeEnum];
/**
 * @export
 */
export const ListIntegrationsKindEnum = {
    Email: 'email',
    Calendar: 'calendar',
    Message: 'message',
    Other: 'other'
} as const;
export type ListIntegrationsKindEnum = typeof ListIntegrationsKindEnum[keyof typeof ListIntegrationsKindEnum];
