import { WorkSchedule } from './WorkSchedule.model'

export const PlanningTypes = ['CONTRACT', 'WORK_SCHEDULE'] as const

export interface PlanningBase {
    id: string
    startDate: string
    type: typeof PlanningTypes[number]
    workSchedule: WorkSchedule | null
    isWorkSchedule: boolean
    isContract: boolean
    isDefault?: boolean
}

export interface PlanningContract extends PlanningBase {
    type: 'CONTRACT'
    workSchedule: null
    isWorkSchedule: false
    isContract: true
    isDefault: boolean
}

export interface PlanningWorkSchedule extends PlanningBase {
    type: 'WORK_SCHEDULE'
    workSchedule: WorkSchedule
    isWorkSchedule: true
    isContract: false
    isDefault: false
}

export type Planning = PlanningContract | PlanningWorkSchedule