import * as React from 'react'
import ListItemSkeleton from 'ui/src/components/List/ListItemSkeleton'

const SummaryLoading = () => (
    <div className='flex gap-y-8'>
        <ListItemSkeleton className="w-[180px] px-2 py-0 min-h-[40px]" primary secondary icon fullWidth />
        <ListItemSkeleton className="w-[180px] px-2 py-0 min-h-[40px]" primary secondary icon fullWidth />
    </div>
)

export default SummaryLoading
