import { BillingInfo } from 'viterbit-api-sdk'

import { IMapper } from './__common'
import { BillingInfo as Model } from '../model/BillingInfo.model'

const billingInfoMapper: IMapper<Model, BillingInfo> = {
    toModel: (dto) => ({
        name: dto.name || '',
        email: dto.email || '',
        phone: dto.phone || '',
        vatNumber: dto.vatNumber || '',
        address: dto.address || '',
        country: dto.country || '',
        city: dto.city || '',
        postalCode: dto.postalCode || '',
        state: dto.state || '',
    }),
    toDto: (model) => ({
        name: model.name, 
        email: model.email, 
        phone: model.phone, 
        vatNumber: model.vatNumber, 
        address: model.address, 
        country: model.country, 
        city: model.city, 
        postalCode: model.postalCode, 
        state: model.state, 
    }),
}

export default billingInfoMapper
