/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { usePendingFileBulkInfo } from '@viterbit/web-app/dataAccess/useCase/pendingFile/usePendingFileBulkInfo'
import DaysCounters from '@viterbit/web-app/feature/leave/list/Summary/DaysCounters'
import SummaryLoading from '@viterbit/web-app/feature/leave/list/Summary/SummaryLoading'
import i18n from '@viterbit/web-app/i18n'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { UploadFileTypeEnum } from 'viterbit-api-sdk'

import DocumentBulkUploadDialog from './DocumentBulkUploadAssignDialog'
import DocumentBulkUploadForm from './DocumentBulkUploadAssignForm'

type DocumentBulkUploadAssignProps = {
    type: UploadFileTypeEnum
}

const DocumentBulkUploadAssign = ({ type }: DocumentBulkUploadAssignProps) => {

    const [open, setOpen] = React.useState(false)
    const navigate = useNavigate()

    const { isLoading, data } = usePendingFileBulkInfo({
        query: { type: type as any }
    })

    const onClose = () => {
        setOpen(false)
    }

    if (isLoading) {
        return (
            <SummaryLoading />
        )
    }

    return (
        <>
            <DaysCounters
                count={data!.assigned}
                label={i18n.t('employees:bulkUpload.bulkAssign.summary.assigned.label')}
                placeholder={i18n.t('employees:bulkUpload.bulkAssign.summary.assigned.placeholder')}
                onClick={() => setOpen(true)}
                disabled={!data!.assigned}
            />

            <DaysCounters
                count={data!.total}
                label={i18n.t('employees:bulkUpload.bulkAssign.summary.total.label')}
                placeholder={i18n.t('employees:bulkUpload.bulkAssign.summary.total.placeholder')}
                onClick={() => navigate(`/core-hr/bulk/documents/${type}/preview`)}
                disabled={!data!.total}
            />

            <DocumentBulkUploadDialog
                open={open}
                onClose={onClose}
            >
                <DocumentBulkUploadForm
                    assigned={data?.assigned || 0}
                    total={data?.total || 0}
                    onSubmitCallBack={(onClose)}
                    type={type}
                />
            </DocumentBulkUploadDialog>
        </>
    )
}

export default DocumentBulkUploadAssign
