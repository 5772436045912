/* eslint-disable @typescript-eslint/no-explicit-any */
import { IconTrash } from '@tabler/icons-react'
import i18n from '@viterbit/web-app/i18n'
import * as React from 'react'

import ButtonIcon, { ButtonIconProps } from './ButtonIcon'

type ButtonDeleteProps = {
    title?: string
    IconComponent?: any
} & Omit<ButtonIconProps, 'title' | 'IconComponent'>

const ButtonDelete = (props: ButtonDeleteProps) => {
    const { title = i18n.t('buttons.delete'), IconComponent = IconTrash, ...rest } = props

    return (
        <ButtonIcon
            title={title}
            IconComponent={IconComponent}
            {...rest}
        />
    )
}

export default ButtonDelete
