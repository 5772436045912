import i18n from '@viterbit/web-app/i18n'
import SectionLayout from '@viterbit/web-app/shared/layout/SectionLayout'
import * as React from 'react'

import LeaveTeamDetail from './LeaveTeamDetail'

const LeaveTeamView =  () => (
    <SectionLayout
        noPadding
        title={i18n.t('employees:calendar.header')}
    >
        <LeaveTeamDetail />
    </SectionLayout>
)

export default LeaveTeamView

