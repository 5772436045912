import { navigationItems } from '@viterbit/web-app/routes'
import React from 'react'
import {
    createBrowserRouter,
    RouterProvider as ReactRouterProvider,
} from 'react-router-dom'


const RouterProvider = () => {

    const router = React.useMemo(() => createBrowserRouter(navigationItems), [])

    return (
        <ReactRouterProvider router={router} />
    )
}

export default RouterProvider