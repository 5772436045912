/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    IconCalendarEvent,
    IconCalendarStats,
    IconClockPlus,
    IconFolder,
    IconShield,
    IconSignature,
    IconUser,
} from '@tabler/icons-react'
import i18n from '@viterbit/web-app/i18n'
import NavigationTabs from '@viterbit/web-app/shared/navigation/Tabs/NavigationTabs'
import * as React from 'react'

type TabsKey = 'personal' | 'profile' | 'contracts' | 'documents' | 'leaves' | 'time-tracking' | 'planning'

export type EmployeeTabsProps = {
    children?: React.ReactNode
    tabsPermissions: Record<TabsKey, boolean>
    basePath: string
    isLoading: boolean
}

const EmployeeDetailTabList = ({ tabsPermissions, basePath, children, isLoading }: EmployeeTabsProps) => (
    <NavigationTabs<TabsKey>
        isLoading={isLoading}
        tabs={[
            {
                key: 'profile',
                path: basePath,
                title: i18n.t('employees:employee.tabs.profile'),
                icon: <IconUser size={18} />,
                exact: true,
                hasPermission: tabsPermissions.profile,
            },
            {
                key: 'personal',
                path: `${basePath}/personal`,
                title: i18n.t('employees:employee.tabs.personal'),
                icon: <IconShield size={18} />,
                exact: false,
                hasPermission: tabsPermissions.personal,
            },
            {
                key: 'contracts',
                path: `${basePath}/contracts`,
                title: i18n.t('employees:employee.tabs.contracts'),
                icon: <IconSignature size={18} />,
                exact: false,
                hasPermission: tabsPermissions.contracts,
            },
            {
                key: 'planning',
                path: `${basePath}/planning`,
                title: i18n.t('employees:employee.tabs.planning'),
                icon: <IconCalendarStats size={18} />,
                hasPermission: tabsPermissions.planning,
            },
            {
                key: 'leaves',
                path: `${basePath}/leaves/${new Date().getFullYear()}`,
                title: i18n.t('employees:employee.tabs.leaves'),
                icon: <IconCalendarEvent size={18} />,
                exact: false,
                hasPermission: tabsPermissions.leaves,
            },
            {
                key: 'documents',
                path: `${basePath}/documents`,
                title: i18n.t('employees:employee.tabs.documents'),
                icon: <IconFolder size={18} />,
                exact: false,
                hasPermission: tabsPermissions.documents,
            },
            {
                key: 'time-tracking',
                path: `${basePath}/time-tracking`,
                title: i18n.t('employees:employee.tabs.timeTracking'),
                icon: <IconClockPlus size={18} />,
                hasPermission: tabsPermissions['time-tracking'],
            },
        ]}
    >
        {children}
    </NavigationTabs>
)

export default EmployeeDetailTabList
