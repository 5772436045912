/* eslint-disable @typescript-eslint/no-explicit-any */
import { Skeleton } from '@mantine/core'
import { useCurrentEmployee } from '@viterbit/web-app/dataAccess/useCase/employee/useCurrentEmployee'
import LeaveProvider from '@viterbit/web-app/feature/leave/LeaveProvider'
import { useCurrentEmployeeId } from '@viterbit/web-app/shared/employee/useCurrentEmployeeId'
import SectionLayout from '@viterbit/web-app/shared/layout/SectionLayout'
import * as React from 'react'
import { Outlet, useHref } from 'react-router-dom'

import EmployeeDetailView from './EmployeeDetailView'

type EmployeeDetailViewProps = {
    children?: React.ReactNode
}

const skeleton = <Skeleton height={12} radius="md" width="200px"  />

const EmployeeDetailLayout = ({ children }: EmployeeDetailViewProps) => {
    const employeeId = useCurrentEmployeeId()
    const href = useHref({})
    const basePath = href.startsWith('/me') ? '/me/profile': `/employees/list/${employeeId}`

    const currentUser = useCurrentEmployee()


    if (!employeeId) {
        return null
    }
    
    return (
        <LeaveProvider>
            <SectionLayout
                noPadding
                title={currentUser?.name || skeleton as any}
            >
                <EmployeeDetailView
                    id={employeeId}
                    basePath={basePath}
                    isLoading={!currentUser}
                >
                    <Outlet />
                    {children}
                </EmployeeDetailView>
            </SectionLayout>
        </LeaveProvider>
    )
}

export default EmployeeDetailLayout
