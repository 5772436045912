
import { classValidatorResolver } from '@hookform/resolvers/class-validator'
import { createLead } from '@viterbit/web-app/dataAccess/service/authService'
import Form from '@viterbit/web-app/shared/form/Form'
import SubmitButton from '@viterbit/web-app/shared/form/SubmitButton'
import * as React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import FormInput from 'ui/src/components/Form/FormInput'
import LayoutHeading from 'ui/src/components/LayoutHeading'

import SignUpRequestValidator from './SignUpRequestValidator'

export type SignUpFields = {
    email: string
}

const resolver = classValidatorResolver(SignUpRequestValidator)

const SignUpRequestForm = () => {

    const navigate = useNavigate()

    return (
        <>
            <LayoutHeading
                title='Sign Up'
                subtitle='Please enter an email to sign up!'
            />

            <Form<SignUpFields>
                mutationConfig={{
                    mutationKey: ['signup'],
                    mutationFn: (data) => createLead({createLeadCommand: data}),
                    onSuccess: () => navigate('/auth/signup/success'),
                }}
                formConfig={{
                    resolver,
                    mode: 'all',
                }} >
                {(form) => (
                    <>
                        <FormInput
                            required
                            asterisk
                            name='email'
                            control={form.control}
                            label='Email'
                            placeholder='Enter your email'
                        />

                        <SubmitButton>Sign Up</SubmitButton>
                    </>
                )}
            </Form>
            
            <div className='mt-4 text-center'>
                <span>Already have an account?</span>
                <Link to='/auth/signin' className='ml-2 text-primary-600'>
                    Sign In
                </Link>
            </div>
        </>
    )
}


export default SignUpRequestForm
