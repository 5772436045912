import { IsNotEmpty } from '@viterbit/web-app/shared/utils/ClassValidators'

import { ExtraDaysFormFields } from './types'

class ExtraDaysValidator implements ExtraDaysFormFields {
    @IsNotEmpty()
    year: number
    @IsNotEmpty()
    operation: number

    reason: string | null
}

export default ExtraDaysValidator
