import { classValidatorResolver } from '@hookform/resolvers/class-validator'
import i18n from '@viterbit/web-app/i18n'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import Button from 'ui/src/components/Button'
import Dialog from 'ui/src/components/Dialog'
import FormInput from 'ui/src/components/Form/FormInput'

import FormDeleteValidator from './FormDeleteValidator'

type DeleteDialogProps = {
    isOpen: boolean
    onClose: () => void
    onSubmit?: () => void
    title?: string
    submitButtonText?: string
    cancelButtonText?: string
    isLoading?: boolean
    textToConfirm?: string
    children?: React.ReactNode
}
type DeleteData = {
    name: string
}
const resolver = classValidatorResolver(FormDeleteValidator)

const FormDeleteDialog = ({
    isOpen,
    onClose,
    cancelButtonText = i18n.t('buttons.cancel'),
    onSubmit,
    submitButtonText = i18n.t('buttons.confirm'),
    title,
    isLoading,
    children,
    textToConfirm = i18n.t('buttons.confirm'),
}: DeleteDialogProps) => {
    const {
        control,
        watch,
        reset,
        formState: { isDirty, isSubmitting },
    } = useForm<DeleteData>({
        resolver,
        mode: 'all',
    })

    const isValid = watch('name') === textToConfirm
    const shouldDisableSubmit =
        !isValid || isSubmitting || isLoading || !isDirty

    React.useEffect(() => {
        if (!isOpen) reset()
    }, [isOpen, reset])

    return (
        <Dialog open={isOpen} onClose={onClose} title={title}>
            <h5 className='flex flex-col gap-2 pb-4 text-sm '>
                <span className='font-normal'>
                    {i18n.t('components.deleteDialog.text')}{' '}
                </span>
                <span className='font-bold'>{textToConfirm}</span>
            </h5>
            <FormInput
                name='name'
                placeholder={i18n.t('components.deleteDialog.input.placeholder')}
                control={control}
            />
            {children}
            <div className='flex gap-x-2 mt-4'>
                <Button onClick={onClose} block>
                    {cancelButtonText}
                </Button>
                <Button
                    onClick={onSubmit}
                    block
                    disabled={shouldDisableSubmit}
                    type='submit'
                    loading={isLoading || isSubmitting}
                    variant='solid'
                    data-testid='delete-dialog-submit-button'
                    className='bg-danger-600 hover:bg-danger-500 active:bg-danger-700'
                >
                    {submitButtonText}
                </Button>
            </div>
        </Dialog>
    )
}

export default FormDeleteDialog
