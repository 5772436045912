import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react'
import { FileModel } from '@viterbit/web-app/dataAccess/model/File.model'
import { usePendingFileSignedUrl } from '@viterbit/web-app/dataAccess/useCase/pendingFile/usePendingFileSignedUrl'
import i18n from '@viterbit/web-app/i18n'
import SimpleCard from '@viterbit/web-app/shared/display/SimpleCard'
import PdfViewer from '@viterbit/web-app/shared/feedback/PdfViewer/PdfViewer'
import PdfViewerSkeleton from '@viterbit/web-app/shared/feedback/PdfViewer/PdfViewerSkeleton'
import Button from 'ui/src/components/Button'
import Skeleton from 'ui/src/components/Skeleton'
import clsxm from 'ui/src/lib'

type DocumentBulkUploadPreviewViewerProps = {
    file: FileModel
    className?: string
    page: number,
    pageCount: number
    onChangePage: (page: number) => void
    onDelete: () => void
    fileId?: string
    isFetching?: boolean
}

const DocumentBulkUploadPreviewViewer = ({
    file: {id, name},
    page,
    pageCount: _pageCount,
    onChangePage,
    onDelete,
    fileId,
    isFetching,
}: DocumentBulkUploadPreviewViewerProps) => {
    const {data, isLoading, isFetching: _isFetching, isRefetching} = usePendingFileSignedUrl({
        query: { id, type: 'pending' },
        keepPreviousData: true,
        refetchOnWindowFocus: false
    })

    const pageCount = _pageCount + (!fileId ? 0 : 1)

    return (
        <div className='flex flex-col w-full gap-y-2'>
            <SimpleCard padding className='mb-2'>
                <div className='flex items-center justify-between '>
                    <div className='flex items-center gap-x-4'>
                        <span className='inline-flex rounded-md shadow-sm isolate'>
                            <div
                                className={clsxm(
                                    'relative inline-flex items-center px-2 py-2 text-sm font-medium text-gray-300 bg-white border border-gray-300 rounded-l-md focus:z-10 focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500',
                                    (page > 1) && 'cursor-pointer hover:bg-gray-50 text-gray-700'
                                )}
                                onClick={isFetching || (page > 1) ? () => onChangePage?.(page - 1) : undefined}
                            >
                                <IconChevronLeft className='w-5 h-5' />
                            </div>
                            <span className='relative inline-flex items-center px-8 py-2 -ml-px text-sm font-medium text-gray-700 bg-white border border-gray-300 focus:z-10 focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500'>
                                {page} / {pageCount}
                            </span>
                            <div
                                className={clsxm(
                                    'relative inline-flex items-center px-2 py-2 -ml-px text-sm font-bold text-gray-300 bg-white border border-gray-300 rounded-r-md focus:z-10 focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500',
                                    (page < pageCount) && 'cursor-pointer hover:bg-gray-50 text-gray-700'
                                )}
                                onClick={isFetching || (page < pageCount) ? (() => onChangePage?.(page + 1)) : undefined}
                            >
                                <IconChevronRight className='w-5 h-5 ' />
                            </div>
                        </span>

                    </div>
                    {
                        isFetching
                            ? <Skeleton animated className='w-28' />
                            : <span className='text-xs font-bold text-center xl:text-md'>{name}</span>
                    }
                
                    <Button onClick={onDelete} disabled={isFetching}>
                        {i18n.t('employees:bulkUpload.preview.delete')}
                    </Button>
                </div>
            </SimpleCard>
            {
                (isLoading || isFetching || _isFetching || isRefetching)
                    ? <PdfViewerSkeleton className='h-[70vh]' />
                    : (
                        <PdfViewer
                            url={data?.url || ''}
                            className='rounded-md h-[70vh]'
                        />
                    )
            }
            
            
        </div>
        
    )
}

export default DocumentBulkUploadPreviewViewer