/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react'

import flattenChildren from './flattenChildren'

type ChildrenObject = {
    name: string
    component: any
}

const splitComponents = (children: React.ReactNode, types: ChildrenObject[]): any => {
    const mappedObject = types.reduce((map, type) => {
        map.set(type.component, type.name)
        return map
    }, new Map())

    const initialValues = [...mappedObject.values()].reduce((output, value) => {
        output[value] = []
        return output
    }, { others: []})


    const data = flattenChildren(children).reduce((output, child) => {
        const match = types.find(t => t.component === (child as any).type)
        const name = match ? match.name : 'others'
        output[name].push(child)
        return output
    }, initialValues)

    return data
}

export default splitComponents