import * as React from 'react'

import BaseDatePicker, { DatePickerProps } from './DatePicker'
import DatePickerRange, { DatePickerRangeProps } from './DatePickerRange'

type DatePickerType = React.FunctionComponent<DatePickerProps> & {
    Range: React.FunctionComponent<DatePickerRangeProps>
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DatePicker: DatePickerType = BaseDatePicker as any
DatePicker.Range = DatePickerRange

export default DatePicker
