/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { IMapper } from './__common'
import organizationMapper from './organization.mapper'
import { CompanyResponse } from '../dto'
import { Company } from '../model/Company.model'

const companyMapper: IMapper<Company, CompanyResponse> = {
    toModel: (dto) => ({
        id: dto.id,
        name: dto.name,
        organization: dto.organization && organizationMapper.toModel(dto.organization),
    }),
    toDto: (dto) => ({
        id: dto.id,
        name: dto.name,
    }),
}

export default companyMapper
