type Props = {
    url: string
    className?: string
}

const PdfViewer = ({ url, className }: Props) => (
    <iframe
        className={className}
        src={url}
        width="100%"
        height="100%"
    />
)

export default PdfViewer
