
import { PermissionAction } from '@viterbit/web-app/dataAccess/model/PermissionAction.model'
import AsideCardInfo from '@viterbit/web-app/shared/display/AsideCardInfo'
import IsGranted from '@viterbit/web-app/shared/permission/IsGranted'

import DeleteLocation from './DeleteLocation'

const DeleteLocationAction = ({ location }: any) => (
    <IsGranted id={location.id} action={PermissionAction.CORE_LOCATION_DELETE}>
        <AsideCardInfo
            className='mb-4 border-b'
            title='Danger Zone'
            description=''
        >
            <DeleteLocation defaultValues={location} id={location.id} />
        </AsideCardInfo>
    </IsGranted>
)

export default DeleteLocationAction
