import useBillingProtected from './useBillingProtected'

const withBillingProtected = <T extends React.ComponentType > (Component: T) => {
    const WithBillingProtected = (props: any) => {
        useBillingProtected()

        return (
            <Component {...props} />
        )
    }

    return WithBillingProtected
}

export default withBillingProtected