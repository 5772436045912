/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EmployeeFindComplaintQueryResponse2 } from './EmployeeFindComplaintQueryResponse2';
import {
    EmployeeFindComplaintQueryResponse2FromJSON,
    EmployeeFindComplaintQueryResponse2FromJSONTyped,
    EmployeeFindComplaintQueryResponse2ToJSON,
} from './EmployeeFindComplaintQueryResponse2';
import type { ListMeta2 } from './ListMeta2';
import {
    ListMeta2FromJSON,
    ListMeta2FromJSONTyped,
    ListMeta2ToJSON,
} from './ListMeta2';

/**
 * 
 * @export
 * @interface ListComplaintQueryResponse
 */
export interface ListComplaintQueryResponse {
    /**
     * 
     * @type {ListMeta2}
     * @memberof ListComplaintQueryResponse
     */
    meta: ListMeta2;
    /**
     * 
     * @type {Array<EmployeeFindComplaintQueryResponse2>}
     * @memberof ListComplaintQueryResponse
     */
    data: Array<EmployeeFindComplaintQueryResponse2>;
}

/**
 * Check if a given object implements the ListComplaintQueryResponse interface.
 */
export function instanceOfListComplaintQueryResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "meta" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function ListComplaintQueryResponseFromJSON(json: any): ListComplaintQueryResponse {
    return ListComplaintQueryResponseFromJSONTyped(json, false);
}

export function ListComplaintQueryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListComplaintQueryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'meta': ListMeta2FromJSON(json['meta']),
        'data': ((json['data'] as Array<any>).map(EmployeeFindComplaintQueryResponse2FromJSON)),
    };
}

export function ListComplaintQueryResponseToJSON(value?: ListComplaintQueryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'meta': ListMeta2ToJSON(value.meta),
        'data': ((value.data as Array<any>).map(EmployeeFindComplaintQueryResponse2ToJSON)),
    };
}

