/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PermissionsActionQuery
 */
export interface PermissionsActionQuery {
    /**
     * 
     * @type {string}
     * @memberof PermissionsActionQuery
     */
    action: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PermissionsActionQuery
     */
    value: Array<string>;
}

/**
 * Check if a given object implements the PermissionsActionQuery interface.
 */
export function instanceOfPermissionsActionQuery(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "action" in value;
    isInstance = isInstance && "value" in value;

    return isInstance;
}

export function PermissionsActionQueryFromJSON(json: any): PermissionsActionQuery {
    return PermissionsActionQueryFromJSONTyped(json, false);
}

export function PermissionsActionQueryFromJSONTyped(json: any, ignoreDiscriminator: boolean): PermissionsActionQuery {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'action': json['action'],
        'value': json['value'],
    };
}

export function PermissionsActionQueryToJSON(value?: PermissionsActionQuery | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'action': value.action,
        'value': value.value,
    };
}

