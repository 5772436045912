/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useComplaintPublicDetail } from '@viterbit/web-app/dataAccess/useCase/complaint/useComplaintPublicDetail'
import throwException from '@viterbit/web-app/shared/utils/throwException'
import { useParams } from 'react-router-dom'

export const useComplaintDetail = () => {
    const { complaintId } = useParams<'complaintId'>()
    const result = useComplaintPublicDetail({
        enabled: !!complaintId
    })

    if (!complaintId) {
        throwException('complaintId is required')
    }

    if (result.failureReason?.response?.status === 400) {
        throwException(`complaint ${complaintId} not found`)
    }

    return result
}