
import { JobPosition } from '@viterbit/web-app/dataAccess/model/JobPosition.model'
import { jobPositionList } from '@viterbit/web-app/dataAccess/service/jobPositionService'
import * as React from 'react'
import { GroupBase } from 'react-select'
import {
    FormSelectAsyncPaginate,
    FormSelectAsyncPaginateProps,
} from 'ui/src/components/Form'
import { FormBaseProps } from 'ui/src/components/Form/shared'

import { useNotification } from '../feedback/useNotification'


type FormSelectJobPositionProps<IsMulti extends boolean> = FormBaseProps &
    Omit<
        FormSelectAsyncPaginateProps<
            JobPosition,
            IsMulti,
            GroupBase<JobPosition>,
            { page: number }
        >,
        'loadOptions'
    >

type LoadOptions = FormSelectAsyncPaginateProps<
JobPosition,
    boolean,
    GroupBase<JobPosition>,
    { page: number }
>['loadOptions']

const FormSelectJobPosition = <IsMulti extends boolean>({
    name,
    control,
    cacheOptions = true,
    isSearchable = false,
    ...rest
}: FormSelectJobPositionProps<IsMulti>) => {
    const notification = useNotification()
    const loadOptions: LoadOptions = async (search, loadedOptions, _meta) =>
        jobPositionList({ page: _meta?.page })
            .then(({ data, meta }) => {
                const hasMore =
                    !!meta?.total &&
                    meta.page <
                        Math.ceil(meta.total / ((meta as any).pageSize ?? 10))
                return {
                    options: data,
                    hasMore,
                    additional: {
                        page: meta.page + 1,
                    },
                }
            })
            .catch((err) => {
                notification.push(
                    'danger',
                    'Fetching error',
                    err.errorCode ?? 'Error at fetch job position'
                )
                return {
                    options: [],
                    hasMore: false,
                }
            })
    return (
        <>
            <FormSelectAsyncPaginate
                name={name}
                control={control}
                cacheOptions={cacheOptions}
                loadOptions={loadOptions}
                isSearchable={isSearchable}
                getOptionLabel={(x) => x.name}
                getOptionValue={(x) => x.id}
                loadOptionsOnMenuOpen
                additional={{
                    page: 1,
                }}
                {...rest}
            />  
        </>
    )
}

export default FormSelectJobPosition
