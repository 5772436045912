import {
    IsOptional,
    IsString,
} from '@viterbit/web-app/shared/utils/ClassValidators'

import { EmployeePersonalAddressInformationFields } from './EmployeePersonalAddressInformationForm'

export default class EmployeePersonalAddressInformationValidator implements EmployeePersonalAddressInformationFields {
  @IsString()
  @IsOptional()
  address?: string

  @IsString()
  @IsOptional()
  city?: string

  @IsString()
  @IsOptional()
  postalCode?: string

  @IsString()
  @IsOptional()
  country?: string

  @IsString()
  @IsOptional()
  state?: string
}
