/* eslint-disable @typescript-eslint/no-explicit-any */
import { ITEMS_BY_PAGE } from '@viterbit/web-app/dataAccess/model/Week.model'
import createUID from 'ui/src/utils/createUid'

import { PaginationMeta, ResourcesPaginatedResponse } from '../dto'

const paginationMetaMapper = (meta: ResourcesPaginatedResponse<any>['meta']): PaginationMeta => {
    const { page, total, ...rest } = meta
    const pageSize = meta.pageSize ?? ITEMS_BY_PAGE
    const pageCount = Math.ceil(meta.total / pageSize)
    const hasMore = page < pageCount
    const nextPage = hasMore ? page + 1 : undefined
    const isLastPage = page === pageCount
    const lastPageTotal = total % (pageCount - 1) * pageSize
    const nextPageTotal = page === pageCount - 1 ? lastPageTotal : pageSize 

    return {
        ...rest,
        total,
        uuid: createUID(),
        page,
        pageSize,
        pageCount,
        hasMore,
        nextPage,
        isLastPage,
        nextPageTotal
    }

}

export default paginationMetaMapper