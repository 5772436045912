import { useMatch } from 'react-router-dom'

export type UseActiveRouteParams = {
    path: string
    exact?: boolean
    matchPath?: string | string[]
}

const useActiveRoute = (config: UseActiveRouteParams) => {
    const match = useMatch({
        path: config.path,
        end: config.exact,
    })

    return Boolean(match)
}

export default useActiveRoute
