/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { IconPlayerPlay, IconPlayerStopFilled } from '@tabler/icons-react'
import dateMapper from '@viterbit/web-app/dataAccess/mapper/date.mapper'
import { useTimeTrackingClockDetail } from '@viterbit/web-app/dataAccess/useCase/timeTracking/useTimeTrackingClockDetail'
import { useTimeTrackingClockUpdate } from '@viterbit/web-app/dataAccess/useCase/timeTracking/useTimeTrackingClockUpdate'
import { useTimeTrackingCounters } from '@viterbit/web-app/dataAccess/useCase/timeTracking/useTimeTrackingCounters'
import i18n from '@viterbit/web-app/i18n'
import { useUser } from '@viterbit/web-app/shared/employee/useUser'
import ApiErrorMessage from '@viterbit/web-app/shared/feedback/ApiErrorMessage'
import ActionButton from '@viterbit/web-app/shared/form/ActionButton'
import { TIME_ZONE } from '@viterbit/web-app/shared/utils/timeZone'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import * as React from 'react'
import { useInterval } from 'react-use'
import ListItem from 'ui/src/components/List/ListItem'
import ListItemText from 'ui/src/components/List/ListItemText'
import Skeleton from 'ui/src/components/Skeleton'


dayjs.extend(utc)
dayjs.extend(timezone)

type Props = {
    className?: string
}


const startDate = dayjs().startOf('day').format('YYYY-MM-DD')
const endDate = dayjs().add(1, 'day').startOf('day').format('YYYY-MM-DD')


const TimeTrackingToggleWidget = ({ className }: Props) => {
    const user = useUser()
    const employeeId = user.id
    const [elapsedTime, setElapsedTime] = React.useState(0)

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const { data, isInitialLoading } = useTimeTrackingClockDetail({ query: { employeeId } })
    const { mutate, isLoading: isUpdateLoading, error } = useTimeTrackingClockUpdate({})
    const { data: counters, isLoading: isCounterInitialLoading } = useTimeTrackingCounters({ query: { employeeId, startDate, endDate } })

    const _setElapsedTime = () => {
        if (data?.startDate) {
            const now = dayjs().tz(data.timezone || undefined)
            const start = dayjs(data.startDate, 'YYYY-MM-DD HH:mm:ss', true).tz(data.timezone || undefined, true)
            setElapsedTime(now.valueOf() - start.valueOf())
        } else {
            setElapsedTime(0)
        }
    }

    const displayElapsedTime = React.useMemo(() => {
        const totalTime = elapsedTime + (counters?.total || 0)
        const hours = Math.floor(totalTime / 1000 / 60 / 60)
        const minutes = String(Math.floor((totalTime / 1000 / 60) % 60)).padStart(2, '0')
        const seconds = String(Math.floor((totalTime / 1000) % 60)).padStart(2, '0')

        return `${hours}:${minutes}:${seconds}`
    }
    , [elapsedTime, counters])

    React.useEffect(_setElapsedTime, [data])
    useInterval(_setElapsedTime, 1000)

    const onClick = () => {
        mutate({
            toggleClockCommand: {
                timezone: TIME_ZONE,
                datetime: dateMapper.toDateTime(Date())!,
                employeeId,
            }
        })
    }

    return (
        <>
            <ListItem clickable onClick={onClick}>
                <ListItemText
                    primary={
                        <div className='flex'>
                            <span className='mr-2 font-bold'>{i18n.t('personalArea:timeTracking.primaryText')}</span>
                            {isCounterInitialLoading || isUpdateLoading ?  <Skeleton className='w-16 mt-1.5' />: displayElapsedTime}
                        </div>
                    }
                    secondary={
                        <span className='italic'>
                            {data?.startDate ? i18n.t('personalArea:timeTracking.toogleTrue') : i18n.t('personalArea:timeTracking.toogleFalse')}
                        </span>
                    }
                />


                {data?.startDate ? (
                    <ActionButton
                        title={i18n.t('personalArea:timeTracking.buttonText.stop')}
                        loading={isUpdateLoading || isInitialLoading}
                        IconComponent={IconPlayerStopFilled}
                        color="red"
                        variant="outline"
                        className='animate-pulse'
                    />
                ) : (
                    <ActionButton
                        title={i18n.t('personalArea:timeTracking.buttonText.start')}
                        loading={isUpdateLoading || isInitialLoading}
                        IconComponent={IconPlayerPlay}
                    />
                )}                
            </ListItem>
            <ApiErrorMessage error={error} className='m-2 mt-0' />
        </>
    )
}

export default TimeTrackingToggleWidget