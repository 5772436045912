import * as React from 'react'

type ToggleIconProps = {
    children?: React.ReactNode
    className?: string
    toggled: boolean
    open?: boolean
    icon: React.ReactNode
    toggledIcon: React.ReactNode
}

const ToggleIcon = (props: ToggleIconProps) => {
    const { toggled, open = true, icon, toggledIcon, className } = props

    if (toggled) {
        return <>{toggledIcon}</>
    }

    if (open) {
        return <>{icon}</>
    }

    return (
        null
    )
}

export default ToggleIcon
