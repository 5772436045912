/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateLeaveAdjustmentsCommand
 */
export interface UpdateLeaveAdjustmentsCommand {
    /**
     * 
     * @type {number}
     * @memberof UpdateLeaveAdjustmentsCommand
     */
    year: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateLeaveAdjustmentsCommand
     */
    operation: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateLeaveAdjustmentsCommand
     */
    reason: string | null;
}

/**
 * Check if a given object implements the UpdateLeaveAdjustmentsCommand interface.
 */
export function instanceOfUpdateLeaveAdjustmentsCommand(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "year" in value;
    isInstance = isInstance && "operation" in value;
    isInstance = isInstance && "reason" in value;

    return isInstance;
}

export function UpdateLeaveAdjustmentsCommandFromJSON(json: any): UpdateLeaveAdjustmentsCommand {
    return UpdateLeaveAdjustmentsCommandFromJSONTyped(json, false);
}

export function UpdateLeaveAdjustmentsCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateLeaveAdjustmentsCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'year': json['year'],
        'operation': json['operation'],
        'reason': json['reason'],
    };
}

export function UpdateLeaveAdjustmentsCommandToJSON(value?: UpdateLeaveAdjustmentsCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'year': value.year,
        'operation': value.operation,
        'reason': value.reason,
    };
}

