import { initialRefreshToken } from '@viterbit/web-app/dataAccess/service/authService'
import React from 'react'
import { useAsync } from 'react-use'

const spinner = document.getElementById('loader-container') as HTMLElement
const root = document.getElementById('root') as HTMLElement

const RefreshTokenProvider = ({children}) => {
    const state = useAsync(initialRefreshToken, [])

    if (state.loading) {
        return null
    }

    setTimeout(() => {
        spinner.remove()
        root.style.display = ''
    }, 100)
        
    return (
        <>
            {children}
        </>
    )
}

export default RefreshTokenProvider