/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateJobPositionLevelCommand,
  FindJobPositionLevelQueryResponse,
  ListJobPositionLevelQueryResponse,
  ResourceCreated,
  UpdateJobPositionLevelCommand,
} from '../models/index';
import {
    CreateJobPositionLevelCommandFromJSON,
    CreateJobPositionLevelCommandToJSON,
    FindJobPositionLevelQueryResponseFromJSON,
    FindJobPositionLevelQueryResponseToJSON,
    ListJobPositionLevelQueryResponseFromJSON,
    ListJobPositionLevelQueryResponseToJSON,
    ResourceCreatedFromJSON,
    ResourceCreatedToJSON,
    UpdateJobPositionLevelCommandFromJSON,
    UpdateJobPositionLevelCommandToJSON,
} from '../models/index';

export interface CreateJobPositionLevelRequest {
    createJobPositionLevelCommand?: CreateJobPositionLevelCommand;
}

export interface DeleteJobPositionLevelRequest {
    id: string;
}

export interface FindJobPositionLevelRequest {
    id: string;
}

export interface ListJobPositionLevelRequest {
    page?: number;
}

export interface UpdateJobPositionLevelRequest {
    id: string;
    updateJobPositionLevelCommand?: UpdateJobPositionLevelCommand;
}

/**
 * 
 */
export class JobPositionLevelApi extends runtime.BaseAPI {

    /**
     * Create a job position level
     * Create a job position level
     */
    async createJobPositionLevelRaw(requestParameters: CreateJobPositionLevelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/job-position-levels`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateJobPositionLevelCommandToJSON(requestParameters.createJobPositionLevelCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Create a job position level
     * Create a job position level
     */
    async createJobPositionLevel(requestParameters: CreateJobPositionLevelRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.createJobPositionLevelRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a job position level
     * Delete a job position level
     */
    async deleteJobPositionLevelRaw(requestParameters: DeleteJobPositionLevelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteJobPositionLevel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/job-position-levels/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Delete a job position level
     * Delete a job position level
     */
    async deleteJobPositionLevel(requestParameters: DeleteJobPositionLevelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.deleteJobPositionLevelRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find a job position level
     * Find a job position level
     */
    async findJobPositionLevelRaw(requestParameters: FindJobPositionLevelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindJobPositionLevelQueryResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling findJobPositionLevel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/job-position-levels/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindJobPositionLevelQueryResponseFromJSON(jsonValue));
    }

    /**
     * Find a job position level
     * Find a job position level
     */
    async findJobPositionLevel(requestParameters: FindJobPositionLevelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindJobPositionLevelQueryResponse> {
        const response = await this.findJobPositionLevelRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List job position levels
     * List job position levels
     */
    async listJobPositionLevelRaw(requestParameters: ListJobPositionLevelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListJobPositionLevelQueryResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/job-position-levels`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListJobPositionLevelQueryResponseFromJSON(jsonValue));
    }

    /**
     * List job position levels
     * List job position levels
     */
    async listJobPositionLevel(requestParameters: ListJobPositionLevelRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListJobPositionLevelQueryResponse> {
        const response = await this.listJobPositionLevelRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a job position level
     * Update a job position level
     */
    async updateJobPositionLevelRaw(requestParameters: UpdateJobPositionLevelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateJobPositionLevel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/job-position-levels/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateJobPositionLevelCommandToJSON(requestParameters.updateJobPositionLevelCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Update a job position level
     * Update a job position level
     */
    async updateJobPositionLevel(requestParameters: UpdateJobPositionLevelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.updateJobPositionLevelRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
