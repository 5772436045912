import EmbeddedProvider from '@viterbit/web-app/shared/behaviour/EmbeddedProvider'
import BodyContent from '@viterbit/web-app/shared/layout/BodyContent'
import HeaderActions from '@viterbit/web-app/shared/layout/HeaderActions'
import HeaderActionsEmbedded from '@viterbit/web-app/shared/layout/HeaderActions/HeaderActionsEmbedded'
import RouterBreadcrumbs from '@viterbit/web-app/shared/navigation/RouterBreadcrumbs'

type SectionLayoutProps = {
    title?: React.ReactNode
    actions?: React.ReactNode
    children: React.ReactNode
    noPadding?: boolean
} & React.HTMLAttributes<HTMLDivElement>

const SectionLayout =  ({ title, children, actions, noPadding, ...rest }: SectionLayoutProps) => (
    <EmbeddedProvider>
        <BodyContent {...rest}>
            <BodyContent.Header>
                <HeaderActions divider className='h-[80px] items-center'>
                    <HeaderActions.Header>
                        <RouterBreadcrumbs title={title}/>
                    </HeaderActions.Header>

                    {actions && (
                        <HeaderActions.Actions>
                            {actions}
                        </HeaderActions.Actions>
                    )}

                    <HeaderActionsEmbedded />
                </HeaderActions>
            </BodyContent.Header>

            <BodyContent.Main className={noPadding ? '': ''}>
                {children}
            </BodyContent.Main>
        </BodyContent>
    </EmbeddedProvider>
)

export default SectionLayout
