/* eslint-disable @typescript-eslint/no-explicit-any */
import { Location } from '@viterbit/web-app/dataAccess/model/Location.model'
import { locationList } from '@viterbit/web-app/dataAccess/service/locationService'
import { GroupBase } from 'react-select'
import {
    FormSelectAsyncPaginate,
    FormSelectAsyncPaginateProps,
} from 'ui/src/components/Form'
import { FormBaseProps } from 'ui/src/components/Form/shared'

import { useNotification } from '../feedback/useNotification'


export type FormSelectLocationProps<IsMulti extends boolean = boolean> = FormBaseProps &
    Omit<
        FormSelectAsyncPaginateProps<
            Location,
            IsMulti,
            GroupBase<Location>,
            { page: number }
        >,
        'loadOptions'
    >

type LoadOptions = FormSelectAsyncPaginateProps<
    Location,
    boolean,
    GroupBase<Location>,
    { page: number }
>['loadOptions']

const FormSelectLocation = <IsMulti extends boolean>({
    name,
    control,
    cacheOptions = true,
    isSearchable = false,
    ...rest
}: FormSelectLocationProps<IsMulti>) => {
    const notification = useNotification()
    const loadOptions: LoadOptions = async (search, loadedOptions, _meta) =>
        locationList({ page: _meta?.page })
            .then(({ data, meta }) => {
                const hasMore =
                    !!meta?.total &&
                    meta.page <
                        Math.ceil(meta.total / ((meta as any).pageSize ?? 10))
                return {
                    options: data,
                    hasMore,
                    additional: {
                        page: meta.page + 1,
                    },
                } as any
            })
            .catch((err) => {
                notification.push(
                    'danger',
                    'Fetching error',
                    err.errorCode ?? 'Error at fetch locations'
                )
                return {
                    options: [],
                    hasMore: false,
                }
            })
    return (
        <>
            <FormSelectAsyncPaginate
                name={name}
                control={control}
                cacheOptions={cacheOptions}
                loadOptions={loadOptions}
                isSearchable={isSearchable}
                getOptionLabel={(x) => x.name}
                getOptionValue={(x) => x.id}
                loadOptionsOnMenuOpen
                additional={{
                    page: 1,
                }}
                {...rest}
            />
        </>
    )
}

export default FormSelectLocation
