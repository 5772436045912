import { useSubscriptionList } from '@viterbit/web-app/dataAccess/useCase/subscription/useSubscriptionList'
import { useUser } from '@viterbit/web-app/shared/employee/useUser'
import * as React from 'react'

import SubscriptionBanner from './SubscriptionBanner'


type SubscriptionBannerListProps = {
    children?: React.ReactNode
    className?: string
}

const styles = {
    root: 'width: 100%'
}

const SubscriptionBannerList = (props: SubscriptionBannerListProps) => {
    const { className } = props

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const { data, isInitialLoading } = useSubscriptionList()
    const user = useUser()

    if (!user) {
        return null
    }

    if (!user.menu.Billing) {
        return null
    }

    const shouldUpgrade = data?.some((subscription) => (subscription.shouldUpgrade))
    
    if (!shouldUpgrade) {
        return null
    }

    if (isInitialLoading || !data?.length) {
        return null
    }

    return (
        <>
            {
                data.map((subscription, index) => (
                    <SubscriptionBanner
                        key={subscription.id || String(index)}
                        subscription={subscription}
                    />
                ))
            }
        </>
    )
}

export default SubscriptionBannerList
