import * as React from 'react'

import { BaseProps } from './util'
import { clsxm } from '../../lib/clsxm'

const classes = {
    tfoot: '',
}

export type TFootProps = BaseProps &
    React.HTMLAttributes<HTMLTableSectionElement>

const TFoot = React.forwardRef<HTMLTableSectionElement, TFootProps>(
    ({ className, children, ...props }, ref) => (
        <tfoot className={clsxm(classes.tfoot, className)} ref={ref} {...props}>
            {children}
        </tfoot>
    )
)

export default TFoot
