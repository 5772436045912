/* eslint-disable react/no-array-index-key */
import dayjs from 'dayjs'
import * as React from 'react'

import Day from './Day'
import { DayProps, payload } from './sharedTypes'
import {
    getMonthDays,
    getWeekdaysNames,
    isSameDate,
    isSameMonth,
    isWeekend,
    noop,
} from './utils'
import { clsxm } from '../../lib/clsxm'

const classes = {
    'week-day-cell': 'h-7',
    'week-day-cell-content': '',
    table: 'border-collapse w-full min-w-[260px]',
    'calendar-week-cell': 'font-semibold',
    'calendar-cell': 'text-center w-9 h-9 p-0',
}

type disabledParam = {
    minDate?: Date
    maxDate?: Date
    disableDate?: (date: Date) => boolean
    disableOutOfMonth?: boolean
    date: Date
    outOfMonth?: boolean
}

const getRangeProps = (date: Date, range: Date[]) => {
    const hasRange =
        Array.isArray(range) &&
        range.length > 0 &&
        range.every((val) => val instanceof Date)

    let inclusiveRange: dayjs.Dayjs[] = []
    if (hasRange) {
        inclusiveRange = [
            dayjs(range[0]).subtract(1, 'day'),
            dayjs(range[1]).add(1, 'day'),
        ]
    }

    const firstInRange = hasRange && isSameDate(date, range[0])
    const lastInRange = hasRange && isSameDate(date, range[1])
    const inRange =
        hasRange &&
        dayjs(date).isAfter(inclusiveRange[0], 'day') &&
        dayjs(date).isBefore(inclusiveRange[1], 'day')

    return {
        firstInRange,
        lastInRange,
        inRange,
        selectedInRange: firstInRange || lastInRange,
    }
}

const isDisabled = (params: disabledParam): boolean => {
    const {
        minDate,
        maxDate,
        disableDate,
        disableOutOfMonth,
        date,
        outOfMonth,
    } = params
    const isAfterMax =
        maxDate instanceof Date && dayjs(maxDate).isBefore(date, 'day')
    const isBeforeMin =
        minDate instanceof Date && dayjs(minDate).isAfter(date, 'day')
    const shouldExclude = typeof disableDate === 'function' && disableDate(date)
    const disabledOutside = !!disableOutOfMonth && !!outOfMonth
    return isAfterMax || isBeforeMin || shouldExclude || disabledOutside
}

type DayPropsParam = {
    date: Date
    month: Date
    hasValue: boolean
    minDate?: Date
    maxDate?: Date
    value?: Date | Date[] | null
    disableDate?: (date: Date) => boolean
    disableOutOfMonth: boolean
    range: Date[]
    weekendDays?: number[]
}

const getDayProps = (props: DayPropsParam): DayProps => {
    const {
        date,
        month,
        hasValue,
        minDate,
        maxDate,
        value,
        disableDate,
        disableOutOfMonth,
        range,
        weekendDays = [0, 6],
    } = props
    const isOutside = (date: Date, month: Date) => !isSameMonth(date, month)

    const outOfMonth = isOutside(date, month)

    const selected = Boolean(
        hasValue &&
            (Array.isArray(value)
                ? value.some((val) => isSameDate(val, date))
                : value && isSameDate(date, value))
    )

    const { inRange, lastInRange, firstInRange, selectedInRange } =
        getRangeProps(date, range)

    return {
        disabled: isDisabled({
            minDate,
            maxDate,
            disableDate,
            disableOutOfMonth,
            date,
            outOfMonth,
        }),

        weekend: isWeekend(date, weekendDays),
        selectedInRange,
        selected,
        inRange,
        firstInRange,
        lastInRange,
        outOfMonth,
    }
}

export type MonthProps = {
    className?: string
    month: Date
    value?: Date | Date[] | null
    minDate?: Date
    maxDate?: Date
    onChange?: (date: Date) => void
    disableOutOfMonth?: boolean
    locale?: string
    dayClassName?: (date?: Date, dayProps?: DayProps) => string | string
    dayStyle?: (date?: Date, dayProps?: DayProps) => React.CSSProperties
    range?: Date[]
    hideWeekdays?: boolean
    preventFocus?: boolean
    focusable?: boolean
    firstDayOfWeek?: 'monday' | 'sunday'
    onDayKeyDown?: (
        onKeyDownPayload: payload,
        event: React.KeyboardEvent<HTMLButtonElement>
    ) => void
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    daysRefs?: any
    disableDate?: (date: Date) => boolean
    onDayMouseEnter?: (
        date: Date | undefined,
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => void
    isDateInRange?: (date?: Date, dayProps?: DayProps) => boolean
    isDateFirstInRange?: (date?: Date, dayProps?: DayProps) => boolean
    isDateLastInRange?: (date?: Date, dayProps?: DayProps) => boolean
    renderDay?: (value: Date) => React.ReactNode
    weekdayLabelFormat?: string
    weekendDays?: number[]
    hideOutOfMonthDates?: boolean
    monthCellClassName?: string
}

const Month = React.forwardRef<HTMLTableElement, MonthProps>((props, ref) => {
    const {
        className,
        month,
        minDate,
        maxDate,
        onChange,
        value,
        disableDate,
        onDayMouseEnter,
        preventFocus,
        range = [],
        dayStyle,
        hideWeekdays,
        dayClassName,
        focusable,
        firstDayOfWeek = 'sunday',
        onDayKeyDown,
        daysRefs,
        isDateInRange = noop,
        isDateFirstInRange = noop,
        isDateLastInRange = noop,
        renderDay,
        weekdayLabelFormat = 'dd',
        weekendDays,
        locale = 'en',
        disableOutOfMonth = false,
        hideOutOfMonthDates = false,
        monthCellClassName,
        ...rest
    } = props

    const days = getMonthDays(month, firstDayOfWeek)

    const weekdays = getWeekdaysNames(
        locale,
        firstDayOfWeek,
        weekdayLabelFormat
    ).map((w: string) => (
        <th className={classes['week-day-cell']} key={w}>
            <span className={classes['week-day-cell-content']}>{w}</span>
        </th>
    ))

    const hasValue = Array.isArray(value)
        ? value.every((item) => item instanceof Date)
        : value instanceof Date

    const hasValueInMonthRange =
        value instanceof Date &&
        dayjs(value).isAfter(dayjs(month).startOf('month')) &&
        dayjs(value).isBefore(dayjs(month).endOf('month'))

    const firstIncludedDay = React.useMemo(
        () =>
            days
                .flatMap((_) => _)
                .find((date) => {
                    const dayProps = getDayProps({
                        date,
                        month,
                        hasValue,
                        minDate,
                        maxDate,
                        value,
                        disableDate,
                        range,
                        weekendDays,
                        disableOutOfMonth,
                    })

                    return !dayProps.disabled && !dayProps.outOfMonth
                }) || dayjs(month).startOf('month').toDate(),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )

    const rows = days.map((row, rowIndex) => {
        const cells = row.map((date, cellIndex) => {
            const dayProps = getDayProps({
                date,
                month,
                hasValue,
                minDate,
                maxDate,
                value,
                disableDate,
                disableOutOfMonth,
                range,
                weekendDays,
            })

            const onKeyDownPayload = { rowIndex, cellIndex, date }
            const showTodayIfNotHadRange =
                isSameDate(date, new Date()) && range.length === 0

            return (
                // eslint-disable-next-line react/no-array-index-key
                <td
                    id={`cell-${rowIndex}-${cellIndex}`}
                    className={clsxm(
                        classes['calendar-cell'],
                        monthCellClassName
                    )}
                    key={cellIndex}
                >
                    <Day
                        ref={(button) => {
                            if (daysRefs) {
                                if (!Array.isArray(daysRefs[rowIndex])) {
                                    daysRefs[rowIndex] = []
                                }
                                daysRefs[rowIndex][cellIndex] = button
                            }
                        }}
                        onClick={() =>
                            typeof onChange === 'function' && onChange(date)
                        }
                        onMouseDown={(event) =>
                            preventFocus && event.preventDefault()
                        }
                        outOfMonth={dayProps.outOfMonth}
                        weekend={dayProps.weekend}
                        inRange={
                            dayProps.inRange || isDateInRange(date, dayProps)
                        }
                        firstInRange={
                            dayProps.firstInRange ||
                            isDateFirstInRange(date, dayProps)
                        }
                        lastInRange={
                            dayProps.lastInRange ||
                            isDateLastInRange(date, dayProps)
                        }
                        firstInMonth={isSameDate(date, firstIncludedDay)}
                        selected={dayProps.selected || dayProps.selectedInRange}
                        hasValue={hasValueInMonthRange}
                        onKeyDown={(event) =>
                            typeof onDayKeyDown === 'function' &&
                            onDayKeyDown(onKeyDownPayload, event)
                        }
                        className={
                            typeof dayClassName === 'function'
                                ? dayClassName(date, dayProps)
                                : ''
                        }
                        style={
                            typeof dayStyle === 'function'
                                ? dayStyle(date, dayProps)
                                : {}
                        }
                        disabled={dayProps.disabled}
                        onMouseEnter={
                            typeof onDayMouseEnter === 'function'
                                ? onDayMouseEnter
                                : noop
                        }
                        focusable={focusable}
                        hideOutOfMonthDates={hideOutOfMonthDates}
                        renderDay={renderDay}
                        isToday={showTodayIfNotHadRange}
                        value={date}
                    />
                </td>
            )
        })

        return (
            // eslint-disable-next-line react/no-array-index-key
            <tr className={clsxm(classes['calendar-week-cell'])} key={rowIndex}>
                {cells}
            </tr>
        )
    })

    return (
        <table
            className={clsxm(classes.table, className)}
            ref={ref}
            cellSpacing='0'
            {...rest}
        >
            {!hideWeekdays && (
                <thead>
                    <tr>{weekdays}</tr>
                </thead>
            )}
            <tbody>{rows}</tbody>
        </table>
    )
})

export default Month
