import * as React from 'react'

export type RadioGroupContextValue = {
    vertical?: boolean
    disabled?: boolean
    onChange?: (
        value: string | undefined,
        event: React.ChangeEvent<HTMLInputElement>
    ) => void
    value?: string | null | undefined
    color?: string
    radioGutter: number
}

const defaultValues: RadioGroupContextValue = {
    vertical: false,
    disabled: false,
    onChange: () => true,
    value: undefined,
    color: 'primary-600',
    radioGutter: 3,
}

const RadioGroupContext =
    React.createContext<RadioGroupContextValue>(defaultValues)

export const RadioGroupContextProvider = RadioGroupContext.Provider

export default RadioGroupContext
