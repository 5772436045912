
import i18n from '@viterbit/web-app/i18n'

import { StatusType } from '../../types'


export enum RelevantDayType {
    Holiday = 'Holiday',
    LeaveFullDay = 'LeaveFullDay',
    LeaveHalfDay = 'LeaveHalfDay',
    LeavePendingDay = 'LeavePendingDay',
}

export const getRelevantClassBackground = (status) =>
    status === StatusType.APPROVED
        ? 'bg-green-200'
        : status === StatusType.PENDING
            ? 'bg-red-200'
            : 'bg-transparent'

export const getCurrentStatus = (status) =>
    status === StatusType.APPROVED
        ? i18n.t('leaves:relevantDays.status.approved')
        : status === StatusType.PENDING
            ? i18n.t('leaves:relevantDays.status.pending')
            : ''
