import * as React from 'react'

import { clsxm } from '../../lib/clsxm'

const classes = {
    tag: 'rounded-full py-1 px-2.5 border items-center border-gray-200 dark:border-gray-600 inline-flex text-xs font-semibold whitespace-nowrap',
    'tag-affix': 'h-2 w-2 rounded-full bg-gray-300',
    'tag-prefix': 'mr-1.5 rtl:ml-1.5',
    'tag-suffix': 'ml-1.5 rtl:mr-1.5',
}

type TagProps = {
    className?: string
    prefixClass?: string
    prefix?: boolean | React.ReactNode
    suffix?: boolean | React.ReactNode
    suffixClass?: string
    children: React.ReactNode
}

const Tag = React.forwardRef<HTMLDivElement, TagProps>(
    (
        {
            className,
            prefix = false,
            suffix = false,
            children,
            prefixClass,
            suffixClass,
        },
        ref
    ) => (
        <div ref={ref} className={clsxm(classes.tag, className)}>
            {prefix && typeof prefix === 'boolean' && (
                <span
                    className={clsxm(
                        classes['tag-affix'],
                        classes['tag-prefix'],
                        prefixClass
                    )}
                />
            )}
            {typeof prefix === 'object' && prefix}
            {children}
            {suffix && typeof suffix === 'boolean' && (
                <span
                    className={clsxm(
                        classes['tag-affix'],
                        classes['tag-suffix'],
                        suffixClass
                    )}
                />
            )}
            {typeof suffix === 'object' && suffix}
        </div>
    )
)

export default Tag
