/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FindLeaveReasonQueryResponse
 */
export interface FindLeaveReasonQueryResponse {
    /**
     * 
     * @type {string}
     * @memberof FindLeaveReasonQueryResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof FindLeaveReasonQueryResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof FindLeaveReasonQueryResponse
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof FindLeaveReasonQueryResponse
     */
    color: string;
    /**
     * 
     * @type {boolean}
     * @memberof FindLeaveReasonQueryResponse
     */
    needApproval: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FindLeaveReasonQueryResponse
     */
    needDocumentation: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FindLeaveReasonQueryResponse
     */
    visibleOtherEmployees: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FindLeaveReasonQueryResponse
     */
    timeDiscount: boolean;
    /**
     * 
     * @type {number}
     * @memberof FindLeaveReasonQueryResponse
     */
    limitedTo: number;
}

/**
 * Check if a given object implements the FindLeaveReasonQueryResponse interface.
 */
export function instanceOfFindLeaveReasonQueryResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "color" in value;
    isInstance = isInstance && "needApproval" in value;
    isInstance = isInstance && "needDocumentation" in value;
    isInstance = isInstance && "visibleOtherEmployees" in value;
    isInstance = isInstance && "timeDiscount" in value;
    isInstance = isInstance && "limitedTo" in value;

    return isInstance;
}

export function FindLeaveReasonQueryResponseFromJSON(json: any): FindLeaveReasonQueryResponse {
    return FindLeaveReasonQueryResponseFromJSONTyped(json, false);
}

export function FindLeaveReasonQueryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindLeaveReasonQueryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': json['description'],
        'color': json['color'],
        'needApproval': json['needApproval'],
        'needDocumentation': json['needDocumentation'],
        'visibleOtherEmployees': json['visibleOtherEmployees'],
        'timeDiscount': json['timeDiscount'],
        'limitedTo': json['limitedTo'],
    };
}

export function FindLeaveReasonQueryResponseToJSON(value?: FindLeaveReasonQueryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'color': value.color,
        'needApproval': value.needApproval,
        'needDocumentation': value.needDocumentation,
        'visibleOtherEmployees': value.visibleOtherEmployees,
        'timeDiscount': value.timeDiscount,
        'limitedTo': value.limitedTo,
    };
}

