import { useInfiniteQuery } from '@tanstack/react-query'
import { LEAVE_POLICY_QUERY_KEY } from '@viterbit/web-app/dataAccess/cacheKeys'
import { leavePolicyList } from '@viterbit/web-app/dataAccess/service/leavePolicyService'

type LeavePolicyListConfig = {
    query: Parameters<typeof leavePolicyList>[0]
    enabled?: boolean
}

export const useLeavePolicyListPaginated = (config?: LeavePolicyListConfig) => {
    const { query = { page: 1 }, enabled = true } = config || {}

    return useInfiniteQuery(
        [LEAVE_POLICY_QUERY_KEY, 'list-paginated', query],
        ({ pageParam }) => leavePolicyList({ page: pageParam }),
        {
            getNextPageParam: (lastPage) => lastPage ? lastPage.meta.nextPage : 1,
            initialData: () => ({
                pageParams: [1],
                pages: [],
            }),
            enabled,
        }
    )
}

