/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FindCompanyQueryResponse } from './FindCompanyQueryResponse';
import {
    FindCompanyQueryResponseFromJSON,
    FindCompanyQueryResponseFromJSONTyped,
    FindCompanyQueryResponseToJSON,
} from './FindCompanyQueryResponse';
import type { ListMeta } from './ListMeta';
import {
    ListMetaFromJSON,
    ListMetaFromJSONTyped,
    ListMetaToJSON,
} from './ListMeta';

/**
 * 
 * @export
 * @interface ListCompanyQueryResponse
 */
export interface ListCompanyQueryResponse {
    /**
     * 
     * @type {ListMeta}
     * @memberof ListCompanyQueryResponse
     */
    meta: ListMeta;
    /**
     * 
     * @type {Array<FindCompanyQueryResponse>}
     * @memberof ListCompanyQueryResponse
     */
    data: Array<FindCompanyQueryResponse>;
}

/**
 * Check if a given object implements the ListCompanyQueryResponse interface.
 */
export function instanceOfListCompanyQueryResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "meta" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function ListCompanyQueryResponseFromJSON(json: any): ListCompanyQueryResponse {
    return ListCompanyQueryResponseFromJSONTyped(json, false);
}

export function ListCompanyQueryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListCompanyQueryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'meta': ListMetaFromJSON(json['meta']),
        'data': ((json['data'] as Array<any>).map(FindCompanyQueryResponseFromJSON)),
    };
}

export function ListCompanyQueryResponseToJSON(value?: ListCompanyQueryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'meta': ListMetaToJSON(value.meta),
        'data': ((value.data as Array<any>).map(FindCompanyQueryResponseToJSON)),
    };
}

