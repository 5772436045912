import { TimeInputProps } from '@mantine/dates'
import * as React from 'react'
import { Controller } from 'react-hook-form'
import ControlledFormControl from 'ui/src/components/Form/ControlledFormControl'
import { FormControlBaseProps } from 'ui/src/components/Form/FormControl'
import { FormBaseProps } from 'ui/src/components/Form/shared'

const FieldCkEditor = React.lazy(() => import('./FieldCkEditor'))

type FormTimePickerProps = FormBaseProps &
    TimeInputProps &
    FormBaseProps &
    FormControlBaseProps

const ControlledFieldCkEditor = ({
    name,
    control,
    asterisk,
    label,
    extra,
    className,
    rules,
    ...rest
}: FormTimePickerProps) => (
    <Controller
        control={control}
        name={name}
        key={name}
        rules={rules}
        render={({ field }) => (
            <ControlledFormControl
                control={control}
                label={label}
                asterisk={asterisk}
                extra={extra}
                className={className}
                {...field}
            >
                <React.Suspense fallback={<div>Loading...</div>}>
                    <FieldCkEditor
                        {...rest}
                        {...field}
                    />
                </React.Suspense>
            </ControlledFormControl>
        )}
    />
)

export default ControlledFieldCkEditor
