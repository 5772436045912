
import { classValidatorResolver } from '@hookform/resolvers/class-validator'
import { UpdateEmployeePayload } from '@viterbit/web-app/dataAccess/dto'
import { Employee } from '@viterbit/web-app/dataAccess/model/Employee.model'
import { employeeUpdate } from '@viterbit/web-app/dataAccess/service/employeeService'
import i18n from '@viterbit/web-app/i18n'
import FieldsetGeoData from '@viterbit/web-app/shared/form/FieldsetGeoData'
import Form from '@viterbit/web-app/shared/form/Form'
import FormSelectCity from '@viterbit/web-app/shared/form/FormSelectCity'
import FormSelectCountry from '@viterbit/web-app/shared/form/FormSelectCountry'
import FormSelectState from '@viterbit/web-app/shared/form/FormSelectState'
import SubmitButton from '@viterbit/web-app/shared/form/SubmitButton'
import useLocale from '@viterbit/web-app/shared/utils/useLocale'
import { useMemo } from 'react'
import FormInput from 'ui/src/components/Form/FormInput'
import clsxm from 'ui/src/lib'

import EmployeePersonalAddressInformationValidator from './EmployeePersonalAddressInformationValidator'

export type EmployeePersonalAddressInformationFields = Pick<
    UpdateEmployeePayload,
    'address' | 'city' | 'postalCode' | 'country' | 'state'
>

export type EmployeePersonalAddressInformationFormProps = {
    employee: Employee
    className?: string
}

const resolver = classValidatorResolver(EmployeePersonalAddressInformationValidator)

const getDefaultValues = ({
    address,
    city,
    postalCode,
    country,
    state
}: Employee): EmployeePersonalAddressInformationFields => ({
    address,
    city,
    postalCode,
    country,
    state
})

const EmployeePersonalAddressInformationForm = ({
    employee,
    className,
}: EmployeePersonalAddressInformationFormProps) => {
    const locale = useLocale()
    const defaultValues = useMemo(() => getDefaultValues(employee), [employee])
    return (
        <Form<EmployeePersonalAddressInformationFields>
            withTransition
            smallButtons
            mutationConfig={{
                mutationFn: (updateEmployeeCommand) =>
                    employeeUpdate({ id: employee.id, updateEmployeeCommand }),
            }}
            formConfig={{
                resolver,
                mode: 'all',
                defaultValues,
            }}
            className={clsxm(
                'p-4 bg-white border border-gray-200 rounded-lg',
                className
            )}
        >
            {(form) => (
                <>
                    <FieldsetGeoData>
                        <FormSelectCountry
                            data-testid='select-country-address-info'
                            locale={locale}
                            name='country'
                            asterisk
                            control={form.control}
                            label={i18n.t('personalArea:profile.addressInformation.form.country.label')}
                            placeholder={i18n.t('personalArea:profile.addressInformation.form.country.placeholder')}
                        />
                        <FormSelectState
                            name='state'
                            asterisk
                            control={form.control}
                            label={i18n.t('personalArea:profile.addressInformation.form.state.label')}
                            placeholder={i18n.t('personalArea:profile.addressInformation.form.state.placeholder')}
                        />
                        <FormSelectCity
                            name='city'
                            control={form.control}
                            label={i18n.t('personalArea:profile.addressInformation.form.city.label')}
                            placeholder={i18n.t('personalArea:profile.addressInformation.form.city.placeholder')}
                            isClearable
                        />
                    </FieldsetGeoData>
                    <FormInput
                        name='address'
                        control={form.control}
                        label={i18n.t('personalArea:profile.addressInformation.form.address.label')}
                        placeholder={i18n.t('personalArea:profile.addressInformation.form.address.placeholder')}
                    />
                   
                    <FormInput
                        name='postalCode'
                        control={form.control}
                        label={i18n.t('personalArea:profile.addressInformation.form.postalCode.label')}
                        placeholder={i18n.t('personalArea:profile.addressInformation.form.postalCode.placeholder')}
                    />
                    <SubmitButton data-testid='employee-address-info-submit-button'>{i18n.t('common:buttons.save')}</SubmitButton>
                </>
            )}
        </Form>
    )
}

export default EmployeePersonalAddressInformationForm
