import { JobPosition } from '@viterbit/web-app/dataAccess/model/JobPosition.model'
import SegmentNavLink from '@viterbit/web-app/shared/navigation/SegmentNavLink'
import * as React from 'react'
import List from 'ui/src/components/List'

export type JobPositionItemProps = {
    position: JobPosition
}

const JobPositionItem = ({position}: JobPositionItemProps) => (
    <SegmentNavLink path={`/employees/positions/${position.id}/details`}>
        <List.Item clickable>
            <List.ItemText primary={position.name} secondary={position.description} />
        </List.Item>
    </SegmentNavLink>
)

export default JobPositionItem
