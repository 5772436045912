import { IsBIC, IsIBAN, ValidateIf } from '@viterbit/web-app/shared/utils/ClassValidators'

import { EmployeePersonalBankInfoFields } from './EmployeePersonalBankInfoShared'

export class EmployeePersonalBankInfoValidator implements EmployeePersonalBankInfoFields {
    bankName: string
    @ValidateIf(o => o.bankNumber)
    @IsIBAN()
    bankNumber: string
    @ValidateIf(o => o.bankSwift)
    @IsBIC()
    bankSwift: string
}
