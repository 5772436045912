import dayjs from 'dayjs'

const dateMapper = {
    toDate: (date: dayjs.ConfigType) => {
        if (!date) return null
        return dayjs(date).format('YYYY-MM-DD')
    },
    toDates: (...dates: dayjs.ConfigType[]) => dates.map(dateMapper.toDate),
    toDateUTC: (date: string) => {
        if (!date) return null
        return dayjs(new Date(date).toUTCString()).format('YYYY-MM-DD')
    },
    toDatesUTC: (...dates: dayjs.ConfigType[]) =>
        dates.map(dateMapper.toDateUTC),
    toTimestamp: (date: dayjs.ConfigType) => {
        if (!date) return null
        return dayjs(date).unix()
    },
    toDateTime: (date: dayjs.ConfigType) => {
        if (!date) return null
        return dayjs(date).format('YYYY-MM-DD HH:mm:ss')
    },
    toDateTimeUTC: (date: dayjs.ConfigType) =>
        dayjs(dayjs(date).toDate().toUTCString()).format('YYYY-MM-DD HH:mm:ss'),
    toDayWithMonth: (date: dayjs.ConfigType) => {
        if (!date) return null
        return dayjs(date).format('MM-DD')
    },
    toHourWithSeconds: (date: dayjs.ConfigType) => {
        if (!date) return null
        const parsed = dayjs(date)
        if (!parsed.isValid()) {
            return null
        }
        return dayjs(date).format('MM:mm')
    },
    toYearWithMonth: (date: dayjs.ConfigType) => {
        if (!date) return null
        return dayjs(date).format('YYYY-MM')
    },
    fromDayWithMonth: (date: string) => {
        if (!date) return null
        return dayjs(date, 'MM-DD')
    },
}

export default dateMapper