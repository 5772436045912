/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TimeTrackingEstimation
 */
export interface TimeTrackingEstimation {
    /**
     * 
     * @type {string}
     * @memberof TimeTrackingEstimation
     */
    date: string;
    /**
     * 
     * @type {string}
     * @memberof TimeTrackingEstimation
     */
    estimation: string;
    /**
     * 
     * @type {string}
     * @memberof TimeTrackingEstimation
     */
    worked: string;
}

/**
 * Check if a given object implements the TimeTrackingEstimation interface.
 */
export function instanceOfTimeTrackingEstimation(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "estimation" in value;
    isInstance = isInstance && "worked" in value;

    return isInstance;
}

export function TimeTrackingEstimationFromJSON(json: any): TimeTrackingEstimation {
    return TimeTrackingEstimationFromJSONTyped(json, false);
}

export function TimeTrackingEstimationFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimeTrackingEstimation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': json['date'],
        'estimation': json['estimation'],
        'worked': json['worked'],
    };
}

export function TimeTrackingEstimationToJSON(value?: TimeTrackingEstimation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': value.date,
        'estimation': value.estimation,
        'worked': value.worked,
    };
}

