import * as React from 'react'

import { MenuContextProvider } from './context/menuContext'
import { ExtendedModeType } from '../Layout/sharedTypes'
import { clsxm } from '../../lib/clsxm'

const classes = {
    menuTitle: 'uppercase font-semibold px-3 mt-4 mb-2',
    light: 'bg-white',
    dark: 'bg-gray-800',
    transparent: 'bg-transparent',
}

export type _MenuProps = {
    menuItemHeight?: number
    variant?: ExtendedModeType
    sideCollapsed?: boolean
    defaultExpandedKeys?: string[]
    defaultActiveKeys?: string[]
    onSelect?: (eventKey: string, e: React.MouseEvent) => void
} & Omit<React.HTMLAttributes<HTMLDivElement>, 'onSelect'>

const _Menu = React.forwardRef<HTMLDivElement, _MenuProps>(
    (
        {
            children,
            className,
            onSelect,
            menuItemHeight = 40,
            variant = 'light',
            sideCollapsed = false,
            defaultExpandedKeys = [],
            defaultActiveKeys = [],
            ...rest
        },
        ref
    ) => {
        const menuDefaultClass = 'flex flex-col'

        const menuClass = clsxm(menuDefaultClass, className)

        return (
            <nav data-test='menu' ref={ref} className={menuClass} {...rest}>
                <MenuContextProvider
                    value={{
                        onSelect,
                        menuItemHeight,
                        variant,
                        sideCollapsed,
                        defaultExpandedKeys,
                        defaultActiveKeys,
                    }}
                >
                    {children}
                </MenuContextProvider>
            </nav>
        )
    }
)

export default _Menu
