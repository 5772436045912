import SimpleCard from '@viterbit/web-app/shared/display/SimpleCard'
import HeaderActions from '@viterbit/web-app/shared/layout/HeaderActions'
import HeaderActionsTitle from '@viterbit/web-app/shared/layout/HeaderActions/HeaderActionsTitle'
import * as React from 'react'

import { DocumentProps } from '../DocumentEmloyeeShared'

type DocumentEmployeeDetailSectionProps = {
    children?: React.ReactNode
    className?: string
    title: string
}  & DocumentProps

const styles = {
    itemWrapper: 'hover:bg-grey-50',
    iconWrapper: 'flex items-center justify-center rounded-md aspect-square w-14'
}

const DocumentEmployeeDetailSection = (props: DocumentEmployeeDetailSectionProps) => {
    const { children, className, type, title, employeeId } = props

    return (
        <SimpleCard className={className}>
            <HeaderActions
                className='p-4 pb-0 bg-white'
            >
                <HeaderActions.Header>
                    <HeaderActionsTitle>
                        {title}
                    </HeaderActionsTitle>
                </HeaderActions.Header>

            </HeaderActions>
    
            {children}
        </SimpleCard>

    )
}

export default DocumentEmployeeDetailSection
