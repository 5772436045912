/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Planning } from '@viterbit/web-app/dataAccess/model/Planning.model'
import * as React from 'react'
import { useImmer } from 'use-immer'

import EmployeePlanningDelete from '../delete/EmployeePlanningDelete'
import EmployeePlanningUpdateDialog from '../update/EmployeePlanningUdateDialog'

type EmployeePlanningListAction = {
    type: 'delete' | 'edit' | null
    planning: Planning | null
}

const initialState: EmployeePlanningListAction = {
    type: null,
    planning: null,
}


const useEmployeePlanningAction = () => {

    const [action, setAction] = useImmer<EmployeePlanningListAction>(initialState)

    const onItemDelete = React.useCallback((planning: Planning) => {
        setAction(draft => {
            draft.type = 'delete'
            draft.planning = planning
        })
    }, [])

    const onItemEdit = React.useCallback((planning: Planning) => {
        setAction(draft => {
            draft.type = 'edit'
            draft.planning = planning
        })
    }, [])

    const closeAction = React.useCallback(() => {
        setAction(draft => {
            draft.type = null
            draft.planning = null
        })
    }, [])

    const dialog = React.useMemo(() => {
        if (action.type === 'delete') {
            return  (
                <EmployeePlanningDelete
                    isOpen
                    key={action.planning!.id}
                    recordId={action.planning!.id}
                    onClose={closeAction}
                />
            )
        }

        if (action.type === 'edit') {
            return (
                <EmployeePlanningUpdateDialog
                    isOpen
                    key={action.planning!.id}
                    planning={action.planning!}
                    onClose={closeAction}
                />
            )
        }
    }, [action])

    return {
        dialog,
        onItemDelete,
        onItemEdit,
    }
}

export default useEmployeePlanningAction