import * as React from 'react'

import { ListItemItemProps } from './List.d'
import clsxm from '../../lib'

const styles = {
    root: [
        'flex flex-row items-center',
        'px-6 py-4 gap-4 min-h-[60px]',
        'group-[.active]:bg-grey-50',
    ],
    clickable: 'hover:bg-gray-50 cursor-pointer'
}

const ListItem = (props: ListItemItemProps) => {
    const { children, className, clickable, ...rest } = props

    return (
        <li role="listitem" className={clsxm(styles.root, clickable && styles.clickable, className)} {...rest}>
            {children}
        </li>
    )
}

export default ListItem
