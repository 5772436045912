import { clsxm } from '@viterbit/web-app/shared/utils/clsxm'
import * as React from 'react'

export const Tabs = React.forwardRef<
    HTMLDivElement,
    {
        disabled?: boolean
        className?: string
        children: React.ReactNode
    }
>(({ className, disabled, children, ...props }, ref) => (
    <span
        ref={ref}
        className={clsxm(
            'inline-flex w-full text-center rounded-md isolate',
            className,
            disabled && 'cursor-not-allowed pointer-events-none opacity-50'
        )}
        {...props}
    >
        {children}
    </span>
))

const classes = {
    base:'min-w-[102px]',
    left: 'relative inline-flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-l-md hover:bg-gray-50 focus:z-10 focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500',
    middle: 'relative inline-flex items-center justify-center w-full px-4 py-2 -ml-px text-sm font-medium text-center text-gray-700 bg-white border border-gray-300 hover:bg-gray-50 focus:z-10 focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500',
    right: 'relative inline-flex items-center justify-center w-full px-4 py-2 -ml-px text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-r-md hover:bg-gray-50 focus:z-10 focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500',
    selected:
        'text-primary-600 bg-primary-50 ring-primary-600 outline-none border-primary-600 z-10',
}

type TabOptionProps = {
    children?: React.ReactNode
    className?: string
    id?: string
    selected?: boolean
    variant: 'left' | 'middle' | 'right'
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
}

const TabOption = ({
    onClick,
    children,
    selected,
    variant = 'right',
}: TabOptionProps) => (
    <button
        onClick={onClick}
        type='button'
        className={clsxm(classes.base,classes[variant], selected && classes.selected)}
    >
        {children}
    </button>
)

export default TabOption
