import { Alert } from '@mantine/core'
import { useSubscriptionList } from '@viterbit/web-app/dataAccess/useCase/subscription/useSubscriptionList'
import i18n from '@viterbit/web-app/i18n'
import FormSkeleton from 'ui/src/components/Form/FormSkeleton'

import SubscriptionList from '../SubscriptionList'

const BillingSubscriptionListView = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const { data, isInitialLoading } = useSubscriptionList()

    if (isInitialLoading) {
        return <FormSkeleton count={1} />
    }

    if (!data) {
        return <Alert color='warning'>{i18n.t('billing:missingSubscription')}</Alert>
    }

    return (
        <SubscriptionList list={data} />
    )
}

export default BillingSubscriptionListView