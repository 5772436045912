export type leaveStatus = 'previous' | 'upcoming' | 'all'

export type Status = 'PENDING' | 'APPROVED' | 'REJECT'

export enum LeavesClusters {
    PAST = 'past',
    UPCOMING = 'upcoming',
    REJECT = 'reject',
    PENDING = 'pending',
}

export enum StatusType {
    PENDING = 'PENDING',
    APPROVED = 'APPROVED',
    REJECT = 'REJECT',
}

export type RelevantDate = {
    label: string
    date: string
    color: string
    isHoliday: boolean
    halfDay: boolean
    status?: string | undefined
    total?: number
    turn?: 'MORNING' | 'AFTERNOON'
    leaveId?: string
}
