

import leavePolicyMapper from '@viterbit/web-app/dataAccess/mapper/leavePolicy.mapper'
import paginationMetaMapper from '@viterbit/web-app/dataAccess/mapper/paginationMeta.mapper'
import { queryClient } from '@viterbit/web-app/shared/lib/ReactQueryProvider'
import { Configuration, LeavePolicyApi } from 'viterbit-api-sdk'

import { commonConfig } from './config'
import { LEAVE_POLICY_QUERY_KEY } from '../cacheKeys'

const api = new LeavePolicyApi(new Configuration(commonConfig))

export const leavePolicyInvalidate = () =>
    queryClient.invalidateQueries([LEAVE_POLICY_QUERY_KEY])


export const leavePolicyCreate = (body: Parameters<typeof api.createLeavesPolicy>[0]) =>
    api.createLeavesPolicy(body).then((data) => {
        leavePolicyInvalidate()
        return data
    })

export const leavePolicyUpdate = (body: Parameters<typeof api.updateLeavePolicies>[0]) =>
    api.updateLeavePolicies(body).then((data) => {
        leavePolicyInvalidate()
        return data
    })

export const leavePolicyDelete = (body: Parameters<typeof api.deleteLeavePolicies>[0]) =>
    api.deleteLeavePolicies(body).then((data) => {
        leavePolicyInvalidate()
        return data
    })

export const leavePolicyList = (body: Parameters<typeof api.listLeavePolicies>[0]) =>
    api.listLeavePolicies(body).then(({data, meta}) => ({
        meta: paginationMetaMapper(meta),
        data: data.map(leavePolicyMapper.toModel)
    }))

export const leavePolicyDetail = (body: Parameters<typeof api.findLeavePolicies>[0]) =>
    api.findLeavePolicies(body).then(leavePolicyMapper.toModel)


