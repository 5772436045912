
import { useInfiniteQuery } from '@tanstack/react-query'
import { COMPLAINT_KEY } from '@viterbit/web-app/dataAccess/cacheKeys'

import { complaintPrivateListPaginated } from '../../service/complaintService'

type CompanyListConfig = {
    query: Parameters<typeof complaintPrivateListPaginated>[0]
    enabled?: boolean
}

export const useComplaintPrivateListPaginated = (config?: CompanyListConfig) => {
    const { query = { page: 1 }, enabled } = config || {}

    return useInfiniteQuery(
        [COMPLAINT_KEY, 'private-list-paginated', query],
        ({ pageParam }) => complaintPrivateListPaginated({ ...query, page: pageParam }),
        {
            getNextPageParam: (lastPage) => lastPage ? lastPage.meta.nextPage : 1,
            initialData: () => ({
                pageParams: [1],
                pages: [],
            }),
            enabled,
        }
    )
}

