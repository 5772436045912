
import { classValidatorResolver } from '@hookform/resolvers/class-validator'
import { JOB_POSITION_LEVEL_QUERY_KEY } from '@viterbit/web-app/dataAccess/cacheKeys'
import { jobPositionLevelCreate } from '@viterbit/web-app/dataAccess/service/jobPositionLevelService'
import * as React from 'react'

import JobPositionLevelCreateForm from './JobPositionLevelCreateForm'
import JobPositionLevelCreateValidator from './JobPositionLevelCreateValidator'

type JobPositionLevelCreateViewProps = {
    children?: React.ReactNode
    className?: string
    positionId: string
    onFinish: any
}

const resolver = classValidatorResolver(JobPositionLevelCreateValidator)

const JobPositionLevelCreateView = ({
    positionId,
    onFinish
}: JobPositionLevelCreateViewProps) => (
    <JobPositionLevelCreateForm
        positionId={positionId}
        onCancel={() => onFinish()}
        formConfig={{
            resolver,
            mode: 'all',
            defaultValues: {
                jobPositionId: positionId,
                name: '',
                description: ''
            },
        }}
        mutationConfig={{
            mutationKey: [JOB_POSITION_LEVEL_QUERY_KEY],
            onSuccess: () => onFinish(),
            mutationFn: (data) => jobPositionLevelCreate({createJobPositionLevelCommand: data}),
        }}
    />
)

export default JobPositionLevelCreateView
