import { useMutation } from '@tanstack/react-query'

import { workScheduleDelete } from '../../service/workScheduledService'

type WorkScheduleDeleteParams = {
    onSuccess?: () => void
    onError?: () => void
}

export const useWorkScheduleDelete = (config: WorkScheduleDeleteParams = {}) =>
    useMutation({
        ...config,
        mutationFn: workScheduleDelete,
        onSuccess: config.onSuccess,
        onError: config.onError,
    })
