import { IMapper } from './__common'
import jobPositionLevelMapper from './jobPositionLevel.mapper'
import { JobPositionResponse } from '../dto/jobPosition'
import { JobPosition } from '../model/JobPosition.model'

const jobPositionMapper: IMapper<JobPosition, JobPositionResponse> = {
    toModel: (dto) => ({
        id: dto.id,
        name: dto.name,
        description: dto.description ?? undefined,
        jobPositionLevels: dto.jobPositionLevels?.data.map(jobPositionLevelMapper.toModel) ?? []
    }),

    toDto: (model) => ({
        id: model.id,
        name: model.name,
        description: model.description,
    }),
}

export default jobPositionMapper