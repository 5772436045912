import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react'
import PdfViewerSkeleton from '@viterbit/web-app/shared/feedback/PdfViewer/PdfViewerSkeleton'
import ButtonSkeleton from 'ui/src/components/Button/ButtonSkeleton'
import Skeleton from 'ui/src/components/Skeleton'

import DocumentBulkUploadPreviewEmployeeListSkeleton from './employeeList/DocumentBulkUploadPreviewEmployeeListSkeleton'

const DocumentBulkUploadPreviewSkeleton = () => (
    <div className='grid grid-cols-12 gap-4 pr-1'>
        <div className='min-h-[500px] col-span-6 xl:col-span-7 2xl:col-span-8'>
            <div className='flex flex-col w-full gap-y-2'>
                <div className='flex items-center justify-between h-[72px] px-2 border-2 rounded-md bg-white'>
                    <span className='inline-flex rounded-md shadow-sm isolate'>
                        <div className='relative inline-flex items-center px-2 py-2 text-sm font-medium text-gray-300 bg-white border border-gray-300 rounded-l-md focus:z-10 focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500'>
                            <IconChevronLeft className='w-5 h-5' />
                        </div>
                        <span className='relative inline-flex items-center px-3 py-2 -ml-px text-sm font-medium text-gray-700 bg-white border border-gray-300 focus:z-10 focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500'>
                            <Skeleton animated className='w-5' />
                            <span className='mx-1'>/</span>
                            <Skeleton animated className='w-5' />
                        </span>
                        <div
                            className='relative inline-flex items-center px-2 py-2 -ml-px text-sm font-bold text-gray-300 bg-white border border-gray-300 rounded-r-md focus:z-10 focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500'>
                            <IconChevronRight className='w-5 h-5 ' />
                        </div>
                    </span>
                    <Skeleton animated className='w-28' />
                    <ButtonSkeleton />
                </div>
                <PdfViewerSkeleton className='h-[500px]' />
            </div>
            
        </div>
        <div className='col-span-6 xl:col-span-5 2xl:col-span-4'>
            <DocumentBulkUploadPreviewEmployeeListSkeleton />
        </div>
    </div>
)

export default DocumentBulkUploadPreviewSkeleton
