/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/no-array-index-key */
import last from 'lodash/last'
import * as React from 'react'

import ChatMessageComponent, { ChatMessage, ChatMessageProps } from './ChatMessage'

type ChatMessagesProps = {
    items: ChatMessage[]
}

import { Badge } from '@mantine/core'
import { IconMailboxOff } from '@tabler/icons-react'
import dateMapper from '@viterbit/web-app/dataAccess/mapper/date.mapper'
import i18n from '@viterbit/web-app/i18n'

import EmptyState from '../../display/EmptyState/EmptyState'


const groupByDate = (array): Record<string, ChatMessage[]> => {
    const grouped = array.reduce((acc, item: ChatMessage) => {
        const date = dateMapper.toDate(item.createdAt)!
        if (!acc[date]) acc[date] = []
        acc[date].push(item)
        return acc
    }, {})

    return grouped
}

const groupByEmployee = (messages: ChatMessage[]) => {
    if (!messages.length) return []

    const items: ChatMessageProps[] = []
  
    while (messages.length > 0) {
        const item = messages.shift()!
        const lastItem: ChatMessageProps = last(items)

        if (lastItem && item.employeeName === lastItem?.employeeName) {
            lastItem.messages.push(item)
            continue
        }
        
        items.push({
            avatarProps: {
                alt: item.employeeName,
            },
            variant: item.employeeName ? 'left' : 'right',
            employeeName: item.employeeName,
            messages: [item],
        })
    }
  
    return items
}


const ChatMessages = (props: ChatMessagesProps) => {
    const { items = [] } = props

    const containerRef = React.useRef<HTMLDivElement>(null)

    const grouped = React.useMemo(() => {
        const groupedByDate = groupByDate(items)
        const byUser = Object.entries(groupedByDate).reduce((acc, [date, items]) => {
            acc[date] = groupByEmployee(items)
            return acc
        }, {})

        return byUser
    }, [items])

    // scroll to bottom at start
    React.useEffect(() => {
        const container = containerRef.current
        if (container) container.scrollTop = container.scrollHeight
    }, [items])


    return (
        <div ref={containerRef} className='flex flex-col flex-1 min-h-0 gap-2 px-4 mt-4 overflow-auto'>
            {!items.length && (
                <EmptyState
                    className='h-full'
                    image={<IconMailboxOff size={64}  stroke={1} />}
                    title={i18n.t('complaint:detail.chat.emptyTitle')}
                    description={i18n.t('complaint:detail.chat.emptyDescription')}
                />
            )}
            {Object.entries(grouped).map(([date, items]: [string, ChatMessageProps[]]) => (
                <>
                    <p className='sticky top-0 z-10 flex justify-center'>
                        <Badge variant='light' color='gray'>{date}</Badge>
                    </p>

                    {items.map((item, index) => (
                        <ChatMessageComponent key={`${date}-${index}`} {...item} />
                    ))}
                </>
            ))}
        </div>

    )
}

export default ChatMessages
