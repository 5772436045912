import { Leave } from '@viterbit/web-app/dataAccess/model/Leave.model'
import { PermissionAction } from '@viterbit/web-app/dataAccess/model/PermissionAction.model'
import i18n from '@viterbit/web-app/i18n'
import useHasPermission from '@viterbit/web-app/shared/permission/useHasPermissions'
import * as React from 'react'
import Dialog from 'ui/src/components/Dialog'

import ReviewAction from './ReviewActions'
import DeleteAction from '../Delete/DeleteAction'
import DeleteSection from '../Delete/DeleteSection'
import EditForm from '../Forms/Edit'

type ReviewDialogProps = {
    employeeId: string
    leave: Leave
    open: boolean
    onClose: () => void
}
const ReviewDialog = ({
    employeeId,
    open,
    onClose,
    leave,
}: ReviewDialogProps) => {
    const [editMode, setEditMode] = React.useState(true)

    const { hasPermission } = useHasPermission({
        [PermissionAction.CORE_HR_LEAVE_APPROVE]: [leave.id],
        [PermissionAction.CORE_HR_LEAVE_UPDATE]: [leave.id],
        [PermissionAction.CORE_HR_LEAVE_DELETE]: [leave.id],
    })

    const {
        canReview,
        canEdit,
        canDelete,
    } = React.useMemo(() => ({
        canReview: hasPermission(PermissionAction.CORE_HR_LEAVE_APPROVE, leave.id),
        canEdit: hasPermission(PermissionAction.CORE_HR_LEAVE_UPDATE, leave.id),
        canDelete: hasPermission(PermissionAction.CORE_HR_LEAVE_DELETE, leave.id),
    }), [hasPermission, leave.id])

    const handleClose = () => {
        onClose()
        setTimeout(() => {
            setEditMode(true)
        }, 1000)
    }


    return (
        <Dialog
            title={i18n.t('leaves:editDialog.title')}
            open={open}
            onClose={handleClose}
        >

            {editMode ? (
                <>
                    <EditForm
                        leaveId={leave.id}
                        employeeId={employeeId}
                        onSubmitCallBack={handleClose}
                        isReadOnly={!canEdit}
                    />
                    <div className='flex items-center justify-between'>
                        {canDelete && (
                            <DeleteAction onClick={() => setEditMode(false)}/>
                        )}
            
                        {canReview && (
                            <ReviewAction
                                leave={leave}
                                onSubmitCallBack={handleClose}
                            />
                        )}
                    </div>
                </>
            ) : (
                <DeleteSection
                    leaveId={leave.id}
                    onDelete={handleClose}
                    onCancel={() => setEditMode(true)}
                />
            )}

        </Dialog>
    )
}

export default ReviewDialog
