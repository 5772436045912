import { useEmployeeDetail } from '@viterbit/web-app/dataAccess/useCase/employee/useEmployeeDetail'

import { useUser } from './useUser'

const useCurrentUser = () => {
    const user = useUser()
    
    const {
        data,
    } = useEmployeeDetail({
        query: {
            id: user.id,
        },
        enabled: !!user.id
    })

    return data
}

export default useCurrentUser
