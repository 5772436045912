import { Button } from '@mantine/core'
import i18n from '@viterbit/web-app/i18n'
import * as React from 'react'
import Dialog from 'ui/src/components/Dialog'

type DeleteDialogProps = {
    isOpen: boolean
    onClose: () => void
    onSubmit?: () => void
    title?: string
    submitButtonText?: string
    cancelButtonText?: string
    isLoading?: boolean
    description?: string
    children?: React.ReactNode
    buttonConfirmColor?: string
}

const FormConfirmDialog = ({
    isOpen,
    onClose,
    cancelButtonText = i18n.t('buttons.cancel'),
    onSubmit,
    submitButtonText = i18n.t('buttons.confirm'),
    title,
    isLoading,
    children,
    description = i18n.t('notification.confirm'),
    buttonConfirmColor = 'primary',
}: DeleteDialogProps) => (
    <Dialog open={isOpen} onClose={onClose} title={title} size='sm'>
        <h5 className='flex flex-col gap-2 pb-4 text-sm '>
            <span className='font-normal'>
                {description}
            </span>
        </h5>
        {children}
        <div className='flex justify-end mt-4 gap-x-2'>
            <Button
                onClick={onClose}
                variant='outline'
                color='gray'
            >
                {cancelButtonText}
            </Button>
            <Button
                onClick={onSubmit}
                type='submit'
                loading={isLoading}
                color={buttonConfirmColor}
                data-testid='confirm-dialog-submit-button'
            >
                {submitButtonText}
            </Button>
        </div>
    </Dialog>
)

export default FormConfirmDialog
