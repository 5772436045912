import i18n from '@viterbit/web-app/i18n'
import * as React from 'react'
import Button from 'ui/src/components/Button'

type DeleteActionProps = {
    onClick?: () => void
}

const DeleteAction = ({ onClick }: DeleteActionProps) => (
    <Button size='xs' variant='plain' onClick={onClick} data-testid='button-delete-leave'>
        {i18n.t('leaves:editDialog.buttons.delete')}
    </Button>
)

export default DeleteAction
