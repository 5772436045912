import * as React from 'react'

import { RadioGroupContextProvider, RadioGroupContextValue } from './context'
import { clsxm } from '../../lib/clsxm'

type RadioGroupProps = {
    id?: string
    vertical?: boolean
    disabled?: boolean
    color?: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value?: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange?: (value: any, event: React.ChangeEvent<HTMLInputElement>) => void
    name?: string
    radioGutter?: number
    className?: string
    children: React.ReactNode
}

const RadioGroup = React.forwardRef<HTMLDivElement, RadioGroupProps>(
    (props, ref) => {
        const {
            id,
            children,
            vertical = false,
            disabled,
            color,
            value: valueProp,
            onChange,
            radioGutter = 3,
            className,
        } = props

        const [value, setValue] = React.useState(valueProp)

        const onRadioGroupChange = React.useCallback(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (nextValue: any, e: any) => {
                setValue(nextValue)
                onChange?.(nextValue, e)
            },
            [onChange, setValue]
        )

        const contextValue: RadioGroupContextValue = React.useMemo(
            () => ({
                vertical,
                value: typeof value === 'undefined' ? null : value,
                color,
                disabled,
                radioGutter,
                onChange: onRadioGroupChange,
            }),
            [disabled, onRadioGroupChange, vertical, color, radioGutter, value]
        )

        const radioGroupClass = clsxm(
            'inline-flex',
            vertical && 'flex-col',
            className
        )

        return (
            <RadioGroupContextProvider value={contextValue}>
                <div
                    data-testid='radio-group'
                    id={id}
                    ref={ref}
                    className={radioGroupClass}
                >
                    {children}
                </div>
            </RadioGroupContextProvider>
        )
    }
)
export default RadioGroup
