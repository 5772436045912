/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DataFindEmployeeQueryResponse } from './DataFindEmployeeQueryResponse';
import {
    DataFindEmployeeQueryResponseFromJSON,
    DataFindEmployeeQueryResponseFromJSONTyped,
    DataFindEmployeeQueryResponseToJSON,
} from './DataFindEmployeeQueryResponse';

/**
 * 
 * @export
 * @interface FindTeamQueryResponse
 */
export interface FindTeamQueryResponse {
    /**
     * 
     * @type {string}
     * @memberof FindTeamQueryResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof FindTeamQueryResponse
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof FindTeamQueryResponse
     */
    countMembers?: number;
    /**
     * 
     * @type {string}
     * @memberof FindTeamQueryResponse
     */
    description?: string | null;
    /**
     * 
     * @type {DataFindEmployeeQueryResponse}
     * @memberof FindTeamQueryResponse
     */
    leaders?: DataFindEmployeeQueryResponse;
}

/**
 * Check if a given object implements the FindTeamQueryResponse interface.
 */
export function instanceOfFindTeamQueryResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function FindTeamQueryResponseFromJSON(json: any): FindTeamQueryResponse {
    return FindTeamQueryResponseFromJSONTyped(json, false);
}

export function FindTeamQueryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindTeamQueryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'countMembers': !exists(json, 'countMembers') ? undefined : json['countMembers'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'leaders': !exists(json, 'leaders') ? undefined : DataFindEmployeeQueryResponseFromJSON(json['leaders']),
    };
}

export function FindTeamQueryResponseToJSON(value?: FindTeamQueryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'countMembers': value.countMembers,
        'description': value.description,
        'leaders': DataFindEmployeeQueryResponseToJSON(value.leaders),
    };
}

