
import i18n from '@viterbit/web-app/i18n'
import SettingsLayout from '@viterbit/web-app/shared/layout/SettingsLayout'
import React from 'react'
import { Outlet } from 'react-router-dom'

import CreateLeavePoliciesDialog from './list/CreateLeavePolicies'
import LeavePoliciesListView from './list/LeavePoliciesListView'
import { useLeavePolicy } from './useLeavePolicy'

const LeavePoliciesView = () => {
    const { data } = useLeavePolicy()
    const title = data?.name || i18n.t('navigation:navbar.settings.leaves')
    
    return (
        <SettingsLayout
            title={title}
            left={<LeavePoliciesListView />}
            leftActions={<CreateLeavePoliciesDialog />}
            leftTitle={i18n.t('settingLeaves:leaves')}
        >
            <Outlet />
        </SettingsLayout>
    )
}

export default LeavePoliciesView
