/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react'

import useCallbackRef from './useCallbackRef'

type OnChangeType<T> = (...args: T[]) => void

type UncontrolledArgs<T> = {
    defaultProp: T
    onChange?: OnChangeType<T>
}

const useUncontrolledState = function <T = unknown>({
    defaultProp,
    // eslint-disable-next-line arrow-body-style
    onChange = () => undefined,
}: UncontrolledArgs<T>): ReturnType<T> {
    const uncontrolledState = React.useState<T>(defaultProp)
    const [value] = uncontrolledState
    const prevValueRef = React.useRef(value)
    const handleChange = useCallbackRef(onChange)

    React.useEffect(() => {
        if (prevValueRef.current !== value) {
            handleChange(value)
            prevValueRef.current = value
        }
    }, [value, prevValueRef, handleChange])

    return uncontrolledState
}

type controlledArgs<T = any> = {
    prop?: T
    defaultProp: T
    onChange?: OnChangeType<T>
}

type ReturnType<T> = [T, React.Dispatch<React.SetStateAction<T>>]

const useControllableState = function <T>({
    prop,
    defaultProp,
    // eslint-disable-next-line arrow-body-style
    onChange = () => {
        return
    },
}: controlledArgs<T>): ReturnType<T> {
    const [uncontrolledProp, setUncontrolledProp] = useUncontrolledState<T>({
        defaultProp,
        onChange,
    })
    const isControlled = prop !== undefined
    const value = prop ?? uncontrolledProp
    const handleChange = useCallbackRef(onChange)

    const setValue = React.useCallback(
        (nextValue: any) => {
            if (isControlled) {
                const value =
                    typeof nextValue === 'function'
                        ? nextValue(prop)
                        : nextValue
                if (value !== prop) {
                    handleChange(value)
                }
            } else {
                setUncontrolledProp(nextValue)
            }
        },
        [isControlled, prop, setUncontrolledProp, handleChange]
    )

    return [value, setValue]
}

export default useControllableState
