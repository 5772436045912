// * Wrapping the toast component in a provider for add custom styles
import { toast as baseToast, ToastOptions } from 'react-toastify'

export const toastOptions = baseToast

const defaultOptions = {
    className:
        'rounded-lg shadow-xl dark:shadow-2xl border-gray-100 dark:bg-gray-700 dark:border-transparent',
    hideProgressBar: true,
    closeButton: false,
    autoClose: 3000,
}

const toast = {
    push: (content: React.ReactNode, options?: ToastOptions) => {
        baseToast
        return baseToast(content, { ...defaultOptions, ...options })
    },
    dismiss: (id?: number) => {
        baseToast.dismiss(id)
    },
}

export default toast
