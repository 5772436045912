/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react'

const getType = (node: any): any => node.type || null

const flattenChildren = (children: React.ReactNode): React.ReactNode[] =>
    React.Children.toArray(children).flatMap(
        (node: React.ReactNode) => {
            if (getType(node) === React.Fragment) {
                return flattenChildren(((node as any)?.props as any)?.children)
            }
            return node
        }
    )

export default flattenChildren