import * as React from 'react'

import { TabsContextProvider } from './context'
import useControllableState from '../../hooks/useControllableState'
import { clsxm } from '../../lib/clsxm'

export type TabsVariantType = 'underline' | 'pill'

export type TabsProps = Omit<
    React.HTMLAttributes<HTMLDivElement>,
    'onChange'
> & {
    className?: string
    variant?: TabsVariantType
    defaultValue?: string
    value?: string
    onChange?: (value: string) => void
}

const Tabs = React.forwardRef<HTMLDivElement, TabsProps>(
    (
        {
            value: valueProp,
            onChange,
            defaultValue = '',
            variant = 'underline',
            className,
            ...rest
        },
        ref
    ) => {
        const [value, setValue] = useControllableState<string>({
            prop: valueProp,
            onChange,
            defaultProp: defaultValue,
        })
        const tabsClass = clsxm('tabs', className)

        return (
            <TabsContextProvider
                value={{
                    value: value,
                    onValueChange: setValue,
                    variant,
                }}
            >
                <div className={tabsClass} {...rest} ref={ref} />
            </TabsContextProvider>
        )
    }
)

export default Tabs
