import React from 'react'
import { useMatches, useNavigate } from 'react-router-dom'
import { useMedia } from 'react-use'

export type useBreakpointRedirectionType = {
    matcherPath: string | RegExp
    redirectPath: string
    minWidth?: number
}

const match = (matcherPath: string | RegExp, pathname: string): boolean => {
    if (typeof matcherPath === 'string') {
        return matcherPath === pathname
    }
    return pathname.match(matcherPath) !== null
}

const useBreakpointRedirection = (config: useBreakpointRedirectionType): void => {
    const { matcherPath, minWidth = 0, redirectPath } = config

    const breakpointEnabled = useMedia(`(min-width: ${minWidth}px)`, false)
    const matches = useMatches()
    const navigate = useNavigate()

    React.useEffect(() => {
        const lastMatch = matches[matches.length - 1]

        if (breakpointEnabled && match(matcherPath, lastMatch.pathname)) {
            navigate(redirectPath)
        }
    }, [breakpointEnabled, matches, matcherPath])
}

export default useBreakpointRedirection
