import * as React from 'react'

import SorterComp, { SorterProps } from './Sorter'
import BaseTable, { TableProps } from './Table'
import TableRowSkeleton from './TableRowSkeleton'
import TBodyComp, { TBodyProps } from './TBody'
import TdComp, { TdProps } from './Td'
import TFootComp, { TFootProps } from './TFoot'
import ThComp, { ThProps } from './Th'
import THeadComp, { THeadProps } from './THead'
import TrComp, { TrProps } from './Tr'

type TableType = React.FunctionComponent<TableProps> & {
    Sorter: React.FunctionComponent<SorterProps>
    TBody: React.FunctionComponent<TBodyProps>
    Td: React.FunctionComponent<TdProps>
    TFoot: React.FunctionComponent<TFootProps>
    Th: React.FunctionComponent<ThProps>
    THead: React.FunctionComponent<THeadProps>
    Tr: React.FunctionComponent<TrProps>
}
// ! we should solve this conversion problem https://stackoverflow.com/questions/66965258/typescript-react-forwardrefexoticcomponent-assignment-with-more-specific-htmlele
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Table: TableType = BaseTable as any
Table.Sorter = SorterComp
Table.TBody = TBodyComp
Table.Td = TdComp
Table.TFoot = TFootComp
Table.Th = ThComp
Table.THead = THeadComp
Table.Tr = TrComp

export { Table, TableRowSkeleton }
