import { IconUserExclamation } from '@tabler/icons-react'
import { Employee } from '@viterbit/web-app/dataAccess/model/Employee.model'
import { complaintAssignEmployee } from '@viterbit/web-app/dataAccess/service/complaintService'
import { useComplaintUnassignEmployee } from '@viterbit/web-app/dataAccess/useCase/complaint/useComplaintUnassignEmployee'
import { useEmployeeListWithoutPagination } from '@viterbit/web-app/dataAccess/useCase/employee/useEmployeeListWithoutPagination'
import TeamAssignEmployeeDialog from '@viterbit/web-app/feature/team/assignEmployee/TeamAssignEmployeeDialog'
import TeamUnassignEmployeeDialog from '@viterbit/web-app/feature/team/unassign/TeamUnassignEmployeeDialog'
import i18n from '@viterbit/web-app/i18n'
import AsideCardInfo from '@viterbit/web-app/shared/display/AsideCardInfo'
import EmptyState from '@viterbit/web-app/shared/display/EmptyState/EmptyState'
import ButtonCreate from '@viterbit/web-app/shared/form/ButtonCreate'
import React from 'react'
import Dialog from 'ui/src/components/Dialog'
import ListSkeleton from 'ui/src/components/List/ListSkeleton'

import ComplaintsManagerList from './ComplaintsManagerList'

type SettingsComplaintsFormAssignState = {
    open: boolean
    action: 'ASSIGN' | 'UNASSIGN'
    employees: Employee[]
}

const SettingsComplaintsForm = () => {
    const [assignData, setAssignData] = React.useState<SettingsComplaintsFormAssignState>({
        open: false,
        action: 'ASSIGN',
        employees: [],
    })

    const {data, isLoading} = useEmployeeListWithoutPagination({
        query: {
            complaintManager: true, 
            pageSize: 9999,
        }
    })

    const closeModal = () => setAssignData({ ...assignData, open: false })

    const unassign = useComplaintUnassignEmployee({
        onSuccess: closeModal,
    })

    return (
        <>
            <AsideCardInfo
                title={i18n.t('settingsComplaints:assignEmployees.title')}
                description={i18n.t('settingsComplaints:assignEmployees.description')}
                actions={
                    <ButtonCreate
                        data-testid='assign-employees'
                        title={i18n.t('settingsComplaints:assignEmployees.title')}
                        onClick={() => setAssignData({ ...assignData, open: true, action: 'ASSIGN' })} collapsed
                    />
                }
            >
                {data && (
                    <ComplaintsManagerList
                        employees={data}
                        onDeleteItem={(employee) => setAssignData({ open: true, action: 'UNASSIGN', employees: [employee] })}
                    />
                )}

                {isLoading && (
                    <ListSkeleton />
                )}

                {!isLoading && !data?.length && (
                    <EmptyState
                        title={i18n.t('settingsComplaints:assignEmployees.emptyListTitle')}
                        description={i18n.t('settingsComplaints:assignEmployees.emptyListDescription')}
                        image={<IconUserExclamation size={80} stroke={1} />}
                        className='p-12 border rounded'
                    />
                )}
            </AsideCardInfo>

            <TeamUnassignEmployeeDialog
                onCancel={closeModal}
                onSubmit={() => unassign.mutate({
                    unassignComplaintManagerCommand: {
                        employeeId: assignData.employees[0].id
                    }
                })}
                title={i18n.t('settingsComplaints:unassignEmployees.title')}
                open={assignData.open && assignData.action === 'UNASSIGN'}
                isLoading={unassign.isLoading}
                error={unassign.error}
            />
            
            <Dialog
                open={assignData.open && assignData.action === 'ASSIGN'}
                onClose={closeModal}
                title={i18n.t('settingsComplaints:assignEmployees.title')}
            >
                <TeamAssignEmployeeDialog
                    onSubmit={(data) => complaintAssignEmployee({
                        assignComplaintManagerCommand: {
                            employeeIds: data.employees.map(x => x.id)
                        }
                    })}
                    complaintManager={false}
                    onCancel={closeModal}
                    onConfirm={closeModal}
                />
            </Dialog>
            
        </>
    )
}

export default SettingsComplaintsForm
