import SimpleCard from '@viterbit/web-app/shared/display/SimpleCard'
import { clsxm } from '@viterbit/web-app/shared/utils/clsxm'
import * as React from 'react'

import ClusterProvider from './LeavesClusters/ClusterProvider'


type SideViewProps = {
    topSection?: React.ReactNode
    topSectionTitle?: string
    tabSection?: React.ReactNode
    bottomSection?: React.ReactNode
    className?: string
}
const SideView = ({
    topSection,
    topSectionTitle,
    bottomSection,
    tabSection,
    className,
}: SideViewProps) => (
    <div className={clsxm('flex flex-col w-full gap-y-4', className)}>
        <SimpleCard
            title={topSectionTitle || 'Pending for approval'}
            className='max-h-48'
        >
            {topSection}
        </SimpleCard>
        <ClusterProvider>
            <SimpleCard>
                {tabSection}
                <div className='flex w-full h-full overflow-y-auto'>
                    <div className='flex flex-col items-start justify-start w-full gap-y-4 max-h-[calc(100vh-200px)] truncate overflow-y-auto h-full'>
                        {bottomSection}
                    </div>
                </div>
            </SimpleCard>
        </ClusterProvider>
    </div>
)

export default SideView
