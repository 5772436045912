/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionIcon, Badge, Loader } from '@mantine/core'
import { IconX } from '@tabler/icons-react'
import i18n from '@viterbit/web-app/i18n'
import * as React from 'react'
import { IFile } from 'ui/src/components/Upload/sharedTypes'
import clsxm from 'ui/src/lib'

type ChatBarFilesProps = {
    children?: React.ReactNode
    className?: string
    files: IFile[]
    onDelete?: (file: IFile) => void
}

const styles = {
    root: 'flex gap-2 flex-wrap'
}

const ChatBarFilesDelete = (props) => (
    <ActionIcon size="xs" color={props.color} radius="xl" variant="transparent" onClick={props.onClick}>
        <IconX />
    </ActionIcon>
)

const ChatBarFiles = (props: ChatBarFilesProps) => {
    const { files, className, onDelete } = props

    if (!files.length) return null

    return (
        <div className={clsxm(styles.root, className)}>
            <div className='font-semibold'>{i18n.t('Attachment')}</div>
            {files.map((file) => {
                const status = file._meta!.status
                const color = {
                    pending: 'gray',
                    ok: 'blue',
                    error: 'red',
                }[status]

                return (
                    <Badge
                        key={file.id}
                        color={color}
                        variant="light"
                        rightSection={{
                            pending: <Loader size={10} color='grey'/>,
                            ok: <ChatBarFilesDelete color={color} onClick={() => onDelete?.(file)} />,
                            error: <ChatBarFilesDelete color={color} onClick={() => onDelete?.(file)} />,
                        }[status]}
                    >
                        <a href={file.url} target="_blank" rel="noreferrer" data-testid="chat-file">
                            {file.name}
                        </a>
                    </Badge>
                )

            })}
        </div>
    )
}

export default ChatBarFiles
