import React from 'react'

type LayoutContextType = {
    toggleCollapsed: () => void
    isCollapsed: boolean
} | null

const LayoutContext = React.createContext<LayoutContextType>(null)

export const LayoutProvider = LayoutContext.Provider

export default LayoutContext
