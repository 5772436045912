/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { IMapper } from './__common'
import { CalendarResponse } from '../dto/calendar'
import { Calendar } from '../model/Calendar.model'

const calendarMapper: IMapper<Calendar, CalendarResponse> = {
    toModel: (dto) => ({
        year: dto.year,
        publicHolidays: dto.publicHolidays || []
    }),
    toDto: () => {
        throw new Error('Model to DTO not implemented')
    },
}

export default calendarMapper
