/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateOrganizationBillingInfoCommand
 */
export interface UpdateOrganizationBillingInfoCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationBillingInfoCommand
     */
    billingName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationBillingInfoCommand
     */
    billingEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationBillingInfoCommand
     */
    billingPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationBillingInfoCommand
     */
    billingVatNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationBillingInfoCommand
     */
    billingAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationBillingInfoCommand
     */
    billingCountry?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationBillingInfoCommand
     */
    billingCity?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationBillingInfoCommand
     */
    billingPostalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationBillingInfoCommand
     */
    billingState?: string;
}

/**
 * Check if a given object implements the UpdateOrganizationBillingInfoCommand interface.
 */
export function instanceOfUpdateOrganizationBillingInfoCommand(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateOrganizationBillingInfoCommandFromJSON(json: any): UpdateOrganizationBillingInfoCommand {
    return UpdateOrganizationBillingInfoCommandFromJSONTyped(json, false);
}

export function UpdateOrganizationBillingInfoCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateOrganizationBillingInfoCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'billingName': !exists(json, 'billingName') ? undefined : json['billingName'],
        'billingEmail': !exists(json, 'billingEmail') ? undefined : json['billingEmail'],
        'billingPhone': !exists(json, 'billingPhone') ? undefined : json['billingPhone'],
        'billingVatNumber': !exists(json, 'billingVatNumber') ? undefined : json['billingVatNumber'],
        'billingAddress': !exists(json, 'billingAddress') ? undefined : json['billingAddress'],
        'billingCountry': !exists(json, 'billingCountry') ? undefined : json['billingCountry'],
        'billingCity': !exists(json, 'billingCity') ? undefined : json['billingCity'],
        'billingPostalCode': !exists(json, 'billingPostalCode') ? undefined : json['billingPostalCode'],
        'billingState': !exists(json, 'billingState') ? undefined : json['billingState'],
    };
}

export function UpdateOrganizationBillingInfoCommandToJSON(value?: UpdateOrganizationBillingInfoCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'billingName': value.billingName,
        'billingEmail': value.billingEmail,
        'billingPhone': value.billingPhone,
        'billingVatNumber': value.billingVatNumber,
        'billingAddress': value.billingAddress,
        'billingCountry': value.billingCountry,
        'billingCity': value.billingCity,
        'billingPostalCode': value.billingPostalCode,
        'billingState': value.billingState,
    };
}

