/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import dayjs from 'dayjs'
import { FindTimeTrackingQueryResponse } from 'viterbit-api-sdk'

import { IMapper } from './__common'
import { TimeTracking } from '../model/TimeTracking.model'

const timeTrackingMapper: IMapper<TimeTracking, FindTimeTrackingQueryResponse> = {
    toModel: (dto) => ({
        id: dto.id!,
        endDate: dayjs(dto.endDate!).toDate(),
        startDate: dayjs(dto.startDate!).toDate(),
    }),
    toDto: () => {
        throw new Error('Not implemented')
    },
}

export default timeTrackingMapper
