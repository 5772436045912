import * as React from 'react';
import clsxm from 'ui/src/lib'
import { useDisclosure } from '@mantine/hooks';
import { Modal, Button, Group } from '@mantine/core';
import Dialog from 'ui/src/components/Dialog'
import i18n from '@viterbit/web-app/i18n';
import { useComplaintOrganization } from '../useComplaintOrganization';
import ButtonSkeleton from 'ui/src/components/Button/ButtonSkeleton';

type ComplaintTermsDialogProps = {
    children?: React.ReactNode
    className?: string
}

const styles = {
    root: ''
}

const ComplaintTermsDialog = (props: ComplaintTermsDialogProps) => {
    const { children, className } = props;

    const [open, setOpen] = React.useState<boolean>(false)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const { data, isInitialLoading } = useComplaintOrganization()

    return (
        <>
            {!isInitialLoading && (
                <Dialog
                    open={open}
                    onClose={() => setOpen(false)}
                    size='xl'
                >
                    <div dangerouslySetInnerHTML={{ __html: data?.terms || '' }} />
                </Dialog>
            )}

            <Button mt={20} variant='white' color='dark' disabled={!data?.terms} loading={isInitialLoading} onClick={() => setOpen(true)}>
                {i18n.t('complaint:terms.openButton')}
            </Button>
        </>
    );
}

export default ComplaintTermsDialog;
