/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAssignBulkPendingFile } from '@viterbit/web-app/dataAccess/useCase/pendingFile/useAssignBulkPendingFile'
import i18n from '@viterbit/web-app/i18n'
import SubmitButton from '@viterbit/web-app/shared/form/SubmitButton'
import * as React from 'react'
import clsxm from 'ui/src/lib'

type DocumentBulkUploadFormProps = {
    children?: React.ReactNode
    assigned: number
    total: number
    onSubmitCallBack?: () => void
    type: string
}

const DocumentBulkUploadForm = (props: DocumentBulkUploadFormProps) => {
    const { onSubmitCallBack, children, assigned, total, type } = props
    const [data] = React.useState({assigned, notAssigned: total - assigned, total})
    const {isLoading, mutate, isSuccess} = useAssignBulkPendingFile({
        onSuccess: onSubmitCallBack
    })

    const handleSubmit = () => mutate({assignBulkPendingFileCommand: {
        type: type as any
    }})

    return (
        <div className='flex flex-col gap-y-3'>
            <div className={clsxm('border rounded w-full min-h-[10rem] p-3')}>
                <div className='flex flex-col gap-y-2'>
                    <span className='font-bold'>
                        {i18n.t('employees:bulkUpload.bulkAssign.modal.assignAlert.title')}
                    </span>
                    <span>
                        {i18n.t('employees:bulkUpload.bulkAssign.modal.assignAlert.description')}
                    </span>
                    <div className='mt-5'>
                        <span className='px-3 py-1 rounded-full bg-primary-200'>{i18n.t('employees:bulkUpload.bulkAssign.modal.assignAlert.assignedTagText', {count: data.assigned})}</span>
                    </div>
                    <div className='mt-2 mb-3'>
                        <span className='px-3 py-1 rounded-full bg-danger-200'>
                            {i18n.t('employees:bulkUpload.bulkAssign.modal.assignAlert.notAssignedTagText', {count: data.notAssigned})}
                        </span>
                    </div>
                </div>
                
            </div>
            {children}

            {/* <Button variant='default' onClick={onCancel}>{cancelText}</Button> */}
            <SubmitButton
                variant='filled'
                disabled={isLoading}
                success={isSuccess}
                loading={isLoading}
                type='button'
                {...{onClick: handleSubmit}}
            >
                {i18n.t('employees:bulkUpload.bulkAssign.modal.confirmButton', {count: data.assigned})}
            </SubmitButton>
        </div>
    )
}

export default DocumentBulkUploadForm
