/* eslint-disable @typescript-eslint/no-non-null-assertion */
import i18n from '@viterbit/web-app/i18n'
import { useCurrentEmployeeId } from '@viterbit/web-app/shared/employee/useCurrentEmployeeId'
import MultiColumnLayout from '@viterbit/web-app/shared/layout/MultiColumnLayout'
import { useParentHref } from '@viterbit/web-app/shared/navigation/useParentHref'
import IsGranted from '@viterbit/web-app/shared/permission/IsGranted'
import * as React from 'react'
import { useParams } from 'react-router-dom'
import FileList from 'ui/src/components/Upload/FileList'
import clsxm from 'ui/src/lib'
import { UploadFileTypeEnum } from 'viterbit-api-sdk'

import DocumentEmployeeDetailForm from './DocumentEmployeeDetailForm'
import DocumentEmployeeDetailItems from './DocumentEmployeeDetailItems'
import DocumentEmployeeDetailSection from './DocumentEmployeeDetailSection'
import DocumentEmployeeNavigationList from './navigation/DocumentEmployeeNavigationList'
import { FileTypeAction, getActionKeyByDocumentType } from '../DocumentEmloyeeShared'

type DocumentEmployeeDetailParams = {
    type: UploadFileTypeEnum
}

const DocumentEmployeeDetailView = () => {
    const { type = 'payroll' } = useParams<'type'>() as DocumentEmployeeDetailParams
    const parent = useParentHref('/documents')
    const employeeId = useCurrentEmployeeId()!
    const action = getActionKeyByDocumentType(type, FileTypeAction.UPLOAD)

    return (
        <MultiColumnLayout
            left={(
                <DocumentEmployeeNavigationList
                    currentPath={parent}
                    employeeId={employeeId}/>
            )}
        >
            <DocumentEmployeeDetailSection
                employeeId={employeeId}
                type={type}
                title={i18n.t(`personalArea:documents.type.${type}`)}
            >
                <IsGranted action={action} id={employeeId!}>
                    <DocumentEmployeeDetailForm employeeId={employeeId} type={type} className={clsxm('p-4 -mb-8' )} />
                </IsGranted>
        
                <FileList className={clsxm('p-4')} columns={2}>
                    <DocumentEmployeeDetailItems className='min-h-[400px] p-4' employeeId={employeeId} type={type}  />
                </FileList>
            </DocumentEmployeeDetailSection>
        </MultiColumnLayout>
    )
}

export default DocumentEmployeeDetailView
