import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import React from 'react'
import ReactDOM from 'react-dom/client'

import '@viterbit/web-app/style/globals.css'

import GlobalProviders from './shared/GlobalProviders'

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    enabled: import.meta.env.PROD
})

const availableHosts = [
    'workspace.viterbit.com',
]

if (import.meta.env.PROD && availableHosts.includes(window.location.host)) {
    const script = document.createElement('script')
    script.id = 'hs-script-loader'
    script.src = '//js-eu1.hs-scripts.com/26294116.js'
    script.defer = true
    script.async = true
    document.querySelector('body')?.appendChild(script)
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
        <GlobalProviders/>
    </React.StrictMode>,
)
