import { useQuery } from '@tanstack/react-query'
import { Api } from 'types/Api'

import { ORGANIZATION_QUERY_KEY } from '../../cacheKeys'
import { organizationPublicDetail } from '../../service/organizationService'

type OrganizationPublicDetailConfig = {
    enabled?: boolean
    query: Parameters<typeof organizationPublicDetail>[0]
}

export const useOrganizationPublicDetail = ({ enabled, query }: OrganizationPublicDetailConfig) =>
    useQuery<Awaited<ReturnType<typeof organizationPublicDetail>>, Api.ResponseError>({
        queryFn: () => organizationPublicDetail(query), 
        queryKey: [ORGANIZATION_QUERY_KEY, 'detail'],
        enabled,
    })

