/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-array-index-key */

import { FileModel } from '@viterbit/web-app/dataAccess/model/File.model'
import { usePendingFileListPaginated } from '@viterbit/web-app/dataAccess/useCase/pendingFile/usePendingFileListPaginated'
import { useOnScreenElement } from '@viterbit/web-app/shared/behaviour/useOnScreenElement'
import { useParentHref } from '@viterbit/web-app/shared/navigation/useParentHref'
import * as React from 'react'
import FileItemSkeleton from 'ui/src/components/Upload/FileItemSkeleton'

import DocumentBulkUploadDetailItem from './DocumentBulkUploadDetailItem'
import { PendingDocumentProps } from '../BulkUploadShared'
import DocumentBulkUploadDeleteDialog from '../delete/DocumentBulkUploadDeleteDialog'

type DocumentBulkUploadListPaginatedProps = {
    className?: string
} & PendingDocumentProps

const DocumentBulkUploadListPaginated = ({
    type,
}: DocumentBulkUploadListPaginatedProps) => {
    const parentHref = useParentHref('/documents')
    const [deleteItem, setDeleteItem] = React.useState<FileModel | null>(null)

    const { data, isFetchingNextPage, fetchNextPage, hasNextPage } = usePendingFileListPaginated({
        query: {
            type: type as any,
        },
        enabled: !!type
    })

    const { ref } = useOnScreenElement({
        onVisible: () => {
            if (hasNextPage) {
                fetchNextPage()
            }
        }
    })

    const lastPage = data?.pages[data.pages.length - 1] // for skeleton items
    const skeletonItems = Array.from({ length: lastPage?.meta.nextPageTotal || 0 }, (_, index) => index)

    return (
        <>
            {data?.pages.flatMap((page) => (
                page.data.map((file) => (
                    <DocumentBulkUploadDetailItem
                        key={file.id}
                        file={file}
                        onDelete={() => {
                            setDeleteItem(file as any)
                        }}
                        parentHref={parentHref}
                    />
                ))
            ))}

            {isFetchingNextPage && skeletonItems.map((_, index) => (<FileItemSkeleton key={index} />))}
            <div ref={ref} hidden={isFetchingNextPage} className="h-2" />
            {
                deleteItem && (
                    <DocumentBulkUploadDeleteDialog
                        file={deleteItem}
                        open={!!deleteItem}
                        onSubmitCallback={() => setDeleteItem(null) }
                        onCancelCallback={() => setDeleteItem(null)}
                    />
                )}
        </>
    )
}

export default DocumentBulkUploadListPaginated
