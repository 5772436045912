import i18n from '@viterbit/web-app/i18n'

type FormatPriceConfig = {
    currency?: string
    trim?: boolean
    amount: number
    locale?: string
}

const formatPrice = (config: FormatPriceConfig): string => {
    const { currency, trim = true, amount, locale = i18n.resolvedLanguage } = config

    let formattedPrice = new Intl.NumberFormat(
        locale,
        currency ? {
            style: 'currency',
            currency,
        }: undefined
    ).format(amount)

    if (trim) {
        formattedPrice = formattedPrice.replace(/\D00(?=\D*$)/, '')
    }

    const removedSpaces = formattedPrice.replace(/\s/g, '')

    return removedSpaces
}

export default formatPrice