import { fileSignedUrl } from '@viterbit/web-app/dataAccess/service/filesService'
import downloadFile from '@viterbit/web-app/shared/utils/fileDownload'
import * as React from 'react'
import { IFile } from 'ui/src/components/Upload/sharedTypes'

type FileDownloadConfig = {
    getFileUrl: (params: { type: string, id: string }) => Promise<{ url: string }>
}

export type FileDownloadReturn = {
    open: (file: IFile) => void
    loading: boolean
}

const defaultConfig = { getFileUrl: fileSignedUrl }

// open image on click
const useFileDownload = (config: FileDownloadConfig = defaultConfig): FileDownloadReturn => {
    const [loading, setLoading] = React.useState(false)

    const open = React.useCallback(async (file: IFile) => {
        setLoading(true)
        const signedUrl = await config.getFileUrl({type: file.kind, id: file.id})
        downloadFile(signedUrl.url, file.name)
        setLoading(false)
    }, [])

    return {
        open,
        loading
    }
}

export default useFileDownload