/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FindFileQueryResponse } from './FindFileQueryResponse';
import {
    FindFileQueryResponseFromJSON,
    FindFileQueryResponseFromJSONTyped,
    FindFileQueryResponseToJSON,
} from './FindFileQueryResponse';

/**
 * 
 * @export
 * @interface PublicEmployee
 */
export interface PublicEmployee {
    /**
     * Employee id
     * @type {string}
     * @memberof PublicEmployee
     */
    id: string;
    /**
     * Employee email
     * @type {string}
     * @memberof PublicEmployee
     */
    email: string;
    /**
     * Employee name
     * @type {string}
     * @memberof PublicEmployee
     */
    name: string;
    /**
     * Employee surname
     * @type {string}
     * @memberof PublicEmployee
     */
    surname?: string | null;
    /**
     * 
     * @type {FindFileQueryResponse}
     * @memberof PublicEmployee
     */
    avatar?: FindFileQueryResponse;
}

/**
 * Check if a given object implements the PublicEmployee interface.
 */
export function instanceOfPublicEmployee(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function PublicEmployeeFromJSON(json: any): PublicEmployee {
    return PublicEmployeeFromJSONTyped(json, false);
}

export function PublicEmployeeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PublicEmployee {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'email': json['email'],
        'name': json['name'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
        'avatar': !exists(json, 'avatar') ? undefined : FindFileQueryResponseFromJSON(json['avatar']),
    };
}

export function PublicEmployeeToJSON(value?: PublicEmployee | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'email': value.email,
        'name': value.name,
        'surname': value.surname,
        'avatar': FindFileQueryResponseToJSON(value.avatar),
    };
}

