
import { Company } from '@viterbit/web-app/dataAccess/model/Company.model'
import { companyList } from '@viterbit/web-app/dataAccess/service/companyService'
import { GroupBase } from 'react-select'
import {
    FormSelectAsyncPaginate,
    FormSelectAsyncPaginateProps,
} from 'ui/src/components/Form'
import { FormBaseProps } from 'ui/src/components/Form/shared'

import { useNotification } from '../feedback/useNotification'

export type FormSelectCompanyProps<IsMulti extends boolean = boolean> = FormBaseProps &
    Omit<
        FormSelectAsyncPaginateProps<
            Company,
            IsMulti,
            GroupBase<Company>,
            { page: number }
        >,
        'loadOptions'
    >

type LoadOptions = FormSelectAsyncPaginateProps<
    Company,
    false,
    GroupBase<Company>,
    { page: number }
>['loadOptions']

const FormSelectCompany = <IsMulti extends boolean>({
    name,
    control,
    cacheOptions = true,
    isSearchable = false,
    ...rest
}: FormSelectCompanyProps<IsMulti>) => {
    const notification = useNotification()

    const loadOptions: LoadOptions = async (search, loadedOptions, _meta) =>
        companyList({ page: _meta?.page })
            .then(({ data, meta }) => ({
                options: data,
                hasMore: meta.hasMore,
                additional: {
                    page: meta.page + 1,
                },
            }))
            .catch((err) => {
                notification.push(
                    'danger',
                    'Fetching error',
                    err.errorCode ?? 'Error at fetch companies'
                )
                return {
                    options: [],
                    hasMore: false,
                    additional: {
                        page: _meta?.page ?? 1,
                    },
                }
            })
    return (
        <FormSelectAsyncPaginate
            name={name}
            control={control}
            cacheOptions={cacheOptions}
            loadOptions={loadOptions}
            isSearchable={isSearchable}
            getOptionLabel={(x) => x.name ?? ''}
            getOptionValue={(x) => x.id}
            loadOptionsOnMenuOpen
            additional={{
                page: 1,
            }}
            {...rest}
        />
    )
}

export default FormSelectCompany
