/* eslint-disable @typescript-eslint/no-explicit-any */
import { IMapper } from './__common'
import { ResourcesPaginatedResponse } from '../dto'

function fromResponse<ModelType, DtoType>(
    response: Promise<ResourcesPaginatedResponse<DtoType>>,
    mapper: IMapper<ModelType, DtoType>['toModel']
): Promise<ResourcesPaginatedResponse<ModelType>> {
    return response.then((response) => {
        const data = Array.isArray(response.data) ? response.data.map(mapper) : mapper(response.data)
        
        return {
            data,
            meta: response.meta
        } as any
    })
}

export default fromResponse