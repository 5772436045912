/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FindLeavePolicyQueryResponse } from './FindLeavePolicyQueryResponse';
import {
    FindLeavePolicyQueryResponseFromJSON,
    FindLeavePolicyQueryResponseFromJSONTyped,
    FindLeavePolicyQueryResponseToJSON,
} from './FindLeavePolicyQueryResponse';
import type { ListMeta } from './ListMeta';
import {
    ListMetaFromJSON,
    ListMetaFromJSONTyped,
    ListMetaToJSON,
} from './ListMeta';

/**
 * 
 * @export
 * @interface ListLeavePolicyQueryResponse
 */
export interface ListLeavePolicyQueryResponse {
    /**
     * 
     * @type {ListMeta}
     * @memberof ListLeavePolicyQueryResponse
     */
    meta: ListMeta;
    /**
     * 
     * @type {Array<FindLeavePolicyQueryResponse>}
     * @memberof ListLeavePolicyQueryResponse
     */
    data: Array<FindLeavePolicyQueryResponse>;
}

/**
 * Check if a given object implements the ListLeavePolicyQueryResponse interface.
 */
export function instanceOfListLeavePolicyQueryResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "meta" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function ListLeavePolicyQueryResponseFromJSON(json: any): ListLeavePolicyQueryResponse {
    return ListLeavePolicyQueryResponseFromJSONTyped(json, false);
}

export function ListLeavePolicyQueryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListLeavePolicyQueryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'meta': ListMetaFromJSON(json['meta']),
        'data': ((json['data'] as Array<any>).map(FindLeavePolicyQueryResponseFromJSON)),
    };
}

export function ListLeavePolicyQueryResponseToJSON(value?: ListLeavePolicyQueryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'meta': ListMetaToJSON(value.meta),
        'data': ((value.data as Array<any>).map(FindLeavePolicyQueryResponseToJSON)),
    };
}

