import { ActionIcon, Button, Input, Loader, Tooltip } from '@mantine/core'
import { IconDownload } from '@tabler/icons-react'
import { FileModel } from '@viterbit/web-app/dataAccess/model/File.model'
import useFileDownload from '@viterbit/web-app/dataAccess/useCase/file/useFileDownload'
import i18n from '@viterbit/web-app/i18n'
import * as React from 'react'
import clsxm from 'ui/src/lib'

import FormUploadDialog from './FormUploadDialog'

type FormInputUploadDialogProps = {
    children?: React.ReactNode
    className?: string
    file?: FileModel
    label: string
    placeholder?: string
} & Omit<React.ComponentProps<typeof FormUploadDialog>, 'onClose' | 'open'>

const styles = {
    root: 'mb-4'
}

const FormInputUploadDialog = (props: FormInputUploadDialogProps) => {
    const { file, label, children, className, placeholder, ...rest } = props

    const [editMode, setEditMode] = React.useState(false)

    const fileName = file?.name || ''

    const download = useFileDownload()

    return (
        <>
            <div className={clsxm(styles.root, className)}>
                {label && (
                    <label htmlFor="file" className='block mb-2 font-semibold'>
                        {label}
                    </label>
                )}

                <Input
                    data-testid='input-upload-field'
                    value={fileName}
                    placeholder={placeholder}
                    icon={file && (
                        <Tooltip label={i18n.t('buttons.download')}>
                            <ActionIcon data-testid='input-upload-download'id='file' onClick={() => download.open(file)}>
                                {download.loading ? <Loader size={18} /> : <IconDownload size={22}/>}
                            </ActionIcon>
                        </Tooltip>
                    )}
                    rightSection={(
                        <Button
                            data-testid='input-upload-change'
                            variant="white"
                            sx={{ background: 'transparent'  }}
                            onClick={() => setEditMode(true)}
                        > 
                            {fileName ? i18n.t('buttons.change') : i18n.t('buttons.upload')} 
                        </Button>
                    )}
                    rightSectionWidth={88}
                    iconWidth={44}
                    readOnly
                    styles={(theme) => ({
                        input: {
                            pointerEvents: 'none',
                        },
                        icon: {
                            pointerEvents: 'all'
                        }
                    })}
                />
            
                {children}
            </div>

            <FormUploadDialog
                {...rest}
                open={editMode}
                onClose={() => setEditMode(false)}
            />

        </>
    )
}

export default FormInputUploadDialog
