import clsxm from 'ui/src/lib'

type Props = {
    className?: string
}

const PdfViewerSkeleton = ({className}: Props) => (
    <div className={clsxm('w-full h-full animate-pulse', className)}>
        <div className="w-full h-full bg-gray-400"></div>
    </div>
)

export default PdfViewerSkeleton