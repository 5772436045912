/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WorkSchedulePeriod2 } from './WorkSchedulePeriod2';
import {
    WorkSchedulePeriod2FromJSON,
    WorkSchedulePeriod2FromJSONTyped,
    WorkSchedulePeriod2ToJSON,
} from './WorkSchedulePeriod2';

/**
 * 
 * @export
 * @interface UpdateWorkScheduleCommand
 */
export interface UpdateWorkScheduleCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateWorkScheduleCommand
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateWorkScheduleCommand
     */
    type?: UpdateWorkScheduleCommandTypeEnum;
    /**
     * 
     * @type {Array<WorkSchedulePeriod2>}
     * @memberof UpdateWorkScheduleCommand
     */
    periods?: Array<WorkSchedulePeriod2> | null;
    /**
     * 
     * @type {WorkSchedulePeriod2}
     * @memberof UpdateWorkScheduleCommand
     */
    mainPeriod?: WorkSchedulePeriod2;
}


/**
 * @export
 */
export const UpdateWorkScheduleCommandTypeEnum = {
    Fixed: 'FIXED',
    Flexible: 'FLEXIBLE'
} as const;
export type UpdateWorkScheduleCommandTypeEnum = typeof UpdateWorkScheduleCommandTypeEnum[keyof typeof UpdateWorkScheduleCommandTypeEnum];


/**
 * Check if a given object implements the UpdateWorkScheduleCommand interface.
 */
export function instanceOfUpdateWorkScheduleCommand(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateWorkScheduleCommandFromJSON(json: any): UpdateWorkScheduleCommand {
    return UpdateWorkScheduleCommandFromJSONTyped(json, false);
}

export function UpdateWorkScheduleCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateWorkScheduleCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'periods': !exists(json, 'periods') ? undefined : (json['periods'] === null ? null : (json['periods'] as Array<any>).map(WorkSchedulePeriod2FromJSON)),
        'mainPeriod': !exists(json, 'mainPeriod') ? undefined : WorkSchedulePeriod2FromJSON(json['mainPeriod']),
    };
}

export function UpdateWorkScheduleCommandToJSON(value?: UpdateWorkScheduleCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'type': value.type,
        'periods': value.periods === undefined ? undefined : (value.periods === null ? null : (value.periods as Array<any>).map(WorkSchedulePeriod2ToJSON)),
        'mainPeriod': WorkSchedulePeriod2ToJSON(value.mainPeriod),
    };
}

