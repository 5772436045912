/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColorInput, ColorInputProps } from '@mantine/core'
import { Controller } from 'react-hook-form'

import ControlledFormControl from './ControlledFormControl'
import { FormControlBaseProps } from './FormControl'
import { FormBaseProps } from './shared'
import { clsxm } from '../../lib/clsxm'

type transformProps = {
    input: (value: any) => any
    output: (value: any) => any
}

export type FormInputColorProps = FormControlBaseProps & ColorInputProps & FormBaseProps & { transform?: transformProps, aside?: React.ReactNode }

const FormInputColor = (props: FormInputColorProps) => {
    const {
        control,
        extra,
        asterisk,
        label,
        name,
        className,
        transform,
        rules,
        aside,
        ...rest
    } = props

    return (
        <ControlledFormControl
            control={control}
            extra={extra}
            asterisk={asterisk}
            label={label}
            className={className}
            name={name}
        >
            <Controller
                control={control}
                name={name}
                rules={rules}
                render={({ field }) => (
                    <>
                        <ColorInput
                            className={clsxm('z-10')}
                            {...field}
                            {...rest}
                            {...(transform && {
                                onChange: (e) => field.onChange(transform.output(e)),
                                value: transform.input(field.value)
                            })}
                        />

                        {aside}
                    </>
                )}
            />
        </ControlledFormControl>
    )
}

export default FormInputColor
