/* eslint-disable @typescript-eslint/no-explicit-any */

import { Alert } from '@mantine/core'
import { IconAlertCircle } from '@tabler/icons-react'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { PermissionAction } from '@viterbit/web-app/dataAccess/model/PermissionAction.model'
import { useLeaveTimelineListPaginated } from '@viterbit/web-app/dataAccess/useCase/leave/useLeaveTimelineListPaginated'
import i18n from '@viterbit/web-app/i18n'
import useHasPermission from '@viterbit/web-app/shared/permission/useHasPermissions'
import { useEffect } from 'react'
import * as React from 'react'
import { useInView } from 'react-intersection-observer'

import LeaveTeamRowList from './LeaveTeamRowList'
import LeaveTeamRowListSkeleton from './LeaveTeamRowListSkeleton'
import { LeaveTeamCalendar, LeaveTeamDay,LeaveTeamEmployee } from './LeaveTeamShared'

type LeaveTeamPaginatedProps = LeaveTeamCalendar & {
    daysLoading: LeaveTeamDay[]
    onItemClicked: (day: LeaveTeamDay, employee: LeaveTeamEmployee) => void
}

const LeaveTeamPaginated = (props: LeaveTeamPaginatedProps) => {
    const { searchParams, daysLoading, onItemClicked } = props

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const { data, hasNextPage, fetchNextPage, isFetchingNextPage, isInitialLoading, isFetching, isFetched } = useLeaveTimelineListPaginated({
        query: searchParams,
        keepPreviousData: true,
    })

    const { ref, inView } = useInView()

    useEffect(() => {
        if (inView && hasNextPage && !isFetching && !isInitialLoading) {
            fetchNextPage()
        }
    }, [inView, hasNextPage, isFetching, isInitialLoading])

    const lastPage = data?.pages[data.pages.length - 1]
    const employeeIds = React.useMemo(() => data?.pages.map((page) => page.data.map((item) => item.id)).flat(), [data?.pages])
    
    const { hasPermission } = useHasPermission({
        [PermissionAction.CORE_HR_LEAVE_CREATE]: employeeIds,
    })
    
    const flatEmployees = React.useMemo(() => data?.pages.map((page) => page.data).flat().map(e => {
        const canCreate = hasPermission(PermissionAction.CORE_HR_LEAVE_CREATE, e.id)

        return {
            ...e,
            canEdit: canCreate,
            canCreate,
            action: canCreate,
        }
    }), [data?.pages, hasPermission])

    if (!data?.pages.length) {
        return <LeaveTeamRowListSkeleton days={daysLoading} />
    }

    return (
        <>
            <LeaveTeamRowList
                query={searchParams}
                items={flatEmployees}
                onItemClicked={onItemClicked}/>

            {isFetchingNextPage && <LeaveTeamRowListSkeleton days={daysLoading} count={lastPage?.meta.nextPageTotal}/>}

            {isFetched && !flatEmployees?.length && (
                <Alert
                    color="grey.3"
                    variant="outline"
                    icon={<IconAlertCircle color='grey'/>}
                    sx={{
                        position: 'absolute',
                        top: 260,
                        width: '100%',
                        zIndex: 10,
                    }}>
                    {i18n.t('employees:common.noMatchingEmployees')}
                </Alert>
            )}
            
            <div ref={ref} hidden={isFetchingNextPage} />
            <ReactQueryDevtools initialIsOpen />
        </>
    )
}

export default LeaveTeamPaginated
