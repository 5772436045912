/* eslint-disable @typescript-eslint/no-explicit-any */
import i18n from '@viterbit/web-app/i18n'
import ApiErrorMessage from '@viterbit/web-app/shared/feedback/ApiErrorMessage'
import FormDeleteDialog from '@viterbit/web-app/shared/form/FormDeleteDialog'
import * as React from 'react'

type TeamUnassignEmployeeDialogProps = {
    title?: string
    open: boolean
    onCancel: () => void
    isLoading: boolean
    onSubmit: () => void
    error: any
}

const TeamUnassignEmployeeDialog = ({ onSubmit, open, isLoading, onCancel, error, title }: TeamUnassignEmployeeDialogProps) => (
    <>
        <FormDeleteDialog
            isOpen={open}
            onClose={onCancel}
            cancelButtonText={i18n.t('common:buttons.cancel')}
            isLoading={isLoading}
            onSubmit={onSubmit}
            submitButtonText={i18n.t('common:buttons.confirm')}
            title={title || i18n.t('common:actions.unassign')}
        >
            <ApiErrorMessage error={error} />
        </FormDeleteDialog>
    </>
)

export default TeamUnassignEmployeeDialog
