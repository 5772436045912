import { IconCheck, IconX } from '@tabler/icons-react'
import * as React from 'react'

import { StepsStatusType } from './Steps'
import { clsxm } from '../../lib/clsxm'

const STEP_STATUS_ICON: Record<StepsStatusType, React.ReactNode> = {
    complete: <IconCheck />,
    pending: null,
    'in-progress': null,
    error: <IconX />,
}

const classes = {
    stepItem: 'flex items-center',
    stepItemVertical: 'items-start flex-col',
    stepItemWrapper: 'flex items-center',
    stepItemIcon:
        'min-w-[2.25rem] text-lg rounded-full w-9 h-9 flex items-center justify-center font-semibold',
    stepItemIconPending: 'border-2 border-gray-300 dark:border-gray-600',
    stepItemIconCurrent: 'border-2',
    stepItemIconError: 'border-2 border-danger-600 text-danger-600',
    stepItemContent: 'ml-3 rtl:mr-3 relative',
    stepItemTitle:
        'whitespace-nowrap font-bold block text-black dark:text-gray-300',
    stepItemTitleError: 'text-danger-600',
    stepClickable: 'cursor-pointer',
    stepConnect: 'h-0.5 w-full ml-2.5 rtl:mr-2.5',
    inactive: 'bg-gray-200 dark:bg-gray-600',
    stepConnectVertical: 'min-h-[3.5rem] w-0.5 ml-4 rtl:mr-4',
}

export type StepsItemProps = {
    className?: string
    vertical?: boolean
    stepNumber?: number
    status?: StepsStatusType
    title?: string | React.ReactNode
    description?: string | React.ReactNode
    customIcon?: string | React.ReactNode
    isLast?: boolean
    onStepChange?: () => void
}

const StepsItem = React.forwardRef<HTMLDivElement, StepsItemProps>(
    (
        {
            className,
            status = 'in-progress',
            customIcon,
            stepNumber,
            description,
            title,
            isLast,
            vertical,
            onStepChange,
            ...rest
        },
        ref
    ) => {
        const color = 'primary-600'

        let stepIcon = <span>{STEP_STATUS_ICON[status] ?? stepNumber}</span>

        if (customIcon) {
            stepIcon = <span>{customIcon}</span>
        }

        const stepItemClass = clsxm(
            classes.stepItem,
            vertical && classes.stepItemVertical,
            className
        )

        const stepWrapperClass = clsxm(
            classes.stepItemWrapper,
            onStepChange && classes.stepClickable
        )

        const stepIconClass = clsxm(
            classes.stepItemIcon,
            status === 'pending' && classes.stepItemIconPending,
            status === 'complete' && `bg-${color} text-white`,
            status === 'error' && classes.stepItemIconError,
            status === 'in-progress' &&
                `text-${color} dark:text-gray-100 border-${color}`,
            status === 'in-progress' && classes.stepItemIconCurrent
        )

        const stepConnectClass = clsxm(
            classes.stepConnect,
            vertical && classes.stepConnectVertical,
            status === 'complete' ? `bg-${color}` : classes.inactive
        )

        const stepTitleClass = clsxm(
            classes.stepItemTitle,
            status === 'error' && classes.stepItemTitleError,
            onStepChange && status !== 'error' && `hover:text-${color}`
        )

        const handleStepChange = () => onStepChange?.()

        return (
            <div
                className={stepItemClass}
                {...rest}
                ref={ref}
                onClick={handleStepChange}
            >
                <div className={stepWrapperClass}>
                    <div className={stepIconClass}>{stepIcon}</div>
                    {title && (
                        <div className='relative ml-3 rtl:mr-3'>
                            {title && (
                                <span className={stepTitleClass}>{title}</span>
                            )}
                            {description && vertical && (
                                <span>{description}</span>
                            )}
                        </div>
                    )}
                </div>
                {!isLast && <div className={stepConnectClass} />}
            </div>
        )
    }
)

export default StepsItem
