
import fetchWithoutPagination from '@viterbit/web-app/dataAccess/helper/fetchWithoutPagination'
import paginationMetaMapper from '@viterbit/web-app/dataAccess/mapper/paginationMeta.mapper'
import roleMapper from '@viterbit/web-app/dataAccess/mapper/role.mapper'
import { Role } from '@viterbit/web-app/dataAccess/model/Role.model'
import { Configuration, RoleApi } from 'viterbit-api-sdk'

import { commonConfig } from './config'

const api = new RoleApi(new Configuration(commonConfig))

export const roleListPaginated = (body: Parameters<typeof api.listRole>[0]) =>
    api.listRole(body).then(({data, meta}) => ({
        meta: paginationMetaMapper(meta),
        data: data.map(roleMapper.toModel)
    }))

export const roleList = (body: Parameters<typeof api.listRole>[0]) =>
    fetchWithoutPagination(roleListPaginated)({...body, pageSize: 10} as any) as Promise<Role[]>

