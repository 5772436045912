import * as React from 'react'
import clsxm from 'ui/src/lib'

type LeaveTeamGridProps = {
    children?: React.ReactNode
    className?: string
    daysCount: number
}

const styles = {
    root: 'grid grid-cols-[repeat(32,minmax(0,1fr))] overflow-x-auto items-center',
    days: {
        28: 'grid-cols-[260px_repeat(28,minmax(40px,1fr))]',
        29: 'grid-cols-[260px_repeat(29,minmax(40px,1fr))]',
        30: 'grid-cols-[260px_repeat(30,minmax(40px,1fr))]',
        31: 'grid-cols-[260px_repeat(31,minmax(40px,1fr))]',
    }
}

const LeaveTeamGrid = (props: LeaveTeamGridProps) => {
    const { children, className, daysCount } = props

    return (
        <div className={clsxm(styles.root, styles.days[daysCount], className)}>
            {children}
        </div>

    )
}

export default LeaveTeamGrid
