/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateLeavePolicyCommand
 */
export interface UpdateLeavePolicyCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateLeavePolicyCommand
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateLeavePolicyCommand
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateLeavePolicyCommand
     */
    businessDays?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateLeavePolicyCommand
     */
    daysPerYear?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateLeavePolicyCommand
     */
    daysRange?: Array<UpdateLeavePolicyCommandDaysRangeEnum>;
}


/**
 * @export
 */
export const UpdateLeavePolicyCommandDaysRangeEnum = {
    Monday: 'MONDAY',
    Tuesday: 'TUESDAY',
    Wednesday: 'WEDNESDAY',
    Thursday: 'THURSDAY',
    Friday: 'FRIDAY',
    Saturday: 'SATURDAY',
    Sunday: 'SUNDAY'
} as const;
export type UpdateLeavePolicyCommandDaysRangeEnum = typeof UpdateLeavePolicyCommandDaysRangeEnum[keyof typeof UpdateLeavePolicyCommandDaysRangeEnum];


/**
 * Check if a given object implements the UpdateLeavePolicyCommand interface.
 */
export function instanceOfUpdateLeavePolicyCommand(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateLeavePolicyCommandFromJSON(json: any): UpdateLeavePolicyCommand {
    return UpdateLeavePolicyCommandFromJSONTyped(json, false);
}

export function UpdateLeavePolicyCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateLeavePolicyCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'businessDays': !exists(json, 'businessDays') ? undefined : json['businessDays'],
        'daysPerYear': !exists(json, 'daysPerYear') ? undefined : json['daysPerYear'],
        'daysRange': !exists(json, 'daysRange') ? undefined : json['daysRange'],
    };
}

export function UpdateLeavePolicyCommandToJSON(value?: UpdateLeavePolicyCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'businessDays': value.businessDays,
        'daysPerYear': value.daysPerYear,
        'daysRange': value.daysRange,
    };
}

