import { IsNotEmpty, IsString } from '@viterbit/web-app/shared/utils/ClassValidators'


class TeamDetailValidator {
    id: string

    @IsNotEmpty()
    @IsString()
    name: string

    description: string
}

export default TeamDetailValidator
