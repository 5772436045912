import { clsxm } from '@viterbit/web-app/shared/utils/clsxm'
import * as React from 'react'
import FormButton, { FormButtonProps } from 'ui/src/components/Button/FormButton'

import FormStatusContext from './FormStatusContext'

type SubmitButtonProps = FormButtonProps & {
    form?: string
}

const SubmitButton = (props: SubmitButtonProps) => {
    const { children, variant, className, ...rest } = props

    const { disableSubmit, loading, success } =
        React.useContext(FormStatusContext)

    const classes = clsxm(
        success && 'pointer-events-none cursor-not-allowed',
        className
    )

    return (
        <FormButton
            disabled={disableSubmit}
            loading={loading}
            variant={variant}
            success={success}
            type="submit"
            className={classes}
            data-testid="submit-button"
            {...rest}
        >
            {children}
        </FormButton>

    )
}

export default SubmitButton
