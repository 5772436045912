/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Employee } from '@viterbit/web-app/dataAccess/model/Employee.model'
import i18n from '@viterbit/web-app/i18n'
import EmptyState from '@viterbit/web-app/shared/display/EmptyState/EmptyState'
import dayjs from 'dayjs'
import * as React from 'react'
import List from 'ui/src/components/List'

import CalendarItem from './CalendarItem'

type CalendarListProps = {
    className?: string
    employee: Employee
    limit?: number
}

const CalendarList = ({employee,className,limit,}: CalendarListProps) => {

    if (!employee.location) {
        return <>You have no location</>
    }

    const holidays = employee.location.calendar
        .flatMap((c) => c.publicHolidays)
        .filter((holiday) => dayjs().isBefore(holiday.day))

    const newList = limit ? holidays.slice(0, limit) : holidays

    if (newList.length === 0) {
        return (
            <EmptyState
                description={i18n.t('emptyState:noHolidays', {
                    emptyKey: employee.location.name,
                })}
            />
        )
    }

    return (
        <List className={className}>
            {newList.map((holiday) => (
                <CalendarItem
                    key={holiday.day}
                    holiday={holiday}
                    location={employee.location!}
                />
            ))}
        </List>
    )
}

export default CalendarList
