import { Avatar } from '@mantine/core'
import { IconBuildingEstate } from '@tabler/icons-react'
import { Organization } from '@viterbit/web-app/dataAccess/model/Organization.model'
import SimpleCard from '@viterbit/web-app/shared/display/SimpleCard'

import OrganizationAvatarUpload from './OrganizationAvatarUpload'

type OrganizationAvatarProps = {
    organization: Organization
    children?: React.ReactNode
}

const OrganizationAvatar = (props: OrganizationAvatarProps) => {
    const { organization, children } = props

    return (
        <SimpleCard padding>
            <article className='flex flex-col items-center justify-center w-full gap-y-4'>
                <OrganizationAvatarUpload organization={organization}>
                    <Avatar
                        alt={organization?.name}
                        size={160}
                        radius={160}
                        src={organization?.logo?.url}
                        sx={{ backgroundColor: '#e5e7eb' }}
                    >
                        <IconBuildingEstate size={80} stroke={1} />
                    </Avatar>
                </OrganizationAvatarUpload>
                {children}
            </article>
        </SimpleCard>
    )
}
export default OrganizationAvatar
