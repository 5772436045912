/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import dateMapper from '@viterbit/web-app/dataAccess/mapper/date.mapper'
import { IWeekDays, WEEK_DAYS } from '@viterbit/web-app/dataAccess/model/Week.model'
import i18n from '@viterbit/web-app/i18n'
import dayjs from 'dayjs'

import getDaysFromDate from './getDaysFromDate'
import { LeaveTeamDay,LeaveTeamEmployee } from './LeaveTeamShared'
import mapLeavesDays from './mapLeavesDays'

const dayOfWeekMapper = {
    0: 6, // Sunday
    1: 0, // Monday
    2: 1, // Tuesday
    3: 2, // Wednesday
    4: 3, // Thursday
    5: 4, // Friday
    6: 5, // Saturday
}

const turnMapper: Record<'MORNING' | 'AFTERNOON', LeaveTeamDay['type']> = {
    MORNING: 'left',
    AFTERNOON: 'right',
}

const mapPublicHolidays = (employee) => employee.location?.calendar.flatMap(calendar => calendar.publicHolidays).reduce((mappedPublicHolidays, publicHoliday) => {
    mappedPublicHolidays[dateMapper.toDate(publicHoliday.day)!] = publicHoliday.name
    return mappedPublicHolidays
}, {})
const getDaysFromEmployee = (
    inputDate: dayjs.ConfigType,
    employee: LeaveTeamEmployee
): LeaveTeamDay[] => {
    if (!employee.leaves) return getDaysFromDate(inputDate)

    const approvedLeaves = employee.leaves.filter(
        (leave) => leave.status === 'APPROVED' || leave.status === 'PENDING'
    )
    const mappedLeavesByMonth = mapLeavesDays(approvedLeaves)
    const mappedPublicHolidays = mapPublicHolidays(employee)

    const currentMonth = dayjs(inputDate).startOf('month')
    const count = currentMonth.daysInMonth()

    const days: LeaveTeamDay[] = Array.from({ length: count }, (_, i) => {
        const number = i + 1
        const dateDay = currentMonth.add(number - 1, 'day')
        const formattedDay = dateMapper.toDate(dateDay)!
        const leave = mappedLeavesByMonth[formattedDay]
        const dayIndexOfWeek = dayOfWeekMapper[dateDay.day()] as keyof typeof WEEK_DAYS
        const dayNameOfWeek: IWeekDays = WEEK_DAYS[dayIndexOfWeek] as IWeekDays
        const isDisabled = !employee.leavePolicy?.daysRange?.includes(dayNameOfWeek)

        const type = !leave?.halfDay ? 'full' : turnMapper[leave.turn!]
        const description = leave?.leaveReason?.visibleOtherEmployees ? leave?.leaveReason?.name : ''

        let tooltipText

        if (employee.canEdit) {
            tooltipText = i18n.t('employees:calendar.tooltipAction.edit')
        }
        if (!leave && employee.canCreate) {
            tooltipText = i18n.t('employees:calendar.tooltipAction.create')
        }
        if (mappedPublicHolidays[formattedDay]) {
            tooltipText = mappedPublicHolidays[formattedDay]
        }
        
        const day = {
            number,
            name: dateDay.format('ddd'),
            isToday: dayjs().isSame(dateDay, 'day'),
            backgroundColor: leave?.leaveReason?.color,
            variant: leave?.status === 'PENDING' ?  'border' :mappedPublicHolidays[formattedDay] ? 'dashed' : 'solid' as any,
            description,
            isDisabled,
            type,
            leave,
            date: dateDay.toDate(),
            tooltipText
        }

        return day
    })
    

    return days
}

export default getDaysFromEmployee
