/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FindFileQueryResponse2 } from './FindFileQueryResponse2';
import {
    FindFileQueryResponse2FromJSON,
    FindFileQueryResponse2FromJSONTyped,
    FindFileQueryResponse2ToJSON,
} from './FindFileQueryResponse2';

/**
 * 
 * @export
 * @interface ComplaintRecordQueryResponse2
 */
export interface ComplaintRecordQueryResponse2 {
    /**
     * 
     * @type {string}
     * @memberof ComplaintRecordQueryResponse2
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ComplaintRecordQueryResponse2
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof ComplaintRecordQueryResponse2
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ComplaintRecordQueryResponse2
     */
    employeeName?: string | null;
    /**
     * 
     * @type {Array<FindFileQueryResponse2>}
     * @memberof ComplaintRecordQueryResponse2
     */
    files?: Array<FindFileQueryResponse2>;
}

/**
 * Check if a given object implements the ComplaintRecordQueryResponse2 interface.
 */
export function instanceOfComplaintRecordQueryResponse2(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ComplaintRecordQueryResponse2FromJSON(json: any): ComplaintRecordQueryResponse2 {
    return ComplaintRecordQueryResponse2FromJSONTyped(json, false);
}

export function ComplaintRecordQueryResponse2FromJSONTyped(json: any, ignoreDiscriminator: boolean): ComplaintRecordQueryResponse2 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'employeeName': !exists(json, 'employeeName') ? undefined : json['employeeName'],
        'files': !exists(json, 'files') ? undefined : ((json['files'] as Array<any>).map(FindFileQueryResponse2FromJSON)),
    };
}

export function ComplaintRecordQueryResponse2ToJSON(value?: ComplaintRecordQueryResponse2 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'message': value.message,
        'createdAt': value.createdAt,
        'employeeName': value.employeeName,
        'files': value.files === undefined ? undefined : ((value.files as Array<any>).map(FindFileQueryResponse2ToJSON)),
    };
}

