/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/function-component-definition */
import { classValidatorResolver } from '@hookform/resolvers/class-validator'
import { BasicCreateFormValidator } from '@viterbit/web-app/shared/form/BasicCreateFormValidator'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import Button from 'ui/src/components/Button'
import Dialog from 'ui/src/components/Dialog'
import { FormInput } from 'ui/src/components/Form'

export type FormFields = {
    name: string
    date?: any
}

const resolver = classValidatorResolver(BasicCreateFormValidator)

const ActionSpan = ({ initialDate, children, onSubmit }) => {

    const [open, setOpen] = React.useState(false)
    
    const handleOnClick = () => {
        setOpen(true)
    }

    const _onSubmit = (
        data: FormFields,
        event?: React.BaseSyntheticEvent
    ) => {
        event?.stopPropagation()
        setOpen(false)
        onSubmit(data)
    }


    const {
        control,
        handleSubmit,
        formState: { isValid, isDirty, isSubmitting },
        setValue
    } = useForm<FormFields>({
        resolver,
        mode: 'all',

    })

    React.useEffect(() => {
        setValue('date', initialDate)
    }, [initialDate])

    const shouldDisabledSubmit =
        !isValid || !isDirty || isSubmitting 
    

    return (
        <>
            <span className='p-2' onClick={handleOnClick}>
                {children}
            </span>
            <Dialog open={open} title="Add holiday" onClose={() => setOpen(false)}>
                <FormInput
                    asterisk
                    name='name'
                    label='Name'
                    placeholder='Enter name'
                    control={control}
                />

                <div className='flex justify-end gap-x-2 mt-4 [&>*]:flex-1'>

                    <Button onClick={() => setOpen(false)}>Cancel</Button>
                    <Button
                        onClick={handleSubmit(_onSubmit)}
                        variant='solid'
                        className='float-right'
                        disabled={shouldDisabledSubmit}
                    >
                        Add
                    </Button>
                </div>
            </Dialog>
        </>
        
    )
}
export default ActionSpan
