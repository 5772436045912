/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { User } from '@viterbit/web-app/dataAccess/model/User.model'
import { NavigationItem } from '@viterbit/web-app/routes'
import * as React from 'react'

import { useUser } from '../employee/useUser'

type RouteEnabled = {
    routes: NavigationItem[]
}

export const isRouteEnabled = (user: User) => (item: NavigationItem) => {
    if (!item.handle.showInMenu) {
        return false
    }

    if (item.handle.permission && !user.menu[item.handle.permission]) {
        return false
    }
    
    return true
}

export const useRouteFilter = (): ReturnType<typeof isRouteEnabled> => {
    const user = useUser()
    const filter = React.useMemo(() => isRouteEnabled(user), [user])

    return filter
}

export const useRouteEnabled = ({
    routes,
}: RouteEnabled) => {
    const filter = useRouteFilter()
    const filtered = React.useMemo(() => routes.filter(filter), [routes, filter])

    return filtered
}

