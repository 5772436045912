/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JsonSchema
 */
export interface JsonSchema {
    /**
     * 
     * @type {string}
     * @memberof JsonSchema
     */
    type?: string;
    /**
     * 
     * @type {Array<object>}
     * @memberof JsonSchema
     */
    properties?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof JsonSchema
     */
    oneOf?: Array<object>;
}

/**
 * Check if a given object implements the JsonSchema interface.
 */
export function instanceOfJsonSchema(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JsonSchemaFromJSON(json: any): JsonSchema {
    return JsonSchemaFromJSONTyped(json, false);
}

export function JsonSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : json['type'],
        'properties': !exists(json, 'properties') ? undefined : json['properties'],
        'oneOf': !exists(json, 'oneOf') ? undefined : json['oneOf'],
    };
}

export function JsonSchemaToJSON(value?: JsonSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'properties': value.properties,
        'oneOf': value.oneOf,
    };
}

