import { IsEmail, IsNotEmpty } from '@viterbit/web-app/shared/utils/ClassValidators'

import { EmployeeProfileProfileFields } from './EmployeeProfileProfileForm'

export class  EmployeeProfileProfileValidation implements EmployeeProfileProfileFields {
    id: string
    managerId: string | null

    @IsNotEmpty()
    locationId: string

    @IsNotEmpty()
    companyId: string

    @IsNotEmpty()
    leavePolicyId: string

    @IsEmail()
    @IsNotEmpty()
    email: string
}
