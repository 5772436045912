/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-constant-condition */
import { classValidatorResolver } from '@hookform/resolvers/class-validator'
import { locationImportHolidays, locationUpdate } from '@viterbit/web-app/dataAccess/service/locationService'
import { useLocationDetail } from '@viterbit/web-app/dataAccess/useCase/location/useLocationDetail'
import i18n from '@viterbit/web-app/i18n'
import FieldsetGeoData from '@viterbit/web-app/shared/form/FieldsetGeoData'
import Form from '@viterbit/web-app/shared/form/Form'
import FormInfo from '@viterbit/web-app/shared/form/FormInfo'
import FormSelectCountry from '@viterbit/web-app/shared/form/FormSelectCountry'
import FormSelectState from '@viterbit/web-app/shared/form/FormSelectState'
import SubmitButton from '@viterbit/web-app/shared/form/SubmitButton'
import useLocale from '@viterbit/web-app/shared/utils/useLocale'
import React from 'react'
import { useParams } from 'react-router-dom'
import Button from 'ui/src/components/Button'
import { findNextValue } from 'ui/src/components/Counter/Counter'
import { FormSwitcher, FormYearCounter } from 'ui/src/components/Form'
import FormSkeleton from 'ui/src/components/Form/FormSkeleton'

import LocationFormsValidator from '../../detail/validators'


export type HolidaysLoadViewFields = {
    year: number
    state?: string
    country?: string
    isFromScratch?: boolean
}

export type LocationHoldayCreateFormProps = {
    onClose: React.MouseEventHandler<HTMLButtonElement>
    onSuccess: () => void
}

const resolver = classValidatorResolver(LocationFormsValidator.HolidaysLoad)

const currentYear = new Date().getFullYear()

const LocationHolidayCreateForm = ({ onClose, onSuccess }: LocationHoldayCreateFormProps) => {
    const params = useParams()
    const locale = useLocale()

    const { data: location, isLoading } = useLocationDetail({
        query: {
            id: params.locationId!
        },
        enabled: !!params.locationId
    })

    if (isLoading) {
        return <FormSkeleton className='mt-[100px]'/>
    }

    const disabledYears = location!.calendar.map((x) => x.year)

    return (
        <Form<HolidaysLoadViewFields, HolidaysLoadViewFields>
            withTransition
            formConfig={{
                resolver,
                mode: 'all',
                defaultValues: {
                    country: location!.country,
                    state: location!.state,
                    year: findNextValue({
                        disabledValues: disabledYears,
                        value: currentYear - 1,
                    }),
                },
            }}
            mutationConfig={{
                onSuccess: onSuccess,
                mutationFn: (data) => {
                    if (data.isFromScratch) {
                        return locationUpdate({
                            id: location!.id,
                            updateLocationCommand: {
                                calendar: [
                                    ...location!.calendar,
                                    {
                                        year: data.year,
                                        publicHolidays: [],
                                    },
                                ],
                            }
                        })
                            .then(() => data)
                    } else {
                        return locationImportHolidays({
                            id: location!.id,
                            importHolidaysCommand: {
                                year: data.year,
                                state: data.state!,
                                country: data.country!,
                            }
                        })
                            .then(() => data)
                    }
                },
            }}
        >
            {(form) => {
                const isFromScratch = form.watch('isFromScratch')
                return (
                    <>
                        <FormYearCounter
                            min={currentYear}
                            max={currentYear + 1}
                            disabledValues={location!.calendar.map(
                                (x) => x.year
                            )}
                            control={form.control}
                            name='year'
                        />

                        <FieldsetGeoData>
                            <FormSelectCountry
                                asterisk
                                locale={locale}
                                name='country'
                                control={form.control}
                                label={i18n.t('settingLocations:country')}
                                placeholder={i18n.t('settingLocations:selectCountry')}
                                isDisabled={isFromScratch}
                            />

                            <FormSelectState
                                asterisk
                                name='state'
                                control={form.control}
                                label={i18n.t('settingLocations:state')}
                                placeholder={i18n.t('settingLocations:selectState')}
                                isDisabled={isFromScratch}
                            />
                        </FieldsetGeoData>
                        <FormSwitcher
                            className='flex-row mb-4'
                            name='isFromScratch'
                            control={form.control}
                            label={i18n.t('settingLocations:startFromScratch')}
                        />
                        <FormInfo info={i18n.t('settingLocations:infoHolidays')} />
                        <Button onClick={onClose} type='button'>{i18n.t('settingLocations:cancel')}</Button>
                        <SubmitButton disabled={!form.formState.isValid}>
                            {i18n.t('settingLocations:confirm')}
                        </SubmitButton>
                    </>
                )
            }}
        </Form>
    )
}

export default LocationHolidayCreateForm
