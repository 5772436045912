import * as React from 'react'
import clsxm from 'ui/src/lib'

type OfferChipProps = {
    children?: React.ReactNode
    className?: string
} & React.HTMLAttributes<HTMLParagraphElement>

const styles = {
    root: 'rounded-full bg-grey-200 py-1 px-2.5 text-xs font-semibold leading-5 text-black w-fit mx-auto text-center',
}

const OfferChip = (props: OfferChipProps) => {
    const { children, className, ...rest } = props

    return (            
        <p {...rest} className={clsxm(styles.root, className)}>{children}</p>
    )
}

export default OfferChip
