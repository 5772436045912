import i18n from '@viterbit/web-app/i18n'
import SubmitButton from '@viterbit/web-app/shared/form/SubmitButton'
import ListSkeleton from 'ui/src/components/List/ListSkeleton'

const DocumentBulkUploadPreviewEmployeeListSkeleton = () => (
    <div className='flex flex-col gap-y-2'>
        <div
            className='flex justify-end w-full py-3 px-2 h-[72px] gap-x-2 items-center border-2 rounded-md'
        >
            <SubmitButton
                variant='filled'
                disabled
                type='button'
            >
                {i18n.t('employees:bulkUpload.preview.assign')}
            </SubmitButton>
        </div>
        <div className='bg-white border-2'>
            <ListSkeleton count={5} primary secondary icon />
        </div>
    </div>
)

export default DocumentBulkUploadPreviewEmployeeListSkeleton