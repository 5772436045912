import { IconEye, IconEyeOff } from '@tabler/icons-react'
import * as React from 'react'

import FormInput, { FormInputProps } from './FormInput'
type FormPasswordInputProps = FormInputProps

const FormPasswordInput = (props: FormPasswordInputProps) => {
    const [inputType, setInputType] = React.useState<'text' | 'password'>(
        'password'
    )

    const isTypePassword = inputType === 'password'

    const handlePasswordVisibility = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.stopPropagation()
        event.preventDefault()
        setInputType(isTypePassword ? 'text' : 'password')
    }

    const inputIcon = (
        <span className='cursor-pointer' onMouseDown={handlePasswordVisibility}>
            {isTypePassword ? <IconEye /> : <IconEyeOff />}
        </span>
    )

    return <FormInput {...props} type={inputType} suffix={inputIcon} />
}

export default FormPasswordInput
