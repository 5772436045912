/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import fetchWithoutPagination from '@viterbit/web-app/dataAccess/helper/fetchWithoutPagination'
import employeeMapper from '@viterbit/web-app/dataAccess/mapper/employee.mapper'
import estimationMapper from '@viterbit/web-app/dataAccess/mapper/estimation.mapper'
import paginationMetaMapper from '@viterbit/web-app/dataAccess/mapper/paginationMeta.mapper'
import timeTrackingMapper from '@viterbit/web-app/dataAccess/mapper/timeTracking.mapper'
import { TimeTracking } from '@viterbit/web-app/dataAccess/model/TimeTracking.model'
import { queryClient } from '@viterbit/web-app/shared/lib/ReactQueryProvider'
import { Configuration, TimeTrackingApi } from 'viterbit-api-sdk'

import { commonConfig } from './config'
import { TIME_TRACKING_QUERY_KEY } from '../cacheKeys'

const api = new TimeTrackingApi(new Configuration(commonConfig))

export const timeTrackingInvalidate = () =>
    queryClient.invalidateQueries([TIME_TRACKING_QUERY_KEY])


export const timeTrackingCreate = (body: Parameters<typeof api.createTimeTracking>[0]) =>
    api.createTimeTracking(body).then((data) => {
        timeTrackingInvalidate()
        return data
    })

export const timeTrackingUpdate = (body: Parameters<typeof api.updateTimeTracking>[0]) =>
    api.updateTimeTracking(body).then((data) => {
        timeTrackingInvalidate()
        return data
    })

export const timeTrackingListPaginated = (body: Parameters<typeof api.listTimeTracking>[0]) =>
    api.listTimeTracking(body).then(({data, meta}) => ({
        meta: paginationMetaMapper(meta),
        data: data.map(timeTrackingMapper.toModel)
    }))

export const timeTrackingList = (body: Parameters<typeof api.listTimeTracking>[0]) =>
    fetchWithoutPagination(timeTrackingListPaginated)({...body, pageSize: 10} as any) as Promise<TimeTracking[]>

export const timeTrackingDelete = (body: Parameters<typeof api.deleteTimeTracking>[0]) =>
    api.deleteTimeTracking(body).then((data) => {
        timeTrackingInvalidate()
        return data
    })
    
export const timeTrackingDetail = (body: Parameters<typeof api.findTimeTracking>[0]) =>
    api.findTimeTracking(body).then(timeTrackingMapper.toModel)

export const timeTrackingClockDetail = (body: Parameters<typeof api.clock>[0]) =>
    api.clock(body)

export const timeTrackingClockUpdate = (body: Parameters<typeof api.toggleClock>[0]) =>
    api.toggleClock(body).then(() => {
        timeTrackingInvalidate()
    })

export const timeTrackingEstimationList = (body: Parameters<typeof api.timeTrackingEstimation>[0]) =>
    api.timeTrackingEstimation(body)
        .then(({ estimations }) => estimations!.map(estimationMapper.toModel))

export const timeTrackingReportListPaginated = (body: Parameters<typeof api.timeTrackingReport>[0]) =>
    api.timeTrackingReport(body).then(response => ({
        meta: paginationMetaMapper(response.meta!),
        data: response.data!.map(employeeMapper.toModel),
    }))

export const timeTrackingApprove = (body: Parameters<typeof api.approveDay>[0]) =>
    api.approveDay(body).then(response => {
        timeTrackingInvalidate()
        return response
    })

export const timeTrackingDisapprove = (body: Parameters<typeof api.rejectDay>[0]) =>
    api.rejectDay(body).then(response => {
        timeTrackingInvalidate()
        return response
    })


