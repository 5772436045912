/* eslint-disable @typescript-eslint/no-explicit-any */
import jwtDecode from 'jwt-decode'
import words from 'lodash/words'

import stringToColor from './stringToColor'
import { JWTUserPayload, User } from '../../dataAccess/model/User.model'

const STORE_NAME = import.meta.env.VITE_JWT_COOKIE_NAME

export const decodeAuthToken = (token: string): User => {
    const decoded = jwtDecode(token) as JWTUserPayload
    const usernameWords =  words(decoded.username.split('@')[0])
    const initials: string = usernameWords.map((word: string) => word[0]).join('')

    return {
        ...decoded,
        color: stringToColor(decoded.username),
        timezone: 'Europe/Madrid',
        name: usernameWords.join(' '),
        initials,
        isActive: ['IN_TRIAL', 'ACTIVE'].includes(decoded.subscriptionStatus),
    }
}

export const parseUser = (jwt?: string): User | null => {
    if (!jwt) { 
        return null
    }

    try {
        return decodeAuthToken(jwt)
    } catch (error) {
        console.error('cannot decode jwt', error)
    }

    return null
}

export const isTokenExpired = (tokenExpirationTime: number): boolean => (tokenExpirationTime * 1000) < Date.now()
