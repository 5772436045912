const TimeTrackingStyles = () => (
    // eslint-disable-next-line react/no-unknown-property
    <style global jsx>
        {`
            /* https://github.com/fullcalendar/fullcalendar/blob/main/packages/core/src/styles/vars.css */
            :root {
                --fc-small-font-size: .85em;
                --fc-page-bg-color: #fff;
                --fc-neutral-bg-color: #f9fafb;
                --fc-neutral-text-color: #808080;
                --fc-border-color: #f3f4f6;
            
                --fc-button-text-color: #111827;
                --fc-button-bg-color: #f3f4f6;
                --fc-button-border-color: #d1d5db;
                --fc-button-hover-bg-color: #e5e7eb;
                --fc-button-hover-border-color: #d1d5db;
                --fc-button-active-bg-color: #e5e7eb;
                --fc-button-active-border-color: #d1d5db;
            
                --fc-event-bg-color: #CFE7FF;
                --fc-event-border-color: #CFE7FF;
                --fc-event-text-color: #4b5563;
                --fc-event-selected-overlay-color: rgba(0, 0, 0, 0.25);
            
                --fc-more-link-bg-color: #d0d0d0;
                --fc-more-link-text-color: inherit;
            
                --fc-event-resizer-thickness: 8px;
                --fc-event-resizer-dot-total-width: 8px;
                --fc-event-resizer-dot-border-width: 1px;
            
                --fc-non-business-color: rgba(215, 215, 215, 0.3);
                --fc-bg-event-color: rgb(143, 223, 130);
                --fc-bg-event-opacity: 0.3;
                --fc-highlight-color: rgba(188, 232, 241, 0.3);
                --fc-today-bg-color: #eff6ff;
                --fc-now-indicator-color: #b12424;
            }
            
            .fc .fc-button-primary:not(:disabled).fc-button-active:focus, .fc .fc-button-primary:not(:disabled):active:focus {
                box-shadow: none;
            }

            .fc .fc-button-primary:disabled {
                display: none;
            }

            .fc .fc-button-primary:disabled,
            .fc .fc-button-primary:focus,
            .fc .fc-button-primary:active:focus {
                box-shadow: none;
            }

            .fc .fc-scrollgrid {
                box-shadow: 0 0 13px 0 rgba(82,63,105,.05);
            }
            
            .fc .fc-scrollgrid,
            .fc .fc-list {
                background: white;
                border-radius: 0.5rem;
                overflow: hidden;
            }
            
            .fc .fc-timegrid-slot:empty:before {
                height: 4px;
                font-size: 4px;
            }
            
            .fc .fc-col-header-cell-cushion {
                padding: 8px;
                font-weight: 500;
            }
            
            .fc-timegrid-slot-label-cushion {
                font-size: 0.75rem;
            }
            
            .fc .fc-timegrid-slot-label-cushion {
                padding: 0 12px;
            }
            
            .fc .fc-scrollgrid-section-header {
                background: var(--fc-neutral-bg-color);
            }
            
            .fc .fc-timegrid-slot-minor {
                border: none;
            }
            
            .fc .fc-popover {
                border-radius: 8px;
            }
            
            .fc .fc-popover-header,
            .fc .fc-more-popover .fc-popover-body {
                padding: 8px 12px;
            }
            
            .fc .fc-button {
                height: 36px;
                padding: 6px 12px;
                font-weight: 500;
                display: flex;
                align-items: center;
                border-radius: .375rem;
            }
            
            .fc .fc-toolbar-chunk {
                display: flex;
                align-items: center;
            }
            
            .fc-event-time {
                padding: 3px 8px;
                text-align: right;
            }
        `}
    </style>
)

export default TimeTrackingStyles
