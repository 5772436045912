/* eslint-disable @typescript-eslint/no-explicit-any */
import { Employee } from '@viterbit/web-app/dataAccess/model/Employee.model'
import EmployeeAvatar from '@viterbit/web-app/shared/employee/EmployeeAvatar'
import * as React from 'react'
import { clsxm } from 'ui/src/lib/clsxm'

import { STYLES } from './EmployeeItemStyles'

export type EmployeeItemProps = {
    children?: React.ReactNode
    className?: string
    employee: Employee
    size?: 'sm' | 'lg'
    leadTitle?: string
    clickable?: boolean
    testIdPrefix?: string
}

const EmployeeItem = (props: EmployeeItemProps) => {
    const { employee, children, className, size = 'sm', leadTitle, clickable, testIdPrefix = 'default' } = props

    const styles = STYLES.sizes[size]

    const headline = React.useMemo(() => {
        if (employee.jobPositionAlias) return employee.jobPositionAlias
        if (employee.jobPosition?.name && employee.jobPositionLevel?.name)
            return `${employee.jobPosition.name} ${employee.jobPositionLevel.name}`
        return employee.email
    }, [employee])

    return (
        <article className={clsxm(STYLES.root, className)}>
            <EmployeeAvatar employee={employee} size={styles.avatarSize as 'lg' | 'xl'}/>
            <div className='flex-1 truncate'>
                {leadTitle && (
                    <div 
                        data-testid={`${testIdPrefix}-employee-item-leadTitle`}
                        className={clsxm(styles.leadTitle)}
                    >
                        {leadTitle}
                    </div>
                )}
                <div
                    data-testid={`${testIdPrefix}-employee-item-name`}
                    className={clsxm('text-black truncate', styles.title)}
                >
                    {employee.name} {employee.surname}
                </div>
                <span
                    className={clsxm('truncate', clickable && 'group-hover:underline', styles.subtitle)}
                    data-testid={`${testIdPrefix}-employee-item-headline`}
                >
                    {headline}
                </span>
            </div>
            {children}
        </article>
    )
}

export default EmployeeItem
