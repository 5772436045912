/* eslint-disable @typescript-eslint/no-explicit-any */

import { UseQueryResult } from '@tanstack/react-query'
import { Subscription } from '@viterbit/web-app/dataAccess/model/Subscription.model'
import throwException from '@viterbit/web-app/shared/utils/throwException'
import React from 'react'

import { useOrganizationDetail } from '../organization/useOrganizationDetail'

type SubscriptionDetailConfig = {
    enabled?: boolean
}

export const useSubscriptionList = ({ enabled }: SubscriptionDetailConfig = {}): UseQueryResult<Subscription[], unknown> => {
    const queryResult = useOrganizationDetail({
        query: {
            include: ['subscription', 'paymentPage', 'prices'],
        },
        enabled,
    })

    return React.useMemo(() => {
        const { data, ...rest } = queryResult

        if (rest.isFetched && !data?.subscription) {
            return throwException('Subscription not found')
        }

        return {
            ...rest,
            data: data?.subscription ? [data.subscription] : undefined
        }
    }, [queryResult]) as any
}


