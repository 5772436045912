import { useMutation } from '@tanstack/react-query'
import { Team } from '@viterbit/web-app/dataAccess/model/Team.model'
import { teamDelete } from '@viterbit/web-app/dataAccess/service/teamService'
import i18n from '@viterbit/web-app/i18n'
import ApiErrorMessage from '@viterbit/web-app/shared/feedback/ApiErrorMessage'
import DeleteAlert from '@viterbit/web-app/shared/feedback/DeleteAlert'
import { useNotification } from '@viterbit/web-app/shared/feedback/useNotification'
import FormDeleteDialog from '@viterbit/web-app/shared/form/FormDeleteDialog'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'



type DeleteTeamProps = {
    team: Team
    id: string
}

const DeleteTeam = (props: DeleteTeamProps) => {
    const { team, id } = props
    const notification = useNotification()
    const navigate = useNavigate()
    const [isDeleting, setIsDeleting] = React.useState(false)
    
    const onSuccess = () => {
        notification.push(
            'success', 
            i18n.t('common:notification.delete'), 
            i18n.t('common:notification.successDelete'))
        setIsDeleting(false)
        navigate('/employees/team')
    }

    const { mutate, isLoading, error } = useMutation({
        mutationKey: ['DeleteTeam'],
        onSuccess,
        mutationFn: (data) => teamDelete({ id: id }),
    })

    return (
        <>
            <DeleteAlert
                border
                buttonText={i18n.t('employees:teams.delete.title')}
                onConfirm={() => setIsDeleting(true)}
            >
                {i18n.t('employees:teams.delete.alert')}
            </DeleteAlert>

            <FormDeleteDialog
                isOpen={isDeleting}
                onClose={() => setIsDeleting(false)}
                cancelButtonText={i18n.t('common:buttons.cancel')}
                isLoading={isLoading}
                onSubmit={mutate}
                submitButtonText={i18n.t('common:buttons.confirm')}
                title={i18n.t('employees:teams.delete.title')}
            >
                <ApiErrorMessage error={error} />
            </FormDeleteDialog>
        </>
    )
}

export default DeleteTeam
