/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateLeaveCommand
 */
export interface CreateLeaveCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateLeaveCommand
     */
    employeeId: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateLeaveCommand
     */
    halfDay?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateLeaveCommand
     */
    initialDate: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLeaveCommand
     */
    endDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateLeaveCommand
     */
    turn?: CreateLeaveCommandTurnEnum | null;
    /**
     * 
     * @type {string}
     * @memberof CreateLeaveCommand
     */
    comment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateLeaveCommand
     */
    leaveReasonId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateLeaveCommand
     */
    files?: Array<string>;
}


/**
 * @export
 */
export const CreateLeaveCommandTurnEnum = {
    Morning: 'MORNING',
    Afternoon: 'AFTERNOON'
} as const;
export type CreateLeaveCommandTurnEnum = typeof CreateLeaveCommandTurnEnum[keyof typeof CreateLeaveCommandTurnEnum];


/**
 * Check if a given object implements the CreateLeaveCommand interface.
 */
export function instanceOfCreateLeaveCommand(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "employeeId" in value;
    isInstance = isInstance && "initialDate" in value;
    isInstance = isInstance && "leaveReasonId" in value;

    return isInstance;
}

export function CreateLeaveCommandFromJSON(json: any): CreateLeaveCommand {
    return CreateLeaveCommandFromJSONTyped(json, false);
}

export function CreateLeaveCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateLeaveCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'employeeId': json['employeeId'],
        'halfDay': !exists(json, 'halfDay') ? undefined : json['halfDay'],
        'initialDate': json['initialDate'],
        'endDate': !exists(json, 'endDate') ? undefined : json['endDate'],
        'turn': !exists(json, 'turn') ? undefined : json['turn'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'leaveReasonId': json['leaveReasonId'],
        'files': !exists(json, 'files') ? undefined : json['files'],
    };
}

export function CreateLeaveCommandToJSON(value?: CreateLeaveCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'employeeId': value.employeeId,
        'halfDay': value.halfDay,
        'initialDate': value.initialDate,
        'endDate': value.endDate,
        'turn': value.turn,
        'comment': value.comment,
        'leaveReasonId': value.leaveReasonId,
        'files': value.files,
    };
}

