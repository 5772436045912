import { JobPosition } from '@viterbit/web-app/dataAccess/model/JobPosition.model'
import { IsNotEmpty, IsString } from '@viterbit/web-app/shared/utils/ClassValidators'

class JobPositionDetailValidator implements Omit<JobPosition, 'jobPositionLevels'> {
    id: string

    @IsNotEmpty()
    @IsString()
    name: string

    @IsString()
    description: string
}

export default JobPositionDetailValidator
