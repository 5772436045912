/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { PermissionAction } from '@viterbit/web-app/dataAccess/model/PermissionAction.model'
import { useCurrentEmployee } from '@viterbit/web-app/dataAccess/useCase/employee/useCurrentEmployee'
import { useOrganizationDetail } from '@viterbit/web-app/dataAccess/useCase/organization/useOrganizationDetail'
import i18n from '@viterbit/web-app/i18n'
import AsideCardInfo from '@viterbit/web-app/shared/display/AsideCardInfo'
import { useCurrentEmployeeId } from '@viterbit/web-app/shared/employee/useCurrentEmployeeId'
import useHasPermission from '@viterbit/web-app/shared/permission/useHasPermissions'
import { clsxm } from '@viterbit/web-app/shared/utils/clsxm'
import * as React from 'react'
import FormSkeleton from 'ui/src/components/Form/FormSkeleton'

import EmployeeProfileAccountForm from './account/EmployeeProfileAccountForm'
import EmployeeProfileResendInvitation from './EmployeeProfileResendInvitation'
import EmployeeProfileProfileForm from './profile/EmployeeProfileProfileForm'
import EmployeeProfileSendInvitation from './sendInvitation/EmployeeProfileSendInvitation'
import EmployeeDelete from '../delete/EmployeeDelete'

type EmployeeProfileViewProps = {
    className?: string
}

const EmployeeProfileView = ({ className }: EmployeeProfileViewProps) => {
    const employeeId = useCurrentEmployeeId()!
    const employee = useCurrentEmployee()

    const { hasPermission, isLoading: isLoadingPermissions } = useHasPermission({
        [PermissionAction.CORE_ACCOUNT_CHANGE_ROLE]: [employeeId],
        [PermissionAction.CORE_EMPLOYEE_DELETE]: [employeeId],
        [PermissionAction.CORE_ACCOUNT_INVITE]: [employeeId],
        [PermissionAction.CORE_EMPLOYEE_UPDATE]: [employeeId],
    })

    const hasPermissionToChangeRole = hasPermission(PermissionAction.CORE_ACCOUNT_CHANGE_ROLE, employeeId)
    const hasPermissionToDeleteEmployee = hasPermission(PermissionAction.CORE_EMPLOYEE_DELETE, employeeId)
    const hasPermissionToAccountInvite = hasPermission(PermissionAction.CORE_ACCOUNT_INVITE, employeeId)
    const hasPermissionToUpdate = hasPermission(PermissionAction.CORE_EMPLOYEE_UPDATE, employeeId)

    const { data: organization } = useOrganizationDetail()

    if (isLoadingPermissions || !employee || !organization) {
        return <FormSkeleton />
    }

    return (
        <>
            {
                hasPermissionToAccountInvite && (
                    <>
                        <EmployeeProfileSendInvitation employee={employee} />
                        <EmployeeProfileResendInvitation employee={employee} />
                    </>
                )
            }

            <div className={clsxm('space-y-4', className)}>
                <AsideCardInfo
                    title={i18n.t('employees:employee.profile.profileInfo.title')}
                    description={i18n.t('employees:employee.profile.profileInfo.description')}
                    direction='vertical'
                >
                    <EmployeeProfileProfileForm employee={employee} hasPermissionToUpdate={hasPermissionToUpdate} />
                </AsideCardInfo>

                {employee.hasAccount && hasPermissionToChangeRole && (
                    <AsideCardInfo
                        title={i18n.t('employees:employee.profile.accountInfo.title')}
                        description={i18n.t('employees:employee.profile.accountInfo.description')}
                        direction='vertical'
                    >
                        <EmployeeProfileAccountForm employee={employee} />
                    </AsideCardInfo>
                )}

                {hasPermissionToDeleteEmployee && (
                    <AsideCardInfo
                        title={i18n.t('employees:employee.profile.deleteAccount.title')}
                        description={i18n.t('employees:employee.profile.deleteAccount.description', { name: organization?.name })}
                        direction='vertical'
                    >
                        <EmployeeDelete employee={employee} />
                    </AsideCardInfo>
                )}
            </div>
        </>
    )
}

export default EmployeeProfileView
