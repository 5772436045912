/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClockQueryResponse
 */
export interface ClockQueryResponse {
    /**
     * 
     * @type {string}
     * @memberof ClockQueryResponse
     */
    startDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClockQueryResponse
     */
    timezone: string | null;
}

/**
 * Check if a given object implements the ClockQueryResponse interface.
 */
export function instanceOfClockQueryResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "timezone" in value;

    return isInstance;
}

export function ClockQueryResponseFromJSON(json: any): ClockQueryResponse {
    return ClockQueryResponseFromJSONTyped(json, false);
}

export function ClockQueryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClockQueryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startDate': !exists(json, 'startDate') ? undefined : json['startDate'],
        'timezone': json['timezone'],
    };
}

export function ClockQueryResponseToJSON(value?: ClockQueryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'startDate': value.startDate,
        'timezone': value.timezone,
    };
}

