/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CalendarDay } from './CalendarDay';
import {
    CalendarDayFromJSON,
    CalendarDayFromJSONTyped,
    CalendarDayToJSON,
} from './CalendarDay';
import type { DataFindFileQueryResponse } from './DataFindFileQueryResponse';
import {
    DataFindFileQueryResponseFromJSON,
    DataFindFileQueryResponseFromJSONTyped,
    DataFindFileQueryResponseToJSON,
} from './DataFindFileQueryResponse';
import type { FindLeaveReasonQueryResponse } from './FindLeaveReasonQueryResponse';
import {
    FindLeaveReasonQueryResponseFromJSON,
    FindLeaveReasonQueryResponseFromJSONTyped,
    FindLeaveReasonQueryResponseToJSON,
} from './FindLeaveReasonQueryResponse';

/**
 * 
 * @export
 * @interface FindLeaveQueryResponse
 */
export interface FindLeaveQueryResponse {
    /**
     * 
     * @type {string}
     * @memberof FindLeaveQueryResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof FindLeaveQueryResponse
     */
    status: string;
    /**
     * 
     * @type {boolean}
     * @memberof FindLeaveQueryResponse
     */
    halfDay: boolean;
    /**
     * 
     * @type {string}
     * @memberof FindLeaveQueryResponse
     */
    turn: string | null;
    /**
     * 
     * @type {string}
     * @memberof FindLeaveQueryResponse
     */
    initialDate: string;
    /**
     * 
     * @type {string}
     * @memberof FindLeaveQueryResponse
     */
    endDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FindLeaveQueryResponse
     */
    comment?: string | null;
    /**
     * 
     * @type {Array<CalendarDay>}
     * @memberof FindLeaveQueryResponse
     */
    calendar: Array<CalendarDay>;
    /**
     * 
     * @type {FindLeaveReasonQueryResponse}
     * @memberof FindLeaveQueryResponse
     */
    leaveReason?: FindLeaveReasonQueryResponse;
    /**
     * 
     * @type {DataFindFileQueryResponse}
     * @memberof FindLeaveQueryResponse
     */
    files?: DataFindFileQueryResponse;
}

/**
 * Check if a given object implements the FindLeaveQueryResponse interface.
 */
export function instanceOfFindLeaveQueryResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "halfDay" in value;
    isInstance = isInstance && "turn" in value;
    isInstance = isInstance && "initialDate" in value;
    isInstance = isInstance && "calendar" in value;

    return isInstance;
}

export function FindLeaveQueryResponseFromJSON(json: any): FindLeaveQueryResponse {
    return FindLeaveQueryResponseFromJSONTyped(json, false);
}

export function FindLeaveQueryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindLeaveQueryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'status': json['status'],
        'halfDay': json['halfDay'],
        'turn': json['turn'],
        'initialDate': json['initialDate'],
        'endDate': !exists(json, 'endDate') ? undefined : json['endDate'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'calendar': ((json['calendar'] as Array<any>).map(CalendarDayFromJSON)),
        'leaveReason': !exists(json, 'leaveReason') ? undefined : FindLeaveReasonQueryResponseFromJSON(json['leaveReason']),
        'files': !exists(json, 'files') ? undefined : DataFindFileQueryResponseFromJSON(json['files']),
    };
}

export function FindLeaveQueryResponseToJSON(value?: FindLeaveQueryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'status': value.status,
        'halfDay': value.halfDay,
        'turn': value.turn,
        'initialDate': value.initialDate,
        'endDate': value.endDate,
        'comment': value.comment,
        'calendar': ((value.calendar as Array<any>).map(CalendarDayToJSON)),
        'leaveReason': FindLeaveReasonQueryResponseToJSON(value.leaveReason),
        'files': DataFindFileQueryResponseToJSON(value.files),
    };
}

