/* eslint-disable @typescript-eslint/no-explicit-any */
import words from 'lodash/words'
import { FindMeAccountQueryResponse } from 'viterbit-api-sdk'

import { User } from '../model/User.model'
import stringToColor from '../../shared/utils/stringToColor'

const userMapper = {
    toModel: (dto: FindMeAccountQueryResponse): User => {
        const usernameWords =  words(dto.email.split('@')[0])
        const initials: string = usernameWords.map((word: string) => word[0]).join('')
        return {
            ...(dto as any),
            color: stringToColor(dto.email),
            name: usernameWords.join(' '),
            initials,
            isActive: ['IN_TRIAL', 'ACTIVE'].includes(dto.subscriptionStatus),
        }
    }
}

export default userMapper
