import * as React from 'react'

import {ListItemSkeletonProps} from './List.d'
import ListItem from './ListItem'
import ListItemText from './ListItemText'
import Skeleton from '../Skeleton'
import clsxm from '../../lib'

const ListItemSkeleton = ({ primary = true, secondary, avatar, icon, className, iconClassName, fullWidth }: ListItemSkeletonProps & { iconClassName?: string }) => (
    <ListItem className={className}>
        {avatar && <Skeleton className='w-10 h-10 mr-2 rounded-full' />}
        {icon && <Skeleton className={clsxm('w-10 h-10 mr-2 rounded-lg', iconClassName)} />}
        <ListItemText
            primary={primary && <Skeleton className={clsxm('h-[14px] mb-2', fullWidth ? 'w-[60%]' : secondary ? 'w-[20%]' : 'w-[40%]')} />}
            secondary={secondary && <Skeleton className={clsxm('h-[14px]', fullWidth ? 'w-full' : 'w-[50%]')} />}
        />
    </ListItem>
)

export default ListItemSkeleton
