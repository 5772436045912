
import { JobPosition } from '@viterbit/web-app/dataAccess/model/JobPosition.model'
import { PermissionAction } from '@viterbit/web-app/dataAccess/model/PermissionAction.model'
import i18n from '@viterbit/web-app/i18n'
import AsideCardInfo from '@viterbit/web-app/shared/display/AsideCardInfo'
import IsGranted from '@viterbit/web-app/shared/permission/IsGranted'

import JobPositionLevelDelete from './JobPositionLevelDelete'

const JobPositionLevelDeleteAction = ({ position }: { position: JobPosition }) => (
    <IsGranted id={position.id} action={PermissionAction.CORE_JOB_POSITION_DELETE}>
        <AsideCardInfo className='mb-4' title={i18n.t('dangerZone')}>
            <JobPositionLevelDelete
                position={position}
                id={position.id}
            />
        </AsideCardInfo>
    </IsGranted>
)

export default JobPositionLevelDeleteAction
