import * as Sentry from '@sentry/react'
import { CONTENT_CHANGED_QUERY_KEY } from '@viterbit/web-app/dataAccess/cacheKeys'
import { ContentChanged } from '@viterbit/web-app/dataAccess/model/ContentChanged.model'
import { queryClient } from '@viterbit/web-app/shared/lib/ReactQueryProvider'
import initialGitInfo from '@viterbit/web-app/shared/utils/gitInfo.json'

export const contentChangedDetail = async (): Promise<ContentChanged> => {
    const response = await window.fetch('/generated/gitInfo.json', {
        method: 'GET',
        headers: {
            'content-type': 'application/json;charset=UTF-8',
        },
        cache: 'no-cache',
    })

    const data = (await response.json()) as Omit<ContentChanged, 'changes'>
    const changes = initialGitInfo.lastCommit.hash !== data.lastCommit.hash
    const returnData = { ...data, changes }

    Sentry.setContext('git-info', returnData)

    return returnData
}

export const isContentChanged = () =>
    queryClient.getQueryData<ContentChanged>([
        CONTENT_CHANGED_QUERY_KEY,
        'detail',
    ])?.changes
