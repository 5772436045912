import * as React from 'react'
import Tooltip from 'ui/src/components/Tooltip'

type RelevantDaysTooltipProps = {
    children: React.ReactNode
    title: React.ReactNode
}

const RelevantDaysTooltip = ({ children, title }: RelevantDaysTooltipProps) => (
    <Tooltip
        tooltipClass='bg-white z-50 max-w-[250px] text-gray-800 rounded-lg py-2 px-4 relative shadow-xl border-gray-100 radius-rounded'
        arrowClassColor='white'
        arrowClassColorDark='white'
        title={<div className='flex flex-col gap-2'>{title}</div>}
    >
        {children}
    </Tooltip>
)

export default RelevantDaysTooltip
