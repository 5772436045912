import { useQuery } from '@tanstack/react-query'

import { EMPLOYEE_QUERY_KEY } from '../../cacheKeys'
import { employeeCounterList } from '../../service/employeeService'

type EmployeeListConfig = {
    query: Parameters<typeof employeeCounterList>[0]
    enabled?: boolean
}

export const useEmployeeCounterList = (config: EmployeeListConfig) => {
    const { query, enabled } = config || {}

    return useQuery({
        queryKey: [EMPLOYEE_QUERY_KEY, 'list-counters', query],
        queryFn: () => employeeCounterList(query),
        enabled,
    })
}

