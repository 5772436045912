/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { PermissionAction } from '@viterbit/web-app/dataAccess/model/PermissionAction.model'
import { useEmployeeDetail } from '@viterbit/web-app/dataAccess/useCase/employee/useEmployeeDetail'
import { useLeaveList } from '@viterbit/web-app/dataAccess/useCase/leave/useLeaveList'
import SimpleCard from '@viterbit/web-app/shared/display/SimpleCard'
import useHasSinglePermission from '@viterbit/web-app/shared/permission/useHasSinglePermission'
import * as React from 'react'

import CalendarLeave from './Calendar/CalendarLeave'
import { flatLeavesDates } from './helpers'
import { RelevantDate } from './types'
import CalendarLoading from '../../settings/locations/holidays/detail/CalendarLoading'

type CalendarViewProps = {
    header: React.ReactNode
    employeeId: string
    year: number
    className?: string
}


const CalendarView = ({
    employeeId,
    year,
    header,
    className,
}: CalendarViewProps) => {
    const { data: employeeInfo } = useEmployeeDetail({
        query: {
            id: employeeId!,
        }
    })

    const { data, isLoading } = useLeaveList({
        query: {
            employeeId: employeeId!,
            year,
        },
        enabled: !!(employeeId && year),
    })

    const canCreateLeave = useHasSinglePermission(PermissionAction.CORE_HR_LEAVE_CREATE, employeeId)

    if (isLoading || !data) {
        return <CalendarLoading />
    }

    const holidays =
        employeeInfo?.location?.calendar.flatMap(calendar => calendar.publicHolidays).map((holiday) => ({
            date: holiday.day,
            color: '#d1d5db',
            label: holiday.name,
            isHoliday: true,
            halfDay: false,
        })) || []

    const workableDays = employeeInfo?.leavePolicy?.daysRange || []

    const flatLeaveDates = flatLeavesDates(data)
    const relevantDates: RelevantDate[] = [...flatLeaveDates, ...holidays]

    return (
        <SimpleCard
            title={header}
        >
            <CalendarLeave
                relevantDates={relevantDates}
                year={year}
                workableDays={workableDays}
                clickable={canCreateLeave}
                employeeId={employeeId}
            />
        </SimpleCard>
    )
}

export default CalendarView
