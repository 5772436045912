/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/function-component-definition */
import { classValidatorResolver } from '@hookform/resolvers/class-validator'
import dateMapper from '@viterbit/web-app/dataAccess/mapper/date.mapper'
import { workScheduleAssign } from '@viterbit/web-app/dataAccess/service/workScheduledService'
import i18n from '@viterbit/web-app/i18n'
import { useCurrentEmployeeId } from '@viterbit/web-app/shared/employee/useCurrentEmployeeId'
import Form from '@viterbit/web-app/shared/form/Form'
import SubmitButton from '@viterbit/web-app/shared/form/SubmitButton'
import * as React from 'react'
import Button from 'ui/src/components/Button'
import Dialog from 'ui/src/components/Dialog'

import EmployeePlanningFields, { EmployeePlanningFieldsType } from '../EmployeePlanningFields'
import EmployeePlanningValidator from '../EmployeePlanningValidator'


const resolver = classValidatorResolver(EmployeePlanningValidator)

function EmployeePlanningCreateDialog({
    isOpen,
    onClose,
}:  {
    isOpen: boolean
    onClose?: () => void
}) {
    const employeeId = useCurrentEmployeeId()

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            title={i18n.t('employees:employee.planning.createButton')}
        >
            <Form<EmployeePlanningFieldsType> 
                withTransition
                formConfig={{
                    resolver,
                    mode: 'all',
                    defaultValues: {
                        type: 'CONTRACT',
                    },
                }}
                mutationConfig={{
                    mutationFn: (data) =>
                        workScheduleAssign({
                            assignWorkScheduleCommand: {
                                employeeId: employeeId!,
                                startDate: dateMapper.toDate(data.startDate)!,
                                workScheduleId: data.workScheduleId,
                            }
                        }),
                    onSuccess: onClose,
                }}
            >
                {
                    (formMethods) => (
                        <>
                            <EmployeePlanningFields formMethods={formMethods} />
                            <Button onClick={onClose}>{i18n.t('buttons.cancel')}</Button>
                            <SubmitButton data-testid="work-schedule-create-confirm">{i18n.t('buttons.create')}</SubmitButton>
                        </>
                    )
                }
            </Form>
        </Dialog>
    )
}

export default EmployeePlanningCreateDialog