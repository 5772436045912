/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-constant-condition */
import { IconMinus, IconPlus } from '@tabler/icons-react'
import * as React from 'react'

import Button from '../Button'

type FindNextConfig = {
    disabledValues?: number[]
    value: number
    step?: number
    isPrev?: boolean
}

export type CounterProps = {
    iconLeft?: React.ReactNode
    iconRight?: React.ReactNode
    onChange: (value: number) => void
    min?: number
    max?: number
} & Omit<FindNextConfig, 'isPrev'>

const styles = {
    root: 'inline-flex font-normal rounded-md shadow-sm isolate',
    button: 'relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 hover:bg-gray-50 focus:z-10 focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500',
}

export const findNextValue = (config: FindNextConfig): number => {
    const { disabledValues = [], value, step = 1, isPrev = false } = config

    let next = value
    do {
        next = isPrev ? next - step : next + step
        
        if (!disabledValues.includes(next)) {
            return next
        }
    } while (true)
}

const Counter = React.forwardRef<HTMLDivElement, CounterProps>((props, ref) => {
    const {
        iconLeft = <IconMinus className='w-5 h-5' />,
        iconRight = <IconPlus className='w-5 h-5' />,
        value,
        step = 1,
        disabledValues = [],
        min,
        max,
        onChange,
    } = props

    const handleIncrement = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault()
        const newValue = findNextValue({ value, step, disabledValues, isPrev: false })
        onChange(newValue)
    }

    const handleDecrement = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault()
        const newValue = findNextValue({ value, step, disabledValues, isPrev: true })
        onChange(newValue)
    }

    const disabledNext = max ? findNextValue({ value, step, disabledValues, isPrev: false }) > max : false
    const disabledPrev = min ? findNextValue({ value, step, disabledValues, isPrev: true }) < min : false

    return (
        <span
            ref={ref}
            className={styles.root}
        >
            <Button
                className="px-4 border-r-0 rounded-tr-none rounded-br-none"
                onClick={handleDecrement}
                disabled={disabledPrev}
            >
                {iconLeft}
            </Button>
            <span className='relative inline-flex items-center justify-center w-full px-4 py-2 -ml-px text-sm font-medium text-gray-700 bg-white border border-gray-300 hover:bg-gray-50 focus:z-10 focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500'>
                {value}
            </span>
            <Button
                className="px-4 border-l-0 rounded-tl-none rounded-bl-none"
                onClick={handleIncrement}
                disabled={disabledNext}
            >
                {iconRight}
            </Button>
        </span>
    )
})
export default Counter
