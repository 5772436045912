import { IconTrash } from '@tabler/icons-react'
import { Employee } from '@viterbit/web-app/dataAccess/model/Employee.model'
import EmployeeItem from '@viterbit/web-app/feature/employee/item/EmployeeItem'
import * as React from 'react'


type ComplaintsManagerItemProps = {
    className?: string
    item: Employee
    onDeleteItem?: (e: React.MouseEvent) => void
}

const ComplaintsManagerItem = ({
    className = '',
    item,
    onDeleteItem
}: ComplaintsManagerItemProps) => (
    <div
        data-testid="complaints-manager-item"
        className={`p-3 border border-gray-300 flex justify-between items-center rounded-md shadow-sm ${className} bg-white`}
    >
        <EmployeeItem employee={item}/>
        <IconTrash
            className='cursor-pointer'
            onClick={onDeleteItem}
        />
    </div>
)

export default ComplaintsManagerItem
