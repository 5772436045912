/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { IconCalendarPlus } from '@tabler/icons-react'
import { PermissionAction } from '@viterbit/web-app/dataAccess/model/PermissionAction.model'
import useLeaveDialog, { useLeaveDialogState } from '@viterbit/web-app/feature/leave/useLeaveDialog'
import i18n from '@viterbit/web-app/i18n'
import ButtonCreate from '@viterbit/web-app/shared/form/ButtonCreate'
import IsGranted from '@viterbit/web-app/shared/permission/IsGranted'
import * as React from 'react'

import useGetEmployeeId from '../../shared/employee/useGetEmployeeId'

type LeavesContextValue = ReturnType<typeof useLeaveDialog>

export const LeaveContext = React.createContext<Omit<LeavesContextValue, 'dialog'>>({
    action: useLeaveDialogState,
    resetAction: () => { console.warn('resetAction is not defined') },
    onItemClicked: () => { console.warn('onItemClicked is not defined') },
})

export const LeaveCreateButton = () => {
    const { onItemClicked } = React.useContext(LeaveContext)
    const {employeeId} = useGetEmployeeId()

    return (
        <IsGranted
            id={employeeId}
            action={PermissionAction.CORE_HR_LEAVE_CREATE}
        >
            <ButtonCreate
                IconComponent={IconCalendarPlus}
                onClick={() => onItemClicked(employeeId)}
                data-testid="button-request-leave"
                title={i18n.t('leaves:createDialog.title')}
            >
            </ButtonCreate>
        </IsGranted>
    )
}

const LeaveProvider = ({ children }: { children: React.ReactNode }) => {
    const { dialog, ...rest } = useLeaveDialog()

    return (
        <LeaveContext.Provider value={rest}>
            {dialog}
            {children}
        </LeaveContext.Provider>
    )
}

export default LeaveProvider
