import { Control, useFormState } from 'react-hook-form'

import FormControl, { FormControlBaseProps } from './FormControl'

export type ControlledFormControlProps = Omit<FormControlBaseProps, 'errors'> & { control: Control }

const ControlledFormControl = (props: ControlledFormControlProps) => {
    const { control, ...rest } = props
    const { errors } = useFormState({ control })

    return (
        <FormControl {...rest} errors={errors}/>
    )
}

export default ControlledFormControl
