import i18n from '@viterbit/web-app/i18n'
import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import Button from 'ui/src/components/Button'
import { FormInput } from 'ui/src/components/Form'

type JustificationSectionProps = {
    disabled?: boolean
    showJustifications?: boolean
    className?: string
}

const JustificationSection = ({
    className,
    disabled,
    showJustifications: showJustificationProps,
}: JustificationSectionProps) => {
    const [showJustifications, setShowJustifications] = React.useState(
        showJustificationProps
    )
    const { control } = useFormContext()

    return (
        <div className={className}>
            {showJustifications ? (
                <FormInput
                    disabled={disabled}
                    textArea
                    label={i18n.t('leaves:justificationSection.comment.label')}
                    placeholder={i18n.t('leaves:justificationSection.comment.placeholder')}
                    name='comment'
                    control={control}
                />
            ) : (
                <>
                    <Button
                        disabled={disabled}
                        block
                        onClick={() => setShowJustifications(true)}
                        variant='twoTone'
                    >
                        {i18n.t('leaves:justificationSection.button')}
                    </Button>
                </>
            )}
        </div>
    )
}

export default JustificationSection
