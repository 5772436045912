/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react'

import { DayProps, payload, view } from './sharedTypes'
import DateTable from './tables/DateTable'
import MonthTable from './tables/MonthTable'
import YearTable from './tables/YearTable'
import { MAX_YEAR, MIN_YEAR } from './utils'
import useControllableState from '../../hooks/useControllableState'
import { clsxm } from '../../lib/clsxm'

const classes = {
    view: 'min-w-[260px] w-full flex gap-6',
}

const focusOnNextFocusableDay = (
    direction: 'left' | 'right' | 'up' | 'down',
    monthIndex: number,
    payload: payload,
    n = 1,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    daysRefs: React.MutableRefObject<any[][]>
) => {
    const changeRow = ['down', 'up'].includes(direction)

    const rowIndex = changeRow
        ? payload.rowIndex + (direction === 'down' ? n : -n)
        : payload.rowIndex

    const cellIndex = changeRow
        ? payload.cellIndex
        : payload.cellIndex + (direction === 'right' ? n : -n)

    const dayToFocus = daysRefs.current[monthIndex][rowIndex][cellIndex]

    if (!dayToFocus) {
        return
    }

    if (dayToFocus.disabled) {
        focusOnNextFocusableDay(direction, monthIndex, payload, n + 1, daysRefs)
    } else {
        dayToFocus.focus()
    }
}

export type CalendarProps = {
    className?: string
    dateViewCount?: number
    labelFormat?:
        | string
        | {
              month: string
              year: string
          }
    dayClassName?: (date?: Date, dayProps?: DayProps) => string | string
    dayStyle?: (date?: Date, dayProps?: DayProps) => React.CSSProperties
    defaultMonth?: Date
    defaultView?: view
    disableDate?: (date: Date) => boolean
    disableOutOfMonth?: boolean
    enableHeaderLabel?: boolean
    hideOutOfMonthDates?: boolean
    hideWeekdays?: boolean
    isDateFirstInRange?: (date?: Date, dayProps?: DayProps) => boolean
    isDateInRange?: (date?: Date, dayProps?: DayProps) => boolean
    isDateLastInRange?: (date?: Date, dayProps?: DayProps) => boolean
    monthLabelFormat?: string
    yearLabelFormat?: string
    monthFormat?: string
    yearFormat?: string
    locale: 'en' | 'es'
    maxDate?: Date
    minDate?: Date
    month?: Date
    onChange: (date: Date | (Date | null)[]) => void
    onDayMouseEnter?: (
        date: Date | undefined,
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => void
    onMonthChange?: (date: Date) => void
    onMouseLeave?: (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => void
    paginateBy?: number
    preventFocus?: boolean
    range?: Date[] | undefined
    renderDay?: (value: Date | undefined) => React.ReactNode
    style?: React.CSSProperties
    value?: Date[] | Date | null
    weekdayLabelFormat?: string
    weekendDays?: number[]
    firstDayOfWeek?: 'monday' | 'sunday'
    label?: string
    nextLabel?: string
    previousLabel?: string
}

const CalendarBase = React.forwardRef<HTMLDivElement, CalendarProps>(
    (props, ref) => {
        const {
            className,
            defaultView = 'date',
            defaultMonth = new Date(),
            dateViewCount = 1,
            month,
            value,
            locale,
            minDate,
            maxDate,
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onMonthChange = () => {},
            onChange,
            preventFocus,
            renderDay,
            monthFormat = 'MMM',
            yearFormat = 'YYYY',
            monthLabelFormat = 'MMMM',
            yearLabelFormat = 'YYYY',
            weekdayLabelFormat = 'dd',
            enableHeaderLabel = true,
            paginateBy = dateViewCount,
            weekendDays,
            hideWeekdays,
            hideOutOfMonthDates,
            isDateInRange,
            isDateFirstInRange,
            isDateLastInRange,
            onDayMouseEnter,
            disableDate,
            disableOutOfMonth,
            dayClassName,
            dayStyle,
            firstDayOfWeek = 'monday',
            range,
            label = 'label',
            nextLabel = 'next',
            previousLabel = 'previous',
        } = props

        const [selectionState, setSelectionState] = React.useState(defaultView)

        const daysRefs = React.useRef(
            Array(dateViewCount)
                .fill(0)
                .map(() => [])
        )

        const [_month, setMonth] = useControllableState({
            prop: month,
            defaultProp: defaultMonth !== undefined ? defaultMonth : new Date(),
            onChange: onMonthChange,
        })

        const [yearSelection, setYearSelection] = React.useState(
            _month.getFullYear() || new Date().getFullYear()
        )

        const [monthSelection, setMonthSelection] = React.useState(
            _month.getMonth() || new Date().getMonth()
        )

        const minYear = minDate?.getFullYear() || MIN_YEAR
        const maxYear = maxDate?.getFullYear() || MAX_YEAR

        const DAYS_PER_ROW = 6

        const handleDayKeyDown = (
            monthIndex: number,
            payload: payload,
            event: React.KeyboardEvent
        ) => {
            switch (event.key) {
            case 'ArrowDown': {
                event.preventDefault()

                const hasRowBelow =
                        payload.rowIndex + 1 <
                        daysRefs.current[monthIndex].length
                if (hasRowBelow) {
                    focusOnNextFocusableDay(
                        'down',
                        monthIndex,
                        payload,
                        1,
                        daysRefs
                    )
                }
                break
            }
            case 'ArrowUp': {
                event.preventDefault()

                const hasRowAbove = payload.rowIndex > 0
                if (hasRowAbove) {
                    focusOnNextFocusableDay(
                        'up',
                        monthIndex,
                        payload,
                        1,
                        daysRefs
                    )
                }
                break
            }
            case 'ArrowRight': {
                event.preventDefault()

                const isNotLastCell = payload.cellIndex !== DAYS_PER_ROW
                if (isNotLastCell) {
                    focusOnNextFocusableDay(
                        'right',
                        monthIndex,
                        payload,
                        1,
                        daysRefs
                    )
                } else if (monthIndex + 1 < dateViewCount) {
                    if (daysRefs.current[monthIndex + 1][payload.rowIndex])
                        (
                                daysRefs.current[monthIndex + 1][
                                    payload.rowIndex
                                ][0] as any
                        )?.focus()
                }
                break
            }
            case 'ArrowLeft': {
                event.preventDefault()

                if (payload.cellIndex !== 0) {
                    focusOnNextFocusableDay(
                        'left',
                        monthIndex,
                        payload,
                        1,
                        daysRefs
                    )
                } else if (monthIndex > 0) {
                    if (daysRefs.current[monthIndex - 1][payload.rowIndex])
                        (
                                daysRefs.current[monthIndex - 1][
                                    payload.rowIndex
                                ][
                                    DAYS_PER_ROW
                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                ] as any
                        )?.focus()
                }
                break
            }
            default:
                break
            }
        }

        return (
            <div ref={ref} className={clsxm(classes.view, className)}>
                {selectionState === 'year' && (
                    <YearTable
                        year={yearSelection}
                        minYear={minYear}
                        maxYear={maxYear}
                        onChange={(year) => {
                            setMonth(new Date(year, monthSelection, 1))
                            setYearSelection(year)
                            setSelectionState('date')
                        }}
                        className={className}
                        preventFocus={preventFocus}
                        yearLabelFormat={yearFormat}
                    />
                )}
                {selectionState === 'month' && (
                    <MonthTable
                        value={{
                            month: _month.getMonth(),
                            year: _month.getFullYear(),
                        }}
                        year={yearSelection}
                        onYearChange={setYearSelection}
                        onNextLevel={() => setSelectionState('year')}
                        locale={locale}
                        minDate={minDate}
                        maxDate={maxDate}
                        onChange={(monthValue) => {
                            setMonth(new Date(yearSelection, monthValue, 1))
                            setMonthSelection(monthValue)
                            setSelectionState('date')
                        }}
                        className={className}
                        preventFocus={preventFocus}
                        yearLabelFormat={yearFormat}
                        monthLabelFormat={monthFormat}
                    />
                )}
                {selectionState === 'date' && (
                    <DateTable
                        dateViewCount={dateViewCount}
                        paginateBy={paginateBy}
                        month={_month}
                        locale={locale}
                        minDate={minDate}
                        maxDate={maxDate}
                        enableHeaderLabel={enableHeaderLabel}
                        daysRefs={daysRefs}
                        onMonthChange={setMonth}
                        onNextLevel={(view) => setSelectionState(view)}
                        onDayKeyDown={handleDayKeyDown}
                        dayClassName={dayClassName}
                        dayStyle={dayStyle}
                        disableOutOfMonth={disableOutOfMonth}
                        disableDate={disableDate}
                        hideWeekdays={hideWeekdays}
                        preventFocus={preventFocus}
                        firstDayOfWeek={firstDayOfWeek}
                        value={value}
                        range={range}
                        onChange={onChange}
                        monthLabelFormat={monthLabelFormat}
                        yearLabelFormat={yearLabelFormat}
                        weekdayLabelFormat={weekdayLabelFormat}
                        onDayMouseEnter={onDayMouseEnter}
                        renderDay={renderDay}
                        hideOutOfMonthDates={hideOutOfMonthDates}
                        isDateInRange={isDateInRange}
                        isDateFirstInRange={isDateFirstInRange}
                        isDateLastInRange={isDateLastInRange}
                        weekendDays={weekendDays}
                        label={label}
                        nextLabel={nextLabel}
                        previousLabel={previousLabel}
                    />
                )}
            </div>
        )
    }
)

export default CalendarBase
