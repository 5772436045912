/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { classValidatorResolver } from '@hookform/resolvers/class-validator'
import { LocaleSelectOptions, TimezoneSelectOptions } from '@viterbit/web-app/dataAccess/dto'
import { Account } from '@viterbit/web-app/dataAccess/model/Account.model'
import { accountUpdate } from '@viterbit/web-app/dataAccess/service/accountService'
import i18n from '@viterbit/web-app/i18n'
import SimpleCard from '@viterbit/web-app/shared/display/SimpleCard'
import Form from '@viterbit/web-app/shared/form/Form'
import SubmitButton from '@viterbit/web-app/shared/form/SubmitButton'
import * as React from 'react'
import { FormSelect } from 'ui/src/components/Form'

import { AccountSettingsPersonalValidator } from './AccountSettingsValidator'

export type AccountSettingsPersonalFormFields = {
    id: string
    locale: string
    timezone: string
}

export type ProfilePersonalSettingsFormProps = {
    account: Account,
    onSuccessUpdate: () => void
}

const resolver = classValidatorResolver(AccountSettingsPersonalValidator)

const getFormDefaultValues = (account: Account): AccountSettingsPersonalFormFields => ({
    id: account.id,
    locale: account.locale ?? '',
    timezone: account.timezone ?? '',
})

const ProfilePersonalSettingsForm = ({
    account,
    onSuccessUpdate
}: ProfilePersonalSettingsFormProps) => {
    const defaultValues = React.useMemo(() => getFormDefaultValues(account), [account])

    return (
        <SimpleCard padding>
            <Form<AccountSettingsPersonalFormFields>
                smallButtons
                withTransition
                mutationConfig={{
                    mutationFn: (data) => accountUpdate({
                        id: account.id,
                        updateAccountCommand: data
                    }),
                    onSuccess: async () => {
                        await onSuccessUpdate()
                        window.location.reload()
                    }
                }}
                formConfig={{
                    resolver,
                    mode: 'all',
                    defaultValues,
                }}
            >
                {(form) => (
                    <>
                        <FormSelect
                            name='locale'
                            control={form.control}
                            placeholder={i18n.t('settings:personalSection.selectLocalePlaceholder')}
                            label={i18n.t('settings:personalSection.selectLocaleLabel')}
                            options={LocaleSelectOptions}
                        />

                        <FormSelect
                            name='timezone'
                            control={form.control}
                            placeholder={i18n.t('settings:personalSection.selectTimezonePlaceholder')}
                            label={i18n.t('settings:personalSection.selectTimezoneLabel')}
                            options={TimezoneSelectOptions}
                        />

                        <SubmitButton>{i18n.t('common:buttons.save')}</SubmitButton>
                    </>
                )}
            </Form>
        </SimpleCard>
    )
}

export default ProfilePersonalSettingsForm
