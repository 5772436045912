import TwoColumnsLayout from '@viterbit/web-app/shared/layout/TwoColumnsLayout'
import * as React from 'react'
import { Outlet } from 'react-router-dom'

const BillingPaymentLayout = () => (
    <TwoColumnsLayout>
        <Outlet />
    </TwoColumnsLayout>
)

export default BillingPaymentLayout

