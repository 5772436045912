import { classValidatorResolver } from '@hookform/resolvers/class-validator'
import { Employee, EmployeeEmergencyInformation } from '@viterbit/web-app/dataAccess/model/Employee.model'
import { employeeUpdate } from '@viterbit/web-app/dataAccess/service/employeeService'
import i18n from '@viterbit/web-app/i18n'
import Form from '@viterbit/web-app/shared/form/Form'
import SubmitButton from '@viterbit/web-app/shared/form/SubmitButton'
import * as React from 'react'
import { FormInput } from 'ui/src/components/Form'
import clsxm from 'ui/src/lib'

import EmployeePersonalEmergencyValidator from './EmployeePersonalEmergencyValidator'

type EmployeePersonalEmergencyFormProps = {
    employee: Employee
    className?: string
}

const resolver = classValidatorResolver(EmployeePersonalEmergencyValidator)

const EmployeePersonalEmergencyForm = ({ employee, className }: EmployeePersonalEmergencyFormProps) => (
    <div>
        <Form<EmployeeEmergencyInformation>
            smallButtons
            withTransition
            mutationConfig={{
                mutationFn: (emergencyInformation) =>
                    employeeUpdate({
                        id: employee.id,
                        updateEmployeeCommand: {
                            emergencyEmail: emergencyInformation.email,
                            emergencyName: emergencyInformation.name,
                            emergencyPhoneNumber: emergencyInformation.phoneNumber,
                        }
                    })
            }}
            formConfig={{
                resolver,
                mode: 'all',
                defaultValues: employee.emergencyInformation,
            }}
            className={clsxm(

                className
            )}
        >
            {(form) => (
                <>
                    <FormInput
                        data-testid="input-emergency-name"
                        name='name'
                        label={i18n.t('personalArea:profile.emergencyInformation.form.name.label')}
                        control={form.control}
                        placeholder={i18n.t('personalArea:profile.emergencyInformation.form.name.placeholder')}
                    />
                    <FormInput
                        data-testid='input-emergency-email'
                        name='email'
                        label={i18n.t('personalArea:profile.emergencyInformation.form.email.label')}
                        control={form.control}
                        placeholder={i18n.t('personalArea:profile.emergencyInformation.form.email.placeholder')}
                    />
                    <FormInput
                        data-testid='input-emergency-phoneNumber'
                        name='phoneNumber'
                        label={i18n.t('personalArea:profile.emergencyInformation.form.phoneNumber.label')}
                        control={form.control}
                        placeholder={i18n.t('personalArea:profile.emergencyInformation.form.phoneNumber.placeholder')}
                    />

                    <SubmitButton data-testid="employee-emergency-contact-form-submit-button">{i18n.t('common:buttons.save')}</SubmitButton>
                </>
            )}
        </Form>
    </div>
)

export default EmployeePersonalEmergencyForm
