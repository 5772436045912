export const WorkScheduleTypes = ['FLEXIBLE', 'FIXED'] as const

export const WorkScheduleDays = ['monday','tuesday','wednesday','thursday','friday','saturday','sunday'] as const

export interface WorkScheduleDayPeriod {
    from?: string
    to?: string
    fixed?: string
}

export type WorkScheduleWeekPeriod = Record<typeof WorkScheduleDays[number], WorkScheduleDayPeriod[] | null>

export interface WorkSchedulePeriod {
    startDate: Date
    endDate: Date
    schedule: WorkScheduleWeekPeriod
}

export interface WorkSchedule {
    id: string
    name: string
    type: typeof WorkScheduleTypes[number]
    periods: WorkSchedulePeriod[]
    mainPeriod: WorkSchedulePeriod
}
