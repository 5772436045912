import i18n from '@viterbit/web-app/i18n'
import SectionLayout from '@viterbit/web-app/shared/layout/SectionLayout'
import * as React from 'react'

import TimeTrackingTeamList from './TimeTrackingTeamList'

const TimeTrackingTeamView =  () => (
    <SectionLayout
        title={i18n.t('employees:teamTimeline.header')}
    >
        <TimeTrackingTeamList />
    </SectionLayout>
)

export default TimeTrackingTeamView

