/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react'
import { clsxm } from 'ui/src/lib/clsxm'

import HeaderActionsTitle from './HeaderActionsTitle'

const STYLES = {
    root: 'flex-auto flex justify-center md:justify-stretch flex-col flex-1 line-clamp-1',
}

type HeaderActionsHeaderProps = {
    children?: React.ReactNode
    className?: string
    Component?: any
}

const HeaderActionsHeader = ({
    Component = 'main',
    children,
    className = '',
}: HeaderActionsHeaderProps) => (
    <Component className={clsxm(STYLES.root, className)}>{children}</Component>
)

HeaderActionsHeader.Title = HeaderActionsTitle

export default HeaderActionsHeader
