/* eslint-disable @typescript-eslint/no-explicit-any */

import { classValidatorResolver } from '@hookform/resolvers/class-validator'
import { Employee,EmployeeContractHoursPeriod } from '@viterbit/web-app/dataAccess/model/Employee.model'
import { employeeUpdate } from '@viterbit/web-app/dataAccess/service/employeeService'
import i18n from '@viterbit/web-app/i18n'
import SimpleCard from '@viterbit/web-app/shared/display/SimpleCard'
import Form from '@viterbit/web-app/shared/form/Form'
import SubmitButton from '@viterbit/web-app/shared/form/SubmitButton'
import pick from 'lodash/pick'
import * as React from 'react'
import { FormInput, FormSelect } from 'ui/src/components/Form'

import { EmployeeContractWorkValidator } from './EmployeeContractWorkValidator'

const EmployeeWorkContractFieldNames = ['contractHoursPeriod', 'contractHoursAmount'] as const

export type EmployeeContractWorkFields = Pick<Employee, typeof EmployeeWorkContractFieldNames[number]>

export type EmployeeWorkContractFormProps = {
    employee: Employee
}

const resolver = classValidatorResolver(EmployeeContractWorkValidator)

const getFormDefaultValues = (employee: Employee): EmployeeContractWorkFields => pick(employee, EmployeeWorkContractFieldNames)

const contractHoursPeriodOptions = EmployeeContractHoursPeriod.map((contractHoursPeriod) => ({
    value: contractHoursPeriod,
    label: i18n.t(`employees:employee.form.contractHoursPeriod.options.${contractHoursPeriod}`),
}))

const EmployeeContractWorkForm = (props: EmployeeWorkContractFormProps) => {
    const { employee } = props

    const defaultValues = React.useMemo(() => getFormDefaultValues(employee), [employee])

    return (
        <SimpleCard padding>
            <Form<EmployeeContractWorkFields>
                smallButtons
                withTransition
                mutationConfig={{
                    mutationFn: (data) => 
                        employeeUpdate({
                            id: employee.id,
                            updateEmployeeCommand: {
                                contractHours: data.contractHoursPeriod || null as any,
                                hours: data.contractHoursAmount ? parseFloat(`${data.contractHoursAmount}`): null,
                            }
                        })
                }}
                formConfig={{
                    resolver,
                    mode: 'all',
                    defaultValues,
                }}
            >
                {(form) => (
                    <>
                        <FormSelect
                            isClearable
                            name='contractHoursPeriod'
                            control={form.control}
                            options={contractHoursPeriodOptions}
                            label={i18n.t('employees:employee.form.contractHoursPeriod.label')}
                            placeholder={i18n.t('employees:employee.form.contractHoursPeriod.placeholder')}
                        />

                        <FormInput
                            type='number'
                            name='contractHoursAmount'
                            label={i18n.t('employees:employee.form.contractHoursAmount.label')}
                            placeholder={i18n.t('employees:employee.form.contractHoursAmount.placeholder')}
                            control={form.control}
                        />

                        <SubmitButton
                            data-testid="employee-work-schedule-submit-button"
                        >
                            {i18n.t('employees:employee.buttons.save')}
                        </SubmitButton>
                    </>
                )
                }
            </Form>
        </SimpleCard>
    )
}

export default EmployeeContractWorkForm
