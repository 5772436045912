/* eslint-disable @typescript-eslint/no-explicit-any */

import {
    ConditionFieldBase,
    ConditionTypeBase,
    ConditionValidationBase,
} from './ConditionBase.model'

export const ConditionDateFields = [] as const
export type ConditionDateField = typeof ConditionDateFields[number]

export const ConditionFieldDateTypes = ['date'] as const
export type ConditionFieldDateType = typeof ConditionFieldDateTypes[number]

export const ConditionDateOperators = [
    'lowerThan',
    'lowerThanOrEquals',
    'greaterThan',
    'greaterThanOrEquals',
    'dateRelativeBefore',
    'dateRelativeAfter',
] as const

export type ConditionDateOperator = typeof ConditionDateOperators[number]
export type ConditionTypeDate = ConditionTypeBase<string> & {
  [key in ConditionDateOperator]?: string
}

export type ConditionValidationDate = ConditionValidationBase<
  ConditionDateOperator,
  string
>

export type ConditionFieldDate = ConditionFieldBase<
  ConditionDateField,
  ConditionFieldDateType,
  ConditionValidationDate
>

export type ConditionFieldDateProperties = {
    [key in ConditionDateField]: string
}

export const isConditionDateField = (
    conditionField: any
): conditionField is ConditionFieldDate =>
    ConditionFieldDateTypes.includes(conditionField.type as any)

export const ConditionDateField: ConditionFieldDate = {
    name: null as never,
    type: 'date',
    validations: [
        {
            name: 'greaterThan',
            value: ['2021-01-01'],
        },
    ],
}
