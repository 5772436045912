
import type { SpotlightAction } from '@mantine/spotlight'
import { IconCircleChevronRight } from '@tabler/icons-react'
import { NavigationItem, NavigationItemMeta, navigationItems } from '@viterbit/web-app/routes'
import uniqBy from 'lodash/uniqBy'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import { useUser } from '../employee/useUser'
import { User } from '../../dataAccess/model/User.model'

type SpotlightActionsReturn = {
    actions: SpotlightAction[]
}

type RouteDefinition = {
    path: string
} & NavigationItemMeta

const getFlattenRoutes = (routes: NavigationItem[], href = ''): RouteDefinition[] => {
    const flattenRoutes = routes.flatMap<RouteDefinition>((item) => {
        const path = item.index || !item.path ? href: `${href}/${item.path}`
        const items: RouteDefinition[] = []

        if (item.children) {
            items.push(...getFlattenRoutes(item.children, path))
        }

        items.push({
            path,
            ...item.handle,
        })

        return items
    })

    return flattenRoutes
}

const getFilteredRoutes = (routes: RouteDefinition[], user: User) => {
    let filteredRoutes = routes
    
    filteredRoutes = filteredRoutes.filter((route) => !route.path.match(new RegExp(('[*:]'), 'i')))
    filteredRoutes = filteredRoutes.filter((route) => route.permission ? user.menu[route.permission]: true)
    filteredRoutes = filteredRoutes.filter((route) => !route.disabled)
    filteredRoutes = filteredRoutes.filter((route) => route.path)
    filteredRoutes = filteredRoutes.filter((route) => route.title)

    return filteredRoutes
}

const defaultIcon = <IconCircleChevronRight />

const mapRoutesToActions = (routes: RouteDefinition[], onTrigger: SpotlightAction['onTrigger'])  => {
    const actions = routes.map<SpotlightAction>((route) => ({
        title: route.title!,
        description: route.description,
        href: route.path,
        icon: route.icon || defaultIcon,
        exact: route.exact,
        closeOnTrigger: true,
        onTrigger
    }))

    return actions
}

const getActionRoutes = (user, onTrigger: SpotlightAction['onTrigger']) => {
    const flattenRoutes = getFlattenRoutes(navigationItems)
    const filteredRoutes = getFilteredRoutes(flattenRoutes, user)
    const mappedRoutes = mapRoutesToActions(filteredRoutes, onTrigger)
    const uniqueRoutes = uniqBy(mappedRoutes, 'href')
    const uniqueTitles = uniqBy(uniqueRoutes, 'title')

    return uniqueTitles
}


const useSpotlightActions = () => {
    const user = useUser()
    const navigate = useNavigate()

    const onTrigger = React.useCallback((action: SpotlightAction) => {
        navigate(action.href)
    }, [navigate])

    const actions = React.useMemo(() => getActionRoutes(user, onTrigger), [user, onTrigger])

    return {
        actions
    }
}


export default useSpotlightActions