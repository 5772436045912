import { Planning, PlanningTypes } from '@viterbit/web-app/dataAccess/model/Planning.model'
import { WorkSchedule } from '@viterbit/web-app/dataAccess/model/WorkSchedule.model'
import i18n from '@viterbit/web-app/i18n'
import FormDatePicker from '@viterbit/web-app/shared/form/FormDatePicker'
import FormSelectWorkSchedule from '@viterbit/web-app/shared/form/FormSelectWorkSchedule'
import * as React from 'react'
import { UseFormReturn } from 'react-hook-form'
import { FormSelect } from 'ui/src/components/Form'

export type EmployeePlanningFieldsType = {
    workScheduleId: string | null
    startDate: Date
} & Pick<Planning, 'type'>

type EmployeePlanningFieldsProps = {
    children?: React.ReactNode
    className?: string
    formMethods: UseFormReturn<EmployeePlanningFieldsType>
    workScheduleDefaultOption?: WorkSchedule | null
    isEdit?: boolean
}

const styles = {
    root: ''
}

const planningTypeOptions = PlanningTypes.map((type) => ({
    value: type,
    label: i18n.t(`employees:employee.planning.fields.type.options.${type}`),
}))

const EmployeePlanningFields = (props: EmployeePlanningFieldsProps) => {
    const { formMethods, workScheduleDefaultOption, isEdit } = props

    const type = formMethods.watch('type')

    return (
        <>
            {!isEdit && <FormSelect
                asterisk
                name='type'
                control={formMethods.control}
                label={i18n.t('employees:employee.planning.fields.type.label')}
                placeholder={i18n.t('employees:employee.planning.fields.type.placeholder')}
                defaultValue={planningTypeOptions[0]}
                options={planningTypeOptions}
            />}

            {type === 'WORK_SCHEDULE' && !isEdit && (
                <FormSelectWorkSchedule
                    asterisk
                    name='workScheduleId'
                    label={i18n.t('employees:employee.planning.fields.workScheduleId.label')}
                    placeholder={i18n.t('employees:employee.planning.fields.workScheduleId.placeholder')}
                    control={formMethods.control}
                    defaultValue={workScheduleDefaultOption}
                />
            )}

            <FormDatePicker
                asterisk
                name='startDate'
                label={i18n.t('employees:employee.planning.fields.startDate.label')}
                placeholder={i18n.t('employees:employee.planning.fields.startDate.placeholder')}
                control={formMethods.control}
            />
        </>

    )
}

export default EmployeePlanningFields
