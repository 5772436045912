
import { WEEK_DAYS } from '@viterbit/web-app/dataAccess/model/Week.model'
import { RelevantDate } from '@viterbit/web-app/feature/leave/list/types'
import SimpleCard from '@viterbit/web-app/shared/display/SimpleCard'
import * as React from 'react'
import clsxm from 'ui/src/lib'

import { FormFields } from './ActionSpan'
import CalendarLocation from './CalendarLocation'


type CalendarHolidaysViewProps = {
    header: React.ReactNode
    year: number
    className?: string
    onSubmit?: (data: FormFields) => void,
    holidays: RelevantDate[]
}

const workableDays = [...WEEK_DAYS]

const LocationHolidayDetailView = ({
    year,
    header,
    className,
    onSubmit,
    holidays
}: CalendarHolidaysViewProps) => (
    <SimpleCard className={clsxm('p-4 flex flex-col gap-y-4', className)} cardBodyClassName="overflow-visible">
        {header}
        <CalendarLocation
            relevantDates={holidays}
            year={year}
            workableDays={workableDays}
            clickable
            onSubmit={onSubmit}
        />
    </SimpleCard>
)

export default LocationHolidayDetailView
