/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateLeaveCommand
 */
export interface UpdateLeaveCommand {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateLeaveCommand
     */
    halfDay?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateLeaveCommand
     */
    turn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateLeaveCommand
     */
    initialDate: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLeaveCommand
     */
    endDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateLeaveCommand
     */
    comment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateLeaveCommand
     */
    leaveReasonId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateLeaveCommand
     */
    files?: Array<string>;
}

/**
 * Check if a given object implements the UpdateLeaveCommand interface.
 */
export function instanceOfUpdateLeaveCommand(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "initialDate" in value;
    isInstance = isInstance && "leaveReasonId" in value;

    return isInstance;
}

export function UpdateLeaveCommandFromJSON(json: any): UpdateLeaveCommand {
    return UpdateLeaveCommandFromJSONTyped(json, false);
}

export function UpdateLeaveCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateLeaveCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'halfDay': !exists(json, 'halfDay') ? undefined : json['halfDay'],
        'turn': !exists(json, 'turn') ? undefined : json['turn'],
        'initialDate': json['initialDate'],
        'endDate': !exists(json, 'endDate') ? undefined : json['endDate'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'leaveReasonId': json['leaveReasonId'],
        'files': !exists(json, 'files') ? undefined : json['files'],
    };
}

export function UpdateLeaveCommandToJSON(value?: UpdateLeaveCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'halfDay': value.halfDay,
        'turn': value.turn,
        'initialDate': value.initialDate,
        'endDate': value.endDate,
        'comment': value.comment,
        'leaveReasonId': value.leaveReasonId,
        'files': value.files,
    };
}

