/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react'

const map = (
    children: React.ReactNode,
    func: (child: any, index: number) => object,
    context: any
) => {
    let index = 0
    return React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) {
            return child
        }

        const resolvedChild = child.type === React.Fragment
            ? child.props.children
            : child

        const handle = func.call(context, resolvedChild, index)
        index += 1
        return handle
    })
}

const mapCloneElement = (
    children: React.ReactNode,
    func: (child: any, index: number) => object,
    context?: any
) => map(
    children,
    (child: any, index: number) =>
        React.cloneElement(child, {
            key: index,
            ...func(child, index),
        }),
    context
)

export { map, mapCloneElement }
