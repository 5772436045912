import { useWorkScheduleDetail } from '@viterbit/web-app/dataAccess/useCase/workSchedule/useWorkScheduleDetail'
import { useParams } from 'react-router-dom'

export const useWorkScheduled = () => {
    const params = useParams()
    const response = useWorkScheduleDetail({
        query: {
            id: params.id!,
        },
        enabled: !!params.id
    })

    return response
}