/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/function-component-definition */
import { Button as MantineButton } from '@mantine/core'
import {useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query'
import ApiErrorMessage from '@viterbit/web-app/shared/feedback/ApiErrorMessage'
import splitComponents from '@viterbit/web-app/shared/utils/splitComponents'
import * as React from 'react'
import { FieldValues, FormProvider, useForm, UseFormProps, UseFormReturn } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { Api } from 'types/Api'
import Button from 'ui/src/components/Button'
import useUniqueId from 'ui/src/hooks/useUniqueId'
import clsxm from 'ui/src/lib'

import FormStatusContext from './FormStatusContext'
import { IFormStatusContext } from './FormStatusContext'
import SubmitButton from './SubmitButton'
import useFormErrors from './useFormErrors'
import useSetFormErrors from './useSetFormErrors'
import GlobalErrorsMessage from '../feedback/GlobalErrorsMessage'

export interface FormProps<FormValues extends FieldValues, ResponseSuccess = Api.ResponseSuccess> {
    children: React.ReactNode | ((formMethods: UseFormReturn<FormValues>, mutationMethods: UseMutationResult<Api.ResponseSuccess, Api.ResponseError, FormValues>, formState: IFormStatusContext) => React.ReactNode)
    className?: string
    formConfig?: UseFormProps<FormValues>
    mutationConfig: UseMutationOptions<ResponseSuccess, Api.ResponseError, FormValues>,
    withTransition?: boolean
    buttons?: React.ReactNode
    smallButtons?: boolean
    allowSubmitDirty?: boolean
    resetOnSuccess?: boolean
    messageErrorClassName?: string
    buttonsClassName?: string
}

/**
 * - A component wrapper of tanstack useMutation and react-hook-form
 * 
 * - Appends a cancel button (optional) and a submit button
 * 
 * - Receives a children function that receives the form methods and the mutation methods as arguments
 * 
 * @param {function | React.ReactNode} props.children       - receive the form methods and the mutation methods
 * @param {UseFormProps} props.formConfig                   - react-hook-form config [link](https://react-hook-form.com/api#useForm)
 * @param {UseMutationOptions} props.mutationConfig         - tanstack useMutation config [link](https://react-query.tanstack.com/reference/useMutatio)
 * @param {string} props.className                          - className to be applied to the form
 * @param {boolean} props.withTransition                    - whether to use react transition or not
 * @param {boolean} props.smallButtons                      - whether to use small buttons aligned in right or not
 * 
 * @example
 * ```tsx
    <Form
        onCancel={router.back}
        cancelButtonText="Back"
        submitButtonText='Sign Up'
        mutationConfig={{
            mutationKey: ['signup'],
            mutationFn: auth.signUp,
            onSuccess: () => router.push('/auth/signup/success'),
        }}
        formConfig={{
            mode: 'all',
        }} >
        {(form, mutation) => (
            <FormInput
                required
                asterisk
                name='email'
                control={form.control}
                label='Email'
                placeholder='Enter your email'
            />
        )}
    </Form>
 * ```
 */
function Form<FormValues extends FieldValues, ResponseSuccess = Api.ResponseSuccess>({
    className,
    children,
    formConfig,
    mutationConfig,
    withTransition,
    smallButtons = false,
    allowSubmitDirty = false,
    resetOnSuccess = false,
    messageErrorClassName,
    buttonsClassName,
}: FormProps<FormValues, ResponseSuccess>) {
    const formRef = React.useRef<HTMLFormElement>(null)
    const [shouldResetForm, setShouldResetForm] = React.useState(false)
    const [isPending, startTransition] = React.useTransition()
    const onSuccessWithTransition = (data, variables, context) => {
        startTransition(() => { mutationConfig.onSuccess?.(data, variables, context) })
    }

    const formId = useUniqueId()
    
    const formMethods = useForm<FormValues>(formConfig)
    const mutation = useMutation<any, Api.ResponseError, any>({
        ...mutationConfig,
        onSuccess: (...args) => {
            setShouldResetForm(true)
            if (resetOnSuccess) {
                mutation.reset()
                formMethods.reset()
            }
            if (withTransition) return onSuccessWithTransition(...args)
            return mutationConfig.onSuccess?.(...args)
        },
    })

    const submit = React.useCallback(async () => {
        formMethods.handleSubmit(mutation.mutate as never)()
    }, [formRef])

    const { isDirty, isSubmitting, isValid } = formMethods.formState
    const { isLoading, isSuccess, error } = mutation

    const parsedErrors = useFormErrors(formMethods, error as any)
    useSetFormErrors<FormValues>(formMethods, parsedErrors.fields)

    const loading = isLoading || isSubmitting || isPending
    const dirty = allowSubmitDirty ? true : isDirty
    const shouldDisableSubmit = (!isValid || loading || !dirty ) && !isSuccess
    const formState = { loading, success: isSuccess, disableSubmit: shouldDisableSubmit, smallButtons, formRef, submit, formId }

    const renderedChildren = typeof children === 'function' ? children(formMethods, mutation, formState) : children

    const { buttons, others } = splitComponents(renderedChildren, [
        { name: 'buttons', component: Button },
        { name: 'buttons', component: SubmitButton },
        { name: 'buttons', component: MantineButton },
        { name: 'buttons', component: Link },
    ])

    React.useEffect(() => {
        if (shouldResetForm && formConfig?.defaultValues) {
            formMethods.reset(formConfig?.defaultValues as FormValues)
            mutation.reset()
            setShouldResetForm(false)
        }
    }, [formConfig?.defaultValues])

    if (import.meta.env.DEV) {
        console.log('Form defaultValues', formConfig?.defaultValues)
        console.log('Form values', formMethods.getValues())
    }

    return (
        <FormProvider {...formMethods}>
            <FormStatusContext.Provider value={formState}>
                <form
                    id={formId}
                    ref={formRef}
                    onSubmit={e => {
                        e.preventDefault()
                        e.stopPropagation()
                        formMethods.handleSubmit(mutation.mutate as never)(e)
                    }}
                    className={clsxm(className, 'min-w-[300px]')}
                >
                    {others}

                    {parsedErrors.global && <GlobalErrorsMessage className={clsxm('mt-4', messageErrorClassName)} error={parsedErrors.global} />}
                    {!parsedErrors.global && <ApiErrorMessage className={clsxm('mt-4', messageErrorClassName)} error={error} />}

                    {!!buttons?.length && (
                        <div className={clsxm('flex justify-end gap-x-2 mt-4', !smallButtons && '[&>*]:flex-1', buttonsClassName)}>
                            {buttons}
                        </div>
                    )}
                </form>
            </FormStatusContext.Provider>
        </FormProvider>
    )
}

export default Form
