

import locationMapper from '@viterbit/web-app/dataAccess/mapper/location.mapper'
import paginationMetaMapper from '@viterbit/web-app/dataAccess/mapper/paginationMeta.mapper'
import { queryClient } from '@viterbit/web-app/shared/lib/ReactQueryProvider'
import { Configuration, LocationApi } from 'viterbit-api-sdk'

import { commonConfig } from './config'
import { LOCATION_QUERY_KEY } from '../cacheKeys'

const api = new LocationApi(new Configuration(commonConfig))

export const locationInvalidate = () =>
    queryClient.invalidateQueries([LOCATION_QUERY_KEY])


export const locationCreate = (body: Parameters<typeof api.createLocation>[0]) =>
    api.createLocation(body).then((data) => {
        locationInvalidate()
        return data
    })

export const locationUpdate = (body: Parameters<typeof api.updateLocation>[0]) =>
    api.updateLocation(body).then((data) => {
        locationInvalidate()
        return data
    })

export const locationDelete = (body: Parameters<typeof api.deleteLocation>[0]) =>
    api.deleteLocation(body).then((data) => {
        locationInvalidate()
        return data
    })

export const locationList = (body: Parameters<typeof api.listLocation>[0]) =>
    api.listLocation(body).then(({data, meta}) => ({
        meta: paginationMetaMapper(meta),
        data: data.map(locationMapper.toModel)
    }))

export const locationDetail = (body: Parameters<typeof api.fetchLocation>[0]) =>
    api.fetchLocation(body).then(locationMapper.toModel)


export const locationImportHolidays = (body: Parameters<typeof api.importHolidaysLocation>[0]) =>
    api.importHolidaysLocation(body).then((data) => {
        locationInvalidate()
        return data
    })

