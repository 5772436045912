import jwtDecode, { JwtPayload } from 'jwt-decode'
import { useSearchParams } from 'react-router-dom'

import ForgotPasswordRedeemDetail from './ForgotPasswordRedeemDetail'

interface CustomJWT extends JwtPayload {
    email: string
}

type InitialProps = {
    token: string
    initialData?: CustomJWT
}

const ForgotPasswordRedeemView = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const token = searchParams.get('token') ?? ''
    const initialProps: InitialProps = { token }
    try {
        initialProps.initialData = jwtDecode(token)
    } catch (error) {
        initialProps.initialData = undefined
    }

    return <ForgotPasswordRedeemDetail {...initialProps} />
}

export default ForgotPasswordRedeemView
