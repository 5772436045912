import { useQuery } from '@tanstack/react-query'

import { LEAVE_QUERY_KEY } from '../../cacheKeys'
import { leaveDetail } from '../../service/leavesService'

type LeaveDetailConfig = {
    query: Parameters<typeof leaveDetail>[0]
    enabled?: boolean
}

export const useLeaveDetail = ({ query, enabled }: LeaveDetailConfig) =>
    useQuery({
        queryFn: () => leaveDetail(query), 
        queryKey: [LEAVE_QUERY_KEY, 'detail', query],
        enabled
    })

