import { IsString } from 'class-validator'

import { ChatBarFields } from './Chat.shared'
import { IsArray } from '../../utils/ClassValidators'

class ChatBarFormValidator implements ChatBarFields {
    @IsArray()
    fileIds: string[]
    @IsString()
    message: string
}

export default ChatBarFormValidator
