

import { IsNotEmpty, ValidateIf } from '@viterbit/web-app/shared/utils/ClassValidators'

import { EmployeePlanningFieldsType } from './EmployeePlanningFields'

class EmployeePlanningValidator implements EmployeePlanningFieldsType {
    @ValidateIf((o) => o.type === 'WORK_SCHEDULE')
    @IsNotEmpty()
    workScheduleId: string
    @IsNotEmpty()
    startDate: any
    @IsNotEmpty()
    type: 'CONTRACT' | 'WORK_SCHEDULE'
}

export default EmployeePlanningValidator