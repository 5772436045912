
import { Link } from 'react-router-dom'
import Button from 'ui/src/components/Button'
import LayoutHeading from 'ui/src/components/LayoutHeading'

const SignUpRequestSuccessView = () => (
    <div className='flex flex-col items-center' data-testid="signup-success-view">
        <LayoutHeading
            title='Signup success'
            subtitle="We've send you an email to confirm your account"
        />
    
        <Link to='/auth/signin'>
            <Button variant='solid'>Login</Button>
        </Link>
    </div>
)

export default SignUpRequestSuccessView
