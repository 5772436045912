import FormUpload from '@viterbit/web-app/shared/form/FormUpload'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import DropZoneSkeleton from 'ui/src/components/Upload/DropZoneSkeleton'
import FileListSkeleton from 'ui/src/components/Upload/FileListSkeleton'
import clsxm from 'ui/src/lib'

import { DocumentProps } from '../DocumentEmloyeeShared'

type DocumentEmployeeDetailFormProps = {
    children?: React.ReactNode
    className?: string
    onUploadSuccess?: () => void
} & DocumentProps

const styles = {
    root: ''
}

const DocumentEmployeeDetailForm = (props: DocumentEmployeeDetailFormProps) => {
    const { children, className, employeeId, type } = props

    const form = useForm()
    const [isPending] = React.useTransition()

    return (
        <div className={clsxm(styles.root, className)}>
            <FormUpload
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                control={form.control as any}
                name='files'
                employeeId={employeeId}
                kind={type}
                multiple
                cleanSuccess
                columns={2}
                className={clsxm(isPending && 'hidden')}
                draggable
            />

            {!!isPending && (
                <>
                    <DropZoneSkeleton className='mx-0 mb-10'/>
                    <FileListSkeleton
                        count={2}
                        columns={2}
                        className="p-4 px-0 pt-0 mb-4"
                    />
                </>
            )}
        </div>

    )
}

export default DocumentEmployeeDetailForm
