/* eslint-disable @typescript-eslint/no-explicit-any */
import dayjs from 'dayjs'
import * as React from 'react'

import BasePicker from './BasePicker'
import { capitalize } from './utils/utils'
import { RangeCalendar } from '../Calendar'
import { DayProps } from '../Calendar/sharedTypes'
import useControllableState from '../../hooks/useControllableState'
import useMergedRef from '../../hooks/useMergedRef'

const validationRule = (val: unknown) =>
    Array.isArray(val) &&
    val.length === 2 &&
    val.every((v) => v instanceof Date)

const isFirstDateSet = (val: unknown) =>
    Array.isArray(val) && val.length === 2 && val[0] instanceof Date

export type DatePickerRangeProps = {
    className?: string
    clearable?: boolean
    clearButton?: React.ReactNode
    closePickerOnChange?: boolean
    dateViewCount?: number
    dayClassName?: (date?: Date, dayProps?: DayProps) => string | string
    dayStyle?: (date?: Date, dayProps?: DayProps) => React.CSSProperties
    defaultMonth?: Date
    defaultOpen?: boolean
    defaultValue?: Date
    defaultView?: 'month' | 'year'
    disabled?: boolean
    disableDate?: (date: Date) => boolean
    enableHeaderLabel?: boolean
    disableOutOfMonth?: boolean
    firstDayOfWeek?: 'monday' | 'sunday'
    hideOutOfMonthDates?: boolean
    hideWeekdays?: boolean
    inputFormat?: string
    inputPrefix?: React.ReactNode
    inputSuffix?: React.ReactNode
    inputtable?: boolean
    labelFormat?:
        | string
        | {
              month: string
              year: string
          }
    locale?: 'en' | 'es'
    maxDate?: Date
    minDate?: Date
    name?: string
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
    onChange?: (date: Date) => void
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
    onDropdownClose?: () => void
    onDropdownOpen?: () => void
    openPickerOnClear?: boolean
    renderDay?: (value: Date | undefined) => React.ReactNode
    size?: 'sm' | 'md' | 'lg'
    style?: React.CSSProperties
    type?: 'date' | 'datetime'
    value?: Date
    weekendDays?: number[]
    yearLabelFormat?: string
    separator?: string
    singleDate?: boolean
    placeholder?: string
}

const DatePickerRange = React.forwardRef<HTMLDivElement, DatePickerRangeProps>(
    (props, ref) => {
        const {
            className,
            clearable = true,
            clearButton,
            closePickerOnChange = true,
            dateViewCount = 1,
            dayClassName,
            dayStyle,
            defaultMonth,
            defaultOpen,
            defaultValue,
            defaultView,
            disabled,
            disableDate,
            enableHeaderLabel,
            disableOutOfMonth,
            firstDayOfWeek = 'monday',
            hideOutOfMonthDates,
            hideWeekdays,
            inputFormat,
            inputPrefix,
            inputSuffix,
            labelFormat = {
                month: 'MMM',
                year: 'YYYY',
            },
            separator = '~',
            locale = 'es',
            maxDate,
            minDate,
            onChange,
            onDropdownClose,
            onDropdownOpen,
            openPickerOnClear = false,
            renderDay,
            singleDate = false,
            size,
            style,
            value,
            weekendDays,
            yearLabelFormat,
            ...rest
        } = props

        const dateFormat = inputFormat || 'YYYY-MM-DD'

        const [dropdownOpened, setDropdownOpened] = React.useState(defaultOpen)

        const inputRef = React.useRef<HTMLInputElement>()

        const [_value, setValue] = useControllableState({
            prop: value,
            defaultProp: (defaultValue !== undefined
                ? defaultValue
                : [null, null]) as any,
            onChange,
        })

        const handleValueChange = (range: any) => {
            setValue(range)
            if (closePickerOnChange && validationRule(range)) {
                setDropdownOpened(false)
                onDropdownClose?.()
                window.setTimeout(() => inputRef.current?.focus(), 0)
            }
        }

        const valueValid = validationRule(_value)
        const firstValueValid = isFirstDateSet(_value)

        const firstDateLabel = (_value as any)[0]
            ? capitalize(
                dayjs((_value as any)[0])
                    .locale('locale')
                    .format(dateFormat)
            )
            : ''

        const secondDateLabel = (_value as any)[1]
            ? capitalize(
                dayjs((_value as any)[1])
                    .locale('locale')
                    .format(dateFormat)
            )
            : ''

        const handleClear = () => {
            setValue([null, null] as any)
            setDropdownOpened(true)
            openPickerOnClear && onDropdownOpen?.()
            inputRef.current?.focus()
        }

        const handleDropdownToggle = (isOpened: boolean) => {
            if (!isOpened && firstValueValid && (_value as any)[1] === null) {
                handleClear()
            }
            setDropdownOpened(isOpened)
        }

        return (
            <BasePicker
                dropdownOpened={dropdownOpened}
                setDropdownOpened={handleDropdownToggle}
                ref={useMergedRef(ref, inputRef)}
                size={size}
                style={style}
                className={className}
                inputLabel={
                    firstValueValid
                        ? `${firstDateLabel} ${separator} ${secondDateLabel}`
                        : ''
                }
                clearable={clearable && firstValueValid}
                clearButton={clearButton}
                onClear={handleClear}
                dateViewCount={dateViewCount}
                onDropdownClose={onDropdownClose}
                onDropdownOpen={onDropdownOpen}
                disabled={disabled}
                inputPrefix={inputPrefix}
                inputSuffix={inputSuffix}
                {...rest}
            >
                <RangeCalendar
                    locale={locale}
                    defaultMonth={
                        valueValid ? (_value as any)[0] : defaultMonth
                    }
                    value={_value as any}
                    onChange={handleValueChange}
                    labelFormat={labelFormat}
                    dayClassName={dayClassName}
                    dayStyle={dayStyle}
                    disableOutOfMonth={disableOutOfMonth}
                    minDate={minDate}
                    maxDate={maxDate}
                    disableDate={disableDate}
                    firstDayOfWeek={firstDayOfWeek}
                    enableHeaderLabel={enableHeaderLabel}
                    singleDate={singleDate}
                    dateViewCount={dateViewCount}
                    defaultView={defaultView}
                    hideOutOfMonthDates={hideOutOfMonthDates}
                    hideWeekdays={hideWeekdays}
                    renderDay={renderDay}
                    weekendDays={weekendDays}
                    yearLabelFormat={yearLabelFormat}
                />
            </BasePicker>
        )
    }
)

export default DatePickerRange
