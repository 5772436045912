
import { Complaint } from '@viterbit/web-app/dataAccess/model/Complaint.model'
import i18n from '@viterbit/web-app/i18n'
import SegmentNavLink from '@viterbit/web-app/shared/navigation/SegmentNavLink'
import List from 'ui/src/components/List'

export type ComplaintListItemType = {
    complaint: Complaint
}

const ComplaintListItemAuthor = (props: ComplaintListItemType) => {
    const { complaint } = props

    if (complaint.authorConfirmed) {
        return (
            <span>
                {complaint.authorName || complaint.authorEmail || complaint.authorRelationship || complaint.authorPhone}
            </span>
        )
    }

    return (
        <span className='italic opacity-60'>
            {i18n.t('complaint:list.emptyAuthor')}
        </span>
    )
}

const ComplaintListItem = (props: ComplaintListItemType) => {
    const { complaint } = props

    return (
        <SegmentNavLink path={`/core-hr/complaints/${complaint.id}/details`}>
            <List.Item clickable>
                <List.ItemText
                    primary={complaint.title}
                    secondary={<ComplaintListItemAuthor complaint={complaint}/>}
                />
            </List.Item>
        </SegmentNavLink>
    )
}

export default ComplaintListItem
