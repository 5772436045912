
import companyMapper from '@viterbit/web-app/dataAccess/mapper/company.mapper'
import paginationMetaMapper from '@viterbit/web-app/dataAccess/mapper/paginationMeta.mapper'
import { queryClient } from '@viterbit/web-app/shared/lib/ReactQueryProvider'
import { CompanyApi, Configuration } from 'viterbit-api-sdk'

import { commonConfig } from './config'
import { COMPANY_QUERY_KEY } from '../cacheKeys'

const api = new CompanyApi(new Configuration(commonConfig))

export const companyInvalidate = () =>
    queryClient.invalidateQueries([COMPANY_QUERY_KEY])


export const companyCreate = (body: Parameters<typeof api.createCompany>[0]) =>
    api.createCompany(body).then((data) => {
        companyInvalidate()
        return data
    })

export const companyUpdate = (body: Parameters<typeof api.updateCompany>[0]) =>
    api.updateCompany(body).then((data) => {
        companyInvalidate()
        return data
    })

export const companyList = (body: Parameters<typeof api.listCompanies>[0]) =>
    api.listCompanies(body).then(({data, meta}) => ({
        meta: paginationMetaMapper(meta),
        data: data.map(companyMapper.toModel)
    }))

export const companyDelete = (body: Parameters<typeof api.deleteCompany>[0]) =>
    api.deleteCompany(body).then((data) => {
        companyInvalidate()
        return data
    })

export const companyDetail = (body: Parameters<typeof api.fetchCompany>[0]) =>
    api.fetchCompany(body).then(companyMapper.toModel)
