import AsideCardInfo from '@viterbit/web-app/shared/display/AsideCardInfo'
import * as React from 'react'
import FormSkeleton from 'ui/src/components/Form/FormSkeleton'

type NavigationTabsContentLoadingProps = {
    title?: string
    description?: string
}

const NavigationTabsContentLoading = ({ title, description } : NavigationTabsContentLoadingProps) => (
    <AsideCardInfo
        title={title}
        description={description}
        direction='vertical'
    >
        <FormSkeleton />
    </AsideCardInfo>

)

export default NavigationTabsContentLoading
