/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAccountListAll } from '@viterbit/web-app/dataAccess/useCase/account/useAccountListAll'
import i18n from '@viterbit/web-app/i18n'
import Cookies from 'js-cookie'
import { MantineReactTable, MRT_ColumnDef, MRT_PaginationState } from 'mantine-react-table'
import { MRT_Localization_ES } from 'mantine-react-table/locales/es'
import { useState } from 'react'
import Dialog from 'ui/src/components/Dialog'

import { IMPERSONATE_USER_KEY } from '../state/userState'
import { AccountExtended } from '../../dataAccess/model/AccountExtended.model'
import { ITEMS_BY_PAGE } from '../../dataAccess/model/Week.model'
import EmployeeItem from '../../feature/employee/item/EmployeeItem'

type Props = {
    onClose: () => void
}

const ImpersonateUserDialog = ({
    onClose
}: Props) => {
    const impersonateUser = (email: string) => {
        Cookies.set(IMPERSONATE_USER_KEY, email)
        window.open('/', '_self')
    }

    const [globalFilter, setGlobalFilter] = useState('')
    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: ITEMS_BY_PAGE,
    })

    const { data, isError, isLoading } = useAccountListAll({
        query: {
            page: pagination.pageIndex + 1,
            pageSize: pagination.pageSize,
            search: globalFilter || undefined,
            include: ['role', 'employee', 'company', 'organization'],
        },
        keepPreviousData: true,
    })

    const columns: MRT_ColumnDef<AccountExtended>[] = [
        {
            header: i18n.t('navigation:navbar.userMenu.impersonate.modal.columns.employee'),
            accessorKey: 'employee',
            Cell: ({ row: {original: {employee}} }) => (
                <div className='flex items-center'>
                    <EmployeeItem employee={employee as any} />
                </div>
            ),
            enableSorting: false,
            enableColumnFilter: false,
        },
        {
            header: i18n.t('navigation:navbar.userMenu.impersonate.modal.columns.company'),
            accessorKey: 'company.name',
            enableSorting: false,
            enableColumnFilter: false,
        },
        {
            header: i18n.t('navigation:navbar.userMenu.impersonate.modal.columns.organization'),
            accessorKey: 'organization.name',
            enableSorting: false,
            enableColumnFilter: false,
        },
        {
            header: i18n.t('navigation:navbar.userMenu.impersonate.modal.columns.role'),
            accessorKey: 'role.name',
            enableSorting: false,
            enableColumnFilter: false,
        },
    ]

    return (
        <Dialog
            open
            onClose={onClose}
            title={i18n.t('navigation:navbar.userMenu.impersonate.modal.title')}
            size={'2xl' as any}
        >
            <MantineReactTable
                columns={columns}
                data={data?.data ?? []}
                rowCount={data?.meta?.total ?? 0}
                positionGlobalFilter='left'
                positionToolbarAlertBanner="none"
                localization={MRT_Localization_ES}
                getRowId={(row) => row.id}
                
                initialState={{
                    showGlobalFilter: true,
                    density: 'xs',
                }}
                state={{
                    globalFilter,
                    pagination,
                    showGlobalFilter: true,
                    showAlertBanner: isError,
                    showSkeletons: isLoading,
                    showProgressBars: false,
                }}
                enableColumnActions={false}
                enableFilterMatchHighlighting={false}
                manualPagination
                manualFiltering
                onGlobalFilterChange={setGlobalFilter}
                onPaginationChange={setPagination}
                
                mantineToolbarAlertBannerProps={
                    isError
                        ? {
                            color: 'error',
                            children: 'Error loading data',
                        }
                        : undefined
                }
                mantineTableBodyRowProps={({ row }) => ({
                    onClick: () => impersonateUser(row.original.email),
                    sx: {
                        cursor: 'pointer',
                    },
                })}
                renderToolbarInternalActions={() => (
                    <></>
                )}
                mantineTopToolbarProps={{
                    className: 'px-0'
                }}
            />
        </Dialog>
    )
}

export default ImpersonateUserDialog