/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import dateMapper from '@viterbit/web-app/dataAccess/mapper/date.mapper'
import { TimeTracking } from '@viterbit/web-app/dataAccess/model/TimeTracking.model'
import { timeTrackingUpdate } from '@viterbit/web-app/dataAccess/service/timeTrackingService'
import i18n from '@viterbit/web-app/i18n'
import { TIME_ZONE } from '@viterbit/web-app/shared/utils/timeZone'
import * as React from 'react'
import { Api } from 'types/Api'

import TimeTrackingDialog from '../list/TimeTrackingDialog'
import TimeTrackingForm from '../list/TimeTrackingForm'
import { TimeTrackingFields } from '../shared'

type EditTimeTrackingProps = {
    open: boolean
    onClose: () => void
    children?: React.ReactNode
    className?: string
    defaultValues: TimeTracking
    onSuccess: (data: Api.ResponseSuccess, variables: TimeTrackingFields, context: unknown) => void
}

const styles = {
    root: ''
}

export type EditTimeTrackingState = {
    defaultValues: TimeTrackingFields
}

const EditTimeTracking = (props: EditTimeTrackingProps) => {
    const { defaultValues, children, className, onSuccess, onClose, open } = props

    return (
        <TimeTrackingDialog
            open={open}
            onClose={onClose}
            title={i18n.t('personalArea:timeTracking.editDialog.title')}
        >
            <TimeTrackingForm
                defaultValues={{
                    endDate: defaultValues.endDate,
                    startDate: defaultValues.startDate,
                }}
                mutationConfig={{
                    mutationFn: ({
                        endDate,
                        startDate,
                    }: TimeTrackingFields) => timeTrackingUpdate({
                        id: defaultValues.id,
                        updateTimeTrackingCommand: {
                            timezone: TIME_ZONE,
                            endDate: dateMapper.toDateTime(endDate)!,
                            startDate: dateMapper.toDateTime(startDate)!,
                        }
                    }),
                    mutationKey: ['edit-tracking', defaultValues.id],
                    onSuccess: (...args) => {
                        onClose()
                        onSuccess(...args)
                    },
                }}
                onCancel={onClose}
                cancelText={i18n.t('personalArea:timeTracking.editDialog.buttons.cancel')}
                confirmText={i18n.t('personalArea:timeTracking.editDialog.buttons.confirm')}
            >
            </TimeTrackingForm>
        </TimeTrackingDialog>
    )
}

export default EditTimeTracking
