import Typography, { TypographySizedProps, TypographySizesStyles, TypographyStyles } from '@viterbit/web-app/shared/typography/Typography'

export const SmallStyles = {
    ...TypographyStyles,
    root: `${TypographyStyles.root} ${TypographySizesStyles.small}`,
} as const

const Small = (props: TypographySizedProps) => {
    return (
        <Typography {...props} size='small' />
    )
}

export default Small
