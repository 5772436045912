import i18n from '@viterbit/web-app/i18n'
import SectionLayout from '@viterbit/web-app/shared/layout/SectionLayout'
import { Outlet } from 'react-router-dom'

const BulkUploadLayout = () => (
    <SectionLayout
        title={i18n.t('employees:bulkUpload.title')}
    >
        <Outlet />
    </SectionLayout>
)

export default BulkUploadLayout
