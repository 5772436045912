
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import DateTimeItemTitle from '@viterbit/web-app/shared/display/DateTimeItem/DateTimeItemTitle'
import * as React from 'react'
import Skeleton from 'ui/src/components/Skeleton'
import Tooltip from 'ui/src/components/Tooltip'
import clsxm from 'ui/src/lib'

import { LeaveTeamDay } from './LeaveTeamShared'

export type LeaveTeamBulletProps = {
    children?: React.ReactNode
    className?: string
    days: LeaveTeamDay[]
    header: React.ReactElement
    firstColumnClassName?: string
    lastColumnClassName?: string
    firstItemClassName?: string
    onItemClicked?: (day: LeaveTeamDay) => void
}

type FillColorProps = Pick<LeaveTeamDay, 'isDisabled' | 'backgroundColor'> & {
    variant?: LeaveTeamDay['variant']
}

const FillColor = ({
    isDisabled,
    backgroundColor,
    variant
}: FillColorProps) => (
    <i
        className={clsxm(
            'w-full h-full block bg-grey-200',
            isDisabled && 'opacity-0'
        )}
        style={variant && {
            dashed: { borderColor: 'black', borderWidth: 1, borderStyle: 'dashed', backgroundColor: 'transparent', borderRadius: 50, opacity: 0.4 },
            'solid': { backgroundColor },
            'border': { borderColor: backgroundColor, borderWidth: 2, borderStyle: 'solid', backgroundColor: 'transparent', borderRadius: 50},
        }[variant]}
    />
)

type FillSemiColorProps = Pick<LeaveTeamDay, 'isDisabled' | 'backgroundColor'> & {
    direction: 'left' | 'right'
    variant?: LeaveTeamDay['variant']
}

const FillSemiColor = ({
    backgroundColor,
    direction,
    ...rest
}: FillSemiColorProps) => (
    <span
        className={clsxm(
            'w-2 h-4 absolute inset-0',
            direction === 'right' && 'ml-2'
        )}
    >
        <FillColor backgroundColor={backgroundColor} {...rest} />
    </span>
)

type BulletWrapperProps = {
    children: React.ReactNode
    onClick?: () => void
}

const BulletWrapper = ({ children, onClick }: BulletWrapperProps) => (
    <div className={`relative w-4 h-4 m-1 overflow-hidden rounded-full place-self-center ${onClick ? 'cursor-pointer': 'pointer-events-none'}`} onClick={onClick}>
        {children}
    </div>
)

const LeaveTeamBullet = (
    props: Pick<
        LeaveTeamDay,
        'isDisabled' | 'isLoading' | 'backgroundColor' | 'type' | 'description' | 'variant' | 'tooltipText'
    > & {
        onClick?: () => void
    }
) => {
    const { type, description, backgroundColor, isLoading, isDisabled, variant, onClick, tooltipText } = props

    if (isLoading) return <Skeleton className='hidden w-2 h-4' />

    const content = type === 'full' ? (
        <BulletWrapper onClick={onClick}>
            <FillColor {...props} />
        </BulletWrapper>
    ) : (
        <BulletWrapper onClick={onClick}>
            <FillColor
                variant={variant} 
                backgroundColor={backgroundColor}
            />
            <FillSemiColor
                backgroundColor={backgroundColor}
                direction={{
                    'left': 'right',
                    'right': 'left',
                }[type!] as 'left' | 'right'}
            />
        </BulletWrapper>
    )

    if (isDisabled || !description && !tooltipText) return content
    return (
        <Tooltip
            tooltipClass='bg-white z-50 max-w-[250px] text-gray-800 rounded-lg py-2 px-4 relative shadow-xl border-gray-100 radius-rounded'
            arrowClassColor='white'
            arrowClassColorDark='white'
            title={
                <div className='flex flex-col gap-2'>
                    <DateTimeItemTitle
                        title={description}
                        action={tooltipText}
                        color={backgroundColor}
                    />
                </div>
            }
        >
            {content}
        </Tooltip>
    )
}


export default LeaveTeamBullet