import { FindCityQueryResponse } from 'viterbit-api-sdk'

import { IMapper } from './__common'
import fromResponse from './__fromResponse'
import { City } from '../model/City.model'

const cityMapper: IMapper<City, FindCityQueryResponse> = {
    fromResponse: function (response) { return fromResponse(response, this.toModel) },
    toModel: function (dto) {
        return {
            name: dto.name,
            state: dto.state,
            country: dto.country,
        }
    },
    toDto: () => {
        throw new Error('Not implemented')
    },
}

export default cityMapper
