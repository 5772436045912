import * as React from 'react'

import { useTabs } from './context'
import { clsxm } from '../../lib/clsxm'

const classes = {
    tabList: 'flex items-center overflow-x-auto overflow-y-hidden',
    tabListUnderline: 'border-b border-gray-200 dark:border-gray-600',
}

export type TabListProps = React.HTMLAttributes<HTMLDivElement>

const TabList = React.forwardRef<HTMLDivElement, TabListProps>((props, ref) => {
    const { className, children, ...rest } = props

    const { variant } = useTabs()

    const tabListClass = clsxm(
        classes.tabList,
        variant === 'underline' && classes.tabListUnderline,
        className
    )

    return (
        <div role='tablist' className={tabListClass} ref={ref} {...rest}>
            {children}
        </div>
    )
})

export default TabList
