/* eslint-disable @typescript-eslint/no-non-null-assertion */

import throwException from '@viterbit/web-app/shared/utils/throwException'
import FormSkeleton from 'ui/src/components/Form/FormSkeleton'

import WorkScheduleDetail from './WorkScheduleDetail'
import { useWorkScheduled } from '../useWorkScheduled'

const WorkScheduleDetailView = () => {
    const { data: workSchedule, isLoading } = useWorkScheduled()

    if (isLoading) {
        return <FormSkeleton className='mt-[100px]'/>
    }

    if (!workSchedule) {
        return throwException('WorkSchedule not found')
    }

    return (
        <WorkScheduleDetail workSchedule={workSchedule} showActions />
    )
}

export default WorkScheduleDetailView
