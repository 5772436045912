import { useAccountDetail } from '@viterbit/web-app/dataAccess/useCase/account/useAccountDetail'
import { useUser } from '@viterbit/web-app/shared/employee/useUser'
import SectionLayout from '@viterbit/web-app/shared/layout/SectionLayout'
import * as React from 'react'

import AccountSettingsDetail from './AccountSettingsDetail'


const AccountSettingsView = () => {
    const user = useUser()

    const { data, isLoading } = useAccountDetail({
        query: { id: user?.id },
        enabled: !!user,
    })

    if (!user || isLoading || !data) return null

    return (
        <SectionLayout>
            <AccountSettingsDetail account={data}/>
        </SectionLayout>
    )

}

export default AccountSettingsView