import * as React from 'react'
import clsxm from 'ui/src/lib'

type ForbiddenContentProps = {
    children?: React.ReactNode
    className?: string
    allow?: boolean
}

const styles = {
    root: '',
    disabled: 'pointer-events-none cursor-not-allowed grayscale'
}

const ForbiddenContent = (props: ForbiddenContentProps) => {
    const { allow = true, children } = props

    return (
        <div className={clsxm(styles.root, !allow && styles.disabled)}>
            {children}
        </div>
    )
}

export default ForbiddenContent
