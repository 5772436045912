import fetchWithoutPagination from '@viterbit/web-app/dataAccess/helper/fetchWithoutPagination'
import jobPositionMapper from '@viterbit/web-app/dataAccess/mapper/jobPosition.mapper'
import paginationMetaMapper from '@viterbit/web-app/dataAccess/mapper/paginationMeta.mapper'
import { JobPositionLevel } from '@viterbit/web-app/dataAccess/model/JobPositionLevel.model'
import { queryClient } from '@viterbit/web-app/shared/lib/ReactQueryProvider'
import { Configuration, JobPositionLevelApi } from 'viterbit-api-sdk'

import { commonConfig } from './config'
import { JOB_POSITION_LEVEL_QUERY_KEY, JOB_POSITION_QUERY_KEY } from '../cacheKeys'


const api = new JobPositionLevelApi(new Configuration(commonConfig))

export const jobPositionInvalidate = () => {
    queryClient.invalidateQueries([JOB_POSITION_QUERY_KEY])
    queryClient.invalidateQueries([JOB_POSITION_LEVEL_QUERY_KEY])
}

export const jobPositionLevelCreate = (body: Parameters<typeof api.createJobPositionLevel>[0]) =>
    api.createJobPositionLevel(body).then((data) => {
        jobPositionInvalidate()
        return data
    })

export const jobPositionLevelUpdate = (body: Parameters<typeof api.updateJobPositionLevel>[0]) =>
    api.updateJobPositionLevel(body).then((data) => {
        jobPositionInvalidate()
        return data
    })

export const jobPositionLevelList = (body: Parameters<typeof api.listJobPositionLevel>[0]) =>
    api.listJobPositionLevel(body).then(({data, meta}) => ({
        meta: paginationMetaMapper(meta),
        data: data.map(jobPositionMapper.toModel)
    }))

export const findWithoutPagination = (body: Parameters<typeof api.listJobPositionLevel>[0]) =>
    fetchWithoutPagination(jobPositionLevelList)({...body, pageSize: 10} as any) as Promise<JobPositionLevel[]>


export const jobPositionLevelFind = async (body: Parameters<typeof api.findJobPositionLevel>[0]) => {
    const res = await api.findJobPositionLevel(body)
    const item = jobPositionMapper.toModel(res)
    return item
}

export const jobPositionLevelDelete = (body: Parameters<typeof api.deleteJobPositionLevel>[0]) =>
    api.deleteJobPositionLevel(body).then((data) => {
        jobPositionInvalidate()
        return data
    })