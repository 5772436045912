/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateComplaintCommand
 */
export interface CreateComplaintCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateComplaintCommand
     */
    organizationId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateComplaintCommand
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof CreateComplaintCommand
     */
    authorName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateComplaintCommand
     */
    authorEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateComplaintCommand
     */
    authorPhone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateComplaintCommand
     */
    authorRelationship?: string | null;
}

/**
 * Check if a given object implements the CreateComplaintCommand interface.
 */
export function instanceOfCreateComplaintCommand(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "organizationId" in value;
    isInstance = isInstance && "title" in value;

    return isInstance;
}

export function CreateComplaintCommandFromJSON(json: any): CreateComplaintCommand {
    return CreateComplaintCommandFromJSONTyped(json, false);
}

export function CreateComplaintCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateComplaintCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'organizationId': json['organizationId'],
        'title': json['title'],
        'authorName': !exists(json, 'authorName') ? undefined : json['authorName'],
        'authorEmail': !exists(json, 'authorEmail') ? undefined : json['authorEmail'],
        'authorPhone': !exists(json, 'authorPhone') ? undefined : json['authorPhone'],
        'authorRelationship': !exists(json, 'authorRelationship') ? undefined : json['authorRelationship'],
    };
}

export function CreateComplaintCommandToJSON(value?: CreateComplaintCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'organizationId': value.organizationId,
        'title': value.title,
        'authorName': value.authorName,
        'authorEmail': value.authorEmail,
        'authorPhone': value.authorPhone,
        'authorRelationship': value.authorRelationship,
    };
}

