
import { classValidatorResolver } from '@hookform/resolvers/class-validator'
import leaveReasonMapper from '@viterbit/web-app/dataAccess/mapper/leaveReason.mapper'
import { LeaveReason } from '@viterbit/web-app/dataAccess/model/LeaveReason.model'
import { leaveReasonUpdate } from '@viterbit/web-app/dataAccess/service/leaveReasonsService'
import Form from '@viterbit/web-app/shared/form/Form'
import SubmitButton from '@viterbit/web-app/shared/form/SubmitButton'
import { useNavigate } from 'react-router-dom'
import Button from 'ui/src/components/Button'

import { LeaveReasonsFormFields } from './DetailsLeaveReasons'
import DetailsLeaveReasonsForm from './DetailsLeaveReasonsForm'
import DetailsLeaveReasonValidator from './DetailsLeaveReasonValidator'

type DetailsLeavePoliciesViewProps = {
    leavePolicyId: string
    leaveReason: LeaveReason
    onSuccess: (data: any) => void
    onCancel: () => void
}

const resolver = classValidatorResolver(DetailsLeaveReasonValidator)

const DetailsLeaveReasonsView = (props: DetailsLeavePoliciesViewProps) => {
    const { leaveReason: details, leavePolicyId, onSuccess, onCancel } = props

    const navigate = useNavigate()

    return (
        <Form<LeaveReasonsFormFields>
            formConfig={{
                resolver,
                mode: 'all',
                defaultValues: details,
            }}
            mutationConfig={{
                onSuccess,
                mutationFn: (data) => leaveReasonUpdate({
                    id: details.id,
                    updateLeaveReasonCommand: leaveReasonMapper.toDto(data),
                }),
            }}
        >
            {(form) => (
                <>
                    <DetailsLeaveReasonsForm details={details} form={form} />
                    <Button type='button' onClick={onCancel}> Cancel</Button>
                    <SubmitButton>Save </SubmitButton>
                </>
            )}
        </Form>
    )
}

export default DetailsLeaveReasonsView
