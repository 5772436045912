import { Employee } from '@viterbit/web-app/dataAccess/model/Employee.model'
import { Summary } from '@viterbit/web-app/dataAccess/model/Summary.model'
import ForbiddenContent from '@viterbit/web-app/shared/display/ForbiddenContent'
import * as React from 'react'
import Dropdown from 'ui/src/components/Dropdown'
import { LeaveAdjustmentData } from 'viterbit-api-sdk'

import SummaryDetails from './SummaryDetails'
import ExtraDaysAction from '../ExtraDays/ExtraDaysAction'

type SummaryDetailsTooltipProps = {
    children: React.ReactNode
    summary: Summary
    extraDays?: LeaveAdjustmentData
    employee: Employee
}

const SummaryDetailsTooltip = ({
    employee,
    children,
    summary,
    extraDays,
}: SummaryDetailsTooltipProps) => (
    <Dropdown
        renderTitle={children}
        placement='bottom-center'
        menuClass='p-0'
        trigger='hover'
    >
        <>
            <SummaryDetails summary={summary} extraDays={extraDays} />
            <ForbiddenContent allow={!employee?.isDeleted}>
                <ExtraDaysAction employee={employee} />
            </ForbiddenContent>
        </>
    </Dropdown>
)

export default SummaryDetailsTooltip
