import { useInfiniteQuery } from '@tanstack/react-query'

import { EMPLOYEE_QUERY_KEY } from '../../cacheKeys'
import { employeeList } from '../../service/employeeService'

type EmployeeListConfig = {
    query: Parameters<typeof employeeList>[0]
    enabled?: boolean
    keepPreviousData?: boolean
    refetchOnWindowFocus?: boolean
}

export const useEmployeeListPaginated = (config?: EmployeeListConfig) => {
    const { query = { page: 1 }, enabled, keepPreviousData, refetchOnWindowFocus } = config || {}

    const queryResult =  useInfiniteQuery(
        [EMPLOYEE_QUERY_KEY, 'list-paginated', query],
        ({ pageParam }) => employeeList({ ...query, page: pageParam || 1 }),
        {
            getNextPageParam: (lastPage) => lastPage ? lastPage.meta.nextPage : 1,
            initialData: () => ({
                pageParams: [1],
                pages: [],
            }),
            enabled,
            keepPreviousData,
            refetchOnWindowFocus,
        }
    )

    return queryResult
}

