import Tooltip from '../Tooltip'
import clsxm from '../../lib'

type FileItemButtonProps = {
    className?: string
    children: React.ReactNode
    onClick?: () => void
    tooltipText?: string
}

const FileItemButton = ({
    children,
    className,
    onClick,
    tooltipText
}: FileItemButtonProps) => {
    const buttonClass = clsxm(
        'text-xl rounded text-gray-400 hover:text-black dark:hover:text-gray-300',
        className
    )

    return (
        <Tooltip title={tooltipText}>
            <span
                className={buttonClass}
                role='button'
                onClick={onClick}
            >
                {children}
            </span>
        </Tooltip>
        
    )
}

export default FileItemButton