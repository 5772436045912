/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { IconCalendarOff } from '@tabler/icons-react'
import { Employee } from '@viterbit/web-app/dataAccess/model/Employee.model'
import i18n from '@viterbit/web-app/i18n'
import EmptyState from '@viterbit/web-app/shared/display/EmptyState/EmptyState'
import * as React from 'react'

import EmployeePlanningListItem from './EmployeePlanningListItem'
import useEmployeePlanningAction from './useEmployeePlanningAction'
import EmployeePlanningCreate from '../create/EmployeePlanningCreate'

type EmployeePlanningListProps = {
    employee: Employee
}


const EmployeePlanningList = (props: EmployeePlanningListProps) => {
    const { employee } = props

    const hasItems = !!employee.planning.length

    const { dialog, onItemDelete, onItemEdit } = useEmployeePlanningAction()

    return (
        <div className='p-4'>
            <p className='flex items-center mb-2 font-semibold'>
                {i18n.t('employees:employee.planning.planningList.title')}
            </p>

            <div className='p-3 border border-gray-300 rounded-md bg-grey-50'>
                {!hasItems && (
                    <EmptyState
                        className='py-10'
                        image={<IconCalendarOff size={64} className='text-gray-400' stroke={1} />}
                        title={i18n.t('employees:employee.planning.planningList.emptyTitle')}
                        description={i18n.t('employees:employee.planning.planningList.emptyDescription')}
                    />
                )}

                <ul className='flex flex-col gap-4'>
                    {hasItems && (
                        employee.planning.map((planning) => (
                            <EmployeePlanningListItem
                                key={planning.id}
                                onClick={() => onItemEdit(planning)}
                                onDelete={() => onItemDelete(planning)}
                                planning={planning}
                            />
                        ))
                    )}
                </ul>
            </div>

            <EmployeePlanningCreate />
            
            {dialog}
            

        </div>
    )
}

export default EmployeePlanningList
