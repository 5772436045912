/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const LogicalOperator = {
    And: 'and',
    Or: 'or'
} as const;
export type LogicalOperator = typeof LogicalOperator[keyof typeof LogicalOperator];


export function LogicalOperatorFromJSON(json: any): LogicalOperator {
    return LogicalOperatorFromJSONTyped(json, false);
}

export function LogicalOperatorFromJSONTyped(json: any, ignoreDiscriminator: boolean): LogicalOperator {
    return json as LogicalOperator;
}

export function LogicalOperatorToJSON(value?: LogicalOperator | null): any {
    return value as any;
}

