/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmployeePersonalInformation
 */
export interface EmployeePersonalInformation {
    /**
     * Employee email
     * @type {string}
     * @memberof EmployeePersonalInformation
     */
    email: string;
    /**
     * Employee name
     * @type {string}
     * @memberof EmployeePersonalInformation
     */
    name: string;
    /**
     * Employee surname
     * @type {string}
     * @memberof EmployeePersonalInformation
     */
    surname?: string | null;
    /**
     * Employee pronouns
     * @type {string}
     * @memberof EmployeePersonalInformation
     */
    pronouns?: string | null;
    /**
     * Employee gender
     * @type {string}
     * @memberof EmployeePersonalInformation
     */
    gender?: string | null;
    /**
     * Employee nationality
     * @type {string}
     * @memberof EmployeePersonalInformation
     */
    nationality?: string | null;
    /**
     * Employee phone
     * @type {string}
     * @memberof EmployeePersonalInformation
     */
    phone?: string | null;
    /**
     * Employee birthday
     * @type {string}
     * @memberof EmployeePersonalInformation
     */
    birthday?: string | null;
    /**
     * Employee birthday
     * @type {number}
     * @memberof EmployeePersonalInformation
     */
    reporters?: number | null;
}

/**
 * Check if a given object implements the EmployeePersonalInformation interface.
 */
export function instanceOfEmployeePersonalInformation(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function EmployeePersonalInformationFromJSON(json: any): EmployeePersonalInformation {
    return EmployeePersonalInformationFromJSONTyped(json, false);
}

export function EmployeePersonalInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeePersonalInformation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'name': json['name'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
        'pronouns': !exists(json, 'pronouns') ? undefined : json['pronouns'],
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'nationality': !exists(json, 'nationality') ? undefined : json['nationality'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'birthday': !exists(json, 'birthday') ? undefined : json['birthday'],
        'reporters': !exists(json, 'reporters') ? undefined : json['reporters'],
    };
}

export function EmployeePersonalInformationToJSON(value?: EmployeePersonalInformation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'name': value.name,
        'surname': value.surname,
        'pronouns': value.pronouns,
        'gender': value.gender,
        'nationality': value.nationality,
        'phone': value.phone,
        'birthday': value.birthday,
        'reporters': value.reporters,
    };
}

