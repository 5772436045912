import Typography, { TypographySizedProps, TypographySizesStyles, TypographyStyles } from '@viterbit/web-app/shared/typography/Typography'
import * as React from 'react'

export const SubtitleStyles = {
    ...TypographyStyles,
    root: `${TypographyStyles.root} ${TypographySizesStyles.subtitle}`,
} as const

const Subtitle = React.forwardRef<HTMLSpanElement, TypographySizedProps>((props, ref) => (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    <Typography {...props} ref={ref} size='subtitle' />
))

export default Subtitle
