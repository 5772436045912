
import i18n from '@viterbit/web-app/i18n'
import TwoColumnsLayout from '@viterbit/web-app/shared/layout/TwoColumnsLayout'
import * as React from 'react'
import { Outlet } from 'react-router-dom'

import TeamCreate from './create/TeamCreate'
import TeamListView from './list/TeamListView'

const TeamLayout = () => (
    <TwoColumnsLayout
        title={i18n.t('employees:teams.header')}
        leftTitle={i18n.t('employees:teams.header')}
        leftActions={<TeamCreate />}
        left={<TeamListView />}
    >
        <Outlet />
    </TwoColumnsLayout>
)

export default TeamLayout
