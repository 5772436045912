import { PermissionAction } from '@viterbit/web-app/dataAccess/model/PermissionAction.model'
import i18n from '@viterbit/web-app/i18n'
import AsideCardInfo from '@viterbit/web-app/shared/display/AsideCardInfo'
import { clsxm } from '@viterbit/web-app/shared/utils/clsxm'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import EmployeeContractInformationForm from './information/EmployeeContractInformationForm'
import EmployeeContractJobPositionForm from './jobPosition/EmployeeContractJobPositionForm'
import EmployeeContractWorkForm from './work/EmployeeContractWorkForm'
import EmployeeContext from '../EmployeeContext'
import NavigationTabsContentLoading from '../../../shared/navigation/Tabs/NavigationTabsContentLoading'

type EmployeeContractsViewProps = {
    className?: string
}

const EmployeeContractView = ({ className }: EmployeeContractsViewProps) => {
    const { employee, hasPermission, basePath } = React.useContext(EmployeeContext)
    const navigate = useNavigate()
    const hasPermissionToSeeTab = hasPermission(PermissionAction.CORE_EMPLOYEE_CONTRACT_FIND, employee.id)
    const hasPermissionToEdit = hasPermission(PermissionAction.CORE_EMPLOYEE_CONTRACT_UPDATE, employee.id)
    
    React.useEffect(() => {
        !hasPermissionToSeeTab && navigate(basePath)
    }, [hasPermissionToSeeTab, employee.id])
    
    if (!hasPermissionToSeeTab) {
        return <NavigationTabsContentLoading />
    }

    return (
        <div className={clsxm('space-y-4', className)}>
            <AsideCardInfo
                title={i18n.t('employees:employee.contracts.contractInfo.title')}
                description={i18n.t('employees:employee.contracts.contractInfo.description')}
                direction='vertical'
                disabled={!hasPermissionToEdit}
            >
                <EmployeeContractInformationForm employee={employee} />
            </AsideCardInfo>

            <AsideCardInfo
                title={i18n.t('employees:employee.profile.jobPositionInfo.title')}
                description={i18n.t('employees:employee.profile.jobPositionInfo.description')}
                direction='vertical'
                disabled={!hasPermissionToEdit}
            >
                <EmployeeContractJobPositionForm employee={employee} />
            </AsideCardInfo>

            <AsideCardInfo
                title={i18n.t('employees:employee.contracts.workContract.title')}
                description={i18n.t('employees:employee.contracts.workContract.description')}
                direction='vertical'
                disabled={!hasPermissionToEdit}
            >
                <EmployeeContractWorkForm employee={employee} />
            </AsideCardInfo>
        </div>
    )
}

export default EmployeeContractView
