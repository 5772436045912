/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CheckLeaveQueryResponse,
  CreateLeaveCommand,
  FilterGroups,
  FindLeaveQueryResponse,
  ListLeaveQueryResponse,
  ListLeaveTimelineQueryResponse,
  ResourceCreated,
  UpdateLeaveCommand,
} from '../models/index';
import {
    CheckLeaveQueryResponseFromJSON,
    CheckLeaveQueryResponseToJSON,
    CreateLeaveCommandFromJSON,
    CreateLeaveCommandToJSON,
    FilterGroupsFromJSON,
    FilterGroupsToJSON,
    FindLeaveQueryResponseFromJSON,
    FindLeaveQueryResponseToJSON,
    ListLeaveQueryResponseFromJSON,
    ListLeaveQueryResponseToJSON,
    ListLeaveTimelineQueryResponseFromJSON,
    ListLeaveTimelineQueryResponseToJSON,
    ResourceCreatedFromJSON,
    ResourceCreatedToJSON,
    UpdateLeaveCommandFromJSON,
    UpdateLeaveCommandToJSON,
} from '../models/index';

export interface ApproveLeavesRequest {
    id: string;
}

export interface CheckLeaveRequest {
    employeeId: string;
    leaveReasonId: string;
    startDate: string;
    endDate: string;
    halfDay?: string;
}

export interface CreateLeavesRequest {
    createLeaveCommand?: CreateLeaveCommand;
}

export interface DeleteLeavesRequest {
    id: string;
    body?: object;
}

export interface FindLeavesRequest {
    id: string;
    include?: Array<FindLeavesIncludeEnum>;
}

export interface ListLeavesRequest {
    employeeId: string;
    year?: number;
    page?: number;
    pageSize?: number;
}

export interface ListLeavesTimelineRequest {
    companyId?: string;
    locationId?: string;
    reporters?: boolean;
    month?: string;
    search?: string;
    page?: number;
    perPage?: number;
    filters?: FilterGroups;
}

export interface RejectLeavesRequest {
    id: string;
}

export interface UpdateLeavesRequest {
    id: string;
    updateLeaveCommand?: UpdateLeaveCommand;
}

/**
 * 
 */
export class LeaveApi extends runtime.BaseAPI {

    /**
     * Approve a leave
     * Approve a leave
     */
    async approveLeavesRaw(requestParameters: ApproveLeavesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling approveLeaves.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core-hr/leaves/{id}/approve`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Approve a leave
     * Approve a leave
     */
    async approveLeaves(requestParameters: ApproveLeavesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.approveLeavesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Check a leave
     * Check a leave
     */
    async checkLeaveRaw(requestParameters: CheckLeaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CheckLeaveQueryResponse>> {
        if (requestParameters.employeeId === null || requestParameters.employeeId === undefined) {
            throw new runtime.RequiredError('employeeId','Required parameter requestParameters.employeeId was null or undefined when calling checkLeave.');
        }

        if (requestParameters.leaveReasonId === null || requestParameters.leaveReasonId === undefined) {
            throw new runtime.RequiredError('leaveReasonId','Required parameter requestParameters.leaveReasonId was null or undefined when calling checkLeave.');
        }

        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling checkLeave.');
        }

        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling checkLeave.');
        }

        const queryParameters: any = {};

        if (requestParameters.employeeId !== undefined) {
            queryParameters['employeeId'] = requestParameters.employeeId;
        }

        if (requestParameters.leaveReasonId !== undefined) {
            queryParameters['leaveReasonId'] = requestParameters.leaveReasonId;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = requestParameters.startDate;
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = requestParameters.endDate;
        }

        if (requestParameters.halfDay !== undefined) {
            queryParameters['halfDay'] = requestParameters.halfDay;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core-hr/leaves/check`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CheckLeaveQueryResponseFromJSON(jsonValue));
    }

    /**
     * Check a leave
     * Check a leave
     */
    async checkLeave(requestParameters: CheckLeaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CheckLeaveQueryResponse> {
        const response = await this.checkLeaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a leave
     * Create a leave
     */
    async createLeavesRaw(requestParameters: CreateLeavesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core-hr/leaves`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateLeaveCommandToJSON(requestParameters.createLeaveCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Create a leave
     * Create a leave
     */
    async createLeaves(requestParameters: CreateLeavesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.createLeavesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a leave
     * Delete a leave
     */
    async deleteLeavesRaw(requestParameters: DeleteLeavesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteLeaves.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core-hr/leaves/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Delete a leave
     * Delete a leave
     */
    async deleteLeaves(requestParameters: DeleteLeavesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.deleteLeavesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find a leave
     * Find a leave
     */
    async findLeavesRaw(requestParameters: FindLeavesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindLeaveQueryResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling findLeaves.');
        }

        const queryParameters: any = {};

        if (requestParameters.include) {
            queryParameters['include'] = requestParameters.include;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core-hr/leaves/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindLeaveQueryResponseFromJSON(jsonValue));
    }

    /**
     * Find a leave
     * Find a leave
     */
    async findLeaves(requestParameters: FindLeavesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindLeaveQueryResponse> {
        const response = await this.findLeavesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch all leaves for the given employee
     * List leaves
     */
    async listLeavesRaw(requestParameters: ListLeavesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListLeaveQueryResponse>> {
        if (requestParameters.employeeId === null || requestParameters.employeeId === undefined) {
            throw new runtime.RequiredError('employeeId','Required parameter requestParameters.employeeId was null or undefined when calling listLeaves.');
        }

        const queryParameters: any = {};

        if (requestParameters.employeeId !== undefined) {
            queryParameters['employeeId'] = requestParameters.employeeId;
        }

        if (requestParameters.year !== undefined) {
            queryParameters['year'] = requestParameters.year;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core-hr/leaves`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListLeaveQueryResponseFromJSON(jsonValue));
    }

    /**
     * Fetch all leaves for the given employee
     * List leaves
     */
    async listLeaves(requestParameters: ListLeavesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListLeaveQueryResponse> {
        const response = await this.listLeavesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch all leaves for the given employee
     * List leaves timeline
     */
    async listLeavesTimelineRaw(requestParameters: ListLeavesTimelineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListLeaveTimelineQueryResponse>> {
        const queryParameters: any = {};

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        if (requestParameters.locationId !== undefined) {
            queryParameters['locationId'] = requestParameters.locationId;
        }

        if (requestParameters.reporters !== undefined) {
            queryParameters['reporters'] = requestParameters.reporters;
        }

        if (requestParameters.month !== undefined) {
            queryParameters['month'] = requestParameters.month;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.perPage !== undefined) {
            queryParameters['perPage'] = requestParameters.perPage;
        }

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core-hr/leaves/timeline`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListLeaveTimelineQueryResponseFromJSON(jsonValue));
    }

    /**
     * Fetch all leaves for the given employee
     * List leaves timeline
     */
    async listLeavesTimeline(requestParameters: ListLeavesTimelineRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListLeaveTimelineQueryResponse> {
        const response = await this.listLeavesTimelineRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reject a leave
     * Reject a leave
     */
    async rejectLeavesRaw(requestParameters: RejectLeavesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling rejectLeaves.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core-hr/leaves/{id}/reject`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Reject a leave
     * Reject a leave
     */
    async rejectLeaves(requestParameters: RejectLeavesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.rejectLeavesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a leave
     * Update a leave
     */
    async updateLeavesRaw(requestParameters: UpdateLeavesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateLeaves.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core-hr/leaves/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateLeaveCommandToJSON(requestParameters.updateLeaveCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Update a leave
     * Update a leave
     */
    async updateLeaves(requestParameters: UpdateLeavesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.updateLeavesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const FindLeavesIncludeEnum = {
    LeaveReason: 'leaveReason',
    Files: 'files'
} as const;
export type FindLeavesIncludeEnum = typeof FindLeavesIncludeEnum[keyof typeof FindLeavesIncludeEnum];
