/* eslint-disable @typescript-eslint/no-explicit-any */
import { classValidatorResolver } from '@hookform/resolvers/class-validator'
import LeaveMapper from '@viterbit/web-app/dataAccess/mapper/leave.mapper'
import { leaveUpdate } from '@viterbit/web-app/dataAccess/service/leavesService'
import { useLeaveDetail } from '@viterbit/web-app/dataAccess/useCase/leave/useLeaveDetail'
import Form from '@viterbit/web-app/shared/form/Form'
import * as React from 'react'

import LeaveForm from './LeaveForm'
import LeaveValidator from './LeaveValidator'
import Loading from './shared/Loading'
import { LeaveFormFields } from './types'

type EditFormProps = {
    leaveId: string
    employeeId: string
    onSubmitCallBack?: () => void
    isReadOnly?: boolean
}

const resolver = classValidatorResolver(LeaveValidator)

const EditForm = ({
    employeeId,
    leaveId,
    onSubmitCallBack,
    isReadOnly,
}: EditFormProps) => {

    const { data: leave, isLoading: isLoadingLeave } = useLeaveDetail({
        query: {
            id: leaveId,
            include: ['leaveReason', 'files']
        },
        enabled: !!leaveId,
    })

    if (isLoadingLeave || !leave) {
        return <Loading />
    }

    const defaultValues = {
        ...leave,
        daysOption: leave.daysOption,
        initialDate: new Date(leave.initialDate) as any,
        endDate: leave.endDate && (new Date(leave.endDate) as any),
        leaveReasonId: leave.leaveReason?.id || '',
    }

    return (
        <Form<LeaveFormFields>
            formConfig={{
                resolver,
                mode: 'all',
                defaultValues
            }}
            mutationConfig={{
                mutationKey: ['editLeave', leaveId],
                mutationFn: (leave) =>
                    leaveUpdate({
                        id: leaveId,
                        updateLeaveCommand: {
                            ...LeaveMapper.toDto(leave),
                            leaveReasonId: leave.leaveReasonId,
                        } as any
                    }),
                onSuccess: () => {
                    onSubmitCallBack?.()
                },
            }}
        >
            {(form, mutation, formState) => (
                <LeaveForm
                    employeeId={employeeId}
                    isReadOnly={isReadOnly}
                    form={form}
                    defaultValues={defaultValues}
                    formState={formState}
                />
            )}
        </Form>
    )
}

export default EditForm
