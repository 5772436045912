/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    registerDecorator,
    ValidationArguments,
    ValidationOptions,
    ValidatorConstraint,
    ValidatorConstraintInterface,
} from 'class-validator'

export function Generic(property: (any) => any, validationOptions?: ValidationOptions) {
    return (object: any, propertyName: string) => {
        registerDecorator({
            target: object.constructor,
            propertyName,
            options: validationOptions,
            constraints: [property],
            validator: GenericConstraint,
        })
    }
}

@ValidatorConstraint({ name: 'Generic' })
export class GenericConstraint implements ValidatorConstraintInterface {
    validate(value: any, args: ValidationArguments) {
        const [callback] = args.constraints
        return callback(value)
    }
}
