import { Subscription } from '@viterbit/web-app/dataAccess/model/Subscription.model'
import * as React from 'react'

import SubscriptionItem from './SubscriptionItem'

type SubscriptionItemProps = {
    children?: React.ReactNode
    className?: string
    list: Subscription[]
}

const SubscriptionList = (props: SubscriptionItemProps) => {
    const { list = [] } = props

    return (
        <div data-testid="subscription-list">
            {
                list.map(item => (
                    <SubscriptionItem
                        key={item.id}
                        subscription={item}
                    />
                ))
            }
        </div>
       
    )
}

export default SubscriptionList
