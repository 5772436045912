/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { classValidatorResolver } from '@hookform/resolvers/class-validator'
import { complaintPublicCreate } from '@viterbit/web-app/dataAccess/service/complaintService'
import i18n from '@viterbit/web-app/i18n'
import Form from '@viterbit/web-app/shared/form/Form'
import SubmitButton from '@viterbit/web-app/shared/form/SubmitButton'
import { NavLink, useNavigate, useParams } from 'react-router-dom'

import { ComplaintCreateFields } from './ComplaintCreateFields'
import ComplaintCreateFormFields from './ComplaintCreateFormFields'
import ComplaintCreateFormValidator from './ComplaintCreateFormValidator'
import { useComplaintOrganization } from '../useComplaintOrganization'
import FormSkeleton from 'ui/src/components/Form/FormSkeleton'
import SegmentNavLink from '@viterbit/web-app/shared/navigation/SegmentNavLink'


const resolver = classValidatorResolver(ComplaintCreateFormValidator)

const ComplaintCreateForm = () => {
    const navigate = useNavigate()
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const { data: organization, isInitialLoading } = useComplaintOrganization()

    if (isInitialLoading) {
        return <FormSkeleton count={1} className='w-[300px]' />
    }

    return (
        <>
            <Form<ComplaintCreateFields>
                withTransition
                formConfig={{
                    mode: 'all',
                    resolver,
                }}
                mutationConfig={{
                    onSuccess: (data) => {
                        navigate(`/complaints/detail/${data.id}`)
                    },
                    mutationFn: (data) => {
                        const { authorConfirmed, title, ...authorFields } = data

                        return complaintPublicCreate({
                            createComplaintCommand: {
                                title,
                                organizationId: organization!.id!,
                                ...(authorConfirmed && authorFields),
                            }
                        })
                    }
                }}
            >
                {(form) => (
                    <>
                        <ComplaintCreateFormFields form={form} />
                        <SubmitButton data-testid='button-complaint'>{i18n.t('complaint:create.form.submit')}</SubmitButton>
                    </>
                )}
            </Form>

            <div className='mt-4 text-center'>
                <span>{i18n.t('complaint:create.form.hasComplaint')}</span>
                <SegmentNavLink data-testid="complaint-login" path={`/complaints/login`} className='ml-2 text-primary-600'>
                    {i18n.t('complaint:create.form.loginComplaint')}
                </SegmentNavLink>
            </div>
        </>
    )
}

export default ComplaintCreateForm
