/* eslint-disable @typescript-eslint/no-explicit-any */

import {
    ConditionFieldBase,
    ConditionTypeBase,
    ConditionValidationBase,
} from './ConditionBase.model'

export const ConditionStringFields = [
    'from',
    'to',
    'subject',
    'message',
    'name',
    'surname',
    'email',
    'companyId',
    'locationId',
    'employeeId',
] as const
export type ConditionStringField = typeof ConditionStringFields[number]

export const ConditionFieldStringTypes = [
    'string',
    'rich_text',
    'quantity',
    'employeeId',
    'companyId',
    'locationId',
] as const
export type ConditionFieldStringType = typeof ConditionFieldStringTypes[number]

export const ConditionStringOperators = ['contains', 'startsWith', 'endsWith', 'minLength', 'maxLength', 'email'] as const
export type ConditionStringOperator = typeof ConditionStringOperators[number]
export type ConditionTypeString = ConditionTypeBase<string> & {
  [key in ConditionStringOperator]?: string
}

export type ConditionValidationString = ConditionValidationBase<
    ConditionStringOperator,
    any
>

export type ConditionFieldString = ConditionFieldBase<
  ConditionStringField,
  ConditionFieldStringType,
  ConditionValidationString
>

export type ConditionFieldStringProperties = {
    [key in ConditionStringField]: string
}

export const isConditionStringField = (
    conditionField: any
): conditionField is ConditionFieldString =>
    ConditionFieldStringTypes.includes(conditionField.type as any)
    
export const ConditionStringField: ConditionFieldString = {
    name: 'name',
    type: 'string',
    validations: [
        {
            name: 'startsWith',
            value: [
                'a'
            ]
        }
    ]
}