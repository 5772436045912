/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Grid } from '@mantine/core'
import { SETTINGS_ROUTE } from '@viterbit/web-app/routes'
import SimpleCard from '@viterbit/web-app/shared/display/SimpleCard'
import MultiColumnLayout from '@viterbit/web-app/shared/layout/MultiColumnLayout'
import React from 'react'
import { useLocation } from 'react-router-dom'

import { useRouteFilter } from './useRouteEnabled'
import { useRouteGroup } from './useRouteGroup'
import { TableOfContents } from '../navigation/TableOfContents'

export type SettingsLayoutBodyProps = {
    leftTitle?: React.ReactNode
    leftActions?: React.ReactNode
} & Pick<React.ComponentProps<typeof MultiColumnLayout>, 'left' | 'children'>

const SettingsLayoutBody = ({ left, children, leftActions, leftTitle }: SettingsLayoutBodyProps) => {
    const { pathname } = useLocation()

    const filterRoutes = useRouteFilter()

    const {
        routes,
        needle,
    } = React.useMemo(() => {
        const routes = SETTINGS_ROUTE.children!.filter(filterRoutes)
        const needle = routes?.find((route) => pathname.startsWith(`/settings/${route.path}`))

        return {
            routes,
            needle,
        }
    }, [filterRoutes, pathname])

    const dropdownGroups = useRouteGroup({
        ...SETTINGS_ROUTE,
        children: routes,
    }, false)

    const mappedLinks = React.useMemo(() =>
        dropdownGroups.map((group) => [
            {
                label: group.label,
                link: '#',
                order: 1,
                icon: group.icon,
            },
            ...group.items.map((item) => ({
                label: item.handle.title!,
                link: item.handle.href!,
                order: 2,
                icon: item.handle.icon
            })),
        ]).flat()
    , [dropdownGroups])

    return (
        <Grid>
            <Grid.Col span={12} md={2}>
                <SimpleCard
                    className='p-0'
                >
                    <TableOfContents
                        active={needle?.handle.href || ''}
                        links={mappedLinks}
                    />
                </SimpleCard>
            </Grid.Col>

            <Grid.Col span={12} md={3}>
                <SimpleCard title={leftTitle} actions={leftActions}>{left}</SimpleCard>
            </Grid.Col>

            <Grid.Col span={12} md={7}>
                <SimpleCard padding>
                    {children}
                </SimpleCard>
            </Grid.Col>
        </Grid>
    )
}

export default SettingsLayoutBody
