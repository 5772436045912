import DropZoneSkeleton from 'ui/src/components/Upload/DropZoneSkeleton'
import FileListSkeleton from 'ui/src/components/Upload/FileListSkeleton'

const DocumentBulkUploadDetailFormLoading = () => (
    <div className='px-3'>
        <DropZoneSkeleton className='mx-0 mb-10' />
        <FileListSkeleton count={2} columns={2} className="p-6 px-0 pt-0 mb-4" />
    </div>
)

export default DocumentBulkUploadDetailFormLoading