import { IconArrowNarrowLeft } from '@tabler/icons-react'
import * as React from 'react'

import Button from './Button'

const classes = {
    paginationPagerPrev: 'mr-1 rtl:rotate-180',
}

type Props = {
    pagerClass: Record<'default' | 'inactive' | 'active' | 'disabled', string>
    currentPage: number
    pageCount?: number
    onPrev?: React.MouseEventHandler<HTMLSpanElement>
    customPrevIcon?: React.ReactNode
    t?: (key: string) => string
}

const Prev: React.FC<Props> = ({ onPrev, customPrevIcon, t, ...rest }) => (
    <Button
        type='prev'
        buttonClass={classes.paginationPagerPrev}
        defaultIcon={
            <div className='flex items-center gap-x-2'>
                <IconArrowNarrowLeft data-testid='pagination-prev-icon-default' />
                { t?.('pagination.prev') || 'Previous'}
            </div>
        }
        customIcon={customPrevIcon}
        onClick={onPrev}
        {...rest}
    />
)

export default Prev
