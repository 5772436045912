import i18n from '@viterbit/web-app/i18n'
import * as React from 'react'
import { Controller } from 'react-hook-form'

import TabOption, { Tabs } from './Tabs'
import { HalfDayOptions } from '../types'

type HalfDaysOptionsProps = {
    onChange: (value: HalfDayOptions) => void
    value: HalfDayOptions
    className?: string
    disabled?: boolean
}

const HalfDaysOptions = (props: HalfDaysOptionsProps) => {
    const { onChange, value, className, disabled } = props

    return (
        <Tabs disabled={disabled} className={className}>
            <TabOption
                variant='left'
                selected={value === HalfDayOptions.Morning}
                onClick={() => onChange(HalfDayOptions.Morning)}
            >
                {i18n.t('leaves:halfDayForm.morning')}
            </TabOption>

            <TabOption
                variant='right'
                selected={value === HalfDayOptions.Afternoon}
                onClick={() => onChange(HalfDayOptions.Afternoon)}
            >
                {i18n.t('leaves:halfDayForm.afternoon')}
            </TabOption>
        </Tabs>
    )
}

const HalfDayForm = ({ name, control, disabled }) => (
    <Controller
        control={control}
        name={name}
        render={({ field }) => (
            <HalfDaysOptions disabled={disabled} {...field} />
        )}
    />
)

export default HalfDayForm
