import i18n from '@viterbit/web-app/i18n'
import * as React from 'react'

import CreateLeaveReasonButton from './CreateLeaveReasonButton'

type LeaveReasonListProps = {
    children: React.ReactNode
    className?: string
    onClick: React.MouseEventHandler<HTMLDivElement>
}

const LeaveReasonList = ({
    children,
    className = '',
    onClick
}: LeaveReasonListProps) => (
    <nav className={`${className} `} aria-label='Breadcrumb'>
        <div className='flex flex-col gap-4'>{children}</div>

        <CreateLeaveReasonButton onClick={onClick}>{i18n.t('settingLeaves:addReason')}</CreateLeaveReasonButton>
    </nav>
)

export default LeaveReasonList
