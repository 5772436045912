/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { IconBeach, IconBlockquote, IconBrandAsana, IconBuildingEstate, IconBuildingSkyscraper, IconCalendar, IconCalendarEvent, IconCalendarStats, IconClockCog, IconClockPlus, IconEngine, IconExternalLink, IconFiles, IconFolder, IconHexagons, IconHome, IconHourglassLow, IconIdBadge2, IconInfoCircle, IconMapPin, IconSettings, IconShield, IconSignature, IconUser, IconUserCircle, IconUserHeart, IconUsers } from '@tabler/icons-react'
import * as React from 'react'
import { NonIndexRouteObject, redirect } from 'react-router-dom'

import { MenuPermissions } from './dataAccess/model/MenuPermissions.model'
import AccountSettingsView from './feature/account/settings/AccountSettingsView'
import AccountSettingsIntegrationFeedbackView from './feature/account/settings/Integrations/feedback/AccountSettingsIntegrationFeedbackView'
import ForgotPasswordRedeemView from './feature/auth/forgotPassword/redeem/ForgotPasswordRedeemView'
import ForgotPasswordRequestView from './feature/auth/forgotPassword/request/ForgotPasswordRequestView'
import ForgotPasswordRequestSuccessView from './feature/auth/forgotPassword/request/success/ForgotPasswordRequestSuccessView'
import LoginView from './feature/auth/login/LoginView'
import RedeemInvitationView from './feature/auth/redeemInvitation/RedeemInvitationView'
import SignUpRedeemView from './feature/auth/signup/redeem/SignUpRedeemView'
import SignUpRequestView from './feature/auth/signup/request/SignUpRequestView'
import SignUpRequestSuccessView from './feature/auth/signup/request/success/SignUpRequestSuccessView'
import BillingPaymentLayout from './feature/billing/payment/BillingPaymentLayout'
import BillingPaymentSuccessView from './feature/billing/payment/BillingPaymentSuccessView'
import BillingSubscriptionListView from './feature/billing/subscription/BillingSubscriptionListView'
import ComplaintView from './feature/complaint/private/ComplaintView'
import ComplaintPrivateDetailView from './feature/complaint/private/detail/ComplaintPrivateDetailView'
import ComplaintLayout from './feature/complaint/public/ComplaintLayout'
import ComplaintCreateView from './feature/complaint/public/create/ComplaintCreateView'
import ComplaintPublicDetailView from './feature/complaint/public/detail/ComplaintPublicDetailView'
import ComplaintGatewayView from './feature/complaint/public/gateway/ComplaintGatewayView'
import ComplaintLoginView from './feature/complaint/public/login/ComplaintLoginView'
import DashboardView from './feature/dashboard/DashboardView'
import BulkUploadLayout from './feature/document/bulkUpload/BulkUploadLayout'
import DocumentBulkUploadDetailView from './feature/document/bulkUpload/detail/DocumentBulkUploadDetailView'
import DocumentBulkUploadPreviewView from './feature/document/bulkUpload/preview/DocumentBulkUploadPreviewView'
import DocumentEmployeeDetailView from './feature/document/employee/detail/DocumentEmployeeDetailView'
import EmployeeContractView from './feature/employee/contract/EmployeeContractView'
import EmployeeDetailLayout from './feature/employee/detail/EmployeeDetailLayout'
import EmployeeListView from './feature/employee/list/EmployeeListView'
import EmployeePersonalView from './feature/employee/personal/EmployeePersonalView'
import EmployeePlanningView from './feature/employee/planning/EmployeePlanningView'
import EmployeeProfileView from './feature/employee/profile/EmployeeProfileView'
import DetailsJobPositionsView from './feature/jobPosition/detail/JobPositionDetailView'
import JobPositionLayout from './feature/jobPosition/JobPositionLayout'
import LeaveView from './feature/leave/LeaveView'
import LeaveTeamView from './feature/leave/team/LeaveTeamView'
import CompaniesView from './feature/settings/companies/CompaniesView'
import CompanyView from './feature/settings/companies/detail/CompanyView'
import SettingsComplaintsLayout from './feature/settings/complaints/SettingsComplaintsLayout'
import SettingsComplaintsView from './feature/settings/complaints/SettingsComplaintsView'
import DetailsLeavePoliciesView from './feature/settings/leavePolicies/detail/DetailsLeavePoliciesView'
import LeavePoliciesView from './feature/settings/leavePolicies/LeavePoliciesView'
import LocationView from './feature/settings/locations/detail/LocationView'
import LocationsLayout from './feature/settings/locations/LocationsLayout'
import SettingsOrganizationLayout from './feature/settings/organization/SettingsOrganizationLayout'
import WorkScheduleDetailView from './feature/settings/workScheduled/detail/WorkScheduleDetailView'
import WorkScheduledLayout from './feature/settings/workScheduled/WorkScheduledLayout'
import TeamDetailView from './feature/team/detail/TeamDetailView'
import TeamLayout from './feature/team/TeamLayout'
import TimeTrackingView from './feature/timeTracking/list/TimeTrackingView'
import TimeTrackingTeamView from './feature/timeTracking/team/TimeTrackingTeamView'
import WorkflowLayout from './feature/workflow/WorkflowLayout'
import i18n from './i18n'
import Error500 from './shared/feedback/Error'
import NotFound from './shared/feedback/NotFound'
import NotPaidSubscription from './shared/feedback/NotPaidSubscription'
import PrivateLayout from './shared/layout/PrivateLayout'
import PublicLayout from './shared/layout/PublicLayout'
import UserMenu from './shared/layout/UserMenu'
import { CreatePermissionRedirection } from './shared/navigation/withPermissionRedirection'
import { getUser } from './shared/state/userState'
import getHostInfo from './shared/utils/getHostInfo'

const WorkflowOnboardingDetail = React.lazy(() => import('./feature/workflow/onboarding/Detail/WorkflowOnboardingDetail'))
const WorkflowOnboardingConfig = React.lazy(() => import('./feature/workflow/onboarding/WorkflowOnboardingConfig'))

const currentYear = new Date().getFullYear()

export type NavigationItemMeta = {
    key: string
    href?: string
    title?: string
    description?: string
    icon?: React.ReactNode
    disabled?: boolean
    position?: 'top' | 'bottom'
    className?: string
    permission?: MenuPermissions
    matchPath?: string[]
    showInMenu?: boolean
    showDropdown?: boolean
    dropDownGroups?: Record<string, {
        label: string
        icon?: React.ReactNode
    }>
    dropDownGroup?: string
    hideSubmenu?: boolean
    exact?: boolean
    showBreadcrumb?: boolean
    customRender?: React.ReactNode
    renderIcon?: boolean
}

export interface NavigationItem extends NonIndexRouteObject {
    handle: NavigationItemMeta
    children?: NavigationItem[]
}

const publicLoader = async() => {
    if (!window.location.href.startsWith(import.meta.env.VITE_PRIVATE_WEB_URL)) {
        return redirect(import.meta.env.VITE_PRIVATE_WEB_URL)
    }

    const user = await getUser()

    if (user) {
        return redirect('/')
    }

    return {
        user
    }
}

const privateLoader = async() => {
    const user = await getUser()

    if (!user) {
        const params = new URLSearchParams()
        params.set('redirect', window.location.pathname)
        const redirectPath = `/auth/signin?${params.toString()}`

        return redirect(redirectPath)
    }

    return {
        user
    }
}


const subdomainLoader = async() => {
    const hostInfo = getHostInfo()

    if (!hostInfo.subdomain) {
        return redirect('/404')
    }

    return hostInfo
}

const parentLoader = (parentPath: string, config: { permission: MenuPermissions, path: string}[]) => async ({ request }) => {
    const url = new URL(request.url)

    if (url.pathname !== parentPath) {
        return {}
    }

    const user = await getUser()

    const navigationItem = config.find((item) => user?.menu[item.permission])

    if (navigationItem) {
        return redirect(navigationItem.path)
    }

    return {}
}

const NOT_FOUND_CONFIG: NavigationItem = {
    path: '*',
    element: <NotFound />,
    handle: {
        title: i18n.t('navigation:notFound'),
        description: i18n.t('navigation:notFoundDesciptions'),
        key: 'not-found',
    },
}


const pendingDocumentsNavigationItemChild: NavigationItem = {
    path: 'preview',
    element: <DocumentBulkUploadPreviewView />,
    handle: {
        key: 'preview',
        // href: '/employees/bulk/documents/preview',
        title: i18n.t('navigation:navbar.documents.preview'),
        description: i18n.t('navigation:navbarDescriptions.documents.preview'),
        permission: 'MyZone.Documents',
        icon: <IconFolder />,
        showBreadcrumb: true,
    },
}

const pendingDocumentsNavigationItem: NavigationItem[] = [
    {
        element: <BulkUploadLayout />,
        handle: {
            key: 'pending-documents',
        },
        children: [
            {
                path: 'payroll',
                handle: {
                    showBreadcrumb: true,
                    title: i18n.t('navigation:navbar.documents.payroll'),
                    description: i18n.t('navigation:navbarDescriptions.documents.payroll'),
                    key: 'documents/payroll',
                    icon: <IconFolder />,
                },
                children: [
                    {
                        index: true as any,
                        element: <DocumentBulkUploadDetailView documentType='payroll' />,
                        handle: {
                            key: 'documents/payroll',
                            icon: <IconFolder />,
                        },
                    },
                    pendingDocumentsNavigationItemChild,
                ]
            },
            {
                path: 'CONTRACT',
                handle: {
                    showBreadcrumb: true,
                    title: i18n.t('navigation:navbar.documents.contract'),
                    description: i18n.t('navigation:navbarDescriptions.documents.contract'),
                    key: 'documents/contract',
                    icon: <IconFolder />,
                },
                children: [
                    {
                        index: true as any,
                        element: <DocumentBulkUploadDetailView documentType='contract' />,
                        handle: {
                            key: 'documents/contract',
                            icon: <IconFolder />,
                        },
                    },
                    pendingDocumentsNavigationItemChild
                ]
            },
            {
                path: 'leave',
                handle: {
                    showBreadcrumb: true,
                    title: i18n.t('navigation:navbar.documents.leave'),
                    description: i18n.t('navigation:navbarDescriptions.documents.leave'),
                    key: 'documents/leave',
                    icon: <IconFolder />,
                },
                children: [
                    {
                        index: true as any,
                        element: <DocumentBulkUploadDetailView documentType='leave' />,
                        handle: {
                            key: 'documents/leave',
                            icon: <IconFolder />,
                        },
                    },
                    pendingDocumentsNavigationItemChild
                ]
            },
            {
                path: 'document',
                handle: {
                    showBreadcrumb: true,
                    title: i18n.t('navigation:navbar.documents.document'),
                    description: i18n.t('navigation:navbarDescriptions.documents.document'),
                    key: 'documents/document',
                    icon: <IconFolder />,
                },
                children: [
                    {
                        index: true as any,
                        element: <DocumentBulkUploadDetailView documentType='document' />,
                        handle: {
                            key: 'documents/document',
                            icon: <IconFolder />,
                        },
                    },
                    pendingDocumentsNavigationItemChild
                ]
            },
        ],

    }
]

const EMPLOYEE_TABS: NavigationItem[] = [
    {
        index: true as any,
        element: <EmployeeProfileView />,
        handle: {
            showBreadcrumb: true,
            title: i18n.t('navigation:navbar.employees.profile'),
            description: i18n.t('navigation:navbarDescriptions.employees.profile'),
            key: 'employees/:employeeId',
            showInMenu: false,
            icon: <IconUser />,
        },
    },
    {
        path: 'personal',
        element: <EmployeePersonalView />,
        handle: {
            showBreadcrumb: true,
            title: i18n.t('navigation:navbar.employees.personal'),
            description: i18n.t('navigation:navbarDescriptions.employees.personal'),
            key: 'employees/:employeeId/personal',
            showInMenu: false,
            icon: <IconShield />,
        },
    },
    {
        path: 'contracts',
        element: <EmployeeContractView />,
        handle: {
            showBreadcrumb: true,
            title: i18n.t('navigation:navbar.employees.contracts'),
            description: i18n.t('navigation:navbarDescriptions.employees.contracts'),
            key: 'employees/:employeeId/contract',
            showInMenu: false,
            icon: <IconSignature />,
        },
    },
    {
        path: 'leaves/:year',
        element: <LeaveView />,
        handle: {
            showBreadcrumb: true,
            title: i18n.t('navigation:navbar.employees.leaves'),
            description: i18n.t('navigation:navbarDescriptions.employees.leaves'),
            key: 'employees/:employeeId/contract',
            showInMenu: false,
            icon: <IconCalendarEvent />,
        },
    },
    {
        path: 'documents/:type?',
        element: <DocumentEmployeeDetailView />,
        handle: {
            permission: 'MyZone.Documents',
            showBreadcrumb: true,
            title: i18n.t('navigation:navbar.employees.documents'),
            description: i18n.t('navigation:navbarDescriptions.employees.documents'),
            key: 'documents',
            showInMenu: true,
            icon: <IconFolder />,
        },
    },
    {
        path: 'time-tracking',
        element: <TimeTrackingView />,
        handle: {
            key: 'employees/:employeeId/time-tracking',
            title: i18n.t('navigation:navbar.employees.timeTracking'),
            description: i18n.t('navigation:navbarDescriptions.employees.timeTracking'),
            showInMenu: false,
            showBreadcrumb: true,
            icon: <IconClockPlus />,
        },
    },
    {
        path: 'planning',
        element: <EmployeePlanningView />,
        handle: {
            key: 'employees/:employeeId/planning',
            showInMenu: false,
            showBreadcrumb: true,
            title: i18n.t('navigation:navbar.employees.planning'),
            description: i18n.t('navigation:navbarDescriptions.employees.planning'),
            icon: <IconCalendarStats />,
        },
    },
]

export const SETTINGS_ROUTE: NavigationItem = 
{
    path: 'settings',
    handle: {
        key: 'settings',
        href: '/settings',
        position: 'bottom',
        title: i18n.t('navigation:navbar.settings._')!,
        description: i18n.t('navigation:navbarDescriptions.settings._')!,
        icon: <IconSettings />,
        permission: 'Settings',
        matchPath: [
            '/settings',
        ],
        showInMenu: true,
        showBreadcrumb: true,
        showDropdown: false,
        dropDownGroups: {
            general: {label: i18n.t('navigation:navbar.settings.general')!, icon: <IconInfoCircle /> },
            coreHr: {label: i18n.t('navigation:navbar.settings.coreHr')!, icon: <IconUserHeart /> },
        },
        renderIcon: true,
    },
    element: CreatePermissionRedirection({
        parentPath: '/settings',
        permissions: [
            {permission: 'Settings.Companies', path: '/settings/companies'},
            {permission: 'Settings.Locations', path: '/settings/locations'},
            {permission: 'Settings.Leaves', path: '/settings/policies'},
            {permission: 'Settings.Complaints', path: '/settings/complaints'},
            {permission: 'Settings.WorkSchedule', path: '/settings/work-schedule'},
        ],
    }),
    children: [
        {
            path: 'companies',
            element: <CompaniesView />,
            handle: {
                key: 'companies',
                href: '/settings/companies',
                title: i18n.t('navigation:navbar.settings.companies')!,
                description: i18n.t('navigation:navbarDescriptions.settings.companies')!,
                permission: 'Settings.Companies',
                icon: <IconBuildingSkyscraper />,
                showInMenu: true,
                showBreadcrumb: true,
                dropDownGroup: 'general',
            },
            children: [
                {
                    path: ':id/details',
                    element: <CompanyView />,
                    handle: {
                        key: 'companies',
                        href: '/settings/companies',
                        title: i18n.t('navigation:navbar.settings.companyDetail')!,
                        description: i18n.t('navigation:navbarDescriptions.settings.companyDetail')!,
                        permission: 'Settings.Companies',
                        icon: <IconBuildingSkyscraper />,
                        showInMenu: true,
                        showBreadcrumb: true,
                    },
                }
            ]
        },
        {
            path: 'locations',
            element: <LocationsLayout />,
            handle: {
                key: 'locations',
                href: '/settings/locations',
                title: i18n.t('navigation:navbar.settings.locations')!,
                description: i18n.t('navigation:navbarDescriptions.settings.locations')!,
                icon: <IconMapPin />,
                permission: 'Settings.Locations',
                showInMenu: true,
                showBreadcrumb: true,
                dropDownGroup: 'general',
            },
            children: [
                {
                    path: ':locationId/details',
                    element: <LocationView />,
                    handle: {
                        key: 'locations',
                        href: '/settings/locations',
                        title: i18n.t('navigation:navbar.settings.locationDetail')!,
                        description: i18n.t('navigation:navbarDescriptions.settings.locationDetail')!,
                        permission: 'Settings.Companies',
                        icon: <IconBuildingSkyscraper />,
                        showInMenu: true,
                        showBreadcrumb: true,
                    }
                },
                {
                    path: ':locationId/holidays/:year/details',
                    element: <LocationView />,
                    handle: {
                        key: 'location-holidays',
                        href: `/settings/locationId/holidays/${currentYear}/details`,
                        title: i18n.t('navigation:navbar.settings.companies')!,
                        description: i18n.t('navigation:navbarDescriptions.settings.companies')!,
                        permission: 'Settings.Companies',
                        icon: <IconBuildingSkyscraper />,
                        showInMenu: false,
                        showBreadcrumb: true,
                    }
                },
            ]
        },
        {
            path: 'policies',
            element: <LeavePoliciesView />,
            handle: {
                key: 'policies',
                href: '/settings/policies',
                title: i18n.t('navigation:navbar.settings.leaves')!,
                description: i18n.t('navigation:navbarDescriptions.settings.leaves')!,
                icon: <IconBeach />,
                permission: 'Settings.Leaves',
                showInMenu: true,
                showBreadcrumb: true,
                dropDownGroup: 'coreHr',
            },
            children: [
                {
                    path: ':id/details',
                    element: <DetailsLeavePoliciesView />,
                    handle: {
                        key: 'policies',
                        href: '/settings/policies',
                        title: i18n.t('navigation:navbar.settings.leaveDetail')!,
                        description: i18n.t('navigation:navbarDescriptions.settings.leaveDetail')!,
                        permission: 'Settings.Leaves',
                        icon: <IconBuildingSkyscraper />,
                        showInMenu: true,
                        showBreadcrumb: true,
                    }
                },
            ]
        },
        {
            path: 'organization',
            element: <SettingsOrganizationLayout />,
            handle: {
                key: 'organization',
                href: '/settings/organization',
                title: i18n.t('navigation:navbar.settings.organization')!,
                description: i18n.t('navigation:navbarDescriptions.settings.organization')!,
                permission: 'Settings',
                icon: <IconBuildingEstate />,
                showInMenu: true,
                showBreadcrumb: true,
                dropDownGroup: 'general',
            },
            children: [
                {
                    index: true as any,
                    element: <SettingsOrganizationLayout />,
                    handle: {
                        key: 'organization',
                        href: '/settings/organization',
                        title: i18n.t('navigation:navbar.settings.organizationDetail')!,
                        description: i18n.t('navigation:navbarDescriptions.settings.organizationDetail')!,
                        permission: 'Settings',
                        icon: <IconBuildingSkyscraper />,
                        showInMenu: true,
                        showBreadcrumb: false,
                    },
                },
            ]
        },
        {
            path: 'complaints',
            element: <SettingsComplaintsLayout />,
            handle: {
                key: 'complaints',
                href: '/settings/complaints',
                title: i18n.t('navigation:navbar.settings.complaints')!,
                description: i18n.t('navigation:navbarDescriptions.settings.complaints')!,
                permission: 'Settings.Complaints',
                icon: <IconBlockquote />,
                showInMenu: true,
                showBreadcrumb: true,
                dropDownGroup: 'coreHr',
            },
            children: [
                {
                    index: true as any,
                    element: <SettingsComplaintsView />,
                    handle: {
                        key: 'complaints',
                        href: '/settings/complaints',
                        icon: <IconBlockquote />,
                        title: i18n.t('navigation:navbar.settings.complaints')!,
                        description: i18n.t('navigation:navbarDescriptions.settings.complaints')!,
                        permission: 'Settings.Complaints',
                        showInMenu: true,
                        showBreadcrumb: false,
                    },
                }
            ]
        },
        {
            path: 'work-schedule',
            element: <WorkScheduledLayout />,
            handle: {
                key: 'work-schedule',
                href: '/settings/work-schedule',
                title: i18n.t('navigation:navbar.settings.workSchedule')!,
                description: i18n.t('navigation:navbarDescriptions.settings.workSchedule')!,
                icon: <IconClockCog />,
                permission: 'Settings.WorkSchedule',
                showInMenu: true,
                showBreadcrumb: true,
                dropDownGroup: 'coreHr',
            },
            children: [
                {
                    path: ':id/details',
                    element: <WorkScheduleDetailView />,
                    handle: {
                        key: 'work-schedule',
                        href: '/settings/work-schedule',
                        title: i18n.t('navigation:navbar.settings.workScheduleDetail')!,
                        permission: 'Settings.WorkSchedule',
                        icon: <IconBuildingSkyscraper />,
                        showInMenu: true,
                        showBreadcrumb: true,
                    }
                },
            ]
        },

        NOT_FOUND_CONFIG,
    ],
}


export const navigationPrivateItems: NavigationItem[] = [
    {
        
        path: '/',
        element: <DashboardView />,
        handle: {
            key: 'dashboard',
            href: '/',
            position: 'top',
            title: i18n.t('navigation:navbar.dashboard')!,
            description: i18n.t('navigation:navbarDescriptions.dashboard')!,
            icon: <IconHome />,
            showInMenu: true,
            exact: true
        },
    },
    {
        path: 'me/profile',
        element: <EmployeeDetailLayout />,
        handle: {
            key: 'me/profile',
            href: '/me/profile',
            position: 'top',
            title: i18n.t('navigation:navbar.personalArea.profile')!,
            description: i18n.t('navigation:navbarDescriptions.personalArea.profile')!,
            permission: 'MyZone.Profile',
            icon: <IconUserCircle />,
            showInMenu: true,
            showBreadcrumb: true,
        },
        children: [
            ...EMPLOYEE_TABS,
            NOT_FOUND_CONFIG,
        ]
    },
    {
        path: 'employees',
        element: CreatePermissionRedirection({
            parentPath: '/employees',
            permissions: [
                {permission: 'Employees.Dashboard', path: '/employees/list'},
                {permission: 'Employees.Teams', path: '/employees/teams'},
                {permission: 'Employees.JobPosition', path: '/employees/positions'},
            ],
        }),
        handle: {
            key: 'employees',
            href: '/employees',
            position: 'top',
            title: i18n.t('navigation:navbar.employees._')!,
            description: i18n.t('navigation:navbarDescriptions.employees._')!,
            icon: <IconUsers />,
            permission: 'Employees',
            matchPath: [
                '/employees',
            ],
            showInMenu: true,
            showBreadcrumb: true,
            showDropdown: true,
        },
        children: [
            {
                path: 'list',
                handle: {
                    showBreadcrumb: true,
                    key: 'dashboard',
                    href: '/employees/list',
                    title: i18n.t('navigation:navbar.employees.employees')!,
                    description: i18n.t('navigation:navbarDescriptions.employees.employees')!,
                    permission: 'Employees.Dashboard',
                    icon: <IconUsers />,
                    matchPath: [
                        '/employees/dashboard',
                        '/employees/([a-zA-Z0-9-]){36}',
                    ],
                    showInMenu: true,
                },
                children: [
                    {
                        index: true as any,
                        element: <EmployeeListView />,
                        handle: {
                            key: 'dashboard',
                            href: '/employees/list',
                            title: i18n.t('navigation:navbar.employees.employees')!,
                            description: i18n.t('navigation:navbarDescriptions.employees.employees')!,
                            permission: 'Employees.Dashboard',
                            icon: <IconUsers />,
                            matchPath: [
                                '/employees/dashboard',
                                '/employees/([a-zA-Z0-9-]){36}',
                            ],
                        },
                    },
                    {
                        path: ':employeeId',
                        element: <EmployeeDetailLayout />,
                        handle: {
                            title: i18n.t('navigation:navbar.employees.employee'),
                            description: i18n.t('navigation:navbarDescriptions.employees.employee'),
                            key: 'employees/:employeeId',
                            permission: 'Employees',
                            showInMenu: false,
                        },
                        children: [
                            ...EMPLOYEE_TABS,
                            NOT_FOUND_CONFIG,
                        ]
                    },
                ]
            },
            {
                path: 'team',
                element: <TeamLayout />,
                handle: {
                    key: 'team',
                    href: '/employees/team',
                    title: i18n.t('navigation:navbar.employees.teams')!,
                    description: i18n.t('navigation:navbarDescriptions.employees.teams')!,
                    permission: 'Employees.Teams',
                    icon: <IconBrandAsana />,
                    showInMenu: true,
                    showBreadcrumb: true,
                },
                children: [
                    {
                        path: ':teamId/details',
                        element: <TeamDetailView />,
                        handle: {
                            key: 'team',
                            permission: 'Employees.Teams',
                            title: i18n.t('navigation:navbar.employees.teamDetail')!,
                            description: i18n.t('navigation:navbarDescriptions.employees.teamDetail')!,
                            showBreadcrumb: true,
                            icon: <IconBrandAsana />,
                        },
                    },
                ]
            },
            {
                path: 'positions',
                element: <JobPositionLayout />,
                handle: {
                    key: 'positions',
                    href: '/employees/positions',
                    title: i18n.t('navigation:navbar.employees.jobPosition')!,
                    description: i18n.t('navigation:navbarDescriptions.employees.jobPosition')!,
                    permission: 'Employees.JobPosition',
                    icon: <IconIdBadge2 />,
                    showInMenu: true,
                    showBreadcrumb: true,
                },
                children: [
                    {
                        path: ':jobPositionId/details',
                        element: <DetailsJobPositionsView />,
                        handle: {
                            key: 'positions',
                            title: i18n.t('navigation:navbar.employees.jobPositionDetail')!,
                            description: i18n.t('navigation:navbarDescriptions.employees.jobPositionDetail')!,
                            permission: 'Employees.JobPosition',
                            showBreadcrumb: true,
                            icon: <IconIdBadge2 />,
                        },
                    },
                ]
            },
            {
                path: 'chart',
                handle: {
                    key: 'chart',
                    href: '/employees/chart',
                    title: i18n.t('navigation:navbar.employees.organizationChart')!,
                    description: i18n.t('navigation:navbarDescriptions.employees.organizationChart')!,
                    permission: 'Employees.OrganizationChart',
                    icon: <IconHexagons />,
                    disabled: true,
                    showInMenu: true,
                    showBreadcrumb: true,
                }
            },
            NOT_FOUND_CONFIG,
        ],
    },
    {
        path: 'core-hr',
        element: CreatePermissionRedirection({
            parentPath: '/core-hr',
            permissions: [
                {permission: 'Employees.TeamCalendar', path: '/core-hr/calendar'},
                {permission: 'Employees.Teams', path: '/core-hr/teams'},
                {permission: 'Settings.WorkSchedule', path: '/core-hr/work-schedule'},
            ],
        }),
        handle: {
            key: 'core-hr',
            href: '/core-hr',
            position: 'top',
            title: i18n.t('navigation:navbar.coreHr._')!,
            description: i18n.t('navigation:navbarDescriptions.coreHr._')!,
            icon: <IconUsers />,
            permission: 'Employees',
            matchPath: [
                '/employees',
            ],
            showInMenu: true,
            showBreadcrumb: true,
            showDropdown: true,
            dropDownGroups: {
                time: { label: i18n.t('navigation:navbar.coreHr.time')!, icon: <IconHourglassLow /> },
                documents: { label: i18n.t('navigation:navbar.coreHr.documents')!, icon: <IconFiles /> },
                external: { label: i18n.t('navigation:navbar.coreHr.external')!, icon: <IconExternalLink /> },
            }
        },
        children: [
            {
                path: 'calendar/:month?',
                element: <LeaveTeamView />,
                handle: {
                    key: 'calendar',
                    href: '/core-hr/calendar',
                    title: i18n.t('navigation:navbar.employees.calendar')!,
                    description: i18n.t('navigation:navbarDescriptions.employees.calendar')!,
                    permission: 'Employees.TeamCalendar',
                    icon: <IconCalendar />,
                    showInMenu: true,
                    showBreadcrumb: true,
                    dropDownGroup: 'time',
                },
            },
            {
                path: 'bulk/documents',
                element: CreatePermissionRedirection({
                    parentPath: '/core-hr/bulk/documents',
                    permissions: [
                        {permission: 'Employees.BulkUpload', path: '/core-hr/bulk/documents/payroll'},
                    ],
                }),
                handle: {
                    key: 'payroll',
                    href: '/core-hr/bulk/documents',
                    title: i18n.t('navigation:navbar.employees.bulkUpload')!,
                    description: i18n.t('navigation:navbarDescriptions.employees.bulkUpload')!,
                    permission: 'Employees.BulkUpload',
                    icon: <IconFolder />,
                    showInMenu: true,
                    showBreadcrumb: true,
                    dropDownGroup: 'documents',
                },
                children: pendingDocumentsNavigationItem,
            },
            {
                path: 'time-tracking/:type/:frequency/:start?',
                element: <TimeTrackingTeamView />,
                handle: {
                    key: 'time-tracking',
                    href: '/core-hr/time-tracking/pending/monthly',
                    title: i18n.t('navigation:navbar.employees.timeTracking')!,
                    description: i18n.t('navigation:navbarDescriptions.employees.timeTracking')!,
                    permission: 'Employees.TimeTracking',
                    icon: <IconClockPlus />,
                    showInMenu: true,
                    showBreadcrumb: true,
                    matchPath: [
                        '/core-hr/time-tracking/*',
                    ],
                    dropDownGroup: 'time',
                },
            },
            {
                path: 'complaints',
                element: <ComplaintView />,
                handle: {
                    key: 'complaints',
                    href: '/core-hr/complaints',
                    title: i18n.t('navigation:navbar.settings.complaints')!,
                    description: i18n.t('navigation:navbarDescriptions.settings.complaints')!,
                    permission: 'Settings.Complaints',
                    showInMenu: false,
                    showBreadcrumb: true,
                    hideSubmenu: true,
                    dropDownGroup: 'external',
                },
                children: [
                    {
                        path: ':complaintId/details',
                        element: <ComplaintPrivateDetailView />,
                        handle: {
                            key: 'complaints',
                            href: '/core-hr/complaints',
                            title: i18n.t('navigation:navbar.settings.complaintsDetail')!,
                            description: i18n.t('navigation:navbarDescriptions.settings.complaintsDetail')!,
                            permission: 'Settings.Complaints',
                            showInMenu: true,
                            showBreadcrumb: true,
                        },
                    }
                ]
            },
            {
                path: 'chart',
                handle: {
                    key: 'chart',
                    href: '/employees/chart',
                    title: i18n.t('navigation:navbar.employees.organizationChart')!,
                    description: i18n.t('navigation:navbarDescriptions.employees.organizationChart')!,
                    permission: 'Employees.OrganizationChart',
                    icon: <IconHexagons />,
                    disabled: true,
                    showInMenu: true,
                    showBreadcrumb: true,
                }
            },
            NOT_FOUND_CONFIG,
        ],
    },
    {
        path: 'workflow/onboarding',
        element: <WorkflowLayout />,
        handle: {
            key: 'workflow/onboarding',
            href: '/workflow/onboarding/detail',
            showBreadcrumb: true,
            showInMenu: false,
            showDropdown: true,
            icon: <IconEngine />,
            title: i18n.t('navigation:navbar.workflow._')!,
            description: i18n.t('navigation:navbarDescriptions.workflow.onboarding')!,
            position: 'top',
        },
        children: [
            {
                ...NOT_FOUND_CONFIG,
                index: true as any,
                handle: {
                    ...NOT_FOUND_CONFIG.handle,
                    key: 'workflow-onboarding-parent',
                }
            },
            {
                path: 'detail',
                element: <React.Suspense><WorkflowOnboardingDetail /></React.Suspense>,
                handle: {
                    key: 'workflow/onboarding',
                    href: '/workflow/onboarding',
                    showBreadcrumb: true,
                    showInMenu: true,
                    icon: <IconEngine />,
                    title: i18n.t('navigation:navbar.workflow.onboarding')!,
                    description: i18n.t('navigation:navbarDescriptions.workflow.onboarding')!,
                }
            },
            {
                path: 'config',
                element: <React.Suspense><WorkflowOnboardingConfig /></React.Suspense>,
                handle: {
                    key: 'workflow/onboarding',
                    href: '/workflow/onboarding',
                    showBreadcrumb: true,
                    showInMenu: true,
                    icon: <IconEngine />,
                    title: i18n.t('navigation:navbar.workflow.onboarding')!,
                    description: i18n.t('navigation:navbarDescriptions.workflow.onboarding')!,
                }
            },
            NOT_FOUND_CONFIG,
        ]
    },
    SETTINGS_ROUTE,
    {
        path: 'account/settings',
        handle: {
            key: 'account/settings',
            showBreadcrumb: true,
            title: i18n.t('navigation:navbar.personalArea._')!,
            description: i18n.t('navigation:navbarDescriptions.personalArea._')!,
        },
        children: [
            {
                index: true as any,
                element: <AccountSettingsView />,
                handle: {
                    key: 'account/settings',
                    showBreadcrumb: true,
                    title: i18n.t('navigation:navbar.personalArea._')!,
                    description: i18n.t('navigation:navbarDescriptions.personalArea._')!,
                },
            },
            {
                path: 'integration/feedback',
                element: <AccountSettingsIntegrationFeedbackView />,
                handle: {
                    key: 'integration/feedback',
                },
            },
        ]
    },
    {
        path: 'billing/subscription',
        handle: {
            key: 'billing/subscription',
            title: i18n.t('navigation:navbar.billing.billing')!,
            description: i18n.t('navigation:navbarDescriptions.billing.billing')!,
            showBreadcrumb: true,
            permission: 'Billing',
        },
        element: CreatePermissionRedirection({
            parentPath: '/billing/subscription',
            permissions: [
                {permission: 'Billing', path: '/billing/subscription/list'},
            ],
        }, BillingPaymentLayout),
        children: [
            {
                path: 'list',
                element: <BillingSubscriptionListView />,
                handle: {
                    key: 'account/settings',
                    title: i18n.t('navigation:navbar.billing.subscriptions')!,
                    description: i18n.t('navigation:navbarDescriptions.billing.subscriptions')!,
                    showBreadcrumb: true,
                }
            },
            {
                path: 'payment-success',
                element: <BillingPaymentSuccessView />,
                handle: {
                    key: 'payment-success',
                }
            },
            NOT_FOUND_CONFIG,
        ]
    },
    {
        handle: {
            key: 'userMenu',
            position: 'bottom',
            title: i18n.t('navigation:navbar.userMenu._')!,
            description: i18n.t('navigation:navbarDescriptions.userMenu._')!,
            className: 'opacity-100',
            icon: <UserMenu />,
            showInMenu: true,
            renderIcon: true,
        },
    },
    NOT_FOUND_CONFIG,
]

export const navigationPublicItems: NavigationItem[] = [
    {
        path: 'auth/signin',
        element: <LoginView />,
        handle: {
            key: 'signin',
        },
    },
    {
        path: 'auth/signup',
        element: <SignUpRequestView />,
        handle: {
            key: 'signup',
        },
    },
    {
        path: 'auth/signup/success',
        element: <SignUpRequestSuccessView />,
        handle: {
            key: 'success',
        },
    },
    {
        path: 'auth/onboarding',
        element: <SignUpRedeemView />,
        handle: {
            key: 'onboarding',
        },
    },
    {
        path: 'auth/invitation',
        element: <RedeemInvitationView />,
        handle: {
            key: 'invitation',
        },
    },
    {
        path: 'auth/forgot-password',
        element: <ForgotPasswordRedeemView />,
        handle: {
            key: 'forgot-password/request',
        },
    },
    {
        path: 'auth/forgot-password/request',
        element: <ForgotPasswordRequestView />,
        handle: {
            key: 'forgot-password/request',
        },
    },
    {
        path: 'auth/forgot-password/request/success',
        element: <ForgotPasswordRequestSuccessView />,
        handle: {
            key: 'forgot-password/request/success',
        },
    },
    NOT_FOUND_CONFIG,
]

export const navigationItems: NavigationItem[] =  [
    {
        loader: publicLoader,
        element: <React.Suspense><PublicLayout /></React.Suspense>,
        children: navigationPublicItems,
        handle: {
            key: 'auth',
        },
    },
    {
        element: <React.Suspense><ComplaintLayout /></React.Suspense>,
        path: 'complaints',
        loader: subdomainLoader,
        children: [
            {
                index: true as any,
                element: <ComplaintCreateView />,
                handle: {
                    key: 'complaints/create',
                    showBreadcrumb: true,
                },
            },
            {
                path: 'detail/:complaintId',
                element: <ComplaintPublicDetailView />,
                handle: {
                    key: 'complaints/detail',
                    title: i18n.t('navigation:navbar.complaints.detail')!,
                    description: i18n.t('navigation:navbarDescriptions.complaints.detail')!,
                    showBreadcrumb: true,
                },
            },
            {
                path: 'login',
                element: <ComplaintLoginView />,
                handle: {
                    key: 'complaints/tracking',
                    title: i18n.t('navigation:navbar.complaints.detail')!,
                    description: i18n.t('navigation:navbarDescriptions.complaints.detail')!,
                    showBreadcrumb: true,
                },
            },
            {
                path: 'gateway/:complaintId',
                element: <ComplaintGatewayView />,
                handle: {
                    key: 'complaints/gateway',
                    showBreadcrumb: false,
                },
            }
        ],
        handle: {
            key: 'auth',
            title: i18n.t('navigation:navbar.complaints.main')!,
            showBreadcrumb: true,
        },
    },
    {
        loader: privateLoader,
        errorElement: <React.Suspense><PrivateLayout><Error500 /></PrivateLayout></React.Suspense>,
        element: <React.Suspense><PrivateLayout /></React.Suspense>,
        children: navigationPrivateItems,
        handle: {
            key: 'private',
        },
    },
    {
        path: 'subscription-invalid',
        element: <NotPaidSubscription />,
        handle: {
            key: 'subscription-invalid',
        },
    },
]