/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/jsx-key */
import SubscriptionBannerList from '@viterbit/web-app/feature/billing/SubscriptionBannerList'
import { navigationPrivateItems } from '@viterbit/web-app/routes'
import { HeaderMegaMenu, NavigationGroup } from '@viterbit/web-app/shared/layout/HeaderMegaMenu'
import groupBy from 'lodash/groupBy'
import * as React from 'react'
import { Outlet } from 'react-router-dom'

import { useUser } from '../employee/useUser'
import SpotlightProvider from '../feedback/SpotlightProvider'
import withBillingProtected from '../permission/withBillingProtected'


const PrivateLayout = ({children}: {children?: React.ReactNode}) => {
    const user = useUser()
    const group: NavigationGroup = React.useMemo(() => groupBy(navigationPrivateItems.filter(item => item.handle.showInMenu), 'handle.position'), [])

    if (!user) {
        return null
    }

    return (
        <SpotlightProvider>
            <div className='flex flex-col h-full'>
                <HeaderMegaMenu group={group} user={user} />
                <SubscriptionBannerList />
                <Outlet />
                <div>
                    {children}
                </div>
            </div>
        </SpotlightProvider>
    )
}


export default withBillingProtected(PrivateLayout)
