import { ITEMS_BY_PAGE } from '@viterbit/web-app/dataAccess/model/Week.model'

const fetchWithoutPagination =
    (caller) => async (data?: Parameters<ReturnType<typeof caller>>) => {
        const response = await caller({ page: 1, ...data })

        const totalPages = Math.ceil(response.meta.total / ITEMS_BY_PAGE)

        const promises = await Promise.all(
            Array.from({ length: totalPages - 1 }).map((_, index) =>
                caller({ page: index + 2, ...data })
            )
        )

        const results = promises.reduce(
            (acc, res) => [...acc, ...res.data],
            response.data
        )
        return results
    }

export default fetchWithoutPagination
