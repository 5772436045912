/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CheckLeaveQueryResponse
 */
export interface CheckLeaveQueryResponse {
    /**
     * 
     * @type {number}
     * @memberof CheckLeaveQueryResponse
     */
    total?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CheckLeaveQueryResponse
     */
    totalComputable?: number | null;
}

/**
 * Check if a given object implements the CheckLeaveQueryResponse interface.
 */
export function instanceOfCheckLeaveQueryResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CheckLeaveQueryResponseFromJSON(json: any): CheckLeaveQueryResponse {
    return CheckLeaveQueryResponseFromJSONTyped(json, false);
}

export function CheckLeaveQueryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CheckLeaveQueryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total': !exists(json, 'total') ? undefined : json['total'],
        'totalComputable': !exists(json, 'totalComputable') ? undefined : json['totalComputable'],
    };
}

export function CheckLeaveQueryResponseToJSON(value?: CheckLeaveQueryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total': value.total,
        'totalComputable': value.totalComputable,
    };
}

