import { IconCheck } from '@tabler/icons-react'
import * as React from 'react'

import AnimatePop from '../Animation/AnimatePop'
import Spinner from '../Spinner'
import { CONTROL_SIZES, SIZES } from '../../constants/constant'
import { clsxm } from '../../lib/clsxm'

const useColorLevel = (level: string): (string | undefined)[] => {
    const colorLevel = [
        '50',
        '100',
        '200',
        '300',
        '400',
        '500',
        '600',
        '700',
        '800',
        '900',
    ]
    const index = colorLevel.indexOf(level)

    const calculateLevel = (action: string): string | undefined => {
        if (index === 0 || index === colorLevel.length - 1) {
            return level
        }
        if (action === 'decrement') {
            return colorLevel[index - 1]
        }
        if (action === 'increment') {
            return colorLevel[index + 1]
        }
    }

    const decreaseLevel = calculateLevel('decrement')

    const increaseLevel = calculateLevel('increment')

    return [increaseLevel, decreaseLevel]
}

type Shape = 'rounded' | 'none' | 'circle'
type Variant = 'solid' | 'twoTone' | 'plain' | 'default'
type Size = 'xxs' | 'xs' | 'sm' | 'md' | 'lg'

export type ButtonProps = {
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
    children?: React.ReactNode
    color?: string
    disabled?: boolean
    loading?: boolean
    success?: boolean
    block?: boolean
    shape?: Shape
    'data-testid'?: string
    className?: string
    variant?: Variant
    icon?: React.ReactNode
    active?: boolean
    size?: Size
    component?: React.ElementType | string
    LoaderIndicator?: React.ElementType
    htmlFor?: string
    successText?: string
} & React.ButtonHTMLAttributes<HTMLButtonElement>

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    (props, ref) => {
        const {
            children,
            size = 'md',
            color = 'primary-600',
            variant = 'default',
            shape = 'rounded',
            type= 'button',
            active = false,
            loading = false,
            success = false,
            disabled = false,
            block,
            icon,
            className,
            LoaderIndicator: Loader = Spinner,
            onClick,
            component: Component = 'button',
            successText,
            'data-testid': dataTestId = 'button',
            ...rest
        } = props

        const [isBlinkingSuccess, setIsBlinkingSuccess] = React.useState(false)

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const splitedColor = color!.split('-')
        const buttonColor = splitedColor[0]
        const buttonColorLevel = splitedColor[1]
        const buttonSize = size

        const [increaseLevel, decreaseLevel] = useColorLevel(buttonColorLevel)

        const sizeIconClass = 'inline-flex items-center justify-center'

        React.useEffect(() => {
            if (success) {
                setIsBlinkingSuccess(true)
                setTimeout(() => {
                    setIsBlinkingSuccess(false)
                }, 2000)
            }
        }, [success])

        const getButtonSize = () => {
            let sizeClass = ''
            switch (buttonSize) {
            case SIZES.LG:
                sizeClass = clsxm(
                    `h-${CONTROL_SIZES.lg}`,
                    icon && !children
                        ? `w-${CONTROL_SIZES.lg} ${sizeIconClass} text-2xl`
                        : 'px-8 py-2 text-base'
                )
                break
            case SIZES.SM:
                sizeClass = clsxm(
                    `h-${CONTROL_SIZES.sm}`,
                    icon && !children
                        ? `w-${CONTROL_SIZES.sm} ${sizeIconClass} text-lg`
                        : 'px-3 py-2 text-sm'
                )
                break
            case SIZES.XS:
                sizeClass = clsxm(
                    `h-${CONTROL_SIZES.xs}`,
                    icon && !children
                        ? `w-${CONTROL_SIZES.xs} ${sizeIconClass} text-base`
                        : 'px-3 py-1 text-xs'
                )
                break
            case SIZES.XXS:
                sizeClass = clsxm(
                    `h-${CONTROL_SIZES.xxs}`,
                    icon && !children
                        ? `w-${CONTROL_SIZES.xxs} ${sizeIconClass} text-base`
                        : 'px-3 py-1 text-xs'
                )
                break
            default:
                sizeClass = clsxm(
                    `h-${CONTROL_SIZES.md}`,
                    icon && !children
                        ? `w-${CONTROL_SIZES.md} ${sizeIconClass} text-xl`
                        : 'px-8 py-2'
                )
                break
            }
            return sizeClass
        }

        const disabledClass = 'opacity-50 cursor-not-allowed pointer-events-none'

        const solidColor = () => {
            const btn = {
                bgColor: active
                    ? `bg-${buttonColor}-${increaseLevel}`
                    : `bg-${buttonColor}-${buttonColorLevel}`,
                textColor: 'text-white',
                hoverColor: active
                    ? ''
                    : `hover:bg-${buttonColor}-${decreaseLevel}`,
                activeColor: `active:bg-${buttonColor}-${increaseLevel}`,
                successColor: 'bg-success-600 pointer-events-none',
            }
            return getBtnColor(btn)
        }

        const twoToneColor = () => {
            const btn = {
                bgColor: active
                    ? `bg-${buttonColor}-200 dark:bg-${buttonColor}-50`
                    : `bg-${buttonColor}-50 dark:bg-${buttonColor}-500 dark:bg-opacity-20`,
                textColor: `text-${buttonColor}-${buttonColorLevel} dark:text-${buttonColor}-50`,
                hoverColor: active
                    ? ''
                    : `hover:bg-${buttonColor}-100 dark:hover:bg-${buttonColor}-500 dark:hover:bg-opacity-30`,
                activeColor: `active:bg-${buttonColor}-200 dark:active:bg-${buttonColor}-500 dark:active:bg-opacity-40`,
                successColor:
                    'bg-success-200 dark:bg-success-50 pointer-events-none',
            }
            return getBtnColor(btn)
        }

        const defaultColor = () => {
            const btn = {
                bgColor: active
                    ? 'bg-gray-100 border border-gray-300 dark:bg-gray-500 dark:border-gray-500'
                    : 'bg-white border border-gray-300 dark:bg-gray-700 dark:border-gray-700',
                textColor: 'text-black dark:text-gray-100',
                hoverColor: active
                    ? ''
                    : 'hover:bg-gray-50 dark:hover:bg-gray-600',
                activeColor:
                    'active:bg-gray-100 dark:active:bg-gray-500 dark:active:border-gray-500',
                successColor:
                    'bg-success-100 border border-success-300 dark:bg-success-500 dark:border-success-500 pointer-events-none',
            }
            return getBtnColor(btn)
        }

        const plainColor = () => {
            const btn = {
                bgColor: active
                    ? 'bg-gray-100 dark:bg-gray-500'
                    : 'bg-transparent border border-transparent',
                textColor: 'text-black dark:text-gray-100',
                hoverColor: active
                    ? ''
                    : 'hover:bg-gray-50 dark:hover:bg-gray-600',
                activeColor:
                    'active:bg-gray-100 dark:active:bg-gray-500 dark:active:border-gray-500',
                successColor:
                    'bg-success-100 dark:bg-success-500 pointer-events-none',
            }
            return getBtnColor(btn)
        }

        type BtnColor = {
            bgColor: string
            textColor: string
            hoverColor: string
            activeColor: string
            successColor: string
        }

        const getBtnColor = ({
            bgColor,
            hoverColor,
            activeColor,
            textColor,
            successColor,
        }: BtnColor) => {
            let stateColor

            if (isBlinkingSuccess) {
                stateColor = successColor
            } else if (disabled || loading) {
                stateColor = disabledClass
            } else {
                stateColor = hoverColor + ' ' + activeColor
            }

            return `${bgColor} ${textColor} ${stateColor}`
        }

        const btnColor = () => {
            switch (variant) {
            case 'solid':
                return solidColor()
            case 'twoTone':
                return twoToneColor()
            case 'plain':
                return plainColor()
            case 'default':
                return defaultColor()
            default:
                return defaultColor()
            }
        }

        const classes = clsxm(
            'font-semibold focus:outline-none whitespace-nowrap',
            shape === 'none' && 'rounded-none',
            shape === 'rounded' && 'rounded-md',
            shape === 'circle' && 'rounded-full',
            btnColor(),
            `radius-${shape}`,
            getButtonSize(),
            className,
            block ? 'w-full' : '',
            'disabled:pointer-events-none'
        )

        const sucessIcon = (
            <AnimatePop open={isBlinkingSuccess}>
                <IconCheck className='h-full mr-2' />
            </AnimatePop>
        )
        const animatedLoader = (
            <Loader
                isSpinning={loading && !isBlinkingSuccess}
                className={
                    loading ? (isBlinkingSuccess ? 'hidden' : 'mr-2') : ''
                }
                color={variant === 'solid' ? 'white' : undefined}
            />
        )

        const renderChildren = () => {
            const mainText = (isBlinkingSuccess && successText) || children

            if (icon && children) {
                return (
                    <span className='flex items-center justify-center'>
                        {loading ? (
                            <Loader
                                className='mr-1'
                                isSpinning
                                color={
                                    variant === 'solid' ? 'white' : undefined
                                }
                            />
                        ) : (
                            <span className='mr-1 text-lg '>{icon}</span>
                        )}
                        <span className='ltr:ml-1 rtl:mr-1'>{mainText}</span>
                    </span>
                )
            }

            if (icon) {
                return loading ? (
                    <Loader
                        isSpinning
                        color={variant === 'solid' ? 'white' : undefined}
                    />
                ) : (
                    icon
                )
            }

            return (
                <>
                    <span className='flex items-center justify-center'>
                        {sucessIcon}
                        {animatedLoader}
                        {mainText}
                    </span>
                </>
            )
        }

        return (
            <Component
                ref={ref}
                type={type}
                className={classes}
                {...rest}
                onClick={onClick}
                disabled={disabled || loading || isBlinkingSuccess}
                data-testid={dataTestId}
            >
                {renderChildren()}
            </Component>
        )
    }
)

export default Button
