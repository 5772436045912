/* eslint-disable @typescript-eslint/no-explicit-any */

const useMergedRef =
    (...refs: any[]) =>
        (element: unknown) =>
            refs.forEach((ref) => {
                if (typeof ref === 'function') {
                    ref(element)
                } else if (ref && typeof ref === 'object') {
                    ref.current = element
                }
            })

export default useMergedRef
