/* eslint-disable @typescript-eslint/no-explicit-any */

import { useEmployeeList } from '@viterbit/web-app/dataAccess/useCase/employee/useEmployeeList'
import EmployeeItem from '@viterbit/web-app/feature/employee/item/EmployeeItem'
import i18n from '@viterbit/web-app/i18n'
import {debounce} from 'lodash'
import * as React from 'react'
import { useFieldArray } from 'react-hook-form'
import Checkbox from 'ui/src/components/Checkbox'
import DataTable from 'ui/src/components/DataTable'

export type TeamAssignEmployeeFormProps = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control: any
    name: string
    query: TeamAssignEmployeeFormQuery
    teamId?: string
    complaintManager?: boolean
}

type TeamAssignEmployeeFormQuery = {
    search?: string
    companyId?: string,
    locationId?: string,
    reporters?: boolean
}

const TeamAssignEmployeeForm = ({
    control,
    name,
    query: _query,
    teamId,
    complaintManager,
}: TeamAssignEmployeeFormProps) => {
    const [queryPayload, setQueryPayload] = React.useState<TeamAssignEmployeeFormQuery & {page: number}>({
        ..._query,
        page: 1
    })

    const {data, isLoading: loading} = useEmployeeList({
        query: {
            ...queryPayload,
            companyId: queryPayload.companyId || undefined,
            locationId: queryPayload.locationId || undefined,
            pageSize: 5,
            notLeadersOfTeamId: teamId || undefined,
            notMembersOfTeamId: teamId || undefined,
            complaintManager: complaintManager,
        }
    })

    const tUI = (key: string) => i18n.getFixedT(`ui:${key}`)
    const tEmployee = (key: string) => i18n.getFixedT(`employees:${key}`)

    React.useEffect(() => {
        queryPayload.search === _query.search
            ? setQueryPayload({
                ...queryPayload,
                ..._query
            })
            : setQueryDebounce(_query)
    }, [_query])

    const setQueryDebounce = React.useCallback(debounce((query: TeamAssignEmployeeFormQuery) => {
        setQueryPayload({
            ...queryPayload,
            ...query
        })
    }, 1000), [])
    
    const { fields, append, remove } = useFieldArray({
        control,
        name,
        keyName: 'uuid' as 'id'
    })

    
    return (
        <div>
            <DataTable
                classes={{
                    tableContainer: 'border rounded-md',
                    table: 'border rounded-md overflow-hidden',
                    tbody: {
                        td: 'font-normal text-grey-600 truncate',
                    },
                    pagination: {
                        container: 'w-full border-t mt-6',
                        component: 'w-full justify-between',
                    },
                }}
                paginationVariant='tabs'
                data={data?.data ?? []}
                pagingData={{
                    total: data?.meta.total ?? 0,
                    pageIndex: queryPayload.page,
                    pageSize: 5,
                    sort: { order: 'asc', key: 'id' },
                }}
                onPaginationChange={page => setQueryPayload({...queryPayload, page})}
                columns={[
                    {
                        accessor: 'name',
                        Cell: ({ row }) => (
                            <label data-testid="form-employees-label" htmlFor={`checkbox-${row.original.id}`}>
                                <EmployeeItem employee={row.original} />
                            </label>
                        ),
                        Header: () => (
                            <label className='text-primary-600'>
                                {tEmployee('teams.employee.columnsHeader') as any} ({fields.length})
                            </label>
                        )
                    },
                    {
                        id: 'selection',
                        Cell: ({ row,  }) => {
                            const fieldIndex = fields.findIndex(x => x.id === row.original.id)
                            const isChecked = fieldIndex >= 0
                            return(
                                <Checkbox
                                    key={row.original.id}
                                    id={`checkbox-${row.original.id}`}
                                    data-testid={`checkbox-${fieldIndex}`}
                                    onChange={e => {
                                        e.target.checked
                                            ? append(row.original)
                                            : remove(fieldIndex)
                                    }}
                                    defaultChecked={isChecked}
                                />
                            )
                        },
                    },
                ]}
                loading={loading}
                t={tUI as any}
            />
        </div>
    )
}

export default TeamAssignEmployeeForm
