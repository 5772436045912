import { useMutation } from '@tanstack/react-query'

import { accountResendInvitation } from '../../service/accountService'

type AccountDetailConfig = {
    onSuccess?: () => void
    onError?: () => void
}

export const useAccountResendInvitation = (config: AccountDetailConfig) =>
    useMutation({
        ...config,
        mutationFn: accountResendInvitation,
        onSuccess: config.onSuccess,
        onError: config.onError,
    })
