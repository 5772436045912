/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useOrganizationPublicDetail } from '@viterbit/web-app/dataAccess/useCase/organization/useOrganizationPublicDetail'
import useHostInfo from '@viterbit/web-app/shared/utils/useHostInfo'
import { HostInfo } from '@viterbit/web-app/shared/utils/getHostInfo'
import throwException from '@viterbit/web-app/shared/utils/throwException'
import { useLoaderData, useParams } from 'react-router-dom'
import { useLocation } from 'react-use'

export const useComplaintOrganization = () => {
    const { host = '' } = useLocation()
    const { subdomain } = useHostInfo(host) as HostInfo
    const result = useOrganizationPublicDetail({
        query: {
            query: subdomain!,
        },
        enabled: !!subdomain
    })

    if (!subdomain) {
        throwException('Subdomain is not provided')
    }
    
    if (result.failureReason?.status === 400) {
        throwException(`Organization not found : ${subdomain}`)
    }

    return result
}