
import { useMutation } from '@tanstack/react-query'
import { Calendar } from '@viterbit/web-app/dataAccess/model/Calendar.model'
import { Location } from '@viterbit/web-app/dataAccess/model/Location.model'
import { locationUpdate } from '@viterbit/web-app/dataAccess/service/locationService'
import i18n from '@viterbit/web-app/i18n'
import ApiErrorMessage from '@viterbit/web-app/shared/feedback/ApiErrorMessage'
import * as React from 'react'

import FormDeleteDialog from '../../../../shared/form/FormDeleteDialog'

type DeleteHolidayItemProps = {
    children?: React.ReactNode
    className?: string
    calendar: Calendar | null
    onCancel: () => void
    onSuccess: () => void
    location: Location
}

const DeleteHolidayItem = (props: DeleteHolidayItemProps) => {
    const { calendar, onCancel, onSuccess, location } = props

    const { mutate, isLoading, error} = useMutation({
        onSuccess: onSuccess,
        mutationFn: locationUpdate,
    })

    const onSubmit = () => {
        const newCalendar = location.calendar.filter((item) => item.year !== calendar?.year)
        mutate({
            id: location.id,
            updateLocationCommand: {
                calendar: newCalendar,
            }
        })
    }

    return (
        <FormDeleteDialog
            isOpen={!!calendar}
            onClose={onCancel}
            isLoading={isLoading}
            onSubmit={onSubmit}
            title={i18n.t('settingLocations:deleteTitle')}
        >
            <ApiErrorMessage error={error} />
        </FormDeleteDialog>

    )
}

export default DeleteHolidayItem
