/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation } from '@tanstack/react-query'
import { ResourceCreated } from 'viterbit-api-sdk'

import { pendingFileRespond } from '../../service/pendingFilesService'


type EmployeeDeleteConfig = {
    onSuccess?: () => void
    onError?: (error: any, variables: any) => void ;
}

type Payload = Parameters<typeof pendingFileRespond>[0]

export const usePendingFileRespond = (config: EmployeeDeleteConfig) =>
    useMutation<ResourceCreated, unknown, Payload>({
        ...config,
        mutationFn: pendingFileRespond,
        onSuccess: config.onSuccess,
        onError: config.onError,
    })
