/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StateHolidaysData } from './StateHolidaysData';
import {
    StateHolidaysDataFromJSON,
    StateHolidaysDataFromJSONTyped,
    StateHolidaysDataToJSON,
} from './StateHolidaysData';

/**
 * 
 * @export
 * @interface FindStateQueryResponse
 */
export interface FindStateQueryResponse {
    /**
     * 
     * @type {string}
     * @memberof FindStateQueryResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof FindStateQueryResponse
     */
    country: string;
    /**
     * 
     * @type {Array<StateHolidaysData>}
     * @memberof FindStateQueryResponse
     */
    holidays?: Array<StateHolidaysData>;
}

/**
 * Check if a given object implements the FindStateQueryResponse interface.
 */
export function instanceOfFindStateQueryResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "country" in value;

    return isInstance;
}

export function FindStateQueryResponseFromJSON(json: any): FindStateQueryResponse {
    return FindStateQueryResponseFromJSONTyped(json, false);
}

export function FindStateQueryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindStateQueryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'country': json['country'],
        'holidays': !exists(json, 'holidays') ? undefined : ((json['holidays'] as Array<any>).map(StateHolidaysDataFromJSON)),
    };
}

export function FindStateQueryResponseToJSON(value?: FindStateQueryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'country': value.country,
        'holidays': value.holidays === undefined ? undefined : ((value.holidays as Array<any>).map(StateHolidaysDataToJSON)),
    };
}

