import * as React from 'react'
import clsxm from 'ui/src/lib'

type FooterProps = {
    className?: string
}

const Footer = ({className }: FooterProps) => (
    <footer className={clsxm('flex items-center justify-center w-full h-10', className)}>
        <p className='font-semibold'>
            Made with <span className='text-red-600 animate-pulse'>❤️</span> by
            Viterbit
        </p>
    </footer>
)



export default Footer
