/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FindIntegrationQueryResponse } from './FindIntegrationQueryResponse';
import {
    FindIntegrationQueryResponseFromJSON,
    FindIntegrationQueryResponseFromJSONTyped,
    FindIntegrationQueryResponseToJSON,
} from './FindIntegrationQueryResponse';

/**
 * 
 * @export
 * @interface IntegrationsDataQueryResponse
 */
export interface IntegrationsDataQueryResponse {
    /**
     * 
     * @type {Array<FindIntegrationQueryResponse>}
     * @memberof IntegrationsDataQueryResponse
     */
    data: Array<FindIntegrationQueryResponse>;
}

/**
 * Check if a given object implements the IntegrationsDataQueryResponse interface.
 */
export function instanceOfIntegrationsDataQueryResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function IntegrationsDataQueryResponseFromJSON(json: any): IntegrationsDataQueryResponse {
    return IntegrationsDataQueryResponseFromJSONTyped(json, false);
}

export function IntegrationsDataQueryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntegrationsDataQueryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(FindIntegrationQueryResponseFromJSON)),
    };
}

export function IntegrationsDataQueryResponseToJSON(value?: IntegrationsDataQueryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ((value.data as Array<any>).map(FindIntegrationQueryResponseToJSON)),
    };
}

