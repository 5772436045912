import LeaveMapper from '@viterbit/web-app/dataAccess/mapper/leave.mapper'
import { DEFAULT_REASON_COLOR } from '@viterbit/web-app/dataAccess/mapper/leaveReason.mapper'
import { Leave } from '@viterbit/web-app/dataAccess/model/Leave.model'
import DateTimeItem from '@viterbit/web-app/shared/display/DateTimeItem'
import * as React from 'react'

type LeaveItemProps = {
    className?: string
    leave: Leave
    onClick: () => void
}

const LeaveItem = ({ leave, onClick }: LeaveItemProps) => (
    <DateTimeItem
        id={leave.id}
        title={leave.leaveReason?.name || ''}
        color={leave.leaveReason?.color || DEFAULT_REASON_COLOR}
        initialDate={leave.initialDate}
        endDate={leave.endDate}
        onClick={onClick}
        content={LeaveMapper.getLeaveDayText({
            halfDay: Boolean(leave.halfDay),
            calendar: leave.calendar
        })}
        data-testid='leave-item'
    />
)

export default LeaveItem
