
import { IWeekDays } from '@viterbit/web-app/dataAccess/model/Week.model'
import { RelevantDate } from '@viterbit/web-app/feature/leave/list/types'
import { isSameDate, parseDateUTC } from '@viterbit/web-app/shared/utils/dateParse'
import dayjs from 'dayjs'
import Tooltip from 'ui/src/components/Tooltip'

import { RelevantDayType } from './helpers'
import Holiday from './Holiday'
import ActionSpan, { FormFields } from '../ActionSpan'

const getRelevantDayComponent = (relevantDate: RelevantDate) => {
    const getRelevantComponentName = () => RelevantDayType.Holiday

    const componentName = getRelevantComponentName()

    const componentDict = {
        [RelevantDayType.Holiday]: <Holiday relevantDate={relevantDate} />,
    }

    return componentDict[componentName]
}

type RelevantDayProps = {
    date: string
    relevantDates: RelevantDate[]
    year: number
    month: number
    workableDays: IWeekDays[]
    clickable?: boolean
    onSubmit?: (data: FormFields) => void
}

const RelevantDay = ({
    date,
    relevantDates,
    year,
    month,
    workableDays,
    clickable,
    onSubmit
}: RelevantDayProps) => {
    const day = dayjs(date).format('DD')
    const monthDate = new Date(year, month, parseInt(day, 10))
    const isRelevantDate = relevantDates.find((rd) =>
        isSameDate(rd.date, monthDate)
    )

    const dayOfWeek = dayjs(date).format('dddd').toUpperCase() as IWeekDays
    const isNotWorkableDay = !workableDays?.includes(dayOfWeek)
    if (isNotWorkableDay && !isRelevantDate) {
        return <span className='text-gray-400'>{day}</span>
    }

    const content = <span>{day}</span>

    if (!isRelevantDate) {
        return clickable ? (
            <ActionSpan
                initialDate={parseDateUTC(date)}
                onSubmit={onSubmit}
            >
                <Tooltip
                    title='Add holiday'
                    tooltipClass='bg-white z-50 max-w-[250px] text-gray-800 rounded-lg py-2 px-4 relative shadow-xl border-gray-100 radius-rounded'
                    arrowClassColor='white-800'
                    arrowClassColorDark='white-800'
                >
                    {content}
                </Tooltip>
            </ActionSpan>
        ) : (
            content
        )
    }

    return getRelevantDayComponent(isRelevantDate)
}

export default RelevantDay
