/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { City } from '@viterbit/web-app/dataAccess/model/City.model'
import { geoDataCityList } from '@viterbit/web-app/dataAccess/service/geoDataService'
import { GroupBase } from 'react-select'
import {
    FormSelectAsyncPaginate,
    FormSelectAsyncPaginateProps,
} from 'ui/src/components/Form'
import { FormBaseProps } from 'ui/src/components/Form/shared'

import { useNotification } from '../feedback/useNotification'


type FormSelectCityProps<IsMulti extends boolean> = FormBaseProps &
    Omit<
        FormSelectAsyncPaginateProps<
            City,
            IsMulti,
            GroupBase<City>,
            { page: number }
        >,
        'loadOptions' | 'defaultValue'
    > & {
        query?: Omit<Parameters<typeof geoDataCityList>[0], 'page'>,
        defaultValue?: string
    }

type LoadOptions = FormSelectAsyncPaginateProps<
    City,
    boolean,
    GroupBase<City>,
    { page: number }
>['loadOptions']

const FormSelectCity = <IsMulti extends boolean>({
    name,
    control,
    cacheOptions = true,
    isSearchable = true,
    query,
    defaultValue,
    ...rest
}: FormSelectCityProps<IsMulti>) => {
    const notification = useNotification()
    const loadOptions: LoadOptions = async (search, loadedOptions, _meta) =>
        geoDataCityList({ ...query!, page: _meta?.page, search })
            .then(({ data, meta }) => {
                const hasMore =
                    !!meta?.total &&
                    meta.page <
                        Math.ceil(meta.total / ((meta as any).pageSize ?? 10))
                return {
                    options: data,
                    hasMore,
                    additional: {
                        page: meta.page + 1,
                    },
                }
            })
            .catch((err) => {
                notification.push(
                    'danger',
                    'Fetching error',
                    err.errorCode ?? 'Error at fetch Cities'
                )
                return {
                    options: [],
                    hasMore: false,
                }
            })
    return (
        <FormSelectAsyncPaginate
            name={name}
            control={control}
            cacheOptions={cacheOptions}
            loadOptions={loadOptions}
            isSearchable={isSearchable}
            getOptionLabel={(x) => x.name}
            getOptionValue={(x) => x.name}
            loadOptionsOnMenuOpen
            additional={{
                page: 1,
            }}
            defaultValue={defaultValue && { name: defaultValue } as any}
            {...rest}
        />

    )
}

export default FormSelectCity
