/* eslint-disable @typescript-eslint/no-non-null-assertion */

import FormSkeleton from 'ui/src/components/Form/FormSkeleton'

import DeletePoliciesAction from './DeletePoliciesAction'
import DetailsLeavePoliciesForm from './DetailsLeavePoliciesForm'
import { useLeavePolicy } from '../useLeavePolicy'

const DetailsLeavePoliciesView = () => {
    const { data: leavePolicy, isLoading } = useLeavePolicy()

    if (isLoading) {
        return <FormSkeleton className='mt-[100px]'/>
    }

    return (
        <>
            <DetailsLeavePoliciesForm key={leavePolicy!.id} leavePolicy={leavePolicy!} />
            <DeletePoliciesAction leavePolicy={leavePolicy} />   
        </>
    )
}

export default DetailsLeavePoliciesView
