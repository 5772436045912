import * as React from 'react'
import { ToastOptions } from 'react-toastify'
import Notification from 'ui/src/components/Notification'
import { toast } from 'ui/src/components/Toast'

type NotificationType = 'danger' | 'success' | 'warning' | 'info'

export const useNotification = () => {
    const push = (
        type: NotificationType,
        title: string,
        message: string,
        options?: ToastOptions
    ) => {
        toast.push(
            <Notification type={type} title={title}>
                {message}
            </Notification>,
            options
        )
    }

    return { push }
}
