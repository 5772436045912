import { IconX } from '@tabler/icons-react'
import * as React from 'react'

import { clsxm } from '../../lib/clsxm'

type CloseButtonProps = {
    absolute?: boolean
    className?: string
    defaultStyle?: boolean
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
}

const CloseButton = React.forwardRef<HTMLButtonElement, CloseButtonProps>(
    (props, ref) => {
        const { absolute, className, defaultStyle = true, ...rest } = props
        const closeButtonClass = clsxm(
            'outline-none',
            defaultStyle &&
                'text-xl rounded p-1 text-gray-400 hover:text-black dark:hover:text-gray-300',
            absolute && 'absolute z-10',
            className
        )

        return (
            <span
                data-testid='close-button'
                className={closeButtonClass}
                role='button'
                {...rest}
                ref={ref}
            >
                <IconX />
            </span>
        )
    }
)

export default CloseButton
