import { useMutation } from '@tanstack/react-query'

import { integrationDelete } from '../../service/integrationService'


type IntegrationDeleteParams = {
    onSuccess?: () => void
    onError?: () => void
}

export const useIntegrationDelete = (config: IntegrationDeleteParams) =>
    useMutation({
        ...config,
        mutationFn: integrationDelete,
        onSuccess: config.onSuccess,
        onError: config.onError,
    })
