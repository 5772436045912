import * as React from 'react'

type ColorOptionProps = {
    children?: React.ReactNode
    color: string
}

const ColorOption = ({ color, children }: ColorOptionProps) => (
    <div className='flex flex-row items-center'>
        <div
            className='w-3 h-3 mr-2 rounded-full'
            style={{ backgroundColor: color }}
        ></div>
        {children}
    </div>
)

export default ColorOption
