import i18n from '@viterbit/web-app/i18n'
import TwoColumnsLayout from '@viterbit/web-app/shared/layout/TwoColumnsLayout'
import * as React from 'react'
import { Outlet } from 'react-router-dom'

import JobPositionCreate from './create/JobPositionCreate'
import JobPositionList from './list/JobPositionList'

const JobPositionLayout = () => (
    <TwoColumnsLayout
        title={i18n.t('employees:jobPositions.header')}
        leftTitle={i18n.t('employees:jobPositions.header')}
        leftActions={<JobPositionCreate />}
        left={<JobPositionList />}
    >
        <Outlet />
    </TwoColumnsLayout>
)

export default JobPositionLayout
