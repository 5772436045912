/* eslint-disable @typescript-eslint/no-explicit-any */
import { isISO31661Alpha2 } from '@viterbit/web-app/shared/utils/ClassValidators'
import { countries } from 'country-flag-icons'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import React from 'react'
import { GroupBase } from 'react-select'
import { FormSelect, FormSelectProps } from 'ui/src/components/Form'
import { FormBaseProps } from 'ui/src/components/Form/shared'

type Option = {
    value: string
    label: string
    placeholder: string
    defaultValue?: string
}

type Props<IsMulti extends boolean> =
    FormBaseProps &
    Omit<FormSelectProps<Option, IsMulti, GroupBase<Option>>, 'defaultValue'> & {
        locale?: string
    }

const getCountryMapper = (
    locale: string
): ((country: string) => { label: string; value: string }) => {
    const getCountryNames = new Intl.DisplayNames([locale], { type: 'region' })
    return (country: string) => ({
        label: `${getUnicodeFlagIcon(country)} ${getCountryNames.of(country)}`,
        value: country,
    })
}

const FormSelectCountry = <IsMulti extends boolean>({
    locale = 'es',
    name = 'country',
    label = 'Country',
    placeholder = 'Enter your country',
    control,
    ...rest
}: Props<IsMulti>) => {
    const countryOptions = countries
        .filter(isISO31661Alpha2)
        .map(getCountryMapper(locale))
    
    return (
        <FormSelect
            name={name}
            control={control}
            label={label}
            placeholder={placeholder}
            options={countryOptions as any}
            {...rest}
        />
    )
}

export default FormSelectCountry
