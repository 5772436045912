import BillingPaymentMessageView from '@viterbit/web-app/feature/billing/payment/BillingPaymentMessageView'
import i18n from '@viterbit/web-app/i18n'
import image from '@viterbit/web-assets/images/success_payment.svg'
import { Link } from 'react-router-dom'
import Button from 'ui/src/components/Button'

const BillingPaymentSuccessView = () => (
    <BillingPaymentMessageView
        title={i18n.t('billing:paymentSuccess')}
        subtitle={i18n.t('billing:paymentSuccessDescription')}
        image={{src: image, alt: 'Payment Success', position: 'left'}}
        data-testid='payment-success-view'
    >
        <Link to='/billing/subscription/list'>
            <Button variant='solid' data-testid='payment-success-view-button'>
                {i18n.t('billing:viewSubscription')}
            </Button>
        </Link>
    </BillingPaymentMessageView>
)

export default BillingPaymentSuccessView