import { classValidatorResolver } from '@hookform/resolvers/class-validator'
import { AssignEmployeesToTeamPayload } from '@viterbit/web-app/dataAccess/dto/team'
import { Employee } from '@viterbit/web-app/dataAccess/model/Employee.model'
import { Team } from '@viterbit/web-app/dataAccess/model/Team.model'
import { teamAssignEmployee, teamUpdate } from '@viterbit/web-app/dataAccess/service/teamService'
import { useTeamUnassignEmployee } from '@viterbit/web-app/dataAccess/useCase/team/useTeamUnassignEmployee'
import i18n from '@viterbit/web-app/i18n'
import EmbeddedContent from '@viterbit/web-app/shared/behaviour/EmbeddedContent'
import AsideCardInfo from '@viterbit/web-app/shared/display/AsideCardInfo'
import ButtonCreate from '@viterbit/web-app/shared/form/ButtonCreate'
import Form from '@viterbit/web-app/shared/form/Form'
import SubmitButton from '@viterbit/web-app/shared/form/SubmitButton'
import * as React from 'react'
import Dialog from 'ui/src/components/Dialog'
import {
    FormInput,
} from 'ui/src/components/Form'

import TeamDetailValidator from './TeamDetailValidator'
import TeamAssignEmployeeDialog from '../assignEmployee/TeamAssignEmployeeDialog'
import TeamLeaderList from '../leader/TeamLeaderList'
import TeamMemberView from '../member/TeamMemberView'
import TeamUnassignEmployeeDialog from '../unassign/TeamUnassignEmployeeDialog'

export type TeamDetailFormProps = {
    team: Team
    children?: React.ReactNode
}

const resolver = classValidatorResolver(TeamDetailValidator)

type AssignEmployees = {
    open: boolean
    type: AssignEmployeesToTeamPayload['assignAs']
}

type UnAssignEmployees = {
    open: boolean
    type: AssignEmployeesToTeamPayload['assignAs']
    employees: Employee[]
}

const TeamDetailForm = ({
    team,
    children
}: TeamDetailFormProps) => {
    const [assignData, setAssignData] = React.useState<AssignEmployees>({
        open: false,
        type: 'LEADER'
    })

    const [unAssignData, setUnAssignData] = React.useState<UnAssignEmployees>({
        open: false,
        type: 'LEADER',
        employees: []
    })
    
    const stopAssign = React.useCallback(() => setAssignData(state => ({...state, open: false})), [])
    const onAssign = React.useCallback((type: AssignEmployeesToTeamPayload['assignAs']) => (event: any) => {
        event?.stopPropagation()
        setAssignData(state => ({
            ...state,
            open: true,
            type,
        }))
    }, [])
    
    const stopUnAssign = React.useCallback(() => setUnAssignData(state => ({...state, open: false})), [])
    const onUnAssign = React.useCallback((type: AssignEmployeesToTeamPayload['assignAs']) => (employeeInput: Employee[] | Employee) => {
        event?.stopPropagation()
        setUnAssignData(state => ({
            ...state,
            open: true,
            type,
            employees: Array.isArray(employeeInput) ? employeeInput : [employeeInput],
        }))
    }, [])


    const { mutate, isLoading, error } = useTeamUnassignEmployee({
        onSuccess: stopUnAssign,
    })
        
    return (
        <>
            <Form<Team>
                withTransition
                formConfig={{
                    resolver,
                    mode: 'all',
                    defaultValues: team,
                }}
                mutationConfig={{
                    mutationKey: ['update-team'],
                    mutationFn: (data) => teamUpdate({
                        id: team.id,
                        updateTeamCommand: data
                    }),
                    onSuccess: stopAssign,
                }}
                className='divider-y'
            >
                {(form, _, state) => (
                    <>
                        <EmbeddedContent>
                            <SubmitButton form={state.formId}>{i18n.t('common:buttons.save')}</SubmitButton>
                        </EmbeddedContent>

                        <AsideCardInfo
                            className='border-b'
                            title={i18n.t('employees:teams.basicSection.title')}
                            description={i18n.t('employees:teams.basicSection.title')}
                        >
                            <FormInput
                                asterisk
                                name='name'
                                control={form.control}
                                label={i18n.t('employees:teams.basicSection.nameLabel')}
                                placeholder={i18n.t('employees:teams.basicSection.namePlaceholder')}
                            />
                            <FormInput
                                textArea
                                size='lg'
                                name='description'
                                control={form.control}
                                label={i18n.t('employees:teams.basicSection.descriptionLabel')}
                                placeholder={i18n.t('employees:teams.basicSection.descriptionPlaceholder')}
                            />
                        </AsideCardInfo>
                        <AsideCardInfo
                            className='py-4 border-b'
                            title={i18n.t('employees:teams.leadersSection.title')}
                            description={i18n.t('employees:teams.leadersSection.description')}
                            actions={
                                <ButtonCreate
                                    title={i18n.t('employees:teams.assignTitle.LEADER')}
                                    onClick={onAssign('LEADER')} collapsed
                                />
                            }
                        >
                            <TeamLeaderList
                                leaders={team.leaders}
                                onDeleteItem={onUnAssign('LEADER')}
                            />
                        </AsideCardInfo>
                        
                        <AsideCardInfo
                            className='py-4 border-b'
                            title={i18n.t('employees:teams.membersSection.title')}
                            description={i18n.t('employees:teams.membersSection.description')}
                            actions={
                                <ButtonCreate
                                    title={i18n.t('employees:teams.assignTitle.MEMBER')}
                                    onClick={onAssign('MEMBER')} collapsed
                                />
                            }
                        >
                            <TeamMemberView params={{ id: team.id }} onDeleteItem={onUnAssign('MEMBER')} />
                        </AsideCardInfo>
                    </>
                )}
            </Form>

            <TeamUnassignEmployeeDialog
                onCancel={stopUnAssign}
                onSubmit={() => mutate({
                    id: team.id,
                    unassignEmployeeCommand: {
                        unassignAs: unAssignData.type,
                        employeeIds: unAssignData.employees.map(e => e.id),
                    }
                })}
                title={i18n.t(`employees:teams.employee.unassignTitle.${unAssignData.type}`)}
                open={unAssignData.open}
                isLoading={isLoading}
                error={error}
            />
            
            <Dialog
                open={assignData.open}
                onClose={stopAssign}
                title={i18n.t(`employees:teams.assignTitle.${assignData.type}`)}
            >
                <TeamAssignEmployeeDialog
                    onSubmit={(data) => teamAssignEmployee({
                        id: team.id,
                        assignEmployeeCommand: {
                            assignAs: assignData.type,
                            employeeIds: data.employees.map(x => x.id)
                        }
                    })}
                    assignAs={assignData.type}
                    teamId={team.id}
                    onCancel={stopAssign}
                    onConfirm={stopAssign}
                />
            </Dialog>
        </>
        
    )
}

export default TeamDetailForm
