import * as React from 'react'

import TBody from './TBody'
import Td from './Td'
import Tr from './Tr'
import Skeleton from '../Skeleton'
import { SkeletonProps } from '../Skeleton/Skeleton'

type TableRowSkeletonProps = {
    columns?: number
    rows?: number
    avatarInColumns?: number[]
    avatarProps?: SkeletonProps
}

const TableRowSkeleton = ({
    columns = 1,
    rows = 10,
    avatarInColumns = [],
    avatarProps,
}: TableRowSkeletonProps) => (
    <TBody>
        {Array.from(new Array(rows), (_, i) => i + 0).map((row) => (
            <Tr key={`row-${row}`}>
                {Array.from(new Array(columns), (_, i) => i + 0).map((col) => (
                    <Td key={`col-${col}`} container='tbody'>
                        <div className='flex flex-auto items-center gap-2'>
                            {avatarInColumns.includes(col) && (
                                <div>
                                    <Skeleton
                                        variant='circle'
                                        {...avatarProps}
                                    />
                                </div>
                            )}
                            <Skeleton />
                        </div>
                    </Td>
                ))}
            </Tr>
        ))}
    </TBody>
)

export default TableRowSkeleton
