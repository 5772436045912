import {Popover } from '@mantine/core'
import * as React from 'react'
import { useWatch } from 'react-hook-form'
import { useBoolean } from 'react-use'

import { type FormInputProps } from './FormInput'
import FormPasswordInput from './FormPasswordInput'

const validationKeys = ['minLength', 'digit', 'symbol', 'uppercase'] as const

type PasswordValidation = {
    [key in typeof validationKeys[number]]: boolean
}

type PasswordValidationEntry = [
    typeof validationKeys[number],
    string
]

const emptyValidation: PasswordValidation = 
    validationKeys.reduce((acc, key) => {
        acc[key] = false
        return acc
    }, {} as PasswordValidation)

const passwordValidations: Record<typeof validationKeys[number], string> = {        
    minLength: '.{8,}',
    digit: '(?=.*?[0-9])',
    symbol: '(?=.*?[#?!@$%^&*-])',
    uppercase: '(?=.*?[A-Z])',
}

const validatePassword = (password: string): PasswordValidation => {
    if (!password) return emptyValidation

    const validations = Object.entries(passwordValidations).reduce((acc, entry) => {
        const [key, value] = entry as PasswordValidationEntry
        acc[key] = !!password.match(value)
        return acc
    }, { ... emptyValidation })

    return validations
}

type ValidationItemProps = {
    isValid: boolean
    children: React.ReactNode
}

const ValidationItem = ({ isValid, children }: ValidationItemProps) => (
    <div className='flex items-center gap-2'>
        <span className={`relative inline-block w-3.5 h-3.5 leading-10 border-2 border-white rounded-full ${ 
            isValid 
                ? 'bg-primary-400 dark:bg-primary-600' 
                : 'bg-gray-400 dark:bg-gray-600'
        } `}/>
        {children}
    </div>
)

const FormPasswordInputValidated = (props: FormInputProps) => {
    const { control, name, ...rest } = props

    const [passwordValidationVisible, setPasswordValidationVisible] = useBoolean(false)
    const passwordValue = useWatch({ control, name })
    const passwordValidations = React.useMemo(() => validatePassword(passwordValue), [passwordValue])

    return (
        <>
            <FormPasswordInput
                {...rest}
                name={name}
                control={control}
                onFocus={() => {
                    setPasswordValidationVisible(true)
                }}
                onBlur={() => {
                    setPasswordValidationVisible(false)
                }}
                aside={
                    <Popover withArrow withinPortal={false} shadow="md" opened={passwordValidationVisible} position="bottom" width="target" >
                        <Popover.Dropdown className='translate-y-12'>
                            <div className="grid grid-flow-col grid-rows-4 gap-y-2">
                                <ValidationItem isValid={passwordValidations.symbol} >At least 1 symbol</ValidationItem>
                                <ValidationItem isValid={passwordValidations.digit} >At least 1 digit</ValidationItem>
                                <ValidationItem isValid={passwordValidations.uppercase} >At least 1 uppercase</ValidationItem>
                                <ValidationItem isValid={passwordValidations.minLength} >At least 8 characters</ValidationItem>
    
                            </div>
                        </Popover.Dropdown>
                    </Popover>
                }
            />

           

        </>
    )
}

export default FormPasswordInputValidated
