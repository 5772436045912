/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FindOrganizationQueryResponse,
  PublicFindOrganizationQueryResponse,
  ResourceCreated,
  UpdateOrganizationBillingInfoCommand,
  UpdateOrganizationCommand,
} from '../models/index';
import {
    FindOrganizationQueryResponseFromJSON,
    FindOrganizationQueryResponseToJSON,
    PublicFindOrganizationQueryResponseFromJSON,
    PublicFindOrganizationQueryResponseToJSON,
    ResourceCreatedFromJSON,
    ResourceCreatedToJSON,
    UpdateOrganizationBillingInfoCommandFromJSON,
    UpdateOrganizationBillingInfoCommandToJSON,
    UpdateOrganizationCommandFromJSON,
    UpdateOrganizationCommandToJSON,
} from '../models/index';

export interface FetchOrganizationRequest {
    include?: Array<FetchOrganizationIncludeEnum>;
    priceId?: FetchOrganizationPriceIdEnum;
}

export interface PublicFetchOrganizationRequest {
    query: string;
}

export interface UpdateOrganizationRequest {
    id: string;
    updateOrganizationCommand?: UpdateOrganizationCommand;
}

export interface UpdateOrganizationBillingInfoRequest {
    id: string;
    updateOrganizationBillingInfoCommand?: UpdateOrganizationBillingInfoCommand;
}

/**
 * 
 */
export class OrganizationApi extends runtime.BaseAPI {

    /**
     * Fetch o by its id
     * Fetch organization
     */
    async fetchOrganizationRaw(requestParameters: FetchOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindOrganizationQueryResponse>> {
        const queryParameters: any = {};

        if (requestParameters.include) {
            queryParameters['include'] = requestParameters.include;
        }

        if (requestParameters.priceId !== undefined) {
            queryParameters['priceId'] = requestParameters.priceId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/organizations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindOrganizationQueryResponseFromJSON(jsonValue));
    }

    /**
     * Fetch o by its id
     * Fetch organization
     */
    async fetchOrganization(requestParameters: FetchOrganizationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindOrganizationQueryResponse> {
        const response = await this.fetchOrganizationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch o by its id
     * Fetch organization
     */
    async publicFetchOrganizationRaw(requestParameters: PublicFetchOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PublicFindOrganizationQueryResponse>> {
        if (requestParameters.query === null || requestParameters.query === undefined) {
            throw new runtime.RequiredError('query','Required parameter requestParameters.query was null or undefined when calling publicFetchOrganization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/public/organizations/{query}`.replace(`{${"query"}}`, encodeURIComponent(String(requestParameters.query))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PublicFindOrganizationQueryResponseFromJSON(jsonValue));
    }

    /**
     * Fetch o by its id
     * Fetch organization
     */
    async publicFetchOrganization(requestParameters: PublicFetchOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PublicFindOrganizationQueryResponse> {
        const response = await this.publicFetchOrganizationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a organization
     * Update a organization
     */
    async updateOrganizationRaw(requestParameters: UpdateOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateOrganization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/organizations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateOrganizationCommandToJSON(requestParameters.updateOrganizationCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Update a organization
     * Update a organization
     */
    async updateOrganization(requestParameters: UpdateOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.updateOrganizationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a organization billing info
     * Update a organization billing info
     */
    async updateOrganizationBillingInfoRaw(requestParameters: UpdateOrganizationBillingInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateOrganizationBillingInfo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/organizations/{id}/billing-info`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateOrganizationBillingInfoCommandToJSON(requestParameters.updateOrganizationBillingInfoCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Update a organization billing info
     * Update a organization billing info
     */
    async updateOrganizationBillingInfo(requestParameters: UpdateOrganizationBillingInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.updateOrganizationBillingInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const FetchOrganizationIncludeEnum = {
    Subscription: 'subscription',
    BillingInfo: 'billingInfo',
    PaymentPage: 'paymentPage',
    Prices: 'prices'
} as const;
export type FetchOrganizationIncludeEnum = typeof FetchOrganizationIncludeEnum[keyof typeof FetchOrganizationIncludeEnum];
/**
 * @export
 */
export const FetchOrganizationPriceIdEnum = {
    Yearly: 'Workspace-Starter-EUR-Yearly',
    Monthly: 'Workspace-Starter-EUR-Monthly'
} as const;
export type FetchOrganizationPriceIdEnum = typeof FetchOrganizationPriceIdEnum[keyof typeof FetchOrganizationPriceIdEnum];
