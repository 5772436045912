/* eslint-disable @typescript-eslint/no-non-null-assertion */
import SimpleCard from '@viterbit/web-app/shared/display/SimpleCard'
import SectionLayout from '@viterbit/web-app/shared/layout/SectionLayout'
import React from 'react'


type FeedbackLayoutProps = {
    title?: string
    actions?: React.ReactNode
    children?: React.ReactNode
}

const FeedbackLayout =  ({ title, children, actions }: FeedbackLayoutProps) => (
    <SectionLayout
        title={title}
        actions={actions}
    >
        <SimpleCard padding>
            {children}
        </SimpleCard>
    </SectionLayout>
)

export default FeedbackLayout
