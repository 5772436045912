import { IsEmail, IsNotEmpty } from '@viterbit/web-app/shared/utils/ClassValidators'

import { EmployeeCreateFields } from './EmployeeCreateShared'

class EmployeeCreateValidator implements EmployeeCreateFields {
    @IsNotEmpty()
    locationId: string

    @IsNotEmpty()
    companyId: string

    @IsNotEmpty()
    leavePolicyId: string

    @IsNotEmpty()
    name: string

    @IsEmail()
    @IsNotEmpty()
    email: string

    @IsNotEmpty()
    startDate: string
}

export default EmployeeCreateValidator