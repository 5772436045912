import { IconUserPlus } from '@tabler/icons-react'
import { PermissionAction } from '@viterbit/web-app/dataAccess/model/PermissionAction.model'
import { useCurrentEmployee } from '@viterbit/web-app/dataAccess/useCase/employee/useCurrentEmployee'
import i18n from '@viterbit/web-app/i18n'
import { useUser } from '@viterbit/web-app/shared/employee/useUser'
import ButtonCreate from '@viterbit/web-app/shared/form/ButtonCreate'
import SectionLayout from '@viterbit/web-app/shared/layout/SectionLayout'
import IsGranted from '@viterbit/web-app/shared/permission/IsGranted'
import * as React from 'react'

import EmployeeListDetail from './EmployeeListDetail'
import EmployeeCreateDialog from '../create/EmployeeCreateDialog'

const EmployeeListView = () => {
    const { organizationId } = useUser()
    const currentUser = useCurrentEmployee()

    const [createDetail, setCreateDetail] = React.useState(false)

    return (
        <>
            <SectionLayout
                title={i18n.t('employees:dashboard.title')}
                actions={(
                    <IsGranted action={PermissionAction.CORE_EMPLOYEE_CREATE} id={organizationId}>
                        <ButtonCreate
                            data-testid='create-employee-button'
                            IconComponent={IconUserPlus}
                            title={i18n.t('employees:dashboard.actionButton')}
                            onClick={() => setCreateDetail(true)}
                        />
                    </IsGranted>
                )}
            >
                <EmployeeListDetail />
            </SectionLayout>


            <EmployeeCreateDialog
                title={i18n.t('employees:dashboard.createDialog.title')}
                open={createDetail}
                onClose={() => setCreateDetail(false)}
                onSubmitCallBack={() => setCreateDetail(false)}
            />
        </>
    )
}

export default EmployeeListView

