import { useQuery } from '@tanstack/react-query'
import { TEAM_QUERY_KEY } from '@viterbit/web-app/dataAccess/cacheKeys'

import { teamDetail } from '../../service/teamService'

type TeamDetailConfig = {
    query: Parameters<typeof teamDetail>[0]
    enabled?: boolean
}

export const useTeamDetail = ({ query, enabled }: TeamDetailConfig) =>
    useQuery({
        queryFn: () => teamDetail({
            id: query.id
        }), 
        queryKey: [TEAM_QUERY_KEY, 'detail', query],
        enabled
    })

