
import { Employee } from '@viterbit/web-app/dataAccess/model/Employee.model'
import { PermissionAction } from '@viterbit/web-app/dataAccess/model/PermissionAction.model'
import * as React from 'react'

export type EmployeeContextValue = {
    employee: Employee
    hasPermission: (action: PermissionAction, ...ids: string[]) => boolean
    basePath: string
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const EmployeeContext = React.createContext<EmployeeContextValue>({} as any)

export const EmployeeContextProvider = EmployeeContext.Provider

export default EmployeeContext
