import { createStyles, Group } from '@mantine/core'

import SegmentNavLink from './SegmentNavLink'
import Subtitle from '../typography/Subtitle'
import Text from '../typography/Text'

const useStyles = createStyles((theme) => ({
    link: {
        ...theme.fn.focusStyles(),
        display: 'block',
        textDecoration: 'none',
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
        lineHeight: 1.2,
        fontSize: theme.fontSizes.sm,
        
        paddingTop: 12,
        paddingBottom: 12,
        paddingLeft: 16,
        paddingRight: 16,
        borderTopRightRadius: theme.radius.sm,
        borderBottomRightRadius: theme.radius.sm,
        borderLeft: `0px solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[4]
        }`,

        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        },
    },

    linkActive: {
        fontWeight: 500,
        borderLeftWidth: 2.5,
        borderLeftColor: theme.colors[theme.primaryColor][6],
        color: theme.colors[theme.primaryColor][6],

        '&, &:hover': {
            backgroundColor: theme.fn.rgba(theme.colors.dark[6], 0.05)
        },
    },

    linkLabel: {
        paddingTop: 8,
        paddingBottom: 8,
        pointerEvents: 'none',
    }
}))

interface TableOfContentsProps {
  links: { label: string; link: string; order: number, icon?: React.ReactNode }[]
  active?: string
  title?: string
  icon?: React.ReactNode
}

export const TableOfContents = ({ links, active, title, icon }: TableOfContentsProps) => {
    const { classes, cx } = useStyles()
    const items = links.map((item, index) => (
        <SegmentNavLink
            path={item.link}
            onClick={(event) => event.preventDefault()}
            key={item.label}
            className={cx(classes.link, 'hover:text-primary-600', {
                [classes.linkActive]: active === item.link,
                'pointer-events-none uppercase': item.order === 1,
                'border-t-0': item.order === 1 && !index,
                'pl-[28px!important]': item.order > 1,
            })}
            disabled={item.order === 1}
        >
            <Text className='flex items-center [&>*]:w-4 [&>*]:mr-2'>
                {item.order !== 1 && item.icon}
                {item.label}
            </Text>
        </SegmentNavLink>
    ))

    return (
        <>
            {(icon || title) && (
                <Group mb="md">
                    {icon}
                    <Subtitle bold>{title}</Subtitle>
                </Group>
            )}

            {items}
        </>
    )
}