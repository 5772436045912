
export const STYLES = {
    root: 'flex items-center gap-4 group',
    sizes: {
        sm: {
            title: 'text-sm font-medium',
            subtitle: 'text-sm',
            avatarSize: 'md',
            leadTitle: 'text-sm'
        },
        lg: {

            title: 'text-xl font-bold',
            subtitle: 'text-md opacity-80',
            avatarSize: 'lg',
            leadTitle: 'text-xs font-bold opacity-60'
        },
    },
}

export const SKELETON_STYLES = {
    root: STYLES.root,
    sizes: {
        sm: {
            root: 'h-12',
            avatar: 'h-10 w-10 rounded-full',
            content: 'flex flex-col gap-2',
            name: 'h-4 w-28',
            email: 'h-4 w-36',
            leadTitle: 'h-4 w-30',
        },
        lg: {
            root: 'h-20',
            avatar: 'h-16 w-16 rounded-full',
            content: 'flex flex-col gap-2',
            name: 'h-6 w-48',
            email: 'h-4 w-36',
            leadTitle: 'h-3 w-20',
        },
    },
}