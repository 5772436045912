export enum PermissionAction  {
    // ? Account actions
    'CORE_ACCOUNT_MANAGEMENT' = 'CORE_ACCOUNT_MANAGEMENT',
    'CORE_ACCOUNT_UPDATE' = 'CORE_ACCOUNT_UPDATE',
    'CORE_ACCOUNT_DELETE' = 'CORE_ACCOUNT_DELETE',
    'CORE_ACCOUNT_FIND' = 'CORE_ACCOUNT_FIND',
    'CORE_ACCOUNT_LIST' = 'CORE_ACCOUNT_LIST',
    'CORE_ACCOUNT_INVITE' = 'CORE_ACCOUNT_INVITE',
    'CORE_ACCOUNT_CHANGE_ROLE' = 'CORE_ACCOUNT_CHANGE_ROLE',

    // ? Company actions
    'CORE_COMPANY_DELETE' = 'CORE_COMPANY_DELETE',

    // ? Employee actions
    'CORE_EMPLOYEE_VIEW' = 'CORE_EMPLOYEE_VIEW',
    'CORE_EMPLOYEE_UPDATE' = 'CORE_EMPLOYEE_UPDATE',
    'CORE_EMPLOYEE_CREATE' = 'CORE_EMPLOYEE_CREATE',
    'CORE_EMPLOYEE_DELETE' = 'CORE_EMPLOYEE_DELETE',
    'CORE_EMPLOYEE_PERSONAL_INFORMATION_FIND' = 'CORE_EMPLOYEE_PERSONAL_INFORMATION_FIND',
    'CORE_EMPLOYEE_CONTRACT_FIND' = 'CORE_EMPLOYEE_CONTRACT_FIND',
    'CORE_EMPLOYEE_PERSONAL_UPDATE' = 'CORE_EMPLOYEE_PERSONAL_UPDATE',
    'CORE_EMPLOYEE_CONTRACT_UPDATE' = 'CORE_EMPLOYEE_CONTRACT_UPDATE',

    // ? File actions

    /*
     * region
     * Avatar file actions
     */
    'CORE_AVATAR_FILE_FIND' = 'CORE_AVATAR_FILE_FIND',
    'CORE_AVATAR_FILE_LIST' = 'CORE_AVATAR_FILE_LIST',
    'CORE_AVATAR_FILE_UPLOAD' = 'CORE_AVATAR_FILE_UPLOAD',
    'CORE_AVATAR_FILE_DELETE' = 'CORE_AVATAR_FILE_DELETE',

    /*
     * Document file actions
     */
    'CORE_DOCUMENT_FILE_FIND' = 'CORE_DOCUMENT_FILE_FIND',
    'CORE_DOCUMENT_FILE_LIST' = 'CORE_DOCUMENT_FILE_LIST',
    'CORE_DOCUMENT_FILE_UPLOAD' = 'CORE_DOCUMENT_FILE_UPLOAD',
    'CORE_DOCUMENT_FILE_DELETE' = 'CORE_DOCUMENT_FILE_DELETE',
    /*
     * Contract file actions
     */
    'CORE_CONTRACT_FILE_FIND' = 'CORE_CONTRACT_FILE_FIND',
    'CORE_CONTRACT_FILE_LIST' = 'CORE_CONTRACT_FILE_LIST',
    'CORE_CONTRACT_FILE_UPLOAD' = 'CORE_CONTRACT_FILE_UPLOAD',
    'CORE_CONTRACT_FILE_DELETE' = 'CORE_CONTRACT_FILE_DELETE',
    /*
     * Payroll file actions
     */
    'CORE_PAYROLL_FILE_FIND' = 'CORE_PAYROLL_FILE_FIND',
    'CORE_PAYROLL_FILE_LIST' = 'CORE_PAYROLL_FILE_LIST',
    'CORE_PAYROLL_FILE_UPLOAD' = 'CORE_PAYROLL_FILE_UPLOAD',
    'CORE_PAYROLL_FILE_DELETE' = 'CORE_PAYROLL_FILE_DELETE',
    /*
     * Leave file actions
     */
    'CORE_LEAVE_FILE_FIND' = 'CORE_LEAVE_FILE_FIND',
    'CORE_LEAVE_FILE_LIST' = 'CORE_LEAVE_FILE_LIST',
    'CORE_LEAVE_FILE_UPLOAD' = 'CORE_LEAVE_FILE_UPLOAD',
    'CORE_LEAVE_FILE_DELETE' = 'CORE_LEAVE_FILE_DELETE',
    /*
     * Personal file actions
     */
    'CORE_PERSONAL_FILE_FIND' = 'CORE_PERSONAL_FILE_FIND',
    'CORE_PERSONAL_FILE_LIST' = 'CORE_PERSONAL_FILE_LIST',
    'CORE_PERSONAL_FILE_UPLOAD' = 'CORE_PERSONAL_FILE_UPLOAD',
    'CORE_PERSONAL_FILE_DELETE' = 'CORE_PERSONAL_FILE_DELETE',

    /*
     * Pending file actions
     */
    'CORE_PENDING_FILE_FIND' = 'CORE_PENDING_FILE_FIND',
    'CORE_PENDING_FILE_LIST' = 'CORE_PENDING_FILE_LIST',
    'CORE_PENDING_FILE_UPLOAD' = 'CORE_PENDING_FILE_UPLOAD',
    'CORE_PENDING_FILE_DELETE' = 'CORE_PENDING_FILE_DELETE',

    // ? Leave actions
    'CORE_HR_LEAVE_APPROVE' = 'CORE_HR_LEAVE_APPROVE',
    'CORE_HR_LEAVE_REJECT' = 'CORE_HR_LEAVE_REJECT',
    'CORE_HR_LEAVE_CREATE' = 'CORE_HR_LEAVE_CREATE',
    'CORE_HR_LEAVE_DELETE' = 'CORE_HR_LEAVE_DELETE',
    'CORE_HR_LEAVE_UPDATE' = 'CORE_HR_LEAVE_UPDATE',
    'CORE_HR_LEAVE_FIND' = 'CORE_HR_LEAVE_FIND',
    'CORE_HR_LEAVE_ADJUST'= 'CORE_HR_LEAVE_ADJUST',
    'CORE_HR_LEAVE_LIST'= 'CORE_HR_LEAVE_LIST',

    // ? Leave policy actions
    'CORE_HR_LEAVE_POLICIES_DELETE' = 'CORE_HR_LEAVE_POLICIES_DELETE',

    // ? Location actions
    'CORE_LOCATION_DELETE' = 'CORE_LOCATION_DELETE',

    // ? Settings actions
    'CORE_HR_SETTINGS_MANAGEMENT' = 'CORE_HR_SETTINGS_MANAGEMENT',
    'CORE_SETTINGS_MANAGEMENT' = 'CORE_SETTINGS_MANAGEMENT',

    // ? Teams actions
    'CORE_TEAM_ASSIGN_EMPLOYEE' = 'CORE_TEAM_ASSIGN_EMPLOYEE',
    'CORE_TEAM_CREATE' = 'CORE_TEAM_CREATE',
    'CORE_TEAM_DELETE' = 'CORE_TEAM_DELETE',
    'CORE_TEAM_UPDATE' = 'CORE_TEAM_UPDATE',
    'CORE_TEAM_VIEW' = 'CORE_TEAM_VIEW',

    // ? Job positions actions
    'CORE_JOB_POSITION_DELETE' = 'CORE_JOB_POSITION_DELETE',

    'CORE_HR_TIME_TRACKING_LIST' = 'CORE_HR_TIME_TRACKING_LIST',
    'CORE_HR_TIME_TRACKING_CREATE' = 'CORE_HR_TIME_TRACKING_CREATE',

    'CORE_HR_WORK_SCHEDULES_FIND' = 'CORE_HR_WORK_SCHEDULES_FIND',
    'CORE_HR_WORK_SCHEDULES_LIST' = 'CORE_HR_WORK_SCHEDULES_LIST',
    'CORE_HR_WORK_SCHEDULES_CREATE' = 'CORE_HR_WORK_SCHEDULES_CREATE',
    'CORE_HR_WORK_SCHEDULES_UPDATE' = 'CORE_HR_WORK_SCHEDULES_UPDATE',
    'CORE_HR_WORK_SCHEDULES_DELETE' = 'CORE_HR_WORK_SCHEDULES_DELETE',

    'AUTOMATION_INTEGRATION_DELETE' = 'AUTOMATION_INTEGRATION_DELETE'
}

export const permissionActionKeys = (Object.keys(PermissionAction) as Array<keyof typeof PermissionAction>)

export type ActionsItem = {
    [key in PermissionAction]: string[]
}

export const createActions = (actions: PermissionAction[], employeesId: string[]): ActionsItem[] => {
    const actionsConfig: ActionsItem[] = actions.reduce(
        (permissions, action) => {
            permissions.push({ [action]: employeesId } as ActionsItem)
            return permissions
        },
        [] as ActionsItem[]
    )

    return actionsConfig
}
    
