/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AssignEmployeeJobPositionLevelCommand
 */
export interface AssignEmployeeJobPositionLevelCommand {
    /**
     * 
     * @type {string}
     * @memberof AssignEmployeeJobPositionLevelCommand
     */
    jobPositionLevelId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssignEmployeeJobPositionLevelCommand
     */
    alias?: string | null;
}

/**
 * Check if a given object implements the AssignEmployeeJobPositionLevelCommand interface.
 */
export function instanceOfAssignEmployeeJobPositionLevelCommand(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AssignEmployeeJobPositionLevelCommandFromJSON(json: any): AssignEmployeeJobPositionLevelCommand {
    return AssignEmployeeJobPositionLevelCommandFromJSONTyped(json, false);
}

export function AssignEmployeeJobPositionLevelCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssignEmployeeJobPositionLevelCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'jobPositionLevelId': !exists(json, 'jobPositionLevelId') ? undefined : json['jobPositionLevelId'],
        'alias': !exists(json, 'alias') ? undefined : json['alias'],
    };
}

export function AssignEmployeeJobPositionLevelCommandToJSON(value?: AssignEmployeeJobPositionLevelCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'jobPositionLevelId': value.jobPositionLevelId,
        'alias': value.alias,
    };
}

