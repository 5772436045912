import {
    IconCaretDown,
    IconCaretLeft,
    IconCaretRight,
    IconCaretUp,
} from '@tabler/icons-react'
import * as React from 'react'

import { placement } from './types'
import { clsxm } from '../../lib/clsxm'

const positionClass = {
    top: '-bottom-3 w-full left-0',
    'top-start': '-bottom-2 left-0 ml-3',
    'top-end': '-bottom-2 right-0 mr-3',
    right: '-left-2 top-1/2 transform -translate-y-1/2',
    'right-start': '-left-2 top-2',
    'right-end': '-left-2 bottom-2',
    bottom: '-top-0 w-full left-0',
    'bottom-start': '-top-2 left-0 ml-3',
    'bottom-end': '-top-2 right-0 mr-3',
    left: '-right-2 top-1/2 transform -translate-y-1/2',
    'left-start': '-right-2 top-2',
    'left-end': '-right-2 bottom-2',
}

type ArrowProps = {
    placement: placement
    color?: string
    colorDark?: string
}

const Arrow = ({ placement = 'top', color, colorDark }: ArrowProps) => {
    const arrowDefaultClass = `absolute text-${color}  dark:text-${colorDark} shadow-xs`

    const arrowClass = clsxm(arrowDefaultClass, positionClass[placement])
    const arrowIcon = {
        top: (className: string) => (
            <IconCaretDown
                stroke='5'
                data-testid='arrow'
                className={className}
            />
        ),
        bottom: (className: string) => (
            <IconCaretUp stroke='5' data-testid='arrow' className={className} />
        ),
        left: (className: string) => (
            <IconCaretRight
                stroke='5'
                data-testid='arrow'
                className={className}
            />
        ),
        right: (className: string) => (
            <IconCaretLeft
                stroke='5'
                data-testid='arrow'
                className={className}
            />
        ),
    }

    const direction = placement.split('-')[0] as
        | 'top'
        | 'bottom'
        | 'left'
        | 'right'

    return arrowIcon[direction](arrowClass)
}

export default Arrow
