
import { Badge } from '@mantine/core'
import { WorkSchedule } from '@viterbit/web-app/dataAccess/model/WorkSchedule.model'
import i18n from '@viterbit/web-app/i18n'
import SegmentNavLink from '@viterbit/web-app/shared/navigation/SegmentNavLink'
import * as React from 'react'
import List from 'ui/src/components/List'

export type WorkScheduledListItemProps = {
    workSchedule: WorkSchedule
}

const WorkScheduledListItem = (props: WorkScheduledListItemProps) => {
    const { workSchedule} = props

    return (
        <SegmentNavLink path={`/settings/work-schedule/${workSchedule.id}/details`}>
            <List.Item clickable>
                <List.ItemText
                    data-testid='work-schedule-list-item-text'
                    primary={workSchedule.name}
                />
                <Badge
                    variant='filled'
                    color='grey'
                    data-testid='work-schedule-list-item-type'
                >
                    {i18n.t(`settingWorkSchedule:fields.type.options.${workSchedule.type}`)}
                </Badge>
            </List.Item>
        </SegmentNavLink>
    )
}

export default WorkScheduledListItem
