
import { LeaveReason } from '@viterbit/web-app/dataAccess/model/LeaveReason.model'
import AsideCardInfo from '@viterbit/web-app/shared/display/AsideCardInfo'
import { parseEventValue, parseInputValue } from '@viterbit/web-app/shared/utils/parsers'
import { UseFormReturn } from 'react-hook-form'
import { FormInput, FormInputColor,FormSwitcher } from 'ui/src/components/Form'

import colorOptions from './colorOptions'
import { LeaveReasonsFormFields } from './DetailsLeaveReasons'

type DetailsLeaveReasonsFormProps = {
    form: UseFormReturn<LeaveReasonsFormFields>
    details: LeaveReason
}

const DetailsLeaveReasonsForm = (props: DetailsLeaveReasonsFormProps) => {
    const { form, details } = props

    const isLimitedTo = form.watch('isLimitedTo', details.isLimitedTo)

    return (
        <>
            <div className='flex flex-col gap-6'>
                <AsideCardInfo
                    title='Basic Information'
                    description='Add color to your leave reason'
                >
                    <FormInput
                        asterisk
                        name='name'
                        control={form.control}
                        label='Name'
                        placeholder='Enter reason name'
                    />
                    <FormInput
                        textArea
                        size='lg'
                        name='description'
                        control={form.control}
                        label='Description'
                        placeholder='Enter reason description'
                    />

                    <FormInputColor
                        name='color'
                        control={form.control}
                        format="hex"
                        swatches={colorOptions.map((option) => option.value)}
                        placeholder="Pick color"
                        label="Select reason color"
                    />
                </AsideCardInfo>


                <AsideCardInfo
                    title='Days counters'
                    description='Setup the total days per year'
                >
                    <FormSwitcher
                        className='flex-row'
                        name="isLimitedTo"
                        label='Limit days per year'
                        control={form.control}
                    />
                    {isLimitedTo && (
                        <FormInput
                            name='limitedTo'
                            control={form.control}
                            label='Days per year'
                            placeholder='Enter days per year'
                            transform={{
                                input: parseInputValue,
                                output: parseEventValue,
                            }}
                        />
                    )}


                </AsideCardInfo>

                <AsideCardInfo
                    title='Configuration'
                    description='Setup kind of leave reason'
                >
                    <FormSwitcher
                        className='flex-row mb-2'
                        name='timeDiscount'
                        control={form.control}
                        label='Time discount'
                    />
                    <FormSwitcher
                        className='flex-row mb-2'
                        name='needApproval'
                        control={form.control}
                        label='Need approval'
                    />
                    <FormSwitcher
                        className='flex-row mb-2'
                        name='needDocumentation'
                        control={form.control}
                        label='Need documentation'
                    />
                    <FormSwitcher
                        className='flex-row mb-2'
                        name='visibleOtherEmployees'
                        control={form.control}
                        label='Visible by other employees'
                    />
                
                </AsideCardInfo>
            </div>
        </>
    )
}

export default DetailsLeaveReasonsForm
