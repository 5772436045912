/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AssignEmployeeCommand
 */
export interface AssignEmployeeCommand {
    /**
     * 
     * @type {Array<string>}
     * @memberof AssignEmployeeCommand
     */
    employeeIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AssignEmployeeCommand
     */
    assignAs: AssignEmployeeCommandAssignAsEnum;
}


/**
 * @export
 */
export const AssignEmployeeCommandAssignAsEnum = {
    Member: 'MEMBER',
    Leader: 'LEADER'
} as const;
export type AssignEmployeeCommandAssignAsEnum = typeof AssignEmployeeCommandAssignAsEnum[keyof typeof AssignEmployeeCommandAssignAsEnum];


/**
 * Check if a given object implements the AssignEmployeeCommand interface.
 */
export function instanceOfAssignEmployeeCommand(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "employeeIds" in value;
    isInstance = isInstance && "assignAs" in value;

    return isInstance;
}

export function AssignEmployeeCommandFromJSON(json: any): AssignEmployeeCommand {
    return AssignEmployeeCommandFromJSONTyped(json, false);
}

export function AssignEmployeeCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssignEmployeeCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'employeeIds': json['employeeIds'],
        'assignAs': json['assignAs'],
    };
}

export function AssignEmployeeCommandToJSON(value?: AssignEmployeeCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'employeeIds': value.employeeIds,
        'assignAs': value.assignAs,
    };
}

