import jwtDecode, { JwtPayload } from 'jwt-decode'
import { useSearchParams } from 'react-router-dom'

import RedeemInvitationDetail from './RedeemInvitationDetail'

interface CustomJWT extends JwtPayload {
    email: string
}

type InitialProps = {
    token: string
    initialData?: CustomJWT
}

const RedeemInvitationView = () => {
    const [searchParams, setSearchParams] = useSearchParams()

    const token = searchParams.get('token') ?? ''
    const initialProps: InitialProps = { token }
    try {
        initialProps.initialData = jwtDecode(token)
    } catch (error) {
        initialProps.initialData = undefined
    }

    return <RedeemInvitationDetail {...initialProps} />
}

export default RedeemInvitationView
