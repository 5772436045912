import { useQuery } from '@tanstack/react-query'

import { LEAVE_QUERY_KEY } from '../../cacheKeys'
import { leaveList } from '../../service/leavesService'

type LeaveDetailConfig = {
    query: Parameters<typeof leaveList>[0]
    enabled?: boolean
}

export const useLeaveList = ({ query, enabled }: LeaveDetailConfig) =>
    useQuery({
        queryFn: () => leaveList(query), 
        queryKey: [LEAVE_QUERY_KEY, 'list', query],
        enabled
    })

