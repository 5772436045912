/* eslint-disable @typescript-eslint/no-explicit-any */
import { AnimatePresence, motion } from 'framer-motion'
import * as React from 'react'

import {
    DropdownMenuContextProvider,
    useDropdownMenuContext,
} from './context/dropdownMenuContext'
import { MenuContextProvider } from './context/menuContext'
import { DropdownPlacementType } from './sharedTypes'
import useUncertainRef from '../../hooks/useUncertainRef'
import useUniqueId from '../../hooks/useUniqueId'
import clsxm from '../../lib'

type MenuProps = {
    className?: string
    children?: React.ReactNode
    activeKey?: string
    hidden?: boolean
    placement?: DropdownPlacementType
    menuClass?: string
    classPrefix?: string
    onSelect?: (eventKey: string, e: React.MouseEvent) => void
    onKeyDown?: (e: React.MouseEvent) => void
    onToggle?: (eventKey: string, e: React.MouseEvent) => void
}

const Menu = React.forwardRef<HTMLDivElement, MenuProps>((props, ref) => {
    const {
        children,
        activeKey,
        onSelect,
        hidden,
        placement,
        menuClass,
        ...rest
    } = props

    const menuRef = useUncertainRef(ref)
    const menuId = useUniqueId('menu-')
    const menuControl = useDropdownMenuContext(menuRef)

    const getTransform = (deg: number) => {
        const rotate = `rotateX(${deg}deg)`
        if (placement && placement.includes('center')) {
            return `${rotate} translateX(-50%)`
        }
        return rotate
    }

    const enterStyle: any = {
        opacity: 1,
        visibility: 'visible',
        transform: getTransform(0),
    }
    const exitStyle: any = {
        opacity: 0,
        visibility: 'hidden',
        transform: getTransform(40),
    }
    const initialStyle = exitStyle

    return (
        <MenuContextProvider
            value={{
                activeKey,
                onSelect,
            }}
        >
            <DropdownMenuContextProvider value={menuControl}>
                <AnimatePresence mode='wait'>
                    <motion.ul
                        id={menuId}
                        ref={menuRef}
                        initial={initialStyle}
                        animate={enterStyle}
                        exit={exitStyle}
                        transition={{ duration: 0.30, type: 'tween' }}
                        className={clsxm(
                            menuClass,
                            hidden ? 'hidden p-0 m-0 h-0 w-0' : ''
                        )}
                        {...(rest as any)}
                    >
                        {children}
                        
                    </motion.ul>
                </AnimatePresence>
            </DropdownMenuContextProvider>
        </MenuContextProvider>
    )
})

export default Menu
