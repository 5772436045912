/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Leave } from '@viterbit/web-app/dataAccess/model/Leave.model'
import CreateDialog from '@viterbit/web-app/feature/leave/list/Create/CreateDialog'
import { DaysRequestsEnum } from '@viterbit/web-app/feature/leave/list/Forms/types'
import ReviewDialog from '@viterbit/web-app/feature/leave/list/Review/ReviewDialog'
import * as React from 'react'

import { LeaveAction } from './useLeaveDialog'

type LeaveDialogProps = {
    children?: React.ReactNode
    className?: string
    action?: LeaveAction
    onClose: () => any
}

const styles = {
    root: ''
}

const LeaveDialog = (props: LeaveDialogProps) => {
    const { action, onClose, className } = props

    if (action?.isUpdate) {
        return (
            <ReviewDialog
                open
                leave={action.defaultValues as Leave}
                employeeId={action.employeeId!}
                onClose={onClose}
            />
        )
    }

    if (action?.isCreate) {
        return (
            <CreateDialog
                open
                onClose={onClose}
                employeeId={action.employeeId!}
                defaultValues={{
                    daysOption: DaysRequestsEnum.OneDay,
                    ...action.defaultValues
                }}
            />
        )
    }

    return null
}

export default LeaveDialog
