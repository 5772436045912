/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { ajvResolver } from '@hookform/resolvers/ajv'
import { Employee } from '@viterbit/web-app/dataAccess/model/Employee.model'
import { employeeUpdate } from '@viterbit/web-app/dataAccess/service/employeeService'
import i18n from '@viterbit/web-app/i18n'
import DynamicFieldsSchema from '@viterbit/web-app/shared/form/DynamicFieldsSchema'
import Form from '@viterbit/web-app/shared/form/Form'
import SubmitButton from '@viterbit/web-app/shared/form/SubmitButton'
import clsxm from 'ui/src/lib'

export type EmployeePersonalIdentificationsFormProps = {
    employee: Employee
    className?: string
}

const EmployeePersonalIdentificationsForm = (props: EmployeePersonalIdentificationsFormProps) => {
    const { employee, className } = props
    const schema = employee.location!.fiscalInformationSchema

    return (
        <Form<object>
            smallButtons
            withTransition
            mutationConfig={{
                mutationFn: (fiscalInformation) =>
                    employeeUpdate({
                        id: employee.id,
                        updateEmployeeCommand: {
                            fiscalInformation,
                        },
                    }),
            }}
            formConfig={{
                resolver: ajvResolver(employee.location?.fiscalInformationSchema as any),
                mode: 'all',
                defaultValues: employee.fiscalInformation,
            }}
            className={clsxm(
                'p-4 bg-white border border-gray-200 rounded-lg',
                className,
            )}
        >
            {(form) => (
                <>
                    <DynamicFieldsSchema
                        schema={schema as any}
                        form={form as any}
                        defaultValues={employee.fiscalInformation}
                    />

                    <SubmitButton data-testid="employee-fiscal-information-submit-button">{i18n.t('employees:employee.buttons.save')}</SubmitButton>
                </>
            )}
        </Form>
    )
}

export default EmployeePersonalIdentificationsForm
