import { Button } from '@mantine/core'
import i18n from '@viterbit/web-app/i18n'
import FormUpload from '@viterbit/web-app/shared/form/FormUpload'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import Dialog from 'ui/src/components/Dialog'
import clsxm from 'ui/src/lib'

type FormUploadDialogProps = {
    className?: string
    onConfirm: (filesId: string[] | string) => Promise<void>
    uploadProps: Omit<React.ComponentProps<typeof FormUpload>, 'control' | 'name'>
} & React.ComponentProps<typeof Dialog>

const styles = {
    root: ''
}


const FormUploadDialog = (props: FormUploadDialogProps) => {
    const { uploadProps, title, className, onClose, onConfirm, open, ...rest  } = props
    const [loading, setLoading] = React.useState(false)
    const form = useForm()

    const files = form.watch('files')

    const onClickConfirm = async () => {
        setLoading(true)
        await onConfirm?.(form.getValues('files'))
        onClose?.()
        setLoading(false)
    }

    React.useMemo(() => {
        if (open)  {
            form.reset()
        }
    }, [open])

    return (
        <Dialog
            open={open}
            onClose={onClose}
            className={clsxm(styles.root, className)}
            title={title}
            {...rest}
        >
            <FormUpload
                {...uploadProps}
                control={form.control}
                name='files'
            />

            <hr />
            
            <div className='flex justify-between mt-4'>
                <Button onClick={onClose} variant='outlined'>{i18n.t('buttons.cancel')}</Button>
                <Button data-testid="form-upload-dialog-confirm" disabled={!files?.length} loading={loading} onClick={onClickConfirm}>{i18n.t('buttons.save')}</Button>
            </div>
        </Dialog>

    )
}

export default FormUploadDialog
