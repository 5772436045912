import * as React from 'react'

import { clsxm } from '../../lib/clsxm'

const classes = {
    'day-content': ' h-full w-full font-medium',
    'day-disabled': 'opacity-30 cursor-not-allowed',
    'day-weekend': 'date-picker-cell-weekend',
    'day-current-month': 'text-gray-700 dark:text-gray-100 font-medium',
    'day-none': 'opacity-0 cursor-not-allowed',
    'day-hoverable':
        'hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-gray-300',
}

type DayTab = {
    focusable?: boolean
    hasValue?: boolean
    selected?: boolean
    firstInMonth?: boolean
}
const getDayTabIndex = (dayTab: DayTab): number => {
    const { focusable, hasValue, selected, firstInMonth } = dayTab

    if (!focusable) {
        return -1
    }

    if (hasValue) {
        return selected ? 0 : -1
    }

    return firstInMonth ? 0 : -1
}

export type DayProps = {
    className?: string
    value: Date
    selected?: boolean
    outOfMonth?: boolean
    onClick?: () => void
    onMouseEnter?: (
        value: Date | undefined,
        event: React.MouseEvent<HTMLButtonElement>
    ) => void
    onMouseDown?: (event: React.MouseEvent<HTMLButtonElement>) => void
    onKeyDown?: (event: React.KeyboardEvent<HTMLButtonElement>) => void
    hasValue?: boolean
    firstInRange?: boolean
    lastInRange?: boolean
    isToday?: boolean
    inRange?: boolean
    firstInMonth?: boolean
    focusable?: boolean
    renderDay?: (value: Date) => React.ReactNode
    disabled?: boolean
    style?: React.CSSProperties
    weekend?: boolean
    hideOutOfMonthDates?: boolean
}

const Day = React.forwardRef<HTMLButtonElement, DayProps>((props, ref) => {
    const {
        className,
        value,
        selected,
        outOfMonth,
        onMouseEnter,
        hasValue,
        firstInRange,
        lastInRange,
        isToday,
        inRange,
        firstInMonth,
        focusable,
        renderDay,
        disabled,
        weekend,
        hideOutOfMonthDates,
        ...rest
    } = props

    const dayClasses = clsxm(
        'bg-transparent',
        classes['day-content'],
        disabled && classes['day-disabled'],
        isToday && 'border-primary-600 border',
        weekend && !disabled && classes['day-weekend'],
        outOfMonth && 'text-gray-400',
        outOfMonth &&
            hideOutOfMonthDates &&
            classes['day-none'] &&
            'cursor-auto pointer-events-none opacity-0',
        !outOfMonth && !disabled && !selected && classes['day-current-month'],
        !disabled && !selected && !inRange && classes['day-hoverable'],
        selected && !disabled && 'bg-primary-600 text-gray-100',
        inRange &&
            !disabled &&
            !firstInRange &&
            !lastInRange &&
            !selected &&
            'bg-primary-600 bg-opacity-10',
        !inRange && !firstInRange && !lastInRange && 'rounded-lg',
        firstInRange && !disabled && 'rounded-tl-lg rounded-bl-lg',
        lastInRange && !disabled && 'rounded-tr-lg rounded-br-lg',
        className
    )

    return (
        <button
            {...rest}
            type='button'
            ref={ref}
            disabled={disabled}
            onMouseEnter={(event: React.MouseEvent<HTMLButtonElement>) =>
                onMouseEnter && onMouseEnter(value, event)
            }
            tabIndex={getDayTabIndex({
                focusable,
                hasValue,
                selected,
                firstInMonth,
            })}
            className={dayClasses}
        >
            {typeof renderDay === 'function'
                ? renderDay(value)
                : value?.getDate()}
        </button>
    )
})

export default Day
