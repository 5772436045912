import { LeaveReason } from '@viterbit/web-app/dataAccess/model/LeaveReason.model'
import { IsNotEmpty, IsString } from '@viterbit/web-app/shared/utils/ClassValidators'


type CreateType = Pick<LeaveReason, 'name'> & { leavePolicyId: string }

class CreateLeaveReasonsValidator implements CreateType {
    @IsString()
    leavePolicyId: string
    @IsString()
    @IsNotEmpty()
    name: string
}

export default CreateLeaveReasonsValidator
