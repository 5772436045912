import { useQuery } from '@tanstack/react-query'

import { PENDING_FILES_QUERY_KEY } from './pendingFilesQueryKeys'
import { pendingFileSignedUrl } from '../../service/pendingFilesService'

type FileDetailConfig = {
    query: Parameters<typeof pendingFileSignedUrl>[0]
    enabled?: boolean
    keepPreviousData?: boolean
    refetchOnWindowFocus?: boolean
}

export const usePendingFileSignedUrl = ({
    query,
    enabled,
    keepPreviousData,
    refetchOnWindowFocus
}: FileDetailConfig) =>
    useQuery({
        queryFn: () => pendingFileSignedUrl(query), 
        queryKey: [PENDING_FILES_QUERY_KEY, 'signed-url', query],
        enabled,
        keepPreviousData,
        refetchOnWindowFocus,
    })

