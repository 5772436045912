/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react'

export default function useUncertainRef(ref: any) {
    const newRef = React.useRef()

    if (ref) {
        return ref
    }

    return newRef
}
