/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Button, Popover } from '@mantine/core'
import { IconChevronDown, IconFilter, IconSearch } from '@tabler/icons-react'
import { useCompanyDetail } from '@viterbit/web-app/dataAccess/useCase/company/useCompanyDetail'
import { useLocationDetail } from '@viterbit/web-app/dataAccess/useCase/location/useLocationDetail'
import i18n from '@viterbit/web-app/i18n'
import FormSelectCompany from '@viterbit/web-app/shared/form/FormSelectCompanies'
import FormSelectLocation from '@viterbit/web-app/shared/form/FormSelectLocation'
import debounce from 'lodash/debounce'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { FormSwitcher } from 'ui/src/components/Form'
import FormControlSkeleton from 'ui/src/components/Form/FormControlSkeleton'
import FormInput from 'ui/src/components/Form/FormInput'
import Spinner from 'ui/src/components/Spinner'

type EmployeeGenericFiltersProps = {
    setFilters: (filters: any) => void
    children?: React.ReactNode
}

export type EmployeeGenericFiltersFields = {
    search: string | null
    companyId: string | null
    locationId: string | null
    reporters?: boolean
}

const DEBOUNCE_TIME = 1000

const EmployeeGenericFilters = (props: EmployeeGenericFiltersProps) => {
    const { setFilters, children } = props
    const [params] = useSearchParams()
    const navigate = useNavigate()
    const [isDebouncing, setIsDebouncing] = React.useState(false)
    const [isLoaded, setIsLoaded] = React.useState({
        company: !params.get('companyId'),
        location: !params.get('locationId'),
    })

    const formValues = React.useMemo(() => ({
        search: params.get('search'),
        companyId: params.get('companyId'),
        locationId: params.get('locationId'),
        reporters: Boolean(params.get('reporters')) || undefined,
    }), [params])

    const form = useForm<EmployeeGenericFiltersFields>({
        defaultValues: formValues,
        mode: 'all',
    })

    const [ companyId, locationId, reporters, search ] = form.watch(['companyId', 'locationId', 'reporters', 'search'])

    const { data: defaultCompany } = useCompanyDetail({
        query: { id: companyId } as any,
        enabled: !!formValues?.companyId && !isLoaded.company,
        onSuccess: () => setIsLoaded((prev) => ({ ...prev, company: true })),
        onError: () => setIsLoaded((prev) => ({ ...prev, company: true })),
    })

    const { data: defaultLocation } = useLocationDetail({
        query: { id: formValues?.locationId } as any,
        enabled: !!formValues?.locationId && !isLoaded.location,
        onSuccess: () => setIsLoaded((prev) => ({ ...prev, location: true })),
        onError: () => setIsLoaded((prev) => ({ ...prev, location: true })),
    })

    const handleSyncChanges = React.useCallback((values = form.getValues()) => {
        const { search, companyId, locationId, reporters } = values

        const searchParams = {} as any
        !!search && (searchParams.search = search)
        !!companyId && (searchParams.companyId = companyId)
        !!locationId && (searchParams.locationId = locationId)
        !!reporters && (searchParams.reporters = reporters)
        
        navigate({
            pathname: window.location.pathname,
            search: new URLSearchParams(searchParams).toString(),
        })
        setIsDebouncing(false)
        setFilters(values)
    }, [])

    const handleDebounceChanges = React.useCallback(debounce(handleSyncChanges, DEBOUNCE_TIME), [])

    React.useEffect(() => {
        setIsDebouncing(true)
        handleDebounceChanges()
    }, [search])

    React.useEffect(() => {
        handleSyncChanges()
    }, [companyId, locationId, reporters])

    const hasFilters = React.useMemo(() => !!companyId || !!locationId || !!reporters, [companyId, locationId, reporters])

    return (
        <div className='flex items-center gap-2'>
            <FormInput
                control={form.control}
                name='search'
                className='w-64 h-full mb-0 '
                placeholder={i18n.t('employees:common.search')}
                suffix={
                    isDebouncing ? (
                        <Spinner isSpinning />
                    ) : (
                        <IconSearch size={21} />
                    )
                }
            />
            <Popover keepMounted>
                <Popover.Target>
                    <Button
                        color={hasFilters ? 'primary': 'grey.4'}
                        variant='outline'
                        sx={{ background: 'white' }}
                    >
                        <IconFilter className='mr-2' size={21} />
                        <span>{i18n.t('employees:common.filter')}</span>
                        <IconChevronDown className='ml-2 text-lg' size={21} />
                    </Button>
                </Popover.Target>

                <Popover.Dropdown>
                    <div className='min-w-[300px] pt-2 px-2'>
                        {children}
                        
                        {!isLoaded.company ? (
                            <FormControlSkeleton label />
                        ) : (
                            <FormSelectCompany
                                label={i18n.t('employees:common.company')}
                                placeholder={i18n.t('employees:common.placeholderSelect')}
                                isClearable
                                isLoading={isDebouncing}
                                control={form.control}
                                name='companyId'
                                defaultValue={defaultCompany}
                            />
                        )}

                        {!isLoaded.location ? (
                            <FormControlSkeleton label />
                        ) : (
                            <FormSelectLocation
                                label={i18n.t('employees:common.location')}
                                placeholder={i18n.t('employees:common.placeholderSelect')}
                                isClearable
                                isLoading={isDebouncing}
                                control={form.control}
                                name='locationId'
                                defaultValue={defaultLocation}
                            />
                        )}

                        <FormSwitcher
                            isLoading={isDebouncing}
                            className='flex-row mb-3'
                            label={i18n.t('employees:common.switcher')}
                            name='reporters'
                            control={form.control}
                        />
                    </div>
                </Popover.Dropdown>
            </Popover>
        </div>
    )
}

export default EmployeeGenericFilters
