import { ActionIcon, Tooltip } from '@mantine/core'
import { IconTrash } from '@tabler/icons-react'
import i18n from '@viterbit/web-app/i18n'
import clsx from 'clsx'
import * as React from 'react'
import List from 'ui/src/components/List'
import { ListItemProps } from 'ui/src/components/List/List.d'
import ListItemText from 'ui/src/components/List/ListItemText'

export type SimpleListItemProps = ListItemProps & {
    className?: string
    color?: string
    name?: string | React.ReactNode | React.ReactNode[] | null | undefined | boolean | number
    description?: string
    descriptionPlaceholder?: string
    onClick?: React.MouseEventHandler<HTMLLIElement>
    leftContent?: React.ReactNode | string
    onDelete?: React.MouseEventHandler<HTMLButtonElement>
}

const SimpleListItem = ({
    name,
    color,
    description,
    descriptionPlaceholder,
    className = '',
    onClick,
    leftContent,
    onDelete,
}: SimpleListItemProps) => (
    <List.Item
        data-testid='simple-list-item'
        className={clsx('p-0 overflow-hidden border border-grey-100 cursor-not-allowed bg-white rounded-md shadow-sm', onClick && 'cursor-pointer  hover:bg-grey-50', className)}
        onClick={onClick}
    >
        {leftContent && (
            <div
                className='flex items-center justify-center h-[60px] text-xl font-medium text-white bg-primary-600 aspect-square'
                style={{ backgroundColor: color }}
            >
                {leftContent}
            </div>
        )}
            
        <ListItemText
            primary={name}
            secondary={description || descriptionPlaceholder}
            secondaryClassName={!description ? 'italic': ''}
        />

        {onDelete && (
            <Tooltip label={i18n.t('buttons.delete')} position='left'>
                <ActionIcon onClick={onDelete} className='mr-3' data-testid="simple-list-item-delete">
                    <IconTrash size={20} />
                </ActionIcon>
            </Tooltip>
        )}
    </List.Item>

)

export default SimpleListItem
