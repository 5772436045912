/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FindEmployeeQueryResponse } from './FindEmployeeQueryResponse';
import {
    FindEmployeeQueryResponseFromJSON,
    FindEmployeeQueryResponseFromJSONTyped,
    FindEmployeeQueryResponseToJSON,
} from './FindEmployeeQueryResponse';

/**
 * 
 * @export
 * @interface DataFindEmployeeQueryResponse
 */
export interface DataFindEmployeeQueryResponse {
    /**
     * 
     * @type {Array<FindEmployeeQueryResponse>}
     * @memberof DataFindEmployeeQueryResponse
     */
    data: Array<FindEmployeeQueryResponse>;
}

/**
 * Check if a given object implements the DataFindEmployeeQueryResponse interface.
 */
export function instanceOfDataFindEmployeeQueryResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function DataFindEmployeeQueryResponseFromJSON(json: any): DataFindEmployeeQueryResponse {
    return DataFindEmployeeQueryResponseFromJSONTyped(json, false);
}

export function DataFindEmployeeQueryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataFindEmployeeQueryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(FindEmployeeQueryResponseFromJSON)),
    };
}

export function DataFindEmployeeQueryResponseToJSON(value?: DataFindEmployeeQueryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ((value.data as Array<any>).map(FindEmployeeQueryResponseToJSON)),
    };
}

