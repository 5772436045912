import React, { Children } from 'react'

import { StepsItemProps } from './StepsItem'
import { clsxm } from '../../lib/clsxm'
import { mapCloneElement } from '../../utils/utils'

const classes = {
    steps: 'flex justify-between items-center',
    stepsVertical: 'flex-col items-start',
}

export type StepsStatusType = 'complete' | 'pending' | 'in-progress' | 'error'

type StepProps = {
    className?: string
    vertical?: boolean
    current?: number
    status?: StepsStatusType
    onChange?: (index: number) => void
    children?: React.ReactNode
}

const Steps = React.forwardRef<HTMLDivElement, StepProps>((props, ref) => {
    const {
        className,
        children,
        onChange,
        vertical = false,
        current = 0,
        status = 'in-progress',
        ...rest
    } = props

    const count = Children.count(children)

    const items = mapCloneElement(children, (item, index) => {
        const itemStyles = {
            flexBasis: index < count - 1 ? `${100 / (count - 1)}%` : undefined,
            maxWidth: index === count - 1 ? `${100 / count}%` : undefined,
        }
        const itemProps: StepsItemProps = {
            stepNumber: index + 1,
            status: 'pending',
            style: !vertical ? itemStyles : undefined,
            isLast: index === count - 1,
            vertical: vertical,
            onStepChange: onChange ? () => onChange(index) : undefined,
            ...item.props,
        }

        if (!item.props.status) {
            if (index === current) {
                itemProps.status = status
                itemProps.className = clsxm(itemProps.className)
            } else if (index < current) {
                itemProps.status = 'complete'
            }
        }
        return itemProps
    })

    return (
        <div
            ref={ref}
            className={clsxm(
                classes.steps,
                vertical && classes.stepsVertical,
                className
            )}
            {...rest}
        >
            <>{items}</>
        </div>
    )
})

export default Steps
