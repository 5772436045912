/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateAccountCommand
 */
export interface CreateAccountCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateAccountCommand
     */
    employeeId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountCommand
     */
    roleId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountCommand
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountCommand
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountCommand
     */
    password: string;
}

/**
 * Check if a given object implements the CreateAccountCommand interface.
 */
export function instanceOfCreateAccountCommand(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "password" in value;

    return isInstance;
}

export function CreateAccountCommandFromJSON(json: any): CreateAccountCommand {
    return CreateAccountCommandFromJSONTyped(json, false);
}

export function CreateAccountCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateAccountCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'employeeId': !exists(json, 'employeeId') ? undefined : json['employeeId'],
        'roleId': !exists(json, 'roleId') ? undefined : json['roleId'],
        'name': json['name'],
        'email': json['email'],
        'password': json['password'],
    };
}

export function CreateAccountCommandToJSON(value?: CreateAccountCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'employeeId': value.employeeId,
        'roleId': value.roleId,
        'name': value.name,
        'email': value.email,
        'password': value.password,
    };
}

