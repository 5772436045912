import { Employee } from '@viterbit/web-app/dataAccess/model/Employee.model'
import { PermissionAction } from '@viterbit/web-app/dataAccess/model/PermissionAction.model'
import ExtraDaysDialog from '@viterbit/web-app/feature/leave/list/ExtraDays/ExtraDayDialog'
import i18n from '@viterbit/web-app/i18n'
import useHasSinglePermission from '@viterbit/web-app/shared/permission/useHasSinglePermission'
import * as React from 'react'

type ExtraDaysActionProps = {
    employee: Employee
}

const ExtraDaysAction = ({ employee }: ExtraDaysActionProps) => {
    const [open, setOpen] = React.useState(false)
    const shouldShowExtraAction = useHasSinglePermission(PermissionAction.CORE_HR_LEAVE_ADJUST, employee.id)

    return (
        <>  
            {
                shouldShowExtraAction && (
                    <div className='flex bg-gray-50 rounded-b-lg py-2.5 flex-start min-w-[220px] border-t'>
                        <button
                            className='px-4 text-sm font-normal bg-gray-50 text-primary-600 hover:text-primary-700 focus:outline-none '
                            onClick={() => setOpen(true)}
                        >
                            {i18n.t('daysSummary:addOrRemoveExtraDays')}
                        </button>
                    </div>
                )
            }      

            <ExtraDaysDialog employee={employee} open={open} onClose={() => setOpen(false)} />
        </>
    )
}
export default ExtraDaysAction
