import employeeMapper from './employee.mapper'
import { TeamResponse, UpdateTeamPayload } from '../dto/team'
import { Team } from '../model/Team.model'

const teamMapper = {
    toModel: (dto: TeamResponse): Team => ({
        id: dto.id,
        name: dto.name,
        description: dto.description || undefined,
        countMembers: dto.countMembers ?? 0,
        leaders: dto.leaders?.data.map(employeeMapper.toModel) ?? []
    }),
    toUpdate: (model: Team): UpdateTeamPayload => ({
        name: model.name,
        description: model.description,
    }),
}

export default teamMapper
