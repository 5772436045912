/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FindJobPositionQueryResponse } from './FindJobPositionQueryResponse';
import {
    FindJobPositionQueryResponseFromJSON,
    FindJobPositionQueryResponseFromJSONTyped,
    FindJobPositionQueryResponseToJSON,
} from './FindJobPositionQueryResponse';
import type { ListMeta } from './ListMeta';
import {
    ListMetaFromJSON,
    ListMetaFromJSONTyped,
    ListMetaToJSON,
} from './ListMeta';

/**
 * 
 * @export
 * @interface ListJobPositionQueryResponse
 */
export interface ListJobPositionQueryResponse {
    /**
     * 
     * @type {ListMeta}
     * @memberof ListJobPositionQueryResponse
     */
    meta: ListMeta;
    /**
     * 
     * @type {Array<FindJobPositionQueryResponse>}
     * @memberof ListJobPositionQueryResponse
     */
    data: Array<FindJobPositionQueryResponse>;
}

/**
 * Check if a given object implements the ListJobPositionQueryResponse interface.
 */
export function instanceOfListJobPositionQueryResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "meta" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function ListJobPositionQueryResponseFromJSON(json: any): ListJobPositionQueryResponse {
    return ListJobPositionQueryResponseFromJSONTyped(json, false);
}

export function ListJobPositionQueryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListJobPositionQueryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'meta': ListMetaFromJSON(json['meta']),
        'data': ((json['data'] as Array<any>).map(FindJobPositionQueryResponseFromJSON)),
    };
}

export function ListJobPositionQueryResponseToJSON(value?: ListJobPositionQueryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'meta': ListMetaToJSON(value.meta),
        'data': ((value.data as Array<any>).map(FindJobPositionQueryResponseToJSON)),
    };
}

