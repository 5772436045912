import { PermissionAction } from '@viterbit/web-app/dataAccess/model/PermissionAction.model'
import BulkUploadDelete from '@viterbit/web-app/feature/document/bulkUpload/detail/BulkUploadDelete'
import i18n from '@viterbit/web-app/i18n'
import EmbeddedContent from '@viterbit/web-app/shared/behaviour/EmbeddedContent'
import SimpleCard from '@viterbit/web-app/shared/display/SimpleCard'
import { useUser } from '@viterbit/web-app/shared/employee/useUser'
import HeaderActions from '@viterbit/web-app/shared/layout/HeaderActions'
import HeaderActionsTitle from '@viterbit/web-app/shared/layout/HeaderActions/HeaderActionsTitle'
import MultiColumnLayout from '@viterbit/web-app/shared/layout/MultiColumnLayout'
import { useParentHref } from '@viterbit/web-app/shared/navigation/useParentHref'
import IsGranted from '@viterbit/web-app/shared/permission/IsGranted'
import FileList from 'ui/src/components/Upload/FileList'
import clsxm from 'ui/src/lib'
import { UploadFileTypeEnum } from 'viterbit-api-sdk'

import DocumentBulkUploadDetailForm from './DocumentBulkUploadDetailForm'
import DocumentBulkUploadDetailFormLoading from './DocumentBulkUploadDetailFormLoading'
import DocumentBulkUploadListPaginated from './DocumentBulkUploadListPaginated'
import DocumentBulkUploadNavigationList from './navigation/DocumentBulkUploadNavigationList'
import DocumentBulkUploadAssign from '../assign/DocumentBulkUploadAssign'

type DocumentBulkUploadDetailViewProps = {
  documentType: UploadFileTypeEnum
}

const DocumentBulkUploadDetailView = ({ documentType }: DocumentBulkUploadDetailViewProps) => {
    const parent = useParentHref('/documents')
    const user = useUser()

    return (
        <>
            <EmbeddedContent>
                <DocumentBulkUploadAssign type={documentType} />
            </EmbeddedContent>

            <MultiColumnLayout left={<DocumentBulkUploadNavigationList currentPath={parent} />}>
                <SimpleCard>
                    <HeaderActions
                        className='p-4 pb-0 bg-white'
                    >
                        <HeaderActions.Header>
                            <HeaderActionsTitle>
                                {i18n.t(`personalArea:documents.type.${documentType}`)}
                            </HeaderActionsTitle>
                        </HeaderActions.Header>
                        <IsGranted
                            action={PermissionAction.CORE_PENDING_FILE_DELETE}
                            id={user.organizationId}
                        >
                            <HeaderActions.Actions>
                                <BulkUploadDelete type={documentType} />
                            </HeaderActions.Actions>
                        </IsGranted>
                    </HeaderActions>

                    <p className='px-4 pt-4 pb-0 '>
                        {i18n.t('employees:bulkUpload.bulkAssign.description')}
                    </p>

                    <IsGranted
                        action={PermissionAction.CORE_PENDING_FILE_UPLOAD}
                        id={user.organizationId}
                        loader={<DocumentBulkUploadDetailFormLoading />}
                    >
                        <DocumentBulkUploadDetailForm type={documentType} className={clsxm('p-4 -mb-8' )} />
                    </IsGranted>
            
                    <FileList className={clsxm('p-4')} columns={2}>
                        <DocumentBulkUploadListPaginated  className='min-h-[400px] p-4' type={documentType}  />
                    </FileList>
                </SimpleCard>
            </MultiColumnLayout>
        </>
    )
}

export default DocumentBulkUploadDetailView