/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-array-index-key */

import {  IconFileCheck } from '@tabler/icons-react'
import { PendingFile } from '@viterbit/web-app/dataAccess/model/PendingFile.model'
import { usePendingFileRespond } from '@viterbit/web-app/dataAccess/useCase/pendingFile/usePendingFileRespond'
import i18n from '@viterbit/web-app/i18n'
import { useNavigate } from 'react-router-dom'
import Spinner from 'ui/src/components/Spinner'
import FileItem from 'ui/src/components/Upload/FileItem'
import FileItemButton from 'ui/src/components/Upload/FileItemButton'

import BulkUploadCoincidenceTag from '../BulkUploadCoincidenceTag'

type DocumentBulkUploadDetailItemProps = {
    file: PendingFile
    parentHref: string
    onDelete?: () => void
}

const DocumentBulkUploadDetailItem = ({
    file,
    onDelete,
    parentHref,
}: DocumentBulkUploadDetailItemProps) => {
    const navigate = useNavigate()
    const { error, isLoading, mutate, isSuccess } = usePendingFileRespond({})

    return (
        <FileItem
            file={file}
            onDelete={onDelete}
            onClick={() => navigate(`${parentHref}/documents/${file.kind}/preview?fileId=${file.id}`)}
            t={i18n.t}
            actionsChildren={
                isLoading || isSuccess
                    ? <Spinner isSpinning />
                    : (
                        file.employee && <FileItemButton
                            onClick={() => mutate({id: file.id, respondPendingFileCommand: {accept: true}})}
                            tooltipText={i18n.t('employees:bulkUpload.preview.assign')}
                        >
                            <IconFileCheck size={18}  />
                        </FileItemButton>
                    )
            }
            secondaryText={
                file.employee && (
                    <BulkUploadCoincidenceTag className='max-w-[200px] '>
                        {file.employee.name} {file.employee.surname}
                    </BulkUploadCoincidenceTag>
                )
            }
            canDelete={!isLoading && !isSuccess}
            error={error}
        />
    )
}

export default DocumentBulkUploadDetailItem
