import { classValidatorResolver } from '@hookform/resolvers/class-validator'
import { Employee } from '@viterbit/web-app/dataAccess/model/Employee.model'
import { employeeLeaveAdjustment } from '@viterbit/web-app/dataAccess/service/employeeService'
import i18n from '@viterbit/web-app/i18n'
import Form from '@viterbit/web-app/shared/form/Form'
import SubmitButton from '@viterbit/web-app/shared/form/SubmitButton'
import Button from 'ui/src/components/Button'
import { FormInput } from 'ui/src/components/Form'
import FormCounter from 'ui/src/components/Form/FormCounter'

import ExtraDaysValidator from './ExtraDaysValidator'
import { ExtraDaysFormFields } from './types'

const resolver = classValidatorResolver(ExtraDaysValidator)

type CreateExtraDayFormProps = {
    onSuccess: () => void
    onCancel: () => void
    employee: Employee
}

const getDefaultValues = (employee: Employee, year: number = new Date().getFullYear()): ExtraDaysFormFields => {
    const defaultValues = employee.leaveAdjustments.find(a => a.year === year) || {
        year,
        operation: 0,
        reason: '',
    }

    return defaultValues
}


const CreateExtraDayForm = ({ employee, onSuccess, onCancel }: CreateExtraDayFormProps) => (    
    <Form<ExtraDaysFormFields>
        withTransition
        mutationConfig={{
            mutationKey: ['leave-adjustment'],
            mutationFn: (updateLeaveAdjustmentsCommand: ExtraDaysFormFields) =>
                employeeLeaveAdjustment({
                    updateLeaveAdjustmentsCommand,
                    id: employee.id,
                }),
            onSuccess,
        }}
        formConfig={{
            resolver,
            mode: 'all',
            defaultValues: getDefaultValues(employee)
        }}
    >
        {(form) => (
            <>
                <div className='flex w-full gap-x-4'>
                    <FormCounter control={form.control} name='year' label={i18n.t('leaves:editExtraDaysDialog.year')} rules={{
                        onChange(event) {
                            const year = Number(event.target.value)
                            const values = getDefaultValues(employee, year)
                            form.reset(values)
                        },
                    }} />
                    <FormCounter control={form.control} name='operation' label={i18n.t('leaves:editExtraDaysDialog.days')} />
                </div>

                <FormInput
                    textArea
                    name='reason'
                    label={i18n.t('leaves:editExtraDaysDialog.reason')}
                    placeholder={i18n.t('leaves:editExtraDaysDialog.enterReason')}
                    control={form.control}
                />
                <Button onClick={onCancel}>{i18n.t('buttons.cancel')}</Button>
                <SubmitButton>{i18n.t('buttons.confirm')}</SubmitButton>
            </>
        )}
    </Form>
)

export default CreateExtraDayForm
