import { IconInfoCircle } from '@tabler/icons-react'
import * as React from 'react'
import Alert from 'ui/src/components/Alert'
import Button from 'ui/src/components/Button'
import clsxm from 'ui/src/lib'

type DeleteAlertProps = {
    children?: React.ReactNode | string
    className?: string
    buttonText?: string
    onConfirm: () => void
    buttonClassName?: string
    type?: React.ComponentProps<typeof Alert>['type']
    border?: React.ComponentProps<typeof Alert>['border']
}

const STYLES = {
    danger: {
        button: 'bg-danger-600 hover:bg-danger-500 active:bg-danger-700',
    },
    warning: {
        button: 'bg-warning-600 hover:bg-warning-500 active:bg-warning-700',
    },
    success: {
        button: 'bg-success-600 hover:bg-success-500 active:bg-success-700',
    },
    info: {
        button: 'bg-info-600 hover:bg-info-500 active:bg-info-700',
    },
    primary: {
        button: 'bg-primary-600 hover:bg-primary-500 active:bg-primary-700',
    },
    default: {
        button: 'bg-gray-600 hover:bg-gray-500 active:bg-gray-700',
    },
} as const

const DeleteAlert = ({ buttonText = 'Delete', children, onConfirm, className, type = 'danger', border }: DeleteAlertProps) => {
    const styles = STYLES[type] || STYLES.default

    return (
        <Alert border={border} type={type} className={clsxm('mb-4 px-4 py-3', className)} showIcon variant='outline' endContent={(
            <Button
                data-testid='delete-alert-button'
                variant='solid'
                onClick={onConfirm}
                className={styles.button}
            >
                {buttonText}
            </Button>
        )} icon={<IconInfoCircle size={24} />}>
            {children}
        </Alert>
    )
}


export default DeleteAlert
