/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FindTimeTrackingQueryResponse
 */
export interface FindTimeTrackingQueryResponse {
    /**
     * 
     * @type {string}
     * @memberof FindTimeTrackingQueryResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof FindTimeTrackingQueryResponse
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof FindTimeTrackingQueryResponse
     */
    endDate: string;
}

/**
 * Check if a given object implements the FindTimeTrackingQueryResponse interface.
 */
export function instanceOfFindTimeTrackingQueryResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "startDate" in value;
    isInstance = isInstance && "endDate" in value;

    return isInstance;
}

export function FindTimeTrackingQueryResponseFromJSON(json: any): FindTimeTrackingQueryResponse {
    return FindTimeTrackingQueryResponseFromJSONTyped(json, false);
}

export function FindTimeTrackingQueryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindTimeTrackingQueryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'startDate': json['startDate'],
        'endDate': json['endDate'],
    };
}

export function FindTimeTrackingQueryResponseToJSON(value?: FindTimeTrackingQueryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'startDate': value.startDate,
        'endDate': value.endDate,
    };
}

