import { IconCalendarEvent } from '@tabler/icons-react'
import { LeaveReason } from '@viterbit/web-app/dataAccess/model/LeaveReason.model'
import SimpleListItem from '@viterbit/web-app/shared/display/SimpleListItem'
import * as React from 'react'

type LeaveReasonItemProps = LeaveReason & {
    className?: string
    onClick: React.MouseEventHandler<HTMLLIElement>
}

const LeaveReasonItem = ({
    name,
    color,
    description,
    className = '',
    onClick,
}: LeaveReasonItemProps) => (
    <SimpleListItem
        name={name}
        color={color}
        description={description}
        className={className}
        leftContent={<IconCalendarEvent />}
        onClick={onClick}
    />

)

export default LeaveReasonItem
