import * as React from 'react'

type TitleProps = {
    title?: string
    color?: string
    action?: string
}

const DateTimeItemTitle = ({ title, color, action }: TitleProps) => (
    <div className='flex w-full'>
        {color && (
            <i
                style={{ backgroundColor: color }}
                className='w-[10px] h-[10px] mt-1 mr-1 rounded-full '
            />
        )}

        <p className='block pr-4 text-sm font-bold text-black truncate'>
            {title}
            {action && (
                <span className='ml-2 font-normal text-gray-500'>
                    {action}
                </span>
            )}
        </p>


    </div>
)

export default DateTimeItemTitle
