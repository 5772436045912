
import { classValidatorResolver } from '@hookform/resolvers/class-validator'
import { Employee } from '@viterbit/web-app/dataAccess/model/Employee.model'
import { employeeUpdate } from '@viterbit/web-app/dataAccess/service/employeeService'
import i18n from '@viterbit/web-app/i18n'
import Form from '@viterbit/web-app/shared/form/Form'
import SubmitButton from '@viterbit/web-app/shared/form/SubmitButton'
import * as React from 'react'
import { FormInput } from 'ui/src/components/Form'
import clsxm from 'ui/src/lib'

import { EmployeePersonalBankInfoFields } from './EmployeePersonalBankInfoShared'
import { EmployeePersonalBankInfoValidator } from './EmployeePersonalBankInfoValidator'

type EmployeePersonalBankInfoFormProps = {
    employee: Employee
    className?: string
}

const resolver = classValidatorResolver(EmployeePersonalBankInfoValidator)

const EmployeePersonalBankInfoForm = ({ employee, className }: EmployeePersonalBankInfoFormProps) => {
    const defaultValues = React.useMemo(() => ({
        bankName: employee?.bankName,
        bankSwift: employee?.bankSwift,
        bankNumber: employee?.bankNumber,
    }), [employee])

    return (
        <div>
            <Form<EmployeePersonalBankInfoFields>
                smallButtons
                withTransition
                mutationConfig={{
                    mutationFn: (updateEmployeeCommand) =>
                        employeeUpdate({
                            id: employee.id,
                            updateEmployeeCommand
                        })
                }}
                formConfig={{
                    resolver,
                    mode: 'all',
                    defaultValues,
                }}
                className={clsxm(
                    'p-4 bg-white border border-gray-200 rounded-lg',
                    className
                )}
            >
                {(form) => (
                    <>
                        <FormInput
                            name='bankName'
                            label={i18n.t('personalArea:profile.bankInformation.form.bankName.label')}
                            control={form.control}
                            placeholder={i18n.t('personalArea:profile.bankInformation.form.bankName.placeholder')}
                        />
                        <FormInput
                            name='bankNumber'
                            label={i18n.t('personalArea:profile.bankInformation.form.bankNumber.label')}
                            control={form.control}
                            placeholder={i18n.t('personalArea:profile.bankInformation.form.bankNumber.placeholder')}
                        />
                        <FormInput
                            name='bankSwift'
                            label={i18n.t('personalArea:profile.bankInformation.form.swiftCode.label')}
                            control={form.control}
                            placeholder={i18n.t('personalArea:profile.bankInformation.form.swiftCode.placeholder')}
                        />

                        <SubmitButton data-testid="employee-bank-info-submit-button">{i18n.t('common:buttons.save')}</SubmitButton>
                    </>
                )}
            </Form>
        </div>
    )
}

export default EmployeePersonalBankInfoForm
