import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import * as React from "react"
import { setJWT } from "@viterbit/web-app/shared/state/complaintState"
import { useSearchParam } from "react-use"

const useGatewayToken = () => {
    const { complaintId } = useParams<'complaintId'>()
    const jwt = useSearchParam('token')
    const navigate = useNavigate()

    React.useEffect(() => {
        if (jwt && complaintId) {
            setJWT({
                jwt,
                password: '',
                id: ''
            })
            navigate(`/complaints/detail/${complaintId}`)
        } else {
            throw new Error('Missing token, organizationId or complaintId')
        }
    }, [jwt, complaintId])

    return {
        jwt
    }
}

export default useGatewayToken