/* eslint-disable @typescript-eslint/no-explicit-any */
import { refreshToken } from '@viterbit/web-app/dataAccess/service/authService'
import { IMPERSONATE_USER_KEY } from '@viterbit/web-app/shared/state/userState'
import Cookies from 'js-cookie'
import qs from 'qs'
import { Api } from 'types/Api'
import { ConfigurationParameters, ErrorContext, Middleware } from 'viterbit-api-sdk'

type ErrorResponse = ErrorContext & {
    error?: Api.ResponseError
}

const middleware: Middleware[] = [
    {
        onError: async (context) =>  {
            const { error } = context as ErrorResponse

            if (error?.status === 401 && error?.url.includes('/auth/refresh-token')) {
                window.location.href = '/'
            }

            if (error?.status === 401) {
                await refreshToken()
            }
        },
    }
]

const mapResponse = async (response: any) => {
    const data = await response?.json()
    return {
        url: response.url,
        status: response.status,
        ok: false,
        statusText: response.statusText,
        data
    }
}

const handleError = async (response: any) => {
    if (response?.status >= 300) {
        throw await mapResponse(response)
    }

    if (response?.status === 401) {
        window.location.href = '/'
    }

    return response
}

const getPreparedInit = (url: string, init: any = {}) => ({
    ...init,
    headers: {
        ...(init.headers ?? {}),
        ...(
            Cookies.get(IMPERSONATE_USER_KEY) && !url.includes('refreshToken')
                ? {'X-Switch-User': Cookies.get(IMPERSONATE_USER_KEY)}
                : {}
        )
    }
})

export const commonConfig: ConfigurationParameters = {
    basePath: import.meta.env.VITE_PUBLIC_API_URL,
    middleware,
    fetchApi: (url: string, init: any) => fetch(url, {
        ...getPreparedInit(url, init),
        credentials: 'include'
    }).then(handleError),
    queryParamsStringify: (query) => qs.stringify(query, {
        arrayFormat: 'indices',
        indices: true,
    })
}

export const noMiddlewareConfig = {
    basePath: import.meta.env.VITE_PUBLIC_API_URL,
    fetchApi: (url: string, init: any) => fetch(url, {
        ...init,
        credentials: 'include'
    }).then(handleError)
}