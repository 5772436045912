/* eslint-disable @typescript-eslint/no-explicit-any */
import { Controller } from 'react-hook-form'

import ControlledFormControl from './ControlledFormControl'
import { FormControlBaseProps } from './FormControl'
import { FormBaseProps } from './shared'
import Counter, { CounterProps } from '../Counter/Counter'

export type FormCounterProps = Partial<CounterProps> &
    FormControlBaseProps &
    FormBaseProps

const FormCounter = (props: FormCounterProps) => {
    const {
        iconLeft,
        iconRight,
        step,
        control,
        extra,
        asterisk,
        label,
        name,
        className,
        rules,
        ...rest
    } = props

    return (
        <ControlledFormControl
            control={control}
            extra={extra}
            asterisk={asterisk}
            label={label}
            className={className}
            name={name}
        >
            <Controller
                control={control}
                name={name}
                rules={rules}
                render={({ field }) => (
                    <Counter
                        {...rest}
                        iconLeft={iconLeft}
                        iconRight={iconRight}
                        step={step}
                        {...field}
                    />
                )}
            />
        </ControlledFormControl>
    )
}

export default FormCounter
