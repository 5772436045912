/* eslint-disable @typescript-eslint/no-non-null-assertion */
import dateMapper from '@viterbit/web-app/dataAccess/mapper/date.mapper'
import { formatDay, formatMonthWithYear } from '@viterbit/web-app/shared/utils/dateFormat'
import dayjs from 'dayjs'
import * as React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

export const EmployeeTeamTimelineFrequencyList = ['daily', 'weekly',  'biweekly', 'monthly'] as const
type EmployeeTeamTimelineFrequencyType = typeof EmployeeTeamTimelineFrequencyList[number]

type EmployeeTeamTimelineParams = {
    type: 'pending' | 'approved'
    frequency: EmployeeTeamTimelineFrequencyType
    start?: string
}

const getStart = (frequency: EmployeeTeamTimelineFrequencyType, from?: dayjs.ConfigType) => {
    const now = dayjs(from)
    const start: Record<EmployeeTeamTimelineFrequencyType, string> = {
        daily: dateMapper.toDate(now.startOf('day'))!,
        monthly: dateMapper.toDate(now.startOf('month'))!,
        weekly: dateMapper.toDate(now.startOf('week'))!,
        biweekly: dateMapper.toDate(now.startOf('week'))!,
    }

    return start[frequency]
}

const getNext = (frequency: EmployeeTeamTimelineFrequencyType, from?: dayjs.ConfigType) => {
    const now = dayjs(from)
    const next: Record<EmployeeTeamTimelineFrequencyType, string> = {
        daily: dateMapper.toDate(now.add(1, 'day').startOf('day'))!,
        monthly: dateMapper.toDate(now.add(1, 'month').startOf('month'))!,
        weekly: dateMapper.toDate(now.add(1, 'week').startOf('week'))!,
        biweekly: dateMapper.toDate(now.add(2, 'week').startOf('week'))!,
    }

    return next[frequency]
}

const getEnd = (frequency: EmployeeTeamTimelineFrequencyType, from?: dayjs.ConfigType) => {
    const next = dayjs(getNext(frequency, from)).subtract(1, 'day')

    return dateMapper.toDate(next)!
}

const getPrev = (frequency: EmployeeTeamTimelineFrequencyType, from?: dayjs.ConfigType) => {
    const now = dayjs(from)
    const prev: Record<EmployeeTeamTimelineFrequencyType, string> = {
        daily: dateMapper.toDate(now.subtract(1, 'day').startOf('day'))!,
        monthly: dateMapper.toDate(now.subtract(1, 'month').startOf('month'))!,
        weekly: dateMapper.toDate(now.subtract(1, 'week').startOf('week'))!,
        biweekly: dateMapper.toDate(now.subtract(2, 'week').startOf('week'))!,
    }

    return prev[frequency]
}

const getRangeLabel = ({
    frequency,
    start,
    end,
}: {
    frequency: EmployeeTeamTimelineFrequencyType
    start: string
    end: string
}) => {
    const label: Record<EmployeeTeamTimelineFrequencyType, string> = {
        daily: formatDay(start),
        weekly: `${formatDay(start)} - ${formatDay(end)}`,
        monthly: formatMonthWithYear(start),
        biweekly: `${formatDay(start)} - ${formatDay(end)}`,
    }

    return label[frequency]
}


const useCustomParams = () => {
    const { frequency, start, type } = useParams() as EmployeeTeamTimelineParams

    return React.useMemo(() => {
        const startDate = getStart(frequency, start)
        const endDate = getEnd(frequency, start)
        const options = {
            frequency,
            start: startDate,
            end: endDate,
        }
        const labelRange = getRangeLabel(options)

        return {
            ...options,
            labelRange,
            type,
        }

    }, [frequency, start, type])
}

export const useTimeTrackingTeamRouteParams = () => {
    const { type, frequency, start, end, labelRange } = useCustomParams()
    const navigate = useNavigate()

    const navigateTo = React.useCallback(
        ({
            newFrequency = frequency,
            newStart = start,
            search = window.location.search,
        }: {
            newFrequency?: EmployeeTeamTimelineFrequencyType
            newStart?: string
            search?: string
        }) => {
            navigate({
                pathname: `/core-hr/time-tracking/${type}/${newFrequency}/${newStart}`,
                search,
            })
        },
        [navigate, frequency, start, end, type]
    )

    const changeFrequency = React.useCallback((newFrequency: EmployeeTeamTimelineFrequencyType) => {
        navigateTo({ newFrequency })
    }, [start, type])

    const next = React.useCallback(() => {
        navigateTo({ newStart: getNext(frequency, start) })
    }, [start, frequency, type])

    const prev = React.useCallback(() => {
        navigateTo({ newStart: getPrev(frequency, start) })
    }, [start, frequency, type])
    
    const {nextLink, nextDisabled} = React.useMemo(() => ({
        nextLink: `/core-hr/time-tracking/${type}/${frequency}/${getNext(frequency, start)}`,
        nextDisabled: dayjs().subtract(1, 'day').isBefore(dayjs(end)),
    }), [frequency, start, type])
    
    const {prevLink, prevDisabled} = React.useMemo(() => ({
        prevLink: `/core-hr/time-tracking/${type}/${frequency}/${getPrev(frequency, start)}`,
        prevDisabled: false,
    }), [frequency, start, type])

    const approveLink = React.useMemo(() => `/core-hr/time-tracking/approved/${frequency}/${start}`, [frequency, start, type])
    const pendingLink = React.useMemo(() => `/core-hr/time-tracking/pending/${frequency}/${start}`, [frequency, start, type])

    return {
        changeFrequency,
        navigateTo,
        nextLink,
        nextDisabled,
        prevLink,
        prevDisabled,
        next,
        prev,
        frequency,
        start,
        end,
        labelRange,
        type,
        approveLink,
        pendingLink,
    }
}