/* eslint-disable @typescript-eslint/ban-ts-comment */

import React from 'react'
import { FieldValues, UseFormReturn } from 'react-hook-form'

const useSetFormErrors = <FormValues extends FieldValues>(form: UseFormReturn<FormValues>, fieldValues: FormValues | null) => {
    React.useEffect(() => {
        if(!fieldValues) return

        // @ts-ignore
        Object.entries(fieldValues).forEach(([field, message]) => form.setError(field, { type: 'custom', message }))
    }, [fieldValues])
}

export default useSetFormErrors
