import SectionLayout from '@viterbit/web-app/shared/layout/SectionLayout'
import * as React from 'react'
import { Outlet } from 'react-router-dom'

import WorkflowTabs from './WorkflowTabs'

const WorkflowLayout = () => (
    <SectionLayout>
        <WorkflowTabs >
            <Outlet />
        </WorkflowTabs>
    </SectionLayout>
)

export default WorkflowLayout
