import { classValidatorResolver } from '@hookform/resolvers/class-validator'
import { UpdateCompanyPayLoad } from '@viterbit/web-app/dataAccess/dto'
import { Company } from '@viterbit/web-app/dataAccess/model/Company.model'
import { companyUpdate } from '@viterbit/web-app/dataAccess/service/companyService'
import i18n from '@viterbit/web-app/i18n'
import EmbeddedContent from '@viterbit/web-app/shared/behaviour/EmbeddedContent'
import AsideCardInfo from '@viterbit/web-app/shared/display/AsideCardInfo'
import Form from '@viterbit/web-app/shared/form/Form'
import SubmitButton from '@viterbit/web-app/shared/form/SubmitButton'
import FormInput from 'ui/src/components/Form/FormInput'

import CompanyValidator from '../CompanyValidator'

export type CompanyFormFields = UpdateCompanyPayLoad

export type CreateCompanyProps = {
    company: Company
}

const resolver = classValidatorResolver(CompanyValidator)

const CompanyForm = ({ company }: CreateCompanyProps) => (
    <Form<CompanyFormFields>
        withTransition
        formConfig={{
            resolver,
            mode: 'all',
            defaultValues: company,
        }}
        mutationConfig={{
            mutationFn: (data) => companyUpdate({
                id: company.id,
                updateCompanyCommand: data
            }),
        }}
    >
        {(form, _, state) => (
            <>
                <EmbeddedContent>
                    <SubmitButton form={state.formId}>{i18n.t('settingCompanies:save')}</SubmitButton>
                </EmbeddedContent>

                <AsideCardInfo
                    className='mb-4'
                    title={i18n.t('settingCompanies:basicInformation')}
                    description={i18n.t('settingCompanies:addANameToYourCompany')}
                >
                    <FormInput
                        asterisk
                        name='name'
                        control={form.control}
                        label='Name'
                        placeholder={i18n.t('settingCompanies:enterCompanyName')}
                    />
                </AsideCardInfo>
            </>
        )}
    </Form>
)

export default CompanyForm
