
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AnimatePresence, motion } from 'framer-motion'
import * as React from 'react'
import { Manager, Popper, Reference } from 'react-popper'
import { Portal } from 'react-portal'

import Arrow from './Arrow'
import { placement } from './types'
import { clsxm } from '../../lib/clsxm'

type PopperElementProps = {
    title: string | React.ReactNode
    forceUpdate?: () => void
    isOpen?: boolean
}

const PopperElement = ({
    title,
    forceUpdate,
    isOpen: isOpenProp,
}: PopperElementProps) => {
    React.useEffect(() => {
        if (isOpenProp && forceUpdate) {
            forceUpdate()
        }
    }, [forceUpdate, isOpenProp])
    return <span>{title}</span>
}

type TooltipProps = {
    title: string | React.ReactNode
    placement?: placement
    children?: React.ReactNode
    wrapperClass?: string
    isOpen?: boolean
    tooltipClass?: string
    arrowClassColor?: string
    arrowClassColorDark?: string
}

const classes = {
    default: 'bg-gray-800 dark:bg-black',
    tooltip: 'z-50 max-w-[250px] text-white rounded-lg py-2 px-4 relative',
    'tooltip-wrapper': 'inline-flex',
    background: 'gray-800',
    'dark-background': 'black',
}

const Tooltip = ({
    title,
    placement = 'top',
    children,
    wrapperClass,
    isOpen: isOpenProp,
    tooltipClass,
    arrowClassColor,
    arrowClassColorDark
}: TooltipProps) => {
    const [isOpen, setIsOpen] = React.useState<boolean>(Boolean(isOpenProp))
    const tooltipNode = React.useRef()

    const defaultTooltipClass = tooltipClass ?? clsxm(classes.default, classes.tooltip)

    const toggleTooltip = React.useCallback(
        (bool: boolean) => {
            if (!isOpenProp) {
                setIsOpen(bool)
            }
        },
        [isOpenProp]
    )

    return (
        <Manager>
            <Reference>
                {({ ref }) => (
                    <span
                        className={clsxm(
                            classes['tooltip-wrapper'],
                            wrapperClass
                        )}
                        ref={ref}
                        onMouseEnter={() => toggleTooltip(true)}
                        onMouseLeave={() => toggleTooltip(false)}
                    >
                        {children}
                    </span>
                )}
            </Reference>
            {isOpen && (
                <Portal>
                    <Popper
                        placement={placement}
                        innerRef={(node) => (tooltipNode.current = node)}
                        modifiers={[
                            {
                                name: 'arrow',
                                options: { element: Arrow as any },
                            },
                            { name: 'offset', options: { offset: [0, 7] } },
                        ]}
                        strategy='fixed'
                    >
                        {({ ref, style }) => (
                            <AnimatePresence>
                                <motion.div
                                    id='motion'
                                    className={defaultTooltipClass}
                                    ref={ref}
                                    style={style}
                                    initial={{
                                        opacity: 0,
                                        visibility: 'hidden',
                                    }}
                                    animate={
                                        isOpen
                                            ? {
                                                opacity: 1,
                                                visibility: 'visible',
                                            }
                                            : {
                                                opacity: 0,
                                                visibility: 'hidden',
                                            }
                                    }
                                    transition={{
                                        duration: 0.15,
                                        type: 'tween',
                                    }}
                                >
                                    <PopperElement
                                        isOpen={isOpen}
                                        title={title}
                                    />
                                    <Arrow
                                        placement={placement}
                                        color={arrowClassColor ?? classes.background}
                                        colorDark={arrowClassColorDark ?? classes['dark-background']}
                                    />
                                </motion.div>
                            </AnimatePresence>
                        )}
                    </Popper>
                </Portal>
            )}
        </Manager>
    )
}

export default Tooltip
