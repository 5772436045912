import { classValidatorResolver } from '@hookform/resolvers/class-validator'
import { IconNoteOff } from '@tabler/icons-react'
import { JobPosition } from '@viterbit/web-app/dataAccess/model/JobPosition.model'
import { JobPositionLevel } from '@viterbit/web-app/dataAccess/model/JobPositionLevel.model'
import { jobPositionUpdate } from '@viterbit/web-app/dataAccess/service/jobPositionService'
import i18n from '@viterbit/web-app/i18n'
import EmbeddedContent from '@viterbit/web-app/shared/behaviour/EmbeddedContent'
import AsideCardInfo from '@viterbit/web-app/shared/display/AsideCardInfo'
import EmptyState from '@viterbit/web-app/shared/display/EmptyState/EmptyState'
import SimpleCard from '@viterbit/web-app/shared/display/SimpleCard'
import ButtonCreate from '@viterbit/web-app/shared/form/ButtonCreate'
import Form from '@viterbit/web-app/shared/form/Form'
import SubmitButton from '@viterbit/web-app/shared/form/SubmitButton'
import React from 'react'
import Dialog from 'ui/src/components/Dialog'
import {
    FormInput
} from 'ui/src/components/Form'

import JobPositionDetailValidator from './JobPositionDetailValidator'
import { JobPositionsFormFields } from '../JobPositionShared'
import JobPositionLevelCreateView from '../level/create/JobPositionLevelCreateView'
import JobPositionLevelDeleteDialog from '../level/delete/JobPositionLevelDeleteDialog'
import DetailsJobPositionLevelView from '../level/detail/JobPositionLevelDetailView'
import JobPositionLevelItem from '../level/list/JobPositionLevelItem'
import JobPositionLevelList from '../level/list/JobPositionLevelList'

export type JobPositionDetailFormProps = {
    position: JobPosition
}

const resolver = classValidatorResolver(JobPositionDetailValidator)

const JobPositionDetailForm = ({
    position,
}: JobPositionDetailFormProps) => {
    const [deleteDetail, setDeleteDetail] = React.useState<JobPositionLevel | null>(null)
    const [createJobPositionLevel, setCreateJobPositionLevel] = React.useState<JobPosition | null>(null)
    const [editJobPositionLevel, setEditJobPositionLevel] = React.useState<JobPositionLevel | null>(null)

    return (
        <>
            <Form<JobPositionsFormFields>
                withTransition
                formConfig={{
                    resolver,
                    mode: 'all',
                    defaultValues: position,
                }}
                mutationConfig={{
                    mutationFn: (data) =>
                        jobPositionUpdate({
                            id: position.id,
                            updateJobPositionCommand: data
                        }),
                }}
            >

                {(form, _, state) => (
                    <>
                        <EmbeddedContent>
                            <SubmitButton form={state.formId}>{i18n.t('common:buttons.save')}</SubmitButton>
                        </EmbeddedContent>

                        <AsideCardInfo
                            className='mb-4 border-b'
                            title= {i18n.t('employees:jobPositions.basicSection.title')}
                            description={i18n.t('employees:jobPositions.basicSection.description')}
                        >
                            <FormInput
                                asterisk
                                name='name'
                                control={form.control}
                                label={i18n.t('employees:jobPositions.basicSection.nameLabel')}
                                placeholder={i18n.t('employees:basicSection.namePlaceholder')}
                            />
                            <FormInput
                                textArea
                                size='lg'
                                name='description'
                                control={form.control}
                                label={i18n.t('employees:jobPositions.basicSection.descriptionLabel')}
                                placeholder={i18n.t('employees:jobPositions.basicSection.descriptionPlaceholder')}
                            />
                        </AsideCardInfo>

                        <AsideCardInfo
                            className='pb-6 mb-4 border-b'
                            title={i18n.t('employees:jobPositions.levelSection.title')}
                            description={
                                <>
                                    <p>
                                        {i18n.t('employees:jobPositions.levelSection.description')}
                                    </p>
                                </>
                            }
                            actions={

                                <ButtonCreate onClick={() => setCreateJobPositionLevel(position)} collapsed />
                            }
                        >

                            {position.jobPositionLevels.length ? (
                                <JobPositionLevelList>
                                    {position.jobPositionLevels?.map((level) => (
                                        <JobPositionLevelItem
                                            key={level.id}
                                            level={level}
                                            onDelete={(event) => {
                                                event.stopPropagation()
                                                setDeleteDetail(level)
                                            }}
                                            onClick={() => { setEditJobPositionLevel(level)}}
                                        />
                                    ))}
                                </JobPositionLevelList>
                            ): (
                                <SimpleCard>
                                    <EmptyState
                                        image={<IconNoteOff size={64} stroke={1} />}
                                        className='py-4 bg-gray-50'
                                        title={i18n.t('employees:jobPositions.levelSection.emptyLevels.title')}
                                        description={i18n.t('employees:jobPositions.levelSection.emptyLevels.description')}
                                    />
                                </SimpleCard>
                            )}
                        </AsideCardInfo>
                    </>
                )}
            </Form>
            <JobPositionLevelDeleteDialog
                onCancel={() => setDeleteDetail(null)}
                onSuccess={() => setDeleteDetail(null)}
                level={deleteDetail}
            />

            <Dialog size="xl" open={!!createJobPositionLevel} onClose={() => setCreateJobPositionLevel(null)} >
                {!!createJobPositionLevel && 
                    <JobPositionLevelCreateView
                        onFinish={() => setCreateJobPositionLevel(null)} positionId={createJobPositionLevel.id}
                    />
                }
            </Dialog>

            <Dialog size="xl" open={!!editJobPositionLevel} onClose={() => setEditJobPositionLevel(null)} >
                {!!editJobPositionLevel && 
                    <DetailsJobPositionLevelView
                        onCancel={() => setEditJobPositionLevel(null)} 
                        onSuccess={() => setEditJobPositionLevel(null)}
                        level={editJobPositionLevel} 
                    />
                }
            </Dialog>
            
        </>
    )
}

export default JobPositionDetailForm
