/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { classValidatorResolver } from '@hookform/resolvers/class-validator'
import { UpdateLocationPayload } from '@viterbit/web-app/dataAccess/dto'
import { Calendar } from '@viterbit/web-app/dataAccess/model/Calendar.model'
import { Employee } from '@viterbit/web-app/dataAccess/model/Employee.model'
import { Location } from '@viterbit/web-app/dataAccess/model/Location.model'
import { locationUpdate } from '@viterbit/web-app/dataAccess/service/locationService'
import { useEmployeeDetail } from '@viterbit/web-app/dataAccess/useCase/employee/useEmployeeDetail'
import i18n from '@viterbit/web-app/i18n'
import EmbeddedContent from '@viterbit/web-app/shared/behaviour/EmbeddedContent'
import AsideCardInfo from '@viterbit/web-app/shared/display/AsideCardInfo'
import { useUser } from '@viterbit/web-app/shared/employee/useUser'
import FieldsetGeoData from '@viterbit/web-app/shared/form/FieldsetGeoData'
import Form from '@viterbit/web-app/shared/form/Form'
import FormSelectCity from '@viterbit/web-app/shared/form/FormSelectCity'
import FormSelectCountry from '@viterbit/web-app/shared/form/FormSelectCountry'
import FormSelectState from '@viterbit/web-app/shared/form/FormSelectState'
import SubmitButton from '@viterbit/web-app/shared/form/SubmitButton'
import useLocale from '@viterbit/web-app/shared/utils/useLocale'
import * as React from 'react'
import Dialog from 'ui/src/components/Dialog'
import FormInput from 'ui/src/components/Form/FormInput'

import DeleteHolidayItem from './DeleteHolidayItem'
import HolidayItem from './HolidayItem'
import HolidaysList from './HolidaysList'
import LocationFormsValidator from './validators'
import LocationHolidayCreateForm from '../holidays/create/LocationHolidayCreateForm'
import DetailsHolidayItemView from '../holidays/detail/LocationHolidayDetailForm'

export type LocationFormFields = UpdateLocationPayload

type LocationFormProps = {
    location: Location
}

type EditHolidays = {
    location: Location
    year: number
    employee: Employee
}

const resolver = classValidatorResolver(LocationFormsValidator.Form)

const LocationForm = ({
    location,
}: LocationFormProps) => {

    const [createHolidays, setCreateHolidays] = React.useState<boolean>(false)
    const [editHolidays, setEditHolidays] = React.useState<EditHolidays | null>(null)

    const user = useUser()
    const {data: employee} = useEmployeeDetail({
        query: {
            id: user.id,
        }
    })
    const locale = useLocale()
    const [deleteDetail, setDeleteDetail] = React.useState<Calendar | null>(null)

    return (
        <>
            <Form<LocationFormFields>
                withTransition
                formConfig={{
                    resolver,
                    mode: 'all',
                    defaultValues: location,
                }}
                mutationConfig={{
                    mutationFn: (data) => locationUpdate({
                        id:location.id,
                        updateLocationCommand: data
                    }),
                }}
            >
                {(form, _, state) => (
                    <>
                        <EmbeddedContent>
                            <SubmitButton form={state.formId}>{i18n.t('settingLocations:save')}</SubmitButton>
                        </EmbeddedContent>

                        <AsideCardInfo
                            className='mb-4 border-b'
                            title={i18n.t('settingLocations:basicInformation')}
                            description={i18n.t('settingLocations:addLocationName')}
                        >
                            <FormInput
                                asterisk
                                name='name'
                                control={form.control}
                                label={i18n.t('settingLocations:name')}
                                placeholder={i18n.t('settingLocations:enterLocationName')}
                            />
                        </AsideCardInfo>


                        <AsideCardInfo
                            className='mb-4 border-b'
                            title={i18n.t('settingLocations:address')}
                            description={i18n.t('settingLocations:locateYourLocation')}
                        >
                            <FieldsetGeoData>
                                <FormSelectCountry
                                    locale={locale}
                                    name='country'
                                    asterisk
                                    control={form.control}
                                    label={i18n.t('settingLocations:country')}
                                    placeholder={i18n.t('settingLocations:selectCountry')}
                                />
                                <FormSelectState
                                    name='state'
                                    asterisk
                                    control={form.control}
                                    label={i18n.t('settingLocations:state')}
                                    placeholder={i18n.t('settingLocations:selectState')}
                                />
                                <FormSelectCity
                                    name='city'
                                    control={form.control}
                                    label={i18n.t('settingLocations:city')}
                                    placeholder={i18n.t('settingLocations:selectCity')}
                                    isClearable
                                />
                            </FieldsetGeoData>
                            <FormInput
                                name='address'
                                control={form.control}
                                label={i18n.t('settingLocations:address')}
                                placeholder={i18n.t('settingLocations:selectAddress')}
                            />
                            <FormInput
                                name='postalCode'
                                control={form.control}
                                label={i18n.t('settingLocations:postalCode')}
                                placeholder={i18n.t('settingLocations:selectPostalCode')}
                            />
                        </AsideCardInfo>

                        <AsideCardInfo
                            className='pb-6 mb-4 border-b'
                            title={i18n.t('settingLocations:holidays')}
                            description={
                                <>
                                    <p>
                                        {i18n.t('settingLocations:addHolidays')}
                                    </p>
                                </>
                            }
                        >
                            <HolidaysList location={location} onClick={() => setCreateHolidays(!createHolidays)}>
                                {location.calendar.map((calendarItem) => (
                                    <HolidayItem
                                        onClick={() => setEditHolidays({
                                            employee: employee!,
                                            location,
                                            year: calendarItem.year
                                        })}
                                        key={calendarItem.year}
                                        calendar={calendarItem}
                                        onDelete={(event) => {
                                            event.preventDefault()
                                            event.stopPropagation()
                                            setDeleteDetail(calendarItem)
                                        }}
                                    />
                                ))}
                            </HolidaysList>
                        </AsideCardInfo>
                    </>
                )}
            </Form>

            <Dialog open={createHolidays} onClose={() => setCreateHolidays(false)}>
                <LocationHolidayCreateForm onClose={() => setCreateHolidays(false)} onSuccess={() => setCreateHolidays(false)}/>
            </Dialog>

            <Dialog size="auto" open={!!editHolidays} onClose={() => setEditHolidays(null)}>
                {editHolidays && <DetailsHolidayItemView {...editHolidays} onSuccess={() => setEditHolidays(null)}/> }
            </Dialog>

            <DeleteHolidayItem
                onCancel={() => setDeleteDetail(null)}
                onSuccess={() => setDeleteDetail(null)}
                location={location}
                calendar={deleteDetail}
            />
        </>

    )
}

export default LocationForm
