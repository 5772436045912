import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useUser } from '../employee/useUser'


const useBillingProtected = () => {
    const user = useUser()
    const navigate = useNavigate()
    const { pathname } = useLocation()

    React.useEffect(() => {
        if (!user) return

        if (!user.isActive && !pathname.startsWith('/billing/')) {
            const redirectPath = user.menu.Billing ? '/billing/subscription/list' : '/subscription-invalid'
            navigate(redirectPath, { replace: true })
        }
    }, [user, pathname])
}

export default useBillingProtected