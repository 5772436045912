/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { PermissionAction } from '@viterbit/web-app/dataAccess/model/PermissionAction.model'
import { employeeUpdate } from '@viterbit/web-app/dataAccess/service/employeeService'
import i18n from '@viterbit/web-app/i18n'
import AsideCardInfo from '@viterbit/web-app/shared/display/AsideCardInfo'
import SimpleCard from '@viterbit/web-app/shared/display/SimpleCard'
import { useCurrentEmployeeId } from '@viterbit/web-app/shared/employee/useCurrentEmployeeId'
import FormInputUploadDialog from '@viterbit/web-app/shared/form/FormInputUploadDialog'
import * as React from 'react'
import clsxm from 'ui/src/lib'

import EmployeePersonalAddressInformationForm from './addressInformation/EmployeePersonalAddressInformationForm'
import EmployeePersonalAvatar from './avatar/EmployeePersonalAvatar'
import EmployeePersonalBankInfoForm from './bankInfo/EmployeePersonalBankInfoForm'
import EmployeePersonalEmergencyForm from './emergency/EmployeePersonalEmergencyForm'
import EmployeePersonalIdentificationsForm from './identification/EmployeePersonalIdentificationsForm'
import EmployeePersonalInformationForm from './information/EmployeePersonalInformationForm'
import EmployeeContext from '../EmployeeContext'
import { useCurrentEmployee } from '../../../dataAccess/useCase/employee/useCurrentEmployee'
import MultiColumnLayout from '../../../shared/layout/MultiColumnLayout'
import NavigationTabsContentLoading from '../../../shared/navigation/Tabs/NavigationTabsContentLoading'

const classes = {
    common: 'p-0 border-white rounded-none',
}

const EmployeePersonalView = () => {
    const employee = useCurrentEmployee()
    const employeeId = useCurrentEmployeeId()!
    const { hasPermission } = React.useContext(EmployeeContext)
    const hasPermissionToEdit = hasPermission(PermissionAction.CORE_EMPLOYEE_UPDATE, employeeId)
    const hasPermissionToEditPersonalInfo = hasPermission(PermissionAction.CORE_EMPLOYEE_PERSONAL_UPDATE, employeeId)
    
    if (!employee) {
        return <NavigationTabsContentLoading />
    }

    return (
        <MultiColumnLayout
            left={<EmployeePersonalAvatar employee={employee} />}
        >

            <div className={clsxm('flex flex-col gap-y-4 w-full')}>
                <SimpleCard padding>
                    <AsideCardInfo
                        title={i18n.t('personalArea:profile.generalInformation.title')}
                        description={i18n.t('personalArea:profile.generalInformation.subtitle')}
                        disabled={!hasPermissionToEditPersonalInfo}
                    >
                        <EmployeePersonalInformationForm
                            employee={employee}
                            className={classes.common}
                        />
                    </AsideCardInfo>
                </SimpleCard>

                <SimpleCard padding>
                    <AsideCardInfo
                        title={i18n.t('personalArea:profile.addressInformation.title')}
                        description={i18n.t('personalArea:profile.addressInformation.subtitle')}
                        disabled={!hasPermissionToEditPersonalInfo}
                    >
                        <EmployeePersonalAddressInformationForm
                            employee={employee}
                            className={classes.common}
                        />
                    </AsideCardInfo>
                </SimpleCard>

                <SimpleCard padding>
                    <AsideCardInfo
                        title={i18n.t('personalArea:profile.bankInformation.title')}
                        description={i18n.t('personalArea:profile.bankInformation.subtitle')}
                        disabled={!hasPermissionToEdit}
                    >
                        <EmployeePersonalBankInfoForm employee={employee} className={classes.common} />
                    </AsideCardInfo>
                </SimpleCard>

                <SimpleCard padding>
                    <AsideCardInfo
                        title={i18n.t('employees:employee.profile.taxInfo.title')}
                        description={i18n.t('employees:employee.profile.taxInfo.description')}
                        disabled={!hasPermissionToEdit}
                    >
                        <FormInputUploadDialog
                            title={i18n.t('employees:employee.profile.taxFileTitle')}
                            label={i18n.t('employees:employee.profile.idFileLabel')}
                            placeholder={i18n.t('employees:employee.profile.idFilePlaceholder')}
                            file={employee.idFile}
                            uploadProps={{
                                employeeId: employee.id,
                                kind: 'id',
                                draggable: true,
                                multiple: false,
                                accept: 'image/png, image/jpeg',
                            }}
                            onConfirm={async (filesId) => {
                                await employeeUpdate({
                                    id: employee.id,
                                    updateEmployeeCommand: {
                                        idFileId: filesId as string,
                                    }
                                })
                            }}
                        />
                        <EmployeePersonalIdentificationsForm employee={employee} className={classes.common} />
                    </AsideCardInfo>
                </SimpleCard>

                <SimpleCard padding>
                    <AsideCardInfo
                        title={i18n.t('personalArea:profile.emergencyInformation.title')}
                        description={i18n.t('personalArea:profile.emergencyInformation.description')}
                        disabled={!hasPermissionToEditPersonalInfo}
                    >
                        <EmployeePersonalEmergencyForm employee={employee} />
                    </AsideCardInfo>
                </SimpleCard>
            
            </div>
        </MultiColumnLayout>
    )
}

export default EmployeePersonalView
