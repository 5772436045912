import { IconNoteOff } from '@tabler/icons-react'
import { useTeamListPaginated } from '@viterbit/web-app/dataAccess/useCase/team/useTeamListPaginated'
import i18n from '@viterbit/web-app/i18n'
import { useOnScreenElement } from '@viterbit/web-app/shared/behaviour/useOnScreenElement'
import EmptyState from '@viterbit/web-app/shared/display/EmptyState/EmptyState'
import BreakpointRedirection from '@viterbit/web-app/shared/navigation/BreakpointRedirection'
import * as React from 'react'
import List from 'ui/src/components/List'

import TeamListItem from './TeamListItem'


const TeamListView = () => {
    const { ref } = useOnScreenElement({
        onVisible: () => {
            hasNextPage && fetchNextPage()
        }
    })
    
    const {
        data,
        fetchNextPage,
        hasNextPage,
        isFetched,
    } = useTeamListPaginated()

    const firstTeam = React.useMemo(() => data?.pages[0]?.data[0], [data])

    return (
        <>
            {firstTeam && <BreakpointRedirection matcherPath="/employees/team" redirectPath={`/employees/team/${firstTeam?.id}/details`} />}

            {isFetched && !firstTeam && (
                <EmptyState
                    image={<IconNoteOff size={64} stroke={1} />}
                    className='my-20'
                    title={i18n.t('employees:teams.emptyList.title')}
                    description={i18n.t('employees:teams.emptyList.description')}
                />
            )}

            <List className='flex flex-col divide-y'>
                {data?.pages?.map(({ data: _data }) =>
                    _data.map((team) => (
                        <TeamListItem key={team.id} item={team} />
                    ))
                )}
            </List>

            <div ref={ref} />
            
        </>
    )
}

export default TeamListView
