/* eslint-disable @typescript-eslint/no-explicit-any */
import { IconRotate2 } from '@tabler/icons-react'
import * as React from 'react'

import Spinner from '../Spinner'
import { clsxm } from '../../lib/clsxm'

const classes = {
    switcher: {
        default:
            'w-[2.75rem] h-6 inline-flex items-center relative rounded-2xl bg-gray-200 dark:bg-gray-600 cursor-pointer transition-colors ease-in-out duration-200',
        switcherDisabled: 'opacity-50 cursor-not-allowed',
    },
    switcherToggle: {
        default: 'absolute transition-all ease-in-out duration-200',
        before: 'absolute left-0 right-0 bottom-0 top-0 bg-white rounded-xl',
    },
    switcherContent: 'text-white transition-colors ease-in-out duration-200',
    switcherToggleLoading: 'absolute transition-all ease-in-out duration-200',
    switcherCheckedLoading: '!text-white',
    switcherUncheckLoading: 'dark:!text-white',
}

export type SwitcherProps = {
    checked?: boolean
    disabled?: boolean
    defaultChecked?: boolean
    isLoading?: boolean
    color?: string
    onChange?: (value: boolean, e: any) => void
    checkedContent?: string | React.ReactNode
    unCheckedContent?: string | React.ReactNode
    labelRef?: string
    className?: string
    name?: string
    readOnly?: boolean
    children?: React.ReactNode
}
const Switcher = React.forwardRef<HTMLDivElement, SwitcherProps>(
    (props, ref) => {
        const {
            checked,
            checkedContent,
            className,
            color,
            defaultChecked,
            disabled,
            isLoading = false,
            labelRef,
            name,
            onChange,
            readOnly,
            unCheckedContent,
            children,
            ...rest
        } = props

        const [switcherChecked, setSwitcherChecked] = React.useState(
            defaultChecked || checked
        )

        React.useEffect(() => {
            if (typeof checked !== 'undefined') {
                setSwitcherChecked(checked)
            }
        }, [checked])

        const getControlProps = () => {
            const _checked: any = { value: switcherChecked }

            if (defaultChecked) {
                _checked.defaultChecked = defaultChecked
            }
            return _checked
        }

        const controlProps = getControlProps()

        const handleChange = (e: any) => {
            const nextChecked = !switcherChecked

            if (disabled || readOnly || isLoading) {
                return
            }

            if (typeof checked === 'undefined') {
                setSwitcherChecked(nextChecked)
                onChange?.(nextChecked, e)
            } else {
                onChange?.(!!switcherChecked, e)
            }
        }

        const switcherColor = color || 'primary-600'

        const switcherClass = clsxm(
            classes.switcher.default,
            (switcherChecked || controlProps.checked) &&
                `switcher-checked bg-${switcherColor} dark:bg-${switcherColor}`,
            disabled && classes.switcher.switcherDisabled,
            className
        )

        return (
            <label ref={labelRef} className={switcherClass}>
                <input
                    className='hidden'
                    ref={ref}
                    type='checkbox'
                    disabled={disabled}
                    readOnly={readOnly}
                    onChange={handleChange}
                    name={name}
                    {...controlProps}
                    {...rest}
                />
                {isLoading ? (
                    <Spinner
                        className={clsxm(
                            'switcher-toggle-loading left-[0.125rem] top-[0.125rem] h-5 w-5',
                            classes.switcherToggleLoading,
                            switcherChecked
                                ? classes.switcherCheckedLoading
                                : classes.switcherUncheckLoading
                        )}
                        indicator={IconRotate2}
                        isSpinning
                    />
                ) : (
                    <div className='switcher-toggle' data-testid="switcher-toggle" />
                )}
                <span
                    className={clsxm(
                        'switcher-content mt-0 mr-[0.45rem] mb-0 ml-[1.65rem]',
                        classes.switcherContent
                    )}
                >
                    {switcherChecked ? checkedContent : unCheckedContent}
                </span>
                {children ? (
                    <span
                        className={clsxm(
                            'ml-2 rtl:mr-2',
                            disabled ? 'opacity-50' : ''
                        )}
                    >
                        {children}
                    </span>
                ) : null}
            </label>
        )
    }
)

export default Switcher
