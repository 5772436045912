import { IconStar } from '@tabler/icons-react'
import * as React from 'react'
import Button from 'ui/src/components/Button'
import { ButtonProps } from 'ui/src/components/Button/Button'
import clsxm from 'ui/src/lib'

type MarkDefaultButtonProps = ButtonProps & {
    children?: React.ReactNode
    className?: string
    show?: boolean
}

const styles = {
    root: ''
}

const MarkDefaultButton = (props: MarkDefaultButtonProps) => {
    const { show, className, ...rest } = props

    if (!show) return null

    return (
        <Button
            shape='circle'
            size='xxs'
            className={clsxm(styles.root, className)}
            variant="twoTone"
            icon={<IconStar fill="currentColor" />}
            {...rest}
        />

    )
}

export default MarkDefaultButton
