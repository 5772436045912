import * as React from 'react'
import { clsxm } from 'ui/src/lib/clsxm'

import HeaderActionsActions from './HeaderActionsActions'
import HeaderActionsEmbedded from './HeaderActionsEmbedded'
import HeaderActionsHeader from './HeaderActionsHeader'

const STYLES = {
    root: 'flex gap-4 flex-wrap items-center',
    sticky: 'lg:sticky top-0 z-20 bg-grey-100',
    divider: 'border-b border-grey-200',
}

type HeaderActionsProps = {
    children?: React.ReactNode
    className?: string
    sticky?: boolean
    divider?: boolean
}

const HeaderActions = ({ children, className, sticky }: HeaderActionsProps) => (
    <header className={clsxm(STYLES.root, sticky && STYLES.sticky, className)}>
        {children}
    </header>
)

HeaderActions.Header = HeaderActionsHeader
HeaderActions.Actions = HeaderActionsActions
HeaderActions.ActionsEmbedded = HeaderActionsEmbedded

export default HeaderActions
