import { IMapper } from './__common'
import { IntegrationResponse } from '../dto/integration'
import { Integration } from '../model/Integration.model'

const integrationMapper: IMapper<Integration, IntegrationResponse> = {
    toModel: (dto) => ({
        ...dto,
        default: dto._default
    }),
    toDto: () => {
        throw new Error('Not implemented')
    },
}

export default integrationMapper
