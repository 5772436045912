/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AssignEmployeeCommand,
  CreateTeamCommand,
  FindTeamMembersQueryResponse,
  FindTeamQueryResponse,
  ListTeamQueryResponse,
  ResourceCreated,
  UnassignEmployeeCommand,
  UpdateTeamCommand,
} from '../models/index';
import {
    AssignEmployeeCommandFromJSON,
    AssignEmployeeCommandToJSON,
    CreateTeamCommandFromJSON,
    CreateTeamCommandToJSON,
    FindTeamMembersQueryResponseFromJSON,
    FindTeamMembersQueryResponseToJSON,
    FindTeamQueryResponseFromJSON,
    FindTeamQueryResponseToJSON,
    ListTeamQueryResponseFromJSON,
    ListTeamQueryResponseToJSON,
    ResourceCreatedFromJSON,
    ResourceCreatedToJSON,
    UnassignEmployeeCommandFromJSON,
    UnassignEmployeeCommandToJSON,
    UpdateTeamCommandFromJSON,
    UpdateTeamCommandToJSON,
} from '../models/index';

export interface AssignEmployeeRequest {
    id: string;
    assignEmployeeCommand?: AssignEmployeeCommand;
}

export interface CreateTeamRequest {
    createTeamCommand?: CreateTeamCommand;
}

export interface DeleteTeamRequest {
    id: string;
}

export interface FetchTeamRequest {
    id: string;
}

export interface FetchTeamMembersRequest {
    id: string;
    page?: number;
    pageSize?: number;
}

export interface ListTeamRequest {
    page?: number;
}

export interface UnassignEmployeeRequest {
    id: string;
    unassignEmployeeCommand?: UnassignEmployeeCommand;
}

export interface UpdateTeamRequest {
    id: string;
    updateTeamCommand?: UpdateTeamCommand;
}

/**
 * 
 */
export class TeamApi extends runtime.BaseAPI {

    /**
     * Assign employee
     * Assign employeee
     */
    async assignEmployeeRaw(requestParameters: AssignEmployeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling assignEmployee.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/teams/{id}/assign-employees`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AssignEmployeeCommandToJSON(requestParameters.assignEmployeeCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Assign employee
     * Assign employeee
     */
    async assignEmployee(requestParameters: AssignEmployeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.assignEmployeeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a team
     * Create a team
     */
    async createTeamRaw(requestParameters: CreateTeamRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/teams`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateTeamCommandToJSON(requestParameters.createTeamCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Create a team
     * Create a team
     */
    async createTeam(requestParameters: CreateTeamRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.createTeamRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a team
     * Delete a team
     */
    async deleteTeamRaw(requestParameters: DeleteTeamRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteTeam.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/teams/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Delete a team
     * Delete a team
     */
    async deleteTeam(requestParameters: DeleteTeamRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.deleteTeamRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch team by its id
     * Fetch team
     */
    async fetchTeamRaw(requestParameters: FetchTeamRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindTeamQueryResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetchTeam.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/teams/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindTeamQueryResponseFromJSON(jsonValue));
    }

    /**
     * Fetch team by its id
     * Fetch team
     */
    async fetchTeam(requestParameters: FetchTeamRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindTeamQueryResponse> {
        const response = await this.fetchTeamRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch team members by id
     * Fetch team members
     */
    async fetchTeamMembersRaw(requestParameters: FetchTeamMembersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindTeamMembersQueryResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetchTeamMembers.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/teams/{id}/members`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindTeamMembersQueryResponseFromJSON(jsonValue));
    }

    /**
     * Fetch team members by id
     * Fetch team members
     */
    async fetchTeamMembers(requestParameters: FetchTeamMembersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindTeamMembersQueryResponse> {
        const response = await this.fetchTeamMembersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List teams
     * List teams
     */
    async listTeamRaw(requestParameters: ListTeamRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListTeamQueryResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/teams`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListTeamQueryResponseFromJSON(jsonValue));
    }

    /**
     * List teams
     * List teams
     */
    async listTeam(requestParameters: ListTeamRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListTeamQueryResponse> {
        const response = await this.listTeamRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Unassign employee
     * Unassign employeee
     */
    async unassignEmployeeRaw(requestParameters: UnassignEmployeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling unassignEmployee.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/teams/{id}/unassign-employees`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UnassignEmployeeCommandToJSON(requestParameters.unassignEmployeeCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Unassign employee
     * Unassign employeee
     */
    async unassignEmployee(requestParameters: UnassignEmployeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.unassignEmployeeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a team
     * Update a team
     */
    async updateTeamRaw(requestParameters: UpdateTeamRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateTeam.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/teams/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateTeamCommandToJSON(requestParameters.updateTeamCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Update a team
     * Update a team
     */
    async updateTeam(requestParameters: UpdateTeamRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.updateTeamRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
