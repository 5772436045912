/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmployeeBankInformation
 */
export interface EmployeeBankInformation {
    /**
     * Employee bank name
     * @type {string}
     * @memberof EmployeeBankInformation
     */
    bankName?: string | null;
    /**
     * Employee bank swift
     * @type {string}
     * @memberof EmployeeBankInformation
     */
    bankSwift?: string | null;
    /**
     * Employee bank number
     * @type {string}
     * @memberof EmployeeBankInformation
     */
    bankNumber?: string | null;
}

/**
 * Check if a given object implements the EmployeeBankInformation interface.
 */
export function instanceOfEmployeeBankInformation(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EmployeeBankInformationFromJSON(json: any): EmployeeBankInformation {
    return EmployeeBankInformationFromJSONTyped(json, false);
}

export function EmployeeBankInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeeBankInformation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bankName': !exists(json, 'bankName') ? undefined : json['bankName'],
        'bankSwift': !exists(json, 'bankSwift') ? undefined : json['bankSwift'],
        'bankNumber': !exists(json, 'bankNumber') ? undefined : json['bankNumber'],
    };
}

export function EmployeeBankInformationToJSON(value?: EmployeeBankInformation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bankName': value.bankName,
        'bankSwift': value.bankSwift,
        'bankNumber': value.bankNumber,
    };
}

