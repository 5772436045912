import FormSelectCountry from '@viterbit/web-app/shared/form/FormSelectCountry'
import FormSelectState from '@viterbit/web-app/shared/form/FormSelectState'
import splitComponents from '@viterbit/web-app/shared/utils/splitComponents'
import * as React from 'react'
import { useFormContext } from 'react-hook-form'



type FormGeoDataProps = {
    children?: React.ReactNode
    className?: string
}

const validateChildren = (countries: any, states: any) => {
    if (states && !countries) {
        throw new Error('You must provide a country when using a state')
    }

    if (countries.length !== 1) {
        throw new Error('You must have a country selector')
    }

    if (states.length !== 1) {
        throw new Error('You must have a state selector')
    }
}
    
const setCustomValue = (value: any) => value

const FormGeoData = (props: FormGeoDataProps) => {
    const { children } = props

    const { countries, states, others } = React.useMemo(() => splitComponents(children, [
        { name: 'countries', component: FormSelectCountry },
        { name: 'states', component: FormSelectState },
    ]), [children])

    const [countryChildren] = countries
    const [stateChildren] = states

    const form = useFormContext()
    const state = form.watch(stateChildren.props.name)
    const country = form.watch(countryChildren.props.name)

    const resetValues = React.useCallback((...types: any[]) => {
        if (types.includes('state') && stateChildren) {
            form.setValue(stateChildren.props.name, null)
        }
    }, [form, countryChildren, stateChildren])

    const onChangeCountry = React.useCallback(() => { resetValues('state', 'city')}, [resetValues])
    const onChangeState = React.useCallback(() => {resetValues('city')}, [resetValues])
    React.useEffect(() => { validateChildren(countries, states) }, [countries, states])

    return (
        <>
            {React.cloneElement(countryChildren, {
                rules: {
                    ...countryChildren.props.rules,
                    onChange: onChangeCountry,
                }
            })}
            {React.cloneElement(stateChildren, {
                key: `${country}`,
                query: { country },
                isDisabled: !country,
                defaultValue: state,
                rules: {
                    ...stateChildren.props.rules,
                    onChange: onChangeState,
                    setValueAs: setCustomValue
                }
            })}
            {others}
        </>
    )
}

export default FormGeoData
