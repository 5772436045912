
import { classValidatorResolver } from '@hookform/resolvers/class-validator'
import { UpdateEmployeePayload } from '@viterbit/web-app/dataAccess/dto'
import dateMapper from '@viterbit/web-app/dataAccess/mapper/date.mapper'
import { Employee } from '@viterbit/web-app/dataAccess/model/Employee.model'
import { employeeUpdate } from '@viterbit/web-app/dataAccess/service/employeeService'
import i18n from '@viterbit/web-app/i18n'
import Form from '@viterbit/web-app/shared/form/Form'
import FormDatePicker from '@viterbit/web-app/shared/form/FormDatePicker'
import FormSelectCountry from '@viterbit/web-app/shared/form/FormSelectCountry'
import SubmitButton from '@viterbit/web-app/shared/form/SubmitButton'
import useLocale from '@viterbit/web-app/shared/utils/useLocale'
import dayjs from 'dayjs'
import * as React from 'react'
import {  FormInput, FormSelect } from 'ui/src/components/Form'
import clsxm from 'ui/src/lib'

import { EmployeePersonalInformationValidator } from './EmployeePersonalInformationValidator'


export type EmployeeGeneralInformationFormPayload = Pick<
    UpdateEmployeePayload,
    | 'name'
    | 'surname'
    | 'pronouns'
    | 'gender'
    | 'nationality'
    | 'phone'
    | 'birthday'
>

type EmployeePersonalInformationFields = Omit<
    EmployeeGeneralInformationFormPayload,
    'birthday'
> & {
    birthday: Date | null
}

export type EmployeePersonalInformationFormProps = {
    employee: Employee
    className?: string
}

const resolver = classValidatorResolver(EmployeePersonalInformationValidator)

const getDefaultValues = ({
    name,
    surname,
    pronouns,
    gender,
    nationality,
    phone,
    birthday,
}: Employee): EmployeePersonalInformationFields => ({
    name,
    surname,
    pronouns,
    gender,
    nationality,
    phone,
    birthday: birthday ? dayjs(birthday).toDate(): null,
})

const genders = [
    {
        value: 'male',
        label: 'Male',
    },
    {
        value: 'female',
        label: 'Female',
    },
    {
        value: 'other',
        label: 'Other',
    },
]

const EmployeePersonalInformationForm = ({
    employee,
    className,
}: EmployeePersonalInformationFormProps) => {
    const locale = useLocale()

    const defaultValues = React.useMemo(() => getDefaultValues(employee), [employee])

    return (
        <Form<EmployeePersonalInformationFields>
            withTransition
            smallButtons
            mutationConfig={{
                mutationFn: (data) =>
                    employeeUpdate({
                        id: employee.id,
                        updateEmployeeCommand: {
                            ...data,
                            birthday: data.birthday ? dateMapper.toDate(data.birthday): null,
                        }
                    }), 
            }}
            formConfig={{
                mode: 'all',
                resolver,
                defaultValues,
            }}
            className={clsxm(
                'p-4 bg-white border border-gray-200 rounded-lg',
                className
            )}
        >
            {(form) => (
                <>
                    <FormInput
                        data-testid='general-info-name'
                        name='name'
                        label={i18n.t('personalArea:profile.generalInformation.form.name.label')}
                        asterisk
                        control={form.control}
                        placeholder={i18n.t('personalArea:profile.generalInformation.form.name.placeholder')}
                    />
                    <FormInput
                        name='surname'
                        label={i18n.t('personalArea:profile.generalInformation.form.surname.label')}
                        control={form.control}
                        placeholder={i18n.t('personalArea:profile.generalInformation.form.surname.placeholder')}
                    />
                    <FormInput
                        name='pronouns'
                        label={i18n.t('personalArea:profile.generalInformation.form.pronouns.label')}
                        control={form.control}
                        placeholder={i18n.t('personalArea:profile.generalInformation.form.pronouns.placeholder')}
                    />
                    <FormSelect
                        name='gender'
                        label={i18n.t('personalArea:profile.generalInformation.form.gender.label')}
                        control={form.control}
                        isClearable
                        placeholder={i18n.t('personalArea:profile.generalInformation.form.gender.placeholder')}
                        options={genders}
                        isSearchable
                    />
                    <FormDatePicker
                        label={i18n.t('personalArea:profile.generalInformation.form.birthday.label')}
                        name='birthday'
                        placeholder={i18n.t('personalArea:profile.generalInformation.form.birthday.placeholder')}
                        control={form.control}
                        clearable
                    />
                    <FormSelectCountry
                        locale={locale}
                        name='nationality'
                        control={form.control}
                        label={i18n.t('personalArea:profile.generalInformation.form.nationality.label')}
                        placeholder={i18n.t('personalArea:profile.generalInformation.form.nationality.placeholder')}
                        isClearable
                    />
                    <FormInput
                        data-testid='general-info-phone'
                        name='phone'
                        label={i18n.t('personalArea:profile.generalInformation.form.phone.label')}
                        control={form.control}
                        placeholder={i18n.t('personalArea:profile.generalInformation.form.phone.placeholder')}
                    />

                    <SubmitButton data-testid='employee-general-info-submit-button'>{i18n.t('common:buttons.save')}</SubmitButton>
                </>
            )}
        </Form>
    )
}

export default EmployeePersonalInformationForm
