import { useQuery } from '@tanstack/react-query'
import { LOCATION_QUERY_KEY } from '@viterbit/web-app/dataAccess/cacheKeys'
import { locationDetail } from '@viterbit/web-app/dataAccess/service/locationService'

type LocationDetailConfig = {
    query: {
        id: string
    }
    enabled?: boolean
    onSuccess?: () => void
    onError?: () => void
}

export const useLocationDetail = ({ query, enabled, onSuccess, onError }: LocationDetailConfig) =>
    useQuery({
        queryFn: () => locationDetail({
            id: query.id
        }), 
        queryKey: [LOCATION_QUERY_KEY, 'detail', query],
        enabled,
        onSuccess,
        onError,
    })

