import * as React from 'react'

import { useTabs } from './context'
import { TabsVariantType } from './Tabs'
import useCallbackRef from '../../hooks/useCallbackRef'
import { clsxm } from '../../lib/clsxm'

const classes = {
    tabNav: 'font-semibold flex items-center justify-center transition duration-200 ease-in-out cursor-pointer',
    tabNavDisabled: 'opacity-60 cursor-not-allowed',
    tabNavUnderline: 'py-3 px-5 border-b-2 border-transparent',
    tabNavPill: 'py-2 px-5 mr-1 rtl:ml-1 rounded-md',
    tabNavIcon: 'mr-1.5 rtl:ml-1.5 text-lg',
    tabNavActive: '',
}

type CustomColorType = {
    theme: string
    level: '100' | '200' | '300' | '400' | '500' | '600' | '700' | '800' | '900'
}

type ColorType = `${string}-${CustomColorType['level']}` | CustomColorType

export type TabNavProps = {
    value: string
    disabled?: boolean
    icon?: string | React.ReactNode
    color?: ColorType
} & React.HTMLAttributes<HTMLDivElement>

const getColorInfo = (
    defaultColor: ColorType = 'primary-600'
): Record<'color' | 'theme' | 'level', string> => {
    if (typeof defaultColor === 'string') {
        return {
            color: defaultColor,
            theme: defaultColor.split('-')[0],
            level: defaultColor.split('-')[1],
        }
    }
    return {
        color: `${defaultColor.theme}-${defaultColor.level}`,
        ...defaultColor,
    }
}

const TabNav = React.forwardRef<HTMLDivElement, TabNavProps>(
    (
        {
            value: valueProp,
            disabled,
            className,
            icon,
            children,
            color: defaultColor,
            ...rest
        },
        ref
    ) => {
        const { value, onValueChange = () => undefined, variant } = useTabs()
        const isSelected = valueProp === value

        const { color, theme } = getColorInfo(defaultColor)

        const onTabNavClick = useCallbackRef(() => {
            if (!isSelected && !disabled) {
                onValueChange(valueProp)
            }
        })

        const tabNavVariantClasses: Record<TabsVariantType, string> = {
            underline: classes.tabNavUnderline,
            pill: classes.tabNavPill,
        }

        const tabNavClass = clsxm(
            classes.tabNav,
            tabNavVariantClasses[variant],
            isSelected && classes.tabNavActive,
            isSelected && `text-${color} dark:text-${theme}-100`,
            isSelected && variant === 'underline' && `border-${color}`,
            isSelected &&
                variant === 'pill' &&
                `bg-${theme}-50 dark:bg-${color} dark:text-gray-100`,
            disabled && classes.tabNavDisabled,
            !disabled &&
                !isSelected &&
                `hover:text-${color} dark:hover:text-${theme}-100`,
            className
        )

        return (
            <div
                className={tabNavClass}
                role='tab'
                aria-selected={isSelected}
                ref={ref}
                onClick={onTabNavClick}
                {...rest}
            >
                {icon && <div className={classes.tabNavIcon}>{icon}</div>}
                {children}
            </div>
        )
    }
)

export default TabNav
