/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react'
import { GroupBase } from 'react-select'
import type {
    ComponentProps,
    UseAsyncPaginateParams,
} from 'react-select-async-paginate'
import { AsyncPaginate } from 'react-select-async-paginate'

import Select from './Select'
import { SelectAsyncProps } from './SelectAsync'
import FormSelectControl from '../Form/FormSelectControl'
import FormSelectOption from '../Form/FormSelectOption'

export type SelectAsyncPaginateProps<
    Option = unknown,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>,
    Additional = unknown
> = Omit<SelectAsyncProps<Option, IsMulti, Group>, 'loadOptions'> &
    UseAsyncPaginateParams<Option, Group, Additional> &
    ComponentProps<Option, Group, IsMulti>

const customProps = {
    'data-testid': 'form-select',
    components: { Option: FormSelectOption, Control: FormSelectControl },
    componentAs: AsyncPaginate
}

const SelectAsyncPaginate = <
    Option = unknown,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>,
    Additional = unknown
>(
        props: SelectAsyncPaginateProps<Option, IsMulti, Group, Additional>
    ) => <Select {...props} {...customProps as any} />

export default SelectAsyncPaginate
