
import { IsNotEmpty } from '@viterbit/web-app/shared/utils/ClassValidators'

import { TimeTrackingFields } from '../shared'

class TimeTrackingValidator implements TimeTrackingFields {
    id?: string

    @IsNotEmpty()
    startDate: Date
    
    @IsNotEmpty()
    endDate: Date
}

export default TimeTrackingValidator