
import { ErrorBoundary } from '@sentry/react'
import * as React from 'react'
import { useRecoilValue } from 'recoil'

import { selectPermissionState } from '../state/permissionState'
import { PermissionAction } from '../../dataAccess/model/PermissionAction.model'

export type IsGrantedComponentProps = {
    id: string
    action: PermissionAction
    children: JSX.Element
    loader?: JSX.Element
    fallback?: JSX.Element
}

const IsGrantedPermission = ({ action, ids, children, fallback }: { action: PermissionAction; ids: string[], children: JSX.Element, fallback?: JSX.Element }) => {
    const permission = useRecoilValue(selectPermissionState({
        [action]: ids
    }))

    const isGranted = ids.every((id) => permission[action]?.[id])

    if (!isGranted) {
        return <>{fallback}</>
    }
    
    return <>{children}</>
}

const IsGranted = ({
    children,
    action,
    fallback,
    loader,
    id,
}: IsGrantedComponentProps) => (
    <ErrorBoundary>
        <React.Suspense fallback={loader}>
            <IsGrantedPermission action={action} ids={[id]} fallback={fallback}>
                {children}
            </IsGrantedPermission>
        </React.Suspense>
    </ErrorBoundary>
)

export default IsGranted
