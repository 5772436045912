import { IconArrowNarrowLeft, IconArrowNarrowRight } from '@tabler/icons-react'
import * as React from 'react'

import Button from '../../Button'
import { clsxm } from '../../../lib/clsxm'

const classes = {
    'table-header-label':
        'cursor-pointer mx-0.5 bg-white select-none text-black dark:text-gray-100 text-lg font-semibold',
}

type HeaderProps = {
    className?: string
    hasNext?: boolean
    hasPrevious?: boolean
    onNext?: () => void
    onPrevious?: () => void
    onNextLevel?: () => void
    label: string
    nextLevelDisabled?: boolean
    nextLabel: string
    previousLabel: string
    preventLevelFocus?: boolean
    shouldRenderCenter?: boolean
    preventFocus?: boolean
    children?: React.ReactNode
}

const Header = ({
    hasNext,
    hasPrevious,
    onNext,
    onPrevious,
    onNextLevel,
    label,
    nextLevelDisabled,
    nextLabel,
    previousLabel,
    preventLevelFocus = false,
    shouldRenderCenter = false,
    preventFocus,
    children,
    className,
}: HeaderProps) => {
    const headerLabel = (
        <button
            type='button'
            className={classes['table-header-label']}
            disabled={nextLevelDisabled}
            onClick={onNextLevel}
            tabIndex={preventLevelFocus ? -1 : 0}
            onMouseDown={(event) => preventFocus && event.preventDefault()}
        >
            {label}
        </button>
    )

    const renderChildren = children ? children : headerLabel

    return (
        <div
            className={clsxm(
                'mb-2 flex items-center justify-between',
                className
            )}
        >
            {!shouldRenderCenter && renderChildren}
            <div
                className={clsxm(
                    shouldRenderCenter && 'w-full justify-between',
                    'flex items-center'
                )}
            >
                <Button
                    type='button'
                    variant='plain'
                    className={clsxm(
                        !hasPrevious &&
                            shouldRenderCenter &&
                            'cursor-default opacity-0'
                    )}
                    size='sm'
                    icon={<IconArrowNarrowLeft className='h-4' />}
                    disabled={!hasPrevious}
                    onClick={onPrevious}
                    aria-label={previousLabel}
                    onMouseDown={(e: React.MouseEvent<HTMLButtonElement>) =>
                        preventFocus && e.preventDefault()
                    }
                />
                {shouldRenderCenter && renderChildren}
                <Button
                    type='button'
                    variant='plain'
                    className={clsxm(
                        !hasNext &&
                            shouldRenderCenter &&
                            'cursor-default opacity-0'
                    )}
                    size='sm'
                    icon={<IconArrowNarrowRight className='h-4' />}
                    disabled={!hasNext}
                    onClick={onNext}
                    aria-label={nextLabel}
                    onMouseDown={(event) =>
                        preventFocus && event.preventDefault()
                    }
                />
            </div>
        </div>
    )
}

export default Header
