/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateLeadCommand,
  FindLeadQueryResponse,
  ForgotPasswordCommand,
  InvalidateTokenCommand,
  LogInCommand,
  LogInResponse,
  PermissionsQuery,
  PermissionsQueryResponse,
  RedeemForgotPasswordCommand,
  RefreshTokenCommand,
  ResourceCreated,
  SignUpCommand,
} from '../models/index';
import {
    CreateLeadCommandFromJSON,
    CreateLeadCommandToJSON,
    FindLeadQueryResponseFromJSON,
    FindLeadQueryResponseToJSON,
    ForgotPasswordCommandFromJSON,
    ForgotPasswordCommandToJSON,
    InvalidateTokenCommandFromJSON,
    InvalidateTokenCommandToJSON,
    LogInCommandFromJSON,
    LogInCommandToJSON,
    LogInResponseFromJSON,
    LogInResponseToJSON,
    PermissionsQueryFromJSON,
    PermissionsQueryToJSON,
    PermissionsQueryResponseFromJSON,
    PermissionsQueryResponseToJSON,
    RedeemForgotPasswordCommandFromJSON,
    RedeemForgotPasswordCommandToJSON,
    RefreshTokenCommandFromJSON,
    RefreshTokenCommandToJSON,
    ResourceCreatedFromJSON,
    ResourceCreatedToJSON,
    SignUpCommandFromJSON,
    SignUpCommandToJSON,
} from '../models/index';

export interface AccountLoginRequest {
    logInCommand?: LogInCommand;
}

export interface AccountSignUpRequest {
    signUpCommand?: SignUpCommand;
}

export interface CreateLeadRequest {
    createLeadCommand?: CreateLeadCommand;
}

export interface FetchLeadRequest {
    id: string;
}

export interface FetchPermissionsRequest {
    permissionsQuery?: PermissionsQuery;
}

export interface ForgotPasswordRequest {
    forgotPasswordCommand?: ForgotPasswordCommand;
}

export interface InvalidateTokenRequest {
    invalidateTokenCommand?: InvalidateTokenCommand;
}

export interface RedeemForgotPasswordRequest {
    redeemForgotPasswordCommand?: RedeemForgotPasswordCommand;
}

export interface RefreshTokenRequest {
    refreshTokenCommand?: RefreshTokenCommand;
}

/**
 * 
 */
export class AuthApi extends runtime.BaseAPI {

    /**
     * Login for accounts
     * Login for accounts
     */
    async accountLoginRaw(requestParameters: AccountLoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LogInResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/auth/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LogInCommandToJSON(requestParameters.logInCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LogInResponseFromJSON(jsonValue));
    }

    /**
     * Login for accounts
     * Login for accounts
     */
    async accountLogin(requestParameters: AccountLoginRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LogInResponse> {
        const response = await this.accountLoginRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Sign up for new accounts
     * Sign up for new accounts
     */
    async accountSignUpRaw(requestParameters: AccountSignUpRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/auth/signup`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SignUpCommandToJSON(requestParameters.signUpCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Sign up for new accounts
     * Sign up for new accounts
     */
    async accountSignUp(requestParameters: AccountSignUpRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.accountSignUpRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a lead
     * Create a lead
     */
    async createLeadRaw(requestParameters: CreateLeadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/auth/lead`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateLeadCommandToJSON(requestParameters.createLeadCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Create a lead
     * Create a lead
     */
    async createLead(requestParameters: CreateLeadRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.createLeadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch lead by its id
     * Fetch lead
     */
    async fetchLeadRaw(requestParameters: FetchLeadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindLeadQueryResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetchLead.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/auth/lead/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindLeadQueryResponseFromJSON(jsonValue));
    }

    /**
     * Fetch lead by its id
     * Fetch lead
     */
    async fetchLead(requestParameters: FetchLeadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindLeadQueryResponse> {
        const response = await this.fetchLeadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch permissions
     * Fetch permissions
     */
    async fetchPermissionsRaw(requestParameters: FetchPermissionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PermissionsQueryResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/auth/permissions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PermissionsQueryToJSON(requestParameters.permissionsQuery),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PermissionsQueryResponseFromJSON(jsonValue));
    }

    /**
     * Fetch permissions
     * Fetch permissions
     */
    async fetchPermissions(requestParameters: FetchPermissionsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PermissionsQueryResponse> {
        const response = await this.fetchPermissionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A user has lost his password
     * A user has lost his password
     */
    async forgotPasswordRaw(requestParameters: ForgotPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/auth/forgot-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ForgotPasswordCommandToJSON(requestParameters.forgotPasswordCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * A user has lost his password
     * A user has lost his password
     */
    async forgotPassword(requestParameters: ForgotPasswordRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.forgotPasswordRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * invalidate token
     * invalidate token
     */
    async invalidateTokenRaw(requestParameters: InvalidateTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/auth/invalidate-token`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InvalidateTokenCommandToJSON(requestParameters.invalidateTokenCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * invalidate token
     * invalidate token
     */
    async invalidateToken(requestParameters: InvalidateTokenRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.invalidateTokenRaw(requestParameters, initOverrides);
    }

    /**
     * A user has lost his password
     * A user has lost his password
     */
    async redeemForgotPasswordRaw(requestParameters: RedeemForgotPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/auth/redeem-forgot-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RedeemForgotPasswordCommandToJSON(requestParameters.redeemForgotPasswordCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * A user has lost his password
     * A user has lost his password
     */
    async redeemForgotPassword(requestParameters: RedeemForgotPasswordRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.redeemForgotPasswordRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * refresh token
     * refresh token
     */
    async refreshTokenRaw(requestParameters: RefreshTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LogInResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/auth/refresh-token`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RefreshTokenCommandToJSON(requestParameters.refreshTokenCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LogInResponseFromJSON(jsonValue));
    }

    /**
     * refresh token
     * refresh token
     */
    async refreshToken(requestParameters: RefreshTokenRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LogInResponse> {
        const response = await this.refreshTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
