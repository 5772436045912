/* eslint-disable react/no-array-index-key */
import * as React from 'react'

import Skeleton from '../Skeleton'
import clsxm from '../../lib'

type ButtonSkeletonProps = {
    block?: boolean
    count?: number
}

const ButtonSkeleton = (props: ButtonSkeletonProps) => {
    const { block, count = 1 } = props

    return (
        <div className='flex justify-end gap-x-4'>
            {
                Array(count).fill(0).map((_, index) => (
                    <Skeleton key={index} className={clsxm('h-10', block ? '[&>*]:flex-1' : 'w-[140px]')} />
                ))
            }
        </div>

    )
}

export default ButtonSkeleton
