
import i18n from '@viterbit/web-app/i18n'
import SettingsLayout from '@viterbit/web-app/shared/layout/SettingsLayout'
import { Outlet } from 'react-router-dom'

import WorkScheduledCreate from './create/WorkScheduledCreate'
import WorkScheduledListView from './list/WorkScheduledListView'
import { useWorkScheduled } from './useWorkScheduled'

const WorkScheduledLayout = () => {
    const { data } = useWorkScheduled()
    const title = data?.name || i18n.t('navigation:navbar.settings.workSchedule')

    return (
        <SettingsLayout
            leftTitle={i18n.t('settingWorkSchedule:list.header')}
            leftActions={<WorkScheduledCreate />}
            title={title}
            left={<WorkScheduledListView />}
        >
            <Outlet />
        </SettingsLayout>
    )
}

export default WorkScheduledLayout
