/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useIntegrationOptionListPaginated } from '@viterbit/web-app/dataAccess/useCase/integration/useIntegrationOptionListPaginated'
import * as React from 'react'
import ListSkeleton from 'ui/src/components/List/ListSkeleton'

import AccountSettingsIntegrationsGroup from './AccountSettingsIntegrationsGroup'

const AccountSettingsIntegrations = () => {
    const {data, isLoading} = useIntegrationOptionListPaginated()

    return (
        <div className='flex flex-col divide-y gap-y-6'>
            {
                isLoading
                    ? (<ListSkeleton count={6} />)
                    : data?.data.map(x => (
                        <AccountSettingsIntegrationsGroup
                            group={x}
                            key={x.kind}
                        />
                    ))
            }
        </div>
    )
}

export default AccountSettingsIntegrations
