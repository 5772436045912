/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useComplaintPrivateDetail } from '@viterbit/web-app/dataAccess/useCase/complaint/useComplaintPrivateDetail'
import { useParams } from 'react-router-dom'

export const useComplaintDetail = () => {
    const { complaintId } = useParams<'complaintId'>()
    const result = useComplaintPrivateDetail({
        query: { id: complaintId! },
        enabled: !!complaintId,
    })

    if (!complaintId) {
        throw new Error('complaintId is required')
    }

    if (result.failureReason?.response?.status === 400) {
        throw new Error(`complaint ${complaintId} not found`)
    }

    return result
}