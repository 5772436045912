import { classValidatorResolver } from '@hookform/resolvers/class-validator'
import { leaveReasonCreate } from '@viterbit/web-app/dataAccess/service/leaveReasonsService'
import i18n from '@viterbit/web-app/i18n'
import BasicCreateFormDialog from '@viterbit/web-app/shared/form/BasicCreateFormDialog'
import * as React from 'react'

import CreateLeaveReasonsValidator from './CreateLeaveReasonsValidator'

type CreateLeaveReasonsViewProps = {
    children?: React.ReactNode
    className?: string
    leavePolicyId: string,
    onCreatedLeaveReason: (leaveReasonId: string) => void,
    isOpen: boolean
    onClose: () =>  void
}

const resolver = classValidatorResolver(CreateLeaveReasonsValidator)

const CreateLeaveReasonsView = ({
    leavePolicyId,
    onCreatedLeaveReason,
    isOpen,
    onClose,

}: CreateLeaveReasonsViewProps) => (
    <BasicCreateFormDialog
        isOpen={isOpen}
        title={i18n.t('settingLeaves:addReason')}
        onClose={onClose}
        formConfig={{
            resolver,
            mode: 'all',
            defaultValues: {
                leavePolicyId,
                name: '',
            },
        }}
        label={i18n.t('settingLeaves:reason')}
        placeholder={i18n.t('settingLeaves:enterReasonName')}
        mutationConfig={{
            mutationKey: ['CreateLeaveReasons'],
            onSuccess: ({ id }) => {
                onCreatedLeaveReason(id)
                onClose()
            },
            mutationFn: (data) => 
                leaveReasonCreate({
                    createLeaveReasonCommand: data
                })
            ,
        }}
    />
)

export default CreateLeaveReasonsView
