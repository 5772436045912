import { useQuery } from '@tanstack/react-query'

import { EMPLOYEE_QUERY_KEY } from '../../cacheKeys'
import { employeeList, employeeListWithoutPagination } from '../../service/employeeService'

export type useEmployeeListWithoutPaginationConfig = {
    query: Parameters<typeof employeeList>[0]
    enabled?: boolean
}

export const useEmployeeListWithoutPagination = (config?: useEmployeeListWithoutPaginationConfig) => {
    const { query = { page: 1 }, enabled } = config || {}

    return useQuery({
        queryKey: [EMPLOYEE_QUERY_KEY, 'list-paginated', query],
        queryFn: () => employeeListWithoutPagination(query),
        enabled,
    })
}

