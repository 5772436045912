import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react'
import * as React from 'react'
import { Link, LinkProps } from 'react-router-dom'
import clsxm from 'ui/src/lib'

export type YearSelectorProps = {
    children?: React.ReactNode | string | number | null
    nextLinkProps: LinkProps & { onClick?: (e: any) => void, disabled?: boolean }
    prevLinkProps: LinkProps & { onClick?: (e: any) => void, disabled?: boolean }
    className?: string
}

const styles = {
    root: 'inline-flex rounded-md isolate',
    link: {
        root: 'relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 hover:bg-gray-50 focus:z-10 focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500',
        disabled: 'opacity-50 cursor-not-allowed pointer-events-none',
        right: 'rounded-r-md',
        left: 'rounded-l-md',
    },
    content: 'relative inline-flex items-center border-r-0 px-4 py-2 -ml-px text-sm font-medium text-gray-700 bg-white border border-gray-300 hover:bg-gray-50 focus:z-10 focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500',
    icon: 'w-5 h-5',
}

const YearSelector = ({
    children,
    nextLinkProps,
    prevLinkProps,
    className = '',
}: YearSelectorProps) => {
    const { disabled: nextDisabled } = nextLinkProps
    const { disabled: prevDisabled } = prevLinkProps
    
    return (
        <span className={clsxm(styles.root, className)}>
            <Link
                {...prevLinkProps}
                className={clsxm(styles.link.root, styles.link.left, prevDisabled && styles.link.disabled, className)}
            >
                <IconChevronLeft className='w-5 h-5' />
            </Link>
            <span className={styles.content}>
                {children}
            </span>
            <Link
                {...nextLinkProps}
                className={clsxm(styles.link.root, styles.link.right, nextDisabled && styles.link.disabled, className)}
            >
                <IconChevronRight className='w-5 h-5 ' />
            </Link>
        </span>
    )
}

export default YearSelector
