import useCompany from '@viterbit/web-app/feature/settings/companies/detail/useCompany'
import FormSkeleton from 'ui/src/components/Form/FormSkeleton'

import CompanyForm from './CompanyForm'
import DeleteCompanyAction from '../delete/DeleteCompanyAction'

const CompanyView = () => {
    const { data: company, isLoading } = useCompany()

    if (isLoading || !company) {
        return <FormSkeleton className='mt-[100px]'/>
    }

    return (
        <>
            <CompanyForm key={company.id} company={company!} />
            <DeleteCompanyAction company={company} />
        </>
    )
}

export default CompanyView
