/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react'
import {
    Column,
    usePagination,
    useRowSelect,
    useSortBy,
    useTable,
} from 'react-table'

import Loading from './Loading'
import Checkbox from '../Checkbox'
import Pagination from '../Pagination'
import { Select } from '../Select'
import { SkeletonProps } from '../Skeleton/Skeleton'
import { Table, TableRowSkeleton } from '../Table'
import { clsxm } from '../../lib/clsxm'

const { Sorter, TBody, THead, Td, Th, Tr } = Table

type IndeterminateCheckboxProps = {
    indeterminate: boolean
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
    onCheckBoxChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
    onIndeterminateCheckBoxChange?: (
        e: React.ChangeEvent<HTMLInputElement>
    ) => void
}

const IndeterminateCheckbox = React.forwardRef<
    HTMLDivElement,
    IndeterminateCheckboxProps
>((props, ref) => {
    const {
        indeterminate,
        onChange,
        onCheckBoxChange,
        onIndeterminateCheckBoxChange,
        ...rest
    } = props

    const defaultRef = React.useRef<any>({})
    const resolvedRef: any = ref || defaultRef

    React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange?.(e)
        onCheckBoxChange?.(e)
        onIndeterminateCheckBoxChange?.(e)
    }

    return (
        <Checkbox
            className='mb-0'
            ref={resolvedRef as any}
            onChange={handleChange}
            {...rest}
        />
    )
})

export type DataTableColumn<T extends object> = Column<T> & {
    isSortedDesc?: boolean
    toggleSortBy?: (value: boolean) => void
    clearSortBy?: any
    sortable?: boolean
}

type OnSortType = (
    arg1: { order: 'asc' | 'desc'; key: string },
    arg2: { id: string; clearSortBy: any }
) => void

type DataTableProps<T extends object> = {
    columns: Array<DataTableColumn<T>>
    data: Array<T>
    loading?: boolean
    onCheckBoxChange?: (checked: boolean, row: T) => void
    onIndeterminateCheckBoxChange?: (checked: boolean, rows: T[]) => void
    onPaginationChange?: (page: number) => void
    onSelectChange?: (index: number) => void
    onSort?: OnSortType
    pageSizes?: Array<number>
    selectable?: boolean
    skeletonAvatarColumns?: Array<number>
    skeletonAvatarProps?: SkeletonProps
    pagingData?: {
        total: number
        pageIndex: number
        pageSize: number
        sort?: {
            order: 'asc' | 'desc'
            key: keyof T
        }
    }
    getToggleAllRowsSelectedProps?: () => any
    getToggleRowSelectedProps?: () => any
    onRowClick?: (row: T) => void
    paginationVariant?: 'default' | 'tabs'
    classes?: {
        tableContainer?: string
        table?: string
        thead?: {
            default?: string
            tr?: string
            th?: string
        }
        tbody?: {
            default?: string
            tr?: string
            td?: string
        }
        pagination?: {
            container?: string
            component?: string
        }
    }
    t?: (key: string) => string
}

const DataTable = <T extends object>(props: DataTableProps<T>) => {
    const {
        skeletonAvatarColumns,
        onCheckBoxChange,
        onIndeterminateCheckBoxChange,
        onPaginationChange,
        onSelectChange,
        onSort,
        skeletonAvatarProps,
        pageSizes = [10],
        pagingData = {
            total: 0,
            pageIndex: 1,
            pageSize: 10,
        },
        data = [],
        columns: columnsProp = [],
        selectable = false,
        loading = false,
        onRowClick,
        classes,
        paginationVariant,
        t,
    } = props

    const { pageSize, pageIndex, total } = pagingData

    const pageSizeOption = React.useMemo(
        () =>
            pageSizes.map((number) => ({
                value: number,
                label: `${number} / ${t?.('table.page') || 'page'}`,
            })),
        [pageSizes]
    )

    const handleCheckBoxChange = (checked: boolean, row: T) => {
        if (!loading) {
            onCheckBoxChange?.(checked, row)
        }
    }

    const handleIndeterminateCheckBoxChange = (checked: boolean, rows: T[]) => {
        if (!loading) {
            onIndeterminateCheckBoxChange?.(checked, rows)
        }
    }

    // const data = React.useMemo(() => dataProp, [dataProp])
    const columns = React.useMemo(() => columnsProp, [columnsProp])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
    }: any = useTable<T>(
        {
            columns,
            data,
            manualPagination: true,
            manualSortBy: true,
            autoResetSelectedRows: false,
            autoResetSelectedCell: false,
            autoResetSelectedColumn: false,
        } as any,
        useSortBy,
        usePagination,
        useRowSelect,
        (hooks) => {
            if (selectable) {
                hooks.visibleColumns.push((columns) => [
                    {
                        id: 'selection',
                        Header: (props: any) => (
                            <div>
                                <IndeterminateCheckbox
                                    {...props.getToggleAllRowsSelectedProps()}
                                    onIndeterminateCheckBoxChange={(e) =>
                                        handleIndeterminateCheckBoxChange(
                                            e.target.checked,
                                            props.rows
                                        )
                                    }
                                />
                            </div>
                        ),
                        Cell: ({ row }: any) => (
                            <div>
                                <IndeterminateCheckbox
                                    {...row.getToggleRowSelectedProps()}
                                    onCheckBoxChange={(e) =>
                                        handleCheckBoxChange(
                                            e.target.checked,
                                            row.original
                                        )
                                    }
                                />
                            </div>
                        ),
                        sortable: false,
                    },
                    ...columns,
                ])
            }
        }
    )

    const handlePaginationChange = (page: number) => {
        if (!loading) {
            onPaginationChange?.(page)
        }
    }

    const handleSelectChange = (value: number | string) => {
        if (!loading) {
            onSelectChange?.(Number(value))
        }
    }

    const handleSort = (column: DataTableColumn<T>) => {
        if (!loading) {
            const { id = '-', isSortedDesc, toggleSortBy, clearSortBy } = column
            const sortOrder = isSortedDesc ? 'desc' : 'asc'
            toggleSortBy?.(!isSortedDesc)
            onSort?.({ order: sortOrder, key: id }, { id, clearSortBy })
        }
    }

    return (
        <>
            <Loading
                isLoading={loading}
                type='cover'
                className={classes?.tableContainer}
            >
                <Table {...getTableProps()} className={classes?.table}>
                    <THead className={classes?.thead?.default}>
                        {
                            headerGroups.map((headerGroup: any) => {
                                const {key, ...restProps} = headerGroup.getHeaderGroupProps()
                                return (
                                    <Tr
                                        key={key}
                                        {...restProps}
                                        className={classes?.thead?.tr}
                                    >
                                        {headerGroup.headers.map((column: any) => (
                                            <Th
                                                {...column.getHeaderProps()}
                                                key={column.id}
                                                className={classes?.thead?.th}
                                            >
                                                {column.render('Header') &&
                                                        (column.sortable ? (
                                                            <div
                                                                className='cursor-pointer'
                                                                onClick={() =>
                                                                    handleSort(column)
                                                                }
                                                            >
                                                                {column.render('Header')}
                                                                <span>
                                                                    <Sorter
                                                                        sort={
                                                                            column.isSortedDesc
                                                                        }
                                                                    />
                                                                </span>
                                                            </div>
                                                        ) : (
                                                            <div>{column.render('Header')}</div>
                                                        ))}
                                            </Th>
                                        ))}
                                    </Tr>
                                )
                            })}
                    </THead>
                    {loading && data.length === 0 ? (
                        <TableRowSkeleton
                            columns={columns.length}
                            rows={pagingData.pageSize}
                            avatarInColumns={skeletonAvatarColumns}
                            avatarProps={skeletonAvatarProps}
                        />
                    ) : (
                        <TBody {...getTableBodyProps()} className={classes?.tbody?.default}>
                            {page.map((row: any) => {
                                prepareRow(row)
                                return (
                                    <Tr
                                        {...row.getRowProps()}
                                        key={row.original.id}
                                        className={classes?.tbody?.tr}
                                    >
                                        {row.cells.map(
                                            (cell: any, index: number) => (
                                                <Td
                                                    {...cell.getCellProps()}
                                                    key={cell.column.id}
                                                    onClick={
                                                        onRowClick
                                                            ? (selectable &&
                                                                  index > 0) ||
                                                              !selectable
                                                                ? () =>
                                                                    onRowClick?.(
                                                                        row.original
                                                                    )
                                                                : undefined
                                                            : undefined
                                                    }
                                                    className={classes?.tbody?.td}
                                                >
                                                    {cell.render('Cell')}
                                                </Td>
                                            )
                                        )}
                                    </Tr>
                                )
                            })}
                        </TBody>
                    )}
                </Table>
            </Loading>
            {
                !loading && (
                    <div
                        className={clsxm(
                            'flex items-center justify-between mt-4 gap-x-2',
                            classes?.pagination?.container
                        )}
                    >
                        <Pagination
                            pageSize={pageSize}
                            currentPage={pageIndex}
                            total={total}
                            onChange={handlePaginationChange}
                            className={classes?.pagination?.component}
                            pagerVariant={paginationVariant}
                            t={t}
                        />
                        {
                            onSelectChange && (
                                <div className='min-w-[130px] flex-none'>
                                    <Select
                                        size='sm'
                                        menuPlacement='top'
                                        isSearchable={false}
                                        value={pageSizeOption.filter(
                                            (option) => option.value === pageSize
                                        )}
                                        options={pageSizeOption}
                                        onChange={(option) =>
                                            handleSelectChange(option?.value ?? '')
                                        }
                                    />
                                </div>
                            )
                        }
                    </div>
                )
            }
        </>
    )
}

export default DataTable
