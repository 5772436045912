/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResendInvitationCommand
 */
export interface ResendInvitationCommand {
    /**
     * 
     * @type {string}
     * @memberof ResendInvitationCommand
     */
    accountId: string;
}

/**
 * Check if a given object implements the ResendInvitationCommand interface.
 */
export function instanceOfResendInvitationCommand(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "accountId" in value;

    return isInstance;
}

export function ResendInvitationCommandFromJSON(json: any): ResendInvitationCommand {
    return ResendInvitationCommandFromJSONTyped(json, false);
}

export function ResendInvitationCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResendInvitationCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accountId': json['accountId'],
    };
}

export function ResendInvitationCommandToJSON(value?: ResendInvitationCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accountId': value.accountId,
    };
}

