
import { classValidatorResolver } from '@hookform/resolvers/class-validator'
import { Employee } from '@viterbit/web-app/dataAccess/model/Employee.model'
import { employeeUpdate } from '@viterbit/web-app/dataAccess/service/employeeService'
import i18n from '@viterbit/web-app/i18n'
import * as React from 'react'

// limit the size of the file to 400kb
const MAX_FILE_SIZE = 400 * 1024

export type AvatarFormField = {
    avatarFileId: string
}

import { Button } from '@mantine/core'
import FormUploadDialog from '@viterbit/web-app/shared/form/FormUploadDialog'

import EmployeePersonalAvatarValidator from './EmployeePersonalAvatarValidator'


type EmployeePersonalAvatarFormProps = {
    employee: Employee
    children?: React.ReactNode
}

const resolver = classValidatorResolver(EmployeePersonalAvatarValidator)

const EmployeePersonalAvatarForm = ({ employee, children }: EmployeePersonalAvatarFormProps) => {
    const [editMode, setEditMode] = React.useState(false)

    return (
        <>
            <FormUploadDialog
                open={editMode}
                onClose={() => setEditMode(false)}
                title={i18n.t('personalArea:profile.avatarSection.form.avatarFileId.label')}
                uploadProps={{
                    employeeId: employee!.id!,
                    kind: 'avatar',
                    draggable: true,
                    multiple: false,
                    maxUploadSize: MAX_FILE_SIZE,
                    accept: 'image/png, image/jpeg',
                }}
                onConfirm={async (filesId) => {
                    await employeeUpdate({
                        id: employee.id,
                        updateEmployeeCommand: {
                            avatarFileId: filesId as string,
                        }
                    })
                }}
            />

            <Button
                onClick={() => setEditMode(true)}
                variant='subtle'
                color='primary.5'
                compact
            >
                {i18n.t('common:buttons.edit')}
            </Button>

            {children}
        </>
    )
}

export default EmployeePersonalAvatarForm
