/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Subscription
 */
export interface Subscription {
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    customerId: string;
    /**
     * 
     * @type {number}
     * @memberof Subscription
     */
    slots: number;
    /**
     * 
     * @type {number}
     * @memberof Subscription
     */
    unitPrice: number;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    currency: string;
    /**
     * 
     * @type {number}
     * @memberof Subscription
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    trialEnd?: string;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    nextBillingAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    status: string;
    /**
     * 
     * @type {boolean}
     * @memberof Subscription
     */
    validPaymentMethod: boolean;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    billingPeriod: string;
    /**
     * 
     * @type {number}
     * @memberof Subscription
     */
    billingPeriodUnit: number;
    /**
     * 
     * @type {number}
     * @memberof Subscription
     */
    discount: number;
}

/**
 * Check if a given object implements the Subscription interface.
 */
export function instanceOfSubscription(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "customerId" in value;
    isInstance = isInstance && "slots" in value;
    isInstance = isInstance && "unitPrice" in value;
    isInstance = isInstance && "currency" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "validPaymentMethod" in value;
    isInstance = isInstance && "billingPeriod" in value;
    isInstance = isInstance && "billingPeriodUnit" in value;
    isInstance = isInstance && "discount" in value;

    return isInstance;
}

export function SubscriptionFromJSON(json: any): Subscription {
    return SubscriptionFromJSONTyped(json, false);
}

export function SubscriptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Subscription {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'customerId': json['customerId'],
        'slots': json['slots'],
        'unitPrice': json['unitPrice'],
        'currency': json['currency'],
        'amount': json['amount'],
        'trialEnd': !exists(json, 'trialEnd') ? undefined : json['trialEnd'],
        'nextBillingAt': !exists(json, 'nextBillingAt') ? undefined : json['nextBillingAt'],
        'status': json['status'],
        'validPaymentMethod': json['validPaymentMethod'],
        'billingPeriod': json['billingPeriod'],
        'billingPeriodUnit': json['billingPeriodUnit'],
        'discount': json['discount'],
    };
}

export function SubscriptionToJSON(value?: Subscription | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'customerId': value.customerId,
        'slots': value.slots,
        'unitPrice': value.unitPrice,
        'currency': value.currency,
        'amount': value.amount,
        'trialEnd': value.trialEnd,
        'nextBillingAt': value.nextBillingAt,
        'status': value.status,
        'validPaymentMethod': value.validPaymentMethod,
        'billingPeriod': value.billingPeriod,
        'billingPeriodUnit': value.billingPeriodUnit,
        'discount': value.discount,
    };
}

