import { useQuery } from '@tanstack/react-query'

import { ORGANIZATION_QUERY_KEY } from '../../cacheKeys'
import { organizationDetail } from '../../service/organizationService'

type OrganizationDetailConfig = {
    query?: Parameters<typeof organizationDetail>[0]
    enabled?: boolean
}

export const useOrganizationDetail = ({ query, enabled }: OrganizationDetailConfig = {}) =>
    useQuery({
        queryFn: () => organizationDetail(query), 
        queryKey: [ORGANIZATION_QUERY_KEY, 'detail', query],
        enabled
    })

