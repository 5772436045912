/* eslint-disable @typescript-eslint/no-explicit-any */
import { IconChevronRight } from '@tabler/icons-react'
import { usePendingFileList } from '@viterbit/web-app/dataAccess/useCase/pendingFile/usePendingFileList'
import i18n from '@viterbit/web-app/i18n'
import SegmentNavLink from '@viterbit/web-app/shared/navigation/SegmentNavLink'
import clsx from 'clsx'
import * as React from 'react'
import ListItem from 'ui/src/components/List/ListItem'
import ListItemText from 'ui/src/components/List/ListItemText'
import Skeleton from 'ui/src/components/Skeleton'

import { PendingDocumentProps } from '../../BulkUploadShared'

type DocumentTypeListItemProps = {
    Icon: React.ElementType
    className: string
    path: string
} & PendingDocumentProps

const styles = {
    root: 'hover:bg-grey-50',
    iconWrapper: 'flex items-center justify-center rounded-md aspect-square w-14'
}

const DocumentBulkUploadNavigationItem = (props: DocumentTypeListItemProps) => {
    const { Icon, className, path, type } = props

    const { isLoading, data } = usePendingFileList({
        query: {
            type: type as any,
        }
    })

    const secondary = isLoading ? <Skeleton className='w-10 h-3 my-1'/> : `${data?.meta.total} ${i18n.t('personalArea:documents.files')}`

    return (
        <SegmentNavLink exact path={path} className={styles.root} activeClassName="bg-grey-50" >
            <ListItem >
                <div className={clsx(styles.iconWrapper, className)}>
                    <Icon stroke={1} color="white" width={30} height={30} />
                </div>                

                <ListItemText
                    primary={i18n.t(`personalArea:documents.type.${type}`)}
                    secondary={secondary}
                />

                <IconChevronRight className='text-dark opacity-60'/>
            </ListItem>
        </SegmentNavLink>
    )
}

export default DocumentBulkUploadNavigationItem
