import { IntegrationType } from '@viterbit/web-app/dataAccess/model/Integration.model'
import i18n from '@viterbit/web-app/i18n'
import MultiColumnLayout from '@viterbit/web-app/shared/layout/MultiColumnLayout'
import Subtitle from '@viterbit/web-app/shared/typography/Subtitle'
import Text from '@viterbit/web-app/shared/typography/Text'
import Title from '@viterbit/web-app/shared/typography/Title'
import clsxm from 'ui/src/lib'

import { integrationTypeIconMapper } from '../../helpers'


type Image ={
    src: string
    alt: string
    position ?: 'left' | 'right'
  }
  
  type Props = {
    type: IntegrationType
    title ?: string
    subtitle ?: string
    image : Image
    children: React.ReactNode
    className?: string
  } & React.HTMLAttributes<HTMLDivElement>

const AccountSettingsIntegrationFeedbackMessage = ({
    image,
    type,
    title,
    subtitle,
    children,
    className,
    ...rest
}: Props) => {
    const icon = integrationTypeIconMapper[type]
    return (
        <MultiColumnLayout
            left={(
                <div className='flex items-center justify-center w-full h-full p-8'>
                    <img src={image.src} alt={image.alt} width={200} height={200} />
                </div>
            )}
            {...rest}
        >
            <div className={clsxm('flex px-12 pb-6 items-left justify-center flex-col gap-y-2 w-full h-full',className)}>
                <div className='flex gap-2'>
                    <img src={icon} alt={type} className="self-center h-5 aspect-square" />
                    <Subtitle>{i18n.t(`integrations:integration.${type}.title`)}</Subtitle>
                </div>
                <Title>{title}</Title>
                <Text className='mb-4'>{subtitle}</Text>
                {children}
            </div>
        </MultiColumnLayout>
    )

}

export default AccountSettingsIntegrationFeedbackMessage