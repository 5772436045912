/* eslint-disable @typescript-eslint/no-explicit-any */

import { classValidatorResolver } from '@hookform/resolvers/class-validator'
import { IconInfoCircle } from '@tabler/icons-react'
import { UpdateEmployeePayload } from '@viterbit/web-app/dataAccess/dto'
import dateMapper from '@viterbit/web-app/dataAccess/mapper/date.mapper'
import { Employee, EmployeeContractDayType, EmployeeContractModality } from '@viterbit/web-app/dataAccess/model/Employee.model'
import { WEEK_DAYS } from '@viterbit/web-app/dataAccess/model/Week.model'
import { employeeUpdate } from '@viterbit/web-app/dataAccess/service/employeeService'
import i18n from '@viterbit/web-app/i18n'
import SimpleCard from '@viterbit/web-app/shared/display/SimpleCard'
import Form from '@viterbit/web-app/shared/form/Form'
import FormDatePicker from '@viterbit/web-app/shared/form/FormDatePicker'
import SubmitButton from '@viterbit/web-app/shared/form/SubmitButton'
import Small from '@viterbit/web-app/shared/typography/Small'
import dayjs from 'dayjs'
import { CheckboxButtonInput, CheckboxButtonInputGroup, FormInput, FormSelect } from 'ui/src/components/Form'

import { EmployeeContractInformationValidator as EmployeeContractInformationValidator } from './EmployeeContractInformationValidator'

export type EmployeeContractFormPayload = Pick<
    UpdateEmployeePayload,
    'salary' | 'startDate' | 'endDate'
>

const contractModalityOptions = EmployeeContractModality.map((contractModality) => ({
    value: contractModality,
    label: i18n.t(`employees:employee.form.contractModality.options.${contractModality}`),
}))

const contractDayTypeOptions = EmployeeContractDayType.map((contractDayType) => ({
    value: contractDayType,
    label: i18n.t(`employees:employee.form.contractDayType.options.${contractDayType}`),
}))

const EmployeeContractFieldNames = ['salary', 'startDate', 'endDate', 'contractModality', 'contractDayType', 'contractDays'] as const

export type EmployeeContractFormFields = Pick<Employee, typeof EmployeeContractFieldNames[number]>


export type EmployeeContractInformationFormProps = {
    employee: Employee
}

const resolver = classValidatorResolver(EmployeeContractInformationValidator)

const EmployeeContractInformationForm = (props: EmployeeContractInformationFormProps) => {
    const { employee } = props

    const WEEK_DAYS_LABELS = WEEK_DAYS.map((day) => i18n.t(`weekDay.${day}`))

    return (
        <SimpleCard padding>
            <Form<EmployeeContractFormFields>
                smallButtons
                formConfig={{
                    resolver,
                    mode: 'all',
                    defaultValues: {
                        salary: employee.salary ?? undefined,
                        startDate: dayjs(employee.startDate).toDate() as any,
                        endDate: employee.endDate ? dayjs(employee.endDate).toDate() as any : null,
                        contractModality: employee.contractModality,
                        contractDayType: employee.contractDayType,
                        contractDays: employee.contractDays,
                    },
                }}
                mutationConfig={{
                    mutationFn: ({ salary, startDate, endDate, contractModality, contractDayType, contractDays }) =>
                        employeeUpdate({
                            id: employee.id,
                            updateEmployeeCommand: {
                                salary: salary ? Number(salary) : null,
                                startDate: dateMapper.toDate(startDate),
                                endDate: endDate ? dateMapper.toDate(endDate) : null,
                                workingModality: contractModality || null as any,
                                workingDayType: contractDayType || null as any,
                                contractSchedule: contractDays || [],
                            }
                        })
                }}
            >
                {(form) => (
                    <>
                        <FormInput
                            label={i18n.t('employees:employee.contracts.contractInfo.form.salary.label')}
                            placeholder={i18n.t('employees:employee.contracts.contractInfo.form.salary.placeholder')}
                            name='salary'
                            type='number'
                            control={form.control}
                        />

                        <FormDatePicker
                            label={i18n.t('employees:employee.contracts.contractInfo.form.startDate.label')}
                            placeholder={i18n.t('employees:employee.contracts.contractInfo.form.startDate.placeholder')}
                            asterisk
                            name='startDate'
                            control={form.control}
                            defaultValue={dayjs(employee.startDate).toDate()}
                        />

                        <FormDatePicker
                            label={i18n.t('employees:employee.contracts.contractInfo.form.endDate.label')}
                            placeholder={i18n.t('employees:employee.contracts.contractInfo.form.endDate.placeholder')}
                            name='endDate'
                            control={form.control}
                            clearable
                            defaultValue={dayjs(employee.endDate).toDate()}
                        />

                        <FormSelect
                            isClearable
                            name='contractModality'
                            control={form.control}
                            options={contractModalityOptions}
                            label={i18n.t('employees:employee.form.contractModality.label')}
                            placeholder={i18n.t('employees:employee.form.contractModality.placeholder')}
                        />

                        <FormSelect
                            isClearable
                            name='contractDayType'
                            control={form.control}
                            options={contractDayTypeOptions}
                            label={i18n.t('employees:employee.form.contractDayType.label')}
                            placeholder={i18n.t('employees:employee.form.contractDayType.placeholder')}
                        />

                        <CheckboxButtonInputGroup
                            label={i18n.t('employees:employee.form.contractDays.label')}
                            name='contractDays'
                            control={form.control as any}
                        >
                            {WEEK_DAYS.map((day, index) => (
                                <CheckboxButtonInput
                                    {...form.register('contractDays')}
                                    key={day}
                                    value={day}
                                >
                                    {WEEK_DAYS_LABELS[index]}
                                </CheckboxButtonInput>
                            ))}
                        </CheckboxButtonInputGroup>
                        <Small className='flex items-center gap-2 -mt-2'>
                            <IconInfoCircle size={12} stroke={1} />
                            {i18n.t('employees:employee.form.contractDays.message', {
                                employeeName: employee.name,
                                total: form.watch('contractDays')?.length ?? 0,
                            })}
                        </Small>

                        <SubmitButton data-testid="employee-contracts-submit-button">{i18n.t('employees:employee.buttons.save')}</SubmitButton>
                    </>
                )}
            </Form>
        </SimpleCard>
    )
}

export default EmployeeContractInformationForm
