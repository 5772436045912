import { ActionIcon } from '@mantine/core'
import { IconTrash } from '@tabler/icons-react'
import * as React from 'react'

type TimeTrackingEventProps = {
    children?: React.ReactNode
    className?: string
    timeText: string
    onDelete?: () => void
}

const styles = {
    root: ''
}

const TimeTrackingEvent = (props: TimeTrackingEventProps) => {
    const { children, className, timeText, onDelete } = props

    return (
        <div className="flex items-start justify-between cursor-pointer fc-event-main hover:bg-primary-300/25 group">
            <span className='text-xs font-medium text-gray-500' data-testid="time-tracking-event">{timeText}</span>

            {onDelete && (
                <ActionIcon
                    data-testid="time-tracking-event-delete"
                    className="-mt-1.5 -mr-1.5 group-hover:opacity-100 opacity-0 transition-opacity duration-200"
                    variant="transparent"
                    onClick={(event) => {
                        event.stopPropagation()
                        onDelete?.()
                    }}
                >
                    <IconTrash stroke={1.5} size="1rem" />
                </ActionIcon>
            )}
        </div>

    )
}

export default TimeTrackingEvent
