import { formatDate } from '@fullcalendar/core'
import { Subscription } from '@viterbit/web-app/dataAccess/model/Subscription.model'
import i18n from '@viterbit/web-app/i18n'
import * as React from 'react'

import BillingPricing from './BillingPrincing'


type BillingSummaryProps = {
    children?: React.ReactNode
    className?: string
    subscription: Subscription
}

const styles = {
    root: 'flex flex-col gap-6 px-10 py-5 justify-center text-center bg-gray-50 min-w-[360px] items-center',
    priceLabel: 'text-base font-semibold text-black',
    footer: 'text-xs leading-5',
}

const BillingSummary =  (props: BillingSummaryProps) => {
    const {  subscription, children } = props

    return (
        <div className={styles.root}>
            <div>
                {/* <OfferChip>{i18n.t('billing:off',{discount:10})}</OfferChip> */}
                <p className={styles.priceLabel}>{i18n.t(`billing:pricePerPeriod.${subscription.period}`)}</p>
                <BillingPricing subscription={subscription}/>
            </div>

            {children}

            {subscription.nextBillingAt && <p className={styles.footer}>
                {i18n.t('billing:planNote', { date: formatDate(subscription.nextBillingAt) })}
            </p>}
        </div>
    )
}

export default BillingSummary
