import { IconLoader2 } from '@tabler/icons-react'
import * as React from 'react'

import AnimatePop from '../Animation/AnimatePop'
import { clsxm } from '../../lib/clsxm'

export type SpinnerProps = {
    isSpinning?: boolean
    color?: string
    className?: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    indicator?: any
}

const Spinner = ({
    isSpinning,
    color = 'primary-600',
    className,
    indicator: Component = IconLoader2,
}: SpinnerProps) => (
    <AnimatePop open={isSpinning}>
        <Component
            data-testid='spinner'
            className={clsxm(
                'w-5, h-5',
                isSpinning && 'animate-spin',
                `text-${color}`,
                className
            )}
        />
    </AnimatePop>
)

export default Spinner
