import { useQuery } from '@tanstack/react-query'

import { TIME_TRACKING_QUERY_KEY } from '../../cacheKeys'
import { timeTrackingEstimationList } from '../../service/timeTrackingService'

export type UseTimeTrackingEstimationListConfig = {
    query: Parameters<typeof timeTrackingEstimationList>[0]
    enabled?: boolean
}

export const useTimeTrackingEstimationList = (config: UseTimeTrackingEstimationListConfig) => {
    const { query, enabled } = config || {}

    return useQuery({
        queryKey: [TIME_TRACKING_QUERY_KEY, 'estimation-list', query],
        queryFn: () => timeTrackingEstimationList(query),
        enabled,
    })
}

