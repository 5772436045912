/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IntegrationOptionQueryResponse } from './IntegrationOptionQueryResponse';
import {
    IntegrationOptionQueryResponseFromJSON,
    IntegrationOptionQueryResponseFromJSONTyped,
    IntegrationOptionQueryResponseToJSON,
} from './IntegrationOptionQueryResponse';

/**
 * 
 * @export
 * @interface IntegrationOptionsDataQueryResponse
 */
export interface IntegrationOptionsDataQueryResponse {
    /**
     * 
     * @type {Array<IntegrationOptionQueryResponse>}
     * @memberof IntegrationOptionsDataQueryResponse
     */
    data: Array<IntegrationOptionQueryResponse>;
}

/**
 * Check if a given object implements the IntegrationOptionsDataQueryResponse interface.
 */
export function instanceOfIntegrationOptionsDataQueryResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function IntegrationOptionsDataQueryResponseFromJSON(json: any): IntegrationOptionsDataQueryResponse {
    return IntegrationOptionsDataQueryResponseFromJSONTyped(json, false);
}

export function IntegrationOptionsDataQueryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntegrationOptionsDataQueryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(IntegrationOptionQueryResponseFromJSON)),
    };
}

export function IntegrationOptionsDataQueryResponseToJSON(value?: IntegrationOptionsDataQueryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ((value.data as Array<any>).map(IntegrationOptionQueryResponseToJSON)),
    };
}

