import employeeMapper from '@viterbit/web-app/dataAccess/mapper/employee.mapper'
import paginationMetaMapper from '@viterbit/web-app/dataAccess/mapper/paginationMeta.mapper'
import teamMapper from '@viterbit/web-app/dataAccess/mapper/team.mapper'
import { queryClient } from '@viterbit/web-app/shared/lib/ReactQueryProvider'
import { Configuration, TeamApi } from 'viterbit-api-sdk'

import { commonConfig } from './config'
import { employeeInvalidate } from './employeeService'
import { TEAM_QUERY_KEY } from '../cacheKeys'

const api = new TeamApi(new Configuration(commonConfig))

export const teamInvalidate = () =>
    queryClient.invalidateQueries([TEAM_QUERY_KEY])


export const teamCreate = (body: Parameters<typeof api.createTeam>[0]) =>
    api.createTeam(body).then((data) => {
        teamInvalidate()
        return data
    })

export const teamUpdate = (body: Parameters<typeof api.updateTeam>[0]) =>
    api.updateTeam(body).then((data) => {
        teamInvalidate()
        return data
    })

export const teamDelete = (body: Parameters<typeof api.deleteTeam>[0]) =>
    api.deleteTeam(body).then((data) => {
        teamInvalidate()
        return data
    })

export const teamList = (body: Parameters<typeof api.listTeam>[0]) =>
    api.listTeam(body).then(({data, meta}) => ({
        meta: paginationMetaMapper(meta),
        data: data.map(teamMapper.toModel)
    }))

export const teamDetail = (body: Parameters<typeof api.fetchTeam>[0]) =>
    api.fetchTeam(body).then(teamMapper.toModel)

export const teamAssignEmployee = (body: Parameters<typeof api.assignEmployee>[0]) =>
    api.assignEmployee(body).then((data) => {
        teamInvalidate()
        employeeInvalidate()
        return data
    })

export const teamUnassignEmployee = (body: Parameters<typeof api.unassignEmployee>[0]) =>
    api.unassignEmployee(body).then((data) => {
        teamInvalidate()
        employeeInvalidate()
        return data
    })

export const teamMemberList = (body: Parameters<typeof api.fetchTeamMembers>[0]) =>
    api.fetchTeamMembers(body).then(({ data, meta }) => ({
        meta: paginationMetaMapper(meta),
        data: data.map(employeeMapper.toModel),
    }))

