import { IWeekDays } from '@viterbit/web-app/dataAccess/model/Week.model'
import { RelevantDate } from '@viterbit/web-app/feature/leave/list/types'
import i18n from '@viterbit/web-app/i18n'
import { formatMonth } from '@viterbit/web-app/shared/utils/dateFormat'
import { Month } from 'ui/src/components/Calendar'

import { FormFields } from './ActionSpan'
import RelevantDay from './relevantDays/RelevantDays'

const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
]

type CalendarLocationProps = {
    year: number
    relevantDates: RelevantDate[]
    workableDays: IWeekDays[]
    clickable?: boolean
    onSubmit?: (data: FormFields) => void
}

const CalendarLocation = ({
    year,
    relevantDates,
    workableDays,
    clickable,
    onSubmit
}: CalendarLocationProps) => (
    <div className='flex flex-wrap w-full h-full bg-white rounded-md gap-y-4 gap-x-4 xl:gap-x-8'>
        {months.map((month, index) => (
            <div className='flex-1' key={month}>
                <p className='py-2 pl-2 text-base font-semibold text-black'>
                    {formatMonth(new Date(2000, index))} 
                </p>

                <Month
                    className='min-w-[250px]'
                    month={new Date(year, index)}
                    locale={i18n.resolvedLanguage}
                    firstDayOfWeek='monday'
                    renderDay={(date) => (
                        <RelevantDay
                            date={date.toISOString()}
                            relevantDates={relevantDates}
                            year={year}
                            month={index}
                            workableDays={workableDays}
                            clickable={clickable}
                            onSubmit={onSubmit}
                        />
                    )}
                    monthCellClassName='w-8 h-8'
                    hideOutOfMonthDates
                />
            </div>
        ))}
    </div>
)

export default CalendarLocation
