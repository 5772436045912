/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { StateHolidaysData } from 'viterbit-api-sdk'

import { IMapper } from './__common'
import holidayMapper from './holiday.mapper'
import { StateHoliday } from '../model/StateHoliday'


const stateHolidayMapper: IMapper<StateHoliday, StateHolidaysData> = {
    toModel: (dto) => ({
        year: dto.year!,
        stateHolidays: dto.stateHolidays?.map(holidayMapper.toModel) || []
    }),
    toDto: () =>{
        throw new Error('Not implemented')
    },
}

export default stateHolidayMapper
