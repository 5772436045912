import * as React from 'react'

type DashboardLayoutProps = {
    inbox: React.ReactNode
    secondSection: React.ReactNode
    children?: React.ReactNode
    holidays?: React.ReactNode
    className?: string
}
const DashboardLayout = ({
    inbox,
    secondSection,
    holidays,
    className = '',
}: DashboardLayoutProps) => (
    <>
        <section
            className={`flex flex-row gap-4 ${className}`}
        >
            <div className='flex-1'>
                {inbox}
            </div>
            <div className='flex-1'>
                {secondSection}
            </div>
            <div className='flex-1'>
                {holidays}
            </div>
        </section>
    </>
)

export default DashboardLayout
