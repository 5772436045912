/* eslint-disable @typescript-eslint/no-non-null-assertion */

import fileMapper from '@viterbit/web-app/dataAccess/mapper/file.mapper'
import paginationMetaMapper from '@viterbit/web-app/dataAccess/mapper/paginationMeta.mapper'
import { queryClient } from '@viterbit/web-app/shared/lib/ReactQueryProvider'
import { Configuration, FileApi } from 'viterbit-api-sdk'

import { commonConfig } from './config'
import { FILES_QUERY_KEY } from '../cacheKeys'

const api = new FileApi(new Configuration(commonConfig))

export const fileInvalidate = () =>
    queryClient.invalidateQueries([FILES_QUERY_KEY])


export const fileCreate = (body: Parameters<typeof api.uploadFile>[0]) =>
    api.uploadFile(body).then((data) => {
        fileInvalidate()
        return data
    })

export const fileListPaginated = (data: Parameters<typeof api.listFiles>[0]) =>
    api.listFiles(data).then(response => ({
        meta: paginationMetaMapper(response.meta!),
        data: response.data!.map(fileMapper.toModel),
    }))

export const fileDelete = (body: Parameters<typeof api.deleteFile>[0]) =>
    api.deleteFile(body).then((data) => {
        fileInvalidate()
        return data
    })

export const fileSignedUrl = (data: Parameters<typeof api.signedFileUrl>[0]) => {
    const isPrivate = window.location.href.startsWith(import.meta.env.VITE_PRIVATE_WEB_URL)

    if (isPrivate) {
        return api.signedFileUrl(data)
    }

    return api.publicSignedFileUrl(data)
}


