import { Integration } from '@viterbit/web-app/dataAccess/model/Integration.model'
import { useIntegrationDelete } from '@viterbit/web-app/dataAccess/useCase/integration/useIntegrationDelete'
import i18n from '@viterbit/web-app/i18n'
import ApiErrorMessage from '@viterbit/web-app/shared/feedback/ApiErrorMessage'
import ButtonDelete from '@viterbit/web-app/shared/form/ButtonDelete'
import FormDeleteDialog from '@viterbit/web-app/shared/form/FormDeleteDialog'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'

type IntegrationDeleteProps = {
    integration: Integration
}

const IntegrationDelete = (props: IntegrationDeleteProps) => {
    const { integration } = props
    const navigate = useNavigate()
    const [isDeleting, setIsDeleting] = React.useState(false)

    const onSuccess = async () => {
        setIsDeleting(false)
        navigate('/account/settings')
    }

    const { mutate, isLoading, error } = useIntegrationDelete({ onSuccess })

    return (
        <>
            <ButtonDelete collapsed onClick={() => setIsDeleting(true)}/>

            <FormDeleteDialog
                isOpen={isDeleting}
                isLoading={isLoading}
                onClose={() => setIsDeleting(false)}
                onSubmit={() => {
                    mutate({ id: integration.id })
                }}
                title={i18n.t('integrations:delete.title')}
            >
                <ApiErrorMessage error={error} />
            </FormDeleteDialog>
        </>
    )
}

export default IntegrationDelete
