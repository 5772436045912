import { PermissionAction } from '@viterbit/web-app/dataAccess/model/PermissionAction.model'
import { WorkSchedule } from '@viterbit/web-app/dataAccess/model/WorkSchedule.model'
import i18n from '@viterbit/web-app/i18n'
import AsideCardInfo from '@viterbit/web-app/shared/display/AsideCardInfo'
import IsGranted from '@viterbit/web-app/shared/permission/IsGranted'
import * as React from 'react'

import WorkScheduleDeleteAction from './WorkScheduleDeleteAction'

type WorkScheduleDeleteProps = {
    workSchedule: WorkSchedule
}

const WorkScheduleDelete = ({ workSchedule }: WorkScheduleDeleteProps) => (
    <IsGranted
        id={workSchedule.id}
        action={PermissionAction.CORE_HR_WORK_SCHEDULES_DELETE}
    >
        <AsideCardInfo className='mb-4' title={i18n.t('settingLeaves:dangerZone')}>
            <WorkScheduleDeleteAction
                workSchedule={workSchedule}
            />
        </AsideCardInfo>
    </IsGranted>
)


export default WorkScheduleDelete
