/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AssignEmployeeJobPositionLevelCommand,
  CreateEmployeeCommand,
  DeleteEmployeeCommand,
  EmployeeOrderByQueryParam,
  FilterGroups,
  FindCounterQueryResponse,
  FindEmployeeQueryResponse,
  ListEmployeeQueryResponse,
  ResourceCreated,
  ResourceDeleted,
  UpdateEmployeeCommand,
  UpdateLeaveAdjustmentsCommand,
} from '../models/index';
import {
    AssignEmployeeJobPositionLevelCommandFromJSON,
    AssignEmployeeJobPositionLevelCommandToJSON,
    CreateEmployeeCommandFromJSON,
    CreateEmployeeCommandToJSON,
    DeleteEmployeeCommandFromJSON,
    DeleteEmployeeCommandToJSON,
    EmployeeOrderByQueryParamFromJSON,
    EmployeeOrderByQueryParamToJSON,
    FilterGroupsFromJSON,
    FilterGroupsToJSON,
    FindCounterQueryResponseFromJSON,
    FindCounterQueryResponseToJSON,
    FindEmployeeQueryResponseFromJSON,
    FindEmployeeQueryResponseToJSON,
    ListEmployeeQueryResponseFromJSON,
    ListEmployeeQueryResponseToJSON,
    ResourceCreatedFromJSON,
    ResourceCreatedToJSON,
    ResourceDeletedFromJSON,
    ResourceDeletedToJSON,
    UpdateEmployeeCommandFromJSON,
    UpdateEmployeeCommandToJSON,
    UpdateLeaveAdjustmentsCommandFromJSON,
    UpdateLeaveAdjustmentsCommandToJSON,
} from '../models/index';

export interface AssignEmployeeToJobPositionLevelRequest {
    id: string;
    assignEmployeeJobPositionLevelCommand?: AssignEmployeeJobPositionLevelCommand;
}

export interface CreateEmployeesRequest {
    createEmployeeCommand?: CreateEmployeeCommand;
}

export interface DeleteEmployeesRequest {
    id: string;
    deleteEmployeeCommand?: DeleteEmployeeCommand;
}

export interface FetchCounterEmployeeRequest {
    id: string;
    year?: string;
}

export interface FetchEmployeeRequest {
    id: string;
    include?: Array<FetchEmployeeIncludeEnum>;
}

export interface ListEmployeesRequest {
    companyId?: string;
    locationId?: string;
    reporters?: boolean;
    complaintManager?: boolean;
    search?: string;
    notMembersOfTeamId?: string;
    archived?: boolean;
    notLeadersOfTeamId?: string;
    page?: number;
    pageSize?: number;
    filters?: FilterGroups;
    orderBy?: EmployeeOrderByQueryParam;
    include?: Array<ListEmployeesIncludeEnum>;
}

export interface UpdateEmployeesRequest {
    id: string;
    updateEmployeeCommand?: UpdateEmployeeCommand;
}

export interface UpdateEmployeesPersonalInformationRequest {
    id: string;
    updateEmployeeCommand?: UpdateEmployeeCommand;
}

export interface UpdateLeaveAdjustmentEmployeesRequest {
    id: string;
    updateLeaveAdjustmentsCommand?: UpdateLeaveAdjustmentsCommand;
}

/**
 * 
 */
export class EmployeeApi extends runtime.BaseAPI {

    /**
     * Assign employee to job position level
     * Assign employee to a job position level
     */
    async assignEmployeeToJobPositionLevelRaw(requestParameters: AssignEmployeeToJobPositionLevelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling assignEmployeeToJobPositionLevel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/employees/{id}/assign-job-position-level`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: AssignEmployeeJobPositionLevelCommandToJSON(requestParameters.assignEmployeeJobPositionLevelCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Assign employee to job position level
     * Assign employee to a job position level
     */
    async assignEmployeeToJobPositionLevel(requestParameters: AssignEmployeeToJobPositionLevelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.assignEmployeeToJobPositionLevelRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a employee
     * Create a employee
     */
    async createEmployeesRaw(requestParameters: CreateEmployeesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/employees`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateEmployeeCommandToJSON(requestParameters.createEmployeeCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Create a employee
     * Create a employee
     */
    async createEmployees(requestParameters: CreateEmployeesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.createEmployeesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a employee
     * Delete a employee
     */
    async deleteEmployeesRaw(requestParameters: DeleteEmployeesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceDeleted>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteEmployees.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/employees/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteEmployeeCommandToJSON(requestParameters.deleteEmployeeCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceDeletedFromJSON(jsonValue));
    }

    /**
     * Delete a employee
     * Delete a employee
     */
    async deleteEmployees(requestParameters: DeleteEmployeesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceDeleted> {
        const response = await this.deleteEmployeesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch employee counter by its id
     * Fetch employee counter
     */
    async fetchCounterEmployeeRaw(requestParameters: FetchCounterEmployeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindCounterQueryResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetchCounterEmployee.');
        }

        const queryParameters: any = {};

        if (requestParameters.year !== undefined) {
            queryParameters['year'] = requestParameters.year;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/employees/{id}/counter`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindCounterQueryResponseFromJSON(jsonValue));
    }

    /**
     * Fetch employee counter by its id
     * Fetch employee counter
     */
    async fetchCounterEmployee(requestParameters: FetchCounterEmployeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindCounterQueryResponse> {
        const response = await this.fetchCounterEmployeeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch employee by its id
     * Fetch employee
     */
    async fetchEmployeeRaw(requestParameters: FetchEmployeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindEmployeeQueryResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetchEmployee.');
        }

        const queryParameters: any = {};

        if (requestParameters.include) {
            queryParameters['include'] = requestParameters.include;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/employees/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindEmployeeQueryResponseFromJSON(jsonValue));
    }

    /**
     * Fetch employee by its id
     * Fetch employee
     */
    async fetchEmployee(requestParameters: FetchEmployeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindEmployeeQueryResponse> {
        const response = await this.fetchEmployeeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch all employee for the given organization
     * List employees
     */
    async listEmployeesRaw(requestParameters: ListEmployeesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListEmployeeQueryResponse>> {
        const queryParameters: any = {};

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        if (requestParameters.locationId !== undefined) {
            queryParameters['locationId'] = requestParameters.locationId;
        }

        if (requestParameters.reporters !== undefined) {
            queryParameters['reporters'] = requestParameters.reporters;
        }

        if (requestParameters.complaintManager !== undefined) {
            queryParameters['complaintManager'] = requestParameters.complaintManager;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.notMembersOfTeamId !== undefined) {
            queryParameters['notMembersOfTeamId'] = requestParameters.notMembersOfTeamId;
        }

        if (requestParameters.archived !== undefined) {
            queryParameters['archived'] = requestParameters.archived;
        }

        if (requestParameters.notLeadersOfTeamId !== undefined) {
            queryParameters['notLeadersOfTeamId'] = requestParameters.notLeadersOfTeamId;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.include) {
            queryParameters['include'] = requestParameters.include;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/employees`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListEmployeeQueryResponseFromJSON(jsonValue));
    }

    /**
     * Fetch all employee for the given organization
     * List employees
     */
    async listEmployees(requestParameters: ListEmployeesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListEmployeeQueryResponse> {
        const response = await this.listEmployeesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a employee
     * Update a employee
     */
    async updateEmployeesRaw(requestParameters: UpdateEmployeesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateEmployees.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/employees/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateEmployeeCommandToJSON(requestParameters.updateEmployeeCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Update a employee
     * Update a employee
     */
    async updateEmployees(requestParameters: UpdateEmployeesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.updateEmployeesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a employee personal information
     * Update a employee personal information
     */
    async updateEmployeesPersonalInformationRaw(requestParameters: UpdateEmployeesPersonalInformationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateEmployeesPersonalInformation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/employees/{id}/personal-information`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateEmployeeCommandToJSON(requestParameters.updateEmployeeCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Update a employee personal information
     * Update a employee personal information
     */
    async updateEmployeesPersonalInformation(requestParameters: UpdateEmployeesPersonalInformationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.updateEmployeesPersonalInformationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update leave adjustments for employee
     * Update leave adjustments for employee
     */
    async updateLeaveAdjustmentEmployeesRaw(requestParameters: UpdateLeaveAdjustmentEmployeesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateLeaveAdjustmentEmployees.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/employees/{id}/leave-adjustments`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateLeaveAdjustmentsCommandToJSON(requestParameters.updateLeaveAdjustmentsCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Update leave adjustments for employee
     * Update leave adjustments for employee
     */
    async updateLeaveAdjustmentEmployees(requestParameters: UpdateLeaveAdjustmentEmployeesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.updateLeaveAdjustmentEmployeesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const FetchEmployeeIncludeEnum = {
    ContractInformation: 'contractInformation',
    PersonalInformation: 'personalInformation',
    FiscalInformation: 'fiscalInformation',
    BankInformation: 'bankInformation',
    DeleteInformation: 'deleteInformation',
    LeaveAdjustments: 'leaveAdjustments',
    Address: 'address',
    Avatar: 'avatar',
    IdFile: 'idFile',
    EmergencyInformation: 'emergencyInformation',
    JobPosition: 'jobPosition',
    JobPositionLevel: 'jobPositionLevel',
    Location: 'location',
    LeavePolicy: 'leavePolicy',
    Leaves: 'leaves',
    Company: 'company',
    Manager: 'manager',
    Account: 'account',
    WorkSchedules: 'workSchedules'
} as const;
export type FetchEmployeeIncludeEnum = typeof FetchEmployeeIncludeEnum[keyof typeof FetchEmployeeIncludeEnum];
/**
 * @export
 */
export const ListEmployeesIncludeEnum = {
    ContractInformation: 'contractInformation',
    PersonalInformation: 'personalInformation',
    FiscalInformation: 'fiscalInformation',
    BankInformation: 'bankInformation',
    DeleteInformation: 'deleteInformation',
    LeaveAdjustment: 'leaveAdjustment',
    Address: 'address',
    Avatar: 'avatar',
    IdFile: 'idFile',
    EmergencyInformation: 'emergencyInformation',
    JobPosition: 'jobPosition',
    JobPositionLevel: 'jobPositionLevel',
    Location: 'location',
    LeavePolicy: 'leavePolicy',
    Leaves: 'leaves',
    Company: 'company',
    Manager: 'manager',
    Account: 'account',
    WorkSchedules: 'workSchedules'
} as const;
export type ListEmployeesIncludeEnum = typeof ListEmployeesIncludeEnum[keyof typeof ListEmployeesIncludeEnum];
