import { useInfiniteQuery } from '@tanstack/react-query'

import { JOB_POSITION_QUERY_KEY } from '../../cacheKeys'
import { jobPositionList } from '../../service/jobPositionService'

type JobPositionListConfig = {
    query: Parameters<typeof jobPositionList>[0]
    enabled?: boolean
}

export const useJobPositionListPaginated = (config?: JobPositionListConfig) => {
    const { query = { page: 1 }, enabled = true } = config || {}

    return useInfiniteQuery(
        [JOB_POSITION_QUERY_KEY, 'list-paginated', query],
        ({ pageParam }) => jobPositionList({ ...query, page: pageParam }),
        {
            getNextPageParam: (lastPage) => lastPage ? lastPage.meta.nextPage : 1,
            initialData: () => ({
                pageParams: [1],
                pages: [],
            }),
            enabled,
        }
    )
}

