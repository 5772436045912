/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FindCityQueryResponse } from './FindCityQueryResponse';
import {
    FindCityQueryResponseFromJSON,
    FindCityQueryResponseFromJSONTyped,
    FindCityQueryResponseToJSON,
} from './FindCityQueryResponse';
import type { ListMeta } from './ListMeta';
import {
    ListMetaFromJSON,
    ListMetaFromJSONTyped,
    ListMetaToJSON,
} from './ListMeta';

/**
 * 
 * @export
 * @interface ListCityQueryResponse
 */
export interface ListCityQueryResponse {
    /**
     * 
     * @type {ListMeta}
     * @memberof ListCityQueryResponse
     */
    meta: ListMeta;
    /**
     * 
     * @type {Array<FindCityQueryResponse>}
     * @memberof ListCityQueryResponse
     */
    data: Array<FindCityQueryResponse>;
}

/**
 * Check if a given object implements the ListCityQueryResponse interface.
 */
export function instanceOfListCityQueryResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "meta" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function ListCityQueryResponseFromJSON(json: any): ListCityQueryResponse {
    return ListCityQueryResponseFromJSONTyped(json, false);
}

export function ListCityQueryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListCityQueryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'meta': ListMetaFromJSON(json['meta']),
        'data': ((json['data'] as Array<any>).map(FindCityQueryResponseFromJSON)),
    };
}

export function ListCityQueryResponseToJSON(value?: ListCityQueryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'meta': ListMetaToJSON(value.meta),
        'data': ((value.data as Array<any>).map(FindCityQueryResponseToJSON)),
    };
}

