
import { IconNoteOff } from '@tabler/icons-react'
import { useJobPositionListPaginated } from '@viterbit/web-app/dataAccess/useCase/jobPosition/useJobPositionListPaginated'
import i18n from '@viterbit/web-app/i18n'
import { useOnScreenElement } from '@viterbit/web-app/shared/behaviour/useOnScreenElement'
import EmptyState from '@viterbit/web-app/shared/display/EmptyState/EmptyState'
import BreakpointRedirection from '@viterbit/web-app/shared/navigation/BreakpointRedirection'
import * as React from 'react'
import List from 'ui/src/components/List'

import JobPositionItem from './JobPositionItem'

const JobPositionList = () => {
    const { ref } = useOnScreenElement({
        onVisible: () => {
            hasNextPage && fetchNextPage()
        }
    })
    
    const {
        isFetched,
        data,
        fetchNextPage,
        hasNextPage,
    } = useJobPositionListPaginated()

    const firstJobPosition = React.useMemo(() => data?.pages[0]?.data[0], [data])

    return (
        <>
            {firstJobPosition && <BreakpointRedirection matcherPath="/employees/positions" redirectPath={`/employees/positions/${firstJobPosition?.id}/details`} />}

            {isFetched && !firstJobPosition && (
                <EmptyState
                    image={<IconNoteOff size={64} stroke={1} />}
                    className='my-20'
                    title={i18n.t('employees:jobPositions.emptyList.title')}
                    description={i18n.t('employees:jobPositions.emptyList.description')}
                />
            )}

            <List className='flex flex-col divide-y'>
                {data?.pages?.map(({ data: jobPositions }) =>
                    jobPositions.map((jobPosition) => (
                        <JobPositionItem
                            position={jobPosition}
                            key={jobPosition.id}
                        />
                    ))
                )}
            </List>

            <div ref={ref} />
        </>
    )
}


export default JobPositionList