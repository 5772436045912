import { PermissionAction } from '@viterbit/web-app/dataAccess/model/PermissionAction.model'
import EmployeeItem from '@viterbit/web-app/feature/employee/item/EmployeeItem'
import SegmentNavLink from '@viterbit/web-app/shared/navigation/SegmentNavLink'
import IsGranted from '@viterbit/web-app/shared/permission/IsGranted'
import * as React from 'react'

import LeaveTeamLayout, { LeaveTeamLayoutProps } from './LeaveTeamLayout'
import { LeaveTeamEmployee } from './LeaveTeamShared'

type LeaveTeamRowProps = {
    employee: LeaveTeamEmployee
} & Omit<LeaveTeamLayoutProps, 'header'>

const LeaveTeamRowHeader = (props: LeaveTeamRowProps) => {
    const { employee } = props

    return (
        <IsGranted
            id={employee.id}
            action={PermissionAction.CORE_EMPLOYEE_PERSONAL_INFORMATION_FIND}
            fallback={<EmployeeItem employee={employee}/>}
            loader={<EmployeeItem employee={employee}/>}
        >
            <SegmentNavLink path={`/employees/list/${employee.id}`}>
                <EmployeeItem employee={employee} clickable/>
            </SegmentNavLink>
        </IsGranted>
    )
}

const EmployeeTeamLeavesRow = (props: LeaveTeamRowProps) => {
    const { employee, ...rest } = props

    return (
        <LeaveTeamLayout
            header={<LeaveTeamRowHeader {...props} />}
            {...rest}
        />
    )
}

export default EmployeeTeamLeavesRow
