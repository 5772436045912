import { JobPositionLevel } from '@viterbit/web-app/dataAccess/model/JobPositionLevel.model'
import { IsNotEmpty, IsString } from '@viterbit/web-app/shared/utils/ClassValidators'

type CreateType = Pick<JobPositionLevel, 'name'> & { jobPositionId: string }

class JobPositionLevelCreateValidator implements CreateType {
    @IsString()
    jobPositionId: string
    @IsString()
    @IsNotEmpty()
    name: string
    description: string
}

export default JobPositionLevelCreateValidator
