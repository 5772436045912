/* eslint-disable no-console */
/* eslint-disable turbo/no-undeclared-env-vars */


function throwException(m) {
    if (import.meta.env.VITE_THROW_EXCEPTION_ON_ERROR === 'true') {
        throw m
    } else {
        console.error(m)
    }

    return null
}

export default throwException