/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BillingInfo } from './BillingInfo';
import {
    BillingInfoFromJSON,
    BillingInfoFromJSONTyped,
    BillingInfoToJSON,
} from './BillingInfo';
import type { FindFileQueryResponse } from './FindFileQueryResponse';
import {
    FindFileQueryResponseFromJSON,
    FindFileQueryResponseFromJSONTyped,
    FindFileQueryResponseToJSON,
} from './FindFileQueryResponse';
import type { PaymentPage } from './PaymentPage';
import {
    PaymentPageFromJSON,
    PaymentPageFromJSONTyped,
    PaymentPageToJSON,
} from './PaymentPage';
import type { Prices } from './Prices';
import {
    PricesFromJSON,
    PricesFromJSONTyped,
    PricesToJSON,
} from './Prices';
import type { Subscription } from './Subscription';
import {
    SubscriptionFromJSON,
    SubscriptionFromJSONTyped,
    SubscriptionToJSON,
} from './Subscription';

/**
 * 
 * @export
 * @interface FindOrganizationQueryResponse
 */
export interface FindOrganizationQueryResponse {
    /**
     * 
     * @type {string}
     * @memberof FindOrganizationQueryResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof FindOrganizationQueryResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof FindOrganizationQueryResponse
     */
    domain: string;
    /**
     * 
     * @type {string}
     * @memberof FindOrganizationQueryResponse
     */
    terms: string;
    /**
     * 
     * @type {BillingInfo}
     * @memberof FindOrganizationQueryResponse
     */
    billingInfo?: BillingInfo;
    /**
     * 
     * @type {Subscription}
     * @memberof FindOrganizationQueryResponse
     */
    subscription?: Subscription;
    /**
     * 
     * @type {PaymentPage}
     * @memberof FindOrganizationQueryResponse
     */
    paymentPage?: PaymentPage;
    /**
     * 
     * @type {Array<Prices>}
     * @memberof FindOrganizationQueryResponse
     */
    prices?: Array<Prices>;
    /**
     * 
     * @type {FindFileQueryResponse}
     * @memberof FindOrganizationQueryResponse
     */
    logo?: FindFileQueryResponse;
}

/**
 * Check if a given object implements the FindOrganizationQueryResponse interface.
 */
export function instanceOfFindOrganizationQueryResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "domain" in value;
    isInstance = isInstance && "terms" in value;

    return isInstance;
}

export function FindOrganizationQueryResponseFromJSON(json: any): FindOrganizationQueryResponse {
    return FindOrganizationQueryResponseFromJSONTyped(json, false);
}

export function FindOrganizationQueryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindOrganizationQueryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'domain': json['domain'],
        'terms': json['terms'],
        'billingInfo': !exists(json, 'billingInfo') ? undefined : BillingInfoFromJSON(json['billingInfo']),
        'subscription': !exists(json, 'subscription') ? undefined : SubscriptionFromJSON(json['subscription']),
        'paymentPage': !exists(json, 'paymentPage') ? undefined : PaymentPageFromJSON(json['paymentPage']),
        'prices': !exists(json, 'prices') ? undefined : ((json['prices'] as Array<any>).map(PricesFromJSON)),
        'logo': !exists(json, 'logo') ? undefined : FindFileQueryResponseFromJSON(json['logo']),
    };
}

export function FindOrganizationQueryResponseToJSON(value?: FindOrganizationQueryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'domain': value.domain,
        'terms': value.terms,
        'billingInfo': BillingInfoToJSON(value.billingInfo),
        'subscription': SubscriptionToJSON(value.subscription),
        'paymentPage': PaymentPageToJSON(value.paymentPage),
        'prices': value.prices === undefined ? undefined : ((value.prices as Array<any>).map(PricesToJSON)),
        'logo': FindFileQueryResponseToJSON(value.logo),
    };
}

