/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BillingInfo
 */
export interface BillingInfo {
    /**
     * 
     * @type {string}
     * @memberof BillingInfo
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof BillingInfo
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof BillingInfo
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof BillingInfo
     */
    vatNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof BillingInfo
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof BillingInfo
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof BillingInfo
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof BillingInfo
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof BillingInfo
     */
    state?: string;
}

/**
 * Check if a given object implements the BillingInfo interface.
 */
export function instanceOfBillingInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BillingInfoFromJSON(json: any): BillingInfo {
    return BillingInfoFromJSONTyped(json, false);
}

export function BillingInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillingInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'vatNumber': !exists(json, 'vatNumber') ? undefined : json['vatNumber'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'state': !exists(json, 'state') ? undefined : json['state'],
    };
}

export function BillingInfoToJSON(value?: BillingInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'email': value.email,
        'phone': value.phone,
        'vatNumber': value.vatNumber,
        'address': value.address,
        'country': value.country,
        'city': value.city,
        'postalCode': value.postalCode,
        'state': value.state,
    };
}

