import { IconCalendarPlus } from '@tabler/icons-react'
import * as React from 'react'

const STYLES = {
    root: 'p-3 text-center rounded-lg border-2 border-dashed border-gray-200 cursor-pointer flex items-center justify-center mt-4',
    hover: 'hover:border-gray-400',
}

type CreateLeaveReasonButtonProps = {
    children?: React.ReactNode
    className?: string
    onClick: React.MouseEventHandler<HTMLDivElement>
}

const CreateLeaveReasonButton = ({
    children,
    className = '',
    onClick,
}: CreateLeaveReasonButtonProps) => (
    <div className={`${STYLES.root} ${STYLES.hover} ${className}`} onClick={onClick}>
        <IconCalendarPlus className='mr-2 text-gray-400' />

        {children}
    </div>
)

export default CreateLeaveReasonButton
