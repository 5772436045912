
import { Leave } from '@viterbit/web-app/dataAccess/model/Leave.model'
import i18n from '@viterbit/web-app/i18n'
import EmptyState from '@viterbit/web-app/shared/display/EmptyState/EmptyState'
import useGetEmployeeId from '@viterbit/web-app/shared/employee/useGetEmployeeId'
import * as React from 'react'
import List from 'ui/src/components/List'

import LeaveItem from './LeaveItem'
import useLeaveDialog from '../../useLeaveDialog'

type ListLeavesProps = {
    leaves: Leave[]
    emptyListKey: string
}

const ListLeaves = ({ leaves, emptyListKey }: ListLeavesProps) => {
    const { employeeId } = useGetEmployeeId()
    const { onItemClicked, dialog } = useLeaveDialog()

    const handleOnClick = (leave: Leave) => {
        onItemClicked(employeeId, leave)
    }

    if (leaves.length === 0)
        return (
            <EmptyState
                description={i18n.t('emptyState:noLeaves', {
                    emptyKey: i18n.t(`leaves:${emptyListKey}`),
                })}
            />
        )

    return (
        <>
            <List className='flex flex-col w-full overflow-y-auto divide-y' data-testid='list-leave'>
                {leaves.map((leave) => (
                    <LeaveItem
                        key={leave.id}
                        leave={leave}
                        onClick={() => handleOnClick(leave)}
                    />
                ))}
            </List>

            {dialog}
        </>
    )
}
export default ListLeaves
