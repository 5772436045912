/* eslint-disable react/no-array-index-key */
import * as React from 'react'

import FileItemSkeleton from './FileItemSkeleton'
import FileList, { FileListProps } from './FileList'

export type FileListSkeletonProps = {
    count?: number
} & FileListProps

const FileListSkeleton = ({ count = 4, ...rest }: FileListSkeletonProps) => (
    <FileList {...rest}>
        {Array(count)
            .fill(0)
            .map((_, index) => (
                <FileItemSkeleton key={index} />
            ))}
    </FileList>
)

export default FileListSkeleton
