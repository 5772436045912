import accountMapper from './account.mapper'
import companyMapper from './company.mapper'
import organizationMapper from './organization.mapper'
import publicEmployeeMapper from './publicEmployee.mapper'
import { AccountExtendedResponse } from '../dto'
import { AccountExtended } from '../model/AccountExtended.model'

const accountExtendedMapper = {
    toModel: (dto: AccountExtendedResponse): AccountExtended => ({
        ...accountMapper.toModel(dto),
        active: dto.active,
        isAdmin: dto.isAdmin,
        employee: dto.employee && publicEmployeeMapper.toModel(dto.employee),
        company: dto.company && companyMapper.toModel(dto.company),
        organization: dto.organization && organizationMapper.toModel(dto.organization),
    }),
}

export default accountExtendedMapper
