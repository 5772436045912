import { useOrganizationDetail } from '@viterbit/web-app/dataAccess/useCase/organization/useOrganizationDetail'
import i18n from '@viterbit/web-app/i18n'
import SettingsLayout from '@viterbit/web-app/shared/layout/SettingsLayout'
import { Outlet } from 'react-router-dom'

import SettingsComplaintsInfo from './SettingsComplaintsInfo'

const SettingsComplaintsLayout = () => {
    const { data } = useOrganizationDetail({
        enabled: true,
    })

    return (
        <SettingsLayout
            title={i18n.t('settingsComplaints:title')}
            left={(
                <SettingsComplaintsInfo organization={data} />
            )}
        >
            <Outlet  />
        </SettingsLayout>
    )
}

export default SettingsComplaintsLayout
