import { IconFile, IconFileText, IconFileZip, IconPhoto } from '@tabler/icons-react'

import { IFile } from './sharedTypes'

type FileItemThumbnailProps = {
    file: IFile
    showImage?: boolean
}

const iconProps = {
    width: 36,
    height: 36,
    stroke: 1,
    color: 'white',
}

const FileItemThumbnail = ({file, showImage}: FileItemThumbnailProps) => {
    const type = file.kind
    if (type.startsWith('image')) {
        if (showImage) {
            return (
            // eslint-disable-next-line @next/next/no-img-element
                <img
                    className='max-w-full max-h-full '
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    src={file.url ?? URL.createObjectURL(file._meta!.file)}
                    alt={`file preview ${name}`}
                />
            )
        }

        return (

            <IconPhoto {...iconProps} />

        )
    }

    if (type === 'application/zip') {
        return (
            <IconFileZip {...iconProps} />
        )
    }

    if (type === 'application/pdf') {
        return (
            <IconFileText {...iconProps} />
        )
    }

    return (
        <IconFile {...iconProps} />
    )
}

export default FileItemThumbnail