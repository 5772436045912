import { Leave } from '@viterbit/web-app/dataAccess/model/Leave.model'

const mapLeavesDays = (leaves: Leave[]): Record<string, Leave> => {
    const leavesDays = leaves.reduce((mappedLeaves, leave) => {
        leave.calendar.forEach(calendarItem => {
            mappedLeaves[calendarItem.day] = leave
        })
        return mappedLeaves
    }, {})

    return leavesDays
}

export default mapLeavesDays