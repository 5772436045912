import * as React from 'react'
import { useParams } from 'react-router-dom'

import { getUser } from '../state/userState'

export const useCurrentEmployeeId = () => {
    const user = getUser()
    const params = useParams()

    const employeeId = React.useMemo(() => params.employeeId || user?.id, [params.employeeId, user])

    return employeeId
}