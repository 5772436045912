/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react'

import DropdownContext from './context/dropdownContext'
import DropdownMenuContext, {
    useDropdownMenuContext,
} from './context/dropdownMenuContext'
import DropdownMenu from './DropdownMenu'
import DropdownToggle from './DropdownToggle'
import { DropdownPlacementType, DropdownTriggerType } from './sharedTypes'
import useRootClose from '../../hooks/useRootClose'

export type DropdownProps = {
    className?: string
    trigger?: DropdownTriggerType
    placement?: DropdownPlacementType
    menuClass?: string
    menuStyle?: object
    style?: object
    disabled?: boolean
    title?: string
    renderTitle?: React.ReactNode
    activeKey?: string
    toggleClassName?: string
    onClick?: (e: React.MouseEvent) => void
    onMouseEnter?: (e: React.MouseEvent) => void
    onMouseLeave?: (e: React.MouseEvent) => void
    onContextMenu?: (e: React.MouseEvent) => void
    onSelect?: (eventKey: string, e: React.MouseEvent) => void
    onOpen?: () => void
    onClose?: () => void
    onToggle?: (open?: boolean) => void
    children: React.ReactNode
    'data-testid'?: string
}

const Dropdown = React.forwardRef<HTMLDivElement, DropdownProps>(
    (props, ref) => {
        const {
            title,
            children,
            menuClass,
            menuStyle,
            disabled,
            renderTitle,
            placement = 'bottom-start',
            activeKey,
            toggleClassName,
            trigger = 'click',
            style,
            onClick,
            onMouseEnter,
            onMouseLeave,
            onContextMenu,
            onSelect,
            onOpen,
            onClose,
            onToggle,
            'data-testid': dataTestId = 'dropdown-toggle',
            ...rest
        } = props

        const overlayTarget: any = React.useRef<HTMLDivElement>()
        const triggerTarget: any = React.useRef<HTMLDivElement>()
        const menuControl = useDropdownMenuContext(overlayTarget)
        const open = menuControl.open

        const buttonId = 'dropdown-toggle-'
        const menuId = 'base-menu-'

        const handleToggle = React.useCallback(
            (isOpen?: boolean) => {
                const nextOpen = typeof isOpen === 'undefined' ? !open : isOpen
                const fn = nextOpen ? onOpen : onClose

                fn?.()
                onToggle?.(nextOpen)
                if (nextOpen) {
                    menuControl.openMenu()
                } else {
                    menuControl.closeMenu()
                }
            },
            [onClose, onOpen, onToggle, open, menuControl]
        )

        const handleClick = React.useCallback(
            (e: any) => {
                e.preventDefault()
                if (disabled) {
                    return
                }
                handleToggle()
            },
            [disabled, handleToggle]
        )

        const handleMouseEnter = React.useCallback(() => {
            if (!disabled) {
                handleToggle(true)
            }
        }, [disabled, handleToggle])

        const handleMouseLeave = React.useCallback(() => {
            if (!disabled) {
                handleToggle(false)
            }
        }, [disabled, handleToggle])

        const handleSelect = (eventKey: string, e: React.MouseEvent) => {
            onSelect?.(eventKey, e)
            handleToggle(false)
        }

        useRootClose(() => handleToggle(), {
            triggerTarget,
            overlayTarget,
            disabled: !open,
            listenEscape: false,
        })

        const dropdownProps = {
            onMouseEnter,
            onMouseLeave,
        }

        const toggleEventHandlers = {
            onClick,
            onContextMenu,
        }

        switch (trigger) {
        case 'context':
            toggleEventHandlers.onContextMenu = (e: React.MouseEvent) => {
                handleClick(e)
                onContextMenu?.(e)
            }
            break
        case 'hover':
            dropdownProps.onMouseEnter = (e: React.MouseEvent) => {
                handleMouseEnter()
                onMouseEnter?.(e)
            }
            dropdownProps.onMouseLeave = (e: React.MouseEvent) => {
                handleMouseLeave()
                onMouseLeave?.(e)
            }
            break
        default:
            toggleEventHandlers.onClick = handleClick
            break
        }

        const toggleElement = (
            <DropdownToggle
                {...rest}
                {...toggleEventHandlers}
                id={buttonId}
                ref={triggerTarget as any}
                className={toggleClassName}
                renderTitle={renderTitle}
                disabled={disabled}
                placement={placement}
                data-testid={dataTestId}
            >
                {title}
            </DropdownToggle>
        )

        const menuElement = (
            <DropdownMenu
                className={menuClass}
                style={menuStyle}
                onSelect={handleSelect}
                activeKey={activeKey}
                ref={overlayTarget}
                hidden={!open}
                placement={placement}
                id={menuId}
            >
                {children}
            </DropdownMenu>
        )

        return (
            <DropdownContext.Provider value={{ activeKey }}>
                <div
                    {...dropdownProps}
                    ref={ref}
                    style={style}
                    className='relative inline-block '
                >
                    {toggleElement}
                    <DropdownMenuContext.Provider value={menuControl}>
                        {menuElement}
                    </DropdownMenuContext.Provider>
                </div>
            </DropdownContext.Provider>
        )
    }
)

export default Dropdown
