import { HolidaysData } from 'viterbit-api-sdk'

import { IMapper } from './__common'
import { Holiday } from '../model/Holiday'


const holidayMapper: IMapper<Holiday, HolidaysData> = {
    toModel: (dto) => ({
        date: dto.date || '',
        name: dto.name || '',
    }),
    toDto: () => {
        throw new Error('Not implemented')
    },
}

export default holidayMapper
