import { IconMoodEmptyFilled } from '@tabler/icons-react'
import i18n from '@viterbit/web-app/i18n'
import EmptyState from '@viterbit/web-app/shared/display/EmptyState/EmptyState'


const DocumentBulkUploadPreviewEmpty = () => (
    <div className='min-h-[400px] flex items-center'>
        <EmptyState
            image={<IconMoodEmptyFilled size={64} className='text-gray-400' stroke={1} />}
            title={i18n.t('employees:bulkUpload.preview.empty.title')}
            description={i18n.t('employees:bulkUpload.preview.empty.description')}
        />
    </div>
)

export default DocumentBulkUploadPreviewEmpty
