
import { ActionIcon } from '@mantine/core'
import { IconTrashX } from '@tabler/icons-react'
import { WorkSchedule } from '@viterbit/web-app/dataAccess/model/WorkSchedule.model'
import SimpleCard from '@viterbit/web-app/shared/display/SimpleCard'
import Small from '@viterbit/web-app/shared/typography/Small'
import Text from '@viterbit/web-app/shared/typography/Text'
import { formatDayWithMonth } from '@viterbit/web-app/shared/utils/dateFormat'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import * as React from 'react'
import { useFormContext,UseFormReturn } from 'react-hook-form'
import clsxm from 'ui/src/lib'

import WorkScheduleDayPeriodsTableFields from './WorkScheduleDayPeriodsTableFields'

dayjs.extend(duration)

type WorkScheduleDetailPeriodFieldItemProps = {
    children?: React.ReactNode
    className?: string
    title: string
    form: UseFormReturn<WorkSchedule>
    name: string
    onRemove?: () => void
    showActions?: boolean
}

const styles = {
    root: 'mb-4 group'
}

const useDateRange = (name) => {
    const context = useFormContext()
    const { startDate, endDate } = context.getValues(`${name}`)

    return React.useMemo(() =>  ({
        startDate: formatDayWithMonth(startDate),
        endDate: formatDayWithMonth(endDate),
    }), [startDate, endDate])
}

const WorkScheduleDetailPeriodFieldItem = (props: WorkScheduleDetailPeriodFieldItemProps) => {
    const { title, name, form, className, onRemove, showActions } = props
    const { startDate, endDate } = useDateRange(name)

    return (
        <SimpleCard
            border
            className={clsxm(styles.root, className)}
            titleClassName='bg-gray-50 px-4'
            title={(
                <div className='flex flex-row items-end gap-2'>
                    <div className='flex-1'>
                        <Text bold>
                            {title}
                        </Text>
                        <Small
                            opacity
                            data-testid='work-schedule-detail-period-field-item-dates'
                        >
                            {startDate} - {endDate}
                        </Small>
                    </div>
                    <div
                    >
                        {onRemove && showActions && <ActionIcon className='opacity-0 group-hover:opacity-100'>
                            <IconTrashX size={21} onClick={onRemove}/>
                        </ActionIcon>}
                    </div>
                </div>
            )}
        >
            
            <WorkScheduleDayPeriodsTableFields
                showActions={showActions}
                className='p-2'
                form={form}
                name={`${name}.schedule`}
            />
        </SimpleCard>
    )
}

export default WorkScheduleDetailPeriodFieldItem
