/* eslint-disable @typescript-eslint/no-explicit-any */

import i18n from '@viterbit/web-app/i18n'
import { registerDecorator, ValidationArguments, ValidationOptions, ValidatorConstraintInterface } from 'class-validator'

import { ValidatorConstraint } from './ClassValidators'

export function IsSlug(validationOptions?: ValidationOptions) {
    return (object: any, propertyName: string) => {
        registerDecorator({
            target: object.constructor,
            propertyName,
            options: validationOptions,
            constraints: [],
            validator: SlugConstraint,
        })
    }
}

@ValidatorConstraint({ name: 'Slug' })
export class SlugConstraint implements ValidatorConstraintInterface {
    validate(value: any, args: ValidationArguments) {
        return /^[a-z0-9]+(?:-[a-z0-9]+)*$/.test(args.value)
    }

    defaultMessage(validationArguments?: ValidationArguments | undefined): string {
        return i18n.t('validation:is_slug')
    }
}
