/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { IMapper } from './__common'
import roleMapper from './role.mapper'
import { AccountResponse } from '../dto'
import { Account } from '../model/Account.model'

const accountMapper: IMapper<Account, AccountResponse> = {
    toModel: (dto) => ({
        id: dto.id || '',
        name: dto.name || '',
        email: dto.email || '',
        role: dto.role && roleMapper.toModel(dto.role),
        locale: dto.locale,
        timezone: dto.timezone,
        active: !!dto.active
    }),
    toDto: (dto) => ({
        id: dto.id,
        name: dto.name,
        email: dto.email,
        locale: dto.locale,
        timezone: dto.timezone
    }),
}

export default accountMapper
