import isEmpty from 'lodash/isEmpty'
import * as React from 'react'

import DynamicFields, { DynamicFieldsProps } from './DynamicFields'
import useDynamicForm, { DynamicField, SchemaType } from './useDynamicForm'

type DynamicFieldsSchemaProps = Omit<DynamicFieldsProps, 'fields'> & { schema: SchemaType }


const getDefaultValue = (field: DynamicField) => {
    if (field.default) {
        return field.default
    }

    if (field.type === 'boolean') {
        return false
    }
    if (field.type === 'integer') {
        return 0
    }
    if (field.type === 'string') {
        return ''
    }
    if (field.type === 'array') {
        return []
    }
}

const DynamicFieldsSchema = (props: DynamicFieldsSchemaProps) => {
    const { form, schema, defaultValues, ...rest } = props

    const fields = useDynamicForm(schema)

    React.useEffect(() => {
        if (isEmpty(defaultValues)) {
            const defaultValues = fields.reduce((acc, field) => {
                acc[field.name] = getDefaultValue(field)
                return acc
            }, {} as Record<string, any>)
            form.reset(defaultValues)
        }
    }, [fields, defaultValues])

    if (!fields.length) {
        return null
    }

    return (
        <DynamicFields form={form} fields={fields} defaultValues={defaultValues} {...rest} />
    )
}

export default DynamicFieldsSchema
