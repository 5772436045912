import * as React from 'react'

export interface IFormStatusContext {
    disableSubmit: boolean
    loading: boolean
    success: boolean
    smallButtons: boolean
    submit: () => Promise<void>
    formRef: React.RefObject<HTMLFormElement>
    formId?: string
}

const initialValues = {
    disableSubmit: false,
    loading: false,
    success: false,
    smallButtons: false,
    submit: async () => {
        console.log('submit not implemented')
    },
    formRef: React.createRef<HTMLFormElement>()
}

const FormStatusContext = React.createContext<IFormStatusContext>(initialValues)

export default FormStatusContext