import i18n from '@viterbit/web-app/i18n'
import * as React from 'react'
import { I18nextProvider } from 'react-i18next'
import { RecoilRoot } from 'recoil'
import RecoilNexus from 'recoil-nexus'

import RefreshTokenProvider from './auth/RefreshTokenProvider'
import LayoutProvider from './layout/LayoutProvider'
import MantineProvider from './lib/MantineProvider'
import ReactQueryProvider from './lib/ReactQueryProvider'
import RouterProvider from './navigation/RouterProvider'

const GlobalProviders = () => (
    <RecoilRoot>
        <RecoilNexus/>
        
        <I18nextProvider i18n={i18n}>
            <ReactQueryProvider>
                <LayoutProvider>
                    <MantineProvider>
                        <RefreshTokenProvider>
                            <RouterProvider />
                        </RefreshTokenProvider>
                    </MantineProvider>
                </LayoutProvider>
            </ReactQueryProvider>
        </I18nextProvider>
    </RecoilRoot>
)

export default GlobalProviders
