import { IsNotEmpty } from '@viterbit/web-app/shared/utils/ClassValidators'

class EmployeePersonalAvatarValidator {
    @IsNotEmpty({
        message: 'required',
    })
    avatarFileId: string
}

export default EmployeePersonalAvatarValidator
