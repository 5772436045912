/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DaysPerReason } from './DaysPerReason';
import {
    DaysPerReasonFromJSON,
    DaysPerReasonFromJSONTyped,
    DaysPerReasonToJSON,
} from './DaysPerReason';

/**
 * 
 * @export
 * @interface FindCounterQueryResponse
 */
export interface FindCounterQueryResponse {
    /**
     * 
     * @type {number}
     * @memberof FindCounterQueryResponse
     */
    totalDays: number;
    /**
     * 
     * @type {number}
     * @memberof FindCounterQueryResponse
     */
    usedTotalDays: number;
    /**
     * 
     * @type {number}
     * @memberof FindCounterQueryResponse
     */
    leavePolicyDays: number;
    /**
     * 
     * @type {number}
     * @memberof FindCounterQueryResponse
     */
    usedLeavePolicyDays: number;
    /**
     * 
     * @type {number}
     * @memberof FindCounterQueryResponse
     */
    carriedDays: number;
    /**
     * 
     * @type {number}
     * @memberof FindCounterQueryResponse
     */
    usedCarriedDays: number;
    /**
     * 
     * @type {number}
     * @memberof FindCounterQueryResponse
     */
    availableDays: number;
    /**
     * 
     * @type {Array<DaysPerReason>}
     * @memberof FindCounterQueryResponse
     */
    daysConsumedPerReason: Array<DaysPerReason>;
}

/**
 * Check if a given object implements the FindCounterQueryResponse interface.
 */
export function instanceOfFindCounterQueryResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "totalDays" in value;
    isInstance = isInstance && "usedTotalDays" in value;
    isInstance = isInstance && "leavePolicyDays" in value;
    isInstance = isInstance && "usedLeavePolicyDays" in value;
    isInstance = isInstance && "carriedDays" in value;
    isInstance = isInstance && "usedCarriedDays" in value;
    isInstance = isInstance && "availableDays" in value;
    isInstance = isInstance && "daysConsumedPerReason" in value;

    return isInstance;
}

export function FindCounterQueryResponseFromJSON(json: any): FindCounterQueryResponse {
    return FindCounterQueryResponseFromJSONTyped(json, false);
}

export function FindCounterQueryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindCounterQueryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalDays': json['totalDays'],
        'usedTotalDays': json['usedTotalDays'],
        'leavePolicyDays': json['leavePolicyDays'],
        'usedLeavePolicyDays': json['usedLeavePolicyDays'],
        'carriedDays': json['carriedDays'],
        'usedCarriedDays': json['usedCarriedDays'],
        'availableDays': json['availableDays'],
        'daysConsumedPerReason': ((json['daysConsumedPerReason'] as Array<any>).map(DaysPerReasonFromJSON)),
    };
}

export function FindCounterQueryResponseToJSON(value?: FindCounterQueryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalDays': value.totalDays,
        'usedTotalDays': value.usedTotalDays,
        'leavePolicyDays': value.leavePolicyDays,
        'usedLeavePolicyDays': value.usedLeavePolicyDays,
        'carriedDays': value.carriedDays,
        'usedCarriedDays': value.usedCarriedDays,
        'availableDays': value.availableDays,
        'daysConsumedPerReason': ((value.daysConsumedPerReason as Array<any>).map(DaysPerReasonToJSON)),
    };
}

