/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useOrganizationDetail } from '@viterbit/web-app/dataAccess/useCase/organization/useOrganizationDetail'
import SectionLayout from '@viterbit/web-app/shared/layout/SectionLayout'
import * as React from 'react'
import Skeleton from 'ui/src/components/Skeleton'

import SettingsOrganizationForm from './SettingsOrganizationForm'
import SettingsOrganizationLoading from './SettingsOrganizationLoading'

const SettingsOrganizationLayout = () => {

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const { data, isInitialLoading } = useOrganizationDetail({
        enabled: true,
    })

    return (
        <SectionLayout
            title={data?.name || <Skeleton className='w-10 h-3 my-1'/> as any}
        >
            {isInitialLoading || !data ? (
                <SettingsOrganizationLoading />
            ) : (
                <SettingsOrganizationForm organization={data} />
            )}
        </SectionLayout>
    )
}

export default SettingsOrganizationLayout
