/* eslint-disable @typescript-eslint/no-unused-vars */


import SegmentNavLink from '@viterbit/web-app/shared/navigation/SegmentNavLink'
import * as React from 'react'
import Tabs from 'ui/src/components/Tabs'

import NavigationTabsContentLoading from './NavigationTabsContentLoading'
import NavigationTabsLoading from './NavigationTabsLoading'

export type NavigationTabsProps<TabsKey extends string> = {
    children?: React.ReactNode
    tabs: NavigationTab<TabsKey>[]
    isLoading?: boolean
}

type NavigationTab<TabsKey extends string> = {
    key: TabsKey
    path: string
    title: string
    icon: React.ReactNode
    exact?: boolean
    hasPermission: boolean
}

const NavigationTabs = <TabsKey extends string>({ children, tabs, isLoading }: NavigationTabsProps<TabsKey>) => {
    if (isLoading) {
        return (
            <NavigationTabsLoading>
                <NavigationTabsContentLoading />
            </NavigationTabsLoading>
        )
    }

    return (
        <Tabs variant='underline'>
            <Tabs.TabList className='mb-4 gap-7 bg-grey-100'>
                {tabs.filter(({ hasPermission }) => hasPermission).map((tab) => (
                    <SegmentNavLink
                        key={tab.key}
                        path={tab.path}
                        activeClassName='text-primary-500 border-b-2 border-primary-500 opacity-100'
                        className='flex items-center justify-center gap-2 py-3 font-semibold text-black opacity-60 hover:opacity-100'
                        exact={tab.exact}
                        data-testid={`navigation-tab-${tab.key}`}
                    >
                        {tab.icon}
                        {tab.title}
                    </SegmentNavLink>
                ))}
            </Tabs.TabList>

            {children}
        </Tabs>
    )
}

export default NavigationTabs
