

import { IsISO31661Alpha2, IsLocale, IsNotEmpty, IsString } from '@viterbit/web-app/shared/utils/ClassValidators'
import { Generic } from '@viterbit/web-app/shared/utils/generic.decorator'
import { Match } from '@viterbit/web-app/shared/utils/match.decorator'

import { isValidPassword, SignUpRedeemFields } from './SignUpRedeemShared'

class SignUpRedeemValidator implements SignUpRedeemFields {
    @IsString() 
        timezone: string

    @IsNotEmpty()
    @IsString()
        name: string

    @IsNotEmpty()
    @IsString()
        organizationName: string

    @IsNotEmpty()
    @Generic(isValidPassword, { message: 'Password is not valid' })
        password: string

    @IsString()
    @Match('password')
        confirmPassword: string

    @IsISO31661Alpha2()
    @IsNotEmpty()
    @IsString()
        country: string

    @IsNotEmpty()
    @IsLocale()
    @IsString()
        locale: string
}

export default SignUpRedeemValidator