import { useLeaveList } from '@viterbit/web-app/dataAccess/useCase/leave/useLeaveList'
import * as React from 'react'
import ListSkeleton from 'ui/src/components/List/ListSkeleton'

import FilterLeavesList from './FIlterLeavesList'
import ListLeaveByCluster from './ListLeaveByClusterParam'
import { LeavesClusters } from '../types'

type LeaveClusterListProps = {
    employeeId: string
    year: number
    leaveCluster?: LeavesClusters
    limit?: number
}

const LeaveClusterList = ({
    employeeId,
    year,
    leaveCluster,
    limit,
}: LeaveClusterListProps) => {
    const { data, isLoading } = useLeaveList({
        query: {
            employeeId,
            year,
        },
        enabled: true
    })

    if (isLoading || !data || !employeeId) {
        return <ListSkeleton count={1} icon primary secondary />
    }

    const newList = limit ? data.slice(0, limit) : data

    return leaveCluster ? (
        <FilterLeavesList leaves={newList} leaveCluster={leaveCluster} />
    ) : (
        <ListLeaveByCluster leaves={newList} />
    )
}

export default LeaveClusterList
