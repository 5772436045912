import { useInfiniteQuery } from '@tanstack/react-query'

import { FILES_QUERY_KEY } from '../../cacheKeys'
import { fileListPaginated } from '../../service/filesService'


type FileListConfig = {
    query: Parameters<typeof fileListPaginated>[0]
    enabled?: boolean
}

export const useFileListPaginated = (config: FileListConfig) => {
    const { query, enabled } = config || {}

    const result = useInfiniteQuery(
        [FILES_QUERY_KEY, query],
        ({ pageParam }) => fileListPaginated({
            ...query,
            page: pageParam
        }),
        {
            getNextPageParam: (lastPage) => lastPage ? lastPage.meta.nextPage : 1,
            initialData: () => ({
                pageParams: [1],
                pages: [],
            }),
            enabled,
        }
    )

    return {
        ...result,
        isLoading: result.isLoading || !result.data?.pages.length,
        total: result.data?.pages[0]?.meta?.total,
    }
}

