/* eslint-disable @typescript-eslint/no-explicit-any */
import { ErrorMessage } from '@hookform/error-message'
import * as React from 'react'

import clsxm from '../../lib'

export type FormErrorProps = {
    children?: React.ReactNode
    className?: string
}

export type FormErrorMessageProps = Omit<FormErrorProps, 'children'> & {
    name: string
    errors: any
}

const getMessage = (children: any) => {
    if (typeof children !== 'string') {
        return null
    }
    return children
}

export const FormError = ({ children, className }: FormErrorProps) => {
    // const message = getMessage(children)
    return (
        <div className={clsxm('text-danger-500', className)}>
            {children}
        </div>
    )
}

const FormErrorMessage = ({ name, errors, ...rest }: FormErrorMessageProps) => (
    <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }: {message: string}) => (
            <FormError {...rest}>{message}</FormError>
        )}
    />
)

export default FormErrorMessage
