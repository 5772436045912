/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SignUpCommand
 */
export interface SignUpCommand {
    /**
     * 
     * @type {string}
     * @memberof SignUpCommand
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpCommand
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpCommand
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpCommand
     */
    organizationName: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpCommand
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpCommand
     */
    locale: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpCommand
     */
    timezone: string;
}

/**
 * Check if a given object implements the SignUpCommand interface.
 */
export function instanceOfSignUpCommand(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "token" in value;
    isInstance = isInstance && "password" in value;
    isInstance = isInstance && "organizationName" in value;
    isInstance = isInstance && "country" in value;
    isInstance = isInstance && "locale" in value;
    isInstance = isInstance && "timezone" in value;

    return isInstance;
}

export function SignUpCommandFromJSON(json: any): SignUpCommand {
    return SignUpCommandFromJSONTyped(json, false);
}

export function SignUpCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): SignUpCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'token': json['token'],
        'password': json['password'],
        'organizationName': json['organizationName'],
        'country': json['country'],
        'locale': json['locale'],
        'timezone': json['timezone'],
    };
}

export function SignUpCommandToJSON(value?: SignUpCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'token': value.token,
        'password': value.password,
        'organizationName': value.organizationName,
        'country': value.country,
        'locale': value.locale,
        'timezone': value.timezone,
    };
}

