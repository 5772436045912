
import fetchWithoutPagination from '@viterbit/web-app/dataAccess/helper/fetchWithoutPagination'
import employeeMapper from '@viterbit/web-app/dataAccess/mapper/employee.mapper'
import LeaveMapper from '@viterbit/web-app/dataAccess/mapper/leave.mapper'
import paginationMetaMapper from '@viterbit/web-app/dataAccess/mapper/paginationMeta.mapper'
import { Leave } from '@viterbit/web-app/dataAccess/model/Leave.model'
import { queryClient } from '@viterbit/web-app/shared/lib/ReactQueryProvider'
import { Configuration, LeaveApi } from 'viterbit-api-sdk'

import { commonConfig } from './config'
import { employeeInvalidate } from './employeeService'
import { LEAVE_QUERY_KEY } from '../cacheKeys'

const api = new LeaveApi(new Configuration(commonConfig))

export const leaveInvalidate = () =>
    queryClient.invalidateQueries([LEAVE_QUERY_KEY])


export const leaveCreate = (...args: Parameters<typeof api.createLeaves>) =>
    api.createLeaves(...args).then((data) => {
        employeeInvalidate()
        leaveInvalidate()
        return data
    })

export const leaveUpdate = (...args: Parameters<typeof api.updateLeaves>) =>
    api.updateLeaves(...args).then((data) => {
        leaveInvalidate()
        return data
    })

export const leaveList = (body: Parameters<typeof api.listLeaves>[0]) =>
    fetchWithoutPagination(leaveListPaginated)({...body, pageSize: 10} as any) as Promise<Leave[]>

export const leaveListPaginated = (body: Parameters<typeof api.listLeaves>[0]) => api.listLeaves(body).then(({data, meta}) => ({
    meta: paginationMetaMapper(meta),
    data: data.map(LeaveMapper.toModel)
}))

export const leaveDelete = (body: Parameters<typeof api.deleteLeaves>[0]) =>
    api.deleteLeaves(body).then((data) => {
        leaveInvalidate()
        return data
    })

export const leaveDetail = (body: Parameters<typeof api.findLeaves>[0]) =>
    api.findLeaves(body).then(LeaveMapper.toModel)

export const leaveApprove = (body: Parameters<typeof api.approveLeaves>[0]) =>
    api.approveLeaves(body).then((data) => {
        leaveInvalidate()
        return data
    })

export const leaveReject = (body: Parameters<typeof api.rejectLeaves>[0]) =>
    api.rejectLeaves(body).then((data) => {
        leaveInvalidate()
        return data
    })

export const leaveCheck = (body: Parameters<typeof api.checkLeave>[0]) =>
    api.checkLeave(body)

export const leaveTimelineListPaginated = (...args: Parameters<typeof api.listLeavesTimeline>) =>
    api.listLeavesTimeline(...args).then(response => ({
        meta: paginationMetaMapper(response.meta!),
        data: response.data!.map(employeeMapper.toModel),
    }))
