/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { atom } from 'recoil'
import { getRecoil,setRecoil } from 'recoil-nexus'

export type ComplaintJWT = {
    id: string
    jwt: string
    password: string
}

const JWT_KEY = 'complaint_jwt'

const getPersistedJWT = () => {
    const jwtData = localStorage.getItem(JWT_KEY)

    if (!jwtData) {
        return null
    }

    const jwtDataParsed: ComplaintJWT = JSON.parse(jwtData)
    return jwtDataParsed
}

const storePersistedJWT = (data: ComplaintJWT | null) => {
    if (!data) {
        localStorage.removeItem(JWT_KEY)
        return
    }
    
    localStorage.setItem(JWT_KEY, JSON.stringify(data))
}

export const complaintState = atom<ComplaintJWT | null>({
    key: 'complaint',
    default: getPersistedJWT(),
})

export const setJWT = (data: ComplaintJWT) => {
    setRecoil(complaintState, data)
    storePersistedJWT(data)
}

export const cleanJWT = () => {
    storePersistedJWT(null)
    setRecoil(complaintState, null)
}

export const getJWT = () => getRecoil(complaintState)