type ComplaintDetailViewProps = {
    label: string | React.ReactNode
    value: string | React.ReactNode
} & React.HTMLAttributes<HTMLDivElement>

const ComplaintDetailItem = (props: ComplaintDetailViewProps) => {
    const { label, value, ...rest } = props

    return (
        <div className="px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-black">{label}</dt>
            <dd {...rest} className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{value}</dd>
        </div>
    )
}

export default ComplaintDetailItem
