/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation } from '@tanstack/react-query'

import { pendingFileAssignBulkInfo } from '../../service/pendingFilesService'

type UseAssignBulkPendingFileConfig = {
    onSuccess?: () => void
    onError?: (error: any) => void ;
}
export const useAssignBulkPendingFile = (config: UseAssignBulkPendingFileConfig) =>
    useMutation({
        ...config,
        mutationFn: pendingFileAssignBulkInfo,
        onSuccess: config.onSuccess,
        onError: config.onError,
    })

