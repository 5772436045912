import * as React from 'react'

import { BaseProps } from './util'
import { clsxm } from '../../lib/clsxm'

const classes = {
    th: 'px-6 py-3 text-left font-medium text-sm text-gray-500 dark:text-gray-100 break-words',
    variants: {
        default:
            'px-6 py-3 text-left  text-gray-500 dark:text-gray-100',
        flex: 'px-6 py-3 text-left font-semibold text-gray-500 dark:text-gray-100',
    },
    hover: 'hover:bg-gray-100/50 hover:dark:bg-gray-700/40',
    compact: 'px-3 py-2',
}

export type ThProps = BaseProps &
    React.HTMLAttributes<HTMLTableCellElement> & {
        compact?: boolean
    }

const Th = ({
    className,
    variant = 'default',
    isHoverable = true,
    compact,
    children,
    ...rest
}: ThProps) => (
    <th
        className={clsxm(
            classes.th,
            isHoverable && classes.hover,
            classes.variants[variant],
            compact && classes.compact,
            className
        )}
        {...rest}
    >
        {children}
    </th>
)

export default Th
