/* eslint-disable @typescript-eslint/no-explicit-any */



import { ConditionDateFields, ConditionDateOperator, ConditionDateOperators, ConditionFieldDate, ConditionFieldDateProperties, ConditionFieldDateTypes, ConditionTypeDate, ConditionValidationDate, isConditionDateField } from './ConditionDate.model'
import { ConditionFieldGeneric, ConditionFieldGenericProperties, ConditionTypeGeneric } from './ConditionGeneric.model'
import { ConditionFieldNumber, ConditionFieldNumberProperties, ConditionFieldNumberTypes, ConditionNumberFields, ConditionNumberOperator, ConditionNumberOperators, ConditionTypeNumber, ConditionValidationNumber, isConditionNumberField } from './ConditionNumber.model'
import { ConditionFieldString, ConditionFieldStringProperties, ConditionFieldStringTypes, ConditionStringFields, ConditionStringOperator, ConditionStringOperators, ConditionTypeString, ConditionValidationString, isConditionStringField } from './ConditionString.model'


export const ConditionFieldNames = [...ConditionStringFields, ...ConditionNumberFields, ...ConditionDateFields] as const
export type ConditionFieldName = typeof ConditionFieldNames[number]

export const ConditionOperators = [...ConditionStringOperators, ...ConditionNumberOperators, ...ConditionDateOperators] as const

export const EqualsOperators = ['equals', 'notEquals', ] as const
export const GenericOperators = [...EqualsOperators] as const
export type GenericOperator = typeof GenericOperators[number]
export type ConditionTypeBase <ParentType> = {
    [key in GenericOperator]?: ParentType
}

export const ConditionFieldBooleanTypes = ['boolean'] as const
export type ConditionFieldBooleanType = typeof ConditionFieldBooleanTypes[number]
export const ConditionFieldArrayTypes = ['array'] as const
export type ConditionFieldArrayType = typeof ConditionFieldArrayTypes[number]
export const ConditionFieldTypes = [...ConditionFieldStringTypes, ...ConditionFieldNumberTypes, ...ConditionFieldDateTypes, ...ConditionFieldBooleanTypes, ...ConditionFieldArrayTypes] as const
export type ConditionFieldType = typeof ConditionFieldTypes[number]
export const LogicalOperators = ['and', 'or'] as const
export type LogicalOperator = typeof LogicalOperators[number]

export type ConditionValidationBase <ParentName extends string, ParentValue> = {
    name: ParentName
    value: ParentValue[]
} | {
    name: GenericOperator
    value: any[]
}
export interface ConditionFieldBase <ParentName extends string, ParentStringType extends string, ConditionValidation extends ConditionValidationBase<any, any>> {
    name: ParentName
    type: ParentStringType
    required?: boolean
    showNodeDetail?: boolean
    validations?: ConditionValidation[]
}

export type ConditionType = ConditionTypeGeneric | ConditionTypeString | ConditionTypeNumber | ConditionTypeDate

export type ConditionFilter = {
    operator: LogicalOperator
    groups: ConditionGroup[]
}

export type ConditionGroup = {
    operator: LogicalOperator
    filters: ConditionRule[]
}

export type ConditionRule = {
    field: string
    operator: ConditionOperator
    value: string
}

export type ConditionField = ConditionFieldString | ConditionFieldNumber | ConditionFieldDate | ConditionFieldGeneric

export type ConditionValidation = ConditionValidationString | ConditionValidationNumber | ConditionValidationDate
export type ConditionOperator = ConditionStringOperator | ConditionNumberOperator | ConditionDateOperator | GenericOperator    

export const getConditionSchemaType = (conditionField: ConditionField) => {
    if (isConditionStringField(conditionField)) {
        return 'string'
    }
    if (isConditionDateField(conditionField)) {
        return 'date'
    }
    if (isConditionNumberField(conditionField)) {
        return 'number'
    }
    return null
}

export const getConditionDynamicType = (conditionField: ConditionField): ConditionFieldType | null => {
    if (isConditionStringField(conditionField)) {
        return ['string', 'rich_text'].includes(conditionField.type) ? conditionField.type : 'string' as any
    }
    if (isConditionDateField(conditionField)) {
        return 'date'
    }
    if (isConditionNumberField(conditionField)) {
        return conditionField.type
    }
    return null
}

export type ConditionFieldProperties = ConditionFieldStringProperties | ConditionFieldNumberProperties | ConditionFieldDateProperties | ConditionFieldGenericProperties
