/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ListMeta } from './ListMeta';
import {
    ListMetaFromJSON,
    ListMetaFromJSONTyped,
    ListMetaToJSON,
} from './ListMeta';
import type { PendingFileQueryResponse } from './PendingFileQueryResponse';
import {
    PendingFileQueryResponseFromJSON,
    PendingFileQueryResponseFromJSONTyped,
    PendingFileQueryResponseToJSON,
} from './PendingFileQueryResponse';

/**
 * 
 * @export
 * @interface ListPendingFileQueryResponse
 */
export interface ListPendingFileQueryResponse {
    /**
     * 
     * @type {ListMeta}
     * @memberof ListPendingFileQueryResponse
     */
    meta: ListMeta;
    /**
     * 
     * @type {Array<PendingFileQueryResponse>}
     * @memberof ListPendingFileQueryResponse
     */
    data: Array<PendingFileQueryResponse>;
}

/**
 * Check if a given object implements the ListPendingFileQueryResponse interface.
 */
export function instanceOfListPendingFileQueryResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "meta" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function ListPendingFileQueryResponseFromJSON(json: any): ListPendingFileQueryResponse {
    return ListPendingFileQueryResponseFromJSONTyped(json, false);
}

export function ListPendingFileQueryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListPendingFileQueryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'meta': ListMetaFromJSON(json['meta']),
        'data': ((json['data'] as Array<any>).map(PendingFileQueryResponseFromJSON)),
    };
}

export function ListPendingFileQueryResponseToJSON(value?: ListPendingFileQueryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'meta': ListMetaToJSON(value.meta),
        'data': ((value.data as Array<any>).map(PendingFileQueryResponseToJSON)),
    };
}

