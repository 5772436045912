
import { companyCreate } from '@viterbit/web-app/dataAccess/service/companyService'
import i18n from '@viterbit/web-app/i18n'
import BasicCreateFormDialog from '@viterbit/web-app/shared/form/BasicCreateFormDialog'
import ButtonCreate from '@viterbit/web-app/shared/form/ButtonCreate'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'


const CreateCompany = () => {
    const [open, setOpen] = React.useState(false)

    const navigate = useNavigate()

    const onClose = () => {
        setOpen(false)
    }

    return (
        <>
            <ButtonCreate
                collapsed
                title={i18n.t('settingCompanies:addCompany')}
                onClick={() => setOpen(true)}
            />

            <BasicCreateFormDialog
                isOpen={open}
                title={i18n.t('settingCompanies:addCompany')}
                onClose={onClose}
                label={i18n.t('settingCompanies:companyName')}
                placeholder={i18n.t('settingCompanies:enterCompanyName')}
                mutationConfig={{
                    onSuccess: (data) => {
                        navigate(`/settings/companies/${data.id}/details`)
                        onClose()
                    },
                    mutationFn: (data) => companyCreate({createCompanyCommand:data}),
                }}
            />
        </>
    )
}

export default CreateCompany
