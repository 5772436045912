import { classValidatorResolver } from '@hookform/resolvers/class-validator'
import { LeavePolicy } from '@viterbit/web-app/dataAccess/model/LeavePolicy.model'
import { LeaveReason } from '@viterbit/web-app/dataAccess/model/LeaveReason.model'
import { WEEK_DAYS } from '@viterbit/web-app/dataAccess/model/Week.model'
import { leavePolicyUpdate } from '@viterbit/web-app/dataAccess/service/leavePolicyService'
import i18n from '@viterbit/web-app/i18n'
import EmbeddedContent from '@viterbit/web-app/shared/behaviour/EmbeddedContent'
import AsideCardInfo from '@viterbit/web-app/shared/display/AsideCardInfo'
import Form from '@viterbit/web-app/shared/form/Form'
import SubmitButton from '@viterbit/web-app/shared/form/SubmitButton'
import { parseEventValue, parseInputValue } from '@viterbit/web-app/shared/utils/parsers'
import React from 'react'
import Dialog from 'ui/src/components/Dialog'
import {
    CheckboxButtonInput,
    CheckboxButtonInputGroup,
    FormInput,
    FormSwitcher
} from 'ui/src/components/Form'

import { LeavePolicyFormFields } from './DetailsLeavePolicies'
import DetailsLeavePoliciesValidator from './DetailsLeavePoliciesValidator'
import LeaveReasonItem from './LeaveReasonItem'
import LeaveReasonList from './LeaveReasonList'
import CreateLeaveReasonsView from './reasons/create/CreateLeaveReasonsView'
import DetailsLeaveReasonsView from './reasons/detail/DetailsLeaveReasonsView'

export type DetailsLeavePoliciesFormProps = {
    leavePolicy: LeavePolicy
}

const resolver = classValidatorResolver(DetailsLeavePoliciesValidator)

type EditLeaveReason = {
    leavePolicyId: string
    leaveReason: LeaveReason
}

const DetailsLeavePoliciesForm = ({
    leavePolicy,
}: DetailsLeavePoliciesFormProps) => {
    const [editLeaveReason, setEditLeaveReason] =  React.useState<EditLeaveReason | null>(null)
    const [createLeaveReason, setCreateLeaveReason] =  React.useState<boolean>(false)

    const WEEK_DAYS_LABELS = WEEK_DAYS.map((day) => i18n.t(`weekDay.${day}`))
    
    return (
        <>
            <Form<LeavePolicyFormFields>
                withTransition
                formConfig={{
                    resolver,
                    mode: 'all',
                    defaultValues: leavePolicy,
                }}
                mutationConfig={{
                    mutationKey: ['update-leave-policies'],
                    mutationFn: (data) =>
                        leavePolicyUpdate({
                            id: leavePolicy.id,
                            updateLeavePolicyCommand: data
                        }),
                }}
            >
                {(form, _, state) => {
                    const isBusinessDay = form.watch('businessDays')
                    return (
                        <>
                            <EmbeddedContent>
                                <SubmitButton form={state.formId}>{i18n.t('settingLeaves:save')}</SubmitButton>
                            </EmbeddedContent>

                            <AsideCardInfo
                                className='mb-4 border-b'
                                title={i18n.t('settingLeaves:basicInformation')}
                                description={i18n.t('settingLeaves:addNameToYourPolicy')}
                            >
                                <FormInput
                                    asterisk
                                    name='name'
                                    control={form.control}
                                    label={i18n.t('settingLeaves:name')}
                                    placeholder={i18n.t('settingLeaves:enterPolicyName')}
                                />
                                <FormInput
                                    textArea
                                    size='lg'
                                    name='description'
                                    control={form.control}
                                    label={i18n.t('settingLeaves:description')}
                                    placeholder={i18n.t('settingLeaves:enterPolicyDescription')}
                                />
                            </AsideCardInfo>

                            <AsideCardInfo
                                className='mb-4 border-b'
                                title={i18n.t('settingLeaves:dayCounters')}
                                description={i18n.t('settingLeaves:configureTotalLeaves')}
                            >
                                <FormInput
                                    type='number'
                                    asterisk
                                    name='daysPerYear'
                                    control={form.control}
                                    transform={{
                                        input: parseInputValue,
                                        output: parseEventValue,
                                    }}
                                    label={i18n.t('settingLeaves:totalDaysPerYear')}
                                    placeholder={i18n.t('settingLeaves:enterTotalDaysPerYear')}
                                />

                                <CheckboxButtonInputGroup
                                    asterisk
                                    label={i18n.t('settingLeaves:journeyDaysRange')}
                                    name='daysRange'
                                    control={form.control}
                                >
                                    {WEEK_DAYS.map((day, index) => (
                                        <CheckboxButtonInput
                                            {...form.register('daysRange')}
                                            key={day}
                                            value={day}
                                        >
                                            {WEEK_DAYS_LABELS[index]}
                                        </CheckboxButtonInput>
                                    ))}
                                </CheckboxButtonInputGroup>
                            </AsideCardInfo>

                            <AsideCardInfo
                                className='mb-4 border-b'
                                title={i18n.t('settingLeaves:businessDaysOrCalendarDays')}
                                description={
                                    <>
                                        <p>
                                            {i18n.t('settingLeaves:businessDaysDescription')}
                                        </p>
                                        <p>
                                            { i18n.t('settingLeaves:calendarDaysDescription')}
                                        </p>
                                        <p>
                                            {i18n.t('settingLeaves:chooseOptionThatFitsCompanyLeavePolicy')}
                                        </p>
                                    </>
                                }
                            >
                                <FormSwitcher
                                    className='flex-row mb-2'
                                    name='businessDays'
                                    control={form.control}
                                    label={
                                        isBusinessDay
                                            ? i18n.t('settingLeaves:businessDays')!
                                            : i18n.t('settingLeaves:calendarDays')!
                                    }
                                />
                            </AsideCardInfo>

                            <AsideCardInfo
                                className='pb-6 mb-4 border-b'
                                title={i18n.t('settingLeaves:reasons')}
                                description={
                                    <>
                                        <p>
                                            {i18n.t('settingLeaves:addYourOwnLeaveReasons')}
                                        </p>
                                        <p>
                                            {i18n.t('settingLeaves:setDefaultColorForEachLeaveReason')}
                                        </p>
                                    </>
                                }
                            >
                                <LeaveReasonList onClick={() => setCreateLeaveReason(true)}>
                                    {leavePolicy.reasons?.map((reason) => (
                                        <LeaveReasonItem
                                            key={reason.id}
                                            {...reason}
                                            onClick={() => setEditLeaveReason({ 
                                                leavePolicyId: leavePolicy.id,
                                                leaveReason: reason 
                                            })}
                                        />
                                    ))}
                                </LeaveReasonList>
                            </AsideCardInfo>
                        </>
                    )
                }}
            </Form>

            <Dialog size="xl" open={!!editLeaveReason} onClose={() => setEditLeaveReason(null)}>
                { editLeaveReason && <DetailsLeaveReasonsView {...editLeaveReason} onSuccess={() => setEditLeaveReason(null)} onCancel={() => setEditLeaveReason(null)}  /> }
            </Dialog>

            <CreateLeaveReasonsView
                isOpen={!!createLeaveReason}
                onClose={() => setCreateLeaveReason(false)}
                leavePolicyId={leavePolicy.id}
                onCreatedLeaveReason={(leaveReasonId) => {
                    setCreateLeaveReason(false)
                }}
            />

        </>
    )
}

export default DetailsLeavePoliciesForm



