
import * as React from 'react'
import { useFormContext } from 'react-hook-form'

import FormSelectCity from './FormSelectCity'
import FormSelectCountry from './FormSelectCountry'
import FormSelectState from './FormSelectState'
import splitComponents from '../utils/splitComponents'



type FormGeoDataProps = {
    children?: React.ReactNode
    className?: string
}

const validateChildren = (countries: any, states: any, cities: any) => {
    if (cities && (!states || !countries)) {
        throw new Error('You must provide a state and country when using a city')
    }

    if (states && !countries) {
        throw new Error('You must provide a country when using a state')
    }

    if (countries.length !== 1) {
        throw new Error('You must have a country selector')
    }

    if (states.length > 1) {
        throw new Error('You must have a state selector')
    }

    if (cities.length > 1) {
        throw new Error('You only can have one city selector')
    }
}

const FieldsetGeoData = (props: FormGeoDataProps) => {
    const { children } = props

    const { countries, states, cities, others } = React.useMemo(() => splitComponents(children, [
        { name: 'countries', component: FormSelectCountry },
        { name: 'cities', component: FormSelectCity },
        { name: 'states', component: FormSelectState },
    ]), [children])

    const [countryChildren] = countries
    const [stateChildren] = states
    const [cityChildren] = cities

    const form = useFormContext()
    const state = form.watch(stateChildren.props.name)
    const country = form.watch(countryChildren.props.name)
    const city = cityChildren && form.watch(cityChildren.props.name)

    const resetValues = React.useCallback((...types : any[]) => {
        if (types.includes('state') && stateChildren) {
            form.setValue(stateChildren.props.name, null)
        }
        if (types.includes('city') && cityChildren) {
            form.setValue(cityChildren.props.name, null)
        }
    }, [form, countryChildren, stateChildren, cityChildren])

    const onChangeCountry = React.useCallback(() => { resetValues('state', 'city')}, [resetValues])
    const onChangeState = React.useCallback(() => {resetValues('city')}, [resetValues])
    React.useEffect(() => { validateChildren(countries, states, cities) }, [countries, states, cities])

    return (
        <>
            {React.cloneElement(countryChildren, {
                rules: {
                    ...countryChildren.props.rules,
                    onChange: onChangeCountry,
                }
            })}
            {React.cloneElement(stateChildren, {
                key: `${country}`,
                query: { country },
                isDisabled: !country || stateChildren.props.isDisabled,
                defaultValue: state,
                rules: {
                    ...stateChildren.props.rules,
                    onChange: onChangeState,
                    setValueAs: (value: any) => value
                }
            })}
            {cityChildren && (
                React.cloneElement(cityChildren, {
                    key: `${country}-${state}`,
                    query: { state, country },
                    isDisabled: !country || !state,
                    defaultValue: city,
                    rules: {
                        ...cityChildren.props.rules,
                        setValueAs: (value: any) => value
                    }
                })
            )}
            {others}
        </>
    )
}

export default FieldsetGeoData
