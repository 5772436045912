import { useQuery } from '@tanstack/react-query'
import { TIME_TRACKING_QUERY_KEY } from '@viterbit/web-app/dataAccess/cacheKeys'
import { timeTrackingClockDetail } from '@viterbit/web-app/dataAccess/service/timeTrackingService'

type TeamDetailConfig = {
    query: Parameters<typeof timeTrackingClockDetail>[0]
    enabled?: boolean
}

export const useTimeTrackingClockDetail = ({ query, enabled }: TeamDetailConfig) =>
    useQuery({
        queryFn: () => timeTrackingClockDetail(query), 
        queryKey: [TIME_TRACKING_QUERY_KEY, 'clock-detail', query],
        enabled
    })

