/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react'

import { useOnScreen } from './useOnScreen'

type UseOnScreenElementConfig = {
    onVisible: () => void
    onHidden?: () => void
}

export function useOnScreenElement(config?: UseOnScreenElementConfig) {
    const { onVisible, onHidden } = config || {}
    const ref = React.useRef<HTMLDivElement>(null)
    const isBottom = useOnScreen(ref)

    React.useEffect(() => {
        if (isBottom) {
            onVisible?.()
        } else {
            onHidden?.()
        }
    }, [isBottom])

    return { ref, isBottom }
}
