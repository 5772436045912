import { useMutation } from '@tanstack/react-query'
import { timeTrackingDisapprove } from '@viterbit/web-app/dataAccess/service/timeTrackingService'

type TimeTrackingDisapproveConfig = {
    onSuccess?: () => void
    onError?: () => void
}

export const useTimeTrackingDisapprove = (config: TimeTrackingDisapproveConfig = {}) =>
    useMutation({
        ...config,
        mutationFn: timeTrackingDisapprove,
        onSuccess: config.onSuccess,
        onError: config.onError,
    })
