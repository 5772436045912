/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ToFromData2
 */
export interface ToFromData2 {
    /**
     * 
     * @type {string}
     * @memberof ToFromData2
     */
    to?: string;
    /**
     * 
     * @type {string}
     * @memberof ToFromData2
     */
    from?: string;
    /**
     * 
     * @type {string}
     * @memberof ToFromData2
     */
    fixed?: string;
}

/**
 * Check if a given object implements the ToFromData2 interface.
 */
export function instanceOfToFromData2(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ToFromData2FromJSON(json: any): ToFromData2 {
    return ToFromData2FromJSONTyped(json, false);
}

export function ToFromData2FromJSONTyped(json: any, ignoreDiscriminator: boolean): ToFromData2 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'to': !exists(json, 'to') ? undefined : json['to'],
        'from': !exists(json, 'from') ? undefined : json['from'],
        'fixed': !exists(json, 'fixed') ? undefined : json['fixed'],
    };
}

export function ToFromData2ToJSON(value?: ToFromData2 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'to': value.to,
        'from': value.from,
        'fixed': value.fixed,
    };
}

