/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { useCompanyListPaginated } from '@viterbit/web-app/dataAccess/useCase/company/useCompanyListPaginated'
import { useOnScreenElement } from '@viterbit/web-app/shared/behaviour/useOnScreenElement'
import BreakpointRedirection from '@viterbit/web-app/shared/navigation/BreakpointRedirection'
import * as React from 'react'
import List from 'ui/src/components/List'

import CompaniesItem from './CompaniesItem'


export type CompaniesListProps = {
    children?: React.ReactNode
}

const CompaniesList = ({ children }: CompaniesListProps) => {
    const { ref } = useOnScreenElement({
        onVisible: () => {
            hasNextPage && fetchNextPage()
        }
    })
    
    const {
        data,
        fetchNextPage,
        hasNextPage,
    } = useCompanyListPaginated()    

    const firstCompany = React.useMemo(() => data?.pages[0]?.data[0], [data])

    return (
        <>
            {firstCompany && <BreakpointRedirection matcherPath="/settings/companies" redirectPath={`/settings/companies/${firstCompany?.id}/details`} />}

            <List data-testid="company-list">
                {data!.pages.map(({ data: _data }) =>
                    _data.map((company) => (
                        <CompaniesItem key={company.id} company={company} />
                    ))
                )}
            </List>
        
            {children}

            <div ref={ref} />
        </>
    )
}

export default CompaniesList
