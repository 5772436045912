/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateJobPositionCommand,
  FindJobPositionQueryResponse,
  ListJobPositionQueryResponse,
  ResourceCreated,
  UpdateJobPositionCommand,
} from '../models/index';
import {
    CreateJobPositionCommandFromJSON,
    CreateJobPositionCommandToJSON,
    FindJobPositionQueryResponseFromJSON,
    FindJobPositionQueryResponseToJSON,
    ListJobPositionQueryResponseFromJSON,
    ListJobPositionQueryResponseToJSON,
    ResourceCreatedFromJSON,
    ResourceCreatedToJSON,
    UpdateJobPositionCommandFromJSON,
    UpdateJobPositionCommandToJSON,
} from '../models/index';

export interface CreateJobPositionRequest {
    createJobPositionCommand?: CreateJobPositionCommand;
}

export interface DeleteJobPositionRequest {
    id: string;
    body?: object;
}

export interface FindJobPositionRequest {
    id: string;
}

export interface ListJobPositionRequest {
    page?: number;
}

export interface UpdateJobPositionRequest {
    id: string;
    updateJobPositionCommand?: UpdateJobPositionCommand;
}

/**
 * 
 */
export class JobPositionApi extends runtime.BaseAPI {

    /**
     * Create a job position
     * Create a job position
     */
    async createJobPositionRaw(requestParameters: CreateJobPositionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/job-positions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateJobPositionCommandToJSON(requestParameters.createJobPositionCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Create a job position
     * Create a job position
     */
    async createJobPosition(requestParameters: CreateJobPositionRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.createJobPositionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a job position
     * Delete a job position
     */
    async deleteJobPositionRaw(requestParameters: DeleteJobPositionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteJobPosition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/job-positions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Delete a job position
     * Delete a job position
     */
    async deleteJobPosition(requestParameters: DeleteJobPositionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.deleteJobPositionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find a job position
     * Find a job position
     */
    async findJobPositionRaw(requestParameters: FindJobPositionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindJobPositionQueryResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling findJobPosition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/job-positions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindJobPositionQueryResponseFromJSON(jsonValue));
    }

    /**
     * Find a job position
     * Find a job position
     */
    async findJobPosition(requestParameters: FindJobPositionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindJobPositionQueryResponse> {
        const response = await this.findJobPositionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List job positions
     * List job positions
     */
    async listJobPositionRaw(requestParameters: ListJobPositionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListJobPositionQueryResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/job-positions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListJobPositionQueryResponseFromJSON(jsonValue));
    }

    /**
     * List job positions
     * List job positions
     */
    async listJobPosition(requestParameters: ListJobPositionRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListJobPositionQueryResponse> {
        const response = await this.listJobPositionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a job position
     * Update a job position
     */
    async updateJobPositionRaw(requestParameters: UpdateJobPositionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateJobPosition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/job-positions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateJobPositionCommandToJSON(requestParameters.updateJobPositionCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Update a job position
     * Update a job position
     */
    async updateJobPosition(requestParameters: UpdateJobPositionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.updateJobPositionRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
