import i18n, { Language } from '@viterbit/web-app/i18n'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)
dayjs.extend(duration)

type DateFormat = {
    DATE: string
    DATE_TIME: string
    DAY_WITH_MONTH: string
    MONTH: string
    TIME: string
}

const userLanguage = i18n.resolvedLanguage as Language || 'es'
const parsedLanguage = userLanguage.split('-')[0]

export const DATE_FORMATS: Record<Language, DateFormat> = {
    es: {
        DATE: 'DD/MM/YYYY',
        DATE_TIME: 'DD/MM/YYYY HH:mm',
        DAY_WITH_MONTH: 'D [de] MMMM',
        MONTH: 'MMMM',
        TIME: 'HH:mm',
    },
    en: {
        DATE: 'MM/DD/YYYY',
        DATE_TIME: 'MM/DD/YYYY HH:mm',
        DAY_WITH_MONTH: 'D [of] MMMM',
        MONTH: 'MMMM',
        TIME: 'HH:mm',
    },
}

export const DATE_FORMAT: DateFormat = DATE_FORMATS[parsedLanguage] || DATE_FORMATS.es

export const formatDate = (date?: dayjs.ConfigType, language: Language = userLanguage) =>
    new Intl.DateTimeFormat(language).format(dayjs(date).toDate())

export const formatDay = (date?: dayjs.ConfigType, language: Language = userLanguage) =>
    new Intl.DateTimeFormat(language, { day: 'numeric', month: 'short', year: 'numeric' }).format(dayjs(date).toDate())

export const formatDateTime = (date?: dayjs.ConfigType, language: Language = userLanguage) =>
    new Intl.DateTimeFormat(language, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
    }).format(dayjs(date).toDate())


export const formatMonthWithYear = (date?: dayjs.ConfigType, language: Language = userLanguage) =>
    new Intl.DateTimeFormat(language, { month: 'long', year: 'numeric' }).format(dayjs(date).toDate())

export const formatMonth = (date?: dayjs.ConfigType, language: Language = userLanguage) =>
    new Intl.DateTimeFormat(language, { month: 'long' }).format(dayjs(date).toDate())

export const formatTime = (date?: dayjs.ConfigType, language: Language = userLanguage) =>
    new Intl.DateTimeFormat(language, { minute: 'numeric', hour: 'numeric' }).format(dayjs(date).toDate())

export const formatDayWithMonth = (date?: dayjs.ConfigType, language: Language = userLanguage) =>
    new Intl.DateTimeFormat(language, { month: 'long', day: 'numeric' }).format(dayjs(date).toDate())

export const formatHumanized = (date?: dayjs.ConfigType, language: Language = userLanguage) => {
    const diff = dayjs.duration(dayjs().diff(dayjs(date)))

    return diff.locale(language).humanize()
}