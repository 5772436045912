import * as React from 'react'

import CalendarBase, {
    CalendarProps as BaseCalendarProps,
} from './CalendarBase'
import { isSameDate } from './utils'

type CalendarProps = {
    multipleSelection?: boolean
} & BaseCalendarProps

const Calendar = React.forwardRef<HTMLDivElement, CalendarProps>(
    (props, ref) => {
        const { multipleSelection, value, onChange, locale, ...rest } = props

        const handleChange = (date: Date) => {
            if (!multipleSelection) {
                return onChange(date)
            }

            const isSelected =
                Array.isArray(value) &&
                value.some((val) => isSameDate(val, date))

            return onChange(
                isSelected
                    ? value.filter((val) => !isSameDate(val, date))
                    : Array.isArray(value)
                        ? [...value, date]
                        : [date]
            )
        }

        return (
            <CalendarBase
                ref={ref}
                value={value}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onChange={handleChange as any}
                locale={locale || 'en'}
                {...rest}
            />
        )
    }
)

export default Calendar
