/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react'

import FormErrorMessage, { FormErrorMessageProps } from './FormErrorMessage'
import { clsxm } from '../../lib/clsxm'

const classes = {
    base: 'mb-4 relative flex flex-col w-full',
    label: 'flex items-center font-semibold',
    errorLabel: 'text-danger-500',
}

export type FormControlBaseProps = {
    label?: string | React.ReactNode
    labelWidth?: number
    children?: React.ReactNode
    asterisk?: boolean
    labelClass?: string
    labelStyle?: React.CSSProperties
    htmlFor?: string
    extra?: React.ReactNode | string
    style?: React.CSSProperties
    className?: string
    contentClassName?: string
    name: string
}

type FormControlProps = FormControlBaseProps & {
    errors: FormErrorMessageProps['errors']
}

const FormControl = (props: FormControlProps) => {
    const {
        extra,
        asterisk,
        labelClass,
        label,
        labelWidth,
        style = {},
        htmlFor,
        children,
        className = '',
        contentClassName = '',
        name,
        errors,
        ...rest
    } = props

    const formItemLabelWidth = labelWidth
    const getFormLabelLayoutClass = label ? `${label && 'pr-2'}` : 'pr-2'

    const formLabelClass = clsxm(
        classes.label,
        label && getFormLabelLayoutClass,
        label && 'mb-2',
        labelClass
    )
    const formLabelStyle = { ...style, ...{ minWidth: formItemLabelWidth } }

    return (
        <div className={clsxm(classes.base, className)} data-test="form-control">
            <label
                htmlFor={htmlFor}
                className={formLabelClass}
                style={formLabelStyle}
            >
                {asterisk && <span className='mr-1 text-danger-500'>*</span>}
                {label}
                {extra && <span className='ml-1'>{extra}</span>}
            </label>

            <div
                className={clsxm(
                    'relative flex w-full flex-col justify-center',
                    contentClassName
                )}
            >
                {children}

                <FormErrorMessage name={name} errors={errors} />
            </div>
        </div>
    )
}

export default FormControl
