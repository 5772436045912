/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateLeaveReasonCommand,
  FindLeaveReasonQueryResponse,
  ListLeaveReasonQueryResponse,
  ResourceCreated,
  UpdateLeaveReasonCommand,
} from '../models/index';
import {
    CreateLeaveReasonCommandFromJSON,
    CreateLeaveReasonCommandToJSON,
    FindLeaveReasonQueryResponseFromJSON,
    FindLeaveReasonQueryResponseToJSON,
    ListLeaveReasonQueryResponseFromJSON,
    ListLeaveReasonQueryResponseToJSON,
    ResourceCreatedFromJSON,
    ResourceCreatedToJSON,
    UpdateLeaveReasonCommandFromJSON,
    UpdateLeaveReasonCommandToJSON,
} from '../models/index';

export interface CreateLeavesReasonRequest {
    createLeaveReasonCommand?: CreateLeaveReasonCommand;
}

export interface DeleteLeaveReasonsRequest {
    id: string;
}

export interface FindLeaveReasonsRequest {
    id: string;
    include?: Array<string>;
}

export interface ListLeaveReasonsRequest {
    leavePolicyId: string;
    page?: number;
}

export interface UpdateLeaveReasonRequest {
    id: string;
    updateLeaveReasonCommand?: UpdateLeaveReasonCommand;
}

/**
 * 
 */
export class LeaveReasonApi extends runtime.BaseAPI {

    /**
     * Create a leave reason
     * Create a leave reason
     */
    async createLeavesReasonRaw(requestParameters: CreateLeavesReasonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core-hr/leave-reasons`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateLeaveReasonCommandToJSON(requestParameters.createLeaveReasonCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Create a leave reason
     * Create a leave reason
     */
    async createLeavesReason(requestParameters: CreateLeavesReasonRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.createLeavesReasonRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a leave reasons
     * Delete a leave reasons
     */
    async deleteLeaveReasonsRaw(requestParameters: DeleteLeaveReasonsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteLeaveReasons.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core-hr/leave-reasons/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Delete a leave reasons
     * Delete a leave reasons
     */
    async deleteLeaveReasons(requestParameters: DeleteLeaveReasonsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.deleteLeaveReasonsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find a leave reason
     * Find a leave reason
     */
    async findLeaveReasonsRaw(requestParameters: FindLeaveReasonsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindLeaveReasonQueryResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling findLeaveReasons.');
        }

        const queryParameters: any = {};

        if (requestParameters.include) {
            queryParameters['include'] = requestParameters.include;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core-hr/leave-reasons/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindLeaveReasonQueryResponseFromJSON(jsonValue));
    }

    /**
     * Find a leave reason
     * Find a leave reason
     */
    async findLeaveReasons(requestParameters: FindLeaveReasonsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindLeaveReasonQueryResponse> {
        const response = await this.findLeaveReasonsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch all leave reasons for the given company
     * List leave reasons
     */
    async listLeaveReasonsRaw(requestParameters: ListLeaveReasonsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListLeaveReasonQueryResponse>> {
        if (requestParameters.leavePolicyId === null || requestParameters.leavePolicyId === undefined) {
            throw new runtime.RequiredError('leavePolicyId','Required parameter requestParameters.leavePolicyId was null or undefined when calling listLeaveReasons.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.leavePolicyId !== undefined) {
            queryParameters['leavePolicyId'] = requestParameters.leavePolicyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core-hr/leave-reasons`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListLeaveReasonQueryResponseFromJSON(jsonValue));
    }

    /**
     * Fetch all leave reasons for the given company
     * List leave reasons
     */
    async listLeaveReasons(requestParameters: ListLeaveReasonsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListLeaveReasonQueryResponse> {
        const response = await this.listLeaveReasonsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a leave reason
     * Update a leave reason
     */
    async updateLeaveReasonRaw(requestParameters: UpdateLeaveReasonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateLeaveReason.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core-hr/leave-reasons/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateLeaveReasonCommandToJSON(requestParameters.updateLeaveReasonCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Update a leave reason
     * Update a leave reason
     */
    async updateLeaveReason(requestParameters: UpdateLeaveReasonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.updateLeaveReasonRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
