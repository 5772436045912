/* eslint-disable @typescript-eslint/no-explicit-any */
import fetchWithoutPagination from '@viterbit/web-app/dataAccess/helper/fetchWithoutPagination'
import employeeMapper from '@viterbit/web-app/dataAccess/mapper/employee.mapper'
import paginationMetaMapper from '@viterbit/web-app/dataAccess/mapper/paginationMeta.mapper'
import SummaryMapper from '@viterbit/web-app/dataAccess/mapper/summary.mapper'
import { Employee } from '@viterbit/web-app/dataAccess/model/Employee.model'
import { queryClient } from '@viterbit/web-app/shared/lib/ReactQueryProvider'
import { Configuration, EmployeeApi } from 'viterbit-api-sdk'

import { commonConfig } from './config'
import { EMPLOYEE_QUERY_KEY } from '../cacheKeys'

const api = new EmployeeApi(new Configuration(commonConfig))

export const employeeInvalidate = () =>
    queryClient.invalidateQueries([EMPLOYEE_QUERY_KEY])


export const employeeCreate = (body: Parameters<typeof api.createEmployees>[0]) =>
    api.createEmployees(body).then((data) => {
        employeeInvalidate()
        return data
    })

export const employeeUpdate = (body: Parameters<typeof api.updateEmployees>[0]) =>
    api.updateEmployees(body).then((data) => {
        employeeInvalidate()
        return data
    })

export const employeeList = (body: Parameters<typeof api.listEmployees>[0]) =>
    api.listEmployees(body).then(({data, meta}) => ({
        meta: paginationMetaMapper(meta),
        data: data.map(employeeMapper.toModel)
    }))

export const employeeListWithoutPagination = (body: Parameters<typeof api.listEmployees>[0]) =>
    fetchWithoutPagination(employeeList)({...body, pageSize: 10} as any) as Promise<Employee[]>

export const employeeDelete = (body: Parameters<typeof api.deleteEmployees>[0]) =>
    api.deleteEmployees(body).then((data) => {
        employeeInvalidate()
        return data
    })
    
export const employeeDetail = (body: Parameters<typeof api.fetchEmployee>[0]) =>
    api.fetchEmployee({
        include: [
            'leavePolicy',
            'company',
            'manager',
            'location',
            'account',
        ],
        ...body,
    }).then(employeeMapper.toModel)

export const assignJobPositionLevel = (body: Parameters<typeof api.assignEmployeeToJobPositionLevel>[0]) =>
    api.assignEmployeeToJobPositionLevel(body).then((data) => {
        employeeInvalidate()
        return data
    })

export const employeeLeaveAdjustment = (body: Parameters<typeof api.updateLeaveAdjustmentEmployees>[0]) =>
    api.updateLeaveAdjustmentEmployees(body).then((data) => {
        employeeInvalidate()
        return data
    })

export const employeeCounterList = (body: Parameters<typeof api.fetchCounterEmployee>[0]) =>
    api.fetchCounterEmployee(body).then(SummaryMapper.toModel)

