/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TimeTrackingEstimation } from './TimeTrackingEstimation';
import {
    TimeTrackingEstimationFromJSON,
    TimeTrackingEstimationFromJSONTyped,
    TimeTrackingEstimationToJSON,
} from './TimeTrackingEstimation';

/**
 * 
 * @export
 * @interface TimeTrackingEstimationQueryResponse
 */
export interface TimeTrackingEstimationQueryResponse {
    /**
     * 
     * @type {Array<TimeTrackingEstimation>}
     * @memberof TimeTrackingEstimationQueryResponse
     */
    estimations?: Array<TimeTrackingEstimation>;
}

/**
 * Check if a given object implements the TimeTrackingEstimationQueryResponse interface.
 */
export function instanceOfTimeTrackingEstimationQueryResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TimeTrackingEstimationQueryResponseFromJSON(json: any): TimeTrackingEstimationQueryResponse {
    return TimeTrackingEstimationQueryResponseFromJSONTyped(json, false);
}

export function TimeTrackingEstimationQueryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimeTrackingEstimationQueryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'estimations': !exists(json, 'estimations') ? undefined : ((json['estimations'] as Array<any>).map(TimeTrackingEstimationFromJSON)),
    };
}

export function TimeTrackingEstimationQueryResponseToJSON(value?: TimeTrackingEstimationQueryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'estimations': value.estimations === undefined ? undefined : ((value.estimations as Array<any>).map(TimeTrackingEstimationToJSON)),
    };
}

