import { Organization } from '@viterbit/web-app/dataAccess/model/Organization.model'
import Logo from '@viterbit/web-app/shared/display/Logo'
import * as React from 'react'

type ComplaintCompanyLogoProps = {
    organization: Organization
}

const ComplaintCompanyLogo = (props: ComplaintCompanyLogoProps) => {
    const { organization } = props

    if (!organization.logo) {
        return <Logo color='white' />
    }

    return (
        <img
            className='object-contain object-left h-20 rounded-md w-80'
            src={organization.logo.url}
            alt={`${organization.logo.name} logo`}
        />
    )
}

export default ComplaintCompanyLogo
