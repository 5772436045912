/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FindEmployeeQueryResponse } from './FindEmployeeQueryResponse';
import {
    FindEmployeeQueryResponseFromJSON,
    FindEmployeeQueryResponseFromJSONTyped,
    FindEmployeeQueryResponseToJSON,
} from './FindEmployeeQueryResponse';
import type { ListMeta } from './ListMeta';
import {
    ListMetaFromJSON,
    ListMetaFromJSONTyped,
    ListMetaToJSON,
} from './ListMeta';

/**
 * 
 * @export
 * @interface ListLeaveTimelineQueryResponse
 */
export interface ListLeaveTimelineQueryResponse {
    /**
     * 
     * @type {ListMeta}
     * @memberof ListLeaveTimelineQueryResponse
     */
    meta?: ListMeta;
    /**
     * 
     * @type {Array<FindEmployeeQueryResponse>}
     * @memberof ListLeaveTimelineQueryResponse
     */
    data?: Array<FindEmployeeQueryResponse>;
}

/**
 * Check if a given object implements the ListLeaveTimelineQueryResponse interface.
 */
export function instanceOfListLeaveTimelineQueryResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ListLeaveTimelineQueryResponseFromJSON(json: any): ListLeaveTimelineQueryResponse {
    return ListLeaveTimelineQueryResponseFromJSONTyped(json, false);
}

export function ListLeaveTimelineQueryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListLeaveTimelineQueryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'meta': !exists(json, 'meta') ? undefined : ListMetaFromJSON(json['meta']),
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(FindEmployeeQueryResponseFromJSON)),
    };
}

export function ListLeaveTimelineQueryResponseToJSON(value?: ListLeaveTimelineQueryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'meta': ListMetaToJSON(value.meta),
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(FindEmployeeQueryResponseToJSON)),
    };
}

