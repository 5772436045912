import { useQuery } from '@tanstack/react-query'

import { TEAM_QUERY_KEY } from '../../cacheKeys'
import { teamMemberList } from '../../service/teamService'

type TeamMemberConfig = {
    query: Parameters<typeof teamMemberList>[0]
    enabled?: boolean
}

export const useTeamMemberList = ({ query, enabled }: TeamMemberConfig) =>
    useQuery({
        queryFn: () => teamMemberList(query), 
        queryKey: [TEAM_QUERY_KEY, 'member-list', query],
        enabled
    })

