/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmployeeAddress
 */
export interface EmployeeAddress {
    /**
     * Employee address
     * @type {string}
     * @memberof EmployeeAddress
     */
    address?: string | null;
    /**
     * Employee city
     * @type {string}
     * @memberof EmployeeAddress
     */
    city?: string | null;
    /**
     * Employee country
     * @type {string}
     * @memberof EmployeeAddress
     */
    country?: string | null;
    /**
     * Employee postal code
     * @type {string}
     * @memberof EmployeeAddress
     */
    postalCode?: string | null;
    /**
     * Employee state
     * @type {string}
     * @memberof EmployeeAddress
     */
    state?: string | null;
}

/**
 * Check if a given object implements the EmployeeAddress interface.
 */
export function instanceOfEmployeeAddress(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EmployeeAddressFromJSON(json: any): EmployeeAddress {
    return EmployeeAddressFromJSONTyped(json, false);
}

export function EmployeeAddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeeAddress {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'address': !exists(json, 'address') ? undefined : json['address'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'state': !exists(json, 'state') ? undefined : json['state'],
    };
}

export function EmployeeAddressToJSON(value?: EmployeeAddress | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address': value.address,
        'city': value.city,
        'country': value.country,
        'postalCode': value.postalCode,
        'state': value.state,
    };
}

