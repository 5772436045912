import * as React from 'react'
import { useRecoilValueLoadable } from 'recoil'

import { PermissionTreeParams, selectPermissionState } from '../state/permissionState'
import { PermissionAction } from '../../dataAccess/model/PermissionAction.model'

export type HasPermissionConfig = {
    action: PermissionAction
    id: string | string[]
}

const useHasPermission = (config: PermissionTreeParams) => {
    const recoilValueLoadable = useRecoilValueLoadable(selectPermissionState(config))

    const hasPermission = React.useCallback((action: PermissionAction, ...ids: string[]) => {
        const hasPermission = ids.every((id) => recoilValueLoadable.contents[action]?.[id])
        return hasPermission
    }, [recoilValueLoadable])

    return React.useMemo(() => ({
        hasPermission,
        isLoading: recoilValueLoadable.state === 'loading',
        isError: recoilValueLoadable.state === 'hasError',
        isSuccess: recoilValueLoadable.state === 'hasValue',
    }), [recoilValueLoadable, hasPermission])
}

export default useHasPermission
