
import i18n from '@viterbit/web-app/i18n'
import * as React from 'react'
import { Api } from 'types/Api'
import Alert from 'ui/src/components/Alert'
import clsxm from 'ui/src/lib'

export type ApiErrorMessageProps = {
    children?: React.ReactNode
    className?: string
    error: Error | null | unknown
}

export const isErrorMissing = (error: Api.ResponseError | null | unknown) =>
    !error || !Object.values(error).length


const getKey = (error: Api.ResponseError) => {
    if (isErrorMissing(error)) return 'default'

    return [400, 401, 500].includes(error.status) ? error.status : 'default'
}

export const getErrorMessage = (error: Api.ResponseError) => {
    if (isErrorMissing(error)) return ''

    if (error.data?.errorCode && i18n.t(`serverValidation:${error.data?.errorCode}`) !== error.data?.errorCode) {
        return i18n.t(`serverValidation:${error.data?.errorCode}`)
    }

    if (error.status === 400) {
        return i18n.t('common:components.apiErrorMessage.message.400')
    }

    return i18n.t(`common:components.apiErrorMessage.message.${getKey(error)}`)
}

export const getErrorTitle = (error: Api.ResponseError) => {
    if (!error || !Object.values(error).length) return ''

    return i18n.t(`common:components.apiErrorMessage.title.${getKey(error)}`)
}

const ApiErrorMessage = ({ error, className }: ApiErrorMessageProps) => {
    if (isErrorMissing(error)) return null

    const apiError = error as Api.ResponseError
    const errorMessage = getErrorMessage(apiError)
    const errorTitle = getErrorTitle(apiError)

    return (
        <Alert type='danger' title={errorTitle} className={clsxm('mb-4', className)} showIcon>
            {errorMessage}
        </Alert>
    )
}


export default ApiErrorMessage
