/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/function-component-definition */
import { classValidatorResolver } from '@hookform/resolvers/class-validator'
import { WorkSchedulePeriod } from '@viterbit/web-app/dataAccess/model/WorkSchedule.model'
import i18n from '@viterbit/web-app/i18n'
import Form from '@viterbit/web-app/shared/form/Form'
import FormDatePicker from '@viterbit/web-app/shared/form/FormDatePicker'
import SubmitButton from '@viterbit/web-app/shared/form/SubmitButton'
import { DATE_FORMAT, formatMonth } from '@viterbit/web-app/shared/utils/dateFormat'
import * as React from 'react'
import Button from 'ui/src/components/Button'
import Dialog from 'ui/src/components/Dialog'

import WorkScheduleDetailPeriodCreateValidation from './WorkScheduleDetailPeriodCreateValidator'

export type PeriodCreateFields = Pick<WorkSchedulePeriod, 'startDate' | 'endDate'>

const resolver = classValidatorResolver(WorkScheduleDetailPeriodCreateValidation)

function WorkScheduleDetailPeriodCreateDialog({
    isOpen,
    onClose,
    onConfirm,
}:  {
    isOpen: boolean
    onClose?: () => void
    onConfirm: (data: PeriodCreateFields) => void
}) {
    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            title={i18n.t('buttons.cancel')}
        >
            <Form<PeriodCreateFields> 
                withTransition
                formConfig={{
                    resolver,
                    mode: 'all',
                }}
                mutationConfig={{
                    mutationFn: (data) => {
                        onConfirm(data)
                    },
                } as any}
            >
                {
                    (formMethods) => (
                        <>
                            <div className='flex gap-2 border-b'>
                                <FormDatePicker
                                    asterisk
                                    name='startDate'
                                    label={i18n.t('settingWorkSchedule:fields.startDate.label')}
                                    placeholder={i18n.t('settingWorkSchedule:fields.startDate.placeholder')}
                                    monthLabelFormat={date => formatMonth(date)}
                                    yearLabelFormat={() => ''}
                                    maxLevel='year'
                                    valueFormat={DATE_FORMAT.DAY_WITH_MONTH}
                                    control={formMethods.control}
                                />
                                <FormDatePicker
                                    asterisk
                                    name='endDate'
                                    label={i18n.t('settingWorkSchedule:fields.endDate.label')}
                                    placeholder={i18n.t('settingWorkSchedule:fields.endDate.placeholder')}
                                    monthLabelFormat={date => formatMonth(date)}
                                    yearLabelFormat={() => ''}
                                    maxLevel='year'
                                    valueFormat={DATE_FORMAT.DAY_WITH_MONTH}
                                    control={formMethods.control}
                                />
                            </div>
                            <Button onClick={onClose}>{i18n.t('buttons.cancel')}</Button>
                            <SubmitButton data-testid="work-schedule-create-confirm">{i18n.t('buttons.create')}</SubmitButton>
                        </>
                    )
                }
            </Form>
        </Dialog>
    )
}

export default WorkScheduleDetailPeriodCreateDialog