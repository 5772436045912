/* eslint-disable @typescript-eslint/no-explicit-any */
import { Leave } from '@viterbit/web-app/dataAccess/model/Leave.model'
import * as React from 'react'
import { useImmer } from 'use-immer'

import EmployeeLeaveDialog from './LeaveDialog'

type ActionValues = Partial<Leave>

export type LeaveAction = {
    isCreate: boolean
    isUpdate: boolean
    defaultValues?: ActionValues
    employeeId: string | null
}

export const useLeaveDialogState: LeaveAction = {
    isCreate: false,
    isUpdate: false,
    defaultValues: {},
    employeeId: null,
}

const useLeaveDialog = () => {
    const [action, setAction] = useImmer<LeaveAction>(useLeaveDialogState)

    const resetAction = React.useCallback(() => {
        setAction((draft) => {
            Object.assign(draft, useLeaveDialogState)
        })
    }, [])

    const onItemClicked = React.useCallback((employeeId: string, defaultValues?: ActionValues) => {
        if (defaultValues?.id) {
            setAction((draft) => {
                draft.isUpdate = true
                draft.defaultValues = defaultValues || {}
                draft.employeeId = employeeId
            })
        } else {
            setAction((draft) => {
                draft.isCreate = true
                draft.defaultValues = defaultValues || {}
                draft.employeeId = employeeId
            })
        }
    }, [])

    const dialog = React.useMemo(() => <EmployeeLeaveDialog action={action} onClose={resetAction} />, [resetAction, action])

    return {
        action,
        resetAction,
        onItemClicked,
        dialog
    }
}

export default useLeaveDialog