
import { Tabs } from '@mantine/core'
import i18n from '@viterbit/web-app/i18n'
import * as React from 'react'

import { ClusterContext } from './ClusterProvider'
import { LeavesClusters } from '../types'

const LeaveClusterTabs = () => {
    const { cluster, onChange } = React.useContext(ClusterContext)

    return (
        <Tabs
            defaultValue={cluster}
            onTabChange={onChange}
        >
            <Tabs.List grow >
                <Tabs.Tab className='capitalize' value={LeavesClusters.UPCOMING} data-testid="tab-upcoming">
                    {i18n.t('leaves:upcoming')}
                </Tabs.Tab>

                <Tabs.Tab className='capitalize' value={LeavesClusters.PAST} data-testid="tab-past">
                    {i18n.t('leaves:past')}
                </Tabs.Tab>

                <Tabs.Tab className='capitalize' value={LeavesClusters.REJECT} data-testid="tab-rejected">
                    {i18n.t('leaves:rejected')}
                </Tabs.Tab>
            </Tabs.List>
        </Tabs>
    )
}

export default LeaveClusterTabs
