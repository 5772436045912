/* eslint-disable @typescript-eslint/no-explicit-any */
import omit from 'lodash/omit'
import pick from 'lodash/pick'

import { IMapper } from './__common'
import leaveReasonMapper from './leaveReason.mapper'
import { EmployeeCounterResponse } from '../dto/employees'
import { DayConsumedPerReason, Summary } from '../model/Summary.model'

// TODO: use when backend send plain array instead of object
const summaryLeaveReasonMapper = (data: DayConsumedPerReason) => {
    const leaveReasonFields = ['name', 'color']
    const counterData = omit(data, leaveReasonFields)
    const leaveReasonData = pick(data, leaveReasonFields)
    const mappedReasonData = pick(
        leaveReasonMapper.toModel(leaveReasonData as any),
        leaveReasonFields
    )
    return { ...mappedReasonData, ...counterData }
}

const SummaryMapper: IMapper<Summary, EmployeeCounterResponse> = {
    toModel: (dto) => ({
        usedTotalDays: dto.usedTotalDays,
        totalDays: dto.totalDays,
        leavePolicyDays: dto.leavePolicyDays,
        usedLeavePolicyDays: dto.usedLeavePolicyDays,
        carriedDays: dto.carriedDays,
        usedCarriedDays: dto.usedCarriedDays,
        availableDays: dto.availableDays,
        daysConsumedPerReason: Object.values(dto.daysConsumedPerReason).map(x => ({
            ...x,
            total: x.total || 0,
            totalDays: x.totalDays || 0,
        })) as any,
    }),
    toDto: () => {
        throw new Error('Model to DTO not implemented')
    },
}
export default SummaryMapper
