/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WorkSchedulePeriod } from './WorkSchedulePeriod';
import {
    WorkSchedulePeriodFromJSON,
    WorkSchedulePeriodFromJSONTyped,
    WorkSchedulePeriodToJSON,
} from './WorkSchedulePeriod';

/**
 * 
 * @export
 * @interface FindWorkScheduleQueryResponse
 */
export interface FindWorkScheduleQueryResponse {
    /**
     * 
     * @type {string}
     * @memberof FindWorkScheduleQueryResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof FindWorkScheduleQueryResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof FindWorkScheduleQueryResponse
     */
    type: string;
    /**
     * 
     * @type {Array<WorkSchedulePeriod>}
     * @memberof FindWorkScheduleQueryResponse
     */
    periods: Array<WorkSchedulePeriod> | null;
    /**
     * 
     * @type {WorkSchedulePeriod}
     * @memberof FindWorkScheduleQueryResponse
     */
    mainPeriod: WorkSchedulePeriod;
}

/**
 * Check if a given object implements the FindWorkScheduleQueryResponse interface.
 */
export function instanceOfFindWorkScheduleQueryResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "periods" in value;
    isInstance = isInstance && "mainPeriod" in value;

    return isInstance;
}

export function FindWorkScheduleQueryResponseFromJSON(json: any): FindWorkScheduleQueryResponse {
    return FindWorkScheduleQueryResponseFromJSONTyped(json, false);
}

export function FindWorkScheduleQueryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindWorkScheduleQueryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'type': json['type'],
        'periods': (json['periods'] === null ? null : (json['periods'] as Array<any>).map(WorkSchedulePeriodFromJSON)),
        'mainPeriod': WorkSchedulePeriodFromJSON(json['mainPeriod']),
    };
}

export function FindWorkScheduleQueryResponseToJSON(value?: FindWorkScheduleQueryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'type': value.type,
        'periods': (value.periods === null ? null : (value.periods as Array<any>).map(WorkSchedulePeriodToJSON)),
        'mainPeriod': WorkSchedulePeriodToJSON(value.mainPeriod),
    };
}

