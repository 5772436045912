/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Flex, Switch, Text, Tooltip } from '@mantine/core'
import {
    IconUserCheck,
    IconUserExclamation,
    IconUserOff,
} from '@tabler/icons-react'
import { Employee } from '@viterbit/web-app/dataAccess/model/Employee.model'
import { PermissionAction } from '@viterbit/web-app/dataAccess/model/PermissionAction.model'
import { useEmployeeListPaginated } from '@viterbit/web-app/dataAccess/useCase/employee/useEmployeeListPaginated'
import i18n from '@viterbit/web-app/i18n'
import EmployeeAvatar from '@viterbit/web-app/shared/employee/EmployeeAvatar'
import { useUser } from '@viterbit/web-app/shared/employee/useUser'
import IsGranted from '@viterbit/web-app/shared/permission/IsGranted'
import { FILTER_MODE_OPTIONS_TO_OPERATOR, STRING_COLUMN_FILTER_MODE_OPTIONS } from '@viterbit/web-app/shared/utils/filters'
import {
    MantineReactTable,
    MRT_ColumnDef,
    MRT_ColumnFiltersState,
    MRT_FilterOption,
    MRT_ShowHideColumnsButton,
    MRT_SortingState,
    MRT_ToggleFiltersButton,
    MRT_Virtualizer,
} from 'mantine-react-table'
import { MRT_Localization_ES } from 'mantine-react-table/locales/es'
import { useState } from 'react'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import Skeleton from 'ui/src/components/Skeleton'
import { FilterGroups, LogicalOperator } from 'viterbit-api-sdk'

const EmployeeListDetail = () => {
    const navigate = useNavigate()
    const user = useUser()

    const tableContainerRef = React.useRef<HTMLDivElement>(null) //we can get access to the underlying TableContainer element and react to its scroll events
    const rowVirtualizerInstanceRef = React.useRef<MRT_Virtualizer<HTMLDivElement, HTMLTableRowElement>>(null) //we can get access to the underlying Virtualizer instance and call its scrollToIndex method
    
    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([])
    const [globalFilter, setGlobalFilter] = useState('')
    const [archived, setArchived] = useState(false)
    const [sorting, setSorting] = useState<MRT_SortingState>([])
    
    const columns: MRT_ColumnDef<Employee>[] = [
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        {
            accessorKey: 'avatar',
            Cell: ({ row: {original: employee} }) => (
                <div className='flex items-center'>
                    <EmployeeAvatar employee={employee} />
                </div>
            ),
            enableSorting: false,
            enableColumnFilter: false,
            enableColumnDragging: false,
            enableColumnActions: false,
            minSize: 30,
            maxSize: 30,
        },
        {
            header: i18n.t('employees:dashboard.table.columns.name'),
            accessorKey: 'name',
            columnFilterModeOptions: STRING_COLUMN_FILTER_MODE_OPTIONS,
            filterFn: 'contains',
            enableColumnActions: false,
            size: 60,
        },
        {
            header: i18n.t('employees:dashboard.table.columns.surname'),
            accessorKey: 'surname',
            columnFilterModeOptions: STRING_COLUMN_FILTER_MODE_OPTIONS,
            filterFn: 'contains',
            enableColumnActions: false,
            size: 80,
        },
        {
            header: i18n.t('employees:dashboard.table.columns.email'),
            accessorKey: 'email',
            columnFilterModeOptions: STRING_COLUMN_FILTER_MODE_OPTIONS,
            filterFn: 'contains',
            enableColumnActions: false,
            size: 110,
        },
        {
            header: i18n.t('employees:dashboard.table.columns.alias'),
            accessorKey: 'jobPositionAlias',
            Cell: ({ row: {original: employee} }) => {
                if (employee.jobPositionAlias) return employee.jobPositionAlias
                if (employee.jobPosition?.name && employee.jobPositionLevel?.name)
                    return `${employee.jobPosition.name} ${employee.jobPositionLevel.name}`
                return null
            },
            enableSorting: false,
            enableColumnFilter: false,
            enableColumnActions: false,
            size: 80,
        },
        {
            header: i18n.t('employees:dashboard.table.columns.location'),
            accessorKey: 'location.name',
            enableSorting: false,
            enableColumnFilter: false,
            enableColumnActions: false,
            size: 80,
        },
        {
            header: i18n.t('employees:dashboard.table.columns.account'),
            accessorKey: 'account',
            Cell: ({ row: {original: employee} }) => {
                const { Icon, className, label } = {
                    inactive: {Icon: IconUserOff, className: 'text-grey-400', label: i18n.t('employees:dashboard.table.columns.accountMessage.inactive')},
                    active: {Icon: IconUserCheck, className: 'text-success-600', label: i18n.t('employees:dashboard.table.columns.accountMessage.active')},
                    pending: {Icon: IconUserExclamation, className: 'text-warning-300', label: i18n.t('employees:dashboard.table.columns.accountMessage.pending')},
                }[employee.status]

                return (
                    <Tooltip withinPortal label={label} position='left'>
                        <Icon
                            size={22}
                            strokeWidth={2}
                            className={className}
                        />
                    </Tooltip>
                )
            },
            mantineTableHeadCellProps: {
                align: 'right',
            },
            mantineTableBodyCellProps: {
                align: 'right',
            },
            enableSorting: false,
            enableColumnFilter: false,
            enableColumnDragging: false,
            enableColumnActions: false,
            minSize: 30,
            maxSize: 30,
        },
        
    ]

    const [columnFilterFns, setColumnFilterFns] = useState<Record<string, MRT_FilterOption>>(
        Object.fromEntries(
            columns
                .filter(x => x.enableColumnFilter !== false)
                .map(({accessorKey, filterFn, columnFilterModeOptions}) => ([
                    accessorKey,
                    filterFn || columnFilterModeOptions?.[0] || 'contains'
                ]))
        )
    )

    const fixedFilters = React.useMemo<FilterGroups|undefined>(() => {
        if (!columnFilters.length) return undefined
        return {
            operator: LogicalOperator.And,
            groups: [
                {
                    operator: LogicalOperator.And,
                    filters: columnFilters.map(({id, value}) => ({
                        field: id,
                        operator: FILTER_MODE_OPTIONS_TO_OPERATOR[columnFilterFns[id]],
                        value: value as string,
                    }))
                }
            ],
        }
    }, [columnFilters, columnFilterFns])

    const { data, fetchNextPage, isError, isFetching, isFetchingNextPage } = useEmployeeListPaginated({
        query: {
            search: globalFilter || undefined,
            archived,
            orderBy: Object.fromEntries(sorting.map(({id, desc}) => [id, desc ? 'desc' : 'asc'])),
            filters: fixedFilters,
            include: ['location', 'jobPosition', 'jobPositionLevel', 'avatar', 'contractInformation'],
        },
        keepPreviousData: true,
        refetchOnWindowFocus: false,
    })


    const flatData = React.useMemo(
        () => data?.pages.flatMap((page) => page.data) ?? [],
        [data],
    )

    const totalDBRowCount = data?.pages?.[0]?.meta?.total ?? 0
    const totalFetched = flatData.length

    const fetchMoreOnBottomReached = React.useCallback(
        (containerRefElement?: HTMLDivElement | null) => {
            if (containerRefElement) {
                const { scrollHeight, scrollTop, clientHeight } = containerRefElement
                //once the user has scrolled within 400px of the bottom of the table, fetch more data if we can
                if (
                    scrollHeight - scrollTop - clientHeight < 400 &&
              !isFetching &&
              totalFetched < totalDBRowCount
                ) {
                    fetchNextPage()
                }
            }
        },
        [fetchNextPage, isFetching, totalFetched, totalDBRowCount],
    )
    
    //scroll to top of table when sorting or filters change
    React.useEffect(() => {
        if (rowVirtualizerInstanceRef.current) {
            // rowVirtualizerInstanceRef.current?.scrollToIndex(0)
        }
    }, [sorting, columnFilters, globalFilter])

    //a check on mount to see if the table is already scrolled to the bottom and immediately needs to fetch more data
    React.useEffect(() => {
        fetchMoreOnBottomReached(tableContainerRef.current)
    }, [fetchMoreOnBottomReached])

    return (
        <MantineReactTable
            columns={columns}
            data={flatData}
            rowCount={totalDBRowCount}
            positionGlobalFilter='left'
            localization={MRT_Localization_ES}
            
            mantineTableProps={{
                verticalSpacing: 'xs',
            }}
            initialState={{
                showGlobalFilter: true,
                density: 'xs',
            }}
            state={{
                columnFilters,
                columnFilterFns,
                globalFilter,
                showGlobalFilter: true,
                showAlertBanner: isError,
                showSkeletons: isFetching && !isFetchingNextPage,
                showProgressBars: isFetchingNextPage,
                sorting,
            }}

            enablePagination={false}
            enableRowVirtualization
            enableColumnFilterModes

            manualFiltering
            manualSorting
            onColumnFiltersChange={setColumnFilters}
            onGlobalFilterChange={setGlobalFilter}
            onSortingChange={setSorting}
            onColumnFilterFnsChange={setColumnFilterFns}
            
            mantineToolbarAlertBannerProps={
                isError
                    ? {
                        color: 'error',
                        children: 'Error loading data',
                    }
                    : undefined
            }

            mantineTableBodyRowProps={({ row }) => ({
                onClick: () => navigate(`/employees/list/${row.original.id}`),
                sx: {
                    cursor: 'pointer',
                },
                'data-testid': row.original.id && `employees-list-row-${row.index}`
            })}

            mantineTableContainerProps={{
                ref: tableContainerRef,
                sx: { height: 'calc(100vh - 380px)' }, //give the table a max height
                onScroll: (
                    event: React.UIEvent<HTMLDivElement>, //add an event listener to the table container element
                ) => fetchMoreOnBottomReached(event.target as HTMLDivElement),
            }}

            renderTopToolbarCustomActions={({ table }) => (
                <IsGranted
                    action={PermissionAction.CORE_EMPLOYEE_CREATE}
                    id={user.organizationId}
                >
                    <div className="flex items-center justify-end flex-1 p-2">
                        <Switch
                            wrapperProps={{
                                'data-testid': 'employees-list-archived-toggle'
                            }}
                            onChange={(event) => setArchived(event.currentTarget.checked)}
                            labelPosition="left"
                            label={i18n.t('employees:dashboard.table.showInactive')}
                        />
                    </div>
                </IsGranted>
            )}
            
            renderToolbarInternalActions={({ table }) => (
                <Flex gap="xs" align="center" className='ml-2'>
                    <MRT_ToggleFiltersButton table={table} />
                    <MRT_ShowHideColumnsButton table={table} />
                </Flex>
            )}

            renderBottomToolbarCustomActions={() => (
                isFetching && !isFetchingNextPage ? (
                    <Skeleton className="h-[14px] w-28 mt-2" />
                ) : (
                    <Text>
                        {i18n.t('employees:dashboard.table.bottomToolbar', {
                            total: totalDBRowCount,
                            fetched: totalFetched,
                        })}
                    </Text>
                )
            )}

            mantinePaperProps={{
                withBorder: false,
                className: 'px-3 py-2'
            }}

            mantineProgressProps={({ isTopToolbar }) => ({
                color: 'primary',
                size: 'xs',
                sx: {
                    display: isTopToolbar ? 'none' : 'block', //hide bottom progress bar
                },
            })}

            rowVirtualizerInstanceRef={rowVirtualizerInstanceRef} //get access to the virtualizer instance
            rowVirtualizerProps={{ overscan: 10 }}
        />        
    )
}

export default EmployeeListDetail
