import { useQuery } from '@tanstack/react-query'
import { LEAVE_POLICY_QUERY_KEY } from '@viterbit/web-app/dataAccess/cacheKeys'
import { leavePolicyDetail } from '@viterbit/web-app/dataAccess/service/leavePolicyService'


type LeavePolicyDetailConfig = {
    query: Parameters<typeof leavePolicyDetail>[0]
    enabled?: boolean
}

export const useLeavePolicyDetail = ({ query, enabled }: LeavePolicyDetailConfig) =>
    useQuery({
        queryFn: () => leavePolicyDetail(query), 
        queryKey: [LEAVE_POLICY_QUERY_KEY, 'detail', query],
        enabled
    })

