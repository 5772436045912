/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { leaveTimelineListPaginated } from '@viterbit/web-app/dataAccess/service/leavesService'
import * as React from 'react'
import clsxm from 'ui/src/lib'

import getDaysFromEmployee from './getDaysFromEmployee'
import EmployeeTeamLeavesRow from './LeaveTeamRow'
import { LeaveTeamDay,LeaveTeamEmployee } from './LeaveTeamShared'

type LeaveTeamRowListProps = {
    items?: LeaveTeamEmployee[]
    query: Parameters<typeof leaveTimelineListPaginated>[0]
    onItemClicked?: (day: LeaveTeamDay, employee: LeaveTeamEmployee) => void
}

const LeaveTeamRowList = (props: LeaveTeamRowListProps) => {
    const { query, items = [], onItemClicked } = props
    
    return (
        <>
            {
                items.map((employee, index) => {
                    const isFirst = !index
                    const isLast = items.length - 1 === index
                    const days = getDaysFromEmployee(query!.month, employee)

                    return (
                        <EmployeeTeamLeavesRow
                            key={employee.id}
                            className={clsxm(isFirst && '')}
                            firstColumnClassName={clsxm(isFirst && 'rounded-tl-lg ', isLast && 'rounded-bl-lg', '')}
                            lastColumnClassName={clsxm(isFirst && 'rounded-tr-lg ', isLast && 'rounded-br-lg', '')}
                            employee={employee}
                            days={days}
                            onItemClicked={employee.action ? ((day) => onItemClicked?.(day, employee)): undefined}
                        />
                    )
                })
            }
        </>
    )
}

export default LeaveTeamRowList
