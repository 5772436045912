
import { Team } from '@viterbit/web-app/dataAccess/model/Team.model'
import SegmentNavLink from '@viterbit/web-app/shared/navigation/SegmentNavLink'
import List from 'ui/src/components/List'

export type TeamItemType = {
    item: Team
}

const TeamListItem = (props: TeamItemType) => {
    const { item} = props
    return (
        <SegmentNavLink path={`/employees/team/${item.id}/details`}>
            <List.Item clickable>
                <List.ItemText
                    primary={item.name}
                    secondary={`${item.countMembers} empleados`}
                />
                {/* <MarkDefaultButton show={policy.default} /> */}
            </List.Item>
        </SegmentNavLink>
    )
}

export default TeamListItem
