import { ComplaintRecord } from './ComplaintRecord.model'

export const ComplaintStatus = ['CREATED', 'RECEIVED', 'CLOSED'] as const

export interface Complaint {
    id: string
    title: string
    authorConfirmed: boolean
    authorName: string | null
    authorPhone: string | null
    authorEmail: string | null
    authorRelationship: string | null
    createdAt: string
    updatedAt: string | null
    receivedAt: string | null
    finishedAt: string | null
    status: typeof ComplaintStatus[number]
    records: ComplaintRecord[]
}
