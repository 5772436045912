

import paginationMetaMapper from '@viterbit/web-app/dataAccess/mapper/paginationMeta.mapper'
import workScheduleMapper from '@viterbit/web-app/dataAccess/mapper/workSchedule.mapper'
import { queryClient } from '@viterbit/web-app/shared/lib/ReactQueryProvider'
import { Configuration, WorkScheduleApi } from 'viterbit-api-sdk'

import { commonConfig } from './config'
import { employeeInvalidate } from './employeeService'
import { WORK_SCHEDULE_KEY } from '../cacheKeys'

const api = new WorkScheduleApi(new Configuration(commonConfig))

export const workScheduleInvalidate = () =>
    queryClient.invalidateQueries([WORK_SCHEDULE_KEY])


export const workScheduleCreate = (body: Parameters<typeof api.createWorkSchedule>[0]) =>
    api.createWorkSchedule(body).then((data) => {
        workScheduleInvalidate()
        return data
    })

export const workScheduleUpdate = (body: Parameters<typeof api.updateWorkSchedule>[0]) =>
    api.updateWorkSchedule(body).then((data) => {
        workScheduleInvalidate()
        return data
    })

export const workScheduleDelete = (body: Parameters<typeof api.deleteWorkSchedule>[0]) =>
    api.deleteWorkSchedule(body).then((data) => {
        workScheduleInvalidate()
        return data
    })

export const workScheduleListPaginated = (body: Parameters<typeof api.listWorkSchedule>[0]) =>
    api.listWorkSchedule(body).then(({data, meta}) => ({
        meta: paginationMetaMapper(meta),
        data: data.map(workScheduleMapper.toModel)
    }))

export const workScheduleDetail = (body: Parameters<typeof api.findWorkSchedule>[0]) =>
    api.findWorkSchedule(body).then(workScheduleMapper.toModel)

export const workScheduleAssign = (body: Parameters<typeof api.assignWorkSchedule>[0]) =>
    api.assignWorkSchedule(body).then((res) => {
        workScheduleInvalidate()
        employeeInvalidate()
        return res
    })

export const workScheduleReassign = (body: Parameters<typeof api.updateAssignedWorkSchedule>[0]) =>
    api.updateAssignedWorkSchedule(body).then((res) => {
        workScheduleInvalidate()
        employeeInvalidate()
        return res
    })
    
export const workScheduleUnassign = (body: Parameters<typeof api.unassignWorkSchedule>[0]) =>
    api.unassignWorkSchedule(body).then((res) => {
        workScheduleInvalidate()
        employeeInvalidate()
        return res
    })


