
import { useLocationDelete } from '@viterbit/web-app/dataAccess/useCase/location/useLocationDelete'
import ApiErrorMessage from '@viterbit/web-app/shared/feedback/ApiErrorMessage'
import DeleteAlert from '@viterbit/web-app/shared/feedback/DeleteAlert'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import { LocationFormFields } from './LocationForm'
import FormDeleteDialog from '../../../../shared/form/FormDeleteDialog'

type DeleteLocationProps = {
    defaultValues: LocationFormFields
    id: string
}

const DeleteLocation = ({ defaultValues, id }: DeleteLocationProps) => {
    const [open, setOpen] = React.useState(false)
    const [isPending, startTransition] = React.useTransition()
    const navigate = useNavigate()

    const { isLoading, mutate, error } = useLocationDelete({
        onSuccess: () => {
            navigate('/settings/locations')
            setOpen(false)
        }
    })

    return (
        <>
            <DeleteAlert
                border
                buttonText='Delete Location'
                onConfirm={() => setOpen(true)}
            >
                Do you want to delete this location? Be aware this action cannot be undone.
            </DeleteAlert>

            <FormDeleteDialog
                isOpen={open}
                onClose={() => setOpen(false)}
                cancelButtonText='Cancel'
                isLoading={isLoading || isPending}
                onSubmit={() => mutate({
                    id: id
                })}
                submitButtonText='Confirm'
                title='Delete location'
            >
                <ApiErrorMessage error={error} />
            </FormDeleteDialog>
        </>
    )
}

export default DeleteLocation
