/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AssignBulkPendingFileCommand,
  BulkInfoPendingFileQueryResponse,
  GetSignedFileUrlQueryResponse,
  GetSignedFileUrlQueryResponse2,
  ListEmployeeQueryResponse,
  ListFileQueryResponse,
  ListPendingFileQueryResponse,
  PendingFileQueryResponse,
  ResourceCreated,
  RespondPendingFileCommand,
} from '../models/index';
import {
    AssignBulkPendingFileCommandFromJSON,
    AssignBulkPendingFileCommandToJSON,
    BulkInfoPendingFileQueryResponseFromJSON,
    BulkInfoPendingFileQueryResponseToJSON,
    GetSignedFileUrlQueryResponseFromJSON,
    GetSignedFileUrlQueryResponseToJSON,
    GetSignedFileUrlQueryResponse2FromJSON,
    GetSignedFileUrlQueryResponse2ToJSON,
    ListEmployeeQueryResponseFromJSON,
    ListEmployeeQueryResponseToJSON,
    ListFileQueryResponseFromJSON,
    ListFileQueryResponseToJSON,
    ListPendingFileQueryResponseFromJSON,
    ListPendingFileQueryResponseToJSON,
    PendingFileQueryResponseFromJSON,
    PendingFileQueryResponseToJSON,
    ResourceCreatedFromJSON,
    ResourceCreatedToJSON,
    RespondPendingFileCommandFromJSON,
    RespondPendingFileCommandToJSON,
} from '../models/index';

export interface AssignBulkPendingFileRequest {
    assignBulkPendingFileCommand?: AssignBulkPendingFileCommand;
}

export interface BulkInfoPendingFileRequest {
    type?: BulkInfoPendingFileTypeEnum;
}

export interface DeleteFileRequest {
    type: string;
    id: string;
}

export interface FindPendingFilesRequest {
    id: string;
}

export interface ListEmployeesOfPendingFileRequest {
    id: string;
    page?: number;
    pageSize?: number;
}

export interface ListFilesRequest {
    employeeId: string;
    type: ListFilesTypeEnum;
    page?: number;
    perPage?: number;
}

export interface ListPendingFilesRequest {
    type: ListPendingFilesTypeEnum;
    matching?: boolean;
    notEqualsFileId?: string;
    page?: number;
    pageSize?: number;
}

export interface PublicSignedFileUrlRequest {
    id: string;
}

export interface ResetPendingFileRequest {
    type?: string;
}

export interface RespondPendingFileRequest {
    id: string;
    respondPendingFileCommand?: RespondPendingFileCommand;
}

export interface SignedFileUrlRequest {
    type: string;
    id: string;
}

export interface UploadComplaintFileRequest {
    file: Blob;
}

export interface UploadFileRequest {
    employeeId: string;
    type: UploadFileTypeEnum;
    file: Blob;
}

export interface UploadPendingFileRequest {
    file: Blob;
    type?: string | null;
}

/**
 * 
 */
export class FileApi extends runtime.BaseAPI {

    /**
     * Assign bulk pending file
     * Assign bulk pending file
     */
    async assignBulkPendingFileRaw(requestParameters: AssignBulkPendingFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/pending-files/assign-bulk`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AssignBulkPendingFileCommandToJSON(requestParameters.assignBulkPendingFileCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Assign bulk pending file
     * Assign bulk pending file
     */
    async assignBulkPendingFile(requestParameters: AssignBulkPendingFileRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.assignBulkPendingFileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * bulk info pending file
     * bulk info file
     */
    async bulkInfoPendingFileRaw(requestParameters: BulkInfoPendingFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BulkInfoPendingFileQueryResponse>> {
        const queryParameters: any = {};

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/pending-files/bulk-info`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BulkInfoPendingFileQueryResponseFromJSON(jsonValue));
    }

    /**
     * bulk info pending file
     * bulk info file
     */
    async bulkInfoPendingFile(requestParameters: BulkInfoPendingFileRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BulkInfoPendingFileQueryResponse> {
        const response = await this.bulkInfoPendingFileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a file
     * Delete a file
     */
    async deleteFileRaw(requestParameters: DeleteFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling deleteFile.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/files/{type}/{id}`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Delete a file
     * Delete a file
     */
    async deleteFile(requestParameters: DeleteFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.deleteFileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find a file
     * Find a file
     */
    async findPendingFilesRaw(requestParameters: FindPendingFilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PendingFileQueryResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling findPendingFiles.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/pending-files/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PendingFileQueryResponseFromJSON(jsonValue));
    }

    /**
     * Find a file
     * Find a file
     */
    async findPendingFiles(requestParameters: FindPendingFilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PendingFileQueryResponse> {
        const response = await this.findPendingFilesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * list employees of a pending file
     * list a file
     */
    async listEmployeesOfPendingFileRaw(requestParameters: ListEmployeesOfPendingFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListEmployeeQueryResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling listEmployeesOfPendingFile.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/pending-files/employees/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListEmployeeQueryResponseFromJSON(jsonValue));
    }

    /**
     * list employees of a pending file
     * list a file
     */
    async listEmployeesOfPendingFile(requestParameters: ListEmployeesOfPendingFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListEmployeeQueryResponse> {
        const response = await this.listEmployeesOfPendingFileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * list a files
     * list a file
     */
    async listFilesRaw(requestParameters: ListFilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListFileQueryResponse>> {
        if (requestParameters.employeeId === null || requestParameters.employeeId === undefined) {
            throw new runtime.RequiredError('employeeId','Required parameter requestParameters.employeeId was null or undefined when calling listFiles.');
        }

        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling listFiles.');
        }

        const queryParameters: any = {};

        if (requestParameters.employeeId !== undefined) {
            queryParameters['employeeId'] = requestParameters.employeeId;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.perPage !== undefined) {
            queryParameters['perPage'] = requestParameters.perPage;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/files`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListFileQueryResponseFromJSON(jsonValue));
    }

    /**
     * list a files
     * list a file
     */
    async listFiles(requestParameters: ListFilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListFileQueryResponse> {
        const response = await this.listFilesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * list a files
     * list a file
     */
    async listPendingFilesRaw(requestParameters: ListPendingFilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListPendingFileQueryResponse>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling listPendingFiles.');
        }

        const queryParameters: any = {};

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.matching !== undefined) {
            queryParameters['matching'] = requestParameters.matching;
        }

        if (requestParameters.notEqualsFileId !== undefined) {
            queryParameters['notEqualsFileId'] = requestParameters.notEqualsFileId;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/pending-files`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListPendingFileQueryResponseFromJSON(jsonValue));
    }

    /**
     * list a files
     * list a file
     */
    async listPendingFiles(requestParameters: ListPendingFilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListPendingFileQueryResponse> {
        const response = await this.listPendingFilesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Signed file url
     * Signed file url
     */
    async publicSignedFileUrlRaw(requestParameters: PublicSignedFileUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetSignedFileUrlQueryResponse2>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling publicSignedFileUrl.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/public/files/signed-url/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSignedFileUrlQueryResponse2FromJSON(jsonValue));
    }

    /**
     * Signed file url
     * Signed file url
     */
    async publicSignedFileUrl(requestParameters: PublicSignedFileUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetSignedFileUrlQueryResponse2> {
        const response = await this.publicSignedFileUrlRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reset pending file
     * Reset pending file
     */
    async resetPendingFileRaw(requestParameters: ResetPendingFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        const queryParameters: any = {};

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/pending-files/reset`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Reset pending file
     * Reset pending file
     */
    async resetPendingFile(requestParameters: ResetPendingFileRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.resetPendingFileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Respond to pending file
     * Respond to pending
     */
    async respondPendingFileRaw(requestParameters: RespondPendingFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling respondPendingFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/pending-files/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RespondPendingFileCommandToJSON(requestParameters.respondPendingFileCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Respond to pending file
     * Respond to pending
     */
    async respondPendingFile(requestParameters: RespondPendingFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.respondPendingFileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Signed file url
     * Signed file url
     */
    async signedFileUrlRaw(requestParameters: SignedFileUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetSignedFileUrlQueryResponse>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling signedFileUrl.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling signedFileUrl.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/files/signed-url/{type}/{id}`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSignedFileUrlQueryResponseFromJSON(jsonValue));
    }

    /**
     * Signed file url
     * Signed file url
     */
    async signedFileUrl(requestParameters: SignedFileUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetSignedFileUrlQueryResponse> {
        const response = await this.signedFileUrlRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Upload a complaint file
     * Upload a complaint file
     */
    async uploadComplaintFileRaw(requestParameters: UploadComplaintFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling uploadComplaintFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/public/complaint-files`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Upload a complaint file
     * Upload a complaint file
     */
    async uploadComplaintFile(requestParameters: UploadComplaintFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.uploadComplaintFileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Upload a file
     * Upload a file
     */
    async uploadFileRaw(requestParameters: UploadFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        if (requestParameters.employeeId === null || requestParameters.employeeId === undefined) {
            throw new runtime.RequiredError('employeeId','Required parameter requestParameters.employeeId was null or undefined when calling uploadFile.');
        }

        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling uploadFile.');
        }

        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling uploadFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.employeeId !== undefined) {
            formParams.append('employeeId', requestParameters.employeeId as any);
        }

        if (requestParameters.type !== undefined) {
            formParams.append('type', requestParameters.type as any);
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/core/files`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Upload a file
     * Upload a file
     */
    async uploadFile(requestParameters: UploadFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.uploadFileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Upload a file
     * Upload a file
     */
    async uploadPendingFileRaw(requestParameters: UploadPendingFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling uploadPendingFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        if (requestParameters.type !== undefined) {
            formParams.append('type', requestParameters.type as any);
        }

        const response = await this.request({
            path: `/api/core/pending-files`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Upload a file
     * Upload a file
     */
    async uploadPendingFile(requestParameters: UploadPendingFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.uploadPendingFileRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const BulkInfoPendingFileTypeEnum = {
    Payroll: 'payroll',
    Document: 'document',
    Leave: 'leave',
    Contract: 'contract'
} as const;
export type BulkInfoPendingFileTypeEnum = typeof BulkInfoPendingFileTypeEnum[keyof typeof BulkInfoPendingFileTypeEnum];
/**
 * @export
 */
export const ListFilesTypeEnum = {
    Leave: 'leave',
    Personal: 'personal',
    Payroll: 'payroll',
    Document: 'document',
    Contract: 'contract',
    Avatar: 'avatar',
    Id: 'id',
    Complaint: 'complaint'
} as const;
export type ListFilesTypeEnum = typeof ListFilesTypeEnum[keyof typeof ListFilesTypeEnum];
/**
 * @export
 */
export const ListPendingFilesTypeEnum = {
    Leave: 'leave',
    Payroll: 'payroll',
    Document: 'document',
    Contract: 'contract',
    Avatar: 'avatar'
} as const;
export type ListPendingFilesTypeEnum = typeof ListPendingFilesTypeEnum[keyof typeof ListPendingFilesTypeEnum];
/**
 * @export
 */
export const UploadFileTypeEnum = {
    Leave: 'leave',
    Personal: 'personal',
    Payroll: 'payroll',
    Document: 'document',
    Contract: 'contract',
    Id: 'id',
    Avatar: 'avatar',
    Complaint: 'complaint',
    Logo: 'logo'
} as const;
export type UploadFileTypeEnum = typeof UploadFileTypeEnum[keyof typeof UploadFileTypeEnum];
