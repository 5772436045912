/* eslint-disable @typescript-eslint/no-explicit-any */

import {
    ConditionFieldBase,
    ConditionTypeBase,
    ConditionValidationBase,
} from './ConditionBase.model'

export const ConditionNumberFields = [] as const
export type ConditionNumberField = typeof ConditionNumberFields[number]

export const ConditionFieldNumberTypes = ['integer', 'float'] as const
export type ConditionFieldNumberType = typeof ConditionFieldNumberTypes[number]

export const ConditionNumberOperators = [
    'lowerThan',
    'lowerThanOrEquals',
    'greaterThan',
    'greaterThanOrEquals',
] as const
export type ConditionNumberOperator = typeof ConditionNumberOperators[number]
export type ConditionTypeNumber = ConditionTypeBase<number> & {
  [key in ConditionNumberOperator]?: number
}

export type ConditionValidationNumber = ConditionValidationBase<
  ConditionNumberOperator,
  number
>

export type ConditionFieldNumber = ConditionFieldBase<
  ConditionNumberField,
  ConditionFieldNumberType,
  ConditionValidationNumber
>

export type ConditionFieldNumberProperties = {
    [key in ConditionNumberField]: string
}

export const isConditionNumberField = (
    conditionField: any
): conditionField is ConditionFieldNumber =>
    ConditionFieldNumberTypes.includes(conditionField.type as any)

export const ConditionNumberField: ConditionFieldNumber = {
    name: 'age' as never,
    type: 'float',
    validations: [
        {
            name: 'greaterThan',
            value: [18],
        },
    ],
}
