/* eslint-disable @typescript-eslint/no-explicit-any */

import i18n from '@viterbit/web-app/i18n'
import * as React from 'react'

import EmployeePlanningCreateButton from './EmployeePlanningCreateButton'
import EmployeePlanningCreateDialog from './EmployeePlanningCreateDialog'


const EmployeePlanningCreate = () => {
    const [open, setOpen] = React.useState(false)

    const onClose = () => {
        setOpen(false)
    }

    return (
        <>
            <EmployeePlanningCreateButton
                data-testid='work-schedule-create-button'
                onClick={() => setOpen(true)}
            >
                {i18n.t('employees:employee.planning.createButton')}
            </EmployeePlanningCreateButton>

            <EmployeePlanningCreateDialog
                isOpen={open}
                onClose={onClose}
            />
        </>
    )
}

export default EmployeePlanningCreate
