import { Box, Tooltip } from '@mantine/core'
import * as React from 'react'

import ApiErrorMessage, { ApiErrorMessageProps } from './ApiErrorMessage'

type ApiErrorMessageFloatingProps = {
    children?: React.ReactNode
    className?: string
} & ApiErrorMessageProps

const ApiErrorMessageFloating = (props: ApiErrorMessageFloatingProps) => {
    const { children, error, ...rest } = props

    if (!error) {
        return <>{ children }</>
    }

    return (
       
        <Tooltip.Floating label={<ApiErrorMessage className='m-0' error={error} {...rest}/>} color="transparent">
            <Box
                sx={(theme) => ({
                    padding: 0,
                    margin: 0,
                    cursor: 'default',
                    backgroundColor:
                        theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
                })}
            >
                {children}
            </Box>
        </Tooltip.Floating>
    )
}

export default ApiErrorMessageFloating
