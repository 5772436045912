/* eslint-disable @typescript-eslint/no-explicit-any */

import { getConditionSchemaType } from '@viterbit/web-app/dataAccess/model/Condition/ConditionBase.model'
import { Employee } from '@viterbit/web-app/dataAccess/model/Employee.model'
import { WorkflowNodeData } from '@viterbit/web-app/dataAccess/model/Workflow.model'
import * as React from 'react'
import { UseFormReturn } from 'react-hook-form'
import { FormInput, FormSwitcher } from 'ui/src/components/Form'
import MTFormInputQuantity from 'ui/src/components/Form/Mantine/MTFormInputQuantity'

import ControlledFieldCkEditor from './ControlledFieldCkEditor'
import FormDatePicker from './FormDatePicker'
import FormSelectCompany from './FormSelectCompanies'
import FormSelectEmployee from './FormSelectEmployee'
import FormSelectLocation from './FormSelectLocation'
import { DynamicField as DynamicFieldType } from './useDynamicForm'

export type DynamicFieldsProps = {
    children?: React.ReactNode
    className?: string
    fields: DynamicFieldType[]
    form: UseFormReturn<WorkflowNodeData>
    defaultValues?: Record<string, any>
    Wrapper?: React.ComponentType
    validate?: boolean
    options?: string[]
}

export type DynamicFieldProps = {
    field: DynamicFieldType
    parentProps: Omit<DynamicFieldsProps, 'fields' | 'Wrapper'>
}

type DynamicFieldMap <Component extends React.ComponentType> = {
    Component: Component
    defaultProps?: Partial<React.ComponentProps<Component>>
    mapProps?: <RootProps extends DynamicFieldsProps> (rootProps: RootProps, field: DynamicFieldType) => React.ComponentProps<Component>
}

type MappedFields = {
    string: DynamicFieldMap<typeof FormInput>
    integer: DynamicFieldMap<typeof FormInput>
    boolean: DynamicFieldMap<typeof FormSwitcher>
    rich_text: DynamicFieldMap<typeof ControlledFieldCkEditor>
    quantity: DynamicFieldMap<typeof MTFormInputQuantity>
    employeeId: DynamicFieldMap<typeof FormSelectEmployee>
    google_email: DynamicFieldMap<typeof FormSelectEmployee>
    companyId: DynamicFieldMap<typeof FormSelectCompany>
    locationId: DynamicFieldMap<typeof FormSelectLocation>
    float: DynamicFieldMap<typeof FormInput>
    date: DynamicFieldMap<typeof FormDatePicker>
}

const getDefaultValue = (field: DynamicFieldType) => {
    if (field.default) {
        return field.default
    }

    const type = getConditionSchemaType(field)

    if (type === 'string') {
        return ''
    }
    if (type === 'date') {
        return null
    }
    if (type === 'number') {
        return 0
    }
}

const defaultMapProps = <RootProps extends DynamicFieldsProps> (rootProps: RootProps, field: DynamicFieldType) => ({
    name: field.name,
    asterisk: field.required,
    control: rootProps.form.control,
    label: field.additionalProperties?.label || field.name,
    placeholder: field.additionalProperties?.placeholder,
    defaultValue: getDefaultValue(field),
})

const MAPPED_FIELDS: MappedFields = {
    string: {
        Component: FormInput,
        defaultProps: {
            type: 'string'
        },
        mapProps: defaultMapProps,
    },
    float: {
        Component: FormInput,
        defaultProps: {
            type: 'number'
        },
        mapProps: defaultMapProps,
    },
    integer: {
        Component: FormInput,
        defaultProps: {
            type: 'number'
        },
        mapProps: defaultMapProps,
    },
    boolean: {
        Component: FormSwitcher,
        mapProps: defaultMapProps,
    },
    rich_text: {
        Component: ControlledFieldCkEditor,
        mapProps: defaultMapProps,
    },
    employeeId: {
        Component: FormSelectEmployee,
        defaultProps: {
            getOptionValue: (option: Employee) => option.id,
        },
        mapProps: defaultMapProps,
    },
    google_email: {
        Component: FormSelectEmployee,
        defaultProps: {
            fetchProps: {
                filters: {
                    operator: 'and',
                    groups: [
                        {
                            operator: 'and',
                            filters: [
                                {
                                    field: 'integrationsEmailGoogle',
                                    operator: 'equals',
                                    value: 'true'
                                }
                            ]
                        }
                    ]
                }
            }
        },
        mapProps: defaultMapProps,
    },
    companyId: {
        Component: FormSelectCompany,
        mapProps: defaultMapProps,
    },
    locationId: {
        Component: FormSelectLocation,
        mapProps: defaultMapProps,
    },
    date: {
        Component: FormDatePicker,
        mapProps: defaultMapProps,
    },
    quantity: {
        Component: MTFormInputQuantity,
        mapProps: (rootProps, field) => {
            const defaultProps = defaultMapProps(rootProps, field)

            return {
                ...defaultProps,
                data: field.options?.map(option => ({
                    value: option,
                    label: option
                })) || [] as any,
            }
        },
    },
}

export const DynamicField = (props: DynamicFieldProps) => {
    const { field, parentProps } = props

    if (!MAPPED_FIELDS[field.type]) {
        console.warn(`No mapped field for type ${field.type}`)
        return null
    }

    const { Component, defaultProps, mapProps } = MAPPED_FIELDS[field.type]
    const mappedProps = mapProps?.(parentProps, field)
    return (
        <Component
            key={field.name}
            {...defaultProps}
            {...mappedProps}
            label=""
        />
    )
}

const DynamicFields = (props: DynamicFieldsProps) => {
    const { fields, Wrapper = React.Fragment } = props

    return (
        <Wrapper>
            {fields.map((field) => {
                if (!MAPPED_FIELDS[field.type]) {
                    console.warn(`No mapped field for type ${field.type}`)
                    return null
                }
                        
                const { Component, defaultProps, mapProps } = MAPPED_FIELDS[field.type]
                const mappedProps = mapProps?.(props, field)
                return (
                    <Component
                        key={field.name}
                        {...defaultProps}
                        {...mappedProps}
                    />
                )
            })}
        </Wrapper>
    )
}

export default React.memo(DynamicFields, () => true)
