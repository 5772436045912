/* eslint-disable @typescript-eslint/no-explicit-any */

import { Menu as MTMenu} from '@mantine/core'
import { components, ControlProps } from 'react-select'

const FormSelectControl = ({ innerProps, ...rest }: ControlProps<any>) => (
    <MTMenu opened >
        <components.Control
            {...rest}
            innerProps={{
                ...innerProps,
                'data-testid': `form-select-${rest.selectProps.name}`,
            } as any}
        />
    </MTMenu>
)


export default FormSelectControl