import * as React from 'react'

import { MenuContextConsumer } from './context/menuContext'
import { ExtendedModeType } from '../Layout/sharedTypes'
import { clsxm } from '../../lib/clsxm'

const classes = {
    menuItem:
        'cursor-pointer font-medium px-3 rounded-md flex items-center w-full whitespace-nowrap gap-x-2 min-w-[32px] box-border',
    light: {
        default: 'text-gray-500',
        hover: 'hover:text-black hover:bg-gray-100',
        active: 'text-black bg-gray-200 hover:bg-gray-200',
    },
    dark: {
        default: 'text-gray-400 dark:text-gray-400',
        hover: 'hover:text-gray-100 hover:bg-gray-700',
        active: 'text-gray-100 bg-gray-600 hover:bg-gray-600',
    },
    themed: {
        default: 'text-gray-100 text-opacity-80',
        hover: 'hover:text-opacity-100 hover:bg-gray-900 hover:bg-opacity-10',
        active: 'text-opacity-100 bg-gray-900 bg-opacity-10',
    },
    transparent: {
        default: 'text-black dark:text-gray-400',
        hover: 'hover:text-black dark:hover:text-gray-100 hover:bg-gray-900/[.1] dark:hover:bg-white/[.1]',
        active: 'text-black dark:text-gray-100 bg-gray-900/[.075] dark:bg-white/[.1]',
    },
    menuItemDisabled: 'cursor-not-allowed opacity-60',
}

type ElementType = HTMLDivElement

export type MenuItemProps = {
    menuItemHeight?: number
    disabled?: boolean
    isActive?: boolean
    eventKey: string
    variant?: ExtendedModeType
    onSelect?: (eventKey: string, e: React.MouseEvent) => void
    children?: React.ReactNode
} & Omit<React.HTMLAttributes<ElementType>, 'onSelect'>

const Item = React.forwardRef<ElementType, MenuItemProps>((props, ref) => {
    const {
        children,
        className,
        disabled,
        eventKey,
        isActive,
        menuItemHeight = 35,
        onSelect,
        style,
        variant = 'light',
        ...rest
    } = props

    // eslint-disable-next-line @typescript-eslint/no-explicit-any

    const variantClasses = classes[variant]
    const menuItemClass = clsxm(
        classes.menuItem,
        variantClasses.default,
        disabled && classes.menuItemDisabled,
        !disabled && variantClasses.hover,
        isActive && variantClasses.active,
        className
    )

    const handleOnClick: React.MouseEventHandler<ElementType> = (e) => {
        !disabled && onSelect?.(eventKey, e)
    }

    return (
        <div
            data-testid={`menu-item-${eventKey}`}
            ref={ref}
            className={menuItemClass}
            style={{ height: `${menuItemHeight}px`, ...style }}
            onClick={handleOnClick}
            {...rest}
        >
            {children}
        </div>
    )
})

const MenuItem = ({ eventKey, ...rest }: MenuItemProps) => (
    <MenuContextConsumer>
        {(context) => (
            <Item
                onSelect={context.onSelect}
                menuItemHeight={context.menuItemHeight}
                variant={context.variant}
                isActive={context.defaultActiveKeys.includes(eventKey)}
                eventKey={eventKey}
                {...rest}
            />
        )}
    </MenuContextConsumer>
)

export default MenuItem
