import { useSearchParams } from 'react-router-dom'

import { useUser } from './useUser'

const useGetEmployeeId = () => {
    const [searchParams] = useSearchParams()
    const user = useUser()

    const employeeId = searchParams.get('employeeId') || user?.id

    return { employeeId }
}

export default useGetEmployeeId
