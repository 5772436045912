import { IconAlertTriangle } from '@tabler/icons-react'
import { useLeaveDelete } from '@viterbit/web-app/dataAccess/useCase/leave/useLeaveDelete'
import i18n from '@viterbit/web-app/i18n'
import ApiErrorMessage from '@viterbit/web-app/shared/feedback/ApiErrorMessage'
import Button from 'ui/src/components/Button'

type DeleteSectionProps = {
    leaveId: string
    onDelete: () => void
    onCancel: () => void
}

const DeleteSection = ({ leaveId, onDelete, onCancel }: DeleteSectionProps) => {


    const { isLoading, mutate, error } = useLeaveDelete({
        onSuccess: onDelete
    })

    return (
        <div className='flex flex-col justify-between h-full p-1 overflow-hidden text-left transition-all transform rounded-lg sm:w-full'>
            <div className='sm:flex sm:items-start'>
                <div className='flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-red-100 rounded-full sm:mx-0 sm:h-10 sm:w-10'>
                    <IconAlertTriangle className='w-5 h-5 text-danger-600' />
                </div>
                <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
                    <h3
                        className='text-lg font-medium leading-6 text-black'
                        id='modal-title'
                    >
                        {i18n.t('leaves:deleteDialog.title')}
                    </h3>
                    <div className='mt-2'>
                        <p className='text-sm text-gray-500'>
                            {i18n.t('leaves:deleteDialog.description')}
                        </p>
                    </div>
                </div>
            </div>

            <ApiErrorMessage error={error} />

            <div className='pt-8 pb-1 sm:flex sm:flex-row-reverse '>
                <Button
                    loading={isLoading}
                    disabled={isLoading}
                    onClick={() => mutate({
                        id: leaveId,
                    })}
                    data-testid='button-sure-delete'
                    className='inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-red-600 border border-transparent rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm'
                >
                    Delete
                </Button>
                <Button
                    disabled={isLoading}
                    onClick={onCancel}
                    className='inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm'
                >
                    Cancel
                </Button>
            </div>
        </div>
    )
}

export default DeleteSection
