/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */

import React from 'react'
import { FieldValues, UseFormReturn } from 'react-hook-form'

import useResponse400Errors from '../feedback/useResponse400Errors'


type FormErrorsReturn<FormValues extends FieldValues> = {
    global: Record<string, string> | null
    fields: FormValues | null
}

const initialErrors = {
    global: null,
    fields: null,
}

const useFormErrors = <FormValues extends FieldValues>(form: UseFormReturn<FormValues>, response: Parameters<typeof useResponse400Errors>[0]): FormErrorsReturn<FormValues> => {
    const errors = useResponse400Errors(response)
    
    const parsedErrors = React.useMemo(() => {
        if (!errors) return initialErrors

        const formKeys = Object.keys(form.getValues())

        const { global, fields } = Object.entries(errors).reduce((output, current) => {
            const [key, value] = current
            
            if (formKeys.includes(key)) {
                output.fields[key] = value
            } else {
                output.global[key] = value
            }

            return output
        }, { global: {}, fields: {} })

        return {
            global: Object.keys(global).length ? global : null,
            fields: Object.keys(fields).length ? fields : null,
        }
    }, [errors])

    return (parsedErrors || initialErrors) as any
}

export default useFormErrors
