/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/ban-types */
import { useEffect, useRef } from 'react'

/**
 * @see https://github.com/streamich/react-use/blob/master/src/useInterval.ts
 * @param callback called when the timer is working
 * @param delay
 */
const useInterval = (callback: Function, delay?: number | null) => {
    const savedCallback = useRef<Function>(() => {})

    useEffect(() => {
        savedCallback.current = callback
    })

    useEffect(() => {
        if (delay !== null) {
            const interval = setInterval(
                () => savedCallback.current(),
                delay || 0
            )
            return () => clearInterval(interval)
        }

        return undefined
    }, [delay])
}

export default useInterval
