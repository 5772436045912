
import { Alert } from '@mantine/core'
import { IconInfoCircle } from '@tabler/icons-react'
import { Employee } from '@viterbit/web-app/dataAccess/model/Employee.model'
import i18n from '@viterbit/web-app/i18n'
import DeleteAlert from '@viterbit/web-app/shared/feedback/DeleteAlert'
import { useNotification } from '@viterbit/web-app/shared/feedback/useNotification'
import { formatDate } from '@viterbit/web-app/shared/utils/dateFormat'
import dayjs from 'dayjs'
import * as React from 'react'

import EmployeeDeleteDialog from './EmployeeDeleteDialog'

type EmployeeDeleteProps = {
    employee: Employee
}

const today = dayjs()

const EmployeeDelete = ({ employee }: EmployeeDeleteProps) => {
    const notification = useNotification()
    const [isDeleting, setIsDeleting] = React.useState(false)

    const onSuccess = () => {
        notification.push(
            'success', 
            i18n.t('common:notification.delete'), 
            i18n.t('common:notification.successDelete'), 
        )
        setIsDeleting(false)
    }

    const { endDate } = employee.deleteInformation || {}
    const hasDate = endDate 
    const date = dayjs(endDate)

    return (
        <>
            {!hasDate && (
                <DeleteAlert
                    buttonText={i18n.t('employees:employeeDelete.title')}
                    onConfirm={() => setIsDeleting(true)}
                >
                    {i18n.t('employees:employeeDelete.alert')}
                </DeleteAlert>
            )}

            {hasDate && date.isSameOrAfter(today) && (
                <DeleteAlert
                    type='warning'
                    buttonClassName=''
                    buttonText={i18n.t('employees:employeeDeleteUpdate.button')}
                    onConfirm={() => setIsDeleting(true)}
                >
                    {i18n.t('employees:employeeDeleteUpdate.alert', { date: formatDate(date) })}
                </DeleteAlert>
            )}

            {hasDate && date.isBefore(today) && (
                <Alert
                    className="p-4 mb-4"
                    variant='outline'
                    icon={<IconInfoCircle size={24} />}>
                    {i18n.t('employees:employeeDeleteUpdate.contractEnded', { date: formatDate(date) })}
                </Alert>
            )}

            <EmployeeDeleteDialog
                employee={employee}
                isOpen={isDeleting}
                onClose={() => setIsDeleting(false)}
                cancelButtonText={i18n.t('common:buttons.cancel')}
                onSuccess={onSuccess}
                submitButtonText={i18n.t('common:buttons.confirm')}
                title={i18n.t('employees:employeeDelete.title')}
            />
        </>
    )
}

export default EmployeeDelete
