import { IconFileDollar, IconFileText, IconWritingSign } from '@tabler/icons-react'
import SimpleCard from '@viterbit/web-app/shared/display/SimpleCard'
import List from 'ui/src/components/List'

import DocumentBulkUploadNavigationItem from './DocumentBulkUploadNavigationItem'

type DocumentBulkUploadNavigationListProps = {
    currentPath: string
}

const DocumentBulkUploadNavigationList = (props: DocumentBulkUploadNavigationListProps) => {
    const { currentPath } = props

    return (
        <SimpleCard>
            <List>
                <DocumentBulkUploadNavigationItem
                    type="payroll"
                    path={`${currentPath}/documents/payroll`}
                    Icon={IconFileDollar}
                    className="bg-success-300"
                />
                <DocumentBulkUploadNavigationItem
                    type="document"
                    path={`${currentPath}/documents/document`}
                    Icon={IconFileText}
                    className="bg-danger-300"
                />
                <DocumentBulkUploadNavigationItem 
                    type="contract"
                    path={`${currentPath}/documents/contract`}
                    Icon={IconWritingSign}
                    className="bg-warning-300"
                />
                <DocumentBulkUploadNavigationItem
                    type="leave"
                    path={`${currentPath}/documents/leave`}
                    Icon={IconFileDollar}
                    className="bg-primary-300"
                />
            </List>
        </SimpleCard>
    )
}

export default DocumentBulkUploadNavigationList
