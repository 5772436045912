/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from 'react'
import clsxm from 'ui/src/lib'

import LeaveTeamBullet from './LeaveTeamBullet'
import { LeaveTeamDay } from './LeaveTeamShared'

export type LeaveTeamLayoutProps = {
    children?: React.ReactNode
    className?: string
    days: LeaveTeamDay[]
    header: React.ReactElement
    firstColumnClassName?: string
    lastColumnClassName?: string
    firstItemClassName?: string
    onItemClicked?: (day: LeaveTeamDay) => void
}

const LeaveTeamLayoutLayoutContent = (props: { day: LeaveTeamDay; className?: string, onClick?: () => void }) => {
    const { day, className, onClick } = props

    const shouldShowOverLayout = day.isToday && day.description

    return (
        <article
            className={clsxm(
                'flex items-center  justify-center h-full relative bg-white border-b',
                className
            )}
        >

            {day.isToday && (
                <aside className='absolute inset-0 border-l border-r bg-primary-50 border-grey-100'></aside>
            )}
        
            <div className={clsxm(shouldShowOverLayout && 'z-[9]')}>
                <LeaveTeamBullet {...day} onClick={onClick} />
            </div>
        </article>
    )
}

const LeaveTeamLayout = (props: LeaveTeamLayoutProps) => {
    const {
        days,
        header,
        firstColumnClassName,
        lastColumnClassName,
        className,
        onItemClicked,
    } = props

    return (
        <>
            <div
                className={clsxm(
                    'lg:sticky left-0 z-10 bg-white py-1.5 px-3.5 border-b',
                    firstColumnClassName
                )}
            >
                {header}
            </div>

            {days.map((day, index) => (
                <LeaveTeamLayoutLayoutContent
                    key={day.number}
                    day={day}
                    onClick={onItemClicked && (() => {
                        onItemClicked(day)
                    })}
                    className={clsxm(
                        days.length - 1 === index && lastColumnClassName,
                        className
                    )}
                />
            ))}
        </>
    )
}

export default LeaveTeamLayout
