
import { IconNoteOff } from '@tabler/icons-react'
import { useWorkScheduleListPaginated } from '@viterbit/web-app/dataAccess/useCase/workSchedule/useWorkScheduleListPaginated'
import i18n from '@viterbit/web-app/i18n'
import { useOnScreenElement } from '@viterbit/web-app/shared/behaviour/useOnScreenElement'
import EmptyState from '@viterbit/web-app/shared/display/EmptyState/EmptyState'
import BreakpointRedirection from '@viterbit/web-app/shared/navigation/BreakpointRedirection'
import * as React from 'react'
import List from 'ui/src/components/List'

import WorkScheduledListItem from './WorkScheduledListItem'

const WorkScheduledListView = () => {
    const { ref } = useOnScreenElement({
        onVisible: () => {
            hasNextPage && fetchNextPage()
        }
    })
    
    const {
        data,
        fetchNextPage,
        hasNextPage,
        isFetched,
    } = useWorkScheduleListPaginated()

    const firstWorkSchedule = React.useMemo(() => data?.pages[0]?.data[0], [data])

    return (
        <>
            {firstWorkSchedule && (
                <BreakpointRedirection
                    matcherPath="/settings/work-schedule"
                    redirectPath={`/settings/work-schedule/${firstWorkSchedule?.id}/details`}
                />
            )}

            {isFetched && !firstWorkSchedule && (
                <EmptyState
                    image={<IconNoteOff size={64} stroke={1} />}
                    className='my-20'
                    title={i18n.t('settingWorkSchedule:emptyList.title')}
                    description={i18n.t('settingWorkSchedule:emptyList.description')}
                />
            )}
        
            <List className='flex flex-col divide-y'>
                {data?.pages?.map(({ data: pageData }) =>
                    pageData.map((workSchedule) => (
                        <WorkScheduledListItem key={workSchedule.id} workSchedule={workSchedule} />
                    ))
                )}
            </List>

            <div ref={ref} />
        </>
    )

}




export default WorkScheduledListView
