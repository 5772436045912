/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetSignedFileUrlQueryResponse
 */
export interface GetSignedFileUrlQueryResponse {
    /**
     * Signed url
     * @type {string}
     * @memberof GetSignedFileUrlQueryResponse
     */
    url: string;
}

/**
 * Check if a given object implements the GetSignedFileUrlQueryResponse interface.
 */
export function instanceOfGetSignedFileUrlQueryResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "url" in value;

    return isInstance;
}

export function GetSignedFileUrlQueryResponseFromJSON(json: any): GetSignedFileUrlQueryResponse {
    return GetSignedFileUrlQueryResponseFromJSONTyped(json, false);
}

export function GetSignedFileUrlQueryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSignedFileUrlQueryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'url': json['url'],
    };
}

export function GetSignedFileUrlQueryResponseToJSON(value?: GetSignedFileUrlQueryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'url': value.url,
    };
}

