import { Select, TextInput } from '@mantine/core'
import * as React from 'react'
import { useController } from 'react-hook-form'

import MTFormInput from './MTFormInput'
import MTFormSelect from './MTFormSelect'

type MTFormInputProps = {
    name: string
    inputProps?: Omit<React.ComponentPropsWithoutRef<typeof MTFormInput>, 'rightSection' | 'onChange'>
    selectProps?: Omit<React.ComponentPropsWithoutRef<typeof MTFormSelect>, 'data' | 'onChange'>
    data: React.ComponentPropsWithoutRef<typeof MTFormSelect>['data']
}

const width = 100

const MTFormInputQuantity = (props: MTFormInputProps) => {
    const { name, inputProps = {}, selectProps = {}, data = [] } = props 
    
    const controller = useController({ name })
    const [quantity, unit = (data[0] as any)?.value] = String(controller.field.value || '').split(' ')

    return (
        <TextInput
            {...inputProps}
            name={`${name}_quantity`}
            value={quantity || ''}
            rightSectionWidth={width}
            onChange={(event) => {
                const value = event.currentTarget.value
                controller.field.onChange(`${value} ${unit}`)
            }}
            rightSection={(
                <Select
                    {...selectProps}
                    name={`${name}_amount`}
                    data={data}
                    value={unit}
                    onChange={(value) => {
                        controller.field.onChange(`${quantity} ${value}`)
                    }}
                    styles={{
                        input: {
                            fontWeight: 500,
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                            width: width,
                        },
                    }}
                />
            )}
        />
    )
}

export default MTFormInputQuantity
