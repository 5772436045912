/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line simple-import-sort/imports
import FullCalendar from '@fullcalendar/react'

import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import timeGridPlugin from '@fullcalendar/timegrid'
import dayjs from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'
import * as React from 'react'
import clsxm from 'ui/src/lib'


import TimeTrackingStyles from './TimeTrackingStyles'
import useTimeTrackingData from './useTimeTrackingData'
import CreateTimeTracking from '../create/CreateTimeTracking'
import EditTimeTracking from '../detail/EditTimeTracking'
import TimeTrackingEvent from './TimeTrackingEvent'
import { TimeTracking } from '@viterbit/web-app/dataAccess/model/TimeTracking.model'
import i18n from '@viterbit/web-app/i18n'
import EmbeddedContent from '@viterbit/web-app/shared/behaviour/EmbeddedContent'
import { timeTrackingCreate, timeTrackingDelete, timeTrackingListPaginated, timeTrackingUpdate } from '@viterbit/web-app/dataAccess/service/timeTrackingService'
import { useCurrentEmployeeId } from '@viterbit/web-app/shared/employee/useCurrentEmployeeId'
import { formatTime } from '@viterbit/web-app/shared/utils/dateFormat'
import { TIME_ZONE } from '@viterbit/web-app/shared/utils/timeZone'
import dateMapper from '@viterbit/web-app/dataAccess/mapper/date.mapper'
import ForbiddenContent from '@viterbit/web-app/shared/display/ForbiddenContent'
import { useCurrentEmployee } from '@viterbit/web-app/dataAccess/useCase/employee/useCurrentEmployee'
import TimeTrackingEstimationList from './TimeTrackingEstimationList'
import useLocale from '@viterbit/web-app/shared/utils/useLocale'
import ApiErrorMessageFloating from '@viterbit/web-app/shared/feedback/ApiErrorMessageFloating'

dayjs.extend(isoWeek)

type TimeTrackingEditState = {
    defaultValues: TimeTracking
}

type TimeTrackingQuery = {
    startDate: string
    endDate: string
    employeeId: string
}

const TimeTrackingView = () => {
    const employeeId = useCurrentEmployeeId() as string
    const employee = useCurrentEmployee()
    const [query, setQuery] = React.useState<TimeTrackingQuery | null>(null)
    const [ error, setError] = React.useState<any>(null)

    const { workingDays } = useTimeTrackingData()

    const locale = useLocale()

    const calendarRef = React.useRef<FullCalendar>(null)

    const [editDetail, setEditDetail] = React.useState<TimeTrackingEditState | null>()

    const events = React.useCallback((info, successCallback, failureCallback) =>  {
        const newQuery = {
            employeeId,
            startDate: dateMapper.toDate(info.start)!,
            endDate: dateMapper.toDate(dayjs(info.end).subtract(1, 'day'))!,
        }
        setQuery(newQuery)
        timeTrackingListPaginated(newQuery).then((res) => {
            successCallback(
                res.data.map((item) => ({
                    id: item.id,
                    start: item.startDate,
                    end: item.endDate,
                }))
            )
        }).catch((err) => {
            setError(err)
            console.error(err)
            failureCallback(err)
        })
    }, [])

    const eventChange = React.useCallback((info) =>  {        
        timeTrackingUpdate({
            id: info.event.id,
            updateTimeTrackingCommand: {
                timezone: TIME_ZONE,
                startDate: dateMapper.toDateTime(info.event.start)!,
                endDate: dateMapper.toDateTime(info.event.end)!,
            }
        }).catch(err => {
            setError(err)
            console.error(err)
            info.revert()
        })
    }, [])

    const select = React.useCallback((info) => {
        const calendarApi = info.view.calendar
        
        timeTrackingCreate({
            createTimeTrackingCommand: {
                timezone: TIME_ZONE,
                employeeId,
                startDate: dateMapper.toDateTime(info.startStr)!,
                endDate: dateMapper.toDateTime(info.endStr)!,
            }
        }).then(({ id }) => {
            calendarApi.refetchEvents()
            calendarApi.unselect()
        }).catch(err => {
            setError(err)
            console.error(err)
            calendarApi.unselect()
        })
    }, [employeeId])

    const selectAllow = React.useCallback((info) => {
        const end = dayjs(info.end)
        const today = dayjs(Date())
        const endDay = end.isoWeekday() - 1
        
        const isFuture = end.isAfter(today)
        const isWorkingDay = workingDays.includes(endDay)
        
        return isWorkingDay && !isFuture
    }, [workingDays])

    React.useEffect(() => {
        if (error) {
            setTimeout(() => {
                setError(null)
            }, 4000)
        }
    }, [error])

    return (
        <div >
            <EmbeddedContent>
                <ForbiddenContent allow={!employee?.isDeleted}>
                    <CreateTimeTracking
                        employeeId={employeeId}
                        onSuccess={() => {
                            const calendarApi = calendarRef.current?.getApi()
                            calendarApi?.refetchEvents()
                        }}
                    />
                </ForbiddenContent>
            </EmbeddedContent>

            {!!editDetail && (
                <EditTimeTracking
                    open={true}
                    defaultValues={editDetail?.defaultValues as any}
                    onClose={() => setEditDetail(null)}
                    onSuccess={() => {
                        const calendarApi = calendarRef.current?.getApi()
                        calendarApi?.refetchEvents()
                    }}                
                />
            )}
            
            <ApiErrorMessageFloating error={error} >
                <FullCalendar
                    ref={calendarRef}
                    firstDay={1}
                    weekNumberCalculation="ISO"
                    editable={true}
                    selectable={true}
                    selectMirror={false}
                    dragScroll
                    eventOverlap={false}
                    navLinks={false}
                    dayMaxEvents={true}
                    headerToolbar={{
                        left: 'title',
                        center: '',
                        right: 'today prev,next'
                    }}
                    buttonText={{
                        today: i18n.t('personalArea:timeTracking.buttonText.today'), 
                        month: i18n.t('personalArea:timeTracking.buttonText.month'), 
                        week: i18n.t('personalArea:timeTracking.buttonText.week'), 
                        day: i18n.t('personalArea:timeTracking.buttonText.day'), 
                        list: i18n.t('personalArea:timeTracking.buttonText.list'), 
                    
                    }}
                    titleFormat={{
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                    }}
                    slotLabelFormat={[
                        { 
                            hour: 'numeric',
                            minute: '2-digit',
                            omitZeroMinute: false,
                            meridiem: 'short'
                        }
                    ]}
                    locale={locale}
                    slotDuration="00:20:00"
                    allDaySlot={false}
                    nowIndicator={true}
                    dayHeaderFormat={{ weekday: 'long' }}
                    plugins={[ interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin ]}
                    selectOverlap={false}
                    initialView="timeGridWeek"
                    select={select}
                    selectAllow={selectAllow}
                    events={events}
                    height="calc(100vh - 360px)"
                    stickyHeaderDates={true}
                    eventClick={({ event }) => {
                        setEditDetail({
                            defaultValues: {
                                id: event.id,
                                startDate: event.start as any,
                                endDate: event.end as any,
                            }
                        })
                    }}
                    eventContent={(info) => (
                        <TimeTrackingEvent
                            timeText={info.timeText}
                            onDelete={() => {
                                timeTrackingDelete({ id: info.event.id }).then(() => {
                                    info.view.calendar.refetchEvents()
                                })
                            }}
                        />
                    )}
                    eventChange={eventChange}
                    eventConstraint={{
                        end: new Date(),
                    }}
                    slotLabelContent={({ date }) => {
                        const hasMinutes = date.getMinutes() > 0

                        if (hasMinutes) {
                            return null
                        }
                    
                        return (
                            <div className={clsxm('flex flex-col items-center text-gray-400')}>
                                <span className="text-xs font-semibold">{formatTime(date)}</span>
                            </div>
                        )
                    }}
                />
            </ApiErrorMessageFloating>

            <TimeTrackingEstimationList query={query} />

            <TimeTrackingStyles />
        </div>
    )
}

export default TimeTrackingView
