import * as React from 'react'
import { NavLink as RouterNavLink, NavLinkProps } from 'react-router-dom'

export type WrappedLinkProps = {
    path: string
} & Omit<NavLinkProps, 'to'> & React.RefAttributes<HTMLAnchorElement>

const NavLink = ({ path, ...rest }: WrappedLinkProps) => (
    <RouterNavLink to={path} {...rest} />
)

export default NavLink
