import * as React from 'react'

import EmbeddedContentContext from './EmbeddedContentContext'

const EmbeddedProvider = ({children}) => {
    const [container, setContainer] = React.useState<HTMLElement | null>(null)

    return (<>
        <EmbeddedContentContext.Provider value={{ container, setContainer }}>
            {children}
        </EmbeddedContentContext.Provider>
    </>
    )
}

export default EmbeddedProvider
