import * as React from 'react'

import { useTabs } from './context'
import { clsxm } from '../../lib/clsxm'

const classes = {
    tabContent: '',
    tabContentActive: '',
}

export type TabContentProps = {
    value: string
} & React.HTMLAttributes<HTMLDivElement>

const TabContent = React.forwardRef<HTMLDivElement, TabContentProps>(
    (props, ref) => {
        const { value, children, className, ...rest } = props

        const context = useTabs()
        const isSelected = value === context.value

        const tabContentClass = clsxm(
            classes.tabContent,
            isSelected && classes.tabContentActive,
            className
        )

        return (
            <div
                role='tabpanel'
                tabIndex={0}
                className={tabContentClass}
                ref={ref}
                {...rest}
            >
                {isSelected && children}
            </div>
        )
    }
)

export default TabContent
