/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { classValidatorResolver } from '@hookform/resolvers/class-validator'
import dateMapper from '@viterbit/web-app/dataAccess/mapper/date.mapper'
import { employeeCreate } from '@viterbit/web-app/dataAccess/service/employeeService'
import i18n from '@viterbit/web-app/i18n'
import Form from '@viterbit/web-app/shared/form/Form'
import FormDatePicker from '@viterbit/web-app/shared/form/FormDatePicker'
import FormSelectCompany from '@viterbit/web-app/shared/form/FormSelectCompanies'
import FormSelectLeavePolicy from '@viterbit/web-app/shared/form/FormSelectLeavePolicy'
import FormSelectLocation from '@viterbit/web-app/shared/form/FormSelectLocation'
import SubmitButton from '@viterbit/web-app/shared/form/SubmitButton'
import { Api } from 'types/Api'
import Button from 'ui/src/components/Button'
import { FormInput } from 'ui/src/components/Form'

import { EmployeeCreateFields } from './EmployeeCreateShared'
import EmployeeCreateValidator from './EmployeeCreateValidator'

type CreateEmployeeFormProps = {
    onSubmitCallBack?: (data: Api.ResponseSuccess) => void
    onCancel?: () => void
}

const resolver = classValidatorResolver(EmployeeCreateValidator)

const CreateEmployeeForm = ({
    onSubmitCallBack,
    onCancel,
}: CreateEmployeeFormProps) => (
    <Form<EmployeeCreateFields>
        withTransition
        formConfig={{
            resolver,
            mode: 'all',
        }}
        mutationConfig={{
            mutationFn: (data) =>
                employeeCreate({
                    createEmployeeCommand: {
                        ...data,
                        startDate: dateMapper.toDate(data.startDate)!,
                    }
                }),
            mutationKey: ['create-employee'],
            onSuccess: onSubmitCallBack,
        }}
    >
        {(form) => (
            <>
                <FormInput
                    label={i18n.t('employees:dashboard.createDialog.form.email.label')}
                    asterisk
                    name='email'
                    control={form.control}
                    data-testid='employee-email-input'
                />
                <FormInput
                    label={i18n.t('employees:dashboard.createDialog.form.name.label')}
                    asterisk
                    name='name'
                    control={form.control}
                    data-testid='employee-name-input'
                />
                <FormSelectCompany
                    label={i18n.t('employees:dashboard.createDialog.form.company.label')}
                    placeholder={i18n.t('employees:dashboard.createDialog.form.company.placeholder')}
                    asterisk
                    name='companyId'
                    control={form.control}
                />
                <FormSelectLocation
                    label={i18n.t('employees:dashboard.createDialog.form.location.label')}
                    placeholder={i18n.t('employees:dashboard.createDialog.form.location.placeholder')}
                    asterisk
                    name='locationId'
                    control={form.control}
                />
                <FormSelectLeavePolicy
                    label={i18n.t('employees:dashboard.createDialog.form.leavePolicy.label')}
                    placeholder={i18n.t('employees:dashboard.createDialog.form.leavePolicy.placeholder')}
                    asterisk
                    name='leavePolicyId'
                    control={form.control}
                />
                <FormDatePicker
                    label={i18n.t('employees:dashboard.createDialog.form.startDate.label')}
                    placeholder={i18n.t('employees:dashboard.createDialog.form.startDate.placeholder')}
                    asterisk
                    name='startDate'
                    control={form.control}
                />

                <Button onClick={onCancel}>{i18n.t('employees:dashboard.createDialog.buttons.cancel')}</Button>
                <SubmitButton>{i18n.t('employees:dashboard.createDialog.buttons.submit')}</SubmitButton>
            </>
        )}
    </Form>
)

export default CreateEmployeeForm
