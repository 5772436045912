import { Table } from '@mantine/core'
import { DEFAULT_REASON_COLOR } from '@viterbit/web-app/dataAccess/mapper/leaveReason.mapper'
import { DayConsumedPerReason } from '@viterbit/web-app/dataAccess/model/Summary.model'
import i18n from '@viterbit/web-app/i18n'
import * as React from 'react'

import SummaryTotalsBullet from './SummaryTotalsBullet'

type SummaryTotalsItemProps = {
    children?: React.ReactNode
    className?: string
    dayConsumedPerReason: DayConsumedPerReason
}

const SummaryTotalsItem = (props: SummaryTotalsItemProps) => {
    const { dayConsumedPerReason, children, className } = props

    return (

        <Table>
            <tr>

                <td>
                    <div className='flex items-start mb-1'>
                        <SummaryTotalsBullet
                            color={dayConsumedPerReason.color || DEFAULT_REASON_COLOR}
                        />
                        <span className='truncate text-grey-400 grow'>
                            {dayConsumedPerReason.name}
                        </span>
                    </div>
                </td>
                <td className='text-right text-black'>
                    {i18n.t('daysSummary:daysWithCount', {
                        count: dayConsumedPerReason.totalDays || 0,
                    })}

                </td>
            </tr>

        </Table>
    )
}

export default SummaryTotalsItem
