import { Location } from '@viterbit/web-app/dataAccess/model/Location.model'
import SegmentNavLink from '@viterbit/web-app/shared/navigation/SegmentNavLink'
import Avatar from 'ui/src/components/Avatar'
import List from 'ui/src/components/List'

export type LocationsItemType = {
    location: Location
}

const LocationsItem = (props: LocationsItemType) => {
    const { location } = props

    return (
        <SegmentNavLink path={`/settings/locations/${location.id}/details`}>
            <List.Item clickable>
                <Avatar
                    src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${location.country}.svg`}
                    alt={location.country}
                    shape="circle"
                    className='w-10 h-10 leading-10'
                />
                <List.ItemText primary={location.name} />
            </List.Item>
        </SegmentNavLink>
    )
}

export default LocationsItem
