import * as React from 'react'

export type LayoutHeadingProps = {
    title?: string
    subtitle?: string
}

const LayoutHeading = ({ title, subtitle }: LayoutHeadingProps) =>
    title || subtitle ? (
        <div className='flex flex-col items-center justify-center mb-4 text-center gap-y-1'>
            <h3>{title}</h3>
            <span>{subtitle}</span>
        </div>
    ) : null

export default LayoutHeading
