/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateEmployeeCommand
 */
export interface CreateEmployeeCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeCommand
     */
    companyId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeCommand
     */
    locationId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeCommand
     */
    leavePolicyId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeCommand
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeCommand
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeCommand
     */
    startDate: string;
}

/**
 * Check if a given object implements the CreateEmployeeCommand interface.
 */
export function instanceOfCreateEmployeeCommand(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "locationId" in value;
    isInstance = isInstance && "leavePolicyId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "startDate" in value;

    return isInstance;
}

export function CreateEmployeeCommandFromJSON(json: any): CreateEmployeeCommand {
    return CreateEmployeeCommandFromJSONTyped(json, false);
}

export function CreateEmployeeCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateEmployeeCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyId': json['companyId'],
        'locationId': json['locationId'],
        'leavePolicyId': json['leavePolicyId'],
        'name': json['name'],
        'email': json['email'],
        'startDate': json['startDate'],
    };
}

export function CreateEmployeeCommandToJSON(value?: CreateEmployeeCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companyId': value.companyId,
        'locationId': value.locationId,
        'leavePolicyId': value.leavePolicyId,
        'name': value.name,
        'email': value.email,
        'startDate': value.startDate,
    };
}

