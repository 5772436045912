import { useMutation } from '@tanstack/react-query'
import { locationDelete } from '@viterbit/web-app/dataAccess/service/locationService'

type LocationDeleteConfig = {
    onSuccess?: () => void
    onError?: () => void
}

export const useLocationDelete = (config: LocationDeleteConfig) =>
    useMutation({
        ...config,
        mutationFn: locationDelete,
        onSuccess: config.onSuccess,
        onError: config.onError,
    })
