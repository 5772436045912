/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmployeeEmergencyInformation
 */
export interface EmployeeEmergencyInformation {
    /**
     * Employee emergency name
     * @type {string}
     * @memberof EmployeeEmergencyInformation
     */
    name?: string | null;
    /**
     * Employee emergency email
     * @type {string}
     * @memberof EmployeeEmergencyInformation
     */
    email?: string | null;
    /**
     * Employee emergency phone number
     * @type {string}
     * @memberof EmployeeEmergencyInformation
     */
    phoneNumber?: string | null;
}

/**
 * Check if a given object implements the EmployeeEmergencyInformation interface.
 */
export function instanceOfEmployeeEmergencyInformation(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EmployeeEmergencyInformationFromJSON(json: any): EmployeeEmergencyInformation {
    return EmployeeEmergencyInformationFromJSONTyped(json, false);
}

export function EmployeeEmergencyInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeeEmergencyInformation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
    };
}

export function EmployeeEmergencyInformationToJSON(value?: EmployeeEmergencyInformation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'email': value.email,
        'phoneNumber': value.phoneNumber,
    };
}

