
import { PermissionAction } from '@viterbit/web-app/dataAccess/model/PermissionAction.model'
import i18n from '@viterbit/web-app/i18n'
import AsideCardInfo from '@viterbit/web-app/shared/display/AsideCardInfo'
import IsGranted from '@viterbit/web-app/shared/permission/IsGranted'

import DeleteCompany from './DeleteCompany'

const DeleteCompanyAction = ({ company }: any) => (
    <IsGranted id={company.id} action={PermissionAction.CORE_COMPANY_DELETE}>
        <AsideCardInfo
            className='pt-4 mb-4 border-t'
            title={i18n.t('settingCompanies:delete.title')}
            description=''
        >
            <DeleteCompany defaultValues={company} id={company.id} />
        </AsideCardInfo>
    </IsGranted>
)

export default DeleteCompanyAction
