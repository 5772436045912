/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { Complaint } from '@viterbit/web-app/dataAccess/model/Complaint.model'
import EmbeddedContent from '@viterbit/web-app/shared/behaviour/EmbeddedContent'
import { ChatBarFormProps } from '@viterbit/web-app/shared/feedback/Chat/ChatBarForm'
import { ComplaintJWT } from '@viterbit/web-app/shared/state/complaintState'
import FormSkeleton from 'ui/src/components/Form/FormSkeleton'

import ComplaintDetailView from './ComplaintDetailView'

type ComplaintDetailLayoutProps = {
    complaint?: Complaint
    isLoading: boolean
    jwtInfo?: ComplaintJWT
    actions?: React.ReactNode
    onSendMessage: ChatBarFormProps['onSubmit']
}

const ComplaintDetailLayout = (props: ComplaintDetailLayoutProps) => {
    const { complaint, isLoading, jwtInfo, actions, onSendMessage } = props

    return (
        <>
            <EmbeddedContent>
                {actions}
            </EmbeddedContent>

            {complaint && <ComplaintDetailView complaint={complaint} jwtInfo={jwtInfo} onSendMessage={onSendMessage}/>}
            {isLoading && <FormSkeleton count={2}/>}
        </>
    )
}

export default ComplaintDetailLayout
