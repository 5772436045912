
import { classValidatorResolver } from '@hookform/resolvers/class-validator'
import { Employee } from '@viterbit/web-app/dataAccess/model/Employee.model'
import { employeeUpdate } from '@viterbit/web-app/dataAccess/service/employeeService'
import i18n from '@viterbit/web-app/i18n'
import SimpleCard from '@viterbit/web-app/shared/display/SimpleCard'
import Form from '@viterbit/web-app/shared/form/Form'
import FormSelectCompany from '@viterbit/web-app/shared/form/FormSelectCompanies'
import FormSelectEmployee from '@viterbit/web-app/shared/form/FormSelectEmployee'
import FormSelectLeavePolicy from '@viterbit/web-app/shared/form/FormSelectLeavePolicy'
import FormSelectLocation from '@viterbit/web-app/shared/form/FormSelectLocation'
import SubmitButton from '@viterbit/web-app/shared/form/SubmitButton'
import React from 'react'
import FormInput from 'ui/src/components/Form/FormInput'

import { EmployeeProfileProfileValidation } from './EmployeeProfileProfileValidator'

export type EmployeeProfileProfileFields = {
    email: string
    managerId: string | null
    locationId: string
    leavePolicyId: string
    companyId: string
}

export type EmployeeProfileFormProps = {
    employee: Employee
    hasPermissionToUpdate: boolean
}

const resolver = classValidatorResolver(EmployeeProfileProfileValidation)

const getFormDefaultValues = (
    employee: Employee
): EmployeeProfileProfileFields => ({
    email: employee.email,
    managerId: employee.manager?.id || null,
    locationId: employee.location?.id || '',
    leavePolicyId: employee.leavePolicy?.id || '',
    companyId: employee.company?.id || '',
})

const EmployeeProfileProfileForm = (props: EmployeeProfileFormProps) => {
    const { employee, hasPermissionToUpdate } = props

    return (
        <SimpleCard padding>
            <Form<EmployeeProfileProfileFields>
                smallButtons
                withTransition
                mutationConfig={{
                    mutationKey: ['update-employee'],
                    mutationFn: (updateEmployeeCommand) => employeeUpdate({
                        id: employee.id,
                        updateEmployeeCommand,
                    }),
                }}
                formConfig={{
                    resolver,
                    mode: 'all',
                    defaultValues: getFormDefaultValues(employee),
                }}
            >
                {(form) => (
                    <>
                        <FormInput
                            label={i18n.t('employees:employee.profile.profileInfo.form.email.label')}
                            placeholder={i18n.t('employees:employee.profile.profileInfo.form.email.placeholder')}
                            asterisk
                            name='email'
                            control={form.control}
                            disabled
                        />

                        <FormSelectCompany
                            label={i18n.t('employees:employee.profile.profileInfo.form.company.label')}
                            placeholder={i18n.t('employees:employee.profile.profileInfo.form.company.placeholder')}
                            asterisk
                            name='companyId'
                            control={form.control}
                            defaultValue={employee.company}
                            isDisabled={!hasPermissionToUpdate}
                        />

                        {
                            hasPermissionToUpdate &&
                        <FormSelectEmployee
                            label={i18n.t('employees:employee.profile.profileInfo.form.manager.label')}
                            placeholder={i18n.t('employees:employee.profile.profileInfo.form.manager.placeholder')}
                            name='managerId'
                            isClearable
                            excludeIds={[employee.id]}
                            control={form.control}
                            defaultValue={employee.manager}
                        />
                        }
                    
                        <FormSelectLocation
                            label={i18n.t('employees:employee.profile.profileInfo.form.location.label')}
                            placeholder={i18n.t('employees:employee.profile.profileInfo.form.location.placeholder')}
                            asterisk
                            name='locationId'
                            control={form.control}
                            defaultValue={employee.location}
                            isDisabled={!hasPermissionToUpdate}
                        />
                    
                        {
                            hasPermissionToUpdate &&
                        <FormSelectLeavePolicy
                            label={i18n.t('employees:employee.profile.profileInfo.form.leavePolicy.label')}
                            placeholder={i18n.t('employees:employee.profile.profileInfo.form.leavePolicy.placeholder')}
                            asterisk
                            name='leavePolicyId'
                            control={form.control}
                            defaultValue={employee.leavePolicy}
                        />
                        }
                        {
                            hasPermissionToUpdate &&
                        <SubmitButton data-testid='employee-personal-submit-button'>{i18n.t('employees:employee.buttons.save')}</SubmitButton>
                        }
                    </>
                )}
            </Form>
        </SimpleCard>
    )
}

export default EmployeeProfileProfileForm
