/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Schedule } from './Schedule';
import {
    ScheduleFromJSON,
    ScheduleFromJSONTyped,
    ScheduleToJSON,
} from './Schedule';

/**
 * 
 * @export
 * @interface WorkSchedulePeriod
 */
export interface WorkSchedulePeriod {
    /**
     * 
     * @type {string}
     * @memberof WorkSchedulePeriod
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof WorkSchedulePeriod
     */
    endDate: string;
    /**
     * 
     * @type {Schedule}
     * @memberof WorkSchedulePeriod
     */
    schedule: Schedule;
}

/**
 * Check if a given object implements the WorkSchedulePeriod interface.
 */
export function instanceOfWorkSchedulePeriod(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "startDate" in value;
    isInstance = isInstance && "endDate" in value;
    isInstance = isInstance && "schedule" in value;

    return isInstance;
}

export function WorkSchedulePeriodFromJSON(json: any): WorkSchedulePeriod {
    return WorkSchedulePeriodFromJSONTyped(json, false);
}

export function WorkSchedulePeriodFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkSchedulePeriod {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startDate': json['startDate'],
        'endDate': json['endDate'],
        'schedule': ScheduleFromJSON(json['schedule']),
    };
}

export function WorkSchedulePeriodToJSON(value?: WorkSchedulePeriod | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'startDate': value.startDate,
        'endDate': value.endDate,
        'schedule': ScheduleToJSON(value.schedule),
    };
}

