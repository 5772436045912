/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AssignBulkPendingFileCommand
 */
export interface AssignBulkPendingFileCommand {
    /**
     * 
     * @type {string}
     * @memberof AssignBulkPendingFileCommand
     */
    type?: AssignBulkPendingFileCommandTypeEnum | null;
}


/**
 * @export
 */
export const AssignBulkPendingFileCommandTypeEnum = {
    Payroll: 'payroll',
    Contract: 'contract',
    Document: 'document',
    Leave: 'leave'
} as const;
export type AssignBulkPendingFileCommandTypeEnum = typeof AssignBulkPendingFileCommandTypeEnum[keyof typeof AssignBulkPendingFileCommandTypeEnum];


/**
 * Check if a given object implements the AssignBulkPendingFileCommand interface.
 */
export function instanceOfAssignBulkPendingFileCommand(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AssignBulkPendingFileCommandFromJSON(json: any): AssignBulkPendingFileCommand {
    return AssignBulkPendingFileCommandFromJSONTyped(json, false);
}

export function AssignBulkPendingFileCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssignBulkPendingFileCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function AssignBulkPendingFileCommandToJSON(value?: AssignBulkPendingFileCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
    };
}

