import i18n from '@viterbit/web-app/i18n'
import LayoutHeading from 'ui/src/components/LayoutHeading'

import LoginForm from './LoginForm'

const LoginView =  () => (
    <>
        <LayoutHeading
            title={i18n.t('auth:signIn.title')}
            subtitle={i18n.t('auth:signIn.subtitle')}
        />
        <LoginForm />
    </>
)

export default LoginView
