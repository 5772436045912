/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PublicEmployee } from './PublicEmployee';
import {
    PublicEmployeeFromJSON,
    PublicEmployeeFromJSONTyped,
    PublicEmployeeToJSON,
} from './PublicEmployee';

/**
 * 
 * @export
 * @interface PendingFileQueryResponse
 */
export interface PendingFileQueryResponse {
    /**
     * 
     * @type {string}
     * @memberof PendingFileQueryResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PendingFileQueryResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PendingFileQueryResponse
     */
    mimeType: string;
    /**
     * 
     * @type {string}
     * @memberof PendingFileQueryResponse
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof PendingFileQueryResponse
     */
    targetType: string;
    /**
     * 
     * @type {number}
     * @memberof PendingFileQueryResponse
     */
    size: number;
    /**
     * 
     * @type {string}
     * @memberof PendingFileQueryResponse
     */
    extension?: string;
    /**
     * 
     * @type {string}
     * @memberof PendingFileQueryResponse
     */
    url?: string | null;
    /**
     * 
     * @type {PublicEmployee}
     * @memberof PendingFileQueryResponse
     */
    employee?: PublicEmployee;
}

/**
 * Check if a given object implements the PendingFileQueryResponse interface.
 */
export function instanceOfPendingFileQueryResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "mimeType" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "targetType" in value;
    isInstance = isInstance && "size" in value;

    return isInstance;
}

export function PendingFileQueryResponseFromJSON(json: any): PendingFileQueryResponse {
    return PendingFileQueryResponseFromJSONTyped(json, false);
}

export function PendingFileQueryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PendingFileQueryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'mimeType': json['mimeType'],
        'type': json['type'],
        'targetType': json['targetType'],
        'size': json['size'],
        'extension': !exists(json, 'extension') ? undefined : json['extension'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'employee': !exists(json, 'employee') ? undefined : PublicEmployeeFromJSON(json['employee']),
    };
}

export function PendingFileQueryResponseToJSON(value?: PendingFileQueryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'mimeType': value.mimeType,
        'type': value.type,
        'targetType': value.targetType,
        'size': value.size,
        'extension': value.extension,
        'url': value.url,
        'employee': PublicEmployeeToJSON(value.employee),
    };
}

