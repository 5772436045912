import { Button, ButtonProps } from '@mantine/core'
import { IconPlus } from '@tabler/icons-react'
import i18n from '@viterbit/web-app/i18n'
import * as React from 'react'

type ButtonCreateProps = {
    children?: React.ReactNode
    className?: string
    title?: string
    onClick?: (event: any) => void
    collapsed?: boolean
    IconComponent?: any
} & Pick<ButtonProps, 'color' | 'size' | 'radius' | 'loading' | 'variant'>

const ButtonCreate = (props: ButtonCreateProps) => {
    const { title = i18n.t('buttons.create'), onClick, collapsed, IconComponent = IconPlus, ...rest } = props

    return (
        <Button
            leftIcon={!collapsed && <IconComponent stroke={2} size="20" />}
            color="primary"
            variant={collapsed ? 'light': 'filled'}
            onClick={onClick}
            size={collapsed ? 'xs' : 'sm'}
            {...rest}
        >
            {title}
        </Button>
    )
}

export default ButtonCreate
