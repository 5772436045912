import { useQuery } from '@tanstack/react-query'

import { JOB_POSITION_QUERY_KEY } from '../../cacheKeys'
import { jobPositionFind } from '../../service/jobPositionService'

type AccountDetailConfig = {
    query: Parameters<typeof jobPositionFind>[0]
    enabled?: boolean
}

export const useJobPositionDetail = ({ query, enabled }: AccountDetailConfig) =>
    useQuery({
        queryFn: () => jobPositionFind(query), 
        queryKey: [JOB_POSITION_QUERY_KEY, 'detail', query],
        enabled
    })

