/* eslint-disable @typescript-eslint/no-explicit-any */
import { Badge, Loader } from '@mantine/core'
import { IconDownload } from '@tabler/icons-react'
import useFileDownload from '@viterbit/web-app/dataAccess/useCase/file/useFileDownload'
import * as React from 'react'
import { IFile } from 'ui/src/components/Upload/sharedTypes'


type ChatBarFilesProps = {
    className?: string
    files: IFile[]
    children?: React.ReactNode | React.ReactNode[] | null | undefined | string
    variant: 'left' | 'right'
}

const styles = {
    root: 'flex gap-2 flex-wrap',
    variant: {
        left: 'flex-row',
        right: 'flex-row-reverse'
    }
} as const


type ChatBarFileProps = {
    className?: string
    file: IFile
    children?: React.ReactNode | React.ReactNode[] | null | undefined | string
    variant: 'left' | 'right'
}

const ChatBarMessageFile = (props: ChatBarFileProps) => {
    const { file, variant } = props

    const { loading, open } = useFileDownload()

    return (
        <>
            <Badge
                onClick={() => open(file)}
                color={variant === 'left' ? 'gray.1' : 'primary.1'}
                variant="filled"
                sx={{ color: 'dark', height: 28, ':active': { transform: 'translateY(1px)' }, cursor: 'pointer' }}
                leftSection={loading ? <Loader size={18} color='gray' /> : <IconDownload className='text-gray-500' size={18}/>}
            >
                <a target="_blank" rel="noreferrer" className='text-gray-500' data-testid="chat-file">
                    {file.name}
                </a>
            </Badge>
        </>

    )
}

const ChatBarMessageFiles = (props: ChatBarFilesProps) => {
    const { files, className, variant } = props

    if (!files.length) return null

    return (
        <>
            {files.map((file) => (
                <ChatBarMessageFile 
                    key={file.id}
                    file={file}
                    variant={variant}
                />
            ))}
        </>

    )
}

export default ChatBarMessageFiles
