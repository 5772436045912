/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { fileCreate } from '@viterbit/web-app/dataAccess/service/filesService'
import i18n from '@viterbit/web-app/i18n'
import toArray from 'lodash/toArray'
import * as React from 'react'
import { useQueue } from 'react-use'
import { IFile } from 'ui/src/components/Upload/sharedTypes'
import { ResourceCreated,UploadFileTypeEnum } from 'viterbit-api-sdk'

import { useNotification } from '../feedback/useNotification'
import { getErrorCode } from '../utils/getErrorCode'
import fileMapper from '../../dataAccess/mapper/file.mapper'
import { FileModel } from '../../dataAccess/model/File.model'

export type UploadFilesConfig = {
    defaultValues?: FileModel[] | FileModel
    mapper?: {
        modelToIFile: (model: any) => IFile,
        iFileToUploadPayload: (file: IFile, kind: string, employeeId?: string) => any
    }
    maxUploadSize?: number
    createService?: (body: { file: Blob }) => Promise<ResourceCreated>
    employeeId?: string
    kind: UploadFileTypeEnum
    cleanSuccess?: boolean
    onUploadSuccess?: (id: IFile) => void
    multiple?: boolean
}

type OnUploadFileResponse = {
    ok: boolean
    id?: string
    error?: string
}

type QueueType = {
    id: string
    response: OnUploadFileResponse
    controllerOnChange: (v: unknown) => void
}

const handleUploadSizeLimit = (
    maxUploadSize: number,
    onError: (error: any) => void,
) =>  (newFileList: IFile[], oldFileList?:IFile[]) => {
    const isLimitedExceeded = newFileList.reduce((acc, file) => {
        if (file.size > maxUploadSize) {
            return true
        }
        return acc
    }, false)

    if (isLimitedExceeded) {
        const error = i18n.t('common:components.form_upload.errors.file_size_limit')
        onError(error)
        return error
    }
    return undefined
}

const useUploadFiles = (config: UploadFilesConfig) => {
    const {
        defaultValues,
        mapper = fileMapper,
        cleanSuccess,
        maxUploadSize = 10485760,
        createService = fileCreate,
        onUploadSuccess,
        employeeId,
        kind,
        multiple,
    } = config

    const [files, setFiles] = React.useState<IFile[]>(toArray(defaultValues).map(mapper.modelToIFile))
    const notification = useNotification()
    const queue = useQueue<QueueType>([])

    const LimitChecker = handleUploadSizeLimit(
        maxUploadSize,
        (error) => notification.push(
            'danger',
            i18n.t('common:components.form_upload.errors.file_size_error'),
            error
        ),
    )

    const handleOnChange = (
        value: IFile[],
        controllerOnChange: (v: unknown) => void
    ) => {
        setFiles(multiple ? [...files, ...value]: [...value])
        value.forEach(
            (file) =>
                file._meta &&
                createService(
                    mapper.iFileToUploadPayload(file, kind, employeeId)
                )
                    .then(({ id }) =>
                        queue.add({
                            id: file.id!,
                            response: { ok: true, id },
                            controllerOnChange,
                        })
                    )
                    .catch((error) => {
                        const errorCode = getErrorCode(error)
                        const errText = errorCode
                            ? i18n.t(`common:components.form_upload.errors.api.${errorCode}`)
                            : i18n.t('common:components.form_upload.errors.error_ocurred')
                        notification.push(
                            'danger',
                            i18n.t('common:components.form_upload.errors.file_error'),
                            errText
                        )
                        queue.add({
                            id: file.id!,
                            response: { ok: false, error: errText },
                            controllerOnChange,
                        })
                    })
        )
    }

    React.useEffect(() => {
        if (!queue.size) return
        const { id, response, controllerOnChange } = queue.first
        onUploadedFile(id, response, controllerOnChange)
        queue.remove()
    }, [queue])

    const setControllerFieldValue = (
        controllerOnChange: (v: unknown) => void,
        _files?: IFile[]
    ) => {
        const filesOk = (_files ?? files)
            .filter((item) => !item._meta || item._meta?.status === 'ok')
            .map((item) => item.id)
        const newValue = multiple ? filesOk : filesOk.pop()
        controllerOnChange(newValue)
    }

    const onUploadedFile = (
        id: string,
        response: OnUploadFileResponse,
        controllerOnChange: (v: unknown) => void
    ) => {
        const file = files.find((item) => item.id === id)
        if (!file) throw new Error('Error at onUploadFile')
        const status = response.ok && response.id ? 'ok' : 'error'
        const error = !response.ok
            ? response.error ?? i18n.t('common:components.form_upload.errors.error_ocurred')
            : undefined
        let _files: IFile[] = files.map((item) =>
            item.id !== id
                ? item
                : {
                    ...item,
                    id: response?.id ?? id,
                    _meta: item._meta && {
                        ...item._meta,
                        status,
                        error,
                    },
                }
        )

        if (cleanSuccess) {
            _files = _files.filter((file) => file._meta?.status !== 'ok')
        }
        !error && onUploadSuccess?.(file)
        setFiles(_files)
        setControllerFieldValue(controllerOnChange, _files)
    }

    const handleOnFileRemove = (
        id: string,
        controllerOnChange: (v: unknown) => void
    ) => {
        const _files = files.filter((item) => item.id !== id)
        setFiles(_files)
        const filesOk = _files
            .filter((item) => !item._meta || item._meta?.status === 'ok')
            .map((item) => item.id)
        controllerOnChange(multiple ? filesOk : filesOk.shift())
    }

    const isLoading = React.useMemo<boolean>(
        () => files.some((x) => x._meta?.status === 'pending'),
        [files]
    )

    const retryUploadFile = (
        file: IFile,
        controllerOnChange: (v: unknown) => void
    ) => {
        if (!file._meta || file._meta?.status === 'ok') return
        setFiles(
            files.map((x) =>
                x.id !== file.id
                    ? x
                    : {
                        ...x,
                        _meta: {
                            file: x._meta!.file,
                            status: 'pending',
                        },
                    }
            )
        )
        createService(mapper.iFileToUploadPayload(file, kind, employeeId))
            .then(({ id }) => {
                onUploadedFile(file.id!, { ok: true, id }, controllerOnChange)
            })
            .catch((error) => {
                const errorCode = getErrorCode(error)
                const errText = errorCode
                    ? i18n.t(`common:components.form_upload.errors.api.${errorCode}`)
                    : i18n.t('common:components.form_upload.errors.error_ocurred')
                notification.push(
                    'danger',
                    i18n.t('common:components.form_upload.errors.file_error'),
                    errText
                )
                onUploadedFile(
                    file.id!,
                    { ok: false, error: errText },
                    controllerOnChange
                )
            })
    }

    return {
        files,
        handleOnChange,
        handleOnFileRemove,
        retryUploadFile,
        LimitChecker,
        isLoading,
    }
}

export default useUploadFiles