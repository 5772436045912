/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateComplaintRecordCommand
 */
export interface CreateComplaintRecordCommand {
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateComplaintRecordCommand
     */
    fileIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateComplaintRecordCommand
     */
    message: string;
}

/**
 * Check if a given object implements the CreateComplaintRecordCommand interface.
 */
export function instanceOfCreateComplaintRecordCommand(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "fileIds" in value;
    isInstance = isInstance && "message" in value;

    return isInstance;
}

export function CreateComplaintRecordCommandFromJSON(json: any): CreateComplaintRecordCommand {
    return CreateComplaintRecordCommandFromJSONTyped(json, false);
}

export function CreateComplaintRecordCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateComplaintRecordCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fileIds': json['fileIds'],
        'message': json['message'],
    };
}

export function CreateComplaintRecordCommandToJSON(value?: CreateComplaintRecordCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fileIds': value.fileIds,
        'message': value.message,
    };
}

