import { useQuery } from '@tanstack/react-query'
import { Api } from 'types/Api'

import { COMPLAINT_KEY } from '../../cacheKeys'
import { complaintPrivateDetail } from '../../service/complaintService'

type ComplaintDetailConfig = {
    enabled?: boolean
    query: Parameters<typeof complaintPrivateDetail>[0]
}

export const useComplaintPrivateDetail = ({ enabled, query }: ComplaintDetailConfig) =>
    useQuery<Awaited<ReturnType<typeof complaintPrivateDetail>>, {response: Api.ResponseError}>({
        queryFn: () => complaintPrivateDetail(query), 
        queryKey: [COMPLAINT_KEY, 'detail', query],
        enabled
    })

