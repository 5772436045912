/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { classValidatorResolver } from '@hookform/resolvers/class-validator'
import { complaintPublicLogin } from '@viterbit/web-app/dataAccess/service/complaintService'
import i18n from '@viterbit/web-app/i18n'
import Form from '@viterbit/web-app/shared/form/Form'
import SubmitButton from '@viterbit/web-app/shared/form/SubmitButton'
import { NavLink, useNavigate, useParams } from 'react-router-dom'

import { ComplaintLoginFields } from './ComplaintLoginFields'
import ComplaintLoginFormFields from './ComplaintLoginFormFields'
import ComplaintLoginFormValidator from './ComplaintLoginFormValidator'
import { useComplaintOrganization } from '../useComplaintOrganization'
import FormSkeleton from 'ui/src/components/Form/FormSkeleton'
import SegmentNavLink from '@viterbit/web-app/shared/navigation/SegmentNavLink'

const resolver = classValidatorResolver(ComplaintLoginFormValidator)

const ComplaintLoginForm = () => {
    const navigate = useNavigate()
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const { data: organization, isInitialLoading } = useComplaintOrganization()

    if (isInitialLoading) {
        return <FormSkeleton count={1} className='w-[300px]' />
    }

    return (
        <>
            <Form<ComplaintLoginFields, Awaited<ReturnType<typeof complaintPublicLogin>>>
                withTransition
                formConfig={{
                    mode: 'all',
                    resolver,
                }}
                mutationConfig={{
                    onSuccess: (data) => {
                        navigate(`/complaints/detail/${data.complaintId}`)
                    },
                    mutationFn: (data) => complaintPublicLogin({
                        loginComplaintQuery: {
                            complaintId: data.id,
                            password: data.password,
                        }
                    })
                }}
            >
                {(form) => (
                    <>
                        <ComplaintLoginFormFields form={form} />
                        <SubmitButton data-testid='button-complaint'>{i18n.t('complaint:login.form.submit')}</SubmitButton>
                    </>
                )}
            </Form>

            <div className='mt-4 text-center'>
                <span>{i18n.t('complaint:login.form.newComplaint')}</span>
                <SegmentNavLink path={`/complaints`} className='ml-2 text-primary-600'>
                    {i18n.t('complaint:login.form.createComplaint')}
                </SegmentNavLink>
            </div>
        </>
    )
}

export default ComplaintLoginForm
