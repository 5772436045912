
import i18n from '@viterbit/web-app/i18n'
import SettingsLayout from '@viterbit/web-app/shared/layout/SettingsLayout'
import * as React from 'react'
import { Outlet } from 'react-router-dom'

import CreateLocationDialog from './list/CreateLocations'
import LocationsList from './list/LocationsList'
import { useLocation } from './useLocation'

const LocationsLayout = () => {
    const { data } = useLocation()
    const title = data?.name || i18n.t('navigation:navbar.settings.locations')
    return (
        <>
            <SettingsLayout
                leftTitle={i18n.t('settingLocations:locations')}
                leftActions={<CreateLocationDialog />}
                title={title}
                left={<LocationsList />}
            >
                <Outlet />
            </SettingsLayout>
        </>
    )
}

export default LocationsLayout

