/* eslint-disable react/no-array-index-key */
import * as React from 'react'

import Skeleton from '../Skeleton'
import clsxm from '../../lib'

export type CalendarItemSkeletonProps = {
    className?: string
    header?: boolean
}

const styles = {
    root: 'w-full min-w-[250px] py-4',
    items: 'grid grid-cols-7 gap-4 mt-10',
}

const CalendarItemSkeleton = ({header, className}: CalendarItemSkeletonProps) => (
    <div className={clsxm(styles.root, className)}>
        {header && <Skeleton className="w-20 h-5"/>}
        <div className={clsxm(styles.items)}>
            {Array.from({ length: 30 }).map((_, index) =>
                <Skeleton key={index} className={clsxm('w-5 h-4', !index && 'col-start-4')}/>
            )}
        </div>
    </div>

)

export default CalendarItemSkeleton
