/* eslint-disable @typescript-eslint/no-explicit-any */
import { WorkSchedule, WorkScheduleDays, WorkSchedulePeriod, WorkScheduleTypes } from '@viterbit/web-app/dataAccess/model/WorkSchedule.model'
import i18n from '@viterbit/web-app/i18n'
import AsideCardInfo from '@viterbit/web-app/shared/display/AsideCardInfo'
import ButtonCreate from '@viterbit/web-app/shared/form/ButtonCreate'
import * as React from 'react'
import { useFieldArray,UseFormReturn } from 'react-hook-form'
import clsxm from 'ui/src/lib'

import WorkScheduleDetailPeriodCreateDialog from './WorkScheduleDetailPeriodCreateDialog'
import WorkScheduleDetailPeriodFieldItem from './WorkScheduleDetailPeriodFieldItem'

type WorkScheduleDetailBasicFieldsProps = {
    children?: React.ReactNode
    className?: string
    form: UseFormReturn<any>
    defaultValues?: WorkSchedule
    showActions?: boolean
}

const styles = {
    root: ''
}

const options = WorkScheduleTypes.map(type => ({
    label: i18n.t(`settingWorkSchedule:fields.type.options.${type}`),
    value: type
}))

const emptyPeriod = WorkScheduleDays.reduce<WorkSchedulePeriod>((output, day) => {
    output.schedule[day] = [
        {
            from: '',
            to: '',
        } as any
    ]
    return output
}, 
{
    startDate: new Date(),
    endDate: new Date(),
    schedule: {} as any,
})

const WorkScheduleDetailPeriodFields = (props: WorkScheduleDetailBasicFieldsProps) => {
    const { form, className, showActions } = props

    const { fields: periods, append, remove } = useFieldArray({
        control: form.control,
        name: 'periods'
    })

    const [create, setCreate] = React.useState(false)

    return (
        <>
            <AsideCardInfo
                className={clsxm(styles.root, className)}
                title={i18n.t('settingWorkSchedule:detail.periodTitle')}
                description={i18n.t('settingWorkSchedule:detail.periodDescription')}
                actions={showActions && (
                    <ButtonCreate
                        collapsed
                        data-testid='work-schedule-detail-periods-create-button'
                        onClick={() => setCreate(true)}
                        title={i18n.t('settingWorkSchedule:detail.addButton')}
                    />
                )}
                
            >
                <WorkScheduleDetailPeriodFieldItem
                    showActions={showActions}
                    title={i18n.t('settingWorkSchedule:fields.periods.mainTitle')}
                    name='mainPeriod'
                    form={form}
                />

                {periods.map((field, index) => (
                    <WorkScheduleDetailPeriodFieldItem
                        showActions={showActions}
                        key={field.id}
                        title={i18n.t('settingWorkSchedule:fields.periods.secondaryTitle', { count: index + 1 })}
                        name={`periods.${index}`}
                        form={form}
                        onRemove={() => remove(index)}
                    />
                ))}

            </AsideCardInfo>

            <WorkScheduleDetailPeriodCreateDialog
                isOpen={create}
                onClose={() => setCreate(false)}
                onConfirm={(period) => {
                    append({...emptyPeriod, ...period})
                    setCreate(false)
                }}
            />

        </>


    )
}

export default WorkScheduleDetailPeriodFields
