
import { classValidatorResolver } from '@hookform/resolvers/class-validator'
import i18n from '@viterbit/web-app/i18n'
import Form, { FormProps } from '@viterbit/web-app/shared/form/Form'
import SubmitButton from '@viterbit/web-app/shared/form/SubmitButton'
import * as React from 'react'
import { DeepPartial, FieldValues, Resolver } from 'react-hook-form'
import { Api } from 'types/Api'
import Button from 'ui/src/components/Button'
import FormInput from 'ui/src/components/Form/FormInput'

import JobPositionLevelCreateValidator from './JobPositionLevelCreateValidator'

const defaultResolver = classValidatorResolver(JobPositionLevelCreateValidator)

const JobPositionLevelCreateForm = <FormValues extends FieldValues = Api.PayloadBase>({
    cancelButtonText = 'cancel',
    submitButtonText = 'create',
    resolver = defaultResolver,
    defaultValues,
    positionId,
    onCancel,
    ...rest
}: Omit<FormProps<FormValues>, 'children'> & {
    positionId: string
    submitButtonText?: string
    cancelButtonText?: string
    resolver?: Resolver<FormValues, any>,
    defaultValues?: DeepPartial<FormValues>,
    onCancel: React.MouseEventHandler<HTMLButtonElement>
}) => (
        <Form<FormValues> 
            withTransition
            formConfig={{
                resolver,
                mode: 'all',
                defaultValues
            }}
            {...rest}
        >
            {
                (formMethods) => (
                    <>
                        <FormInput
                            asterisk
                            name='name'
                            label={i18n.t('employees:jobPositions.levels.nameLabel')}
                            placeholder={i18n.t('employees:jobPositions.levels.namePlaceholder')}
                            control={formMethods.control}
                        />

                        <FormInput
                            textArea
                            size='lg'
                            name='description'
                            control={formMethods.control}
                            label={i18n.t('employees:jobPositions.levels.descriptionLabel')}
                            placeholder={i18n.t('employees:jobPositions.levels.descriptionPlaceholder')}
                        />

                        <Button onClick={onCancel}>{cancelButtonText !== 'cancel' ? cancelButtonText : i18n.t('common:buttons.' + cancelButtonText)}</Button>
                        <SubmitButton>{submitButtonText !== 'create' ? submitButtonText : i18n.t('common:buttons.' + submitButtonText)}</SubmitButton>
                    </>
                )
            }
        </Form>
    )

export default JobPositionLevelCreateForm