/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { PermissionAction, permissionActionKeys } from '@viterbit/web-app/dataAccess/model/PermissionAction.model'
import { authPermissions } from '@viterbit/web-app/dataAccess/service/authService'
import cloneDeep from 'lodash/cloneDeep'
import { atom, selectorFamily } from 'recoil'
import { getRecoil,setRecoil } from 'recoil-nexus'
import { FetchPermissionsRequest, PermissionsQueryResponse } from 'viterbit-api-sdk'


type PermissionTreeContent ={
    [key: string]: boolean
}

type PermissionTree = Record<PermissionAction, PermissionTreeContent>

export type PermissionTreeConfig = Partial<PermissionTree>

export type PermissionTreeParams = Partial<Record<PermissionAction, string[]>>

export type PermissionTreeQuery = Required<FetchPermissionsRequest>['permissionsQuery']['data']

export type PermissionTreeResponse = Required<PermissionsQueryResponse>['data']
 
const defaultValues = permissionActionKeys.reduce((acc, key) => {
    acc[key] = {}
    return acc
}, {} as PermissionTree)

export const permissionState = atom<PermissionTree>({
    key: 'permission',
    default: defaultValues
})

const selectCurrentPermissions = () => getRecoil(permissionState)

const filterCachedPermissions = (tree: PermissionTreeParams) => {
    const currentCache = selectCurrentPermissions()
    const permissionKeys = Object.keys(tree) as PermissionAction[]

    const missingPermissions = permissionKeys.reduce<PermissionTreeParams>((acc, key) => {
        const currentCacheEmployee = currentCache[key]
        const currentTreeEmployee = tree[key] as string[]
        const missingEmployeePermissions = currentTreeEmployee.reduce<string[]>((acc, employeeId) => {
            if (!currentCacheEmployee[employeeId]) {
                acc.push(employeeId)
            }
            return acc
        }, [])

        if (missingEmployeePermissions.length) {
            acc[key] = missingEmployeePermissions
        }

        return acc
    }, {})

    return missingPermissions
}

const parsePermissionTreeParams = (tree: PermissionTreeParams): PermissionTreeConfig => {
    const permissionKeys = Object.keys(tree) as PermissionAction[]

    const parsedTree = permissionKeys.reduce<PermissionTreeConfig>((acc, key) => {
        const currentTreeEmployee = tree[key] as string[]
        const parsedEmployee = currentTreeEmployee.reduce<Record<string, boolean>>((acc, employeeId) => {
            acc[employeeId] = true
            return acc
        }, {})

        acc[key] = parsedEmployee
        return acc
    }, {})

    return parsedTree
}

const parsePermissionTree = (tree: PermissionTreeParams): PermissionTreeConfig => {
    const permissionKeys = Object.keys(tree) as PermissionAction[]

    const parsedTree = permissionKeys.reduce<PermissionTreeConfig>((acc, key) => {
        const currentTreeEmployee = tree[key] as string[]
        const parsedEmployee = currentTreeEmployee.reduce<Record<string, boolean>>((acc, employeeId) => {
            acc[employeeId] = true
            return acc
        }, {})

        acc[key] = parsedEmployee
        return acc
    }, {})

    return parsedTree
}

const savePermissionResponse = (response: PermissionTreeResponse) => {
    const cachedPermissions = cloneDeep(selectCurrentPermissions())

    const newCache = response.reduce<PermissionTree>((acc, responseItem) => {
        const action = responseItem.action as PermissionAction

        responseItem.value.forEach(({ value, id }) => {
            acc[action] = {
                ...acc[action],
                [id]: value,
            }
        })

        return acc
    }, cachedPermissions)

    setRecoil(permissionState, newCache)

    return newCache
}

const savePermissionTree = (tree: PermissionTreeConfig) => {
    const currentCache = selectCurrentPermissions()
    const permissionKeys = Object.keys(tree) as PermissionAction[]
    const newCache = permissionKeys.reduce<PermissionTree>((acc, key) => {
        acc[key] = {
            ...acc[key],
            ...tree[key],
        }
        return acc
    }, currentCache)
    setRecoil(permissionState, newCache)
}

export const selectPermissionState = selectorFamily<PermissionTreeConfig, PermissionTreeParams>({
    key: 'select-permission',
    get: (params) => async ({ get }) => {
        const missingPermissions = filterCachedPermissions(params)

        const payload = Object.entries(missingPermissions).reduce<PermissionTreeQuery>((acc, [action, value]) => {
            acc.push({
                action,
                value,
            })

            return acc
        }, [])

        if (payload.length) {
            const response = await authPermissions({
                permissionsQuery: {
                    data: payload,
                }
            })

            return savePermissionResponse(response.data)
        }

        return get(permissionState)
    }
})

export const hasPermissionState = ({ ids, action}: {ids: string[], action: PermissionAction}) => selectPermissionState({
    [action]: ids
})

export const clearPermissions = () => {
    setRecoil(permissionState, defaultValues)
}
