import { classValidatorResolver } from '@hookform/resolvers/class-validator'
import { Account } from '@viterbit/web-app/dataAccess/model/Account.model'
import { accountChangePassword } from '@viterbit/web-app/dataAccess/service/accountService'
import i18n from '@viterbit/web-app/i18n'
import SimpleCard from '@viterbit/web-app/shared/display/SimpleCard'
import Form from '@viterbit/web-app/shared/form/Form'
import SubmitButton from '@viterbit/web-app/shared/form/SubmitButton'
import * as React from 'react'
import FormPasswordInput from 'ui/src/components/Form/FormPasswordInput'
import FormPasswordInputValidated from 'ui/src/components/Form/FormPasswordInputValidated'

import { AccountSettingsPasswordValidator } from './AccountSettingsValidator'


export type ProfilePasswordSettingsFormFields = {
    id: string
    password: string
    confirmPassword: string
}

export type AccountSettingsPasswordFormProps = {
    account: Account,
    onSuccessUpdate: () => void
}

const resolver = classValidatorResolver(AccountSettingsPasswordValidator)

const AccountSettingsPasswordForm = ({
    account,
    onSuccessUpdate
}: AccountSettingsPasswordFormProps) => (
    <SimpleCard padding>
        <Form<ProfilePasswordSettingsFormFields>
            smallButtons
            withTransition
            resetOnSuccess
            mutationConfig={{
                mutationFn: (data) => accountChangePassword({
                    id: account.id,
                    changePasswordCommand: {
                        password: data.password,
                    }
                }),
                onSuccess: onSuccessUpdate
            }}
            formConfig={{
                resolver,
                mode: 'all'
            }}
        >
            {(form) => (
                <>
                    <FormPasswordInputValidated
                        asterisk
                        name='password'
                        control={form.control}
                        label={i18n.t('settings:passwordSection.passLabel')}
                        placeholder={i18n.t('settings:passwordSection.passPlaceholder')}
                    />
                    <FormPasswordInput
                        asterisk
                        name='confirmPassword'
                        control={form.control}
                        label={i18n.t('settings:passwordSection.confLabel')}
                        placeholder={i18n.t('settings:passwordSection.confPlaceholder')}
                    />

                    <SubmitButton>{i18n.t('common:buttons.save')}</SubmitButton>
                </>
            )
            }
        </Form>
    </SimpleCard>
)

export default AccountSettingsPasswordForm
