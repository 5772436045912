import * as React from 'react'

import Info from './Info'
import { getClasses } from './style'
import { BaseProgressProps } from './types'
import { SIZES } from '../../constants/constant'
import { clsxm } from '../../lib/clsxm'

type ProgressLineProps = {
    animate?: boolean
} & BaseProgressProps

const ProgressLine = React.forwardRef<HTMLDivElement, ProgressLineProps>(
    (props: ProgressLineProps, ref) => {
        const {
            color = 'primary-600',
            percent,
            size = SIZES.MD,
            showInfo = true,
            customInfo,
            animate = false,
            className,
        } = props

        const classes = getClasses(color)

        const progressBackgroundClass = clsxm(
            classes['progress-bg'],
            classes.line,
            size === SIZES.XS && 'h-1',
            size === SIZES.SM && 'h-1.5',
            size === SIZES.MD && 'h-2',
            size === SIZES.LG && 'h-2.5',

            `bg-${color}`,
            animate && 'animate-pulse'
        )

        const progressClass = clsxm(
            'flex items-center justify-center w-full gap-x-2',
            className
        )

        return (
            <div ref={ref} className={progressClass}>
                <div className={classes['progress-wrapper']}>
                    <div className={classes['progress-inner']}>
                        <div
                            data-testid='progress-line'
                            className={progressBackgroundClass}
                            style={{ width: `${percent}%` }}
                        />
                    </div>
                </div>
                {showInfo ? (
                    <Info className='flex-shrink' percent={percent}>
                        {customInfo}
                    </Info>
                ) : null}
            </div>
        )
    }
)
export default ProgressLine
