import { FindStateQueryResponse } from 'viterbit-api-sdk'

import { IMapper } from './__common'
import fromResponse from './__fromResponse'
import stateHolidayMapper from './stateHoliday.mapper'
import { State } from '../model/State.model'

const stateMapper: IMapper<State, FindStateQueryResponse> = {
    fromResponse: function (response) { return fromResponse(response, this.toModel) },
    toModel: function (dto) {
        return {
            name: dto.name,
            country: dto.country,
            holidays: dto.holidays?.map(stateHolidayMapper.toModel) || []
        }
    },
    toDto: function () {
        throw new Error('Not implemented')
    },
}

export default stateMapper
