
import { LeaveReason } from '@viterbit/web-app/dataAccess/model/LeaveReason.model'
import { leaveCheck } from '@viterbit/web-app/dataAccess/service/leavesService'
import i18n from '@viterbit/web-app/i18n'
import FormDatePicker from '@viterbit/web-app/shared/form/FormDatePicker'
import FormInfo from '@viterbit/web-app/shared/form/FormInfo'
import FormSelectEmployeeLeaveReason from '@viterbit/web-app/shared/form/FormSelectEmployeeLeaveReason'
import { IFormStatusContext } from '@viterbit/web-app/shared/form/FormStatusContext'
import FormUpload from '@viterbit/web-app/shared/form/FormUpload'
import SubmitButton from '@viterbit/web-app/shared/form/SubmitButton'
import { parseDateUTC } from '@viterbit/web-app/shared/utils/dateParse'
import dayjs from 'dayjs'
import * as React from 'react'
import { UseFormReturn } from 'react-hook-form'

import DaysTabForm from './shared/DaysTabForm'
import HalfDayForm from './shared/HalfDayForm'
import JustificationSection from './shared/JustificationSection'
import { DaysRequestsEnum, LeaveFormFields } from './types'

type CountDays = {
    total: number
    computable: number
}

type DaysRequestInfoProps = {
    countDays: CountDays
}

const DaysRequestInfo = ({ countDays }: DaysRequestInfoProps) => (
    <FormInfo
        info={i18n.t('leaves:leaveForm.daysRequestInfo', countDays)}
    />
)

type LeaveFormProps = {
    employeeId: string
    defaultValues: Partial<LeaveFormFields>
    isReadOnly?: boolean
    form: UseFormReturn<LeaveFormFields>
    formState: IFormStatusContext
}

const LeaveForm = ({
    employeeId,
    defaultValues,
    isReadOnly,
    form,
    formState,
}: LeaveFormProps) => {
    const [countDays, setCountDays] = React.useState<{
        total: number
        computable: number
    }>({ total: -1, computable: -1 })

    const watchDaysOption = form.watch('daysOption')
    const isSeveralDays = watchDaysOption === DaysRequestsEnum.SeveralDays
    const isHalfDay = watchDaysOption === DaysRequestsEnum.HalfDay

    const watchInitialDate = form.watch('initialDate')
    const watchEndDate = form.watch('endDate')

    const files = form.watch('files')

    React.useEffect(() => {
        const isNewLeave = !defaultValues.endDate
        const selectNewDates =
            parseDateUTC(watchInitialDate) !==
            parseDateUTC(defaultValues.initialDate)
        const shouldFocusEndDate =
            isSeveralDays && watchInitialDate && (isNewLeave || selectNewDates)
        if (shouldFocusEndDate) {
            form.setFocus('endDate')
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchInitialDate, isSeveralDays])

    const leaveReasonId = form.getValues('leaveReasonId')
    // * In this  hook we handle the count of days requested
    React.useEffect(() => {
        const endDate = watchEndDate || ''
        const getCountDays = async (
            startDate: string,
            endDate: string,
            leaveReasonId: string,
            employeeId: string,
            isHalfDay: boolean
        ) => {
            if (!startDate || !leaveReasonId || (!endDate && isSeveralDays)) {
                return setCountDays({ total: -1, computable: -1 })
            }

            if (!endDate) endDate = startDate

            startDate = parseDateUTC(startDate)
            endDate = endDate ? parseDateUTC(endDate) : ''
            const result = await leaveCheck({
                employeeId,
                leaveReasonId,
                startDate,
                endDate,
                halfDay: isHalfDay ? 'true' : 'false',
            })
            if (result) {
                setCountDays({
                    total: result.total ?? -1,
                    computable: result.totalComputable ?? -1,
                })
            } else {
                return setCountDays({ total: -1, computable: -1 })
            }
        }

        getCountDays(
            watchInitialDate,
            endDate,
            leaveReasonId,
            employeeId,
            isHalfDay
        )
    }, [
        employeeId,
        watchInitialDate,
        watchEndDate,
        isHalfDay,
        isSeveralDays,
        leaveReasonId,
        form,
    ])

    const shouldShowDaysRequestInfo =
        isSeveralDays && countDays.computable != -1 && !formState.disableSubmit

    const [reason, setReason] = React.useState<LeaveReason | undefined | null>(
        defaultValues.leaveReason
    )

    
    const disableSubmit = formState.disableSubmit || (!files?.length && reason?.needDocumentation)
    return (
        <>
            <FormSelectEmployeeLeaveReason
                isDisabled={isReadOnly}
                label={i18n.t('leaves:leaveForm.reason.label')}
                asterisk
                name='leaveReasonId'
                control={form.control}
                employeeId={employeeId}
                loadOptionsOnMenuOpen={true}
                defaultValue={defaultValues?.leaveReason}
                onSelectItem={(x: LeaveReason | null) => setReason(x)}
                placeholder={i18n.t('leaves:leaveForm.reason.placeholder')}
            />
            {reason?.needDocumentation && (
                <FormUpload
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    control={form.control as any}
                    label={i18n.t('leaves:leaveForm.files.label')}
                    asterisk
                    name='files'
                    employeeId={employeeId}
                    kind='leave'
                    multiple
                    defaultValues={defaultValues.files}
                    draggable
                >
                </FormUpload>
            )}

            <DaysTabForm
                name='daysOption'
                control={form.control}
                disabled={isReadOnly}
            />

            <FormDatePicker
                disabled={isReadOnly}
                name='initialDate'
                className='w-full'
                asterisk
                label={i18n.t('leaves:leaveForm.initialDate.label')}
                placeholder={i18n.t('leaves:leaveForm.initialDate.placeholder')}
                control={form.control}
            />
            {isSeveralDays && (
                <FormDatePicker
                    disabled={isReadOnly}
                    name='endDate'
                    className='w-full'
                    asterisk
                    label={i18n.t('leaves:leaveForm.endDate.label')}
                    placeholder={i18n.t('leaves:leaveForm.endDate.placeholder')}
                    control={form.control}
                    minDate={
                        watchInitialDate
                            ? dayjs(watchInitialDate).add(1, 'day').toDate()
                            : undefined
                    }
                />
            )}
            {isHalfDay && (
                <div className='items-center flex-auto w-full mt-4 mb-4 gap-x-4'>
                    <HalfDayForm
                        disabled={isReadOnly}
                        name='turn'
                        control={form.control}
                    />
                </div>
            )}

            {shouldShowDaysRequestInfo && (
                <DaysRequestInfo countDays={countDays} />
            )}

            <JustificationSection
                disabled={isReadOnly}
                showJustifications={Boolean(defaultValues?.comment)}
            />

            <div className='flex justify-end gap-x-2 mt-4 [&>*]:flex-1'>
                <SubmitButton disabled={disableSubmit} data-testid='button-request-leave-form'>
                    {i18n.t('leaves:leaveForm.buttons.submit')}
                </SubmitButton>
            </div>
               
        </>
    )
}

export default LeaveForm
