import * as React from 'react'

type ColorOptionProps = {
    children?: React.ReactNode
    value: string
}

const ColorOption = ({ value, children }: ColorOptionProps) => (
    <div className='flex flex-row'>
        <div
            className='mr-2 h-[20px] w-[20px] rounded'
            style={{ backgroundColor: value }}
        ></div>
        {children}
    </div>
)

export default ColorOption
