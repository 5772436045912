/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as Sentry from '@sentry/react'
import { accountMeInfo } from '@viterbit/web-app/dataAccess/service/accountService'
import i18n from '@viterbit/web-app/i18n'
import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'
import { atom } from 'recoil'
import { getRecoil,setRecoil } from 'recoil-nexus'
import { LogInResponse } from 'viterbit-api-sdk'

import { JWTUserPayload, User } from '../../dataAccess/model/User.model'

export const IMPERSONATE_USER_KEY = 'vb_impersonate_email'

export const userState = atom<User | null>({
    key: 'user',
    default: null
})

export const setUser = async ({token}: LogInResponse) => {
    const user = await accountMeInfo()
    const decoded = jwtDecode(token) as JWTUserPayload
    if (user.id !== decoded.id) {
        user.impersonationInfo = {
            isActive: true,
            impersonator: {
                id: decoded.id,
                email: decoded.username
            }
        }
    } else {
        Cookies.remove(IMPERSONATE_USER_KEY)
    }
    Sentry.setUser(user)
    setRecoil(userState, user)
    await i18n.changeLanguage(user.locale)
}

export const clearUser = () => {
    Cookies.remove(IMPERSONATE_USER_KEY)
    setRecoil(userState, null)
}

export const getUser = () => getRecoil(userState)