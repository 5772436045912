import { useMutation } from '@tanstack/react-query'

import { complaintUnassignEmployee } from '../../service/complaintService'

type ComplaintUnassignEmployee = {
    onSuccess?: () => void
    onError?: () => void
}

export const useComplaintUnassignEmployee = (config: ComplaintUnassignEmployee) =>
    useMutation({
        ...config,
        mutationFn: complaintUnassignEmployee,
        onSuccess: config.onSuccess,
        onError: config.onError,
    })
