import { clsxm } from '@viterbit/web-app/shared/utils/clsxm'
import * as React from 'react'

type BodyContentMainProps = {
    children?: React.ReactNode
    className?: string
}

const styles = {
    root: '',
}

const BodyContentMain = ({ children, className }: BodyContentMainProps) => (
    <main className={clsxm(styles.root, className)}>
        {children}
    </main>
)

export default BodyContentMain
