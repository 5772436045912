import Avatar from '../Avatar'
import { LayoutHeadingProps } from '../LayoutHeading/LayoutHeading'

export type LayoutProps = LayoutHeadingProps & {
    children?: React.ReactNode
    logo: React.ReactNode
    author: string | React.ReactNode
    authorPosition: string | React.ReactNode
    sidebarContent: string | React.ReactNode
    backgroundImage?: string
} & React.HTMLAttributes<HTMLDivElement>

const SideLayout = ({
    children,
    author,
    authorPosition,
    logo,
    sidebarContent,
    ...rest
}: LayoutProps) => (
    <div className='grid h-full lg:grid-cols-3' {...rest}>
        <div className='flex-col justify-between hidden px-16 py-6 bg-no-repeat bg-cover lg:flex bg-gradient-to-t from-black to-blue-500'>
            {logo}
            <div>
                <div className='flex items-center gap-4 mb-4'>
                    <Avatar className='border-2 border-white' shape='circle'>
                        {typeof author === 'string' ? author.charAt(0): ''}
                    </Avatar>
                    <div className='flex-1 text-white'>
                        <div className='text-base font-semibold'>{author}</div>
                        <span className='opacity-80'>{authorPosition}</span>
                    </div>
                </div>
                <p className='text-lg text-white opacity-80'>
                    {sidebarContent}
                </p>
            </div>
            <span className='text-white'>
                Copyright &copy; {`${new Date().getFullYear()}`}{' '}
                <span className='font-semibold'>Viterbit</span>{' '}
            </span>
        </div>
        <div className='flex flex-col items-center justify-center col-span-2 bg-white dark:bg-gray-800'>
            {children}
        </div>
    </div>
)

export default SideLayout
