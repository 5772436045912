import * as React from 'react'

import { clsxm } from '../../lib/clsxm'

const classes = {
    default:
        'table-auto min-w-full divide-y divide-gray-200 dark:divide-gray-700',
}

export type TableProps = {
    className?: string
    overflow?: boolean
    children?: React.ReactNode
}

export const Table = React.forwardRef<HTMLTableElement, TableProps>(
    ({ className, children, overflow = true, ...rest }, ref) => {
        const tableClass = clsxm(classes.default, className)

        return (
            <div className={clsxm(overflow && 'overflow-x-auto')}>
                <table className={tableClass} {...rest} ref={ref}>
                    {children}
                </table>
            </div>
        )
    }
)

export default Table
