/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    registerDecorator,
    ValidationArguments,
    ValidationOptions,
} from 'class-validator'
import dayjs from 'dayjs'

const DateIsGreaterThan =
    (property: string, validationOptions?: ValidationOptions) =>
        (object: any, propertyName: string) => {
            registerDecorator({
                name: 'DateIsGreaterThan',
                target: object.constructor,
                propertyName,
                options: validationOptions,
                constraints: [property],
                validator: {
                    validate(value: any, args: ValidationArguments) {
                        const [relatedPropertyName] = args.constraints
                        const relatedValue = (args.object as any)[
                            relatedPropertyName
                        ]
                        return dayjs(value).isAfter(dayjs(relatedValue))
                    },
                },
            })
        }

export default DateIsGreaterThan
