import * as React from 'react'

import { clsxm } from '../../lib/clsxm'

const classes = {
    skeleton: 'bg-gray-200 dark:bg-gray-600 flex',
    skeletonCircle: 'rounded-full w-10 h-10',
    skeletonBlock: 'rounded h-2.5 w-full',
}

export type SkeletonProps = {
    variant?: 'circle' | 'block'
    animated?: boolean
    className?: string
}

const Skeleton = ({
    variant = 'block',
    animated = true,
    className,
}: SkeletonProps) => (
    <span
        data-testid='skeleton'
        className={clsxm(
            classes.skeleton,
            variant === 'circle' && classes.skeletonCircle,
            variant === 'block' && classes.skeletonBlock,
            animated && 'animate-pulse',
            className
        )}
    />
)

export default Skeleton
