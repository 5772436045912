import { useMutation } from '@tanstack/react-query'

import { pendingFileReset } from '../../service/pendingFilesService'

type FileBulkDeleteConfig = {
    onSuccess?: () => void
    onError?: () => void
}

export const useFileBulkDelete = (config: FileBulkDeleteConfig) =>
    useMutation({
        ...config,
        mutationFn: pendingFileReset,
        onSuccess: config.onSuccess,
        onError: config.onError,
    })
