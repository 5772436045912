import { IconPlaylistX } from '@tabler/icons-react'
import { Employee } from '@viterbit/web-app/dataAccess/model/Employee.model'
import i18n from '@viterbit/web-app/i18n'
import EmptyState from '@viterbit/web-app/shared/display/EmptyState/EmptyState'
import * as React from 'react'

import TeamLeaderItem from './TeamLeaderItem'

type TeamLeaderListProps = {
    className?: string
    leaders: Employee[]
    onDeleteItem: (employee: Employee) => void
}

const TeamLeaderList = ({
    leaders,
    onDeleteItem
}: TeamLeaderListProps) => {

    if (!leaders.length) {
        return (
            <EmptyState
                image={<IconPlaylistX size={28} stroke={1} />}
                className='pt-2 pb-4 border border-gray-200 rounded-md gap-y-0.5 bg-grey-50'
                title={i18n.t('employees:teams.leadersSection.emptyTitle')}
                description={i18n.t('employees:teams.leadersSection.emptyDescription')}
            />
        )
    }

    return (
        <div className='grid grid-cols-2 gap-4'>
            {
                leaders.map(employee => (
                    <TeamLeaderItem
                        key={employee.id}
                        item={employee}
                        onDeleteItem={() => onDeleteItem(employee)}
                    />
                ))
            }
        </div>
    )
}

export default TeamLeaderList
