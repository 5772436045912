/* eslint-disable @typescript-eslint/no-explicit-any */

import { workScheduleCreate } from '@viterbit/web-app/dataAccess/service/workScheduledService'
import i18n from '@viterbit/web-app/i18n'
import BasicCreateFormDialog from '@viterbit/web-app/shared/form/BasicCreateFormDialog'
import ButtonCreate from '@viterbit/web-app/shared/form/ButtonCreate'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'


const WorkScheduledCreate = () => {
    const [open, setOpen] = React.useState(false)
    const navigate = useNavigate()

    const onSuccess = async (result: any) => {
        onClose()
        navigate(`/settings/work-schedule/${result.id}/details`)
    }

    const onClose = () => {
        setOpen(false)
    }

    return <>
        <ButtonCreate
            collapsed
            title={i18n.t('settingWorkSchedule:create.action')}
            onClick={() => setOpen(true)}
            data-testid='work-schedule-create-button'
        />

        <BasicCreateFormDialog
            isOpen={open}
            withTransition
            title={i18n.t('settingWorkSchedule:create.title')}
            onClose={onClose}
            label={i18n.t('settingWorkSchedule:create.label')}
            placeholder={i18n.t('settingWorkSchedule:create.placeholder')}
            mutationConfig={{
                onSuccess,
                mutationFn: (data) =>
                    workScheduleCreate({
                        createWorkScheduleCommand: data
                    }),
            }}
        />

    </>
}

export default WorkScheduledCreate
