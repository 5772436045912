/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { complaintState } from '@viterbit/web-app/shared/state/complaintState'
import { useRecoilValue } from 'recoil'

import ComplaintDetailLayout from '../../ComplaintDetailLayout'
import { useComplaintDetail } from '../useComplaintDetail'
import BodyContent from '@viterbit/web-app/shared/layout/BodyContent'
import { complaintPublicRecordCreate } from '@viterbit/web-app/dataAccess/service/complaintService'
import RouterBreadcrumbs from '@viterbit/web-app/shared/navigation/RouterBreadcrumbs'
import i18n from '@viterbit/web-app/i18n'

const ComplaintPublicDetailView = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const { data, isInitialLoading } = useComplaintDetail()
    const jwtInfo = useRecoilValue(complaintState)!

    return (
        <BodyContent wrapperClassName='h-screen bg-grey-100 py-6'>
            <RouterBreadcrumbs title={i18n.t('complaint:detail.description.title')} className='mb-4'/>

            <hr className='mb-4'/>

            <ComplaintDetailLayout
                complaint={data}
                isLoading={isInitialLoading}
                jwtInfo={jwtInfo}
                onSendMessage={data => complaintPublicRecordCreate({
                    createComplaintRecordCommand: {
                        message: data.message,
                        fileIds: data.fileIds,
                    }
                })}
            />
        </BodyContent>
    )
}

export default ComplaintPublicDetailView
