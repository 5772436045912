import {
    IconSortAscendingLetters,
    IconSortAZ,
    IconSortDescendingLetters,
} from '@tabler/icons-react'
import * as React from 'react'

export type SorterProps = {
    sort?: boolean
}

const Sorter = ({ sort }: SorterProps) => {
    const renderSort = () => {
        if (typeof sort !== 'boolean') {
            return <IconSortAZ data-testid='FaSort' />
        }
        const color = 'text-primary-600'
        return sort ? (
            <IconSortDescendingLetters
                className={color}
                data-testid='FaSortDown'
            />
        ) : (
            <IconSortAscendingLetters
                className={color}
                data-testid='FaSortUp'
            />
        )
    }

    return <div className='inline-flex'>{renderSort()}</div>
}

export default Sorter
