/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ToFromData } from './ToFromData';
import {
    ToFromDataFromJSON,
    ToFromDataFromJSONTyped,
    ToFromDataToJSON,
} from './ToFromData';

/**
 * 
 * @export
 * @interface Schedule
 */
export interface Schedule {
    /**
     * 
     * @type {Array<ToFromData>}
     * @memberof Schedule
     */
    monday: Array<ToFromData> | null;
    /**
     * 
     * @type {Array<ToFromData>}
     * @memberof Schedule
     */
    tuesday: Array<ToFromData> | null;
    /**
     * 
     * @type {Array<ToFromData>}
     * @memberof Schedule
     */
    wednesday: Array<ToFromData> | null;
    /**
     * 
     * @type {Array<ToFromData>}
     * @memberof Schedule
     */
    thursday: Array<ToFromData> | null;
    /**
     * 
     * @type {Array<ToFromData>}
     * @memberof Schedule
     */
    friday: Array<ToFromData> | null;
    /**
     * 
     * @type {Array<ToFromData>}
     * @memberof Schedule
     */
    saturday: Array<ToFromData> | null;
    /**
     * 
     * @type {Array<ToFromData>}
     * @memberof Schedule
     */
    sunday: Array<ToFromData> | null;
}

/**
 * Check if a given object implements the Schedule interface.
 */
export function instanceOfSchedule(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "monday" in value;
    isInstance = isInstance && "tuesday" in value;
    isInstance = isInstance && "wednesday" in value;
    isInstance = isInstance && "thursday" in value;
    isInstance = isInstance && "friday" in value;
    isInstance = isInstance && "saturday" in value;
    isInstance = isInstance && "sunday" in value;

    return isInstance;
}

export function ScheduleFromJSON(json: any): Schedule {
    return ScheduleFromJSONTyped(json, false);
}

export function ScheduleFromJSONTyped(json: any, ignoreDiscriminator: boolean): Schedule {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'monday': (json['monday'] === null ? null : (json['monday'] as Array<any>).map(ToFromDataFromJSON)),
        'tuesday': (json['tuesday'] === null ? null : (json['tuesday'] as Array<any>).map(ToFromDataFromJSON)),
        'wednesday': (json['wednesday'] === null ? null : (json['wednesday'] as Array<any>).map(ToFromDataFromJSON)),
        'thursday': (json['thursday'] === null ? null : (json['thursday'] as Array<any>).map(ToFromDataFromJSON)),
        'friday': (json['friday'] === null ? null : (json['friday'] as Array<any>).map(ToFromDataFromJSON)),
        'saturday': (json['saturday'] === null ? null : (json['saturday'] as Array<any>).map(ToFromDataFromJSON)),
        'sunday': (json['sunday'] === null ? null : (json['sunday'] as Array<any>).map(ToFromDataFromJSON)),
    };
}

export function ScheduleToJSON(value?: Schedule | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'monday': (value.monday === null ? null : (value.monday as Array<any>).map(ToFromDataToJSON)),
        'tuesday': (value.tuesday === null ? null : (value.tuesday as Array<any>).map(ToFromDataToJSON)),
        'wednesday': (value.wednesday === null ? null : (value.wednesday as Array<any>).map(ToFromDataToJSON)),
        'thursday': (value.thursday === null ? null : (value.thursday as Array<any>).map(ToFromDataToJSON)),
        'friday': (value.friday === null ? null : (value.friday as Array<any>).map(ToFromDataToJSON)),
        'saturday': (value.saturday === null ? null : (value.saturday as Array<any>).map(ToFromDataToJSON)),
        'sunday': (value.sunday === null ? null : (value.sunday as Array<any>).map(ToFromDataToJSON)),
    };
}

