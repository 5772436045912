/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FindIntegrationQueryResponse
 */
export interface FindIntegrationQueryResponse {
    /**
     * 
     * @type {string}
     * @memberof FindIntegrationQueryResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof FindIntegrationQueryResponse
     */
    type: FindIntegrationQueryResponseTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FindIntegrationQueryResponse
     */
    kind: FindIntegrationQueryResponseKindEnum;
    /**
     * 
     * @type {boolean}
     * @memberof FindIntegrationQueryResponse
     */
    active: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FindIntegrationQueryResponse
     */
    _default: boolean;
    /**
     * 
     * @type {object}
     * @memberof FindIntegrationQueryResponse
     */
    meta: object;
}


/**
 * @export
 */
export const FindIntegrationQueryResponseTypeEnum = {
    Google: 'google',
    Microsoft: 'microsoft',
    Slack: 'slack',
    Zapier: 'zapier',
    Viterbit: 'viterbit'
} as const;
export type FindIntegrationQueryResponseTypeEnum = typeof FindIntegrationQueryResponseTypeEnum[keyof typeof FindIntegrationQueryResponseTypeEnum];

/**
 * @export
 */
export const FindIntegrationQueryResponseKindEnum = {
    Email: 'email',
    Calendar: 'calendar',
    Message: 'message',
    Other: 'other'
} as const;
export type FindIntegrationQueryResponseKindEnum = typeof FindIntegrationQueryResponseKindEnum[keyof typeof FindIntegrationQueryResponseKindEnum];


/**
 * Check if a given object implements the FindIntegrationQueryResponse interface.
 */
export function instanceOfFindIntegrationQueryResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "kind" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "_default" in value;
    isInstance = isInstance && "meta" in value;

    return isInstance;
}

export function FindIntegrationQueryResponseFromJSON(json: any): FindIntegrationQueryResponse {
    return FindIntegrationQueryResponseFromJSONTyped(json, false);
}

export function FindIntegrationQueryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindIntegrationQueryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': json['type'],
        'kind': json['kind'],
        'active': json['active'],
        '_default': json['default'],
        'meta': json['meta'],
    };
}

export function FindIntegrationQueryResponseToJSON(value?: FindIntegrationQueryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': value.type,
        'kind': value.kind,
        'active': value.active,
        'default': value._default,
        'meta': value.meta,
    };
}

