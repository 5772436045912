import { clsxm } from '@viterbit/web-app/shared/utils/clsxm'
import * as React from 'react'

import { LeaveTeamDay } from './LeaveTeamShared'

export type LeaveTeamHeaderProps = {
    children?: React.ReactNode
    days: LeaveTeamDay[]
    header: React.ReactNode
}

const LeaveTeamHeaderHeaderDay = (props: { day: LeaveTeamDay }) => {
    const { day } = props

    return (
        <article className={clsxm('flex flex-col items-center px-1 text-xs', day.isToday && 'text-primary-600')}>
            <span className='font-bold'>{day.number}</span>
            <span className=''>{day.name}</span>
        </article>
    )
}

const LeaveTeamHeaderHeader = (props: LeaveTeamHeaderProps) => {
    const { days, header } = props

    return (
        <>
            <div className={clsxm('bg-grey-100 lg:sticky top-0 h-full left-0 z-10 py-6')}>
                {header}
            </div>


            {days.map((day) => (
                <LeaveTeamHeaderHeaderDay key={day.number} day={day} />
            ))}
        </>

    )
}

export default LeaveTeamHeaderHeader
