/* eslint-disable @typescript-eslint/no-non-null-assertion */


import { IMapper } from './__common'
import workScheduleMapper from './workSchedule.mapper'
import { EmployeeResponse } from '../dto'
import { Planning, PlanningContract, PlanningWorkSchedule } from '../model/Planning.model'


const planningMapper: IMapper<Planning[], EmployeeResponse['workSchedules']> = {
    toModel: (dto) => {
        if (!dto?.data) {
            return []
        }

        const items: Planning[] = dto.data.map((item, index) => {
            if (item.type === 'WORK_SCHEDULE') {
                const planningItem : PlanningWorkSchedule = {
                    id: item.id,
                    startDate: item.startDate,
                    type: item.type,
                    workSchedule: workScheduleMapper.toModel(item.workSchedule!),
                    isWorkSchedule: true,
                    isContract: false,
                    isDefault: false,
                }

                return planningItem
            }

            if (item.type === 'CONTRACT') {
                const planningItem : PlanningContract = {
                    id: item.id,
                    startDate: item.startDate,
                    type: item.type,
                    workSchedule: null,
                    isWorkSchedule: false,
                    isContract: true,
                    isDefault: index === 0,
                }

                return planningItem
            }

            throw new Error(`Unknown planning type: ${item.type}`)
        })

        return items
    },
    toDto: (dto) => {
        throw new Error('Not implemented')
    },
}

export default planningMapper
