import * as React from 'react'
import { useHref, useNavigate } from 'react-router-dom'
import { ToastOptions } from 'react-toastify'
import { useSearchParam } from 'react-use'
import Notification from 'ui/src/components/Notification'
import { toast, ToastContainer } from 'ui/src/components/Toast'

export const useErrorNotification = () => {
    const push = (title: string, message: string, options?: ToastOptions) =>
        toast.push(
            <Notification type='danger' title={title}>
                {message}
            </Notification>,
            options
        )

    return { push, renderToast: <ToastContainer /> }
}

export const useUrlParamsError = () => {
    const errorMessage = useErrorNotification()
    const error = useSearchParam('error')
    const navigate = useNavigate()
    const pathname = useHref({})

    React.useEffect(() => {
        if (error) {
            errorMessage.push('Error', error, { toastId: error })
            if (pathname) navigate(pathname)
        }
    }, [error, errorMessage, pathname])

    return errorMessage
}
