import * as React from 'react'

import ControlledFormControl, { ControlledFormControlProps } from './ControlledFormControl'

type CheckboxButtonInputGroupProps = ControlledFormControlProps & {
    children?: React.ReactNode | React.ReactNode[]
    className?: string
}

const CheckboxButtonInputGroup = ({
    children,
    className = '',
    ...rest
}: CheckboxButtonInputGroupProps) => (
    <ControlledFormControl  {...rest} >
        <div className={`${className} flex justify-between gap-2`}>
            {children}
        </div>
    </ControlledFormControl>
)

export default CheckboxButtonInputGroup
