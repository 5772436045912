import * as React from 'react'

import Skeleton from '../Skeleton'
import clsxm from '../../lib'

type DropZoneSkeletonProps = {
    children?: React.ReactNode
    className?: string
}

const styles = {
    root: 'border-2 relative border-dashed border-gray-300 dark:border-gray-600 rounded-lg cursor-pointer flex items-center justify-center px-5 py-6 gap-x-2 mx-6 mt-6'
}

const DropZoneSkeleton = (props: DropZoneSkeletonProps) => {
    const {  children, className } = props

    return (
        <div className={clsxm(styles.root, className)}>
            <Skeleton className='w-44'/>
        </div>

    )
}

export default DropZoneSkeleton
