import { WorkSchedule, WorkScheduleTypes } from '@viterbit/web-app/dataAccess/model/WorkSchedule.model'
import i18n from '@viterbit/web-app/i18n'
import * as React from 'react'
import { UseFormReturn } from 'react-hook-form'
import { FormInput, FormSelect } from 'ui/src/components/Form'
import clsxm from 'ui/src/lib'

type WorkScheduleDetailBasicFieldsProps = {
    children?: React.ReactNode
    className?: string
    form: UseFormReturn<any>
    defaultValues?: WorkSchedule
    showActions?: boolean
}

const styles = {
    root: 'flex gap-2'
}

const options = WorkScheduleTypes.map(type => ({
    label: i18n.t(`settingWorkSchedule:fields.type.options.${type}`),
    value: type
}))

const WorkScheduleDetailBasicFields = (props: WorkScheduleDetailBasicFieldsProps) => {
    const { form, className, defaultValues, showActions } = props

    return (
        <div className={clsxm(styles.root, className)}>
            <FormInput
                asterisk
                name='name'
                label={i18n.t('settingWorkSchedule:fields.name.label')}
                placeholder={i18n.t('settingWorkSchedule:fields.name.placeholder')}
                control={form.control}
                data-testid='work-schedule-input-name'
                readOnly={!showActions}
            />

            <FormSelect
                asterisk
                name='type'
                options={options}
                className={showActions ? '' : 'pointer-events-none'}
                label={i18n.t('settingWorkSchedule:fields.type.label')}
                placeholder={i18n.t('settingWorkSchedule:fields.type.placeholder')}
                control={form.control}
                data-testid='work-schedule-select-type'
                defaultValue={options.find(option => option.value === defaultValues?.type)}
            />
        </div>

    )
}

export default WorkScheduleDetailBasicFields
