import { useQuery } from '@tanstack/react-query'

import { WORK_SCHEDULE_KEY } from '../../cacheKeys'
import { workScheduleDetail } from '../../service/workScheduledService'

type WorkScheduleDetailParams = {
    query: Parameters<typeof workScheduleDetail>[0]
    enabled?: boolean
}

export const useWorkScheduleDetail = ({ query, enabled }: WorkScheduleDetailParams) =>
    useQuery({
        queryFn: () => workScheduleDetail(query), 
        queryKey: [WORK_SCHEDULE_KEY, 'detail', query],
        enabled
    })

