/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Tabs } from '@mantine/core'
import { ComplaintStatus } from '@viterbit/web-app/dataAccess/model/Complaint.model'
import i18n from '@viterbit/web-app/i18n'
import * as React from 'react'

import ComplaintList from './ComplaintList'

export type ComplaintListViewProps = {
    children?: React.ReactNode
}

const ComplaintListView = ({ children }: ComplaintListViewProps) => {
    const [activeTab, setActiveTab] = React.useState<typeof ComplaintStatus[number]>(ComplaintStatus[0])

    return (
        <>
            <Tabs
                defaultValue={ComplaintStatus[0]}
                onTabChange={setActiveTab as any}
            >
                <Tabs.List grow >
                    {ComplaintStatus.map((status) => (
                        <Tabs.Tab key={status} value={status}>
                            {i18n.t(`complaint:statusFilters.${status}`)}
                        </Tabs.Tab>
                    ))}
                </Tabs.List>
            </Tabs>

            <ComplaintList key={activeTab} activeTab={activeTab} />
        </>
    )
}

export default ComplaintListView
