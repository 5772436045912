import * as React from 'react'

import useHasPermissions from './useHasPermissions'
import { PermissionAction } from '../../dataAccess/model/PermissionAction.model'

const useHasSinglePermission = (action: PermissionAction, ...ids: string[]) => {
    const permissions = useHasPermissions({
        [action]: ids
    })

    const isPermissionGranted = React.useMemo(() => 
        permissions.hasPermission(action, ...ids)
    , [permissions])

    return isPermissionGranted
}

export default useHasSinglePermission
