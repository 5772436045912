/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApproveDayCommand,
  ClockQueryResponse,
  CreateTimeTrackingCommand,
  EmployeeOrderByQueryParam,
  FilterGroups,
  FindTimeTrackingQueryResponse,
  ListTimeTrackingQueryResponse,
  RejectDayCommand,
  ResourceCreated,
  TimeTrackingEstimationQueryResponse,
  TimeTrackingReportQueryResponse,
  ToggleClockCommand,
  UpdateTimeTrackingCommand,
} from '../models/index';
import {
    ApproveDayCommandFromJSON,
    ApproveDayCommandToJSON,
    ClockQueryResponseFromJSON,
    ClockQueryResponseToJSON,
    CreateTimeTrackingCommandFromJSON,
    CreateTimeTrackingCommandToJSON,
    EmployeeOrderByQueryParamFromJSON,
    EmployeeOrderByQueryParamToJSON,
    FilterGroupsFromJSON,
    FilterGroupsToJSON,
    FindTimeTrackingQueryResponseFromJSON,
    FindTimeTrackingQueryResponseToJSON,
    ListTimeTrackingQueryResponseFromJSON,
    ListTimeTrackingQueryResponseToJSON,
    RejectDayCommandFromJSON,
    RejectDayCommandToJSON,
    ResourceCreatedFromJSON,
    ResourceCreatedToJSON,
    TimeTrackingEstimationQueryResponseFromJSON,
    TimeTrackingEstimationQueryResponseToJSON,
    TimeTrackingReportQueryResponseFromJSON,
    TimeTrackingReportQueryResponseToJSON,
    ToggleClockCommandFromJSON,
    ToggleClockCommandToJSON,
    UpdateTimeTrackingCommandFromJSON,
    UpdateTimeTrackingCommandToJSON,
} from '../models/index';

export interface ApproveDayRequest {
    approveDayCommand?: ApproveDayCommand;
}

export interface ClockRequest {
    employeeId?: string;
}

export interface CreateTimeTrackingRequest {
    createTimeTrackingCommand?: CreateTimeTrackingCommand;
}

export interface DeleteTimeTrackingRequest {
    id: string;
}

export interface FindTimeTrackingRequest {
    id: string;
}

export interface ListTimeTrackingRequest {
    employeeId: string;
    startDate: string;
    endDate: string;
}

export interface RejectDayRequest {
    rejectDayCommand?: RejectDayCommand;
}

export interface TimeTrackingEstimationRequest {
    startDate: string;
    endDate: string;
    employeeId: string;
}

export interface TimeTrackingReportRequest {
    startDate: string;
    endDate: string;
    companyId?: string;
    locationId?: string;
    status?: string;
    reporters?: boolean;
    search?: string;
    page?: number;
    perPage?: number;
    filters?: FilterGroups;
    orderBy?: EmployeeOrderByQueryParam;
    include?: Array<TimeTrackingReportIncludeEnum>;
}

export interface ToggleClockRequest {
    toggleClockCommand?: ToggleClockCommand;
}

export interface UpdateTimeTrackingRequest {
    id: string;
    updateTimeTrackingCommand?: UpdateTimeTrackingCommand;
}

/**
 * 
 */
export class TimeTrackingApi extends runtime.BaseAPI {

    /**
     * Approve day
     * Approve day
     */
    async approveDayRaw(requestParameters: ApproveDayRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core-hr/time-tracking/approve`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApproveDayCommandToJSON(requestParameters.approveDayCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Approve day
     * Approve day
     */
    async approveDay(requestParameters: ApproveDayRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.approveDayRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Clock for time tracking
     * Clock for time tracking
     */
    async clockRaw(requestParameters: ClockRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClockQueryResponse>> {
        const queryParameters: any = {};

        if (requestParameters.employeeId !== undefined) {
            queryParameters['employeeId'] = requestParameters.employeeId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core-hr/time-tracking/clock`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClockQueryResponseFromJSON(jsonValue));
    }

    /**
     * Clock for time tracking
     * Clock for time tracking
     */
    async clock(requestParameters: ClockRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClockQueryResponse> {
        const response = await this.clockRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a time tracking
     * Create a time tracking
     */
    async createTimeTrackingRaw(requestParameters: CreateTimeTrackingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core-hr/time-tracking`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateTimeTrackingCommandToJSON(requestParameters.createTimeTrackingCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Create a time tracking
     * Create a time tracking
     */
    async createTimeTracking(requestParameters: CreateTimeTrackingRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.createTimeTrackingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a time tracking
     * Delete a time tracking
     */
    async deleteTimeTrackingRaw(requestParameters: DeleteTimeTrackingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteTimeTracking.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core-hr/time-tracking/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Delete a time tracking
     * Delete a time tracking
     */
    async deleteTimeTracking(requestParameters: DeleteTimeTrackingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.deleteTimeTrackingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find a time tracking
     * Find a time tracking
     */
    async findTimeTrackingRaw(requestParameters: FindTimeTrackingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindTimeTrackingQueryResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling findTimeTracking.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core-hr/time-tracking/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindTimeTrackingQueryResponseFromJSON(jsonValue));
    }

    /**
     * Find a time tracking
     * Find a time tracking
     */
    async findTimeTracking(requestParameters: FindTimeTrackingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindTimeTrackingQueryResponse> {
        const response = await this.findTimeTrackingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List time trackings
     * List time trackings
     */
    async listTimeTrackingRaw(requestParameters: ListTimeTrackingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListTimeTrackingQueryResponse>> {
        if (requestParameters.employeeId === null || requestParameters.employeeId === undefined) {
            throw new runtime.RequiredError('employeeId','Required parameter requestParameters.employeeId was null or undefined when calling listTimeTracking.');
        }

        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling listTimeTracking.');
        }

        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling listTimeTracking.');
        }

        const queryParameters: any = {};

        if (requestParameters.employeeId !== undefined) {
            queryParameters['employeeId'] = requestParameters.employeeId;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = requestParameters.startDate;
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = requestParameters.endDate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core-hr/time-tracking`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListTimeTrackingQueryResponseFromJSON(jsonValue));
    }

    /**
     * List time trackings
     * List time trackings
     */
    async listTimeTracking(requestParameters: ListTimeTrackingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListTimeTrackingQueryResponse> {
        const response = await this.listTimeTrackingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reject day
     * Reject day
     */
    async rejectDayRaw(requestParameters: RejectDayRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core-hr/time-tracking/reject`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RejectDayCommandToJSON(requestParameters.rejectDayCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Reject day
     * Reject day
     */
    async rejectDay(requestParameters: RejectDayRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.rejectDayRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Time tracking estimation
     * Time tracking estimation
     */
    async timeTrackingEstimationRaw(requestParameters: TimeTrackingEstimationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TimeTrackingEstimationQueryResponse>> {
        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling timeTrackingEstimation.');
        }

        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling timeTrackingEstimation.');
        }

        if (requestParameters.employeeId === null || requestParameters.employeeId === undefined) {
            throw new runtime.RequiredError('employeeId','Required parameter requestParameters.employeeId was null or undefined when calling timeTrackingEstimation.');
        }

        const queryParameters: any = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = requestParameters.startDate;
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = requestParameters.endDate;
        }

        if (requestParameters.employeeId !== undefined) {
            queryParameters['employeeId'] = requestParameters.employeeId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core-hr/time-tracking/estimation`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TimeTrackingEstimationQueryResponseFromJSON(jsonValue));
    }

    /**
     * Time tracking estimation
     * Time tracking estimation
     */
    async timeTrackingEstimation(requestParameters: TimeTrackingEstimationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TimeTrackingEstimationQueryResponse> {
        const response = await this.timeTrackingEstimationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Time tracking report
     * Time tracking report
     */
    async timeTrackingReportRaw(requestParameters: TimeTrackingReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TimeTrackingReportQueryResponse>> {
        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling timeTrackingReport.');
        }

        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling timeTrackingReport.');
        }

        const queryParameters: any = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = requestParameters.startDate;
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = requestParameters.endDate;
        }

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        if (requestParameters.locationId !== undefined) {
            queryParameters['locationId'] = requestParameters.locationId;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.reporters !== undefined) {
            queryParameters['reporters'] = requestParameters.reporters;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.perPage !== undefined) {
            queryParameters['perPage'] = requestParameters.perPage;
        }

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.include) {
            queryParameters['include'] = requestParameters.include;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core-hr/time-tracking/report`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TimeTrackingReportQueryResponseFromJSON(jsonValue));
    }

    /**
     * Time tracking report
     * Time tracking report
     */
    async timeTrackingReport(requestParameters: TimeTrackingReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TimeTrackingReportQueryResponse> {
        const response = await this.timeTrackingReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Toggle clock
     * Toggle clock
     */
    async toggleClockRaw(requestParameters: ToggleClockRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core-hr/time-tracking/toggle-clock`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ToggleClockCommandToJSON(requestParameters.toggleClockCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Toggle clock
     * Toggle clock
     */
    async toggleClock(requestParameters: ToggleClockRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.toggleClockRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a time tracking
     * Update a time tracking
     */
    async updateTimeTrackingRaw(requestParameters: UpdateTimeTrackingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateTimeTracking.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core-hr/time-tracking/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateTimeTrackingCommandToJSON(requestParameters.updateTimeTrackingCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Update a time tracking
     * Update a time tracking
     */
    async updateTimeTracking(requestParameters: UpdateTimeTrackingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.updateTimeTrackingRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const TimeTrackingReportIncludeEnum = {
    ContractInformation: 'contractInformation',
    PersonalInformation: 'personalInformation',
    FiscalInformation: 'fiscalInformation',
    BankInformation: 'bankInformation',
    DeleteInformation: 'deleteInformation',
    LeaveAdjustment: 'leaveAdjustment',
    Address: 'address',
    Avatar: 'avatar',
    IdFile: 'idFile',
    EmergencyInformation: 'emergencyInformation',
    JobPosition: 'jobPosition',
    JobPositionLevel: 'jobPositionLevel',
    Location: 'location',
    LeavePolicy: 'leavePolicy',
    Leaves: 'leaves',
    Company: 'company',
    Manager: 'manager',
    Account: 'account',
    WorkSchedules: 'workSchedules'
} as const;
export type TimeTrackingReportIncludeEnum = typeof TimeTrackingReportIncludeEnum[keyof typeof TimeTrackingReportIncludeEnum];
