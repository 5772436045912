/* eslint-disable @typescript-eslint/no-explicit-any */
// import auth from '@viterbit/web-app/data-access/api/auth'
import { Popover } from '@mantine/core'
import { IconBlockquote, IconBolt, IconEye,IconLogout, IconSettings, IconUser } from '@tabler/icons-react'
import { signOut } from '@viterbit/web-app/dataAccess/service/authService'
import { useOrganizationDetail } from '@viterbit/web-app/dataAccess/useCase/organization/useOrganizationDetail'
import i18n from '@viterbit/web-app/i18n'
import Cookies from 'js-cookie'
import * as React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useMedia } from 'react-use'
import Avatar from 'ui/src/components/Avatar'

import { HeaderMegaMenuGroup } from './HeaderMegaMenu'
import ImpersonateUserDialog from './ImpersonateUserDialog'
import useCurrentUser from '../employee/useCurrentUser'
import { useUser } from '../employee/useUser'
import { IMPERSONATE_USER_KEY } from '../state/userState'
import Small from '../typography/Small'
import Text from '../typography/Text'

const UserMenu = () => {
    const user = useUser()

    const navigate = useNavigate()
    const userInfo = useCurrentUser()
    const { data: organization, isLoading } = useOrganizationDetail()
    const [open, setOpen] = React.useState(false)
    const isMobile = useMedia('(max-width: 767px)')

    const [opened, setOpened] = React.useState(false)
    const {pathname} = useLocation()
    React.useEffect(() => {
        setOpened(false)
    }, [ pathname ])

    const exitImpersonation = () => {
        Cookies.remove(IMPERSONATE_USER_KEY)
        window.open('/', '_self')
    }

    const MENU_ITEMS = {
        COMPLAINTS_SETTINGS: {
            title: i18n.t('navigation:navbar.settings.complaintsSettings'),
            description: i18n.t('navigation:navbarDescriptions.settings.complaintsSettings'),
            icon: <IconSettings />,
            key: 'complaints-public',
            href: '/core-hr/complaints',
            'data-testid': 'link-complaints-settings',
        },
        COMPLAINTS_PUBLIC: {
            title: i18n.t('navigation:navbar.settings.complaints'),
            description: i18n.t('navigation:navbarDescriptions.settings.publicComplaints'),
            icon: <IconBlockquote />,
            key: 'complaints-settings',
            href: `${organization?.publicPath}/complaints`,
            'data-testid': 'link-complaints-public',
            'aria-disabled': isLoading,
        },
        IMPERSONATE: {
            description: i18n.t('navigation:navbar.userMenu.impersonate.start'),
            icon: <IconEye />,
            title: i18n.t('navigation:navbar.userMenu.impersonate.start'),
            onClick: () => {
                setOpened(false)
                setOpen(true)
            },
            key: 'link-impersonate-user',
            'data-testid': 'link-impersonate-user'
        },
        LINK_SUBSCRIPTION: {
            description: i18n.t('navigation:navbar.userMenu.billings'),
            icon: <IconBolt />,
            title: i18n.t('navigation:navbar.userMenu.billings'),
            key: 'link-subscription',
            href: '/billing/subscription/list',
            'data-testid': 'link-subscription',
        },
        PROFILE: {
            title: i18n.t('navigation:navbar.userMenu.settings'),
            description: i18n.t('navigation:navbarDescriptions.userMenu.settings'),
            icon: <IconBlockquote />,
            key: 'account/settings',
            href: '/account/settings',
            'data-testid': 'account-settings',
        },
        LOGOUT: user.impersonationInfo?.isActive ? {
            title: i18n.t('navigation:navbar.userMenu.impersonate.exit'),
            description: i18n.t('navigation:navbarDescriptions.userMenu.signOut'),
            icon: <IconLogout />,
            key: 'link-impersonate-logout',
            'data-testid': 'link-impersonate-logout',
            onClick: exitImpersonation,
        }: {
            title: i18n.t('navigation:navbar.userMenu.signOut'),
            description: i18n.t('navigation:navbarDescriptions.userMenu.signOut'),
            icon: <IconLogout />,
            key: 'logout',
            'data-testid': 'logout',
            onClick: signOut
        },
    }

    if (isMobile) {
        return (
            <>
                <div className='flex flex-row-reverse items-center gap-2' hidden>
                    <Avatar shape='rounded' size='md' style={{ backgroundColor: user.color }} className=''>
                        {user.initials.toUpperCase()}
                    </Avatar>
                    <div>
                        <Text className='max-w-[160px] text-right truncate'>
                            {userInfo?.name} {userInfo?.surname}
                        </Text>
                        <Small
                            bold
                            opacity
                            className='max-w-[160px] text-right truncate'
                        >
                            {userInfo?.headline}
                        </Small>
                    </div>
                </div>

                {user.canSwitchUser && (
                    <HeaderMegaMenuGroup
                        cols={1}
                        user={user}
                        icon={<IconEye />}
                        label={i18n.t('navigation:navbar.userMenu.devTools')}
                        items={[MENU_ITEMS.IMPERSONATE]}
                    />
                )}

                {user.menu['Billing'] && (
                    <HeaderMegaMenuGroup
                        cols={1}
                        user={user}
                        icon={<IconBolt />}
                        label={i18n.t('navigation:navbar.userMenu.billings')}
                        items={[MENU_ITEMS.LINK_SUBSCRIPTION]}
                    />
                )}

                <HeaderMegaMenuGroup
                    cols={1}
                    user={user}
                    icon={<IconBlockquote />}
                    label={i18n.t('navigation:navbar.settings.complaints')}
                    items={
                        user.menu['Complaints'] ? [
                            MENU_ITEMS.COMPLAINTS_PUBLIC,
                            MENU_ITEMS.COMPLAINTS_SETTINGS,
                        ]: [MENU_ITEMS.COMPLAINTS_PUBLIC]
                    }
                />

                <HeaderMegaMenuGroup
                    cols={1}
                    user={user}
                    icon={<IconUser />}
                    label={i18n.t('navigation:navbar.settings.myAccount')}
                    items={
                        user.menu['AccountSettings'] ? [
                            MENU_ITEMS.PROFILE,
                            MENU_ITEMS.LOGOUT,
                        ]: [MENU_ITEMS.LOGOUT]
                    }
                />

            </>
        )

    }

    return (
        <>
            <Popover
                opened={opened}
                onChange={setOpened}
                width={300}
                position="bottom-end"
                radius="md"
                shadow="md"
                withinPortal
            >
                <Popover.Target>
                    <div data-testid='user-menu' className='flex flex-row-reverse items-center gap-2' onClick={() => setOpened(!opened)}>
                        <Avatar shape='rounded' size='md' style={{ backgroundColor: user.color }} className=''>
                            {user.initials.toUpperCase()}
                        </Avatar>
                        <div>
                            <Text className='max-w-[160px] text-right truncate'>
                                {userInfo?.name} {userInfo?.surname}
                            </Text>
                            <Small
                                bold
                                opacity
                                className='max-w-[160px] text-right truncate'
                            >
                                {userInfo?.headline}
                            </Small>
                        </div>
                    </div>
                </Popover.Target>

                <Popover.Dropdown sx={{ overflow: 'hidden' }} pt={16} pb={0} px={0}>
                    <div key="user">
                        {user.canSwitchUser && (
                            <HeaderMegaMenuGroup
                                cols={1}
                                user={user}
                                icon={<IconEye />}
                                label={i18n.t('navigation:navbar.userMenu.devTools')}
                                items={[MENU_ITEMS.IMPERSONATE]}
                            />
                        )}

                        {user.menu['Billing'] && (
                            <HeaderMegaMenuGroup
                                cols={1}
                                user={user}
                                icon={<IconBolt />}
                                label={i18n.t('navigation:navbar.userMenu.billings')}
                                items={[MENU_ITEMS.LINK_SUBSCRIPTION]}
                            />
                        )}

                        <HeaderMegaMenuGroup
                            cols={1}
                            user={user}
                            icon={<IconBlockquote />}
                            label={i18n.t('navigation:navbar.settings.complaints')}
                            items={
                                user.menu['Complaints'] ? [
                                    MENU_ITEMS.COMPLAINTS_PUBLIC,
                                    MENU_ITEMS.COMPLAINTS_SETTINGS,
                                ]: [MENU_ITEMS.COMPLAINTS_PUBLIC]
                            }
                        />

                        <HeaderMegaMenuGroup
                            cols={1}
                            user={user}
                            icon={<IconUser />}
                            label={i18n.t('navigation:navbar.settings.myAccount')}
                            items={
                                user.menu['AccountSettings'] ? [
                                    MENU_ITEMS.PROFILE,
                                    MENU_ITEMS.LOGOUT,
                                ]: [MENU_ITEMS.LOGOUT]
                            }
                        />
                    </div>
                </Popover.Dropdown>
            </Popover>
            
            {open && (
                <ImpersonateUserDialog
                    onClose={() => setOpen(false)}
                />
            )}
        </>
    )
}

export default UserMenu
