import { IsNotEmpty, IsString } from '@viterbit/web-app/shared/utils/ClassValidators'


class CompanyValidator {
    @IsNotEmpty()
    @IsString()
    name: string
}

export default CompanyValidator
