/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react'
import { GroupBase } from 'react-select'
import ReactSelect, { Props } from 'react-select'

import { CustomSelectProps } from './sharedTypes'
import { useSelectProps } from './useSelectProps'
import FormSelectControl from '../Form/FormSelectControl'
import FormSelectOption from '../Form/FormSelectOption'

export type SelectProps<
    Option = unknown,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>
> = Props<Option, IsMulti, Group> &
    CustomSelectProps & {
        componentAs?: any
        onSelectItem?: (item: Option | Option[] | null) => void
    }

const SelectInner = <
    Option = unknown,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>
>(
        {
            componentAs: Component = ReactSelect,
            ...rest
        }: SelectProps<Option, IsMulti, Group>,
        ref: React.ForwardedRef<HTMLDivElement>
    ) => {
    const preparedProps = useSelectProps<Option, IsMulti, Group>(rest)

    return (
        <Component
            classNamePrefix='select'
            
            ref={ref as any}
            {...preparedProps}
        />
    )
}

const SelectWithRef = React.forwardRef(SelectInner) as any

type SelectWithRefProps<
    Option = unknown,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>
> = SelectProps<Option, IsMulti, Group> & {
    ref?: React.Ref<HTMLDivElement>
}

const Select = <
    Option = unknown,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>
>({
        ref,
        ...props
    }: SelectWithRefProps<Option, IsMulti, Group>) =>
        <SelectWithRef
            ref={ref}
            data-testid="form-select" components={{
                Option: FormSelectOption,
                Control: FormSelectControl,
            }}
            {...props}
            menuPosition="fixed"
            menuPortalTarget={document.body}
            menuShouldScrollIntoView={false}
            styles={{
                menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                control: (baseStyles: any, state: any) => ({
                    ...baseStyles,
                    borderColor: state.isFocused ? '#0C80F5' : '#e5e7eb',
                    boxShadow: 'none',
                }),
            }}
        />

export default Select
