import { useTimeTrackingEstimationList,UseTimeTrackingEstimationListConfig } from '@viterbit/web-app/dataAccess/useCase/timeTracking/useTimeTrackingEstimationList'
import * as React from 'react'
import Skeleton from 'ui/src/components/Skeleton'

type TimeTrackingEstimationListProps = {
    query: UseTimeTrackingEstimationListConfig['query'] | null
}

const skeletonItems = Array.from({ length: 7 }, (_, index) => (<Skeleton key={index} className="h-[12px] w-[80px] mx-auto my-3" />))

const TimeTrackingEstimationList = (props: TimeTrackingEstimationListProps) => {
    const { query } = props

    const estimationReturn = useTimeTrackingEstimationList({
        query: query as UseTimeTrackingEstimationListConfig['query'],
        enabled: !!query,
    })

    return (
        <div className='relative flex h-10 -mt-1 text-xs bg-white border divide-x z-1 rounded-b-md'>
            <div className='w-[59px] flex items-center justify-center font-semibold'>
                Total
            </div>
            <div className='grid flex-1 grid-cols-7 text-center divide-x'>
                {estimationReturn.data?.map((estimation) => (
                    <div key={estimation.date} className='flex items-center justify-center'>
                        <span data-testid="time-tracking-estimation-worked" className='font-semibold'>{estimation.workedFormatted}</span>
                        {!!estimation.estimatedMinutes && (
                            <>
                                / <span data-testid="time-tracking-estimation-estimated">{estimation.estimatedFormatted}</span>
                            </>
                        )}
                    </div>
                ))}
                {estimationReturn.isLoading && skeletonItems}
            </div>
        </div>
    )
}

export default TimeTrackingEstimationList
