/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateEmployeeCommand
 */
export interface UpdateEmployeeCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeCommand
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeCommand
     */
    surname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeCommand
     */
    pronouns?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeCommand
     */
    birthday?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeCommand
     */
    gender?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeCommand
     */
    nationality?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeCommand
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeCommand
     */
    address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeCommand
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeCommand
     */
    postalCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeCommand
     */
    country?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeCommand
     */
    state?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeCommand
     */
    startDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeCommand
     */
    endDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeCommand
     */
    contractType?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateEmployeeCommand
     */
    salary?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeCommand
     */
    salaryRange?: UpdateEmployeeCommandSalaryRangeEnum | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateEmployeeCommand
     */
    hours?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeCommand
     */
    contractHours?: UpdateEmployeeCommandContractHoursEnum | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateEmployeeCommand
     */
    contractSchedule?: Array<UpdateEmployeeCommandContractScheduleEnum>;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeCommand
     */
    workingModality?: UpdateEmployeeCommandWorkingModalityEnum | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeCommand
     */
    workingDayType?: UpdateEmployeeCommandWorkingDayTypeEnum | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateEmployeeCommand
     */
    trialPeriod?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeCommand
     */
    bankName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeCommand
     */
    bankSwift?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeCommand
     */
    bankNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeCommand
     */
    emergencyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeCommand
     */
    emergencyEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeCommand
     */
    emergencyPhoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeCommand
     */
    jobPositionAlias?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeCommand
     */
    locationId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeCommand
     */
    companyId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeCommand
     */
    jobPositionLevelId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeCommand
     */
    leavePolicyId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeCommand
     */
    managerId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeCommand
     */
    avatarFileId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeCommand
     */
    idFileId?: string | null;
    /**
     * 
     * @type {object}
     * @memberof UpdateEmployeeCommand
     */
    fiscalInformation?: object;
}


/**
 * @export
 */
export const UpdateEmployeeCommandSalaryRangeEnum = {
    Daily: 'DAILY',
    Weekly: 'WEEKLY',
    Monthly: 'MONTHLY',
    Yearly: 'YEARLY'
} as const;
export type UpdateEmployeeCommandSalaryRangeEnum = typeof UpdateEmployeeCommandSalaryRangeEnum[keyof typeof UpdateEmployeeCommandSalaryRangeEnum];

/**
 * @export
 */
export const UpdateEmployeeCommandContractHoursEnum = {
    Hourly: 'HOURLY',
    Daily: 'DAILY',
    Weekly: 'WEEKLY',
    Monthly: 'MONTHLY',
    Yearly: 'YEARLY'
} as const;
export type UpdateEmployeeCommandContractHoursEnum = typeof UpdateEmployeeCommandContractHoursEnum[keyof typeof UpdateEmployeeCommandContractHoursEnum];

/**
 * @export
 */
export const UpdateEmployeeCommandContractScheduleEnum = {
    Monday: 'MONDAY',
    Tuesday: 'TUESDAY',
    Wednesday: 'WEDNESDAY',
    Thursday: 'THURSDAY',
    Friday: 'FRIDAY',
    Saturday: 'SATURDAY',
    Sunday: 'SUNDAY'
} as const;
export type UpdateEmployeeCommandContractScheduleEnum = typeof UpdateEmployeeCommandContractScheduleEnum[keyof typeof UpdateEmployeeCommandContractScheduleEnum];

/**
 * @export
 */
export const UpdateEmployeeCommandWorkingModalityEnum = {
    OnSite: 'ON_SITE',
    Hybrid: 'HYBRID',
    Remote: 'REMOTE'
} as const;
export type UpdateEmployeeCommandWorkingModalityEnum = typeof UpdateEmployeeCommandWorkingModalityEnum[keyof typeof UpdateEmployeeCommandWorkingModalityEnum];

/**
 * @export
 */
export const UpdateEmployeeCommandWorkingDayTypeEnum = {
    Full: 'FULL',
    Partial: 'PARTIAL'
} as const;
export type UpdateEmployeeCommandWorkingDayTypeEnum = typeof UpdateEmployeeCommandWorkingDayTypeEnum[keyof typeof UpdateEmployeeCommandWorkingDayTypeEnum];


/**
 * Check if a given object implements the UpdateEmployeeCommand interface.
 */
export function instanceOfUpdateEmployeeCommand(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateEmployeeCommandFromJSON(json: any): UpdateEmployeeCommand {
    return UpdateEmployeeCommandFromJSONTyped(json, false);
}

export function UpdateEmployeeCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateEmployeeCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
        'pronouns': !exists(json, 'pronouns') ? undefined : json['pronouns'],
        'birthday': !exists(json, 'birthday') ? undefined : json['birthday'],
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'nationality': !exists(json, 'nationality') ? undefined : json['nationality'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'startDate': !exists(json, 'startDate') ? undefined : json['startDate'],
        'endDate': !exists(json, 'endDate') ? undefined : json['endDate'],
        'contractType': !exists(json, 'contractType') ? undefined : json['contractType'],
        'salary': !exists(json, 'salary') ? undefined : json['salary'],
        'salaryRange': !exists(json, 'salaryRange') ? undefined : json['salaryRange'],
        'hours': !exists(json, 'hours') ? undefined : json['hours'],
        'contractHours': !exists(json, 'contractHours') ? undefined : json['contractHours'],
        'contractSchedule': !exists(json, 'contractSchedule') ? undefined : json['contractSchedule'],
        'workingModality': !exists(json, 'workingModality') ? undefined : json['workingModality'],
        'workingDayType': !exists(json, 'workingDayType') ? undefined : json['workingDayType'],
        'trialPeriod': !exists(json, 'trialPeriod') ? undefined : json['trialPeriod'],
        'bankName': !exists(json, 'bankName') ? undefined : json['bankName'],
        'bankSwift': !exists(json, 'bankSwift') ? undefined : json['bankSwift'],
        'bankNumber': !exists(json, 'bankNumber') ? undefined : json['bankNumber'],
        'emergencyName': !exists(json, 'emergencyName') ? undefined : json['emergencyName'],
        'emergencyEmail': !exists(json, 'emergencyEmail') ? undefined : json['emergencyEmail'],
        'emergencyPhoneNumber': !exists(json, 'emergencyPhoneNumber') ? undefined : json['emergencyPhoneNumber'],
        'jobPositionAlias': !exists(json, 'jobPositionAlias') ? undefined : json['jobPositionAlias'],
        'locationId': !exists(json, 'locationId') ? undefined : json['locationId'],
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'jobPositionLevelId': !exists(json, 'jobPositionLevelId') ? undefined : json['jobPositionLevelId'],
        'leavePolicyId': !exists(json, 'leavePolicyId') ? undefined : json['leavePolicyId'],
        'managerId': !exists(json, 'managerId') ? undefined : json['managerId'],
        'avatarFileId': !exists(json, 'avatarFileId') ? undefined : json['avatarFileId'],
        'idFileId': !exists(json, 'idFileId') ? undefined : json['idFileId'],
        'fiscalInformation': !exists(json, 'fiscalInformation') ? undefined : json['fiscalInformation'],
    };
}

export function UpdateEmployeeCommandToJSON(value?: UpdateEmployeeCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'surname': value.surname,
        'pronouns': value.pronouns,
        'birthday': value.birthday,
        'gender': value.gender,
        'nationality': value.nationality,
        'phone': value.phone,
        'address': value.address,
        'city': value.city,
        'postalCode': value.postalCode,
        'country': value.country,
        'state': value.state,
        'startDate': value.startDate,
        'endDate': value.endDate,
        'contractType': value.contractType,
        'salary': value.salary,
        'salaryRange': value.salaryRange,
        'hours': value.hours,
        'contractHours': value.contractHours,
        'contractSchedule': value.contractSchedule,
        'workingModality': value.workingModality,
        'workingDayType': value.workingDayType,
        'trialPeriod': value.trialPeriod,
        'bankName': value.bankName,
        'bankSwift': value.bankSwift,
        'bankNumber': value.bankNumber,
        'emergencyName': value.emergencyName,
        'emergencyEmail': value.emergencyEmail,
        'emergencyPhoneNumber': value.emergencyPhoneNumber,
        'jobPositionAlias': value.jobPositionAlias,
        'locationId': value.locationId,
        'companyId': value.companyId,
        'jobPositionLevelId': value.jobPositionLevelId,
        'leavePolicyId': value.leavePolicyId,
        'managerId': value.managerId,
        'avatarFileId': value.avatarFileId,
        'idFileId': value.idFileId,
        'fiscalInformation': value.fiscalInformation,
    };
}

