/* eslint-disable @typescript-eslint/no-explicit-any */
import { PendingFile } from '@viterbit/web-app/dataAccess/model/PendingFile.model'
import { PublicEmployee } from '@viterbit/web-app/dataAccess/model/PublicEmployee.model'
import { ITEMS_BY_PAGE } from '@viterbit/web-app/dataAccess/model/Week.model'
import { useEmployeeList } from '@viterbit/web-app/dataAccess/useCase/employee/useEmployeeList'
import { usePendingFileRespond } from '@viterbit/web-app/dataAccess/useCase/pendingFile/usePendingFileRespond'
import EmployeeItem from '@viterbit/web-app/feature/employee/item/EmployeeItem'
import i18n from '@viterbit/web-app/i18n'
import SimpleCard from '@viterbit/web-app/shared/display/SimpleCard'
import ApiErrorMessage from '@viterbit/web-app/shared/feedback/ApiErrorMessage'
import SubmitButton from '@viterbit/web-app/shared/form/SubmitButton'
import { MantineReactTable, MRT_ColumnDef, MRT_PaginationState, MRT_RowSelectionState } from 'mantine-react-table'
import { MRT_Localization_ES } from 'mantine-react-table/locales/es'
import { useMemo, useState } from 'react'
import clsxm from 'ui/src/lib'

import BulkUploadCoincidenceTag from '../../BulkUploadCoincidenceTag'

type DocumentBulkUploadPreviewEmployeeListProps = {
    file: PendingFile
    className?: string
    onChange?: () => void
    onDelete?: () => void
}

const DocumentBulkUploadPreviewEmployeeList = ({
    file,
    onDelete,
    onChange = () => null,
}: DocumentBulkUploadPreviewEmployeeListProps) => {
    const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>(
        !file.employee?.id ? {} : {[file.employee.id]: true}
    )
    const [globalFilter, setGlobalFilter] = useState('')
    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: ITEMS_BY_PAGE,
    })

    const { data, isError, isFetching, isLoading } = useEmployeeList({
        query: {
            page: pagination.pageIndex + 1,
            pageSize: pagination.pageSize,
            search: globalFilter || undefined,
            include: ['avatar'],
        },
        keepPreviousData: true,
        refetchOnWindowFocus: false
    })

    const columns: MRT_ColumnDef<PublicEmployee>[] = [
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        {
            header: '',
            accessorKey: 'name',
            Cell: ({ row: {original: employee} }) => (
                <div className='flex items-center'>
                    <EmployeeItem
                        employee={employee as any}
                    />
                </div>
            ),
            enableSorting: false,
            enableColumnFilter: false,
        },
        {
            header: '',
            accessorKey: 'email',
            Cell: ({ row: {original: employee} }) => (
                <div className='flex items-center justify-center'>
                    {
                        employee.id === file.employee?.id
                            ? <BulkUploadCoincidenceTag className='px-2' />
                            : null
                    } 
                </div>
            ),
            enableSorting: false,
            enableColumnFilter: false,
        },
    ]
    
    const selectedEmployee = useMemo(() => {
        const selectedEmployeeId=  Object.entries(rowSelection)
            .filter(([_, value]) => value)
            .map(([key]) => key)
            .shift()
        return selectedEmployeeId === file.employee?.id
            ? file.employee
            : data?.data.find(employeeItem => employeeItem.id === selectedEmployeeId)
    }, [rowSelection])


    const {isLoading: isLoadingRespond,isSuccess: isSuccessRespond, mutate: respondMutation, error} = usePendingFileRespond({
        onSuccess: onChange,
    })

    const handleRespond = (accept: boolean) => {
        if (!selectedEmployee?.id && accept) return

        respondMutation({
            id: file.id,
            respondPendingFileCommand: {
                employeeId: selectedEmployee?.id,
                accept,
            }
        })
    }

    return (
        <>
            <div className='flex flex-col gap-y-4'>
                <SimpleCard padding>
                    <div
                        className={clsxm(
                            'flex justify-end w-full gap-x-4 items-center',
                            selectedEmployee && 'justify-between'
                        )}
                    >
                        {
                            selectedEmployee && (
                                <EmployeeItem
                                    employee={{
                                        ...selectedEmployee,
                                        email: selectedEmployee.id === file.employee?.id
                                            ? <BulkUploadCoincidenceTag />
                                            : ''
                                    } as any}
                                    size='sm'
                                />
                            )
                        }
                        <SubmitButton
                            variant='filled'
                            disabled={!selectedEmployee}
                            success={isSuccessRespond}
                            loading={isLoadingRespond}
                            type='button'
                            {...{onClick: () => handleRespond(true)}}
                        >
                            {i18n.t('employees:bulkUpload.preview.assign')}
                        </SubmitButton>
                    </div>
                </SimpleCard>

                <ApiErrorMessage error={error} />
                <div className='relative'>

                    <MantineReactTable
                        columns={columns}
                        data={data?.data ?? []} //data is undefined on first render
                        rowCount={data?.meta?.total ?? 0}
                        positionGlobalFilter='left'
                        positionToolbarAlertBanner="none"
                        localization={MRT_Localization_ES}
                        getRowId={(row) => row.id}
                    
                        initialState={{
                            showGlobalFilter: true,
                            density: 'xs',
                        }}
                        state={{
                            globalFilter,
                            pagination,
                            rowSelection,
                            showGlobalFilter: true,
                            showAlertBanner: isError,
                            showSkeletons: isLoading,
                            showProgressBars: false,
                        }}
                        enableColumnActions={false}
                        enableMultiRowSelection={false} //use radio buttons instead of checkboxes
                        enableRowSelection

                        manualPagination
                        onGlobalFilterChange={setGlobalFilter}
                        onRowSelectionChange={setRowSelection}
                        onPaginationChange={setPagination}
                        // enableTopToolbar={false}
                        // enableBottomToolbar={!!data?.data.length}
                        
                        mantineToolbarAlertBannerProps={
                            isError
                                ? {
                                    color: 'error',
                                    children: 'Error loading data',
                                }
                                : undefined
                        }
                        mantinePaperProps={{
                            withBorder: false,
                            className: 'px-3 py-2 relative',
                        }}
                        mantineTableBodyRowProps={({ row }) => ({
                            //add onClick to row to select upon clicking anywhere in the row
                            // onClick: row.getToggleSelectedHandler(),
                            // sx: { cursor: 'pointer' },

                            onClick: () =>
                                setRowSelection((prev) => ({
                                    // ...prev,
                                    [row.id]: !prev[row.id],
                                })),
                            selected: rowSelection[row.id],
                            sx: {
                                cursor: 'pointer',
                            },
                        })}
                        renderToolbarInternalActions={({ table }) => (
                            <></>
                        )}
                        displayColumnDefOptions={{
                            'mrt-row-select': {
                                header: '',
                                size: 15
                            },
                        }}
                        mantineTableHeadProps={{
                            display: 'none'
                        }}
                        mantineTopToolbarProps={{
                            className: 'px-0'
                        }}
                    />
                </div>
            </div>
        </>
    )
}

export default DocumentBulkUploadPreviewEmployeeList