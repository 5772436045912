export const validatePassword = (password: string): PasswordValidation => {
    if (!password) return emptyValidation

    const validationEntries = Object.entries(passwordValidations) as [keyof PasswordValidation, string][]
    const validations = validationEntries.reduce<PasswordValidation>((acc, [key, value]) => {
        acc[key] = !!password.match(value)
        return acc
    }, { ... emptyValidation })

    return validations
}

export const isValidPassword = (password: string): boolean => {
    const validations = validatePassword(password)
    return Object.values(validations).every(Boolean)
}

export const emptyValidation: PasswordValidation = {
    minLength: false,
    digit: false,
    symbol: false,
    uppercase: false,
}

export const passwordValidations = {        
    minLength: '.{8,}',
    digit: '(?=.*?[0-9])',
    symbol: '(?=.*?[#?!@$%^&*-])',
    uppercase: '(?=.*?[A-Z])',
}

export type SignUpRedeemFields = {
    name: string
    password: string
    confirmPassword: string
    country: string
    locale: string
    organizationName: string
    timezone: string
}

export type PasswordValidation = {
    minLength: boolean
    digit: boolean
    symbol: boolean
    uppercase: boolean
}