import { Button } from '@mantine/core'
import { Subscription } from '@viterbit/web-app/dataAccess/model/Subscription.model'
import i18n from '@viterbit/web-app/i18n'
import formatPrice from '@viterbit/web-app/shared/utils/formatPrice'
import * as React from 'react'
import clsxm from 'ui/src/lib'


type PriceListProps = {
    children?: React.ReactNode
    className?: string
    price: Subscription['prices'][number]
    onClick: () => void
}

const styles = {
    root: ''
}

const PriceItem = (props: PriceListProps) => {
    const { price, onClick, className } = props

    return (
        <div className={clsxm('flex flex-col gap-2 p-4 text-center bg-white border rounded-xl border-grey-200', className)}>
            <h3 id="tier-freelancer" className="leading-8 text-gray-700 ">{price.name}</h3>
            <p className="text-sm leading-6 text-black whitespace-pre-line">{i18n.t(`billing:pricing.billingNote.${price.periodUnit}`)}</p>
            <p className="flex items-baseline justify-center mb-2 gap-x-1">

                <span className="text-4xl font-bold tracking-tight text-black" data-testid="price-item-amount">
                    {formatPrice({
                        amount: price.price,
                        currency: price.currency
                    })}
                </span>

                <span className="text-sm font-semibold leading-6 text-black" data-testid="price-item-period-unit">
                    / {price.period > 1 && price.period} {i18n.t(`billing:pricing.periodUnit.${price.periodUnit}`)}
                </span>
            </p>

            <p className="mb-2 text-xs font-semibold leading-6 text-gray-400">
                mínimo 10 usuarios
            </p>
            <Button onClick={onClick} data-testid="price-item-confirm">
                {i18n.t('billing:pricing.confirm')}
            </Button>
        </div>

    )
}

export default PriceItem
