/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LeaveAdjustmentData
 */
export interface LeaveAdjustmentData {
    /**
     * 
     * @type {number}
     * @memberof LeaveAdjustmentData
     */
    year: number;
    /**
     * 
     * @type {number}
     * @memberof LeaveAdjustmentData
     */
    operation: number;
}

/**
 * Check if a given object implements the LeaveAdjustmentData interface.
 */
export function instanceOfLeaveAdjustmentData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "year" in value;
    isInstance = isInstance && "operation" in value;

    return isInstance;
}

export function LeaveAdjustmentDataFromJSON(json: any): LeaveAdjustmentData {
    return LeaveAdjustmentDataFromJSONTyped(json, false);
}

export function LeaveAdjustmentDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeaveAdjustmentData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'year': json['year'],
        'operation': json['operation'],
    };
}

export function LeaveAdjustmentDataToJSON(value?: LeaveAdjustmentData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'year': value.year,
        'operation': value.operation,
    };
}

