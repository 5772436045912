/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { classValidatorResolver } from '@hookform/resolvers/class-validator'
import dateMapper from '@viterbit/web-app/dataAccess/mapper/date.mapper'
import { WorkSchedule, WorkSchedulePeriod, WorkScheduleWeekPeriod } from '@viterbit/web-app/dataAccess/model/WorkSchedule.model'
import { workScheduleUpdate } from '@viterbit/web-app/dataAccess/service/workScheduledService'
import i18n from '@viterbit/web-app/i18n'
import EmbeddedContent from '@viterbit/web-app/shared/behaviour/EmbeddedContent'
import AsideCardInfo from '@viterbit/web-app/shared/display/AsideCardInfo'
import Form from '@viterbit/web-app/shared/form/Form'
import SubmitButton from '@viterbit/web-app/shared/form/SubmitButton'
import React from 'react'
import {WorkSchedulePeriod2 } from 'viterbit-api-sdk'

import WorkScheduleDetailBasicFields from './WorkScheduleDetailBasicFields'
import WorkScheduleDetailPeriodFields from './WorkScheduleDetailPeriodFields'
import WorkScheduleDetailValidator from './WorkScheduleDetailValidator'

const resolver = classValidatorResolver(WorkScheduleDetailValidator)

type WorkScheduleDetailFormProps = {
    workSchedule: WorkSchedule
    showActions?: boolean
}


const mapPeriod = (type: WorkSchedule['type']) => (period: WorkSchedulePeriod): WorkSchedulePeriod2 => ({
    endDate: dateMapper.toDayWithMonth(period.endDate)!,
    startDate: dateMapper.toDayWithMonth(period.startDate)!,
    schedule: Object.entries(period.schedule).reduce<WorkScheduleWeekPeriod>((output, [day, dayPeriods]) => {
        if (!dayPeriods?.length) return output

        const isFixed = type === 'FIXED'
        const periods = isFixed ? dayPeriods : dayPeriods.slice(0, 1)
        const mappedPeriods = periods.map((period) => {
            const { from, to, fixed } = period
            const fixedFields = isFixed && from && to && { from, to } || { from: null, to: null }
            const flexibleFields = !isFixed && fixed && { fixed } || { fixed: null }

            return {
                ...fixedFields,
                ...flexibleFields,
            }
        })
        output[day].push(...mappedPeriods)
            
        return output
    }, {
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: [],
        sunday: [],
    }) as any,
})

const WorkScheduleDetailForm = ({
    workSchedule,
    showActions,
}: WorkScheduleDetailFormProps) => (
    <>
        <Form<WorkSchedule>
            withTransition
            formConfig={{
                resolver,
                mode: 'all',
                defaultValues: workSchedule,
            }}
            mutationConfig={{
                mutationFn: (data) =>{
                    const periodMapper = mapPeriod(data.type)

                    return workScheduleUpdate({
                        id: workSchedule.id,
                        updateWorkScheduleCommand: {
                            name: data.name,
                            type: data.type,
                            mainPeriod: periodMapper(data.mainPeriod),
                            periods: data.periods.map(periodMapper),
                        }
                    })
                }
            }}
            className='mb-4'
        >
            {(form, _, state) => (
                <>
                    {showActions && (
                        <EmbeddedContent>
                            <SubmitButton form={state.formId}>{i18n.t('buttons.save')}</SubmitButton>
                        </EmbeddedContent>
                    )}

                    <AsideCardInfo
                        className='mb-4 border-b'
                        title={i18n.t('settingWorkSchedule:detail.basicInformationTitle')}
                        description={i18n.t('settingWorkSchedule:detail.basicInformationDescription')}
                    >
                        <WorkScheduleDetailBasicFields
                            form={form}
                            defaultValues={workSchedule}
                            showActions={showActions}
                        />
                    </AsideCardInfo>

                    <WorkScheduleDetailPeriodFields
                        form={form}
                        defaultValues={workSchedule}
                        showActions={showActions}
                    />

                </>
            )}
        </Form>

    </>
)


export default WorkScheduleDetailForm



