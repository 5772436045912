/* eslint-disable @typescript-eslint/no-explicit-any */
import get from 'lodash/get'
import * as React from 'react'
import { Controller } from 'react-hook-form'
import { GroupBase } from 'react-select'

import ControlledFormControl from './ControlledFormControl'
import { FormControlBaseProps } from './FormControl'
import FormSelectControl from './FormSelectControl'
import FormSelectOption from './FormSelectOption'
import { FormBaseProps } from './shared'
import { Select, SelectProps } from '../Select'

export type FormSelectProps<
    Option = unknown,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>
> = SelectProps<Option, IsMulti, Group> & FormControlBaseProps & FormBaseProps


const FormSelect = <
    Option = unknown,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>
>({
        name,
        control,
        label,
        asterisk,
        options = [],
        isMulti,
        getOptionValue = (option: Option) => get(option, 'value') as any,
        labelWidth,
        labelClass,
        labelStyle,
        htmlFor,
        extra,
        style,
        rules,
        onSelectItem,
        ...rest
    }: FormSelectProps<Option, IsMulti, Group>) => {
    const handleChange = (
        newValue: any,
        controllerOnChange: (v: any) => void
    ) => {
        const fixedValue = isMulti
            ? Array.from(newValue).map((x: any) => getOptionValue(x))
            : newValue
                ? getOptionValue(newValue)
                : null
        controllerOnChange(fixedValue)
        onSelectItem?.(newValue)
    }

    const handleOnBlur = (
        newValue: any,
        controllerOnBlur: (v: any) => void
    ) => {
        const fixedValue = isMulti
            ? Array.from(newValue).map((x: any) => getOptionValue(x))
            : newValue
                ? getOptionValue(newValue)
                : null
        controllerOnBlur(fixedValue)
    }

    return (
        <ControlledFormControl
            label={label}
            labelWidth={labelWidth}
            asterisk={asterisk}
            labelClass={labelClass}
            labelStyle={labelStyle}
            htmlFor={htmlFor}
            extra={extra}
            style={style}
            control={control}
            name={name}
        >
            <Controller
                control={control}
                name={name}
                rules={rules}
                render={({ field, fieldState: { error } }) => (
                    <Select
                        name={name}
                        showDropDownIndicator
                        size='sm'
                        options={options}
                        isMulti={isMulti}
                        getOptionValue={getOptionValue}
                        components={{ Option: FormSelectOption, Control: FormSelectControl }}
                        defaultValue={
                            isMulti
                                ? (field.value ? field.value : []).map(
                                    (x: any) =>
                                        options.find(
                                            (y: any) =>
                                                getOptionValue(y) === x
                                        )
                                )
                                : options.find(
                                    (y: any) =>
                                        getOptionValue(y) === field.value
                                )
                        }
                        isInvalid={Boolean(error)}
                        {...rest}
                        onChange={(e: any) => handleChange(e, field.onChange)}
                        onBlur={(e: any) => handleOnBlur(e, field.onBlur)}
                    />
                )}
            />
        </ControlledFormControl>
    )
}

export default FormSelect
