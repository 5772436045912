/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AssignWorkScheduleCommand
 */
export interface AssignWorkScheduleCommand {
    /**
     * 
     * @type {string}
     * @memberof AssignWorkScheduleCommand
     */
    employeeId: string;
    /**
     * 
     * @type {string}
     * @memberof AssignWorkScheduleCommand
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof AssignWorkScheduleCommand
     */
    workScheduleId?: string | null;
}

/**
 * Check if a given object implements the AssignWorkScheduleCommand interface.
 */
export function instanceOfAssignWorkScheduleCommand(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "employeeId" in value;
    isInstance = isInstance && "startDate" in value;

    return isInstance;
}

export function AssignWorkScheduleCommandFromJSON(json: any): AssignWorkScheduleCommand {
    return AssignWorkScheduleCommandFromJSONTyped(json, false);
}

export function AssignWorkScheduleCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssignWorkScheduleCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'employeeId': json['employeeId'],
        'startDate': json['startDate'],
        'workScheduleId': !exists(json, 'workScheduleId') ? undefined : json['workScheduleId'],
    };
}

export function AssignWorkScheduleCommandToJSON(value?: AssignWorkScheduleCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'employeeId': value.employeeId,
        'startDate': value.startDate,
        'workScheduleId': value.workScheduleId,
    };
}

