/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from 'react'

import SettingsComplaintsForm from './SettingsComplaintsForm'

const SettingsComplaintsView = () => (
    <SettingsComplaintsForm  />
)

export default SettingsComplaintsView
