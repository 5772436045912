
import { classValidatorResolver } from '@hookform/resolvers/class-validator'
import { LoginResponse } from '@viterbit/web-app/dataAccess/dto'
import { accountLogin } from '@viterbit/web-app/dataAccess/service/authService'
import i18n from '@viterbit/web-app/i18n'
import Form from '@viterbit/web-app/shared/form/Form'
import SubmitButton from '@viterbit/web-app/shared/form/SubmitButton'
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom'
import Checkbox from 'ui/src/components/Checkbox'
import { FormInput, FormPasswordInput } from 'ui/src/components/Form'

import LoginValidator from './LoginValidator'

export type SignInFields = {
    email: string
    password: string
    rememberMe?: boolean
}

const resolver = classValidatorResolver(LoginValidator)

const LoginForm = () => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()

    return (
        <>
            <Form<SignInFields, LoginResponse>
                withTransition
                formConfig={{
                    resolver,
                    mode: 'all',
                }}
                mutationConfig={{
                    onSuccess: () => {
                        navigate(searchParams.get('redirect') || '/')
                    },
                    mutationFn: (data) =>
                        accountLogin({
                            logInCommand: {
                                password: data.password,
                                username: data.email,
                            }
                        }),
                }}
            >
                {(form) => (
                    <>
                        <FormInput
                            asterisk
                            name='email'
                            control={form.control}
                            label={i18n.t('auth:signIn.form.email')}
                            placeholder={i18n.t('auth:signIn.form.emailPlaceholder')}
                            data-testid="sign-in-email"
                        />
                        <FormPasswordInput
                            asterisk
                            name='password'
                            control={form.control}
                            label={i18n.t('auth:signIn.form.password')}
                            placeholder={i18n.t('auth:signIn.form.passwordPlaceholder')}
                            data-testid="sign-in-password"
                        />
                        <div className='flex justify-between mb-4'>
                            <div className='flex gap-x-1'>
                                <Checkbox />
                                <span>{i18n.t('auth:signIn.form.rememberMe')} </span>
                            </div>
                            <NavLink
                                to='/auth/forgot-password/request'
                                className='ml-2 text-primary-600'
                            >
                                {i18n.t('auth:signIn.form.forgotPassword')}
                            </NavLink>
                        </div>

                        <SubmitButton data-testid='button-sign-in'>{i18n.t('auth:signIn.form.submit')}</SubmitButton>
                    </>
                )}
            </Form>

            <div className='mt-4 text-center'>
                <span>{i18n.t('auth:signIn.form.noAccount')}</span>
                <NavLink to='/auth/signup' className='ml-2 text-primary-600'>
                    {i18n.t('auth:signIn.form.signUp')}
                </NavLink>
            </div>
        </>
    )
}

export default LoginForm
