import { IsEmail, IsNotEmpty, IsString, ValidateIf } from '@viterbit/web-app/shared/utils/ClassValidators'

import { ComplaintCreateFields } from './ComplaintCreateFields'

class ComplaintCreateFormValidator implements ComplaintCreateFields {
    @IsNotEmpty()
    @IsString()
    title: string
    authorConfirmed: boolean
    @ValidateIf(o => o.authorConfirmed)
    authorName: string | null
    @IsEmail()
    @ValidateIf(o => o.authorConfirmed && o.authorEmail)
    authorEmail: string | null
    @ValidateIf(o => o.authorConfirmed && o.authorPhone)
    authorPhone: string | null
    @ValidateIf(o => o.authorConfirmed)
    authorRelationship: string | null
}

export default ComplaintCreateFormValidator
