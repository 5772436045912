/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EstimationReportData
 */
export interface EstimationReportData {
    /**
     * 
     * @type {number}
     * @memberof EstimationReportData
     */
    estimation: number;
    /**
     * 
     * @type {number}
     * @memberof EstimationReportData
     */
    worked: number;
    /**
     * 
     * @type {number}
     * @memberof EstimationReportData
     */
    balance: number;
}

/**
 * Check if a given object implements the EstimationReportData interface.
 */
export function instanceOfEstimationReportData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "estimation" in value;
    isInstance = isInstance && "worked" in value;
    isInstance = isInstance && "balance" in value;

    return isInstance;
}

export function EstimationReportDataFromJSON(json: any): EstimationReportData {
    return EstimationReportDataFromJSONTyped(json, false);
}

export function EstimationReportDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): EstimationReportData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'estimation': json['estimation'],
        'worked': json['worked'],
        'balance': json['balance'],
    };
}

export function EstimationReportDataToJSON(value?: EstimationReportData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'estimation': value.estimation,
        'worked': value.worked,
        'balance': value.balance,
    };
}

