import { Button } from '@mantine/core'
import { FileTypes } from '@viterbit/web-app/dataAccess/model/File.model'
import { useFileBulkDelete } from '@viterbit/web-app/dataAccess/useCase/file/useFileBulkDelete'
import { usePendingFileListPaginated } from '@viterbit/web-app/dataAccess/useCase/pendingFile/usePendingFileListPaginated'
import i18n from '@viterbit/web-app/i18n'
import ApiErrorMessage from '@viterbit/web-app/shared/feedback/ApiErrorMessage'
import FormDeleteDialog from '@viterbit/web-app/shared/form/FormDeleteDialog'
import * as React from 'react'

type BulkUploadDeleteProps = {
    children?: React.ReactNode
    className?: string
    type: FileTypes
}

const styles = {
    root: ''
}

const BulkUploadDelete = (props: BulkUploadDeleteProps) => {
    const { type } = props

    const [isDeleting, setIsDeleting] = React.useState(false)

    const { data } = usePendingFileListPaginated({
        query: {
            type: type as any,
        },
        enabled: !!type
    })

    const hasItems = !!data?.pages?.[0]?.meta.total
    
    const { isLoading, mutate, error } = useFileBulkDelete({
        onSuccess: () => {
            setIsDeleting(false)
        }
    })

    if (!hasItems) {
        return null
    }

    return (
        <>
            <FormDeleteDialog
                isOpen={isDeleting}
                onClose={() => setIsDeleting(false)}
                cancelButtonText={i18n.t('personalArea:documents.deleteFileDialog.buttons.cancel')}
                isLoading={isLoading}
                onSubmit={() => mutate({
                    type,
                })}
                submitButtonText={i18n.t('personalArea:documents.deleteFileDialog.buttons.submit')}
                title={i18n.t('personalArea:documents.deleteFileDialog.title')}
                textToConfirm={i18n.t('personalArea:documents.deleteFileDialog.buttons.confirm')}
            >
                <ApiErrorMessage error={error} />
            </FormDeleteDialog>


            <Button color='grey' variant='white' size='sm' onClick={() => setIsDeleting(true)}>
                {i18n.t('employees:bulkUpload.bulkDelete')}
            </Button>
        </>

    )
}

export default BulkUploadDelete
