
import { Leave } from '@viterbit/web-app/dataAccess/model/Leave.model'
import dayjs from 'dayjs'
import isSameOrAfter from  'dayjs/plugin/isSameOrAfter'
import * as React from 'react'

import ListLeaves from '../List/ListLeaves'
import { LeavesClusters, StatusType } from '../types'
dayjs.extend(isSameOrAfter)

const today = dayjs()

const filters = {
    [LeavesClusters.PAST]: (l: Leave) => l.status === StatusType.APPROVED && dayjs(l.endDate || l.initialDate).isBefore(today, 'day'),
    [LeavesClusters.UPCOMING]: (l: Leave) => l.status === StatusType.APPROVED && dayjs(l.endDate || l.initialDate).isSameOrAfter(today, 'day'),
    [LeavesClusters.REJECT]: (l: Leave) => l.status === StatusType.REJECT,
    [LeavesClusters.PENDING]: (l: Leave) => l.status === StatusType.PENDING,
}

type FilterLeavesListProps = {
    leaves: Leave[]
    leaveCluster: LeavesClusters
}

const FilterLeavesList = ({ leaves, leaveCluster }: FilterLeavesListProps) => {
    const filterFunction = filters[leaveCluster]
    const filteredLeaves = leaves.filter(filterFunction)
    return <ListLeaves leaves={filteredLeaves} emptyListKey={leaveCluster} />
}

export default FilterLeavesList
