/* eslint-disable @typescript-eslint/no-explicit-any */
import { classValidatorResolver } from '@hookform/resolvers/class-validator'
import { Button } from '@mantine/core'
import i18n from '@viterbit/web-app/i18n'
import Form, { FormProps } from '@viterbit/web-app/shared/form/Form'
import FormDateTimePicker from '@viterbit/web-app/shared/form/FormDateTimePicker'
import SubmitButton from '@viterbit/web-app/shared/form/SubmitButton'
import dayjs from 'dayjs'
import * as React from 'react'

import TimeTrackingValidator from './TimeTrackingValidator'
import { createDateTime, TimeTrackingFields } from '../shared'

type TimeTrackingFormProps = {
    defaultValues?: TimeTrackingFields
    mutationConfig: FormProps<TimeTrackingFields>['mutationConfig']
    onCancel: () => void
    cancelText: string
    confirmText: string
    children?: React.ReactNode
}

const styles = {
    root: 'pt-4',
    fieldset: 'flex items-baseline gap-2',
    field: 'flex-1',
}

const maxDate = new Date()

const resolver = classValidatorResolver(TimeTrackingValidator)

const validateRange = ({
    startDate,
    endDate,
    startTime,
    endTime
} : {
    startDate: Date
    endDate: Date
    startTime: Date
    endTime: Date
}) => {
    const start = createDateTime(startDate, startTime)
    const end = createDateTime(endDate, endTime)
    return start.isBefore(end)
}

const setWithoutSeconds = (date: Date) => {
    const newDate = new Date(date)
    newDate.setSeconds(0)
    return newDate
}

const TimeTrackingForm = (props: TimeTrackingFormProps) => {
    const { defaultValues, mutationConfig, onCancel, cancelText, confirmText, children } = props

    return (
        <Form<TimeTrackingFields>
            className={styles.root}
            withTransition
            formConfig={{
                resolver,
                mode: 'all',
                defaultValues,
            }}
            mutationConfig={mutationConfig}
        >
            {(form, _, state) => {
                const endDate = form.watch('endDate')
                const startDate = form.watch('startDate')

                const isValid = dayjs(endDate).isAfter(startDate)

                return (
                    <>
                        <div className='flex gap-2 '>

                            <FormDateTimePicker
                                setValueAs={setWithoutSeconds}
                                maxDate={endDate}
                                name='startDate'
                                asterisk
                                label={i18n.t('personalArea:timeTracking.fields.startDate.label')}
                                placeholder={i18n.t('personalArea:timeTracking.fields.startDate.placeholder')}
                                control={form.control}
                            />

                            <FormDateTimePicker
                                setValueAs={setWithoutSeconds}
                                minDate={startDate}
                                name='endDate'
                                asterisk
                                label={i18n.t('personalArea:timeTracking.fields.endDate.label')}
                                placeholder={i18n.t('personalArea:timeTracking.fields.endDate.placeholder')}
                                control={form.control}
                            /> 
                        </div>
                        
                        {children}

                        <hr/>

                        <Button variant='default' onClick={onCancel}>{cancelText}</Button>
                        <SubmitButton >{confirmText}</SubmitButton>
                    </>
                )
            }}
        </Form>
    )
}

export default TimeTrackingForm
