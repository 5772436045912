import * as React from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { useAsync, useLocation } from 'react-use'

import { getUser } from '../state/userState'
import { MenuPermissions } from '../../dataAccess/model/MenuPermissions.model'

type PermissionRedirectionProps = {
    parentPath: string,
    currentPath: string,
    permissions: { permission: MenuPermissions, path: string}[]
}

const findRoute = async (config: PermissionRedirectionProps) => {
    if (config.currentPath !== config.parentPath) {
        return null
    }

    const user = await getUser()

    const navigationItem = config.permissions.find((item) => user?.menu[item.permission])

    if (navigationItem) {
        return navigationItem.path
    }

    return null
}

const useRouteRedirection = ({ config }: { config: PermissionRedirectionProps }) => {
    const detail = useAsync(() => findRoute(config), [config])

    return detail.value
}

export const withPermissionRedirection = (config: Omit<PermissionRedirectionProps, 'currentPath'>, WrappedComponent: any = React.Fragment) => {
    const WithPermissionRedirection = (props: any) => {
        const location = useLocation()
        const configWithPath = React.useMemo(() => ({ ...config, currentPath: location.pathname! }), [config, location])
        const route = useRouteRedirection({ config: configWithPath })
        const navigate = useNavigate()

        React.useEffect(() => {
            route && navigate(route, { replace: true })
        }, [route])
    
        return <WrappedComponent {...props} />
    }
    
    return WithPermissionRedirection
}

export const CreatePermissionRedirection = (...args: Parameters<typeof withPermissionRedirection>) => {
    const PermissionRedirection = withPermissionRedirection(...args)

    return <PermissionRedirection><Outlet /></PermissionRedirection>
}