import * as React from 'react'

import ListItem from './ListItem'
import ListItemSkeleton from './ListItemSkeleton'
import ListItemText from './ListItemText'
import clsxm from '../../lib'

type ListItemProps = {
    children?: React.ReactNode
    className?: string
    dataTestId?: string
}

const styles = {
    root: 'divide-y flex flex-col w-full'
}

const List = (props: ListItemProps) => {
    const { children, className, dataTestId } = props

    return (
        <ul className={clsxm(styles.root, className)} data-testid={dataTestId}>
            {children}
        </ul>
    )
}

List.Item = ListItem
List.ItemText = ListItemText
List.ListItemSkeleton = ListItemSkeleton

export default List
