/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { usePreviewPendingFileList } from '@viterbit/web-app/dataAccess/useCase/pendingFile/usePreviewPendingFileList'
import { useMemo, useState } from 'react'
import { useNavigate, useSearchParams} from 'react-router-dom'
import { useLocation } from 'react-router-dom'

import DocumentBulkUploadPreviewEmpty from './DocumentBulkUploadPreviewEmpty'
import DocumentBulkUploadPreviewSkeleton from './DocumentBulkUploadPreviewSkeleton'
import DocumentBulkUploadPreviewViewer from './DocumentBulkUploadPreviewViewer'
import DocumentBulkUploadPreviewEmployeeList from './employeeList/DocumentBulkUploadPreviewEmployeeList'
import DocumentBulkUploadPreviewEmployeeListSkeleton from './employeeList/DocumentBulkUploadPreviewEmployeeListSkeleton'
import DocumentBulkUploadDeleteDialog from '../delete/DocumentBulkUploadDeleteDialog'

const DocumentBulkUploadPreviewLayout = () => {
    const location = useLocation()
    const type = useMemo(() => location.pathname.split('/').slice(-2, -1)[0], [location.pathname])
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()

    const query = useMemo<Parameters<typeof usePreviewPendingFileList>[0]['query']>(() => {
        const fileId = searchParams.get('fileId') || undefined
        const page = +(searchParams.get('page') || 1)
        return {
            fileId,
            page,
            matching: searchParams.get('matching') === 'true',
            type: type as any,
        }
    }, [searchParams, type])

    const {isLoading, data, isFetching} = usePreviewPendingFileList({
        query,
        keepPreviousData: true,
        refetchOnWindowFocus: false
    })
    const [openDelete, setOpenDelete] = useState(false)

    if (isLoading) return <DocumentBulkUploadPreviewSkeleton />

    const file = data?.data[0]
    if (!file) return <DocumentBulkUploadPreviewEmpty />

    const handleChange = () => {
        setOpenDelete(false)
        const total = data.meta.total + (!query.fileId ? 0 : 1) 
        if (total <= 1) {
            navigate(`/core-hr/bulk/documents/${type}`)
            return
        }
        const page = query.page! <= 1 || query.page === total
            ? 1
            : query.page!
        
        setSearchParams(getQueryFixed(page, !!query.fileId && query.page !== 1))
    }

    const handleChangePage = (page: number) => setSearchParams(getQueryFixed(page, true))

    const getQueryFixed = (page: number, withFileId = false) => {
        const searchObj: any = {}
        if (page > 1)
            searchObj.page = page
        if (query.fileId && withFileId)
            searchObj.fileId = query.fileId
        if (query.matching)
            searchObj.matching = true
        return searchObj
    }

    return (
        <div className='grid grid-cols-12 gap-4 pr-1'>
            <div className='min-h-[500px] col-span-6 xl:col-span-7 2xl:col-span-8'>
                <DocumentBulkUploadPreviewViewer
                    file={file! as any}
                    page={query.page!}
                    pageCount={data.meta.total}
                    onChangePage={handleChangePage}
                    onDelete={() => setOpenDelete(true)}
                    fileId={query.fileId}
                />
            </div>
            <div className='col-span-6 xl:col-span-5 2xl:col-span-4'>
                {
                    (isFetching)
                        ? <DocumentBulkUploadPreviewEmployeeListSkeleton />
                        : <DocumentBulkUploadPreviewEmployeeList file={file!} onChange={handleChange} />
                }
                    
                {
                    openDelete && (
                        <DocumentBulkUploadDeleteDialog
                            file={file!}
                            open
                            onSubmitCallback={handleChange}
                            onCancelCallback={() => setOpenDelete(false)}
                        />
                    )
                }
            </div>
        </div>

    )
}

export default DocumentBulkUploadPreviewLayout
