import * as React from 'react'

import RadioGroupContext from './context'
import { clsxm } from '../../lib/clsxm'

const getClasses = (color: string) => ({
    radio: 'h-5 w-5 border border-gray-300  dark:border-gray-600 rounded-full cursor-pointer inline-block align-middle flex-shrink-0 p-0 appearance-none transition-colors duration-150 ease-in-out  shadow-sm',
    checked: `bg-${color}`,
    disabled:
        'text-gray-200 bg-gray-100 dark:bg-gray-600 dark:text-gray-400 cursor-not-allowed',
    'radio-label': 'items-center cursor-pointer inline-flex',
    'radio-label-disabled': 'text-gray-200 cursor-not-allowed',
    'radio-group': 'inline-flex',
    'radio-group-vertical': 'flex flex-col',
})

type RadioProps = {
    checked?: boolean
    disabled?: boolean
    defaultChecked?: boolean
    color?: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange?: (value: any, event: React.ChangeEvent<HTMLInputElement>) => void
    labelRef?: React.Ref<HTMLLabelElement>
    value?: string
    vertical?: boolean
    className?: string
} & React.InputHTMLAttributes<HTMLInputElement>

const Radio = React.forwardRef<HTMLInputElement, RadioProps>((props, ref) => {
    const {
        disabled: disabledContext,
        value: groupValue,
        onChange: onGroupChange,
        color: colorContext,
        vertical: verticalContext,
        radioGutter,
    } = React.useContext(RadioGroupContext)

    const {
        children,
        className,
        checked: checkedProp,
        color,
        defaultChecked,
        disabled = disabledContext,
        id,
        labelRef,
        onChange,
        readOnly,
        value,
        vertical = verticalContext,
        ...rest
    } = props

    const getChecked = () =>
        typeof groupValue !== 'undefined'
            ? groupValue === value
            : Boolean(checkedProp)

    const [radioChecked, setRadioChecked] = React.useState<boolean>(
        getChecked()
    )

    const radioColor = color || colorContext || 'primary-600'

    const controlProps = React.useMemo(() => {
        if (typeof groupValue !== 'undefined') {
            return { checked: radioChecked }
        }
        return { checked: checkedProp, defaultChecked }
    }, [radioChecked, checkedProp, defaultChecked, groupValue])

    const onRadioChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (disabled || readOnly) {
                return
            }
            if (groupValue) {
                onGroupChange?.(value, e)
            } else {
                setRadioChecked(e.target.checked)
                onChange?.(value, e)
            }
        },
        [
            disabled,
            setRadioChecked,
            onChange,
            value,
            onGroupChange,
            groupValue,
            readOnly,
        ]
    )

    React.useEffect(() => {
        const propChecked = getChecked()
        if (radioChecked !== propChecked) {
            setRadioChecked(propChecked)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, checkedProp, groupValue])

    const classes = getClasses(radioColor)

    const radioDefaultClass = clsxm(
        classes.radio,
        `text-${radioColor}`,
        radioChecked && classes.checked,
        disabled && radioChecked && 'border-none'
    )
    const radioColorClass = clsxm(disabled && classes.disabled)
    const labelDisabledClass = clsxm(
        disabled && classes['radio-label-disabled']
    )

    const radioClass = clsxm(radioDefaultClass, radioColorClass)
    const labelClass = clsxm(
        classes['radio-label'],
        labelDisabledClass,
        className,
        `${'inline-flex'}`,
        `${radioGutter ? 'm' + (vertical ? 'b-' : 'r-') + radioGutter : ''}`
    )

    return (
        <label ref={labelRef} className={labelClass}>
            <input
                id={id}
                ref={ref}
                type='radio'
                className={clsxm(radioClass, 'relative')}
                disabled={disabled}
                value={value}
                onChange={onRadioChange}
                readOnly={readOnly}
                {...controlProps}
                {...rest}
            />
            {radioChecked && (
                <span
                    className={clsxm(
                        'absolute ml-1.5 h-2 w-2 rounded-full bg-white transition-all duration-200 ease-in-out',
                        disabled && 'opacity-80'
                    )}
                />
            )}
            {children ? (
                <span className={clsxm('ml-2 ltr:ml-2 rtl:mr-2')}>
                    {children}
                </span>
            ) : null}
        </label>
    )
})

export default Radio
