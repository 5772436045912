import { IconCalendarStats, IconCalendarX } from '@tabler/icons-react'
import theme from '@viterbit/theme/index.json'
import { Planning } from '@viterbit/web-app/dataAccess/model/Planning.model'
import i18n from '@viterbit/web-app/i18n'
import SimpleListItem from '@viterbit/web-app/shared/display/SimpleListItem'
import { SimpleListItemProps } from '@viterbit/web-app/shared/display/SimpleListItem'
import { formatDate } from '@viterbit/web-app/shared/utils/dateFormat'
import * as React from 'react'

type EmployeePlanningListItemProps = {
    planning: Planning
    onClick: () => void
    onDelete: () => void
}

const styles = {
    root: ''
}

const EmployeePlanningListItem = (props: EmployeePlanningListItemProps) => {
    const { planning, onClick, onDelete } = props

    const simpleListItemProps = React.useMemo<SimpleListItemProps>(() => {
        const { isWorkSchedule, isDefault } = planning
        const leftContent = isWorkSchedule ? <IconCalendarStats /> : <IconCalendarX />
        const color = isWorkSchedule ? theme.color.shades.primary[700] : theme.color.shades.grey[600]
        const description = isWorkSchedule ? (i18n.t('employees:employee.planning.planningList.item.descriptionEntry') + planning.workSchedule.name) : i18n.t('employees:employee.planning.planningList.item.descriptionContract')
        const name = isDefault ? i18n.t('employees:employee.planning.planningList.item.descriptionDefault') : formatDate(planning.startDate)

        return {
            leftContent,
            color,
            description,
            name,
            onClick: isDefault ? undefined : onClick,
            onDelete: isDefault ? undefined : (event) => {
                event.stopPropagation()
                onDelete()
            },
        }
    }, [planning])

    return (
        <SimpleListItem {...simpleListItemProps} />
    )


}

export default EmployeePlanningListItem
