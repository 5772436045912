/* eslint-disable @typescript-eslint/no-explicit-any */
import { GroupBase, Props } from 'react-select'

import {
    SelectDefaultClearIndicator,
    SelectDefaultDropdownIndicator,
    SelectDefaultLoadingIndicator,
    SelectDefaultOption,
    twColorMapped,
} from './common'
import { CustomSelectProps } from './sharedTypes'
import { SIZES } from '../../constants/constant'
import { clsxm } from '../../lib/clsxm'

type UseSelectProps<
    Option = unknown,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>
> = Props<Option, IsMulti, Group> & CustomSelectProps

export function useSelectProps<
    Option = unknown,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>
>(props: UseSelectProps<Option, IsMulti, Group>) {
    const {
        size,
        style,
        className,
        components,
        isInvalid,
        // mode = 'light',
        // componentAs: Component = ReactSelect,
        showDropDownIndicator,
        ...rest
    } = props
    const selectSize = size || SIZES.MD

    const twHeight = {
        [SIZES.XS]: '28px',
        [SIZES.SM]: '39px',
        [SIZES.MD]: '44px',
        [SIZES.LG]: '56px',
    }

    const getBoxShadow = (state: any) => {
        const shadaowBase = '0 0 0 1px '

        if (isInvalid) {
            return shadaowBase + twColorMapped.red['500']
        }

        if (state.isFocused) {
            return shadaowBase + twColorMapped.primary['600']
        }

        return 'none'
    }

    const styles = {
        control: (provided: any, state: any) => ({
            ...provided,
            height: twHeight[selectSize],
            minHeight: twHeight[selectSize],
            '&:hover': {
                boxShadow: getBoxShadow(state),
                cursor: 'pointer',
            },
            boxShadow: getBoxShadow(state),
            borderRadius: '0.375rem',
            ...(isInvalid ? { borderColor: twColorMapped.red['500'] } : {}),
        }),
        input: (css: any) => ({
            ...css,
            input: {
                outline: 'none',
                outlineOffset: 0,
                boxShadow: 'none !important',
            },
        }),
        menu: (provided: any) => ({ ...provided, zIndex: 50 }),
        ...style,
    }

    const selectClass = clsxm('select', `select-${selectSize}`, className)
    return {
        className: selectClass,
        styles,
        theme: (theme: any) => ({
            ...theme,
            colors: {
                ...theme.colors,
                // neutral20: mode === 'dark' ? twColor.gray['600'] : twColor.gray['300'],
                // neutral30: mode === 'dark' ? twColor.gray['600'] : twColor.gray['300'],
                // neutral80: twColor.gray['700'],
                // primary25: twColor[themeColor]['50'],
                // primary50: twColor[themeColor]['100'],
                // primary: twColor[themeColor][primaryColorLevel],
            },
        }),
        components: {
            IndicatorSeparator: () => null,
            Option: SelectDefaultOption,
            LoadingIndicator: SelectDefaultLoadingIndicator,
            DropdownIndicator:
                showDropDownIndicator && SelectDefaultDropdownIndicator,
            ClearIndicator: SelectDefaultClearIndicator,
            ...components,
        },
        ...rest,
    }
}
