/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Flex, SegmentedControl, Text } from '@mantine/core'
import { Employee } from '@viterbit/web-app/dataAccess/model/Employee.model'
import useTimeTrackingTeam from '@viterbit/web-app/feature/timeTracking/team/useTimeTrackingTeam'
import i18n from '@viterbit/web-app/i18n'
import EmbeddedContent from '@viterbit/web-app/shared/behaviour/EmbeddedContent'
import EmployeeAvatar from '@viterbit/web-app/shared/employee/EmployeeAvatar'
import YearSelector from '@viterbit/web-app/shared/form/YearSelector'
import SegmentNavLink from '@viterbit/web-app/shared/navigation/SegmentNavLink'
import { FILTER_MODE_OPTIONS_TO_OPERATOR, STRING_COLUMN_FILTER_MODE_OPTIONS } from '@viterbit/web-app/shared/utils/filters'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import get from 'lodash/get'
import {
    MantineReactTable,
    MRT_ColumnDef,
    MRT_ColumnFiltersState,
    MRT_FilterOption,
    MRT_ShowHideColumnsButton,
    MRT_SortingState,
    MRT_ToggleFiltersButton,
    MRT_Virtualizer,
} from 'mantine-react-table'
import { MRT_Localization_ES } from 'mantine-react-table/locales/es'
import { useState } from 'react'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import Alert from 'ui/src/components/Alert'
import Skeleton from 'ui/src/components/Skeleton'
import Tabs from 'ui/src/components/Tabs'

import TimeTrackingTeamCounter from './TimeTrackingTeamCounter'
import { useTimeTrackingReview } from './useTimeTrackingReview'
import { EmployeeTeamTimelineFrequencyList, useTimeTrackingTeamRouteParams } from './useTimeTrackingTeamRouteParams'
import EmployeeGenericFilters, { EmployeeGenericFiltersFields } from '../../employee/EmployeeGenericFilters'
import { FilterGroups, LogicalOperator } from 'viterbit-api-sdk'

dayjs.extend(duration)

const frequencyOptions = EmployeeTeamTimelineFrequencyList.map((frequency) => ({
    value: frequency,
    label: i18n.t(`employees:teamTimeline.frequencyList.${frequency}`),
}))
const getHoursAndMinutes = (durationInMinutes: number) => {
    const isNegative = durationInMinutes < 0
    const absDurationInMinutes = Math.abs(durationInMinutes)
    const hours = String(Math.floor(absDurationInMinutes / 60)).padStart(2, '0')
    const minutes = String(absDurationInMinutes % 60).padStart(2, '0')
    
    return { hours, minutes, isNegative }
}

const renderTime = (accessorKey) => function({ row: { original: employee } }) {
    const rawTime = get(employee, accessorKey)
    const { hours, minutes, isNegative } = getHoursAndMinutes(rawTime)

    return (
        <div >
            {isNegative && '- '}
            <span>{hours}:</span>
            <span>{minutes}h</span>
        </div>
    )
}

const TimeTrackingTeamList = () => {
    const navigate = useNavigate()
    const [filters, setFilters] = React.useState<EmployeeGenericFiltersFields>({
        search: null,
        companyId: null,
        locationId: null,
        reporters: false,
    })

    const tableContainerRef = React.useRef<HTMLDivElement>(null) //we can get access to the underlying TableContainer element and react to its scroll events
    const rowVirtualizerInstanceRef = React.useRef<MRT_Virtualizer<HTMLDivElement, HTMLTableRowElement>>(null) //we can get access to the underlying Virtualizer instance and call its scrollToIndex method
    
    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([])
    const [sorting, setSorting] = useState<MRT_SortingState>([])
    const { type, frequency, start, end, nextLink, prevLink, nextDisabled, changeFrequency, labelRange, approveLink, pendingLink } = useTimeTrackingTeamRouteParams()

    const searchParams = React.useMemo(() => ({
        search: filters.search || undefined,
        companyId: filters.companyId || undefined,
        locationId: filters.locationId || undefined,
        reporters: filters.reporters || undefined,
    }), [filters])

    const renderClickableCell = (accessorKey: string) =>
        function({ row: { original: employee } }) {
            return (
                <div
                    className='cursor-pointer'
                    onClick={(e) => {
                        e?.stopPropagation()
                        navigate(`/employees/list/${employee.id}/time-tracking`)
                    }}
                >
                    {get(employee, accessorKey)}
                </div>
            )
        }
    
    const columns: MRT_ColumnDef<Employee>[] = [
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        {
            accessorKey: 'avatar',
            Cell: ({ row: {original: employee} }) => (
                <div className='flex cursor-pointer'>
                    <EmployeeAvatar employee={employee} onClick={(e) => {
                        e?.stopPropagation()
                        navigate(`/employees/list/${employee.id}/time-tracking`)
                    }} />
                </div>
            ),
            enableSorting: false,
            enableColumnFilter: false,
            enableColumnDragging: false,
            enableColumnActions: false,
            minSize: 30,
            maxSize: 30,
        },
        {
            header: i18n.t('employees:dashboard.table.columns.name'),
            accessorKey: 'name',
            Cell: renderClickableCell('name'),
            columnFilterModeOptions: STRING_COLUMN_FILTER_MODE_OPTIONS,
            filterFn: 'contains',
            enableSorting: false,
            enableColumnActions: false,
            size: 60,
        },
        {
            header: i18n.t('employees:dashboard.table.columns.surname'),
            accessorKey: 'surname',
            Cell: renderClickableCell('surname'),
            columnFilterModeOptions: STRING_COLUMN_FILTER_MODE_OPTIONS,
            filterFn: 'contains',
            enableSorting: false,
            enableColumnActions: false,
            size: 80,
        },
        {
            header: i18n.t('employees:dashboard.table.columns.email'),
            accessorKey: 'email',
            Cell: renderClickableCell('email'),
            columnFilterModeOptions: STRING_COLUMN_FILTER_MODE_OPTIONS,
            filterFn: 'contains',
            enableSorting: false,
            enableColumnActions: false,
            size: 110,
        },
        {
            header: i18n.t('employees:dashboard.table.columns.estimation'),
            accessorKey: 'estimation.estimation',
            enableSorting: false,
            enableColumnFilter: false,
            enableColumnDragging: false,
            enableColumnActions: false,
            Cell: renderTime('estimation.estimation'),
            size: 10,
            mantineTableBodyCellProps: {
                align: 'right'
            },
            mantineTableHeadCellProps: {
                align: 'right'
            },
        },
        {
            header: i18n.t('employees:dashboard.table.columns.worked'),
            accessorKey: 'estimation.worked',
            enableSorting: false,
            enableColumnFilter: false,
            enableColumnDragging: false,
            enableColumnActions: false,
            Cell: renderTime('estimation.worked'),
            size: 10,
            mantineTableBodyCellProps: {
                align: 'right'
            },
            mantineTableHeadCellProps: {
                align: 'right'
            },
        },
        {
            header: i18n.t('employees:dashboard.table.columns.balance'),
            accessorKey: 'estimation.balance',
            enableSorting: false,
            enableColumnFilter: false,
            enableColumnDragging: false,
            enableColumnActions: false,
            Cell: renderTime('estimation.balance'),
            size: 10,
            mantineTableBodyCellProps: {
                align: 'right'
            },
            mantineTableHeadCellProps: {
                align: 'right'
            },
        },
        
    ]

    const [columnFilterFns, setColumnFilterFns] = useState<Record<string, MRT_FilterOption>>(
        Object.fromEntries(
            columns
                .filter(x => x.enableColumnFilter !== false)
                .map(({accessorKey, filterFn, columnFilterModeOptions}) => ([
                    accessorKey,
                    filterFn || columnFilterModeOptions?.[0] || 'contains'
                ]))
        )
    )

    const fixedFilters = React.useMemo<FilterGroups|undefined>(() => {
        if (!columnFilters.length) return undefined
        return {
            operator: LogicalOperator.And,
            groups: [
                {
                    operator: LogicalOperator.And,
                    filters: columnFilters.map(({id, value}) => ({
                        field: id,
                        operator: FILTER_MODE_OPTIONS_TO_OPERATOR[columnFilterFns[id]],
                        value: value as string,
                    }))
                }
            ],
        }
    }, [columnFilters, columnFilterFns])

    const { data, fetchNextPage, isError, isFetching, isFetchingNextPage, total } = useTimeTrackingTeam({
        orderBy: Object.fromEntries(sorting.map(({id, desc}) => [id, desc ? 'desc' : 'asc'])),
        filters: fixedFilters,
        endDate: end,
        startDate: start,
        status: type.toUpperCase(),
        ...searchParams
    })

    const flatData = React.useMemo(
        () => data?.pages.flatMap((page) => page.data) ?? [],
        [data],
    )

    const totalDBRowCount = data?.pages?.[0]?.meta?.total ?? 0
    const totalFetched = flatData.length

    const fetchMoreOnBottomReached = React.useCallback(
        (containerRefElement?: HTMLDivElement | null) => {
            if (containerRefElement) {
                const { scrollHeight, scrollTop, clientHeight } = containerRefElement
                //once the user has scrolled within 400px of the bottom of the table, fetch more data if we can
                if (
                    scrollHeight - scrollTop - clientHeight < 400 &&
              !isFetching &&
              totalFetched < totalDBRowCount
                ) {
                    fetchNextPage()
                }
            }
        },
        [fetchNextPage, isFetching, totalFetched, totalDBRowCount],
    )
    
    //scroll to top of table when sorting or filters change
    React.useEffect(() => {
        if (rowVirtualizerInstanceRef.current) {
            // rowVirtualizerInstanceRef.current?.scrollToIndex(0)
        }
    }, [sorting, columnFilters])

    //a check on mount to see if the table is already scrolled to the bottom and immediately needs to fetch more data
    React.useEffect(() => {
        fetchMoreOnBottomReached(tableContainerRef.current)
    }, [fetchMoreOnBottomReached])

    const { button, employees, setEmployees, employeeIds } = useTimeTrackingReview({
        startDate: start,
        endDate: end,
        type,
    })

    return (
        <>
            <EmbeddedContent>
                <EmployeeGenericFilters setFilters={setFilters}>
                    <div className='flex flex-col gap-1 mb-4'>
                        <span className='block font-semibold'>{i18n.t('employees:teamTimeline.frequencyLabel')}</span>
                        <SegmentedControl
                            onChange={changeFrequency}
                            color="blue"
                            data={frequencyOptions}
                            value={frequency}
                        />
                    </div>
                </EmployeeGenericFilters>
            </EmbeddedContent>

            <Tabs variant='underline'>
                <Tabs.TabList className='gap-10 mx-6 -mb-[1px] bg-grey-100'>
                    <SegmentNavLink
                        exact
                        key='pending'
                        path={pendingLink}
                        matchPath="core-hr/time-tracking/pending/*"
                        activeClassName='text-primary-500 border-b-2 border-primary-500 '
                        className='flex items-center justify-center gap-2 py-3 font-semibold'
                        data-testid="time-tracking-team-pending"
                    >
                        {i18n.t('employees:teamTimeline.tab.pending')}
                        <TimeTrackingTeamCounter currentType={type} type="pending" total={total} sx={{ padding: 4 }}/>
                    </SegmentNavLink>
                    <SegmentNavLink
                        exact
                        key='approve'
                        path={approveLink}
                        matchPath="core-hr/time-tracking/approved/*"
                        activeClassName='text-primary-500 border-b-2 border-primary-500 '
                        className='flex items-center justify-center gap-2 py-3 font-semibold'
                        data-testid="time-tracking-team-approve"
                    >
                        {i18n.t('employees:teamTimeline.tab.approve')}
                        <TimeTrackingTeamCounter currentType={type} type="approved" total={total} sx={{ padding: 4 }}/>
                    </SegmentNavLink>
                </Tabs.TabList>
            </Tabs>

            <MantineReactTable
                columns={columns}
                data={flatData}
                rowCount={totalDBRowCount}
                positionGlobalFilter='left'
                localization={MRT_Localization_ES}

                enableRowSelection
                enableMultiRowSelection
                enableSelectAll
                onRowSelectionChange={setEmployees}
                getRowId={(row) => row.id}
            
                mantineTableProps={{
                    verticalSpacing: 'xs',
                }}
                initialState={{
                    density: 'xs',
                }}
                state={{
                    columnFilters,
                    columnFilterFns,
                    showAlertBanner: isError,
                    showSkeletons: isFetching && !isFetchingNextPage,
                    showProgressBars: isFetchingNextPage,
                    sorting,
                    rowSelection: employees,
                }}

                enablePagination={false}
                enableRowVirtualization
                enableColumnFilterModes

                manualFiltering
                manualSorting
                onColumnFiltersChange={setColumnFilters}
                onSortingChange={setSorting}
                onColumnFilterFnsChange={setColumnFilterFns}
            
                mantineToolbarAlertBannerProps={
                    isError
                        ? {
                            color: 'error',
                            children: 'Error loading data',
                        }
                        : undefined
                }

                mantineTableContainerProps={{
                    ref: tableContainerRef,
                    sx: { height: 'calc(100vh - 380px)' }, //give the table a max height
                    onScroll: (
                        event: React.UIEvent<HTMLDivElement>, //add an event listener to the table container element
                    ) => fetchMoreOnBottomReached(event.target as HTMLDivElement),
                }}
            
                renderToolbarInternalActions={({ table }) => (
                    <Flex gap="xs" align="center" className='ml-2'>
                        <MRT_ToggleFiltersButton table={table} />
                        <MRT_ShowHideColumnsButton table={table} />
                    </Flex>
                )}

                renderTopToolbarCustomActions={() => (
                    <div className={`flex justify-between items-end gap-2 ${employeeIds.length && 'mt-1'}`}>
                        <YearSelector
                            nextLinkProps={{ to: nextLink, disabled: nextDisabled }}
                            prevLinkProps={{ to: prevLink }}
                        >
                            {labelRange}
                        </YearSelector>

                        {!isFetching && !nextDisabled && button}
                        {!isFetching && nextDisabled && (
                            <Alert
                            >
                                {i18n.t('employees:teamTimeline.currentPeriodAlert')}
                            </Alert>
                        )}
                    </div>
                )}

                renderBottomToolbarCustomActions={() => (
                    isFetching && !isFetchingNextPage ? (
                        <Skeleton className="h-[14px] w-28 mt-2" />
                    ) : (
                        <Text>
                            {i18n.t('employees:dashboard.table.bottomToolbar', {
                                total: totalDBRowCount,
                                fetched: totalFetched,
                            })}
                        </Text>
                    )
                )}

                mantinePaperProps={{
                    withBorder: false,
                    className: 'px-3 py-2 relative',
                }}

                mantineProgressProps={({ isTopToolbar }) => ({
                    color: 'primary',
                    size: 'xs',
                    sx: {
                        display: isTopToolbar ? 'none' : 'block', //hide bottom progress bar
                    },
                })}

                rowVirtualizerInstanceRef={rowVirtualizerInstanceRef} //get access to the virtualizer instance
                rowVirtualizerProps={{ overscan: 10 }}

                displayColumnDefOptions={{
                    'mrt-row-select': {
                        mantineTableBodyCellProps: {
                            sx: {
                                width: 0,
                                maxWidth: 50,
                            },
                        },
                        mantineTableHeadCellProps: {
                            sx: {
                                width: 0,
                                maxWidth: 50,
                            },
                        },
                    },

                }}
            />    
        </>    
    )
}

export default TimeTrackingTeamList
