/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateCompanyCommand,
  FindCompanyQueryResponse,
  ListCompanyQueryResponse,
  ResourceCreated,
  ResourceDeleted,
  UpdateCompanyCommand,
} from '../models/index';
import {
    CreateCompanyCommandFromJSON,
    CreateCompanyCommandToJSON,
    FindCompanyQueryResponseFromJSON,
    FindCompanyQueryResponseToJSON,
    ListCompanyQueryResponseFromJSON,
    ListCompanyQueryResponseToJSON,
    ResourceCreatedFromJSON,
    ResourceCreatedToJSON,
    ResourceDeletedFromJSON,
    ResourceDeletedToJSON,
    UpdateCompanyCommandFromJSON,
    UpdateCompanyCommandToJSON,
} from '../models/index';

export interface CreateCompanyRequest {
    createCompanyCommand?: CreateCompanyCommand;
}

export interface DeleteCompanyRequest {
    id: string;
}

export interface FetchCompanyRequest {
    id: string;
    include?: Array<string>;
}

export interface ListCompaniesRequest {
    page?: number;
    include?: Array<string>;
}

export interface UpdateCompanyRequest {
    id: string;
    updateCompanyCommand?: UpdateCompanyCommand;
}

/**
 * 
 */
export class CompanyApi extends runtime.BaseAPI {

    /**
     * Create a company
     * Create a company
     */
    async createCompanyRaw(requestParameters: CreateCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceDeleted>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/companies`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateCompanyCommandToJSON(requestParameters.createCompanyCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceDeletedFromJSON(jsonValue));
    }

    /**
     * Create a company
     * Create a company
     */
    async createCompany(requestParameters: CreateCompanyRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceDeleted> {
        const response = await this.createCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a company
     * Delete a company
     */
    async deleteCompanyRaw(requestParameters: DeleteCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceDeleted>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteCompany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/companies/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceDeletedFromJSON(jsonValue));
    }

    /**
     * Delete a company
     * Delete a company
     */
    async deleteCompany(requestParameters: DeleteCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceDeleted> {
        const response = await this.deleteCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch company by its id
     * Fetch company
     */
    async fetchCompanyRaw(requestParameters: FetchCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindCompanyQueryResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetchCompany.');
        }

        const queryParameters: any = {};

        if (requestParameters.include) {
            queryParameters['include'] = requestParameters.include;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/companies/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindCompanyQueryResponseFromJSON(jsonValue));
    }

    /**
     * Fetch company by its id
     * Fetch company
     */
    async fetchCompany(requestParameters: FetchCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindCompanyQueryResponse> {
        const response = await this.fetchCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List companies
     * List companies
     */
    async listCompaniesRaw(requestParameters: ListCompaniesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListCompanyQueryResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.include) {
            queryParameters['include'] = requestParameters.include;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/companies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListCompanyQueryResponseFromJSON(jsonValue));
    }

    /**
     * List companies
     * List companies
     */
    async listCompanies(requestParameters: ListCompaniesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListCompanyQueryResponse> {
        const response = await this.listCompaniesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a company
     * Update a company
     */
    async updateCompanyRaw(requestParameters: UpdateCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateCompany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/companies/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCompanyCommandToJSON(requestParameters.updateCompanyCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Update a company
     * Update a company
     */
    async updateCompany(requestParameters: UpdateCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.updateCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
