import i18n from '@viterbit/web-app/i18n'
import BasicCreateFormDialog from '@viterbit/web-app/shared/form/BasicCreateFormDialog'
import ButtonCreate from '@viterbit/web-app/shared/form/ButtonCreate'
import * as React from 'react'

import { teamCreate } from '../../../dataAccess/service/teamService'


const TeamCreate = () => {
    const [open, setOpen] = React.useState(false)
    
    const onSuccess = async () => {
        onClose()
    }

    const onClose = () => {
        setOpen(false)
    }

    return <>
        <ButtonCreate collapsed title={i18n.t('employees:teams.createButton')} onClick={() => setOpen(true)} />

        <BasicCreateFormDialog
            isOpen={open}
            withTransition
            title={i18n.t('employees:teams.createTitle')}
            label={i18n.t('employees:teams.createLabel')}
            placeholder={i18n.t('employees:teams.createPlaceholder')}
            onClose={onClose}
            mutationConfig={{
                mutationKey: ['CreateTeam'],
                onSuccess,
                mutationFn: (data) => teamCreate({
                    createTeamCommand: data
                }),
            }}
        />

    </>
}

export default TeamCreate
