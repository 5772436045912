import { Button } from '@mantine/core'
import { GoogleIntegration, Integration, IntegrationType } from '@viterbit/web-app/dataAccess/model/Integration.model'
import { IntegrationGroup, IntegrationOption } from '@viterbit/web-app/dataAccess/model/IntegrationOption.model'
import i18n from '@viterbit/web-app/i18n'
import SimpleCard from '@viterbit/web-app/shared/display/SimpleCard'
import Small from '@viterbit/web-app/shared/typography/Small'
import Text from '@viterbit/web-app/shared/typography/Text'
import Avatar from 'ui/src/components/Avatar'

import IntegrationDelete from './delete/IntegrationDelete'
import { integrationTypeIconMapper } from '../helpers'

export type AccountSettingsIntegrationItemProps = {
    kind: IntegrationGroup
    option: IntegrationOption
    className?: string
}

const GoogleUser = ({ photo, email, name }: GoogleIntegration['meta']['user']) => (
    <div className='flex w-10/12 gap-x-2'>
        <Avatar
            src={photo}
            alt={name}
            shape='circle'
            size='sm'
        >
            {name[0]}
        </Avatar>
        <div className='flex flex-col'>
            <Small bold className='truncate'>{name}</Small>
            <Small className='truncate'>{email}</Small>
        </div>
    </div>
)

const AccountSettingsIntegrationItemContent = (integration: Integration) => {
    const mapper = {
        [IntegrationType.Google]: (<GoogleUser {...(integration as GoogleIntegration).meta.user} />)
    }
    return (
        <div className='flex items-center justify-between w-full gap-1 py-2'>
            {mapper[integration.type] || <div>Coming Soon</div>}
            <IntegrationDelete integration={integration} />
        </div>
    )
}

const AccountSettingsIntegrationItem = ({
    option: {
        available,
        data,
        integrated,
        integrations,
        type,
    },
}: AccountSettingsIntegrationItemProps) => {
    const icon = integrationTypeIconMapper[type]
    return (
        <SimpleCard
            padding
            title={(
                <div className='flex gap-2'>
                    <img src={icon} alt={type} className="self-center h-5 aspect-square" />
                    <span>{i18n.t(`integrations:integration.${type}.title`)}</span>
                </div>
            )}
            actions={(available && !integrated)
                ? (
                    <a href={data.url}>
                        <Button
                            size='xs'
                            variant='light'
                        >
                            {i18n.t('integrations:status.integrate')}
                        </Button>
                    </a>
                )
                : (
                    <Button
                        size='xs'
                        variant='light'
                        sx={{ pointerEvents: 'none' }}
                        color={available ? 'success' : 'warning'}
                    >
                        {i18n.t(`integrations:status.${available ? 'integrated' : 'coming_soon'}`)}
                    </Button>
                )}
        >
            <div className='flex flex-col divide-y gap-y-1'>
                {!integrations.length && (
                    <Text opacity>{i18n.t(`integrations:integration.${type}.description`)}</Text>
                )}
                {integrations.map(x => (<AccountSettingsIntegrationItemContent key={x.id} {...x} />))}
            </div>
        </SimpleCard>
    )
}

export default AccountSettingsIntegrationItem
