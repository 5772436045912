import { IconFileDollar, IconFileText, IconWritingSign } from '@tabler/icons-react'
import SimpleCard from '@viterbit/web-app/shared/display/SimpleCard'
import List from 'ui/src/components/List'

import DocumentEmployeeNavigationItem from './DocumentEmployeeNavigationItem'

type DocumentEmployeeNavigationListProps = {
    currentPath: string
    employeeId: string
}

const DocumentEmployeeNavigationList = (props: DocumentEmployeeNavigationListProps) => {
    const { currentPath, employeeId } = props

    return (
        <SimpleCard>
            <List>
                <DocumentEmployeeNavigationItem
                    type="payroll"
                    employeeId={employeeId}
                    path={`${currentPath}/documents`}
                    Icon={IconFileDollar}
                    className="bg-success-300"
                />
                <DocumentEmployeeNavigationItem
                    type="document"
                    employeeId={employeeId}
                    path={`${currentPath}/documents/document`}
                    Icon={IconFileText}
                    className="bg-danger-300"
                />
                <DocumentEmployeeNavigationItem 
                    type="contract"
                    employeeId={employeeId}
                    path={`${currentPath}/documents/contract`}
                    Icon={IconWritingSign}
                    className="bg-warning-300"
                />
                <DocumentEmployeeNavigationItem
                    type="leave"
                    employeeId={employeeId}
                    path={`${currentPath}/documents/leave`}
                    Icon={IconFileDollar}
                    className="bg-primary-300"
                />
                <DocumentEmployeeNavigationItem
                    type="personal"
                    employeeId={employeeId}
                    path={`${currentPath}/documents/personal`}
                    Icon={IconFileDollar}
                    className="bg-primary-300"
                />
            </List>
        </SimpleCard>
    )
}

export default DocumentEmployeeNavigationList
