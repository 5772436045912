import {
    IconAlertTriangle,
    IconCircleCheck,
    IconInfoCircle,
} from '@tabler/icons-react'
import * as React from 'react'

import { STATUS } from '../../constants/constant'
import { clsxm } from '../../lib/clsxm'

const ICONS = {
    [STATUS.SUCCESS]: {
        color: 'text-success-600',
        icon: <IconCircleCheck size={20} />,
    },
    [STATUS.INFO]: {
        color: 'text-info-600',
        icon: <IconInfoCircle size={20} />,
    },
    [STATUS.WARNING]: {
        color: 'text-warning-600',
        icon: <IconAlertTriangle size={20} />,
    },
    [STATUS.DANGER]: {
        color: 'text-danger-800',
        icon: <IconInfoCircle size={20} />,
    },
}
type Keys = keyof typeof STATUS
type StatusIconProps = {
    type: typeof STATUS[Keys]
    iconColor?: string
    icon?: React.ReactNode
    className?: string
}

const StatusIcon = ({ type, iconColor, icon, className }: StatusIconProps) => {
    const baseIcon = ICONS[type]

    return (
        <span
            data-testid='status-icon'
            className={clsxm(
                `text-2xl ${baseIcon.color}`,
                iconColor,
                className
            )}
        >
            {icon || baseIcon.icon}
        </span>
    )
}
export default StatusIcon
