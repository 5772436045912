/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { classValidatorResolver } from '@hookform/resolvers/class-validator'
import { Input } from '@mantine/core'
import { Organization } from '@viterbit/web-app/dataAccess/model/Organization.model'
import { organizationUpdate } from '@viterbit/web-app/dataAccess/service/organizationService'
import i18n from '@viterbit/web-app/i18n'
import EmbeddedContent from '@viterbit/web-app/shared/behaviour/EmbeddedContent'
import AsideCardInfo from '@viterbit/web-app/shared/display/AsideCardInfo'
import ControlledFieldCkEditor from '@viterbit/web-app/shared/form/ControlledFieldCkEditor'
import Form from '@viterbit/web-app/shared/form/Form'
import SubmitButton from '@viterbit/web-app/shared/form/SubmitButton'
import SettingsLayoutBody from '@viterbit/web-app/shared/layout/SettingsLayoutBody'
import useHostInfo from '@viterbit/web-app/shared/utils/useHostInfo'
import * as React from 'react'

import OrganizationAvatar from './OrganizationAvatar'
import { OrganizationUpdateFields } from './OrganizationUpdateFields'
import { OrganizationUpdateValidator } from './OrganizationUpdateValidator'

type SettingsOrganizationLayoutProps = {
    children?: React.ReactNode
    organization: Organization
}

const resolver = classValidatorResolver(OrganizationUpdateValidator)

const SettingsOrganizationForm = (props: SettingsOrganizationLayoutProps) => {
    const { organization } = props

    const hostInfo = useHostInfo(import.meta.env.VITE_PUBLIC_WEB_URL)

    return (
        <Form<OrganizationUpdateFields>
            mutationConfig={{
                mutationFn: (payload) =>
                    organizationUpdate({
                        id: organization.id,
                        updateOrganizationCommand: {
                            name: payload.name,
                            terms: payload.terms,
                            domain: payload.domain,
                            logoFileId: organization.logo?.id,
                        },
                    }),
            }}
            formConfig={{
                mode: 'all',
                resolver,
                defaultValues: organization,
            }}
        >
            {(form, _, state) => (
                <>
                    <EmbeddedContent>
                        <SubmitButton
                            type='submit'
                            onClick={(e) => {
                                e.preventDefault()
                                state.submit()
                            }}
                            className='float-right max-w-fit'
                        >
                            {i18n.t('settingsOrganization:form.submitButton')}
                        </SubmitButton>
                    </EmbeddedContent>

                    <SettingsLayoutBody
                        left={
                            (
                                <OrganizationAvatar organization={organization}>
                                    <Input.Wrapper
                                        className='w-full'
                                        withAsterisk
                                        error={form.formState.errors.name?.message}
                                        label={i18n.t('settingsOrganization:form.fields.name.label')}
                                    >
                                        <Input
                                            {...form.register('name')}
                                            name='name'
                                            placeholder={i18n.t('settingsOrganization:form.fields.name.placeholder')}
                                        />
                                    </Input.Wrapper>
                                    <Input.Wrapper
                                        className='w-full'
                                        withAsterisk
                                        error={form.formState.errors.domain?.message}
                                        label={i18n.t('settingsOrganization:form.fields.domain.label')}
                                    >
                                        <div className='flex items-center justify-between overflow-hidden border border-gray-300 rounded-md'>
                                            <Input
                                                {...form.register('domain')}
                                                name='domain'
                                                styles={{
                                                    wrapper: {
                                                        marginBottom: 0,
                                                    },
                                                    input: {
                                                        border: 0,
                                                    },
                                                }}
                                                placeholder={i18n.t('settingsOrganization:form.fields.domain.placeholder')}
                                            />
                                            <div
                                                className='leading-[39px] px-3 border-l bg-gray-50'
                                            >
                                                    .{hostInfo.domain}
                                            </div>
                                        </div>
                                    </Input.Wrapper>
                                </OrganizationAvatar>
                            )}
                    >

                        <>
                            <AsideCardInfo
                                title={i18n.t('settingsOrganization:termsSection.title')}
                                description={i18n.t('settingsOrganization:termsSection.description')}
                            >
                                <ControlledFieldCkEditor
                                    asterisk
                                    name='terms'
                                    control={form.control}
                                    label={i18n.t('settingsOrganization:form.fields.terms.label')}
                                />
                            </AsideCardInfo>
                        </>
                    </SettingsLayoutBody>
                </>
            )}
        </Form>
    )
}

export default SettingsOrganizationForm
