import { IMapper } from './__common'
import { LeaveReasonResponse } from '../dto/leaveReasons'
import { LeaveReason } from '../model/LeaveReason.model'

export const DEFAULT_REASON_COLOR = '#CCCCCC'

const leaveReasonMapper: IMapper<LeaveReason, LeaveReasonResponse> = {
    toModel: (dto, leavePolicyId: string) => ({
        leavePolicyId: leavePolicyId,
        id: dto.id,
        name: dto.name,
        description: dto.description,
        needApproval: dto.needApproval,
        needDocumentation: dto.needDocumentation,
        visibleOtherEmployees: dto.visibleOtherEmployees,
        color: dto.color,
        timeDiscount: dto.timeDiscount,
        limitedTo: Math.max(dto.limitedTo, 1),
        isLimitedTo: Number(dto.limitedTo) >= 0
    }),

    toDto: (model) => ({
        id: model.id,
        name: model.name,
        description: model.description,
        needApproval: model.needApproval,
        needDocumentation: model.needDocumentation,
        visibleOtherEmployees: model.visibleOtherEmployees,
        color: model.color || DEFAULT_REASON_COLOR,
        timeDiscount: model.timeDiscount,
        limitedTo: model.isLimitedTo ? model.limitedTo : -1,
    }),
}

export default leaveReasonMapper
