import { useMutation } from '@tanstack/react-query'
import { timeTrackingClockUpdate } from '@viterbit/web-app/dataAccess/service/timeTrackingService'

type TimeTrackingClockConfig = {
    onSuccess?: () => void
    onError?: () => void
}

export const useTimeTrackingClockUpdate = (config: TimeTrackingClockConfig) =>
    useMutation({
        ...config,
        mutationFn: timeTrackingClockUpdate,
        onSuccess: config.onSuccess,
        onError: config.onError,
    })
