/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FindIntegrationQueryResponse } from './FindIntegrationQueryResponse';
import {
    FindIntegrationQueryResponseFromJSON,
    FindIntegrationQueryResponseFromJSONTyped,
    FindIntegrationQueryResponseToJSON,
} from './FindIntegrationQueryResponse';
import type { ListMeta } from './ListMeta';
import {
    ListMetaFromJSON,
    ListMetaFromJSONTyped,
    ListMetaToJSON,
} from './ListMeta';

/**
 * 
 * @export
 * @interface ListIntegrationQueryResponse
 */
export interface ListIntegrationQueryResponse {
    /**
     * 
     * @type {ListMeta}
     * @memberof ListIntegrationQueryResponse
     */
    meta: ListMeta;
    /**
     * 
     * @type {Array<FindIntegrationQueryResponse>}
     * @memberof ListIntegrationQueryResponse
     */
    data: Array<FindIntegrationQueryResponse>;
}

/**
 * Check if a given object implements the ListIntegrationQueryResponse interface.
 */
export function instanceOfListIntegrationQueryResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "meta" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function ListIntegrationQueryResponseFromJSON(json: any): ListIntegrationQueryResponse {
    return ListIntegrationQueryResponseFromJSONTyped(json, false);
}

export function ListIntegrationQueryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListIntegrationQueryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'meta': ListMetaFromJSON(json['meta']),
        'data': ((json['data'] as Array<any>).map(FindIntegrationQueryResponseFromJSON)),
    };
}

export function ListIntegrationQueryResponseToJSON(value?: ListIntegrationQueryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'meta': ListMetaToJSON(value.meta),
        'data': ((value.data as Array<any>).map(FindIntegrationQueryResponseToJSON)),
    };
}

