import splitComponents from '@viterbit/web-app/shared/utils/splitComponents'
import * as React from 'react'
import clsxm from 'ui/src/lib'

import ChatBar from './ChatBar'
import ChatBarForm from './ChatBarForm'
import ChatMessage from './ChatMessage'
import ChatMessages from './ChatMessages'
import SimpleCard from '../../display/SimpleCard'


type ChatProps = {
    children?: React.ReactNode
    className?: string
}

const styles = {
    root: ''
}

const Chat = (props: ChatProps) => {
    const { children, className } = props

    const { messages, chatBar, others } = React.useMemo(() => splitComponents(children, [
        { name: 'messages', component: ChatMessages },
        { name: 'messages', component: ChatMessage },
        { name: 'chatBar', component: ChatBar },
        { name: 'chatBar', component: ChatBarForm },
    ]), [children])

    return (
        <SimpleCard className='mt-4'>
            <div
                className={clsxm('h-[400px] justify-end flex flex-col pb-4 space-y-4', className)}
            >
                {messages}

                {chatBar}

                {others}
            </div>
        </SimpleCard>
    )
}

export default Chat
