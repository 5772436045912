import { Button as MantineButton } from '@mantine/core'
import { IconChevronDown, IconFilter, IconSearch } from '@tabler/icons-react'
import { Employee } from '@viterbit/web-app/dataAccess/model/Employee.model'
import i18n from '@viterbit/web-app/i18n'
import Form from '@viterbit/web-app/shared/form/Form'
import FormSelectCompany from '@viterbit/web-app/shared/form/FormSelectCompanies'
import FormSelectLocation from '@viterbit/web-app/shared/form/FormSelectLocation'
import SubmitButton from '@viterbit/web-app/shared/form/SubmitButton'
import * as React from 'react'
import Button from 'ui/src/components/Button'
import Dropdown from 'ui/src/components/Dropdown'
import {
    FormInput, FormSwitcher,
} from 'ui/src/components/Form'

import TeamAssignEmployeeForm from './TeamAssignEmployeeForm'

export type TeamAssignEmployeeDialogFormProps = {
    onCancel?: () => void
    onConfirm?: () => void
    teamId?: string
    complaintManager?: boolean
    onSubmit: (data: TeamAssignEmployeeDialogFormFields) => Promise<any>
}

type TeamAssignEmployeeDialogFormFields = {
    search?: string
    companyId?: string,
    locationId?: string,
    reporters?: boolean
    employees: Employee[]
}

const TeamAssignEmployeeDialogForm = ({
    onCancel,
    onConfirm,
    teamId,
    complaintManager,
    onSubmit,
}: TeamAssignEmployeeDialogFormProps) => (
    <Form<TeamAssignEmployeeDialogFormFields>
        withTransition
        formConfig={{
            shouldUnregister: false,
            defaultValues: {
                employees: [],
            }
        }}
        mutationConfig={{
            mutationFn: onSubmit,
            onSuccess: onConfirm,
        }}
    >
        {(form) => {
            const {employees: _employees, ...query} = form.watch()
            return (
                <>
                    <div className='flex mb-3 gap-x-2'>
                        <FormInput
                            control={form.control}
                            name="search"
                            className='w-64 h-full mb-0 '
                            placeholder={i18n.t('employees:common.search')}
                            suffix={<IconSearch size={21} />}
                        />
                        <Dropdown
                            trigger="hover"
                            placement='bottom-end'
                            renderTitle={
                                <Button>
                                    <IconFilter className='mr-2' size={21} />
                                    <span>{i18n.t('employees:common.filter')}</span>
                                    <IconChevronDown className='ml-2 text-lg' size={21} />
                                </Button>
                            }
                        >
                            <div className='min-w-[300px] pt-2 px-2'>
                                <FormSelectCompany
                                    label={i18n.t('employees:common.company')}
                                    isClearable
                                    placeholder={i18n.t('employees:common.placeholderSelect')}
                                    control={form.control}
                                    name="companyId"
                                />
                                <FormSelectLocation
                                    label={i18n.t('employees:common.location')}
                                    isClearable
                                    control={form.control}
                                    name="locationId"
                                    placeholder={i18n.t('employees:common.placeholderSelect')}
                                />
                                                
                                <FormSwitcher
                                    className='flex-row mb-3'
                                    label={i18n.t('employees:common.switcher')}
                                    name='reporters'
                                    control={form.control}
                                />
                            </div>
                        </Dropdown>
                    </div>
                    
                    <TeamAssignEmployeeForm
                        control={form.control}
                        name='employees'
                        query={query}
                        teamId={teamId}
                        complaintManager={complaintManager}
                    />
                        
                    <MantineButton variant='outline' color='gray' onClick={onCancel}>{i18n.t('common:buttons.cancel')}</MantineButton>
                    <SubmitButton>{i18n.t('employees:teams.employee.assign')}</SubmitButton>  
                </>
            )
        }}
    </Form>
        
)

export default TeamAssignEmployeeDialogForm
