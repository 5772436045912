/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FindComplaintQueryResponse } from 'viterbit-api-sdk'

import { IMapper } from './__common'
import fileMapper from './file.mapper'
import { Complaint } from '../model/Complaint.model'

const complaintMapper: IMapper<Complaint, FindComplaintQueryResponse> = {
    toModel: (dto) => {
        const authorConfirmed = !!(dto.authorEmail || dto.authorPhone || dto.authorName || dto.authorRelationship)
    
        return {
            id: dto.id!,
            title: dto.title!,
            status: dto.status as any,
            authorConfirmed,
            createdAt: dto.createdAt!,
            updatedAt: dto.updatedAt || null,
            receivedAt: dto.receivedAt || null,
            finishedAt: dto.finishedAt || null,
            authorEmail: dto.authorEmail || null,
            authorName: dto.authorName || null,
            authorPhone: dto.authorPhone || null,
            authorRelationship: dto.authorRelationship || null,
            firstViewedAt: null,
            lastViewedAt: null,
            records: dto.records?.data.map(item => ({
                id: item.id!,
                message: item.message!,
                createdAt: item.createdAt!,
                employeeName: item.employeeName || null,
                files: (item.files || []).map(fileMapper.toModel)
            })) || []

        }
    },
    toDto: (dto) => {
        throw new Error('Not implemented')
    }
}

export default complaintMapper
