import logoBlue from '@viterbit/web-assets/images/logo_blue.png'
import logoWhite from '@viterbit/web-assets/images/logo_white.png'
import * as React from 'react'
import { Link } from 'react-router-dom'

const styles = {
    root: 'flex items-center justify-center w-16 h-16 mx-auto bg-white rounded-full'
}

const colorIcons = {
    white: logoWhite,
    blue: logoBlue
}

type LogoProps = {
    color?: keyof typeof colorIcons
}

const Logo = (props: LogoProps ) => {
    const { color = 'blue' } = props

    return (
        <Link to='/'>
            <img src={colorIcons[color]} alt='Viterbit logo' width='26' className='mr-6' />
        </Link>

    )
}

export default Logo
