/* eslint-disable @typescript-eslint/no-explicit-any */

import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react'

import FormCounter, { FormCounterProps } from './FormCounter'

const FormYearCounter = ({
    iconLeft = <IconChevronLeft className='w-5 h-5' />,
    iconRight = <IconChevronRight className='w-5 h-5' />,
    ...rest
}: FormCounterProps) => (
    <FormCounter iconLeft={iconLeft} iconRight={iconRight} {...rest} />
)

export default FormYearCounter
