
import i18n from '@viterbit/web-app/i18n'
import { UseFormReturn } from 'react-hook-form'
import { FormInput } from 'ui/src/components/Form'

import { JobPositionLevelDetailView } from './JobPositionLevelDetailFields'

type DetailsJobPositionLevelFormProps = {
    form: UseFormReturn<JobPositionLevelDetailView>
}

const JobPositionLevelDetailForm = ({ form }: DetailsJobPositionLevelFormProps) => (
    <>
        <FormInput
            asterisk
            name='name'
            control={form.control}
            label={i18n.t('employees:jobPositions.levels.nameLabel')}
            placeholder={i18n.t('employees:jobPositions.levels.namePlaceholder')}
        />
        <FormInput
            textArea
            size='lg'
            name='description'
            control={form.control}
            label={i18n.t('employees:jobPositions.levels.descriptionLabel')}
            placeholder={i18n.t('employees:jobPositions.levels.descriptionPlaceholder')}
        />
    </>
)

export default JobPositionLevelDetailForm
