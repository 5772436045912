

import accountMapper from '@viterbit/web-app/dataAccess/mapper/account.mapper'
import accountExtendedMapper from '@viterbit/web-app/dataAccess/mapper/accountExtended.mapper'
import paginationMetaMapper from '@viterbit/web-app/dataAccess/mapper/paginationMeta.mapper'
import userMapper from '@viterbit/web-app/dataAccess/mapper/user.mapper'
import { queryClient } from '@viterbit/web-app/shared/lib/ReactQueryProvider'
import { AccountApi,Configuration } from 'viterbit-api-sdk'

import { commonConfig } from './config'
import { employeeInvalidate } from './employeeService'
import { ACCOUNT_QUERY_KEY, EMPLOYEE_QUERY_KEY } from '../cacheKeys'

const api = new AccountApi(new Configuration(commonConfig))

export const accountInvalidate = () => {
    queryClient.invalidateQueries([EMPLOYEE_QUERY_KEY])
    queryClient.invalidateQueries([ACCOUNT_QUERY_KEY])
}

export const accountCreate = (body: Parameters<typeof api.createAccount>[0]) =>
    api.createAccount(body).then((data) => {
        accountInvalidate()
        return data
    })

export const accountUpdate = (body: Parameters<typeof api.updateAccount>[0]) =>
    api.updateAccount(body).then((data) => {
        accountInvalidate()
        return data
    })

export const accountDelete = (body: Parameters<typeof api.deleteAccount>[0]) =>
    api.deleteAccount(body).then((data) => {
        accountInvalidate()
        return data
    })

export const accountList = (body: Parameters<typeof api.fetchAccounts>[0]) =>
    api.fetchAccounts(body).then(({data, meta}) => ({
        meta: paginationMetaMapper(meta),
        data: data.map(accountMapper.toModel)
    }))

export const accountListAll = (body: Parameters<typeof api.fetchAllAccounts>[0]) =>
    api.fetchAllAccounts(body).then(({data, meta}) => ({
        meta: paginationMetaMapper(meta),
        data: data.map(accountExtendedMapper.toModel)
    }))

export const accountDetail = (body: Parameters<typeof api.findAccounts>[0]) =>
    api.findAccounts(body).then(accountMapper.toModel)

export const accountMeInfo = () =>
    api.findMeAccount().then(userMapper.toModel)

export const accountChangePassword = (body: Parameters<typeof api.changePassword>[0]) =>
    api.changePassword(body).then((data) => {
        accountInvalidate()
        return data
    })

export const accountChangeRole = (body: Parameters<typeof api.changeRole>[0]) =>
    api.changeRole(body).then((data) => {
        accountInvalidate()
        return data
    })

export const accountInvite = (body: Parameters<typeof api.inviteAccount>[0]) =>
    api.inviteAccount(body).then((data) => {
        employeeInvalidate()
        return data
    })

export const accountResendInvitation = (body: Parameters<typeof api.resendInvitationAccount>[0]) =>
    api.resendInvitationAccount(body).then((data) => {
        employeeInvalidate()
        return data
    })

export const accountRedeemInvite = (body: Parameters<typeof api.redeemInviteAccount>[0]) =>
    api.redeemInviteAccount(body)