/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FindMeAccountQueryResponse
 */
export interface FindMeAccountQueryResponse {
    /**
     * 
     * @type {string}
     * @memberof FindMeAccountQueryResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof FindMeAccountQueryResponse
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof FindMeAccountQueryResponse
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof FindMeAccountQueryResponse
     */
    organizationId: string;
    /**
     * 
     * @type {string}
     * @memberof FindMeAccountQueryResponse
     */
    locale: FindMeAccountQueryResponseLocaleEnum;
    /**
     * 
     * @type {string}
     * @memberof FindMeAccountQueryResponse
     */
    timezone: string;
    /**
     * 
     * @type {string}
     * @memberof FindMeAccountQueryResponse
     */
    subscriptionStatus: string;
    /**
     * 
     * @type {boolean}
     * @memberof FindMeAccountQueryResponse
     */
    validPaymentMethod: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FindMeAccountQueryResponse
     */
    canSwitchUser: boolean;
    /**
     * 
     * @type {object}
     * @memberof FindMeAccountQueryResponse
     */
    menu: object;
}


/**
 * @export
 */
export const FindMeAccountQueryResponseLocaleEnum = {
    EsEs: 'es-ES',
    EsMx: 'es-MX',
    EnUs: 'en-US',
    EnGb: 'en-GB'
} as const;
export type FindMeAccountQueryResponseLocaleEnum = typeof FindMeAccountQueryResponseLocaleEnum[keyof typeof FindMeAccountQueryResponseLocaleEnum];


/**
 * Check if a given object implements the FindMeAccountQueryResponse interface.
 */
export function instanceOfFindMeAccountQueryResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "username" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "organizationId" in value;
    isInstance = isInstance && "locale" in value;
    isInstance = isInstance && "timezone" in value;
    isInstance = isInstance && "subscriptionStatus" in value;
    isInstance = isInstance && "validPaymentMethod" in value;
    isInstance = isInstance && "canSwitchUser" in value;
    isInstance = isInstance && "menu" in value;

    return isInstance;
}

export function FindMeAccountQueryResponseFromJSON(json: any): FindMeAccountQueryResponse {
    return FindMeAccountQueryResponseFromJSONTyped(json, false);
}

export function FindMeAccountQueryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindMeAccountQueryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'username': json['username'],
        'email': json['email'],
        'organizationId': json['organizationId'],
        'locale': json['locale'],
        'timezone': json['timezone'],
        'subscriptionStatus': json['subscriptionStatus'],
        'validPaymentMethod': json['validPaymentMethod'],
        'canSwitchUser': json['canSwitchUser'],
        'menu': json['menu'],
    };
}

export function FindMeAccountQueryResponseToJSON(value?: FindMeAccountQueryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'username': value.username,
        'email': value.email,
        'organizationId': value.organizationId,
        'locale': value.locale,
        'timezone': value.timezone,
        'subscriptionStatus': value.subscriptionStatus,
        'validPaymentMethod': value.validPaymentMethod,
        'canSwitchUser': value.canSwitchUser,
        'menu': value.menu,
    };
}

