
import { Divider } from '@mantine/core'
import i18n from '@viterbit/web-app/i18n'
import { UseFormReturn } from 'react-hook-form'
import { FormInput, FormSwitcher } from 'ui/src/components/Form'

import { ComplaintCreateFields } from './ComplaintCreateFields'

type ComplaintCreateFormFieldsProps = {
    form: UseFormReturn<ComplaintCreateFields>
}

const ComplaintCreateFormFields = (props: ComplaintCreateFormFieldsProps) => {
    const { form } = props

    const authorConfirmed: boolean = form.watch('authorConfirmed', false)

    return (
        <>

            <FormInput
                asterisk
                name='title'
                control={form.control}
                label={i18n.t('complaint:create.form.title')}
                placeholder={i18n.t('complaint:create.form.titlePlaceholder')}
                data-testid="complaint-title"
            />

            <Divider className='pb-6'/>

            <FormSwitcher
                className='flex-row'
                name="authorConfirmed"
                label={i18n.t('complaint:create.form.authorConfirmed')}
                control={form.control}
            />

            {authorConfirmed && (
                <>
                    <FormInput
                        name='authorName'
                        control={form.control}
                        label={i18n.t('complaint:create.form.authorName')}
                        placeholder={i18n.t('complaint:create.form.authorNamePlaceholder')}
                        data-testid="complaint-authorName"
                    />
                    <FormInput
                        name='authorPhone'
                        control={form.control}
                        label={i18n.t('complaint:create.form.authorPhone')}
                        placeholder={i18n.t('complaint:create.form.authorPhonePlaceholder')}
                        data-testid="complaint-authorPhone"
                    />
                    <FormInput
                        name='authorEmail'
                        control={form.control}
                        label={i18n.t('complaint:create.form.authorEmail')}
                        placeholder={i18n.t('complaint:create.form.authorEmailPlaceholder')}
                        data-testid="complaint-authorEmail"
                    />
                    <FormInput
                        name='authorRelationship'
                        control={form.control}
                        label={i18n.t('complaint:create.form.authorRelationship')}
                        placeholder={i18n.t('complaint:create.form.authorRelationshipPlaceholder')}
                        data-testid="complaint-authorRelationship"
                    />
                </>
            )}
        </>
    )
}

export default ComplaintCreateFormFields
