
import { PermissionAction } from '@viterbit/web-app/dataAccess/model/PermissionAction.model'
import { UploadFileTypeEnum } from 'viterbit-api-sdk'

export type DocumentProps = {
    employeeId: string
    type: UploadFileTypeEnum
}

export type DocumentPermissions = {
    canUpload?: boolean
}

export const DEFAULT_DOCUMENT_TYPE = UploadFileTypeEnum.Payroll

export enum FileTypeAction {
    FIND = 'FIND',
    LIST = 'LIST',
    UPLOAD = 'UPLOAD',
    DELETE = 'DELETE',
}

const documentActions = {
    [UploadFileTypeEnum.Leave]: {
        [FileTypeAction.FIND]: PermissionAction.CORE_LEAVE_FILE_FIND,
        [FileTypeAction.LIST]: PermissionAction.CORE_LEAVE_FILE_LIST,
        [FileTypeAction.UPLOAD]: PermissionAction.CORE_LEAVE_FILE_UPLOAD,
        [FileTypeAction.DELETE]: PermissionAction.CORE_LEAVE_FILE_DELETE,
    },
    [UploadFileTypeEnum.Personal]: {
        [FileTypeAction.FIND]: PermissionAction.CORE_PERSONAL_FILE_FIND,
        [FileTypeAction.LIST]: PermissionAction.CORE_PERSONAL_FILE_LIST,
        [FileTypeAction.UPLOAD]: PermissionAction.CORE_PERSONAL_FILE_UPLOAD,
        [FileTypeAction.DELETE]: PermissionAction.CORE_PERSONAL_FILE_DELETE,
    },
    [UploadFileTypeEnum.Payroll]: {
        [FileTypeAction.FIND]: PermissionAction.CORE_PAYROLL_FILE_FIND,
        [FileTypeAction.LIST]: PermissionAction.CORE_PAYROLL_FILE_LIST,
        [FileTypeAction.UPLOAD]: PermissionAction.CORE_PAYROLL_FILE_UPLOAD,
        [FileTypeAction.DELETE]: PermissionAction.CORE_PAYROLL_FILE_DELETE,
    },
    [UploadFileTypeEnum.Document]: {
        [FileTypeAction.FIND]: PermissionAction.CORE_DOCUMENT_FILE_FIND,
        [FileTypeAction.LIST]: PermissionAction.CORE_DOCUMENT_FILE_LIST,
        [FileTypeAction.UPLOAD]: PermissionAction.CORE_DOCUMENT_FILE_UPLOAD,
        [FileTypeAction.DELETE]: PermissionAction.CORE_DOCUMENT_FILE_DELETE,
    },
    [UploadFileTypeEnum.Contract]: {
        [FileTypeAction.FIND]: PermissionAction.CORE_CONTRACT_FILE_FIND,
        [FileTypeAction.LIST]: PermissionAction.CORE_CONTRACT_FILE_LIST,
        [FileTypeAction.UPLOAD]: PermissionAction.CORE_CONTRACT_FILE_UPLOAD,
        [FileTypeAction.DELETE]: PermissionAction.CORE_CONTRACT_FILE_DELETE,
    },
}

export const getActionKeyByDocumentType = (type: UploadFileTypeEnum, action: FileTypeAction): PermissionAction => documentActions[type][action]



