/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { IFile } from 'ui/src/components/Upload/sharedTypes'
import { UploadFileTypeEnum } from 'viterbit-api-sdk'

import publicEmployeeMapper from './publicEmployee.mapper'
import { PendingFileResponse, UploadPendingFilePayload } from '../dto/files'
import { PendingFile } from '../model/PendingFile.model'

const pendingFileMapper = {
    toModel: (dto: PendingFileResponse): PendingFile => ({
        id: dto.id,
        name: dto.name,
        size: dto.size,
        type: dto.mimeType,
        kind: dto.targetType as UploadFileTypeEnum,
        url: `/api/files/${dto.type}/${dto.id}`,
        extension: dto.extension,
        employee: dto.employee && publicEmployeeMapper.toModel(dto.employee)
    }),
    modelToIFile: ({id, name, size, type, url,}: PendingFile): IFile => ({
        id,
        name,
        size,
        kind: type,
        url,
    }),
    iFileToUploadPayload: ({ _meta }: IFile, kind?: UploadFileTypeEnum): UploadPendingFilePayload => ({
        file: _meta!.file,
        type: kind,
    }),
}

export default pendingFileMapper
