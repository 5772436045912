import { useMemo } from 'react'
import { useHref } from 'react-router-dom'

const getParentHref = (href: string, needle: string): string => href.substring(0, href.lastIndexOf(needle))

export const useParentHref = (...needle: string[]): string => {
    const href = useHref({})
    const parentHref = useMemo(() => {
        const match = needle.find((needle) => href.includes(needle))
        return match && getParentHref(href, match)
    }, [needle])

    return parentHref || '/'
}

