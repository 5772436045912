/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RejectDayCommand
 */
export interface RejectDayCommand {
    /**
     * 
     * @type {Array<string>}
     * @memberof RejectDayCommand
     */
    employeeIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RejectDayCommand
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof RejectDayCommand
     */
    endDate: string;
}

/**
 * Check if a given object implements the RejectDayCommand interface.
 */
export function instanceOfRejectDayCommand(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "employeeIds" in value;
    isInstance = isInstance && "startDate" in value;
    isInstance = isInstance && "endDate" in value;

    return isInstance;
}

export function RejectDayCommandFromJSON(json: any): RejectDayCommand {
    return RejectDayCommandFromJSONTyped(json, false);
}

export function RejectDayCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): RejectDayCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'employeeIds': json['employeeIds'],
        'startDate': json['startDate'],
        'endDate': json['endDate'],
    };
}

export function RejectDayCommandToJSON(value?: RejectDayCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'employeeIds': value.employeeIds,
        'startDate': value.startDate,
        'endDate': value.endDate,
    };
}

