/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useCompanyDetail } from '@viterbit/web-app/dataAccess/useCase/company/useCompanyDetail'
import { useParams } from 'react-router-dom'

const useCompany = () => {
    const { id } = useParams<'id'>()

    return useCompanyDetail({
        query: { id: id! },
        enabled: !!id,
    })
}

export default useCompany