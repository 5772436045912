/* eslint-disable @typescript-eslint/no-explicit-any */
import { Badge } from '@mantine/core'
import * as React from 'react'

type TimeTrackingTeamCounterProps = {
    children?: React.ReactNode
    className?: string
    type: 'pending' | 'approved'
    currentType: 'pending' | 'approved'
    sx?: any
    total: {
        PENDING: number
        APPROVED: number
    }
}

const TimeTrackingTeamCounter = (props: TimeTrackingTeamCounterProps) => {
    const { type, currentType, total, sx } = props

    return (
        <Badge
            sx={sx}
            variant='filled'
            color={type === currentType ? 'primary': 'grey'}
        >
            {total[type.toUpperCase()]}
        </Badge>

    )
}

export default TimeTrackingTeamCounter
