/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { State } from '@viterbit/web-app/dataAccess/model/State.model'
import { geoDataStateList } from '@viterbit/web-app/dataAccess/service/geoDataService'
import { GroupBase } from 'react-select'
import {
    FormSelectAsyncPaginate,
    FormSelectAsyncPaginateProps,
} from 'ui/src/components/Form'
import { FormBaseProps } from 'ui/src/components/Form/shared'

import { useNotification } from '../feedback/useNotification'


export type FormSelectStateProps<IsMulti extends boolean> = FormBaseProps &
    Omit<
        FormSelectAsyncPaginateProps<
            State,
            IsMulti,
            GroupBase<State>,
            { page: number }
        >,
        'loadOptions' | 'defaultValue'
    > & {
        query?: Omit<Parameters<typeof geoDataStateList>[0], 'page'>,
        defaultValue?: string
    }

type LoadOptions = FormSelectAsyncPaginateProps<
    State,
    boolean,
    GroupBase<State>,
    { page: number }
>['loadOptions']

const FormSelectState = <IsMulti extends boolean>({
    name,
    control,
    cacheOptions = true,
    isSearchable = true,
    query,
    defaultValue,
    ...rest
}: FormSelectStateProps<IsMulti>) => {
    const notification = useNotification()
    const loadOptions: LoadOptions = async (search, loadedOptions, _meta) =>
        geoDataStateList({ ...query!, page: _meta?.page, search, })
            .then(({ data, meta }) => {
                const hasMore =
                !!meta?.total &&
                meta.page <
                    Math.ceil(meta.total / ((meta as any).pageSize ?? 10))
                return {
                    options: data,
                    hasMore,
                    additional: {
                        page: meta.page + 1,
                    },
                }
            })
            .catch((err) => {
                notification.push(
                    'danger',
                    'Fetching error',
                    err.errorCode ?? 'Error at fetch leave policies'
                )
                return {
                    options: [],
                    hasMore: false,
                }
            })
    return (
        <>
            <FormSelectAsyncPaginate
                name={name}
                control={control}
                cacheOptions={cacheOptions}
                loadOptions={loadOptions}
                isSearchable={isSearchable}
                getOptionLabel={(x) => x.name}
                getOptionValue={(x) => x.name}
                loadOptionsOnMenuOpen
                additional={{
                    page: 1,
                }}
                defaultValue={defaultValue && { name: defaultValue } as any}
                {...rest}
            />
        </>
    )
}

export default FormSelectState
