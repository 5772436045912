import * as React from 'react'
import Avatar from 'ui/src/components/Avatar'
import { AvatarProps } from 'ui/src/components/Avatar/Avatar'

import { Employee } from '../../dataAccess/model/Employee.model'

interface EmployeeAvatarProps extends AvatarProps {
    employee: Employee
}

const EmployeeAvatar = (props: EmployeeAvatarProps) => {
    const { employee, ...rest } = props

    const src = employee.avatar?.url

    return (
        <Avatar
            src={src}
            alt={employee.name}
            shape='circle'
            style={{
                backgroundColor: employee.color
            }}
            {...rest}
        >
            {employee.name[0]}
        </Avatar>

    )
}

export default EmployeeAvatar
