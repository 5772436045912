import getHostInfo, { HostInfo } from './getHostInfo'
import { Organization } from '../../dataAccess/model/Organization.model'

const getPublicPath = (organization: Organization, hostInfo: HostInfo = getHostInfo(import.meta.env.VITE_PUBLIC_WEB_URL)): string => {
    const protocol = import.meta.env.VITE_PUBLIC_WEB_URL.split('://')[0]
    const port = window.location.port ? `:${window.location.port}` : ''
    const publicPath = `${protocol}://${organization?.domain}.${hostInfo.domain}${port}`

    return publicPath
}

export default getPublicPath
