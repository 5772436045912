import * as React from 'react'

import { TabsVariantType } from './Tabs'

type ValueType = {
    value?: string
    onValueChange?: (value: string) => void
    variant: TabsVariantType
}

const TabsContext = React.createContext<ValueType>({
    variant: 'underline',
})

export const TabsContextProvider = TabsContext.Provider

export const TabsContextConsumer = TabsContext.Consumer

export const useTabs = () => React.useContext(TabsContext)

export default TabsContext
