import { IsNotEmpty, ValidateIf } from '@viterbit/web-app/shared/utils/ClassValidators'

import { EmployeeDeleteDialogFields } from './EmployeeDeleteDialogShared'


export class EmployeeDeleteValidator implements EmployeeDeleteDialogFields {
    isManager: boolean
    @IsNotEmpty()
    endDate: Date | null
    @IsNotEmpty()
    reason: string
    observations: string
    @ValidateIf((o) => o.isManager)
    @IsNotEmpty()
    newManagerId: string
}