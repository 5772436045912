import * as React from 'react'

import ListItemSkeleton from '../List/ListItemSkeleton'


const styles = {
    root: 'py-0 pl-0 min-h-[auto] border rounded-lg overflow-hidden',
    thumbnail: 'w-16 h-16 mr-2 rounded-none',
}

const FileItemSkeleton = () => (
    <ListItemSkeleton primary secondary className={styles.root} icon iconClassName={styles.thumbnail} />
)

export default FileItemSkeleton
