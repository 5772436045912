/* eslint-disable @typescript-eslint/no-explicit-any */
import { IconArrowRight } from '@tabler/icons-react'
import isNil from 'lodash/isNil'
import * as React from 'react'

import DropdownContext from './context/dropdownContext'
import DropdownMenuContext, {
    DropdownMenuContextProvider,
    useDropdownMenuContext,
} from './context/dropdownMenuContext'
import MenuContext from './context/menuContext'
import MenuItem from './Item'
import { DropdownModeType, DropdownTriggerType } from './sharedTypes'
import useUncertainRef from '../../hooks/useUncertainRef'
import { clsxm } from '../../lib/clsxm'

const classes = {
    submenuItem: 'justify-between',
    light: 'text-black',
    dark: 'text-gray-400 dark:text-gray-400',
    themed: 'text-gray-100 text-opacity-80',
    transparent: 'text-black dark:text-gray-400',
    variant: {
        default: '',
        header: '',
        divider: 'border-b border-gray-200 dark:border-gray-600 my-2',
        custom: '',
    },
}

type VariantType = 'default' | 'header' | 'divider' | 'custom'

export type DropdownItemProps = {
    variant?: VariantType
    mode?: DropdownModeType
    children?: React.ReactNode
    active?: boolean
    disabled?: boolean
    className?: string
    submenu?: any
    eventKey?: string
    style?: any
    icon?: React.ReactNode
    trigger?: DropdownTriggerType
    onClick?: () => void
    onSelect?: (eventKey: string, e: React.MouseEvent) => void
}

const DropdownItem = React.forwardRef<HTMLDivElement, DropdownItemProps>(
    (
        {
            mode = 'light',
            children,
            active: activeProp,
            disabled,
            className,
            submenu,
            style,
            eventKey = '',
            onClick,
            onSelect,
            variant,
            ...rest
        },
        ref
    ) => {
        const menuitemRef = useUncertainRef(ref)
        const menuitemId = React.useId()
        const submenuRef = React.useRef()

        const dropdown = React.useContext(DropdownContext)
        const menu = React.useContext(MenuContext)
        const menuControl = React.useContext(DropdownMenuContext)
        const submenuControl = useDropdownMenuContext(submenuRef)

        const open = submenuControl.open

        const active =
            activeProp ||
            (!isNil(menu?.activeKey) && menu?.activeKey === eventKey) ||
            (!isNil(dropdown.activeKey) && dropdown.activeKey === eventKey)

        const openSubmenuIfExists = React.useCallback(() => {
            if (!submenu) {
                return
            }
            submenuControl.openMenu()
            submenuControl.focusItemAt(0)
        }, [submenu, submenuControl])

        const activate = React.useCallback(
            (e: React.MouseEvent) => {
                onSelect?.(eventKey, e)
                menu?.onSelect?.(eventKey, e)
            },
            [eventKey, onSelect, menu]
        )

        const handleClick = React.useCallback(
            (e: React.MouseEvent) => {
                if (disabled) {
                    return
                }

                if (submenu) {
                    openSubmenuIfExists()
                } else {
                    activate(e)
                }
            },
            [disabled, submenu, openSubmenuIfExists, activate]
        )

        const handleOnClick = (e: React.MouseEvent) => {
            handleClick(e)
            onClick?.()
        }

        const handleMouseOver = React.useCallback(() => {
            if (submenu) {
                submenuControl.openMenu()
            }
        }, [submenu, submenuControl])

        const handleMouseOut = React.useCallback(() => {
            if (submenu) {
                submenuControl.closeMenu()
            }
        }, [submenu, submenuControl])

        const menuitemEventHandlers: any = {
            onClick: handleOnClick,
        }

        if (submenu) {
            menuitemEventHandlers.onMouseOver = handleMouseOver
            menuitemEventHandlers.onMouseOut = handleMouseOut
        }

        const { registerItem, unregisterItem } = menuControl ?? {}

        React.useEffect(() => {
            if (variant !== 'divider' && variant !== 'header') {
                registerItem?.(menuitemRef.current, { disabled })
            }
            return () => {
                unregisterItem?.(menuitemId)
            }
        }, [
            registerItem,
            unregisterItem,
            menuitemRef,
            menuitemId,
            disabled,
            variant,
        ])

        if (
            variant === 'divider' ||
            variant === 'header' ||
            variant === 'custom'
        ) {
            return (
                <li
                    ref={menuitemRef}
                    id={menuitemId}
                    style={style}
                    className={clsxm(
                        classes[mode],
                        classes.variant[variant],
                        className
                    )}
                    {...(variant === 'custom' ? menuitemEventHandlers : {})}
                    {...rest}
                >
                    {(variant === 'header' || variant === 'custom') && children}
                </li>
            )
        }

        function renderChildren() {
            if (!React.isValidElement(children)) {
                return children
            }
            return React.cloneElement(children)
        }

        function renderSubmenu() {
            if (!submenu) {
                return null
            }

            return (
                <DropdownMenuContextProvider value={submenuControl}>
                    {React.cloneElement(submenu, {
                        ref: submenuRef,
                        hidden: !open,
                    })}
                </DropdownMenuContextProvider>
            )
        }

        if (submenu) {
            return (
                <li
                    {...rest}
                    style={style}
                    className='relative'
                    {...menuitemEventHandlers}
                >
                    <MenuItem
                        ref={menuitemRef}
                        id={menuitemId}
                        isActive={active}
                        eventKey={eventKey}
                        variant={mode}
                        className={clsxm(classes.submenuItem, className)}
                    >
                        <span>{children}</span>
                        <IconArrowRight />
                    </MenuItem>
                    {renderSubmenu()}
                </li>
            )
        }

        return (
            <MenuItem
                ref={menuitemRef}
                style={style}
                isActive={active}
                disabled={disabled}
                eventKey={eventKey}
                variant={mode}
                className={className}
                {...menuitemEventHandlers}
                {...rest}
            >
                {renderChildren()}
                {renderSubmenu()}
            </MenuItem>
        )
    }
)

export default DropdownItem
