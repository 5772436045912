
import { LocaleSelectOptions } from '@viterbit/web-app/dataAccess/dto'
import FormSelectCountry from '@viterbit/web-app/shared/form/FormSelectCountry'
import { UseFormReturn } from 'react-hook-form'
import { FormInput, FormPasswordInput, FormSelect } from 'ui/src/components/Form'
import FormPasswordInputValidated from 'ui/src/components/Form/FormPasswordInputValidated'

import { SignUpRedeemFields } from './SignUpRedeemShared'

export type SignUpRedeemFormProps = {
    form: UseFormReturn<SignUpRedeemFields>
    locale: string
}

const SignUpRedeemForm = (props: SignUpRedeemFormProps) => {
    const { form, locale } = props

    return (
        <>
            <FormInput
                asterisk
                name='name'
                control={form.control}
                label='Name'
                placeholder='Enter your name'
            />
            <FormInput
                asterisk
                name='organizationName'
                control={form.control}
                label='Organization name'
                placeholder='Enter your organization name'
            />
            <FormPasswordInputValidated
                className='mb-3'
                asterisk
                name='password'
                control={form.control}
                label='Password'
                placeholder='Enter your password'
            />            
            <FormPasswordInput
                asterisk
                name='confirmPassword'
                control={form.control}
                label='Repeat Password'
                placeholder='Repeat your password'
            />
            <FormSelect
                asterisk
                name='locale'
                control={form.control}
                placeholder='Select'
                label='Locale'
                options={LocaleSelectOptions}
            />

            <FormSelectCountry
                locale={locale}
                asterisk
                name='country'
                control={form.control}
                label='Country'
                placeholder='Enter your country'
            />
            <FormInput
                className='hidden'
                name='timezone'
                control={form.control}
                label='timezone'
            />
        </>
    )
}

export default SignUpRedeemForm
