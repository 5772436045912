/* eslint-disable @typescript-eslint/no-explicit-any */
import pendingFileMapper from '@viterbit/web-app/dataAccess/mapper/pendingFile.mapper'
import { pendingFileCreate } from '@viterbit/web-app/dataAccess/service/pendingFilesService'
import FormUpload from '@viterbit/web-app/shared/form/FormUpload'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import clsxm from 'ui/src/lib'

import { PendingDocumentProps } from '../BulkUploadShared'

type DocumentBulkUploadListFormProps = {
    children?: React.ReactNode
    className?: string
    onUploadSuccess?: () => void
} & PendingDocumentProps

const styles = {
    root: ''
}

const DocumentBulkUploadDetailForm = (props: DocumentBulkUploadListFormProps) => {
    const { children, className, type } = props

    const form = useForm()
    const [isPending] = React.useTransition()

    return (
        <div className={clsxm(styles.root, className)}>
            <FormUpload
                control={form.control as any}
                name='files'
                kind={type}
                multiple
                cleanSuccess
                columns={2}
                className={clsxm(isPending && 'hidden')}
                draggable
                accept='application/pdf'
                createService={pendingFileCreate}
                mapper={pendingFileMapper as any}
            >
                {children}
            </FormUpload>
        </div>

    )
}

export default DocumentBulkUploadDetailForm
