import { Employee } from '@viterbit/web-app/dataAccess/model/Employee.model'
import * as React from 'react'

import ComplaintsManagerItem from './ComplaintsManagerItem'

type ComplaintsManagerListProps = {
    className?: string
    employees: Employee[]
    onDeleteItem: (employee: Employee) => void
}

const ComplaintsManagerList = ({
    employees,
    onDeleteItem
}: ComplaintsManagerListProps) => (
    <div className='grid grid-cols-2 gap-4'>
        {
            employees.map(employee => (
                <ComplaintsManagerItem
                    key={employee.id}
                    item={employee}
                    onDeleteItem={() => onDeleteItem(employee)}
                />
            ))
        }
    </div>
)

export default ComplaintsManagerList
