import { Month } from '@mantine/dates'
import { IWeekDays } from '@viterbit/web-app/dataAccess/model/Week.model'
import i18n from '@viterbit/web-app/i18n'
import dayjs from 'dayjs'

import RelevantDay from './RelevantDays/RelevantDay'
import { RelevantDate } from '../types'

type CalendarLeaveProps = {
    year: number
    relevantDates: RelevantDate[]
    workableDays: IWeekDays[]
    clickable?: boolean
    employeeId: string
}

const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
]

const CalendarLeave = ({
    year,
    relevantDates,
    workableDays,
    clickable,
    employeeId,
}: CalendarLeaveProps) => (
    <div className='flex flex-wrap justify-around w-full h-full px-6 py-4 bg-white rounded-md gap-x-4 gap-y-4'>
        {months.map((month, index) => (
            <div className='' key={month}>
                <p className='py-2 pl-1 mb-1 font-semibold text-black'>
                    {i18n.t(`leaves:calendar.months.${month}`)}
                </p>

                <Month
                    static={!clickable}
                    withCellSpacing={false}
                    month={new Date(year, index)}
                    getDayProps={(date) => ({
                        ...(dayjs(date).isToday() && {
                            sx: {
                                border: '1px solid #3796F7'
                            }
                        })
                    })}
                    renderDay={(date) => {
                        if (dayjs(date).get('month') !== index) {
                            return null
                        }

                        return (
                            <RelevantDay
                                date={date.toISOString()}
                                relevantDates={relevantDates}
                                year={year}
                                month={index}
                                workableDays={workableDays}
                                clickable={clickable}
                                employeeId={employeeId}
                            />
                        )
                    }}

                    size='sm'
                />

                {/* <Month
                    className='min-w-[250px]'
                    month={new Date(year, index)}
                    locale={i18n.resolvedLanguage}
                    firstDayOfWeek='monday'
                    monthCellClassName='w-8 h-8'
                    hideOutOfMonthDates
                /> */}
            </div>
        ))}
    </div>
)

export default CalendarLeave
