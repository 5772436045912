/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UnassignEmployeeCommand
 */
export interface UnassignEmployeeCommand {
    /**
     * 
     * @type {Array<string>}
     * @memberof UnassignEmployeeCommand
     */
    employeeIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UnassignEmployeeCommand
     */
    unassignAs: UnassignEmployeeCommandUnassignAsEnum;
}


/**
 * @export
 */
export const UnassignEmployeeCommandUnassignAsEnum = {
    Member: 'MEMBER',
    Leader: 'LEADER'
} as const;
export type UnassignEmployeeCommandUnassignAsEnum = typeof UnassignEmployeeCommandUnassignAsEnum[keyof typeof UnassignEmployeeCommandUnassignAsEnum];


/**
 * Check if a given object implements the UnassignEmployeeCommand interface.
 */
export function instanceOfUnassignEmployeeCommand(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "employeeIds" in value;
    isInstance = isInstance && "unassignAs" in value;

    return isInstance;
}

export function UnassignEmployeeCommandFromJSON(json: any): UnassignEmployeeCommand {
    return UnassignEmployeeCommandFromJSONTyped(json, false);
}

export function UnassignEmployeeCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): UnassignEmployeeCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'employeeIds': json['employeeIds'],
        'unassignAs': json['unassignAs'],
    };
}

export function UnassignEmployeeCommandToJSON(value?: UnassignEmployeeCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'employeeIds': value.employeeIds,
        'unassignAs': value.unassignAs,
    };
}

