import fileMapper from './file.mapper'
import { PublicEmployeeResponse } from '../dto'
import { PublicEmployee } from '../model/PublicEmployee.model'

const publicEmployeeMapper = {
    toModel: (dto: PublicEmployeeResponse): PublicEmployee => ({
        id: dto.id,
        email: dto.email,
        name: dto.name,
        surname: dto.surname ?? undefined,
        avatar: dto.avatar && fileMapper.toModel(dto.avatar),
    }),
}

export default publicEmployeeMapper
