
import { IconCalendarOff } from '@tabler/icons-react'
import { Location } from '@viterbit/web-app/dataAccess/model/Location.model'
import CalendarItem from '@viterbit/web-app/feature/settings/locations/calendar/list/CalendarItem'
import i18n from '@viterbit/web-app/i18n'
import EmptyState from '@viterbit/web-app/shared/display/EmptyState/EmptyState'
import SimpleCard from '@viterbit/web-app/shared/display/SimpleCard'
import SubmitButton from '@viterbit/web-app/shared/form/SubmitButton'
import BodyContent from '@viterbit/web-app/shared/layout/BodyContent'
import HeaderActions from '@viterbit/web-app/shared/layout/HeaderActions'
import MultiColumnLayout from '@viterbit/web-app/shared/layout/MultiColumnLayout'
import { sortBy } from 'lodash'
import { useFieldArray, UseFormReturn } from 'react-hook-form'

import { FormFields } from './ActionSpan'
import { HolidayItemFormFields } from './LocationHolidayDetailShared'
import LocationHolidayDetailView from './LocationHolidayDetailView'

export type CalendarHolidaysFormProps = {
    form: UseFormReturn<HolidayItemFormFields>
    location: Location,
    year: number
}

const CalendarHolidaysForm = ({
    location,
    year,
    form,
}: CalendarHolidaysFormProps) => {

    const calendarIndex = location.calendar.findIndex((calendar) => calendar.year === Number(year))

    const { fields, append, remove } = useFieldArray({
        control: form.control,
        name: `calendar.${calendarIndex}.publicHolidays`,
    })

    const onSubmitCalendarHolidayValue = (data: FormFields) => {
        append({
            day: data.date,
            name: data.name
        })
    }

    const onDeleteHolidayItem = (index: number) => {
        remove(index)
    }

    const sortedFields = sortBy(fields, ['day'])

    return (
        <>
            <BodyContent.Header>
                <HeaderActions  className='mb-4 bg-white' divider>
                    <HeaderActions.Header className='pt-2'>

                        <div className='text-2xl text-black'>
                            <span className='font-bold'>{location.name}</span>
                            {location.state && <span>{`, ${location.state}`}</span>}
                        </div>
                        <div className='text-sm'>
                            {sortedFields.length} holidays
                        </div>

                    </HeaderActions.Header>
                    <HeaderActions.Actions>
                        <SubmitButton>Save</SubmitButton>
                    </HeaderActions.Actions>
                </HeaderActions>

            </BodyContent.Header>
            <MultiColumnLayout
                left={
                    <SimpleCard cardBodyClassName='overflow-visible'>
                        {!sortedFields.length && (
                            <EmptyState 
                                className='py-36'
                                image={<IconCalendarOff size={64} className='text-gray-400' stroke={1} />}
                                title={i18n.t('settingLocations:calendarHolidays.emptyState.title')}
                                description={i18n.t('settingLocations:calendarHolidays.emptyState.description')}
                            />
                        )}

                        {!!sortedFields.length && (
                            sortedFields?.map((holiday, index) => (
                                <CalendarItem
                                    key={holiday.day}
                                    holiday={holiday}
                                    location={location}
                                    onDelete={() => onDeleteHolidayItem(index)}
                                    className='border-b hover:bg-gray-50'
                                />
                            ))
                        )}

                    </SimpleCard>
                }
            >

                <LocationHolidayDetailView
                    header={<div className='flex items-center justify-between flex-cols'></div>}
                    year={year}
                    onSubmit={(v) => onSubmitCalendarHolidayValue(v)}
                    holidays={sortedFields?.map((holiday) => ({
                        date: holiday.day,
                        color: '#d1d5db',
                        label: holiday.name,
                        isHoliday: true,
                        halfDay: false,
                    })) || []}
                />

            </MultiColumnLayout>
        </>
    )
}

export default CalendarHolidaysForm