import { useQuery } from '@tanstack/react-query'

import { PENDING_FILES_QUERY_KEY } from './pendingFilesQueryKeys'
import { pendingFileListPaginated } from '../../service/pendingFilesService'


type FileListConfig = {
    query: Parameters<typeof pendingFileListPaginated>[0]
    enabled?: boolean
    keepPreviousData?: boolean
}

export const usePendingFileList = ({
    query,
    enabled,
    keepPreviousData
}: FileListConfig) => useQuery({
    queryKey: [PENDING_FILES_QUERY_KEY, 'list-paginated', query],
    queryFn: () => pendingFileListPaginated(query),
    enabled,
    keepPreviousData,
})
