/* eslint-disable @typescript-eslint/no-explicit-any */
import { NavigationItem, NavigationItemMeta } from '@viterbit/web-app/routes'
import * as React from 'react'
import { useMatches } from 'react-router-dom'
import clsxm from 'ui/src/lib'

import Breadcrumbs, { BreadcrumbsProps } from './Breadcrumbs/Breadcrumbs'
import BreadcrumbsItem from './Breadcrumbs/BreadcrumbsItem'

type RouterBreadcrumbsProps = Omit<BreadcrumbsProps, 'children'>

const styles = {
    root: ''
}

const RouterBreadcrumbs = (props: RouterBreadcrumbsProps) => {
    const { className, title, icon } = props

    const matches = useMatches()
    const breadcrumbItems = React.useMemo(() => matches.filter((match) => (match.handle as NavigationItemMeta).showBreadcrumb), [matches])

    if (!matches) {
        return null
    }

    const current = breadcrumbItems[breadcrumbItems.length - 1] as NavigationItem

    return (
        <Breadcrumbs
            className={clsxm(styles.root, className)} title={title || current?.handle.title}
            icon={icon || current?.handle.icon}
        >
            {breadcrumbItems.map((match, index) => {
                const { title } = match.handle as NavigationItemMeta
                
                return (
                    <BreadcrumbsItem href={match.pathname} key={match.id}>{title}</BreadcrumbsItem>
                )
            })}
        </Breadcrumbs>
    )
}

export default RouterBreadcrumbs
