import { Button } from '@mantine/core'
import HeaderActions from '@viterbit/web-app/shared/layout/HeaderActions'
import SegmentNavLink from '@viterbit/web-app/shared/navigation/SegmentNavLink'
import Subtitle from '@viterbit/web-app/shared/typography/Subtitle'
import * as React from 'react'

import SimpleCardBody from './SimpleCardBody'
import { clsxm } from '../../utils/clsxm'

type SimpleCardProps = {
    title?: React.ReactNode
    children: React.ReactNode
    href?: string
    actionLabel?: string
    className?: string
    titleClassName?: string
    cardBodyClassName?: string
    padding?: boolean
    border?: boolean
    actions?: React.ReactNode
}

const SimpleCard = ({
    padding,
    title,
    children,
    href,
    actionLabel,
    className = '',
    titleClassName = '',
    cardBodyClassName = '',
    border,
    actions
}: SimpleCardProps) => (
    <div className={clsxm(
        'flex flex-col bg-white rounded overflow-hidden',
        border ? 'border border-grey-200': 'shadow-sm',
        padding ? 'px-6 py-5 pt-0': '',
        className,
    )}>
        {(title || actionLabel) && (
            <>

                <HeaderActions className={`h-[52px] ${!padding ? 'px-6': ''} ${titleClassName}`}>
                    <HeaderActions.Header>
                        <Subtitle bold truncate>{title}</Subtitle>
                    </HeaderActions.Header>
                    <HeaderActions.Actions>
                        {actionLabel && href && (
                            <SegmentNavLink path={href} className='text-sm'>
                                <Button variant='light' size='xs'>
                                    {actionLabel}
                                </Button>
                            </SegmentNavLink>
                        )}
                        {actions}
                    </HeaderActions.Actions>
                </HeaderActions>
                
                <hr className={`m-0 border-0 border-b border-grey-200 ${padding && '-mx-6'}`} />
            </>
        )}
        <SimpleCardBody className={`${padding ? 'pt-5': ''} ${cardBodyClassName}`}>
            {children}
        </SimpleCardBody>
    </div>

)

// 0 0 13px 0 rgba(82,63,105,.05)

export default SimpleCard
