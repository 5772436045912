import { Badge } from '@mantine/core'
import i18n from '@viterbit/web-app/i18n'

type Props= {
    className?: string
    children?: React.ReactNode
}

const BulkUploadCoincidenceTag = ({
    className,
    children
}: Props) => (
    <Badge className={className}>
        {children || i18n.t('employees:bulkUpload.preview.coincidence')}
    </Badge>
)

export default BulkUploadCoincidenceTag
