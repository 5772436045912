export const PAYMENT_QUERY_KEY = 'payment'
export const SUBSCRIPTION_QUERY_KEY = 'subscription'
export const PLAN_QUERY_KEY = 'plan'
export const JOB_POSITION_QUERY_KEY = 'job-position'
export const JOB_POSITION_LEVEL_QUERY_KEY = 'job-position-level'
export const TEAM_QUERY_KEY = 'team'
export const PERMISSION_QUERY_KEY = 'permission'
export const LEAVE_POLICY_QUERY_KEY = 'leave-policy'
export const LEAVE_REASON_QUERY_KEY = 'leave-reason'
export const ACCOUNT_QUERY_KEY = 'account'
export const EMPLOYEE_QUERY_KEY = 'employee'
export const TIME_TRACKING_QUERY_KEY = 'time-tracking'
export const LEAVE_QUERY_KEY = 'leave'
export const GEO_DATA_QUERY_KEY = 'geo-data'
export const FILES_QUERY_KEY = 'files'
export const COMPANY_QUERY_KEY = 'company'
export const LOCATION_QUERY_KEY = 'location'
export const ORGANIZATION_QUERY_KEY = 'organization'
export const ORGANIZATION_PUBLICQUERY_KEY = 'organization-public'
export const COMPLAINT_KEY = 'complaint-key'
export const WORK_SCHEDULE_KEY = 'work-schedule'
export const CONTENT_CHANGED_QUERY_KEY = 'content-changed'
export const WORKFLOW_QUERY_KEY = 'workflow'
export const INTEGRATION_QUERY_KEY = 'integration'

