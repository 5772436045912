import { useMutation } from '@tanstack/react-query'
import { DeleteFileRequest } from 'viterbit-api-sdk'

import { fileDelete } from '../../service/filesService'

type FileDeleteConfig = {
    onSuccess?: () => void
    onError?: (error: any, variables: DeleteFileRequest, context: unknown) => void
}

export const useFileDelete = (config: FileDeleteConfig) =>
    useMutation({
        ...config,
        mutationFn: fileDelete,
        onSuccess: config.onSuccess,
        onError: config.onError,
    })
