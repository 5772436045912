/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IntegrationsDataQueryResponse } from './IntegrationsDataQueryResponse';
import {
    IntegrationsDataQueryResponseFromJSON,
    IntegrationsDataQueryResponseFromJSONTyped,
    IntegrationsDataQueryResponseToJSON,
} from './IntegrationsDataQueryResponse';

/**
 * 
 * @export
 * @interface IntegrationOptionQueryResponse
 */
export interface IntegrationOptionQueryResponse {
    /**
     * 
     * @type {string}
     * @memberof IntegrationOptionQueryResponse
     */
    type: IntegrationOptionQueryResponseTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof IntegrationOptionQueryResponse
     */
    authType: IntegrationOptionQueryResponseAuthTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof IntegrationOptionQueryResponse
     */
    integrated: boolean;
    /**
     * 
     * @type {object}
     * @memberof IntegrationOptionQueryResponse
     */
    data: object;
    /**
     * 
     * @type {boolean}
     * @memberof IntegrationOptionQueryResponse
     */
    available?: boolean | null;
    /**
     * 
     * @type {IntegrationsDataQueryResponse}
     * @memberof IntegrationOptionQueryResponse
     */
    integrations: IntegrationsDataQueryResponse;
}


/**
 * @export
 */
export const IntegrationOptionQueryResponseTypeEnum = {
    Google: 'google',
    Microsoft: 'microsoft',
    Slack: 'slack',
    Zapier: 'zapier',
    Viterbit: 'viterbit'
} as const;
export type IntegrationOptionQueryResponseTypeEnum = typeof IntegrationOptionQueryResponseTypeEnum[keyof typeof IntegrationOptionQueryResponseTypeEnum];

/**
 * @export
 */
export const IntegrationOptionQueryResponseAuthTypeEnum = {
    Oauth: 'oauth',
    ApiKey: 'api-key',
    Basic: 'basic'
} as const;
export type IntegrationOptionQueryResponseAuthTypeEnum = typeof IntegrationOptionQueryResponseAuthTypeEnum[keyof typeof IntegrationOptionQueryResponseAuthTypeEnum];


/**
 * Check if a given object implements the IntegrationOptionQueryResponse interface.
 */
export function instanceOfIntegrationOptionQueryResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "authType" in value;
    isInstance = isInstance && "integrated" in value;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "integrations" in value;

    return isInstance;
}

export function IntegrationOptionQueryResponseFromJSON(json: any): IntegrationOptionQueryResponse {
    return IntegrationOptionQueryResponseFromJSONTyped(json, false);
}

export function IntegrationOptionQueryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntegrationOptionQueryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'authType': json['authType'],
        'integrated': json['integrated'],
        'data': json['data'],
        'available': !exists(json, 'available') ? undefined : json['available'],
        'integrations': IntegrationsDataQueryResponseFromJSON(json['integrations']),
    };
}

export function IntegrationOptionQueryResponseToJSON(value?: IntegrationOptionQueryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'authType': value.authType,
        'integrated': value.integrated,
        'data': value.data,
        'available': value.available,
        'integrations': IntegrationsDataQueryResponseToJSON(value.integrations),
    };
}

