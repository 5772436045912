import { IsEmail, IsNotEmpty } from '@viterbit/web-app/shared/utils/ClassValidators'

class LoginValidator {
    @IsNotEmpty()
    @IsEmail()
    email: string
    @IsNotEmpty()
    password: string
}

export default LoginValidator