/* eslint-disable @typescript-eslint/no-explicit-any */
import { IconCheck, IconChevronDown, IconX } from '@tabler/icons-react'
import * as React from 'react'

import Spinner from '../Spinner'
import { SIZES } from '../../constants/constant'
import { clsxm } from '../../lib/clsxm'

export const SelectClasses = {
    select: '',
    size: {
        [SIZES.XS]: '',
        [SIZES.SM]: '',
        [SIZES.MD]: '',
        [SIZES.LG]: '',
    },
    loadingIndicator: '',
    option: {
        default:
            'flex items-center justify-between px-2 py-1.5 hover:bg-gray-50 dark:hover:bg-gray-600 cursor-default',
        selected: 'bg-gray-100 dark:bg-gray-500',
        disabled:
            'opacity-40 dark:opacity-30 hover:bg-transparent cursor-not-allowed',
    },
}

export const twColorMapped = {
    red: {
        500: '#ef4444',
    },
    primary: {
        600: '#2563eb',
    },
}

export const SelectDefaultOption = ({
    innerProps,
    label,
    selectProps,
    isSelected,
    isDisabled,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
any) => {
    const { themeColor } = selectProps
    return (
        <div
            className={clsxm(
                SelectClasses.option.default,
                isSelected && SelectClasses.option.selected,
                isDisabled && SelectClasses.option.disabled
            )}
            {...innerProps}
        >
            <span className='ml-2'>{label}</span>
            {isSelected && (
                <IconCheck
                    className={`text-${themeColor} text-xl dark:text-white`}
                />
            )}
        </div>
    )
}

export const SelectDefaultDropdownIndicator = () => (
    <div className='select-dropdown-indicator'>
        <IconChevronDown />
    </div>
)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SelectDefaultClearIndicator = (props: any) => {
    const {
        innerProps: { ref, ...restInnerProps },
    } = props
    return (
        <div {...restInnerProps} ref={ref}>
            <div className='select-clear-indicator'>
                <IconX />
            </div>
        </div>
    )
}

export const SelectDefaultLoadingIndicator = () => (
    <Spinner className='text-primary-600 mr-1' isSpinning />
)
