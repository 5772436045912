/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */

import complaintMapper from '@viterbit/web-app/dataAccess/mapper/complaint.mapper'
import paginationMetaMapper from '@viterbit/web-app/dataAccess/mapper/paginationMeta.mapper'
import { queryClient } from '@viterbit/web-app/shared/lib/ReactQueryProvider'
import { getJWT, setJWT } from '@viterbit/web-app/shared/state/complaintState'
import {ComplaintApi,Configuration, FileApi } from 'viterbit-api-sdk'

import { commonConfig } from './config'
import { employeeInvalidate } from './employeeService'
import { COMPLAINT_KEY } from '../cacheKeys'

const CONFIG = {
    basePath: import.meta.env.VITE_PUBLIC_API_URL,
    fetchApi: (url: string, init: any) => fetch(url, {
        ...init,
        credentials: 'include'
    }),
    middleware: [
        {
            pre: async (context) => {
                const jwtResponse = await getJWT()
    
                if (!context.init.headers) {
                    context.init.headers = {}
                }
    
                if (jwtResponse) {
                    context.init.headers['x-jwt'] = jwtResponse.jwt
                }
    
                return context
            }
        }
    ]
}

const complaintApi = new ComplaintApi(new Configuration(commonConfig))
const complaintPublicApi = new ComplaintApi(new Configuration(CONFIG))
const fileApi = new FileApi(new Configuration(CONFIG))

export const complaintInvalidate = () =>
    queryClient.invalidateQueries([COMPLAINT_KEY])

export const complaintPublicCreate = (body: Parameters<typeof complaintApi.createComplaint>[0]) =>
    complaintPublicApi.createComplaint(body).then(res => {
        complaintInvalidate()
        setJWT(res)
        return res
    })

export const complaintPublicDetail = () =>
    complaintPublicApi.findComplaint().then(complaintMapper.toModel)

export const complaintPrivateDetail = (body: Parameters<typeof complaintApi.employeeFindComplaint>[0]) =>
    complaintApi.employeeFindComplaint(body).then(complaintMapper.toModel)

export const complaintPublicRecordCreate = (body: Parameters<typeof complaintApi.createComplaintRecords>[0]) =>
    complaintPublicApi.createComplaintRecords(body).then(res => {
        complaintInvalidate()
        return res
    })

export const complaintPrivateRecordCreate = (body: Parameters<typeof complaintApi.createEmployeeComplaintRecords>[0]) =>
    complaintApi.createEmployeeComplaintRecords(body).then(res => {
        complaintInvalidate()
        return res
    })

export const complaintPublicLogin = (body: Parameters<typeof complaintApi.loginComplaint>[0]) =>
    complaintPublicApi.loginComplaint(body).then(res => {
        setJWT({
            id: body!.loginComplaintQuery!.complaintId!,
            password: body!.loginComplaintQuery!.password!,
            jwt: res.jwt,
        })

        return res
    })

export const complaintPrivateListPaginated = (body: Parameters<typeof complaintApi.listComplaint>[0]) =>
    complaintApi.listComplaint(body).then(({ data, meta }) => ({
        meta: paginationMetaMapper(meta),
        data: data.map(complaintMapper.toModel)
    }))

export const complaintPrivateUpdate = (body: Parameters<typeof complaintApi.updateEmployeeComplaints>[0]) =>
    complaintApi.updateEmployeeComplaints(body).then(res => {
        complaintInvalidate()
        return res
    })

export const complaintAssignEmployee = (body: Parameters<typeof complaintApi.assignComplaintManager>[0]) =>
    complaintApi.assignComplaintManager(body).then(res => {
        complaintInvalidate()
        employeeInvalidate()
        return res
    })

export const complaintUnassignEmployee = (body: Parameters<typeof complaintApi.unassignComplaintManager>[0]) =>
    complaintApi.unassignComplaintManager(body).then(res => {
        complaintInvalidate()
        employeeInvalidate()
        return res
    })

export const complaintPublicFileUpload = fileApi.uploadComplaintFile.bind(fileApi)