import { IsNotEmpty, IsString } from '@viterbit/web-app/shared/utils/ClassValidators'
import { IsSlug } from '@viterbit/web-app/shared/utils/slug.decorator'

import { OrganizationUpdateFields } from './OrganizationUpdateFields'

export class OrganizationUpdateValidator implements OrganizationUpdateFields {
    @IsNotEmpty()
    @IsString()
    name: string
    
    @IsNotEmpty()
    @IsString()
    terms: string

    @IsSlug()
    domain: string
}