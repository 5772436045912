import React from 'react'
import { useBoolean } from 'react-use'
import LayoutContext from 'ui/src/components/LayoutContext'

const LayoutProvider = ({ children }: {children: React.ReactNode}) => {
    const [isCollapsed, setIsCollapsed] = useBoolean(false)

    const toggleCollapsed = () => {
        setIsCollapsed(!isCollapsed)
    }

    return (
        <LayoutContext.Provider value={{ isCollapsed, toggleCollapsed }}>
            {children}
        </LayoutContext.Provider>
    )
}

export default LayoutProvider
