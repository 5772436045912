/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LoginComplaintQuery
 */
export interface LoginComplaintQuery {
    /**
     * 
     * @type {string}
     * @memberof LoginComplaintQuery
     */
    complaintId?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginComplaintQuery
     */
    password?: string;
}

/**
 * Check if a given object implements the LoginComplaintQuery interface.
 */
export function instanceOfLoginComplaintQuery(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LoginComplaintQueryFromJSON(json: any): LoginComplaintQuery {
    return LoginComplaintQueryFromJSONTyped(json, false);
}

export function LoginComplaintQueryFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginComplaintQuery {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'complaintId': !exists(json, 'complaintId') ? undefined : json['complaintId'],
        'password': !exists(json, 'password') ? undefined : json['password'],
    };
}

export function LoginComplaintQueryToJSON(value?: LoginComplaintQuery | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'complaintId': value.complaintId,
        'password': value.password,
    };
}

