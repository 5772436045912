import { useMutation } from '@tanstack/react-query'
import { leavePolicyDelete } from '@viterbit/web-app/dataAccess/service/leavePolicyService'
import i18n from '@viterbit/web-app/i18n'
import ApiErrorMessage from '@viterbit/web-app/shared/feedback/ApiErrorMessage'
import DeleteAlert from '@viterbit/web-app/shared/feedback/DeleteAlert'
import { useNotification } from '@viterbit/web-app/shared/feedback/useNotification'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import { LeavePolicyFormFields } from './DetailsLeavePolicies'
import FormDeleteDialog from '../../../../shared/form/FormDeleteDialog'

type DeleteLeavePolicyProps = {
    leavePolicy: LeavePolicyFormFields
    id: string
}

const DeleteLeavePolicy = (props: DeleteLeavePolicyProps) => {
    const navigate = useNavigate()
    const { leavePolicy, id } = props
    const notification = useNotification()
    const [isDeleting, setIsDeleting] = React.useState(false)
    const [isPending, startTransition] = React.useTransition()

    const onSuccess = async () => {
        startTransition(() => {
            setIsDeleting(false)
            navigate('/settings/policies')
            notification.push('success', 'Delete', 'Deleted successfully')
        })
    }

    const { mutate, isLoading, error } = useMutation({
        mutationKey: ['DeleteLeavePolicies'],
        onSuccess,
        mutationFn: leavePolicyDelete,
    })

    return (
        <>
            <DeleteAlert
                buttonText={i18n.t('settingLeaves:delete')}
                onConfirm={() => setIsDeleting(true)}
            >
                {i18n.t('settingLeaves:deleteLeavePolicyConfirmation')}
         
            </DeleteAlert>

            <FormDeleteDialog
                isOpen={isDeleting}
                onClose={() => setIsDeleting(false)}
                cancelButtonText='Cancel'
                isLoading={isLoading || isPending}
                onSubmit={() => {
                    mutate({ id })
                }}
                submitButtonText='Confirm'
                title='Delete Leave Policy'
            >
                <ApiErrorMessage error={error} />
            </FormDeleteDialog>
        </>
    )
}

export default DeleteLeavePolicy
