import Text from '@viterbit/web-app/shared/typography/Text'
import Title from '@viterbit/web-app/shared/typography/Title'
import * as React from 'react'
import clsxm from 'ui/src/lib'

import MultiColumnLayout from '../../../shared/layout/MultiColumnLayout'

type Image ={
  src: string
  alt: string
  position ?: 'left' | 'right'
}

type MessageViewProps = {
  title ?: string
  subtitle ?: string
  image : Image
  children: React.ReactNode
  className ?: string
} & React.HTMLAttributes<HTMLDivElement>

const BillingPaymentMessageView = ({ title, subtitle, image:imageProp, children, className, ...rest }: MessageViewProps) => (
    <MultiColumnLayout
        left={(
            <div className='flex items-center justify-center w-full h-full p-8'>
                <img src={imageProp.src} alt={imageProp.alt} width={200} height={200} />
            </div>
        )}
        {...rest}
    >
        <div className={clsxm('flex px-12 pb-6 items-left justify-center flex-col gap-y-2 w-full h-full',className)}>
            <Title>{title}</Title>
            <Text className='mb-4'>{subtitle}</Text>
            {children}
        </div>
    </MultiColumnLayout>
)

export default BillingPaymentMessageView

