import * as React from 'react'

import useMergedRef from '../../hooks/useMergedRef'
import { clsxm } from '../../lib/clsxm'

const classes = {
    avatar: 'relative text-gray-100 bg-gray-400 dark:bg-gray-600 flex items-center justify-center',
    'avatar-sm': 'w-8 h-8 leading-8',
    'avatar-md': 'w-10 h-10 leading-10',
    'avatar-lg': 'w-12 h-12 leading-10',
    'avatar-xl': 'w-16 h-16 leading-10',
    'avatar-2xl': 'w-24 h-24 leading-10',
    'avatar-rounded': 'rounded-md ',
    'avatar-circle': 'rounded-full',
    'avatar-square': 'rounded-none',
    'avatar-img': 'block object-cover w-full h-full',
    'avatar-string': 'absolute flex items-center origin-center left-2/4',
    'avatar-inner-sm': 'h-8',
    'avatar-inner-md': 'h-10',
    'avatar-inner-lg': 'h-12',
    'avatar-inner-xl': 'h-12',
    'avatar-inner-2xl': 'h-12',
    'avatar-icon': 'flex items-center justify-center h-full',
    'avatar-icon-sm': 'text-lg',
    'avatar-icon-md': 'text-xl',
    'avatar-icon-lg': 'text-2xl',
    'avatar-icon-xl': 'text-2xl',
    'avatar-icon-2xl': 'text-2xl',
}

export const measures = {
    sm: 32,
    md: 40,
    lg: 48,
    xl: 64,
    '2xl': 96,
}

export type AvatarProps = {
    className?: string
    style?: React.CSSProperties
    shape?: 'rounded' | 'square' | 'circle'
    size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl' | number
    icon?: React.ReactNode
    src?: string
    srcSet?: string
    alt?: string
    children?: React.ReactNode
    onClick?: (even?: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void
    ImageComponent?: React.ElementType
    'data-testid'?: string
}

const Avatar = React.forwardRef<HTMLDivElement, AvatarProps>((props, ref) => {
    const {
        className,
        size = 'md',
        shape = 'rounded',
        icon,
        style,
        src,
        alt,
        srcSet,
        onClick,
        ImageComponent = 'img'
    } = props
    let { children } = props
    const [scale, setScale] = React.useState(1)

    const avatarChildren = React.useRef<HTMLSpanElement | null>()
    const avatarNode = React.useRef<HTMLDivElement>()

    const avatarMergeRef = useMergedRef(ref, avatarNode)

    React.useEffect(() => {
        const innerScale = () => {
            if (!avatarChildren.current || !avatarNode.current) {
                return
            }
            const avatarChildrenWidth = avatarChildren.current.offsetWidth
            const avatarNodeWidth = avatarNode.current.offsetWidth
            if (avatarChildrenWidth === 0 || avatarNodeWidth === 0) {
                return
            }
            setScale(
                avatarNodeWidth - 8 < avatarChildrenWidth
                    ? (avatarNodeWidth - 8) / avatarChildrenWidth
                    : 1
            )
        }
        innerScale()
    }, [scale, children])

    const measure = typeof size === 'number' ? size : measures[size]

    const sizeStyle =
        typeof size === 'number'
            ? {
                width: size,
                height: size,
                minWidth: size,
                lineHeight: `${size}px`,
                fontSize: icon ? size / 2 : 12,
            }
            : {}

    if (src) {
        children = (
            <ImageComponent
                className={clsxm(
                    classes['avatar-img'],
                    classes[`avatar-${shape}`]
                )}
                src={src}
                srcSet={srcSet}
                width={measure}
                height={measure}
                alt={alt}
                loading='lazy'
            />
        )
    } else if (icon) {
        children = (
            <span
                className={clsxm(
                    classes['avatar-icon'],
                    typeof size !== 'number' && classes[`avatar-icon-${size}`]
                )}
            >
                {icon}
            </span>
        )
    } else {
        const childrenSizeStyle =
            typeof size === 'number' ? { lineHeight: `${size}px` } : {}
        const stringCentralized = {
            transform: `translateX(-50%) scale(${scale})`,
        }
        children = (
            <span
                className={clsxm(
                    classes['avatar-string'],
                    typeof size === 'number'
                        ? ''
                        : classes[`avatar-inner-${size}`]
                )}
                style={{
                    ...childrenSizeStyle,
                    ...stringCentralized,
                    ...(typeof size === 'number' ? { height: size } : {}),
                }}
                ref={(node) => {
                    avatarChildren.current = node
                }}
            >
                {children}
            </span>
        )
    }

    const spanClasses = clsxm(
        classes.avatar,
        classes[`avatar-${shape}`],
        typeof size === 'string' ? classes[`avatar-${size}`] : '',
        className
    )

    return (
        <span
            data-testid='avatar'
            className={spanClasses}
            style={{ ...sizeStyle, ...style }}
            ref={avatarMergeRef as unknown as React.LegacyRef<HTMLSpanElement>}
            onClick={onClick}
        >
            {children}
        </span>
    )
})
export default Avatar
