/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { IconClockPlus } from '@tabler/icons-react'
import dateMapper from '@viterbit/web-app/dataAccess/mapper/date.mapper'
import { PermissionAction } from '@viterbit/web-app/dataAccess/model/PermissionAction.model'
import { timeTrackingCreate } from '@viterbit/web-app/dataAccess/service/timeTrackingService'
import i18n from '@viterbit/web-app/i18n'
import ButtonCreate from '@viterbit/web-app/shared/form/ButtonCreate'
import IsGranted from '@viterbit/web-app/shared/permission/IsGranted'
import { TIME_ZONE } from '@viterbit/web-app/shared/utils/timeZone'
import * as React from 'react'
import { Api } from 'types/Api'
import ButtonSkeleton from 'ui/src/components/Button/ButtonSkeleton'

import TimeTrackingDialog from '../list/TimeTrackingDialog'
import TimeTrackingForm from '../list/TimeTrackingForm'
import { TimeTrackingFields } from '../shared'

type CreateTimeTrackingProps = {
    children?: React.ReactNode
    className?: string
    employeeId: string
    onSuccess: (data: Api.ResponseSuccess, variables: TimeTrackingFields, context: unknown) => void
}

type DialogState = {
    defaultValues: TimeTrackingFields
}

const CreateTimeTracking = (props: CreateTimeTrackingProps) => {
    const { employeeId, children, className, onSuccess } = props

    const [createDetail, setCreateDetail] = React.useState<DialogState | null>(null)

    const onClose = () => {
        setCreateDetail(null)
    }

    return (
        <>
            <IsGranted
                action={PermissionAction.CORE_HR_TIME_TRACKING_CREATE}
                id={employeeId}
                loader={
                    <ButtonSkeleton />
                }
            >
                <ButtonCreate
                    data-testid="create-time-tracking"
                    IconComponent={IconClockPlus}
                    title={i18n.t('personalArea:timeTracking.createDialog.buttonText')}
                    onClick={() =>
                        setCreateDetail({
                            defaultValues: {
                                startDate: new Date(),
                                endDate: new Date(),
                            }
                        })}
                >
                </ButtonCreate>
            </IsGranted>

            <TimeTrackingDialog
                open={!!createDetail}
                onClose={onClose}
                title={i18n.t('personalArea:timeTracking.createDialog.title')}
            >
                <TimeTrackingForm
                    mutationConfig={{
                        mutationFn: ({
                            endDate,
                            startDate,
                        }: TimeTrackingFields) => timeTrackingCreate({
                            createTimeTrackingCommand: {
                                employeeId,
                                endDate: dateMapper.toDateTimeUTC(endDate)!,
                                startDate: dateMapper.toDateTimeUTC(startDate)!,
                                timezone: TIME_ZONE
                            }
                        }),
                        mutationKey: ['create-tracking', employeeId],
                        onSuccess: (...args) => {
                            onClose()
                            onSuccess(...args)
                        },
                    }}
                    onCancel={onClose}
                    cancelText={i18n.t('personalArea:timeTracking.createDialog.buttons.cancel')}
                    confirmText={i18n.t('personalArea:timeTracking.createDialog.buttons.confirm')}
                />
            </TimeTrackingDialog>
        </>
    )
}

export default CreateTimeTracking
