import Small from '@viterbit/web-app/shared/typography/Small'
import * as React from 'react'
import clsxm from 'ui/src/lib'

type DaysCountersProps = {
    className?: string
    count: number
    label: string
    placeholder: string
    onClick?: () => void
    disabled?: boolean
}

const DaysCounters = ({
    count,
    label,
    placeholder,
    className,
    onClick,
    disabled,
}: DaysCountersProps) => (
    <>
        <article className={clsxm('flex items-center gap-x-2 group', className, onClick && 'cursor-pointer', disabled && 'grayscale opacity-50 pointer-events-none' )} onClick={onClick}>
            <h1 className='text-3xl font-bold'>{count}</h1>
            <div>
                <Small className='w-[max-content]'>{label}</Small>
                <Small className={`text-primary-600 w-[max-content] ${onClick && 'group-hover:underline'} `}>
                    {placeholder}
                </Small>
            </div>
        </article>
    </>
)

export default DaysCounters
