import * as React from 'react'
import { clsxm } from 'ui/src/lib/clsxm'

type SummaryTotalsBulletProps = {
    color?: string
    className?: string
}

const SummaryTotalsBullet = ({ color }: SummaryTotalsBulletProps) => (
    <span className={clsxm('flex-shrink-0 inline-block w-2 h-2 bg-gray-200 rounded-full mt-2 mr-2')} aria-hidden="true" style={{backgroundColor:color }}></span>
)

export default SummaryTotalsBullet
