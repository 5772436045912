

import { Company } from '@viterbit/web-app/dataAccess/model/Company.model'
import SegmentNavLink from '@viterbit/web-app/shared/navigation/SegmentNavLink'
import List from 'ui/src/components/List'

export type CompaniesItemType = {
    company: Company
}

const CompaniesItem = (props: CompaniesItemType) => {
    const { company } = props

    return (
        <SegmentNavLink path={`/settings/companies/${company.id}/details`}>
            <List.Item clickable>
                <List.ItemText primary={company.name} />
            </List.Item>
        </SegmentNavLink>
    )
}

export default CompaniesItem
