import { IsOptional, IsString } from '@viterbit/web-app/shared/utils/ClassValidators'

export class EmployeeContractJobPositionValidator {
    employeeId: string
    @IsString()
    @IsOptional()
    id?: string | null
    @IsString()
    @IsOptional()
    alias?: string
}