/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionIcon, ActionIconProps, FileButton, FileInputProps, Input, InputProps, Loader } from '@mantine/core'
import { IconPaperclip, IconSend } from '@tabler/icons-react'
import * as React from 'react'
import { IFile } from 'ui/src/components/Upload/sharedTypes'
import clsxm from 'ui/src/lib'

import ChatBarFiles from './ChatBarFiles'

export type ChatBarProps = {
    children?: React.ReactNode
    className?: string
    inputProps?: InputProps
    fileProps?: FileInputProps & {
        files: IFile[]
        onDeleteFile?: (file: IFile) => void
        loading?: boolean
    }
    buttonSendProps?: ActionIconProps
}

const ChatBar = (props: ChatBarProps) => {
    const { inputProps, fileProps, buttonSendProps } = props
    const { files = [], onDeleteFile, ...filePropsRest} = fileProps || {}
    const hasFiles = !!files.length

    return (
        <div className={clsxm('relative mx-4')}>
            <Input
                {...inputProps}
                radius="md"
                multiline={true}
                data-testid="chat-bar-input"
                classNames={{
                    // input: hasFiles ? 'min-h-[60px!important] pb-[24px!important]' : '',
                }}
            />
            <FileButton {...filePropsRest as any} multiple>
                {(props: any) => (
                    <ActionIcon
                        size="md"
                        radius="xl"
                        variant="transparent"
                        data-testid="chat-bar-files"
                        sx={{
                            position: 'absolute',
                            top: 5,
                            right: 42,
                        }}
                        {...props}
                    >

                        {props.loading ? <Loader size={28} color='gray'/> : <IconPaperclip size="22"/> }
                    </ActionIcon>
                )}
            </FileButton>
            <ActionIcon
                color="primary"
                size="md"
                radius="xl"
                variant="filled"
                type='submit'
                data-testid="chat-bar-send"
                sx={{
                    position: 'absolute',
                    top: 5,
                    right: 8,
                }}
                {...buttonSendProps}
            >
                <IconSend size="20"/>
            </ActionIcon>
            <ChatBarFiles files={files} className='mt-2' onDelete={onDeleteFile}/>
        </div>
    )
}

export default ChatBar
