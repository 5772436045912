import { IconDownload, IconX } from '@tabler/icons-react'
import * as React from 'react'

import DownloadFile from './DownloadFile'
import FileItemButton from './FileItemButton'
import { IFile } from './sharedTypes'
import Spinner from '../Spinner'
import clsxm from '../../lib'


type FileItemActionsProps = {
    children?: React.ReactNode
    className?: string
    file: IFile
    onDelete?: (file: IFile) => void
    onDownload?: (file: IFile) => void
    canDelete?: boolean
    t?: (key: string) => string
}

const styles = {
    root: 'flex items-center gap-2'
}

const FileItemActions = (props: FileItemActionsProps) => {
    const { file, children, className, onDelete, canDelete, t, onDownload } = props

    if (file._meta?.status === 'pending') {
        return (
            <Spinner isSpinning  />
        )
    }

    if (file._meta?.status === 'error') {
        return (
            <FileItemButton
                onClick={() => onDelete?.(file)}
                tooltipText={t?.('upload.file_item.remove_file') || 'Remove File'}
            >
                <IconX size={18}  />
            </FileItemButton>
        )
    }

    if (!file.url) {
        console.error('FileItemActions: file.url is not defined')
        return null
    }

    return (
        <div className={clsxm(styles.root, className)}>
            {children}
            {
                onDownload && (
                    <FileItemButton
                        tooltipText={t?.('ui:upload.file_item.download_file') || 'Download File'}
                    >
                        <DownloadFile onClick={() => onDownload?.(file)} url={file.url}>
                            <IconDownload size={18}  />
                        </DownloadFile>
                    </FileItemButton>
                )
            }
            

            {canDelete && (
                <FileItemButton
                    onClick={() => onDelete?.(file)}
                    tooltipText={t?.('ui:upload.file_item.remove_file') || 'Remove File'}
                >
                    <IconX size={18}/>
                </FileItemButton>
            )}
        </div>

    )
}

export default FileItemActions
