
import { useInfiniteQuery } from '@tanstack/react-query'
import { COMPANY_QUERY_KEY } from '@viterbit/web-app/dataAccess/cacheKeys'
import { companyList } from '@viterbit/web-app/dataAccess/service/companyService'
import { Api } from 'types/Api'

type CompanyListConfig = {
    query: Api.QueryType<Parameters<typeof companyList>[0]>
    enabled?: boolean
}

export const useCompanyListPaginated = (config?: CompanyListConfig) => {
    const { query = { page: 1 }, enabled } = config || {}

    return useInfiniteQuery(
        [COMPANY_QUERY_KEY, query],
        ({ pageParam }) => companyList({ page: pageParam }),
        {
            getNextPageParam: (lastPage) => lastPage ? lastPage.meta.nextPage : 1,
            initialData: () => ({
                pageParams: [1],
                pages: [],
            }),
            enabled,
        }
    )
}

