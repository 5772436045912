import i18n from '@viterbit/web-app/i18n'
import DateTimeItemTitle from '@viterbit/web-app/shared/display/DateTimeItem/DateTimeItemTitle'
import dayjs from 'dayjs'
import * as React from 'react'

import { getCurrentStatus, getRelevantClassBackground } from './helpers'
import RelevantDaysTooltip from './RelevantDaysTooltip'
import { hexToRgba } from '../../helpers'
import { RelevantDate } from '../../types'

type LeavePendingDayProps = {
    relevantDate: RelevantDate
}

const LeavePendingDay = ({ relevantDate }: LeavePendingDayProps) => {
    const day = dayjs(relevantDate.date).format('DD')

    const classNameBg = getRelevantClassBackground(relevantDate.status)

    return (
        <RelevantDaysTooltip
            title={
                <>
                    <DateTimeItemTitle
                        title={relevantDate.label}
                        color={relevantDate.color}
                    />
                    <div className='flex flex-row w-full gap-8'>
                        <div className='flex flex-row'>
                            <p className='pr-1 font-semibold text-black'>
                                {relevantDate.total}
                            </p>
                            {
                                relevantDate.total === 1
                                    ? i18n.t('leaves:relevantDays.day')
                                    : i18n.t('leaves:relevantDays.days')
                            }
                        </div>
                        <div>
                            <span
                                className={`relative flex items-center justify-center rounded-full text-black ${classNameBg}`}
                            >
                                <p className='px-2'>
                                    {getCurrentStatus(relevantDate.status)}
                                </p>
                            </span>
                        </div>
                    </div>
                </>
            }
        >
            <span
                style={{
                    borderColor: hexToRgba(relevantDate.color, 1),
                }}
                className='flex items-center justify-center border-2 border-dashed rounded-full w-7 h-7'
            >
                {day}
            </span>
        </RelevantDaysTooltip>
    )
}

export default LeavePendingDay
