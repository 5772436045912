import { signOut } from '@viterbit/web-app/dataAccess/service/authService'
import illustration from '@viterbit/web-assets/images/error-404.svg'
import React from 'react'

const tw = {
    container: 'flex flex-col justify-center p-8',
    contentContainer: 'flex flex-col md:flex-row md:items-center min-h-[60vh] max-w-screen-xl',
    imageContainer: 'w-full max-w-xs md:w-1/2 lg:mr-20 mb-4 md:mb-0 md:max-w-[300px]',
    image: 'w-full',
    textContainer: 'text-center md:text-left md:w-1/2',
    title: 'text-2xl font-bold text-black mb-2',
    text: 'text-lg font-medium text-gray-700 mb-4',
    button:
    'px-4 py-2 rounded-md text-base font-medium text-white bg-blue-600 hover:bg-blue-500',
}

const NotPaidSubscription = () => (
    <div className={tw.container}>
        <div className={tw.contentContainer}>
            <div className={tw.imageContainer}>
                <img
                    className={tw.image}
                    src={illustration}
                    alt="Ilustración de página no encontrada"
                />
            </div>
        
            <div className={tw.textContainer}>
                <h1 className={tw.title}>Oops, parece que hay un problema con la subscripción</h1>
                <p className={tw.text}>
                    Lo sentimos, tu subscripción no está activa o ha sido cancelada. Por favor, ponte en contacto con el administrador para resolver el problema.
                </p>


                <button className={tw.button} onClick={signOut}>Salir</button>

            </div>
        </div>
    </div>
)

export default NotPaidSubscription
