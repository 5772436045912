
import { useLeavePolicyListPaginated } from '@viterbit/web-app/dataAccess/useCase/leavePolicy/useLeavePolicyListPaginated'
import { useOnScreenElement } from '@viterbit/web-app/shared/behaviour/useOnScreenElement'
import BreakpointRedirection from '@viterbit/web-app/shared/navigation/BreakpointRedirection'
import * as React from 'react'
import List from 'ui/src/components/List'

import LeavePoliciesItem from './LeavePoliciesItem'

const LeavePoliciesListView = () => {
    const { ref } = useOnScreenElement({
        onVisible: () => {
            hasNextPage && fetchNextPage()
        }
    })
    
    const {
        data,
        fetchNextPage,
        hasNextPage,
    } = useLeavePolicyListPaginated()

    const firstPolicy = React.useMemo(() => data?.pages[0]?.data[0], [data])

    return (
        <>
            {firstPolicy && <BreakpointRedirection matcherPath="/settings/policies" redirectPath={`/settings/policies/${firstPolicy?.id}/details`} />}
        
            <List className='flex flex-col divide-y'>
                {data?.pages?.map(({ data: pageData }) =>
                    pageData.map((policy) => (
                        <LeavePoliciesItem key={policy.id} policy={policy} />
                    ))
                )}
            </List>

            <div ref={ref} />
        </>
    )
}

export default LeavePoliciesListView
