/* eslint-disable @typescript-eslint/no-explicit-any */

import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(duration)
dayjs.extend(utc)
dayjs.extend(timezone)

type TimeRangesFormatted = {
    total: number
    totalFormatted: string
}

const sumTimeRanges = (data, startKey = 'startDate', endKey = 'endDate', format: any = undefined): TimeRangesFormatted => {
    const counterMs = data.reduce((acc, item) => {
        const start = dayjs(item[startKey], format)
        const end = dayjs(item[endKey], format)

        if (!start.isValid() || !end.isValid()) {
            return acc
        }

        const total = start.isSameOrAfter(end) ? end.add(1, 'day').diff(start) : dayjs(end).diff(start)

        return {
            total: acc.total + total,
        }
    }, {
        total: 0
    })

    const dataCountersFormatted = Object.keys(counterMs).reduce((acc, key) => {
        const value: number = counterMs[key]

        return {
            ...acc,
            [key]: value,
            [`${key}Formatted`]: dayjs.duration(value).format('HH:mm:ss')
        }
    }, {
        total: 0,
        totalFormatted: ''
    })
    
    return dataCountersFormatted
}

export default sumTimeRanges