import * as React from 'react'

import Header from './Header'
import { formatYear, MAX_YEAR, MIN_YEAR } from '../utils'
import { clsxm } from '../../../lib/clsxm'

const classes = {
    'year-cell': 'text-center py-2 rounded-lg font-semibold',
    'year-cell-active': 'year-cell-active bg-primary-600 text-white',
    'year-cell-disabled': 'opacity-30 bg-gray-300 cursor-not-allowed',
    year: 'w-ful',
    'year-table': 'grid grid-cols-3 gap-2',
}

const getDecadeRange = (year: number): number[] => {
    const rounded = year - (year % 10) - 1
    const range = new Array<number>()
    for (let i = 0; i < 12; i += 1) {
        const rangeYear = rounded + i
        range.push(rangeYear)
    }

    return range
}

type YearTableProps = {
    className?: string
    year: number
    onChange: (year: number) => void
    minYear?: number
    maxYear?: number
    preventFocus?: boolean
    yearLabelFormat?: string
    nextLabel?: string
    prevLabel?: string
}

const YearTable = ({
    className,
    year: yearProp,
    onChange,
    minYear = MIN_YEAR,
    maxYear = MAX_YEAR,
    preventFocus,
    yearLabelFormat,
    nextLabel = 'Next decade',
    prevLabel = 'Previous decade',
}: YearTableProps) => {
    const [year, setYear] = React.useState(yearProp)
    const decadeRange = getDecadeRange(year)

    const decadeYears = decadeRange.map((y) => {
        const disabled = y < minYear || y > maxYear

        const isActive = y === year

        return (
            <button
                type='button'
                key={y}
                onClick={() => onChange(y)}
                disabled={disabled}
                onMouseDown={(event) => preventFocus && event.preventDefault()}
                className={clsxm(
                    classes['year-cell'],
                    isActive && !disabled && classes['year-cell-active'],
                    !isActive && !disabled && 'hover:bg-gray-100',
                    disabled && classes['year-cell-disabled']
                )}
            >
                {formatYear(y, yearLabelFormat)}
            </button>
        )
    })
    const formattedYear = (y: number) => formatYear(y, yearLabelFormat)

    return (
        <div className={clsxm(classes.year, className)}>
            <Header
                label={`${formattedYear(decadeRange[0])} - ${formattedYear(
                    decadeRange[decadeRange.length - 1]
                )}`}
                hasPrevious={minYear < decadeRange[0]}
                hasNext={maxYear > decadeRange[decadeRange.length - 1]}
                onNext={() => setYear((prevYear) => prevYear + 10)}
                onPrevious={() => setYear((prevYear) => prevYear - 10)}
                nextLevelDisabled
                nextLabel={nextLabel}
                previousLabel={prevLabel}
                preventFocus={preventFocus}
            />
            <div className={classes['year-table']}>{decadeYears}</div>
        </div>
    )
}

export default YearTable
