import { useMutation } from '@tanstack/react-query'
import { Leave } from '@viterbit/web-app/dataAccess/model/Leave.model'
import { leaveApprove, leaveReject } from '@viterbit/web-app/dataAccess/service/leavesService'
import i18n from '@viterbit/web-app/i18n'
import { useErrorNotification } from '@viterbit/web-app/shared/feedback/useErrorsNotifications'
import * as React from 'react'
import Button from 'ui/src/components/Button'
import clsxm from 'ui/src/lib'

type ReviewActionProps = {
    leave: Leave
    onSubmitCallBack?: () => void
}

const ReviewAction = ({ leave, onSubmitCallBack }: ReviewActionProps) => {
    const leaveId = leave.id
    const leaveStatus = leave.status

    const notification = useErrorNotification()

    const handleSuccess = () => {
        onSubmitCallBack?.()
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleError = (error: any) =>
        notification.push('Error', error?.data?.errorCode)

    const { isLoading: isLoadingApproval, mutate: mutateApprove } = useMutation(
        {
            mutationKey: ['approve-leave', leaveId],
            mutationFn: (id: string) => leaveApprove({
                id,
            }),
            onSuccess: handleSuccess,
            onError: handleError,
        }
    )

    const { isLoading: isLoadingRejected, mutate: mutateRejection } =
        useMutation({
            mutationKey: ['deny-leave', leaveId],
            mutationFn: (id: string) => leaveReject({
                id
            }),
            onSuccess: handleSuccess,
            onError: handleError,
        })

    const shouldDisableActions = isLoadingApproval || isLoadingRejected

    const handleApprove = () => {
        mutateApprove(leaveId)
    }

    const handleDenied = () => {
        mutateRejection(leaveId)
    }

    const showDenied = leaveStatus === 'PENDING' || leaveStatus === 'APPROVED'
    const showApprove = leaveStatus === 'PENDING' || leaveStatus === 'REJECT'

    return (
        <div
            className={clsxm(
                'flex gap-x-2',
                showDenied && showApprove ? 'w-full' : 'w-1/2'
            )}
        >
            {showDenied && (
                <Button
                    onClick={handleDenied}
                    disabled={shouldDisableActions}
                    loading={isLoadingRejected}
                    block
                    variant={showApprove ? 'default' : 'solid'}
                    data-testid='button-denied-leave'
                >
                    {i18n.t('leaves:editDialog.buttons.denied')}
                </Button>
            )}
            {showApprove && (
                <Button
                    variant='solid'
                    block
                    loading={isLoadingApproval}
                    disabled={shouldDisableActions}
                    onClick={handleApprove}
                    data-testid='button-approve-leave'
                >
                    {i18n.t('leaves:editDialog.buttons.approve')}
                </Button>
            )}
        </div>
    )
}

export default ReviewAction
