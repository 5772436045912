/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    IconInfoCircle,
    IconSettings,
} from '@tabler/icons-react'
import i18n from '@viterbit/web-app/i18n'
import NavigationTabs from '@viterbit/web-app/shared/navigation/Tabs/NavigationTabs'
import * as React from 'react'

type TabsKey = 'detail' | 'config'

const basePath = '/workflow/onboarding'
const isLoading = false

const WorkflowTabs = ({ children }) => {

    const tabPermissions = React.useMemo<Record<TabsKey, boolean>>(() => ({
        detail: true,
        config: true,
    }), [])

    return (
        <NavigationTabs<TabsKey>
            isLoading={isLoading}
            tabs={[
                {
                    key: 'detail',
                    path: `${basePath}/detail`,
                    title: i18n.t('workflow:section.detail.tabName'),
                    icon: <IconInfoCircle size={18} />,
                    exact: false,
                    hasPermission: tabPermissions.detail,
                },
                {
                    key: 'config',
                    path: `${basePath}/config`,
                    title: i18n.t('workflow:section.config.tabName'),
                    icon: <IconSettings size={18} />,
                    exact: false,
                    hasPermission: tabPermissions.config,
                },
            ]}
        >
            {children}
        </NavigationTabs>
    )
}

export default WorkflowTabs
