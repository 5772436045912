import * as React from 'react'

import clsxm from '../../lib'

export type FileListProps = {
    children?: React.ReactNode
    className?: string
    columns?: 1 | 2 | 3 | 4 | 5
}

const styles = {
    root: 'grid grid-cols-1 gap-4',
    columns: {
        1: 'grid-cols-1',
        2: 'grid-cols-2',
        3: 'grid-cols-3',
        4: 'grid-cols-4',
        5: 'grid-cols-5',
    }
}

const FileList = (props: FileListProps) => {
    const { children, className, columns = 1 } = props

    return (
        <div className={clsxm(styles.root, styles.columns[columns], className)}>
            {children}
        </div>

    )
}

export default FileList
