import { Employee } from '@viterbit/web-app/dataAccess/model/Employee.model'
import i18n from '@viterbit/web-app/i18n'
import MultiColumnLayout from '@viterbit/web-app/shared/layout/MultiColumnLayout'
import * as React from 'react'
import Alert from 'ui/src/components/Alert'
import Button from 'ui/src/components/Button'
import Dialog from 'ui/src/components/Dialog'
import clsxm from 'ui/src/lib'

import EmployeeProfileSendInvitationForm from './EmployeeProfileSendInvitationForm'

type EmployeeProfileSendInvitationProps = {
    children?: React.ReactNode
    className?: string
    employee: Employee
}

const EmployeeProfileSendInvitation = (props: EmployeeProfileSendInvitationProps) => {
    const { employee, className } = props

    const [open, setOpen] = React.useState(false)
    const handleClose = () => setOpen(false)
    const handleOpen = () => setOpen(true)

    if (employee.hasAccount || employee.isDeleted) {
        return null
    }

    return (
        <>
            <MultiColumnLayout left>
                <Alert
                    type='info'
                    className={clsxm('mb-4', className)}
                    showIcon
                    variant='outline'
                    endContent={
                        <Button
                            variant='solid'
                            onClick={handleOpen}
                            data-testid='send-invitation-button'
                            className='bg-primary-600 hover:bg-primary-500 active:bg-primary-700'
                        >
                            {i18n.t('employees:employee.profile.sendInvitation.button')}
                        </Button>
                    }
                >
                    {i18n.t('employees:employee.profile.sendInvitation.alertText')}
                </Alert>

                <Dialog
                    title={i18n.t('employees:employee.profile.sendInvitation.dialog.title')}
                    open={open}
                    onClose={handleClose}
                >
                    <h6 className='pb-4 font-medium'>
                        {i18n.t('employees:employee.profile.sendInvitation.dialog.text')}
                    </h6>
                    <EmployeeProfileSendInvitationForm
                        employeeId={employee.id}
                        onSuccess={handleClose}
                    />
                </Dialog>
            </MultiColumnLayout>
        </>
    )
}

export default EmployeeProfileSendInvitation
