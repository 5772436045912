
export const SubscriptionStatus = ['FUTURE', 'IN_TRIAL', 'ACTIVE', 'NON_RENEWING', 'PAUSED', 'CANCELLED'] as const
export type SubscriptionStatus = typeof SubscriptionStatus[number]
export const SubscriptionFrequencies = ['day', 'week', 'month', 'year'] as const
export type SubscriptionFrequencies = typeof SubscriptionFrequencies[number]
export const SubscriptionDiscountTypes = ['percentage', 'amount'] as const
export type SubscriptionDiscountTypes = typeof SubscriptionDiscountTypes[number]

export interface SubscriptionPrice {
    id: string
    currency: string
    price: number
    name: string
    paymentPageUrl?: string
    period: number
    periodUnit: SubscriptionFrequencies
}

export interface Subscription {
    id: string
    customerId: string
    slots: number
    unitPrice: number
    currency: string
    totalAmount: number
    trialEndAt?: string
    nextBillingAt: string
    status: SubscriptionStatus
    validPaymentMethod: boolean
    shouldUpgrade: boolean
    period: number
    periodUnit: SubscriptionFrequencies
    prices: SubscriptionPrice[]
}
