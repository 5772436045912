
import { useMutation } from '@tanstack/react-query'
import { JobPositionLevel } from '@viterbit/web-app/dataAccess/model/JobPositionLevel.model'
import { jobPositionLevelDelete } from '@viterbit/web-app/dataAccess/service/jobPositionLevelService'
import i18n from '@viterbit/web-app/i18n'
import ApiErrorMessage from '@viterbit/web-app/shared/feedback/ApiErrorMessage'
import FormDeleteDialog from '@viterbit/web-app/shared/form/FormDeleteDialog'
import * as React from 'react'

type JobPositionLevelDeleteDialogProps = {
    children?: React.ReactNode
    className?: string
    level: JobPositionLevel | null
    onCancel: () => void
    onSuccess: () => void
}

const JobPositionLevelDeleteDialog = ({
    level,
    onCancel,
    onSuccess
}: JobPositionLevelDeleteDialogProps) => {
    const [isPending, startTransition] = React.useTransition()

    const { mutate, isLoading, error} = useMutation({
        onSuccess: () => {
            startTransition(() => {
                onSuccess()
            })
        },
        mutationFn: () => jobPositionLevelDelete({id: level!.id!}),
    })

    return (
        <FormDeleteDialog
            isOpen={!!level}
            onClose={onCancel}
            cancelButtonText={i18n.t('common:buttons.cancel')}
            isLoading={isLoading || isPending}
            onSubmit={mutate}
            submitButtonText={i18n.t('common:buttons.confirm')}
            title={i18n.t('employees:jobPositions.levelSection.deleteTitle')}
        >
            <ApiErrorMessage error={error} />
        </FormDeleteDialog>

    )
}

export default JobPositionLevelDeleteDialog
