/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DataComplaintQueryResponse2 } from './DataComplaintQueryResponse2';
import {
    DataComplaintQueryResponse2FromJSON,
    DataComplaintQueryResponse2FromJSONTyped,
    DataComplaintQueryResponse2ToJSON,
} from './DataComplaintQueryResponse2';

/**
 * 
 * @export
 * @interface EmployeeFindComplaintQueryResponse
 */
export interface EmployeeFindComplaintQueryResponse {
    /**
     * 
     * @type {string}
     * @memberof EmployeeFindComplaintQueryResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeFindComplaintQueryResponse
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeFindComplaintQueryResponse
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeFindComplaintQueryResponse
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeFindComplaintQueryResponse
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeFindComplaintQueryResponse
     */
    receivedAt?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeeFindComplaintQueryResponse
     */
    finishedAt?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeeFindComplaintQueryResponse
     */
    authorName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeeFindComplaintQueryResponse
     */
    authorEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeeFindComplaintQueryResponse
     */
    authorPhone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeeFindComplaintQueryResponse
     */
    authorRelationship?: string | null;
    /**
     * 
     * @type {DataComplaintQueryResponse2}
     * @memberof EmployeeFindComplaintQueryResponse
     */
    records?: DataComplaintQueryResponse2;
}

/**
 * Check if a given object implements the EmployeeFindComplaintQueryResponse interface.
 */
export function instanceOfEmployeeFindComplaintQueryResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function EmployeeFindComplaintQueryResponseFromJSON(json: any): EmployeeFindComplaintQueryResponse {
    return EmployeeFindComplaintQueryResponseFromJSONTyped(json, false);
}

export function EmployeeFindComplaintQueryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeeFindComplaintQueryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'receivedAt': !exists(json, 'receivedAt') ? undefined : json['receivedAt'],
        'finishedAt': !exists(json, 'finishedAt') ? undefined : json['finishedAt'],
        'authorName': !exists(json, 'authorName') ? undefined : json['authorName'],
        'authorEmail': !exists(json, 'authorEmail') ? undefined : json['authorEmail'],
        'authorPhone': !exists(json, 'authorPhone') ? undefined : json['authorPhone'],
        'authorRelationship': !exists(json, 'authorRelationship') ? undefined : json['authorRelationship'],
        'records': !exists(json, 'records') ? undefined : DataComplaintQueryResponse2FromJSON(json['records']),
    };
}

export function EmployeeFindComplaintQueryResponseToJSON(value?: EmployeeFindComplaintQueryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': value.title,
        'status': value.status,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'receivedAt': value.receivedAt,
        'finishedAt': value.finishedAt,
        'authorName': value.authorName,
        'authorEmail': value.authorEmail,
        'authorPhone': value.authorPhone,
        'authorRelationship': value.authorRelationship,
        'records': DataComplaintQueryResponse2ToJSON(value.records),
    };
}

