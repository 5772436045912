import { ActionIcon, ButtonProps, Tooltip } from '@mantine/core'
import { IconPlus } from '@tabler/icons-react'
import i18n from '@viterbit/web-app/i18n'
import * as React from 'react'

type ActionButtonProps = {
    children?: React.ReactNode
    className?: string
    title?: string
    onClick?: (event: any) => void
    IconComponent?: any
} & Pick<ButtonProps, 'color' | 'size' | 'radius' | 'loading' | 'variant'>

const ActionButton  = (props: ActionButtonProps) => {
    const { title = i18n.t('buttons.create'), onClick, IconComponent = IconPlus, ...rest } = props

    return (
        <Tooltip label={title} position='left' >
            <ActionIcon color="primary" size="md" radius="xl" variant="filled" onClick={onClick} {...rest}>
                <IconComponent stroke={2.5} size="16" />
            </ActionIcon>
        </Tooltip>
    )

}

export default ActionButton
