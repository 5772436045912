/* eslint-disable @typescript-eslint/no-explicit-any */
import { IMapper } from './__common'
import calendarMapper from './calendar.mapper'
import { LocationResponse } from '../dto'
import { Location } from '../model/Location.model'

const locationMapper: IMapper<Location, LocationResponse> = {
    toModel: (dto) => ({
        id: dto.id,
        name: dto.name,
        calendar: dto.calendar ? dto.calendar.map(calendarMapper.toModel) : [],
        country: dto.country || '',
        address: dto.address || '',
        city: dto.city || '',
        state: dto.state || '',
        postalCode: dto.postalCode || '',
        fiscalInformationSchema: (dto.fiscalInformationSchema || {}) as any,
    
    }),
    toDto: (model) => ({
        id: model.id,
        name: model.name,
        country: model.country,
    }),
}

export default locationMapper
