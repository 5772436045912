/* eslint-disable @typescript-eslint/no-explicit-any */

import dayjs from 'dayjs'

import { LeaveTeamDay } from './LeaveTeamShared'


const getDaysFromDate = (dateInput: dayjs.ConfigType, options?: Partial<LeaveTeamDay>& {locale?:string}): LeaveTeamDay[] => {
    const date = dayjs(dateInput).startOf('month')
    const count = date.daysInMonth()

    const days = Array.from({ length: count }, (_, i) => {
        const number = i + 1
        const dateDay = date.add(number - 1, 'day')
        return {
            ...options,
            number,
            //capitalize first letter
            name: dateDay.locale(options?.locale || 'es').format('ddd').charAt(0).toUpperCase() + dateDay.locale(options?.locale || 'es').format('ddd').slice(1, 3),
            isToday: dayjs().isSame(dateDay, 'day'),
            variant: 'solid' as any,
            date: dateDay.toDate(),
        }
    })

    return days
}
export default getDaysFromDate