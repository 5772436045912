import * as React from 'react'

import clsxm from '../../lib'

type DownloadFileProps = {
    children?: React.ReactNode
    className?: string
    url: string
    onClick: React.MouseEventHandler<HTMLDivElement>
}

const styles = {
    root: 'cursor-pointer'
}

const DownloadFile = (props: DownloadFileProps) => {
    const { children, className, onClick } = props

    return (
        <div className={clsxm(styles.root, className)} onClick={onClick}>
            {children}
        </div>
    )
}

export default DownloadFile
