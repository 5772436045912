
import { Employee } from '@viterbit/web-app/dataAccess/model/Employee.model'
import { employeeList } from '@viterbit/web-app/dataAccess/service/employeeService'
import { useEmployeeDetail } from '@viterbit/web-app/dataAccess/useCase/employee/useEmployeeDetail'
import { useFormContext } from 'react-hook-form'
import { GroupBase } from 'react-select'
import {
    FormSelectAsyncPaginate,
    FormSelectAsyncPaginateProps,
} from 'ui/src/components/Form'
import { FormBaseProps } from 'ui/src/components/Form/shared'
import { ListEmployeesRequest } from 'viterbit-api-sdk'

import { useNotification } from '../feedback/useNotification'

type Entity = Pick<Employee, 'id' | 'name' | 'surname'>

const excludeEmployeesByIds = (ids: string[], employees: Entity[]) =>
    employees.filter((employee) => !ids.includes(employee.id))

export type FormSelectEmployeeProps<IsMulti extends boolean = boolean> = FormBaseProps &
    Omit<
        FormSelectAsyncPaginateProps<
            Entity,
            IsMulti,
            GroupBase<Entity>,
            { page: number }
        >,
        'loadOptions'
    > & {
        excludeIds?: string[]
        getOptionValue?: (employee: Entity) => string
        fetchProps?: Omit<ListEmployeesRequest, 'page' | 'pageSize'>
    }

type LoadOptions = FormSelectAsyncPaginateProps<
    Entity,
    boolean,
    GroupBase<Entity>,
    { page: number }
>['loadOptions']

const FormSelectEmployee = <IsMulti extends boolean>({
    name,
    control,
    cacheOptions = true,
    isSearchable = false,
    excludeIds = [],
    getOptionValue = (employee) => employee.id,
    fetchProps = {},
    defaultValue,
    ...rest
}: FormSelectEmployeeProps<IsMulti>) => {
    const notification = useNotification()
    const employeeId = useFormContext().watch(name)
    employeeId
    const { data: defaultEmployee = defaultValue } = useEmployeeDetail({
        query: { id: employeeId },
        enabled: !!employeeId && !defaultValue,
    })

    const loadOptions: LoadOptions = async (search, loadedOptions, _meta) =>
        employeeList({ page: _meta?.page, ...fetchProps })
            .then(({ data, meta }) => ({
                options: excludeEmployeesByIds(excludeIds, data),
                hasMore: meta.hasMore,
                additional: {
                    page: meta.page + 1,
                },
            }))
            .catch((err) => {
                notification.push(
                    'danger',
                    'Fetching error',
                    err.errorCode ?? 'Error at fetch locations'
                )
                return {
                    options: [],
                    hasMore: false,
                }
            })
    return (
        <>
            <FormSelectAsyncPaginate
                defaultValue={defaultEmployee}
                name={name}
                control={control}
                cacheOptions={cacheOptions}
                loadOptions={loadOptions}
                isSearchable={isSearchable}
                getOptionLabel={(x) => x.name}
                getOptionValue={getOptionValue}
                loadOptionsOnMenuOpen
                additional={{
                    page: 1,
                }}
                {...rest}
            />
        </>
    )
}

export default FormSelectEmployee
