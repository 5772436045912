import { useQuery } from '@tanstack/react-query'
import sumTimeRanges from '@viterbit/web-app/shared/utils/sumTimeRanges'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import { UseTimeTrackingListWithoutPaginationConfig } from './useTimetrackingListWithoutPagination'
import { TIME_TRACKING_QUERY_KEY } from '../../cacheKeys'
import { timeTrackingList } from '../../service/timeTrackingService'

dayjs.extend(duration)
dayjs.extend(utc)
dayjs.extend(timezone)

export const useTimeTrackingCounters = (config: UseTimeTrackingListWithoutPaginationConfig) => {
    const { query, enabled } = config || {}

    return useQuery({
        queryKey: [TIME_TRACKING_QUERY_KEY, 'list-without-pagination', query],
        queryFn: () => timeTrackingList(query),
        select: (data) => sumTimeRanges(data),
        enabled,
    })
}

