import { useInfiniteQuery } from '@tanstack/react-query'

import { LEAVE_QUERY_KEY } from '../../cacheKeys'
import { leaveTimelineListPaginated } from '../../service/leavesService'

type LeaveTimelineListConfig = {
    query: Parameters<typeof leaveTimelineListPaginated>[0]
    enabled?: boolean
    keepPreviousData?: boolean
}

export const useLeaveTimelineListPaginated = (config: LeaveTimelineListConfig) => {
    const { query, enabled, keepPreviousData } = config || {}

    return useInfiniteQuery(
        [LEAVE_QUERY_KEY, 'timeline', 'list-paginated', query],
        ({ pageParam }) => leaveTimelineListPaginated({
            ...query,
            page: pageParam || 1,
        }),
        {
            getNextPageParam: (lastPage) => lastPage ? lastPage.meta.nextPage : 1,
            initialData: () => ({
                pageParams: [1],
                pages: [],
            }),
            enabled,
            keepPreviousData,
        }
    )
}

