
import { Leave } from '@viterbit/web-app/dataAccess/model/Leave.model'
import * as React from 'react'

import { ClusterContext } from './ClusterProvider'
import FilterLeavesList from './FIlterLeavesList'

type ListLeaveByClusterParamProps = {
    leaves: Leave[]
}

const ListLeaveByClusterParam = ({ leaves }: ListLeaveByClusterParamProps) => {
    const { cluster } = React.useContext(ClusterContext)

    return <FilterLeavesList leaves={leaves} leaveCluster={cluster} />
}

export default ListLeaveByClusterParam
