import { Avatar, Button } from '@mantine/core'
import { IconBuildingEstate } from '@tabler/icons-react'
import { Organization } from '@viterbit/web-app/dataAccess/model/Organization.model'
import i18n from '@viterbit/web-app/i18n'
import SimpleCard from '@viterbit/web-app/shared/display/SimpleCard'


type SettingsComplaintsInfoProps = {
    organization?: Organization
    children?: React.ReactNode
}

const SettingsComplaintsInfo = (props: SettingsComplaintsInfoProps) => {
    const { organization, children } = props

    const publicHref = organization?.publicPath + '/complaints'

    return (
        <SimpleCard padding>
            <article className='flex flex-col items-center justify-center w-full gap-y-4'>
                <Avatar
                    alt={organization?.name}
                    size={160}
                    radius={160}
                >
                    <IconBuildingEstate size={80} stroke={1} />
                </Avatar>

                <p className='font-semibold'>{i18n.t('settingsComplaints:info.publicUrlTitle')}</p>
                <a href={publicHref} target='_blank' rel="noreferrer" >
                    <Button variant='light' >
                        {i18n.t('settingsComplaints:info.publicUrlLink')}
                    </Button>
                </a>
            </article>
        </SimpleCard>
    )
}
export default SettingsComplaintsInfo
