import { SpotlightProvider as  MantineSpotlightProvider } from '@mantine/spotlight'
import * as React from 'react'

import useSpotlightActions from '../behaviour/useSpotlightActions'

type SpotlightProviderProps = {
    children?: React.ReactNode
    className?: string
}

const SpotlightProvider = (props: SpotlightProviderProps) => {
    const { children } = props

    const { actions } = useSpotlightActions()

    return (
        <MantineSpotlightProvider
            highlightQuery
            actions={actions}
        >
            {children}
        </MantineSpotlightProvider>

    )
}

export default SpotlightProvider
