/* eslint-disable @typescript-eslint/no-explicit-any */

import { components, OptionProps } from 'react-select'

const FormSelectOption = ({ innerProps, ...rest }: OptionProps<any>) => (
    <components.Option
        {...rest}
        innerProps={{
            ...innerProps,
            'data-test': 'form-select-option',
            'data-testid': rest.data.id,
        } as any} />
)


export default FormSelectOption