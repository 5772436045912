/* eslint-disable @typescript-eslint/no-explicit-any */
import { classValidatorResolver } from '@hookform/resolvers/class-validator'
import { UseMutationOptions } from '@tanstack/react-query'
import { complaintPublicFileUpload } from '@viterbit/web-app/dataAccess/service/complaintService'
import { fileCreate } from '@viterbit/web-app/dataAccess/service/filesService'
import Form from '@viterbit/web-app/shared/form/Form'
import * as React from 'react'
import { useController,UseFormReturn } from 'react-hook-form'
import { useCounter } from 'react-use'
import { Api } from 'types/Api'
import { fileToIFile } from 'ui/src/components/Upload/utils'

import { ChatBarFields } from './Chat.shared'
import ChatBar, { ChatBarProps } from './ChatBar'
import ChatBarFormValidator from './ChatBarFormValidator'
import useGetEmployeeId from '../../employee/useGetEmployeeId'
import { IFormStatusContext } from '../../form/FormStatusContext'
import useUploadFiles, { UploadFilesConfig } from '../../form/useUploadFiles'

export interface ChatBarFormProps <ResponseSuccess = Api.ResponseSuccess> extends ChatBarProps {
    children?: React.ReactNode
    className?: string
    onSubmit: UseMutationOptions<ResponseSuccess, Api.ResponseError, ChatBarFields>['mutationFn']
}

const styles = {
    root: ''
}

type ChatBarFormConnectedProps = Omit<ChatBarFormProps, 'onSubmit'> & {
    form: UseFormReturn<ChatBarFields>
    formState: IFormStatusContext
    uploadConfig: UploadFilesConfig
}

const resolver = classValidatorResolver(ChatBarFormValidator)

const ChatBarFormConnected = (props: ChatBarFormConnectedProps) => {
    const { form, formState, uploadConfig } = props

    const {
        isLoading,
        files: queueFiles,
        handleOnChange,
        handleOnFileRemove,
        retryUploadFile,
        LimitChecker,
    } = useUploadFiles(uploadConfig)

    const {
        field,
        fieldState: { invalid, isTouched, isDirty },
        formState: { touchedFields, dirtyFields },
    } = useController({
        name: 'fileIds',
        control: form.control,
    })

    return (
        <ChatBar
            inputProps={form.register('message')}
            fileProps={{
                multiple: uploadConfig.multiple as any,
                onChange: (files: any) => handleOnChange(files?.map(fileToIFile), field.onChange),
                onDeleteFile: (file) => handleOnFileRemove(file.id, field.onChange),
                files: queueFiles,
                loading: isLoading,
            }}
            buttonSendProps={{
                disabled: formState.disableSubmit || isLoading,
            }}
        />
    )
}

const isPrivate = window.location.href.startsWith(import.meta.env.VITE_PRIVATE_WEB_URL) 

const ChatBarForm = (props: ChatBarFormProps) => {
    const { onSubmit, ...rest } = props

    const [key, { inc }] = useCounter()
    const { employeeId } = useGetEmployeeId()

    const fileUpload = React.useCallback(
        (body) => {
            if (isPrivate) {
                return fileCreate({
                    ...body,
                    employeeId,
                    type: 'complaint',
                })
            }

            return complaintPublicFileUpload(body)
        },
        []
    )

    return (
        <Form<ChatBarFields>
            key={key}
            withTransition
            formConfig={{
                mode: 'all',
                defaultValues: {
                    fileIds: [],
                    message: '',
                },
                resolver,
            }}
            mutationConfig={{
                mutationFn: onSubmit,
                onSuccess: () => inc(),
            }}
            resetOnSuccess
            messageErrorClassName="mx-4"
        >
            {(form, _, formState) => (
                <ChatBarFormConnected
                    {...rest}
                    form={form}
                    formState={formState}
                    uploadConfig={{
                        kind: 'document',
                        multiple: true,
                        defaultValues: [],
                        createService: fileUpload
                    }}
                />
            )}
        </Form>
    )
}

export default ChatBarForm
