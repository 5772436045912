/* eslint-disable react/no-array-index-key */
import { Fragment } from 'react'

import FormControlSkeleton from './FormControlSkeleton'
import ButtonSkeleton from '../Button/ButtonSkeleton'
import ListItemSkeleton from '../List/ListItemSkeleton'

type FormSkeletonProps = {
    count?: number
    className?: string
}

const FormSkeleton = ({ count = 1, className }: FormSkeletonProps) => (
    <div className={className} data-testid="form-skeleton" >
        {Array(count).fill(0).map((_, index) => (
            <Fragment key={String(index)}>
                {!!index && <hr />}
                <ListItemSkeleton className='px-0 mt-6 mb-4' primary secondary />
                <FormControlSkeleton label />
                <FormControlSkeleton label area />
            </Fragment>
        ))}

        <ButtonSkeleton />
    </div>
)

export default FormSkeleton
