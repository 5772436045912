import { Loader } from '@mantine/core';
import SpinnerLogo from '@viterbit/web-app/shared/feedback/SpinnerLogo';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import clsxm from 'ui/src/lib'
import useGatewayToken from './useGatewayToken';


type ComplaintGatewayViewProps = {
    children?: React.ReactNode
    className?: string
}

const styles = {
    root: ''
}

const ComplaintGatewayView = (props: ComplaintGatewayViewProps) => {
    const { children, className } = props;

    useGatewayToken()    

    return (
        <div className={clsxm(styles.root, className)}>
            <SpinnerLogo />
            {children}
        </div>

    );
}

export default ComplaintGatewayView;
