
import { Location } from '@viterbit/web-app/dataAccess/model/Location.model'
import { locationUpdate } from '@viterbit/web-app/dataAccess/service/locationService'
import Form from '@viterbit/web-app/shared/form/Form'

import CalendarHolidaysForm from './LocationHolidayDetailLayout'
import { HolidayItemFormFields } from './LocationHolidayDetailShared'

type DetailsHolidayItemViewProps = {
    location: Location
    year: number
    onSuccess: () => void
}

const DetailsHolidayItemView = ({location, year, onSuccess }: DetailsHolidayItemViewProps) => (
    <Form<HolidayItemFormFields>
        formConfig={{
            mode: 'all',
            defaultValues: location
        }}
        mutationConfig={{
            onSuccess,
            mutationFn: (data) => locationUpdate({
                id: location.id,
                updateLocationCommand: data
            }),
        }}
    >
        {(form) => (
            <CalendarHolidaysForm
                location={location}
                form={form}
                year={year}
            />
        )}
    </Form>
)

export default DetailsHolidayItemView
