import { IconArrowRight } from '@tabler/icons-react'
import dayjs from 'dayjs'
import * as React from 'react'

import { DaysProps } from './DateTimeItem'

const Calendar = ({ month, day }: {month: string, day: string}) => (
    <div className='overflow-hidden border border-gray-100 rounded-md border-1 '>
        <div className='font-medium bg-primary-600'>
            <p className='text-xs text-center text-white'>{month}</p>
        </div>
        <div>
            <p className='px-3 my-1 text-sm font-extrabold text-center text-black '>
                {day}
            </p>
        </div>
    </div>
)

const DateTimeItemRangeDate = ({ initialDate, endDate, locale }: DaysProps) => {
    const isRangeDate = !!endDate && endDate !== initialDate

    return (
        <div className='flex items-center gap-x-1'>
            <Calendar
                month={dayjs(initialDate).locale(locale).format('MMM')}
                day={dayjs(initialDate).format('DD')}
            />
            {isRangeDate && (
                <>
                    <IconArrowRight className='w-3 h-4 font-bold text-gray-800' />
                    <Calendar
                        month={dayjs(endDate).locale(locale).format('MMM')}
                        day={dayjs(endDate).format('DD')}
                    />
                </>
            )}
        </div>
    )
}

export default DateTimeItemRangeDate
