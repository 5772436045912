/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-array-index-key */
import { IconChevronRight } from '@tabler/icons-react'
import Subtitle from '@viterbit/web-app/shared/typography/Subtitle'
import * as React from 'react'
import clsxm from 'ui/src/lib'

import BreadcrumbsItem from './BreadcrumbsItem'

export type BreadcrumbsProps = {
    children: React.ReactNode
    className?: string
    title?: React.ReactNode
    icon?: React.ReactNode
}

const styles = {
    root: 'flex',
}

const Breadcrumbs = ({ children, className = '', title, icon }: BreadcrumbsProps) => {
    const lastIndex = React.Children.toArray(children).length - 1

    return (
        <nav className={clsxm(styles.root, className)} aria-label='Breadcrumb'>
            {title && (
                <>
                    <Subtitle bold className='pr-3 mr-3 border-r-2 [&>*]:w-[18px] mr-2'>
                        {title}
                    </Subtitle>


                    {icon && (
                        <span className='flex items-center mr-2 text-gray-400 [&>*]:w-[18px] '>
                            {icon}
                        </span>
                    )}
                </>
            )}
        
            <ol role='list' className='flex items-center space-x-1.5'>
                {React.Children.map(children, (child: any, index) => {
                    if (!child) return null

                    return (
                        <li key={index}>
                            <div className='flex items-center'>
                                {!!index && (
                                    <IconChevronRight className='w-5 h-5 mr-1.5 text-gray-300' />
                                )}
                                {React.cloneElement(child, {
                                    current: index === lastIndex,
                                })}
                            </div>
                        </li>
                    )
                })}
            </ol>
        </nav>
    )
}

Breadcrumbs.Item = BreadcrumbsItem

export default Breadcrumbs
