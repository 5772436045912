import * as React from 'react'

import { clsxm } from '../../lib/clsxm'
import { mapCloneElement } from '../../utils/utils'

type TimelineProps = {
    className?: string
    children?: React.ReactNode
}
// /flex-col items-start
const Timeline = React.forwardRef<HTMLUListElement, TimelineProps>(
    ({ className, children }, ref) => {
        const count = React.Children.count(children)

        const items = mapCloneElement(children, (item, index) => ({
            isLast: index === count - 1,
            ...item.props,
        }))

        return (
            <ul ref={ref} className={clsxm('flex-col items-start', className)}>
                <>{items}</>
            </ul>
        )
    }
)

export default Timeline
