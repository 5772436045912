
import { classValidatorResolver } from '@hookform/resolvers/class-validator'
import { forgotPassword } from '@viterbit/web-app/dataAccess/service/authService'
import Form from '@viterbit/web-app/shared/form/Form'
import SubmitButton from '@viterbit/web-app/shared/form/SubmitButton'
import { Link, useNavigate } from 'react-router-dom'
import FormInput from 'ui/src/components/Form/FormInput'
import LayoutHeading from 'ui/src/components/LayoutHeading'

import ForgotPasswordRequestValidator from './ForgotPasswordRequestValidator'

export type ForgotPasswordRequestFields = {
    email: string
}

const resolver = classValidatorResolver(ForgotPasswordRequestValidator)

const ForgotPasswordRequestView = () => {

    const navigate = useNavigate()

    return (
        <>
            <LayoutHeading
                title='Password recover'
                subtitle='Please enter your email to recover your password'
            />

            <Form<ForgotPasswordRequestFields>
                mutationConfig={{
                    mutationFn: (data) => forgotPassword({forgotPasswordCommand: data}),
                    onSuccess: ()=> navigate('/auth/forgot-password/request/success')
                }}
                formConfig={{
                    resolver,
                    mode: 'all'
                }}
            >
                {(form) => (
                    <>
                        <FormInput
                            required
                            asterisk
                            name='email'
                            control={form.control}
                            label='Email'
                            placeholder='Enter your email'
                        />

                        <SubmitButton>Recover my password</SubmitButton>
                    </>
                )}
            </Form>

            <div className='mt-4 text-center'>
                <span>Does you have an account?</span>
                <Link to='/auth/signin' className='ml-2 text-primary-600'>
                    Sign In
                </Link>
            </div>
        </>
    )
}

export default ForgotPasswordRequestView
