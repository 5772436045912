import fetchWithoutPagination from '@viterbit/web-app/dataAccess/helper/fetchWithoutPagination'
import jobPositionMapper from '@viterbit/web-app/dataAccess/mapper/jobPosition.mapper'
import paginationMetaMapper from '@viterbit/web-app/dataAccess/mapper/paginationMeta.mapper'
import { queryClient } from '@viterbit/web-app/shared/lib/ReactQueryProvider'
import { Configuration, JobPositionApi } from 'viterbit-api-sdk'

import { commonConfig } from './config'
import { JOB_POSITION_QUERY_KEY } from '../cacheKeys'
import { JobPosition } from '../model/JobPosition.model'


const api = new JobPositionApi(new Configuration(commonConfig))

export const jobPositionInvalidate = () =>
    queryClient.invalidateQueries([JOB_POSITION_QUERY_KEY])

export const jobPositionCreate = (body: Parameters<typeof api.createJobPosition>[0]) =>
    api.createJobPosition(body).then((data) => {
        jobPositionInvalidate()
        return data
    })

export const jobPositionUpdate = (body: Parameters<typeof api.updateJobPosition>[0]) =>
    api.updateJobPosition(body).then((data) => {
        jobPositionInvalidate()
        return data
    })

export const jobPositionList = (body: Parameters<typeof api.listJobPosition>[0]) =>
    api.listJobPosition(body).then(({data, meta}) => ({
        meta: paginationMetaMapper(meta),
        data: data.map(jobPositionMapper.toModel)
    }))

export const findWithoutPagination = (body: Parameters<typeof api.listJobPosition>[0]) =>
    fetchWithoutPagination(jobPositionList)({...body, pageSize: 10} as any) as Promise<JobPosition[]>


export const jobPositionFind = async (body: Parameters<typeof api.findJobPosition>[0]) => {
    const res = await api.findJobPosition(body)
    const item = jobPositionMapper.toModel(res)
    return item
}

export const jobPositionDelete = (body: Parameters<typeof api.deleteJobPosition>[0]) =>
    api.deleteJobPosition(body).then((data) => {
        jobPositionInvalidate()
        return data
    })