/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AssignComplaintManagerCommand,
  CreateComplaintCommand,
  CreateComplaintRecordCommand,
  CreateComplaintResponse,
  EmployeeCreateComplaintRecordCommand,
  EmployeeFindComplaintQueryResponse,
  FindComplaintQueryResponse,
  ListComplaintQueryResponse,
  LoginComplaintQuery,
  LoginComplaintQueryResponse,
  ResourceCreated,
  UnassignComplaintManagerCommand,
} from '../models/index';
import {
    AssignComplaintManagerCommandFromJSON,
    AssignComplaintManagerCommandToJSON,
    CreateComplaintCommandFromJSON,
    CreateComplaintCommandToJSON,
    CreateComplaintRecordCommandFromJSON,
    CreateComplaintRecordCommandToJSON,
    CreateComplaintResponseFromJSON,
    CreateComplaintResponseToJSON,
    EmployeeCreateComplaintRecordCommandFromJSON,
    EmployeeCreateComplaintRecordCommandToJSON,
    EmployeeFindComplaintQueryResponseFromJSON,
    EmployeeFindComplaintQueryResponseToJSON,
    FindComplaintQueryResponseFromJSON,
    FindComplaintQueryResponseToJSON,
    ListComplaintQueryResponseFromJSON,
    ListComplaintQueryResponseToJSON,
    LoginComplaintQueryFromJSON,
    LoginComplaintQueryToJSON,
    LoginComplaintQueryResponseFromJSON,
    LoginComplaintQueryResponseToJSON,
    ResourceCreatedFromJSON,
    ResourceCreatedToJSON,
    UnassignComplaintManagerCommandFromJSON,
    UnassignComplaintManagerCommandToJSON,
} from '../models/index';

export interface AssignComplaintManagerRequest {
    assignComplaintManagerCommand?: AssignComplaintManagerCommand;
}

export interface CreateComplaintRequest {
    createComplaintCommand?: CreateComplaintCommand;
}

export interface CreateComplaintRecordsRequest {
    createComplaintRecordCommand?: CreateComplaintRecordCommand;
}

export interface CreateEmployeeComplaintRecordsRequest {
    employeeCreateComplaintRecordCommand?: EmployeeCreateComplaintRecordCommand;
}

export interface EmployeeFindComplaintRequest {
    id: string;
}

export interface ListComplaintRequest {
    status?: string;
    page?: number;
    pageSize?: number;
}

export interface LoginComplaintRequest {
    loginComplaintQuery?: LoginComplaintQuery;
}

export interface UnassignComplaintManagerRequest {
    unassignComplaintManagerCommand?: UnassignComplaintManagerCommand;
}

export interface UpdateEmployeeComplaintsRequest {
    id: string;
    body?: object;
}

/**
 * 
 */
export class ComplaintApi extends runtime.BaseAPI {

    /**
     * Assign complaint manager
     * Assign complaint manager
     */
    async assignComplaintManagerRaw(requestParameters: AssignComplaintManagerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core-hr/complaints/assign-complaint-manager`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AssignComplaintManagerCommandToJSON(requestParameters.assignComplaintManagerCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Assign complaint manager
     * Assign complaint manager
     */
    async assignComplaintManager(requestParameters: AssignComplaintManagerRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.assignComplaintManagerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a complaint
     * Create a complaint
     */
    async createComplaintRaw(requestParameters: CreateComplaintRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateComplaintResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/public/complaints`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateComplaintCommandToJSON(requestParameters.createComplaintCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateComplaintResponseFromJSON(jsonValue));
    }

    /**
     * Create a complaint
     * Create a complaint
     */
    async createComplaint(requestParameters: CreateComplaintRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateComplaintResponse> {
        const response = await this.createComplaintRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a complaint record
     * Create a complaint record
     */
    async createComplaintRecordsRaw(requestParameters: CreateComplaintRecordsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/public/complaint-records`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateComplaintRecordCommandToJSON(requestParameters.createComplaintRecordCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Create a complaint record
     * Create a complaint record
     */
    async createComplaintRecords(requestParameters: CreateComplaintRecordsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.createComplaintRecordsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a complaint record from an employee
     * Create a complaint record
     */
    async createEmployeeComplaintRecordsRaw(requestParameters: CreateEmployeeComplaintRecordsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core-hr/complaint-records`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EmployeeCreateComplaintRecordCommandToJSON(requestParameters.employeeCreateComplaintRecordCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Create a complaint record from an employee
     * Create a complaint record
     */
    async createEmployeeComplaintRecords(requestParameters: CreateEmployeeComplaintRecordsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.createEmployeeComplaintRecordsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find a complaint
     * Find a complaint
     */
    async employeeFindComplaintRaw(requestParameters: EmployeeFindComplaintRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmployeeFindComplaintQueryResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling employeeFindComplaint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core-hr/complaints/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmployeeFindComplaintQueryResponseFromJSON(jsonValue));
    }

    /**
     * Find a complaint
     * Find a complaint
     */
    async employeeFindComplaint(requestParameters: EmployeeFindComplaintRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmployeeFindComplaintQueryResponse> {
        const response = await this.employeeFindComplaintRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find a complaint
     * Find a complaint
     */
    async findComplaintRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindComplaintQueryResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/public/complaints`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindComplaintQueryResponseFromJSON(jsonValue));
    }

    /**
     * Find a complaint
     * Find a complaint
     */
    async findComplaint(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindComplaintQueryResponse> {
        const response = await this.findComplaintRaw(initOverrides);
        return await response.value();
    }

    /**
     * List complaints
     * List complaints
     */
    async listComplaintRaw(requestParameters: ListComplaintRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListComplaintQueryResponse>> {
        const queryParameters: any = {};

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core-hr/complaints`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListComplaintQueryResponseFromJSON(jsonValue));
    }

    /**
     * List complaints
     * List complaints
     */
    async listComplaint(requestParameters: ListComplaintRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListComplaintQueryResponse> {
        const response = await this.listComplaintRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Login complaint
     * Login complaint
     */
    async loginComplaintRaw(requestParameters: LoginComplaintRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginComplaintQueryResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/public/complaints/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginComplaintQueryToJSON(requestParameters.loginComplaintQuery),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginComplaintQueryResponseFromJSON(jsonValue));
    }

    /**
     * Login complaint
     * Login complaint
     */
    async loginComplaint(requestParameters: LoginComplaintRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginComplaintQueryResponse> {
        const response = await this.loginComplaintRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * unassign complaint manager
     * Unassign complaint manager
     */
    async unassignComplaintManagerRaw(requestParameters: UnassignComplaintManagerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core-hr/complaints/unassign-complaint-manager`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UnassignComplaintManagerCommandToJSON(requestParameters.unassignComplaintManagerCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * unassign complaint manager
     * Unassign complaint manager
     */
    async unassignComplaintManager(requestParameters: UnassignComplaintManagerRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.unassignComplaintManagerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a complaint from an employee
     * Update a complaint
     */
    async updateEmployeeComplaintsRaw(requestParameters: UpdateEmployeeComplaintsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateEmployeeComplaints.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core-hr/complaints/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Update a complaint from an employee
     * Update a complaint
     */
    async updateEmployeeComplaints(requestParameters: UpdateEmployeeComplaintsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.updateEmployeeComplaintsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
