/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { classValidatorResolver } from '@hookform/resolvers/class-validator'
import LeaveMapper from '@viterbit/web-app/dataAccess/mapper/leave.mapper'
import { leaveCreate } from '@viterbit/web-app/dataAccess/service/leavesService'
import Form from '@viterbit/web-app/shared/form/Form'
import * as React from 'react'

import LeaveForm from './LeaveForm'
import LeaveValidator from './LeaveValidator'
import { LeaveFormFields } from './types'

type CreateFormProps = {
    employeeId?: string
    onSubmitCallBack?: () => void
    defaultValues: Partial<LeaveFormFields>
}

const resolver = classValidatorResolver(LeaveValidator)

const CreateForm = ({
    employeeId,
    onSubmitCallBack,
    defaultValues,
}: CreateFormProps) => (
    <Form<LeaveFormFields>
        formConfig={{
            resolver,
            mode: 'all',
            defaultValues,
        }}
        mutationConfig={{
            mutationKey: ['createLeaves', employeeId],
            mutationFn: (leave) =>
                leaveCreate({
                    createLeaveCommand: {
                        ...LeaveMapper.toDto(leave),
                        leaveReasonId: leave.leaveReasonId,
                        employeeId: employeeId!,
                    } as any
                }),
            onSuccess: () => {
                onSubmitCallBack?.()
            },
        }}
    >
        {(form, _, formState) => (
            <LeaveForm
                employeeId={employeeId!}
                form={form}
                defaultValues={defaultValues}
                formState={formState}
            />
        )}
    </Form>
)

export default CreateForm
