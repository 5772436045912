import { CalendarHoliday } from '@viterbit/web-app/dataAccess/model/CalendarHoliday.model'
import { Location } from '@viterbit/web-app/dataAccess/model/Location.model'
import DateTimeItem from '@viterbit/web-app/shared/display/DateTimeItem'
import * as React from 'react'

type CalendarItemProps = {
    children?: React.ReactNode
    className?: string
    holiday: CalendarHoliday
    location: Location
    onDelete?: (id: string) => void
}

const CalendarItem = ({ holiday, location, className, onDelete }: CalendarItemProps) => (
    <DateTimeItem
        id={holiday.day}
        className={className}
        initialDate={holiday.day}
        title={holiday.name}
        content={location.name}
        onDelete={onDelete}
    />
)

export default CalendarItem
