import { IconBell } from '@tabler/icons-react'
import EmployeeItem from '@viterbit/web-app/feature/employee/item/EmployeeItem'
import EmployeeItemSkeleton from '@viterbit/web-app/feature/employee/item/EmployeeItemSkeleton'
import LeaveClusterList from '@viterbit/web-app/feature/leave/list/LeavesClusters/LeaveClusterList'
import DaysSummary from '@viterbit/web-app/feature/leave/list/Summary/DaysSummary'
import SummaryLoading from '@viterbit/web-app/feature/leave/list/Summary/SummaryLoading'
import { LeavesClusters } from '@viterbit/web-app/feature/leave/list/types'
import CalendarList from '@viterbit/web-app/feature/settings/locations/calendar/list/CalendarList'
import TimeTrackingToggleWidget from '@viterbit/web-app/feature/timeTracking/toggle/TimeTrackingToggleWidget'
import i18n from '@viterbit/web-app/i18n'
import EmbeddedProvider from '@viterbit/web-app/shared/behaviour/EmbeddedProvider'
import EmptyState from '@viterbit/web-app/shared/display/EmptyState/EmptyState'
import SimpleCard from '@viterbit/web-app/shared/display/SimpleCard'
import useCurrentUser from '@viterbit/web-app/shared/employee/useCurrentUser'
import BodyContent from '@viterbit/web-app/shared/layout/BodyContent'
import HeaderActions from '@viterbit/web-app/shared/layout/HeaderActions'
import * as React from 'react'

import DashboardLayout from './DashboardLayout'

const year = new Date().getFullYear()

const DashboardView = () => {
    const employee = useCurrentUser()

    return (
        <EmbeddedProvider>
            <BodyContent data-testid="dashboard-view">
                <BodyContent.Header>
                    <HeaderActions className='py-4'>
                        <HeaderActions.Header>
                            {!employee && <EmployeeItemSkeleton size='lg' email name />}
                            {employee && <EmployeeItem
                                employee={employee}
                                size='lg'
                                leadTitle={i18n.t('Welcome back')}
                            />}
                        </HeaderActions.Header>
                        <HeaderActions.Actions>
                            {!employee && <SummaryLoading />}
                            {employee && <DaysSummary
                                employee={employee}
                                year={year}
                            />}
                        </HeaderActions.Actions>
                    </HeaderActions>
                </BodyContent.Header>

                <BodyContent.Main>
                    <DashboardLayout
                        inbox={
                            <>
                                <SimpleCard
                                    title={i18n.t('dashboard:timetracking')}
                                    href='/me/profile/time-tracking'
                                    actionLabel={i18n.t('dashboard:seeTimeTracking')}
                                    className='mb-4'
                                >
                                    <TimeTrackingToggleWidget />
                                </SimpleCard>

                                <SimpleCard
                                    title={i18n.t('dashboard:inbox')}
                                    className='hidden'
                                >
                                    <EmptyState
                                        className='min-h-[200px] flex items-center justify-center'
                                        image={<IconBell size={64} className='text-gray-400' stroke={1} />}
                                        title={i18n.t('dashboard:emptyNotifications.title')}
                                        description={i18n.t('dashboard:emptyNotifications.description')}
                                    />
                                </SimpleCard>
                            </>
                        }
                        secondSection={
                            <SimpleCard
                                title={i18n.t('dashboard:upcoming')}
                                href='/me/profile/leaves/2023'
                                actionLabel={i18n.t('dashboard:requestLeaves')}
                            >
                                {!employee && <SummaryLoading />}
                                {employee && <LeaveClusterList
                                    employeeId={employee.id}
                                    year={year}
                                    leaveCluster={LeavesClusters.UPCOMING}
                                    limit={5}
                                />}
                            </SimpleCard>
                        }
                        holidays={

                            <SimpleCard
                                title={i18n.t('dashboard:holidays')}
                                href='/me/profile/leaves/2023'
                                actionLabel={i18n.t('dashboard:seeCalendar')}
                            >
                                {employee && <CalendarList employee={employee} limit={5} />}
                            </SimpleCard>
                        }
                    />
                </BodyContent.Main>
            </BodyContent>
        </EmbeddedProvider>
    )
}

export default DashboardView
