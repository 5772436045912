
export const ITEMS_BY_PAGE = 10

export const WEEK_DAYS = [
    'MONDAY',
    'TUESDAY',
    'WEDNESDAY',
    'THURSDAY',
    'FRIDAY',
    'SATURDAY',
    'SUNDAY',
] as const

export type IWeekDays = typeof WEEK_DAYS[number]
