/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FindLeaveReasonQueryResponse } from './FindLeaveReasonQueryResponse';
import {
    FindLeaveReasonQueryResponseFromJSON,
    FindLeaveReasonQueryResponseFromJSONTyped,
    FindLeaveReasonQueryResponseToJSON,
} from './FindLeaveReasonQueryResponse';

/**
 * 
 * @export
 * @interface DataFindLeaveReasonQueryResponse
 */
export interface DataFindLeaveReasonQueryResponse {
    /**
     * 
     * @type {Array<FindLeaveReasonQueryResponse>}
     * @memberof DataFindLeaveReasonQueryResponse
     */
    data: Array<FindLeaveReasonQueryResponse>;
}

/**
 * Check if a given object implements the DataFindLeaveReasonQueryResponse interface.
 */
export function instanceOfDataFindLeaveReasonQueryResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function DataFindLeaveReasonQueryResponseFromJSON(json: any): DataFindLeaveReasonQueryResponse {
    return DataFindLeaveReasonQueryResponseFromJSONTyped(json, false);
}

export function DataFindLeaveReasonQueryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataFindLeaveReasonQueryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(FindLeaveReasonQueryResponseFromJSON)),
    };
}

export function DataFindLeaveReasonQueryResponseToJSON(value?: DataFindLeaveReasonQueryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ((value.data as Array<any>).map(FindLeaveReasonQueryResponseToJSON)),
    };
}

