import * as React from 'react'
import clsxm from 'ui/src/lib'

import Text from '../../typography/Text'

type EmptyStateProps = {
    title?: string
    description?: string
    image?: React.ReactNode
    className?: string
}

const EmptyState = ({
    title,
    description,
    image,
    className,
}: EmptyStateProps) => (
    <div
        className={clsxm(
            'flex flex-col w-full items-center justify-center min-h-[80px] gap-y-2',
            className
        )}
    >
        {image}
        <div className='text-center'>
            {title && (<Text>{title}</Text>)}
            {description && (<Text opacity>{description}</Text>)}
        </div>
    </div>
)

export default EmptyState
