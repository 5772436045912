import jwtDecode, { JwtPayload } from 'jwt-decode'
import { useSearchParams } from 'react-router-dom'

import SignUpRedeemDetail from './SignUpRedeemDetail'

interface CustomJWT extends JwtPayload {
    email: string
    organizationName: string
}

type InitialProps = {
    token: string
    initialData?: CustomJWT
}

const SignUpRedeemView = () => {

    const [searchParams, setSearchParams] = useSearchParams()

    const token = searchParams.get('token') ?? ''
    const initialProps: InitialProps = { token }

    const country = 'ES'

    try {
        initialProps.initialData = jwtDecode(token)
    } catch (error) {
        initialProps.initialData = undefined
    }

    return <SignUpRedeemDetail {...initialProps} country={country} />
}

export default SignUpRedeemView
