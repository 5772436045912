/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionIcon, Button, ButtonProps, Tooltip } from '@mantine/core'
import { IconHelp } from '@tabler/icons-react'
import * as React from 'react'

export type ButtonIconProps = {
    children?: React.ReactNode
    className?: string
    title: string
    onClick?: (event: any) => void
    collapsed?: boolean
    IconComponent?: any
    sx?: any,
} & Pick<ButtonProps, 'color' | 'size' | 'radius' | 'loading' | 'variant'>

const ButtonIcon = (props: ButtonIconProps) => {
    const { title, onClick, collapsed, IconComponent = IconHelp, ...rest } = props

    const icon = <IconComponent size="20" stroke={1.5} />

    if (collapsed) {
        return (
            <Tooltip label={title} position='left'>
                <ActionIcon onClick={onClick} {...rest}>
                    {icon}
                </ActionIcon>
            </Tooltip>
        )
    }

    return (
        <Button leftIcon={icon} color='grey' variant="outline" onClick={onClick} {...rest}>
            {title}
        </Button>
    )
}

export default ButtonIcon
