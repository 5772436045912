export const SIZES = {
    XXS: 'xxs',
    XS: 'xs',
    SM: 'sm',
    MD: 'md',
    LG: 'lg',
}

// * this number are multiplied by 4 to give the pixel value
export const CONTROL_SIZES = {
    [SIZES.XXS]: 5,
    [SIZES.XS]: 7,
    [SIZES.SM]: 9,
    [SIZES.MD]: 11,
    [SIZES.LG]: 14,
}

export const STATUS = {
    DANGER: 'danger',
    SUCCESS: 'success',
    WARNING: 'warning',
    INFO: 'info',
}
