
import { FileModel } from '@viterbit/web-app/dataAccess/model/File.model'
import { useFileDelete } from '@viterbit/web-app/dataAccess/useCase/file/useFileDelete'
import i18n from '@viterbit/web-app/i18n'
import ApiErrorMessage from '@viterbit/web-app/shared/feedback/ApiErrorMessage'
import { useNotification } from '@viterbit/web-app/shared/feedback/useNotification'
import FormDeleteDialog from '@viterbit/web-app/shared/form/FormDeleteDialog'
import { getErrorCode } from '@viterbit/web-app/shared/utils/getErrorCode'
import * as React from 'react'

type DeleteDocumentProps = {
    file: FileModel
    open: boolean
    onSubmitCallback: () => void
    onCancelCallback: () => void
}

const DeleteDocument = ({ 
    file,
    open, 
    onSubmitCallback,
    onCancelCallback
}: DeleteDocumentProps) => {
    const notification = useNotification()
    const [isDeleting, setIsDeleting] = React.useState(open)

    const { mutate, isLoading, error } = useFileDelete({
        onSuccess: () => {
            notification.push(
                'success',
                i18n.t('personalArea:documents.deleteFileDialog.messages.fileDeleted.title'),
                i18n.t('personalArea:documents.deleteFileDialog.messages.fileDeleted.description')
            )
            setIsDeleting(false)
            onSubmitCallback()
        },
        onError: (e) => {
            const errorCode = getErrorCode(e)
            notification.push(
                'danger',
                i18n.t('personalArea:documents.deleteFileDialog.messages.deleteError.title'),
                errorCode
                    ? i18n.t(`serverValidation:${errorCode}`)
                    : i18n.t('personalArea:documents.deleteFileDialog.messages.deleteError.description')
            )
        }
    })

    const onSubmit = () => {
        mutate({
            id: file.id, type: file.kind
        })
    }


    const onClose = () => {
        setIsDeleting(false)
        onCancelCallback()
    }
    return (
        <>
            <FormDeleteDialog
                isOpen={isDeleting}
                onClose={onClose}
                cancelButtonText={i18n.t('personalArea:documents.deleteFileDialog.buttons.cancel')}
                isLoading={isLoading}
                onSubmit={onSubmit}
                submitButtonText={i18n.t('personalArea:documents.deleteFileDialog.buttons.submit')}
                title={i18n.t('personalArea:documents.deleteFileDialog.title')}
            >
                <ApiErrorMessage error={error} />
            </FormDeleteDialog>
        </>
    )
}

export default DeleteDocument
