import * as React from 'react'

import StatusIcon from '../StatusIcon'
import { STATUS } from '../../constants/constant'
import { clsxm } from '../../lib/clsxm'

type Keys = keyof typeof STATUS
type NotificationProps = {
    type?: typeof STATUS[Keys]
    title?: React.ReactNode
    customIcon?: JSX.Element
    className?: string
    children?: React.ReactNode
}

const Notification = ({
    customIcon,
    type,
    title,
    children,
    className,
}: NotificationProps) => (
    <div className={clsxm('flex', !children && 'items-center', className)}>
        {type && !customIcon ? (
            <div className='mr-3'>
                <StatusIcon type={type} />
            </div>
        ) : null}
        {customIcon && <div className='mr-3'>{customIcon}</div>}
        <div>
            {title && (
                <div
                    className={clsxm(
                        'font-semibold text-gray-800 dark:text-white',
                        children && 'mb-1'
                    )}
                >
                    {title}
                </div>
            )}
            <div>{children}</div>
        </div>
    </div>
)

export default Notification
