import { WorkSchedule, WorkSchedulePeriod } from '@viterbit/web-app/dataAccess/model/WorkSchedule.model'
import { IsNotEmpty } from '@viterbit/web-app/shared/utils/ClassValidators'

class WorkScheduleDetailValidator implements WorkSchedule {
    id: string
    @IsNotEmpty()
    name: string
    @IsNotEmpty()
    type: 'FLEXIBLE' | 'FIXED'
    periods: WorkSchedulePeriod[]
    mainPeriod: WorkSchedulePeriod
}

export default WorkScheduleDetailValidator
