import i18n from '@viterbit/web-app/i18n'
import * as React from 'react'
import { Controller } from 'react-hook-form'

import TabOption, { Tabs } from './Tabs'
import { DaysRequestsEnum } from '../types'

type DayTabsOptionsProps = {
    onChange: (value: DaysRequestsEnum) => void
    value: DaysRequestsEnum
    disabled?: boolean
}

const DayTabsOptions = React.forwardRef<HTMLDivElement, DayTabsOptionsProps>(
    ({ onChange, value, disabled }, ref) => (
        <Tabs className='mb-4' disabled={disabled} ref={ref}>
            <TabOption
                variant='left'
                selected={value === DaysRequestsEnum.HalfDay}
                onClick={() => onChange(DaysRequestsEnum.HalfDay)}
            >
                {i18n.t('leaves:dayTabs.halfDay')}
            </TabOption>
            <TabOption
                variant='middle'
                selected={value === DaysRequestsEnum.OneDay}
                onClick={() => onChange(DaysRequestsEnum.OneDay)}
            >
                {i18n.t('leaves:dayTabs.oneDay')}
            </TabOption>
            <TabOption
                variant='right'
                selected={value === DaysRequestsEnum.SeveralDays}
                onClick={() => onChange(DaysRequestsEnum.SeveralDays)}
            >
                {i18n.t('leaves:dayTabs.severalDays')}
            </TabOption>
        </Tabs>
    )
)

const DaysTabForm = ({ name, control, disabled }) => (
    <Controller
        control={control}
        name={name}
        render={({ field }) => (
            <DayTabsOptions disabled={disabled} {...field} />
        )}
    />
)

export default DaysTabForm
