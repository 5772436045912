import i18n from '@viterbit/web-app/i18n'
import FeedbackLayout from '@viterbit/web-app/shared/layout/FeedbackLayout'
import imageError from '@viterbit/web-assets/images/error_payment.svg'
import imageSuccess from '@viterbit/web-assets/images/success_payment.svg'
import { Link } from 'react-router-dom'
import Button from 'ui/src/components/Button'

import AccountSettingsIntegrationFeedbackMessage from './AccountSettingsIntegrationFeedbackMessage'
import useIntegrationFeedbackResponse from './useIntegrationFeedbackResponse'

const AccountSettingsIntegrationFeedbackView = () => {
    const {
        success,
        type,
        errorCode
    } = useIntegrationFeedbackResponse()

    const image = success ? imageSuccess : imageError
    const title = success
        ? i18n.t('integrations:feedback.success.title')
        : i18n.t('integrations:feedback.error.title')
    const subtitle = success
        ? i18n.t('integrations:feedback.success.subtitle')
        : i18n.t('integrations:feedback.error.subtitle', {
            errorCode: i18n.t(`integrations:feedback.error.code.${errorCode}`)
        })

    return (
        <FeedbackLayout>
            <AccountSettingsIntegrationFeedbackMessage
                type={type}
                title={title}
                subtitle={subtitle}
                image={{src: image, alt: 'integration feedback', position: 'left'}}
            >
                <Link to='/account/settings'>
                    <Button variant='solid' data-testid='account-settings-integration-feedback-view-button'>
                        {i18n.t('integrations:feedback.viewIntegrations')}
                    </Button>
                </Link>
            </AccountSettingsIntegrationFeedbackMessage>
        </FeedbackLayout>
    )

}

export default AccountSettingsIntegrationFeedbackView