/* eslint-disable @typescript-eslint/no-explicit-any */
import { LeaveReason } from '@viterbit/web-app/dataAccess/model/LeaveReason.model'
import { employeeDetail } from '@viterbit/web-app/dataAccess/service/employeeService'
import * as React from 'react'
import { GroupBase } from 'react-select'
import {
    FormSelectAsyncPaginate,
    FormSelectAsyncPaginateProps,
} from 'ui/src/components/Form'
import { FormBaseProps } from 'ui/src/components/Form/shared'

import ColorOption from '../display/ColorOption'
import { useNotification } from '../feedback/useNotification'
import { getErrorCode } from '../utils/getErrorCode'


type FormSelectEmployeeLeaveReasonProps<IsMulti extends boolean> =
    FormBaseProps &
        Omit<
            FormSelectAsyncPaginateProps<
                LeaveReason,
                IsMulti,
                GroupBase<LeaveReason>
            >,
            'loadOptions'
        > & {
            employeeId: string
        }

type LoadOptions = FormSelectAsyncPaginateProps<
    LeaveReason,
    false,
    GroupBase<LeaveReason>
>['loadOptions']

const FormSelectEmployeeLeaveReason = <IsMulti extends boolean>({
    employeeId,
    name,
    control,
    cacheOptions = true,
    isSearchable = false,
    ...rest
}: FormSelectEmployeeLeaveReasonProps<IsMulti>) => {
    const notification = useNotification()

    const loadOptions: LoadOptions = async () =>
        employeeDetail({
            id: employeeId,
        })
            .then(data => ({
                options: data?.leavePolicy?.reasons ?? [],
                hasMore: false,
            }))
            .catch((err) => {
                notification.push(
                    'danger',
                    'Fetching error',
                    getErrorCode(err) ??
                        'Error at fetch leave reasons of employee'
                )
                return {
                    options: [],
                    hasMore: false,
                }
            })
    return (            
        <div data-testid='leave-reason-id'>
            <FormSelectAsyncPaginate
                name={name}
                control={control}
                cacheOptions={cacheOptions}
                loadOptions={loadOptions}
                isSearchable={isSearchable}
                getOptionLabel={(x) =>
                (<ColorOption color={x.color}>{x.name}</ColorOption>) as any
                }
                getOptionValue={(x) => x.id}
                loadOptionsOnMenuOpen
                {...rest}
            />
        </div>
    )
}

export default FormSelectEmployeeLeaveReason
