import { Modal, ModalProps } from '@mantine/core'
import * as React from 'react'

type SizeType = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | `${number}%` | `${number}px` | 'auto' | `calc(${string})` // ? example "calc(100vw - 87px)"

export type DialogProps = Omit<Partial<ModalProps>, 'opened' | 'size'> & {
    open: boolean
    size?: SizeType
}

const Dialog = ({
    open,
    onClose = () => null,
    children,
    title,
    size = 'md',
    centered = true,
    ...rest
}: DialogProps) => (
    <Modal
        opened={open}
        onClose={onClose}
        title={
            typeof title === 'string'
                ? <div className='text-xl font-semibold'>{title}</div>
                : title
        }
        size={size}
        centered={centered}
        closeOnEscape={true}
        {...rest}
    >
        {children}
    </Modal>
)


export default Dialog
