import i18n from '@viterbit/web-app/i18n'
import LayoutHeading from 'ui/src/components/LayoutHeading'

import ComplaintLoginForm from './ComplaintLoginForm'
import { useComplaintOrganization } from '../useComplaintOrganization'

const ComplaintLoginView =  () => {
    const { data } = useComplaintOrganization()
    
    return (
        <>
            <LayoutHeading
                title={data?.name}
                subtitle={i18n.t('complaint:login.subtitle')}
            />
            <ComplaintLoginForm />
        </>
    )
}

export default ComplaintLoginView
