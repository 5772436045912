import { useCurrentEmployee } from '@viterbit/web-app/dataAccess/useCase/employee/useCurrentEmployee'
import i18n from '@viterbit/web-app/i18n'
import AsideCardInfo from '@viterbit/web-app/shared/display/AsideCardInfo'
import SimpleCard from '@viterbit/web-app/shared/display/SimpleCard'
import { clsxm } from '@viterbit/web-app/shared/utils/clsxm'
import * as React from 'react'

import EmployeePlanningList from './list/EmployeePlanningList'

type EmployeePlanningViewProps = {
    className?: string
}

const classes = {
    separator:'pb-4'
}

const EmployeePlanningView = ({ className }: EmployeePlanningViewProps) => {
    const employee = useCurrentEmployee()

    if (!employee) {
        return null
    }

    return (
        <>
            <div className={clsxm('space-y-8', className)}>
                <AsideCardInfo
                    title={i18n.t('employees:employee.planning.basicInfo.title')}
                    description={i18n.t('employees:employee.planning.basicInfo.description')}
                    direction='vertical'
                    className={classes.separator}
                >
                    <SimpleCard>
                        <EmployeePlanningList employee={employee} />
                    </SimpleCard>

                </AsideCardInfo>
            </div>

            
        </>
    )
}

export default EmployeePlanningView
