/* eslint-disable react/no-array-index-key */
import * as React from 'react'

import List from '.'
import { ListSkeletonProps } from './List.d'
import ListItemSkeleton from './ListItemSkeleton'

const ListSkeleton = ({ count = 3, ...rest }: ListSkeletonProps) => (
    <List>
        {Array(count)
            .fill(0)
            .map((_, index) => (
                <ListItemSkeleton key={index} {...rest} />
            ))}
    </List>
)

export default ListSkeleton
