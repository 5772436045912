import { NavLink } from '@mantine/core'
import { IconBolt } from '@tabler/icons-react'
import { Subscription } from '@viterbit/web-app/dataAccess/model/Subscription.model'
import i18n from '@viterbit/web-app/i18n'
import { formatHumanized } from '@viterbit/web-app/shared/utils/dateFormat'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-use'
import clsxm from 'ui/src/lib'

type SubscriptionBannerProps = {
    children?: React.ReactNode
    className?: string
    subscription: Subscription
}

const styles = {
    root: 'h-10 bg-grey-400 text-white text-sm flex items-center justify-center gap-4'
}

const SubscriptionBanner = (props: SubscriptionBannerProps) => {
    const { subscription, children, className } = props
    const location = useLocation()


    if (!subscription.shouldUpgrade) {
        return null
    }

    if (location.pathname?.startsWith('/billing/subscription')) {
        return null
    }

    const bannerMessage = subscription.status === 'IN_TRIAL' ? 'billing:bannerMessage.trial' : 'billing:bannerMessage.expired'

    return (
        <div className={clsxm(styles.root, className)} data-testid="subscription-banner">
            <span>
                {i18n.t(bannerMessage, {
                    date: formatHumanized(subscription.trialEndAt)
                })}
            </span>
            <Link to="/billing/subscription/list">
                <NavLink
                    variant="filled"
                    color='grey.3'
                    icon={<IconBolt />}
                    data-testid="subscription-banner-button"
                    label={i18n.t('billing:upgrade')}
                    sx={{
                        backgroundColor: 'transparent',
                        color: 'white',
                        fontWeight: 'bold',
                        '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.1)',
                            color: 'white',
                        },
                    }}
                />
            </Link>
            {children}
        </div>

    )
}

export default SubscriptionBanner
