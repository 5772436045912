

import leaveReasonMapper from '@viterbit/web-app/dataAccess/mapper/leaveReason.mapper'
import paginationMetaMapper from '@viterbit/web-app/dataAccess/mapper/paginationMeta.mapper'
import { queryClient } from '@viterbit/web-app/shared/lib/ReactQueryProvider'
import { Configuration, LeaveReasonApi } from 'viterbit-api-sdk'

import { commonConfig } from './config'
import { LEAVE_POLICY_QUERY_KEY, LEAVE_REASON_QUERY_KEY } from '../cacheKeys'


const api = new LeaveReasonApi(new Configuration(commonConfig))

export const leaveReasonInvalidate = () => {
    queryClient.invalidateQueries([LEAVE_REASON_QUERY_KEY])
    queryClient.invalidateQueries([LEAVE_POLICY_QUERY_KEY])
}
    

export const leaveReasonCreate = (body: Parameters<typeof api.createLeavesReason>[0]) =>
    api.createLeavesReason(body).then((data) => {
        leaveReasonInvalidate()
        return data
    })

export const leaveReasonUpdate = (body: Parameters<typeof api.updateLeaveReason>[0]) =>
    api.updateLeaveReason(body).then((data) => {
        leaveReasonInvalidate()
        return data
    })

export const leaveReasonDelete = (body: Parameters<typeof api.deleteLeaveReasons>[0]) =>
    api.deleteLeaveReasons(body).then((data) => {
        leaveReasonInvalidate()
        return data
    })

export const leaveReasonList = (body: Parameters<typeof api.listLeaveReasons>[0]) =>
    api.listLeaveReasons(body).then(({data, meta}) => ({
        meta: paginationMetaMapper(meta),
        data: data.map(leaveReasonMapper.toModel)
    }))

export const leaveReasonDetail = (body: Parameters<typeof api.findLeaveReasons>[0]) =>
    api.findLeaveReasons(body).then(leaveReasonMapper.toModel)


