import dayjs from 'dayjs'
import * as React from 'react'

import CalendarBase, {
    CalendarProps as BaseCalendarProps,
} from './CalendarBase'
import { DayProps } from './sharedTypes'
import { isSameDate } from './utils'

type CalendarProps = {
    value: Date[]
    singleDate?: boolean
} & Omit<BaseCalendarProps, 'range' | 'isDateFirstInRange'>

const RangeCalendar = React.forwardRef<HTMLDivElement, CalendarProps>(
    (props, ref) => {
        const {
            value,
            onChange,
            locale = 'en',
            singleDate = false,
            dayStyle,
            onMouseLeave,
            dateViewCount = 1,
            paginateBy = dateViewCount,
            ...rest
        } = props

        const [hoveredDay, setHoveredDay] = React.useState<
            null | Date | undefined
        >(null)
        const [pickedDate, setPickedDate] = React.useState<
            null | Date | undefined
        >(null)

        const setRangeDate = (date: Date): void => {
            if (pickedDate) {
                if (isSameDate(date, pickedDate) && !singleDate) {
                    setPickedDate(null)
                    setHoveredDay(null)
                    return
                }

                const result = [date, pickedDate]
                result.sort((a, b) => a.getTime() - b.getTime())
                onChange(result)
                setPickedDate(null)
                return
            }

            if (
                Array.isArray(value) &&
                value.length > 0 &&
                value[0] &&
                isSameDate(date, value[0]) &&
                !singleDate
            ) {
                setPickedDate(null)
                setHoveredDay(null)
                onChange([null, null])
                return
            }

            onChange([date, null])
            setPickedDate(date)
            return
        }

        const handleMouseLeave = (
            event: React.MouseEvent<HTMLButtonElement, MouseEvent>
        ) => {
            typeof onMouseLeave === 'function' && onMouseLeave(event)
            setHoveredDay(null)
        }

        const shouldHighlightDate = (
            date?: Date,
            modifiers?: DayProps
        ): boolean => {
            if (pickedDate && hoveredDay) {
                const result = [hoveredDay, pickedDate]
                result.sort((a, b) => a.getTime() - b.getTime())
                return Boolean(
                    !modifiers?.selected &&
                        dayjs(date).subtract(1, 'day').isBefore(result[1]) &&
                        dayjs(date).add(1, 'day').isAfter(result[0])
                )
            }
            return false
        }

        const isPickedDateFirstInRange = (
            date?: Date,
            modifiers?: DayProps
        ): boolean => {
            if (pickedDate && hoveredDay) {
                const result = [hoveredDay, pickedDate]
                result.sort((a, b) => a.getTime() - b.getTime())
                return Boolean(
                    modifiers?.selected && dayjs(date).isBefore(result[1])
                )
            }
            return false
        }

        const isPickedDateLastInRange = (
            date?: Date,
            modifiers?: DayProps
        ): boolean => {
            if (pickedDate && hoveredDay) {
                const result = [hoveredDay, pickedDate]
                result.sort((a, b) => a.getTime() - b.getTime())
                return Boolean(
                    modifiers?.selected && dayjs(date).isAfter(result[0])
                )
            }
            return false
        }

        return (
            <CalendarBase
                locale={locale}
                ref={ref}
                value={pickedDate}
                dayStyle={dayStyle}
                onMouseLeave={handleMouseLeave}
                onDayMouseEnter={(date) => setHoveredDay(date)}
                onChange={(date) => setRangeDate(date as Date)}
                dateViewCount={dateViewCount}
                paginateBy={paginateBy}
                hideOutOfMonthDates={dateViewCount > 1}
                range={value}
                isDateInRange={shouldHighlightDate}
                isDateFirstInRange={isPickedDateFirstInRange}
                isDateLastInRange={isPickedDateLastInRange}
                {...rest}
            />
        )
    }
)

export default RangeCalendar
