/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FindOrganizationQueryResponse } from './FindOrganizationQueryResponse';
import {
    FindOrganizationQueryResponseFromJSON,
    FindOrganizationQueryResponseFromJSONTyped,
    FindOrganizationQueryResponseToJSON,
} from './FindOrganizationQueryResponse';

/**
 * 
 * @export
 * @interface FindCompanyQueryResponse
 */
export interface FindCompanyQueryResponse {
    /**
     * 
     * @type {string}
     * @memberof FindCompanyQueryResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof FindCompanyQueryResponse
     */
    name: string;
    /**
     * 
     * @type {FindOrganizationQueryResponse}
     * @memberof FindCompanyQueryResponse
     */
    organization?: FindOrganizationQueryResponse;
}

/**
 * Check if a given object implements the FindCompanyQueryResponse interface.
 */
export function instanceOfFindCompanyQueryResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function FindCompanyQueryResponseFromJSON(json: any): FindCompanyQueryResponse {
    return FindCompanyQueryResponseFromJSONTyped(json, false);
}

export function FindCompanyQueryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindCompanyQueryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'organization': !exists(json, 'organization') ? undefined : FindOrganizationQueryResponseFromJSON(json['organization']),
    };
}

export function FindCompanyQueryResponseToJSON(value?: FindCompanyQueryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'organization': FindOrganizationQueryResponseToJSON(value.organization),
    };
}

