import { IsNotEmpty, IsOptional, IsString, IsStrongPassword } from '@viterbit/web-app/shared/utils/ClassValidators'
import { Match } from '@viterbit/web-app/shared/utils/match.decorator'

import { ProfilePasswordSettingsFormFields } from './AccountSettingsPasswordForm'
import { AccountSettingsPersonalFormFields } from './AccountSettingsPersonalForm'

class AccountSettingsPasswordValidator  implements ProfilePasswordSettingsFormFields {
    id: string
    @IsNotEmpty()
    @IsStrongPassword()
    password: string

    @IsString()
    @Match('password')
    confirmPassword: string
}


class AccountSettingsPersonalValidator  implements AccountSettingsPersonalFormFields {
    id: string
    @IsString()
    @IsOptional()
    locale: string
    @IsString()
    @IsOptional()
    timezone: string
}

export { AccountSettingsPasswordValidator, AccountSettingsPersonalValidator }