/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { IMapper } from './__common'
import billingInfoMapper from './billingInfo.mapper'
import fileMapper from './file.mapper'
import { OrganizationResponse } from '../dto/organization'
import { Organization } from '../model/Organization.model'
import { Subscription, SubscriptionStatus } from '../model/Subscription.model'
import getPublicPath from '../../shared/utils/getPublicPath'
import throwException from '../../shared/utils/throwException'

const PAID_STATUS: SubscriptionStatus[] = ['ACTIVE']

const mapSubscription = (dto: OrganizationResponse): Subscription => {
    if (!dto) {
        throwException('Subscription is not defined')
    }

    const {
        customerId,
        id,
        status,
        slots,
        nextBillingAt,
        amount,
        billingPeriod,
        billingPeriodUnit,
        unitPrice,
        currency,
        trialEnd,
        validPaymentMethod,
    } = dto.subscription!
    const { prices = [] } = dto

    if (!SubscriptionStatus.includes(status as any)) {
        throwException(`Subscription status ${status} is not valid`)
    }

    return {
        id,
        customerId,
        slots: slots as any as number,
        unitPrice: unitPrice / 100,
        currency: currency,
        totalAmount: amount / 100,
        trialEndAt: trialEnd,
        nextBillingAt: nextBillingAt!,
        status: status as SubscriptionStatus,
        validPaymentMethod: validPaymentMethod,
        period: billingPeriodUnit,
        periodUnit: billingPeriod as any,
        shouldUpgrade: !validPaymentMethod,
        prices: prices.map((price) => ({
            id: price.id,
            currency: price.currency,
            name: price.name,
            period: price.period,
            periodUnit: price.periodUnit as any,
            price: price.price / 100,
            paymentPageUrl: price.paymentPage,
        })),
    }
}

const organizationMapper: IMapper<Organization, OrganizationResponse> = {
    toModel: (dto) => {
        const organization: Organization = {
            id: dto.id,
            name: dto.name,
            terms: dto.terms,
            billingInfo:
                dto.billingInfo && billingInfoMapper.toModel(dto.billingInfo),
            domain: dto.domain,
            subscription: dto.subscription && mapSubscription(dto),
            logo: dto.logo ? fileMapper.toModel(dto.logo): null,
        }

        organization.publicPath = getPublicPath(organization)

        return organization
    },
    toDto: (model) => ({
        id: model.id,
        name: model.name,
        billingInfo:
            model.billingInfo && billingInfoMapper.toDto(model.billingInfo),
    }),
}

export default organizationMapper
