/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Button } from '@mantine/core'
import { Complaint } from '@viterbit/web-app/dataAccess/model/Complaint.model'
import { useComplaintPrivateUpdate } from '@viterbit/web-app/dataAccess/useCase/complaint/useComplaintPrivateUpdate'
import i18n from '@viterbit/web-app/i18n'
import * as React from 'react'
import ButtonSkeleton from 'ui/src/components/Button/ButtonSkeleton'


type ComplaintPrivateChangeStatusActionProps = {
    complaint?: Complaint
}

const styles = {
    root: ''
}

const NEXT_STATUS_MAP = {
    CREATED: 'RECEIVED',
    RECEIVED: 'CLOSED'
}

const ComplaintPrivateChangeStatusAction = (props: ComplaintPrivateChangeStatusActionProps) => {
    const { complaint } = props

    const { isLoading, mutate } = useComplaintPrivateUpdate()

    if (!complaint) {
        return <ButtonSkeleton />
    }

    const nextStatus = NEXT_STATUS_MAP[complaint!.status!]

    if (!nextStatus) {
        return null
    }

    return (
        <Button
            data-testid='complaint-change-status'
            loading={isLoading}
            onClick={() => mutate({
                id: complaint!.id,
            })}
        >
            {i18n.t(`complaint:detail.statusButton.${nextStatus}`)}
        </Button>
    )
}

export default ComplaintPrivateChangeStatusAction
