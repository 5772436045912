/* eslint-disable @typescript-eslint/no-explicit-any */
import { createEmotionCache, MantineProvider as Provider } from '@mantine/core'
import { DatesProvider } from '@mantine/dates'
import theme from '@viterbit/theme/index.json'
import i18n from '@viterbit/web-app/i18n'
import * as React from 'react'
import 'dayjs/locale/en'
import 'dayjs/locale/es'

const cache = createEmotionCache({ key: 'mantine', prepend: false })

const colorShades = Object.entries(theme.color.shades).reduce((output, [colorName, shadeObject]) => {
    output[colorName] = Object.values(shadeObject as any)
    return output
}, {})

/**
 * @see https://mantine.dev/theming/default-props/ to configure components
 * @param param0 
 * @returns 
 */
const MantineProvider = ({ children }: { children: React.ReactNode }) => (
    <DatesProvider
        settings={{
            locale: i18n.resolvedLanguage,
            firstDayOfWeek: 1,
            weekendDays: [0]
        }}
    >
        <Provider
            emotionCache={cache}
            theme={{
                white: theme.color.plain.white,
                black: theme.color.plain.black,

                colors: colorShades,
                primaryColor: 'primary',
                primaryShade: { light: 6, dark: 8 },

                fontFamily: theme.fontFamily.primary,

                fontSizes: {
                    ...Object.entries(theme.fontSize).reduce((output, [key, value]) => {
                        output[key] = value[0]
                        return output
                    }, {} as any),
                },

                defaultRadius: 'sm',

                radius: {
                    xs: '2px',
                    sm: '4px',
                    md: '6px',
                    lg: '8px',
                    xl: '99999px',
                },

                components: {
                    Button: {
                        defaultProps: {
                            radius: 'sm',
                        },
                        sizes: {
                            xs: (theme) => ({
                                root: {
                                    '&': { height: 32, fontSize: theme.fontSizes.xs },
                                },                                
                            }),
                            sm: (theme) => ({
                                root: {
                                    '&': { height: 39 },
                                },
                            }),
                            md: (theme) => ({
                                root: {
                                    '&': { height: 39 },
                                }
                            }),
                        },
                    },

                    Input: {
                        styles: {
                            input: {
                                '&': {
                                    height: 39,
                                    borderColor: theme.color.shades.grey[200],
                                },
                            },
                        },
                    },

                    Tooltip: {
                        variants: {
                            white: (theme) => ({
                                tooltip: {
                                    backgroundColor: 'white',
                                    boxShadow: theme.shadows.xl,
                                    color: theme.colors.dark,
                                    padding: '10px 20px',
                                    rounded: 'md',
                                    border: `2px solid ${theme.colors.gray[0]}`,
                                    ...theme.fn.hover({ backgroundColor: theme.colors.red[8] }),
                                },
                            }),
                        },
                        defaultProps: {
                            withArrow: true,
                            variant: 'white',
                        }
                    },

                    DatePicker: {
                        styles: {
                            day: {
                                '&[data-weekend]:not([data-outside]):not([data-selected])': { color: 'inherit', },
                            },
                        },
                    },

                    Indicator: {
                        styles: {
                            root: {
                                '&': { zIndex: 10 },
                            },
                        },
                    },

                    Popover: {
                        defaultProps: {
                            withinPortal: true
                        }

                    },

                    RichTextEditor: {
                        styles: {
                            root: {
                                '&': {
                                    borderColor: theme.color.shades.grey[200],
                                },
                            },
                            content: {
                                '&': {
                                    minHeight: 400,
                                },
                            },
                            toolbar: {
                                '&': {
                                    borderColor: theme.color.shades.grey[200],
                                },
                            },
                        },
                    },

                    Modal: {
                        defaultProps: {
                            overlayProps: {
                                color: '#a0a0a0',
                                opacity: 0.3,
                                blur: 3,
                            }
                        },
                    },

                    Drawer: {
                        defaultProps: {
                            overlayProps: {
                                color: '#a0a0a0',
                                opacity: 0.3,
                                blur: 3,
                            },
                        },
                    },

                    Day: {
                        styles: {
                            day: {
                                '&[data-weekend]:not([data-outside]):not([data-selected])': { color: 'inherit', },
                            },
                        },
                    },

                    Table: {
                        styles: {
                            root: {
                                '& .mantine-TableHeadCell-Content-Labels': { color: theme.color.shades.grey[500], fontWeight: 500 },
                            }
                        }
                    },

                    Tabs: {
                        styles: {
                            tab: {
                                height: 52,
                                color: theme.color.shades.grey[500],
                            },
                        },
                    },
                },

                shadows: {
                    ...theme.boxShadow
                }
            }}
        >
            {children}
        </Provider>
    </DatesProvider>
)

export default MantineProvider