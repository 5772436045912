import { classValidatorResolver } from '@hookform/resolvers/class-validator'
import { JobPositionLevel } from '@viterbit/web-app/dataAccess/model/JobPositionLevel.model'
import { jobPositionLevelUpdate } from '@viterbit/web-app/dataAccess/service/jobPositionLevelService'
import i18n from '@viterbit/web-app/i18n'
import Form from '@viterbit/web-app/shared/form/Form'
import SubmitButton from '@viterbit/web-app/shared/form/SubmitButton'
import { MouseEventHandler } from 'react'
import Button from 'ui/src/components/Button'

import { JobPositionLevelDetailView } from './JobPositionLevelDetailFields'
import JobPositionLevelDetailForm from './JobPositionLevelDetailForm'
import DetailsJobPositionLevelValidator from './JobPositionLevelDetailValidator'

type DetailsJobPositionLevelViewProps = {
    level?: JobPositionLevel
    onCancel: MouseEventHandler<HTMLButtonElement>
    onSuccess: any
}

const resolver = classValidatorResolver(DetailsJobPositionLevelValidator)

const DetailsJobPositionLevelView = ({ 
    level: details,
    onCancel,
    onSuccess
}: DetailsJobPositionLevelViewProps) => {

    if (!details) {
        return null
    }

    return (
        <Form<JobPositionLevelDetailView>
            formConfig={{
                resolver,
                mode: 'all',
                defaultValues: details,
            }}
            mutationConfig={{
                onSuccess: () => onSuccess(),
                mutationFn: (data) => jobPositionLevelUpdate({id:details.id, updateJobPositionLevelCommand: data}),
            }}
        >
            {(form) => (
                <>
                    <JobPositionLevelDetailForm form={form} />
                    <Button onClick={onCancel}> {i18n.t('buttons.cancel')}</Button>
                    <SubmitButton>{i18n.t('buttons.save')} </SubmitButton>
                </>
            )}
        </Form>
    )
}

export default DetailsJobPositionLevelView
