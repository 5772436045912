import { DayConsumedPerReasonMap } from '@viterbit/web-app/dataAccess/model/Summary.model'
import * as React from 'react'
import clsxm from 'ui/src/lib'

import SummaryTotalsItem from './SummaryTotalsItem'

type SummaryTotalsProps = {
    children?: React.ReactNode
    className?: string
    daysConsumedPerReason: DayConsumedPerReasonMap
}

const SummaryTotals = ({
    daysConsumedPerReason,
    children,
    className,
}: SummaryTotalsProps) => (
    <div className={clsxm('px-2.5 min-w-[360px]', className)}>
        {Object.entries(daysConsumedPerReason).map(([key, value]) => (
            <SummaryTotalsItem key={key} dayConsumedPerReason={value} />
        ))}
        {children}
    </div>
)

export default SummaryTotals
