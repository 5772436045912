/* eslint-disable @typescript-eslint/no-explicit-any */
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat.js'
import * as React from 'react'
import { usePopper } from 'react-popper'
dayjs.extend(localizedFormat)

import { IconCalendar } from '@tabler/icons-react'
import { useClickAway } from 'react-use'

import CloseButton from '../CloseButton'
import Input from '../Input'
import useMergedRef from '../../hooks/useMergedRef'

const BasePicker = React.forwardRef((props: any, ref) => {
    const {
        inputtable,
        className,
        clearButton,
        children,
        form,
        field,
        size,
        inputLabel,
        inputPrefix,
        inputSuffix = <IconCalendar className='text-lg' />,
        clearable = true,
        placeholder,
        name,
        dropdownOpened,
        setDropdownOpened,
        onDropdownOpen,
        onDropdownClose,
        onBlur,
        onFocus,
        onChange,
        onKeyDown,
        onClear,
        disabled,
        type,
    } = props

    const toggleDropdown = () => {
        setDropdownOpened(!dropdownOpened)
        !dropdownOpened ? onDropdownOpen?.() : onDropdownClose?.()
    }

    const handleInputClick = () =>
        !inputtable && !dropdownOpened ? toggleDropdown() : openDropdown()

    const closeDropdown = () => {
        setDropdownOpened(false)
        onDropdownClose?.()
    }

    const suffixIconSlot = clearable ? (
        <div onClick={onClear}>
            {clearButton || <CloseButton className='text-base' />}
        </div>
    ) : (
        <>{inputSuffix}</>
    )

    const openDropdown = () => {
        setDropdownOpened(true)
        onDropdownOpen?.()
    }

    const handleKeyDown = (event: any) => {
        typeof onKeyDown === 'function' && onKeyDown(event)
        if ((event.key === 'Space' || event.key === 'Enter') && !inputtable) {
            event.preventDefault()
            openDropdown()
        }
    }

    const handleInputBlur = (event: any) => {
        typeof onBlur === 'function' && onBlur(event)
        if (inputtable) {
            closeDropdown()
        }
    }

    const handleInputFocus = (event: any) => {
        typeof onFocus === 'function' && onFocus(event)
        if (inputtable) {
            openDropdown()
        }
    }

    const referenceRef = React.useRef(null)
    const popperRef = React.useRef(null)

    const { styles, attributes, forceUpdate } = usePopper(
        referenceRef.current,
        popperRef.current,
        {
            placement: 'bottom-start',
            modifiers: [
                {
                    name: 'offset',
                    enabled: true,
                    options: {
                        offset: [0, 10],
                    },
                },
            ],
        }
    )

    useClickAway(popperRef, closeDropdown)
    React.useEffect(() => {
        dropdownOpened && forceUpdate?.()
    }, [dropdownOpened])

    return (
        <>
            <Input
                form={form}
                field={field}
                className={className}
                placeholder={placeholder}
                ref={useMergedRef(ref, referenceRef)}
                size={size}
                name={name}
                value={inputLabel}
                readOnly={!inputtable}
                suffix={suffixIconSlot}
                prefix={inputPrefix}
                onClick={handleInputClick}
                onKeyDown={handleKeyDown}
                onBlur={handleInputBlur}
                onFocus={handleInputFocus}
                onChange={onChange}
                autoComplete='off'
                type={type}
                disabled={disabled}
            />
            <div
                className='z-40 rounded-lg'
                ref={popperRef}
                style={styles.popper}
                {...attributes.popper}
            >
                {dropdownOpened && (
                    <div className='p-4 bg-white rounded-lg shadow-xl dark:bg-gray-700 dark:shadow-2xl'>
                        {children}
                    </div>
                )}
            </div>
        </>
    )
})

export default BasePicker
