import ColorOption from './ColorOption'

const colorOptions = [
    {
        label: <ColorOption value='#00B8D9'>Ocean</ColorOption>,
        value: '#00B8D9',
    },
    {
        label: <ColorOption value='#0052CC'>Blue</ColorOption>,
        value: '#0052CC',
    },
    {
        label: <ColorOption value='#5243AA'>Purple</ColorOption>,
        value: '#5243AA',
    },
    {
        label: <ColorOption value='#FF5630'>Red</ColorOption>,
        value: '#FF5630',
    },
    {
        label: <ColorOption value='#FF8B00'>Orange</ColorOption>,
        value: '#FF8B00',
    },
    {
        label: <ColorOption value='#FFC400'>Yellow</ColorOption>,
        value: '#FFC400',
    },
    {
        label: <ColorOption value='#36B37E'>Green</ColorOption>,
        value: '#36B37E',
    },
    {
        label: <ColorOption value='#00875A'>Forest</ColorOption>,
        value: '#00875A',
    },
    {
        label: <ColorOption value='#253858'>Slate</ColorOption>,
        value: '#253858',
    },
    {
        label: <ColorOption value='#666666'>Silver</ColorOption>,
        value: '#666666',
    },
]

export default colorOptions