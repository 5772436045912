/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from 'react'
import { Portal } from 'react-portal'

import EmbeddedContentContext from './EmbeddedContentContext'

type EmbeddedContentProps = {
    children: React.ReactNode
}

const EmbeddedContent = ({ children }: EmbeddedContentProps) => {
    // ! This is a hack to make sure the DOM is loaded before rendering the portal to avoid hydration errors
    const [isFirstMount, setIsFirstMount] = React.useState(false)

    const context = React.useContext(EmbeddedContentContext)

    React.useEffect(() => {
        setIsFirstMount(true)
    }, [])

    if (!context) return null

    return (
        <>
            {isFirstMount && (
                <Portal node={context.container}>{children}</Portal>
            )}
        </>
    )
}

export default EmbeddedContent
