import * as Sentry from '@sentry/react'
import illustration from '@viterbit/web-assets/images/error-500.svg'
import React from 'react'
import { Link, useRouteError } from 'react-router-dom'

const tw = {
    container: 'flex flex-col justify-center px-4',
    contentContainer: 'flex flex-col md:flex-row md:items-center min-h-[60vh] max-w-screen-xl',
    imageContainer: 'w-full max-w-xs md:w-1/2 lg:mr-20 mb-4 md:mb-0 md:max-w-[300px]',
    image: 'w-full',
    textContainer: 'text-center md:text-left md:w-1/2',
    title: 'text-2xl font-bold text-black mb-2',
    text: 'text-lg font-medium text-gray-700 mb-4',
    button:
    'px-4 py-2 rounded-md text-base font-medium text-white bg-blue-600 hover:bg-blue-500',
}

const Error500 = () => {
    const error = useRouteError()

    React.useEffect(() => {
        Sentry.captureException(error)
    }, [])
    
    return (
        <div className={tw.container}>
            <div className={tw.contentContainer}>
                <div className={tw.imageContainer}>
                    <img
                        className={tw.image}
                        src={illustration}
                        alt="Ilustración de página no encontrada"
                    />
                </div>
        
                <div className={tw.textContainer}>
                    <h1 className={tw.title}>Huston!, hemos tenido un problema</h1>
                    <p className={tw.text}>
            Lo sentimos, pero en este momento no podemos mostrarte la información.
                    </p>

                    <Link to="/">
                        <button className={tw.button}>Ir a la página principal</button>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Error500
