import { TimeInput, TimeInputProps } from '@mantine/dates'
import { IconClock } from '@tabler/icons-react'
import * as React from 'react'
import { Controller } from 'react-hook-form'

import ControlledFormControl from './ControlledFormControl'
import { FormControlBaseProps } from './FormControl'
import { FormBaseProps } from './shared'

type FormTimePickerProps = FormBaseProps &
    TimeInputProps &
    FormBaseProps &
    FormControlBaseProps & {
        hideIcon?: boolean
    }

const FormTimePicker = ({
    name,
    control,
    asterisk,
    label,
    extra,
    className,
    rules,
    hideIcon,
    ...rest
}: FormTimePickerProps) => (
    <Controller
        control={control}
        name={name}
        key={name}
        rules={rules}
        render={({ field }) => (
            <ControlledFormControl
                control={control}
                label={label}
                asterisk={asterisk}
                extra={extra}
                className={className}
                {...field}
            >
                <TimeInput 
                    icon={!hideIcon && <IconClock size={16} />}
                    {...rest} {...field}
                />
            </ControlledFormControl>
        )}
    />
)

export default FormTimePicker
