
import * as React from 'react'


type JobPositionLevelListProps = {
    children: React.ReactNode
    className?: string
}

const JobPositionLevelList = ({
    children,
    className = '',
}: JobPositionLevelListProps) => (
    <nav className={`${className} `} aria-label='Breadcrumb'>
        <div className='flex flex-col gap-4'>{children}</div>

    </nav>
)

export default JobPositionLevelList
