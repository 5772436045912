
import { classValidatorResolver } from '@hookform/resolvers/class-validator'
import { accountLogin, accountSignUp } from '@viterbit/web-app/dataAccess/service/authService'
import Form from '@viterbit/web-app/shared/form/Form'
import SubmitButton from '@viterbit/web-app/shared/form/SubmitButton'
import { TIME_ZONE } from '@viterbit/web-app/shared/utils/timeZone'
import useJwtToken, { JwtTokenHook } from '@viterbit/web-app/shared/utils/useJwtToken'
import useLocale from '@viterbit/web-app/shared/utils/useLocale'
import { JwtPayload } from 'jwt-decode'
import { useNavigate } from 'react-router-dom'
import Alert from 'ui/src/components/Alert'

import SignUpRedeemForm from './SignUpRedeemForm'
import { SignUpRedeemFields } from './SignUpRedeemShared'
import SignUpRedeemValidator from './SignUpRedeemValidator'

const resolver = classValidatorResolver(SignUpRedeemValidator)

interface CustomJWT extends JwtPayload {
    email: string
    organizationName: string
}

interface CustomJwtTokenHook extends JwtTokenHook {
    data: CustomJWT
}

type SignUpRedeemDetailProps = {
    token: string
    initialData?: CustomJWT
    country: string
}


const SignUpRedeemDetail = ({ token, initialData, country }: SignUpRedeemDetailProps) => {

    const { error, isExpired } = useJwtToken(
        token,
        initialData
    ) as CustomJwtTokenHook
    const locale = useLocale()
    const navigate = useNavigate()

    // TODO: (improve) bad request feedback
    if (error) {
        return (
            <Alert title='Error' type='danger'>
                The request is invalid
            </Alert>
        )
    }

    // TODO: (improve) token expired feedback
    if (isExpired) {
        return (
            <Alert title='Error' type='danger'>
                The request is expired
            </Alert>
        )
    }

    return (
        <>
            <Form<SignUpRedeemFields>
                withTransition
                mutationConfig={{
                    mutationKey: ['OnBoarding'],
                    mutationFn: (data) =>  accountSignUp({signUpCommand: {...data, token}}),
                    onSuccess:  async (data, variables) => accountLogin({logInCommand: 
                            {
                                password: variables.password,
                                username: initialData!.email,
                            }}).then(() => {
                        navigate('/')
                    })
                }}
                formConfig={{
                    resolver,
                    mode: 'all',
                    defaultValues: {
                        organizationName: initialData!.organizationName,
                        timezone: TIME_ZONE,
                        locale: window.navigator.language,
                        country
                    },
                }}
            >
                {(form) => (
                    <>
                        <SignUpRedeemForm form={form} locale={locale} />
                        <SubmitButton>Confirm</SubmitButton>
                    </>
                )}
            </Form>
        </>
    )
}

export default SignUpRedeemDetail
