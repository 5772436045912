/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ListRoleQueryResponse,
} from '../models/index';
import {
    ListRoleQueryResponseFromJSON,
    ListRoleQueryResponseToJSON,
} from '../models/index';

export interface ListRoleRequest {
    page?: number;
}

/**
 * 
 */
export class RoleApi extends runtime.BaseAPI {

    /**
     * Fetch all role for the given employee
     * List roles
     */
    async listRoleRaw(requestParameters: ListRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListRoleQueryResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core/roles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListRoleQueryResponseFromJSON(jsonValue));
    }

    /**
     * Fetch all role for the given employee
     * List roles
     */
    async listRole(requestParameters: ListRoleRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListRoleQueryResponse> {
        const response = await this.listRoleRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
