
import { useQuery } from '@tanstack/react-query'
import { CONTENT_CHANGED_QUERY_KEY } from '@viterbit/web-app/dataAccess/cacheKeys'
import { contentChangedDetail } from '@viterbit/web-app/dataAccess/service/contentChangedService'
import gitInfo from '@viterbit/web-app/shared/utils/gitInfo.json'

export const useContentChangedDetail = () =>
    useQuery({
        queryFn: () => contentChangedDetail(), 
        queryKey: [CONTENT_CHANGED_QUERY_KEY, 'detail'],
        refetchInterval: 1000 * 60,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
        refetchIntervalInBackground: true,
        initialData: gitInfo,
    })

