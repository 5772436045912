import * as React from 'react'

import Spinner from '../Spinner'
import { clsxm } from '../../lib/clsxm'

type Props = {
    className?: string
    isLoading?: boolean
    spinnerClass?: string
    type?: 'default' | 'cover'
    customLoader?: React.ReactNode
    children?: React.ReactNode
}

const DefaultLoading = ({
    isLoading,
    children,
    spinnerClass,
    className,
    customLoader,
}: Props) =>
    isLoading ? (
        <div
            className={clsxm(
                !customLoader && 'flex h-full items-center justify-center',
                className
            )}
        >
            {customLoader ? (
                <>{customLoader}</>
            ) : (
                <Spinner className={spinnerClass} isSpinning />
            )}
        </div>
    ) : (
        <>{children}</>
    )

const CoveredLoading = ({
    isLoading,
    children,
    spinnerClass,
    className,
    customLoader,
}: Props) => (
    <div className={clsxm(isLoading ? 'relative' : '', className)}>
        {children}
        {isLoading && (
            <div className='absolute inset-0 w-full h-full bg-white bg-opacity-50 dark:bg-gray-800 dark:bg-opacity-60' />
        )}
        {isLoading && (
            <div className='absolute z-10 transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2'>
                {customLoader ? (
                    <>{customLoader}</>
                ) : (
                    <Spinner className={spinnerClass} isSpinning />
                )}
            </div>
        )}
    </div>
)

const Loading = (props: Props) => {
    switch (props.type) {
    case 'cover':
        return <CoveredLoading {...props} />
    case 'default':
    default:
        return <DefaultLoading {...props} />
    }
}

export default Loading
