import { IFile } from './sharedTypes'
import createUID from '../../utils/createUid'

export const fileToIFile = (file: File): IFile => ({
    id: createUID(),
    name: file.name,
    size: file.size,
    kind: file.type,
    url: URL.createObjectURL(file),
    _meta: {
        file,
        status: 'pending',
    }
})
