/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionIcon, Pagination } from '@mantine/core'
import { IconPlaylistX, IconTrash } from '@tabler/icons-react'
import theme from '@viterbit/theme/index.json'
import { Employee } from '@viterbit/web-app/dataAccess/model/Employee.model'
import { PaginatedTeamMembers } from '@viterbit/web-app/dataAccess/model/Team.model'
import EmployeeItem from '@viterbit/web-app/feature/employee/item/EmployeeItem'
import i18n from '@viterbit/web-app/i18n'
import EmptyState from '@viterbit/web-app/shared/display/EmptyState/EmptyState'
import { MantineReactTable,MRT_ColumnDef,MRT_PaginationState } from 'mantine-react-table'
import { MRT_Localization_ES } from 'mantine-react-table/locales/es'
import * as React from 'react'

type TeamMemberListProps = {
    className?: string
    teamId: string
    members?: PaginatedTeamMembers
    pagination: MRT_PaginationState
    onDeleteItem: (employee: Employee) => void
    loading: boolean
    onPaginationChange: (pagination: MRT_PaginationState) => void
}

const TeamMemberList = ({
    pagination,
    members,
    loading,
    onDeleteItem,
    onPaginationChange,
}: TeamMemberListProps) => { 

    const columns: MRT_ColumnDef<Employee>[] = [
        {
            accessorKey: 'name',
            Cell: ({ row }) => <EmployeeItem employee={row.original} />,
            header: i18n.t('employees:teams.members.headerName')
        },
        {
            accessorKey: 'email',
            header: i18n.t('employees:teams.members.headerEmail')
        },
        {
            accessorKey: 'account.id',
            header: '',
            mantineTableBodyCellProps: {
                align: 'right',
            },
            size: 10,
            Cell: ({ row }) => (
                <ActionIcon onClick={() => onDeleteItem(row.original) as any}>
                    <IconTrash size={20} />
                </ActionIcon>
            )
        },
    ]

    if (!loading && members?.data.length === 0) {
        return (
            <EmptyState
                image={<IconPlaylistX size={28} stroke={1} />}
                className='pt-2 pb-4 border border-gray-200 rounded-md gap-y-0.5 bg-grey-50'
                title={i18n.t('employees:teams.members.emptyTitle')}
                description={i18n.t('employees:teams.members.emptyDescription')}
            />
        )
    }

    return(
        <MantineReactTable<Employee>
            localization={MRT_Localization_ES}
            data={members?.data || []}
            rowCount={members?.meta.total || 0}
            onPaginationChange={onPaginationChange}
            columns={columns}
            state={{
                showSkeletons: loading,
            }}
            renderBottomToolbarCustomActions={() => (
                members?.meta.pageCount && (
                    <div className='flex justify-end w-full h-10 pr-2'>
                        <Pagination
                            size="sm"
                            total={members?.meta.pageCount || 0}
                            defaultValue={pagination.pageIndex}
                            onChange={(page) => onPaginationChange({ ...pagination, pageIndex: page })}
                        />
                    </div>
                )
            )}

            paginateExpandedRows={false}
            enablePagination={false}
            enableRowActions={false}
            enableColumnActions={false}
            enableColumnFilters={false}
            enableSorting={false}
            enableTopToolbar={false}
            enableBottomToolbar={(members?.meta.pageCount || 0) > 1}

            mantineTableProps={{
                highlightOnHover: false,
                verticalSpacing: 'xs',
                sx: {

                    '& thead tr': { backgroundColor: theme.color.shades.grey[50] },
                }
            }}
            mantinePaperProps={{
                sx: {
                    boxShadow: 'none',
                },
            }}

        />
    )
}

export default TeamMemberList
