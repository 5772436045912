/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { complaintPrivateRecordCreate } from '@viterbit/web-app/dataAccess/service/complaintService'

import ComplaintPrivateChangeStatusAction from './ComplaintPrivateChangeStatusAction'
import { useComplaintDetail } from '../useComplaintDetail'
import ComplaintDetailLayout from '../../ComplaintDetailLayout'

const ComplaintPrivateDetailView = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const { data, isInitialLoading } = useComplaintDetail()

    return (
        <ComplaintDetailLayout
            key={data?.id}
            complaint={data}
            isLoading={isInitialLoading}
            actions={<ComplaintPrivateChangeStatusAction complaint={data} />}
            onSendMessage={payload => complaintPrivateRecordCreate({
                employeeCreateComplaintRecordCommand: {
                    complaintId: data!.id!,
                    message: payload.message,
                    fileIds: payload.fileIds,
                }
            })}
        />
    )
}

export default ComplaintPrivateDetailView
