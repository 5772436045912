/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { useJobPositionDetail } from '@viterbit/web-app/dataAccess/useCase/jobPosition/useJobPositionDetail'
import { useParams } from 'react-router-dom'

import JobPositionDetailForm from './JobPositionDetailForm'
import JobPositionLevelDeleteAction from '../level/delete/JobPositionLevelDeleteAction'

const DetailsJobPositionsView = () => {

    const { jobPositionId } = useParams()

    const { data: position, isLoading } = useJobPositionDetail({
        query: { id: jobPositionId! },
        enabled: !!jobPositionId
    })

    if (isLoading || !position){
        return null
    }

    return (
        <>
            <JobPositionDetailForm key={position.id} position={position}/>
            <JobPositionLevelDeleteAction position={position} />
        </>
    )
}

export default DetailsJobPositionsView
