/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmployeeContractInformation
 */
export interface EmployeeContractInformation {
    /**
     * Employee trial period
     * @type {boolean}
     * @memberof EmployeeContractInformation
     */
    trialPeriod?: boolean | null;
    /**
     * Employee working day type
     * @type {string}
     * @memberof EmployeeContractInformation
     */
    workingDayType?: EmployeeContractInformationWorkingDayTypeEnum | null;
    /**
     * Employee working modality
     * @type {string}
     * @memberof EmployeeContractInformation
     */
    workingModality?: EmployeeContractInformationWorkingModalityEnum | null;
    /**
     * Employee hours
     * @type {number}
     * @memberof EmployeeContractInformation
     */
    hours?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof EmployeeContractInformation
     */
    schedule?: Array<EmployeeContractInformationScheduleEnum>;
    /**
     * Employee hours
     * @type {string}
     * @memberof EmployeeContractInformation
     */
    contractHours?: EmployeeContractInformationContractHoursEnum | null;
    /**
     * Employee state
     * @type {string}
     * @memberof EmployeeContractInformation
     */
    state?: string | null;
    /**
     * Employee salary
     * @type {number}
     * @memberof EmployeeContractInformation
     */
    salary?: number | null;
    /**
     * Employee salary range
     * @type {string}
     * @memberof EmployeeContractInformation
     */
    salaryRange?: string | null;
    /**
     * Employee contract type
     * @type {string}
     * @memberof EmployeeContractInformation
     */
    contractType?: string | null;
    /**
     * Employee job position alias
     * @type {string}
     * @memberof EmployeeContractInformation
     */
    jobPositionAlias?: string | null;
    /**
     * Employee startDate
     * @type {string}
     * @memberof EmployeeContractInformation
     */
    startDate: string;
    /**
     * Employee endDate
     * @type {string}
     * @memberof EmployeeContractInformation
     */
    endDate?: string | null;
}


/**
 * @export
 */
export const EmployeeContractInformationWorkingDayTypeEnum = {
    Full: 'FULL',
    Partial: 'PARTIAL'
} as const;
export type EmployeeContractInformationWorkingDayTypeEnum = typeof EmployeeContractInformationWorkingDayTypeEnum[keyof typeof EmployeeContractInformationWorkingDayTypeEnum];

/**
 * @export
 */
export const EmployeeContractInformationWorkingModalityEnum = {
    OnSite: 'ON_SITE',
    Hybrid: 'HYBRID',
    Remote: 'REMOTE'
} as const;
export type EmployeeContractInformationWorkingModalityEnum = typeof EmployeeContractInformationWorkingModalityEnum[keyof typeof EmployeeContractInformationWorkingModalityEnum];

/**
 * @export
 */
export const EmployeeContractInformationScheduleEnum = {
    Monday: 'MONDAY',
    Tuesday: 'TUESDAY',
    Wednesday: 'WEDNESDAY',
    Thursday: 'THURSDAY',
    Friday: 'FRIDAY',
    Saturday: 'SATURDAY',
    Sunday: 'SUNDAY'
} as const;
export type EmployeeContractInformationScheduleEnum = typeof EmployeeContractInformationScheduleEnum[keyof typeof EmployeeContractInformationScheduleEnum];

/**
 * @export
 */
export const EmployeeContractInformationContractHoursEnum = {
    Hourly: 'HOURLY',
    Daily: 'DAILY',
    Weekly: 'WEEKLY',
    Monthly: 'MONTHLY',
    Yearly: 'YEARLY'
} as const;
export type EmployeeContractInformationContractHoursEnum = typeof EmployeeContractInformationContractHoursEnum[keyof typeof EmployeeContractInformationContractHoursEnum];


/**
 * Check if a given object implements the EmployeeContractInformation interface.
 */
export function instanceOfEmployeeContractInformation(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "startDate" in value;

    return isInstance;
}

export function EmployeeContractInformationFromJSON(json: any): EmployeeContractInformation {
    return EmployeeContractInformationFromJSONTyped(json, false);
}

export function EmployeeContractInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeeContractInformation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'trialPeriod': !exists(json, 'trialPeriod') ? undefined : json['trialPeriod'],
        'workingDayType': !exists(json, 'workingDayType') ? undefined : json['workingDayType'],
        'workingModality': !exists(json, 'workingModality') ? undefined : json['workingModality'],
        'hours': !exists(json, 'hours') ? undefined : json['hours'],
        'schedule': !exists(json, 'schedule') ? undefined : json['schedule'],
        'contractHours': !exists(json, 'contractHours') ? undefined : json['contractHours'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'salary': !exists(json, 'salary') ? undefined : json['salary'],
        'salaryRange': !exists(json, 'salaryRange') ? undefined : json['salaryRange'],
        'contractType': !exists(json, 'contractType') ? undefined : json['contractType'],
        'jobPositionAlias': !exists(json, 'jobPositionAlias') ? undefined : json['jobPositionAlias'],
        'startDate': json['startDate'],
        'endDate': !exists(json, 'endDate') ? undefined : json['endDate'],
    };
}

export function EmployeeContractInformationToJSON(value?: EmployeeContractInformation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'trialPeriod': value.trialPeriod,
        'workingDayType': value.workingDayType,
        'workingModality': value.workingModality,
        'hours': value.hours,
        'schedule': value.schedule,
        'contractHours': value.contractHours,
        'state': value.state,
        'salary': value.salary,
        'salaryRange': value.salaryRange,
        'contractType': value.contractType,
        'jobPositionAlias': value.jobPositionAlias,
        'startDate': value.startDate,
        'endDate': value.endDate,
    };
}

