
import { IconCheck } from '@tabler/icons-react'
import i18n from '@viterbit/web-app/i18n'
import clsxm from 'ui/src/lib'

const includedFeatures = [
    'leaves',
    'teams',
    'employeesCalendar',
    'jobPositions',
]

type PlanItemFeaturesProps = {
    className?: string
}

const styles = {
    root: 'grid grid-cols-1 gap-2 text-sm leading-6 text-black sm:grid-cols-2 pb-1',
    list: 'flex gap-x-3',
    listIcon: 'flex-none w-5 h-6 text-primary-600',
}

const PlanItemFeatures =  (props: PlanItemFeaturesProps) => {
    const { className } = props

    return (
        <ul
            role="list"
            className={clsxm(styles.root, className)}
        >
            {includedFeatures.map((feature) => (
                <li key={feature} className={styles.list}>
                    <IconCheck className={styles.listIcon}aria-hidden="true" />
                    {i18n.t(`billing:${feature}`)}
                </li>
            ))}
        </ul>

    )
}

export default PlanItemFeatures
