/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useQuery } from '@tanstack/react-query'

import { PENDING_FILES_QUERY_KEY } from './pendingFilesQueryKeys'
import { pendingFileDetail, pendingFileListPaginated } from '../../service/pendingFilesService'


type FileListConfig = {
    query: Parameters<typeof pendingFileListPaginated>[0] & {
        fileId?: string
    }
    enabled?: boolean
    keepPreviousData?: boolean
    refetchOnWindowFocus?: boolean
}

export const usePreviewPendingFileList = ({
    query,
    enabled,
    keepPreviousData,
    refetchOnWindowFocus,
}: FileListConfig) => useQuery({
    queryKey: [PENDING_FILES_QUERY_KEY, 'preview-list-paginated', query],
    queryFn: async () => {
        const {fileId, ..._query} = query
        const data = await pendingFileListPaginated({
            ..._query,
            page: fileId
                ? (_query.page! <= 1)
                    ? 1
                    : _query.page! - 1
                : _query.page || 1,
            pageSize: 1,
            notEqualsFileId: fileId || undefined
        })
        if (fileId && _query.page! <= 1) {
            const file = await pendingFileDetail({id: fileId})
            data.data = !file ? [] : [file]
        }
        return data
    },
    enabled,
    keepPreviousData,
    refetchOnWindowFocus,
})
