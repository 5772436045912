/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmployeeDeleteInformation
 */
export interface EmployeeDeleteInformation {
    /**
     * Employee delete end date
     * @type {string}
     * @memberof EmployeeDeleteInformation
     */
    endDate?: string | null;
    /**
     * Employee delete observations
     * @type {string}
     * @memberof EmployeeDeleteInformation
     */
    observations?: string | null;
    /**
     * Employee delete reason
     * @type {string}
     * @memberof EmployeeDeleteInformation
     */
    reason?: string | null;
    /**
     * Employee delete new Manager Id
     * @type {string}
     * @memberof EmployeeDeleteInformation
     */
    newManagerId?: string | null;
    /**
     * Employee delete new Manager name
     * @type {string}
     * @memberof EmployeeDeleteInformation
     */
    newManagerName?: string | null;
}

/**
 * Check if a given object implements the EmployeeDeleteInformation interface.
 */
export function instanceOfEmployeeDeleteInformation(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EmployeeDeleteInformationFromJSON(json: any): EmployeeDeleteInformation {
    return EmployeeDeleteInformationFromJSONTyped(json, false);
}

export function EmployeeDeleteInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeeDeleteInformation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'endDate': !exists(json, 'endDate') ? undefined : json['endDate'],
        'observations': !exists(json, 'observations') ? undefined : json['observations'],
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
        'newManagerId': !exists(json, 'newManagerId') ? undefined : json['newManagerId'],
        'newManagerName': !exists(json, 'newManagerName') ? undefined : json['newManagerName'],
    };
}

export function EmployeeDeleteInformationToJSON(value?: EmployeeDeleteInformation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'endDate': value.endDate,
        'observations': value.observations,
        'reason': value.reason,
        'newManagerId': value.newManagerId,
        'newManagerName': value.newManagerName,
    };
}

