import { SKELETON_STYLES, STYLES } from '@viterbit/web-app/feature/employee/item/EmployeeItemStyles'
import * as React from 'react'
import Skeleton from 'ui/src/components/Skeleton'
import clsxm from 'ui/src/lib'


type EmployeeItemSkeletonProps = {
    children?: React.ReactNode
    className?: string
    name?: boolean
    email?: boolean
    leadTitle?: boolean
    size?: 'sm' | 'lg'
}

const EmployeeItemSkeleton = (props: EmployeeItemSkeletonProps) => {
    const { name, email, leadTitle, children, size = 'sm', className } = props

    const styles = SKELETON_STYLES.sizes[size]

    return (
        <article className={clsxm(STYLES.root, styles.root, className)}>
            <Skeleton className={styles.avatar} />
            <div className="pointer-events-none">
                {leadTitle && (<Skeleton className={styles.leadTitle} />)}
                {name && (<Skeleton className={styles.name} />)}
                {email && (<Skeleton className={styles.email} />)}
            </div>
            {children}
        </article>

    )
}

export default EmployeeItemSkeleton
