import { JobPositionLevel } from '@viterbit/web-app/dataAccess/model/JobPositionLevel.model'
import i18n from '@viterbit/web-app/i18n'
import SimpleListItem, { SimpleListItemProps } from '@viterbit/web-app/shared/display/SimpleListItem'
import * as React from 'react'

type JobPositionLevelItemProps = {
    level: JobPositionLevel,
} & SimpleListItemProps

const JobPositionLevelItem = ({
    level,
    className = '',
    onDelete,
    onClick,
}: JobPositionLevelItemProps) => {
    const [initial] = level.name.split('')
    
    return (
        <SimpleListItem
            className={className}
            name={level.name}
            description={level.description}
            descriptionPlaceholder={i18n.t('employees:jobPositions.levelSection.itemDescription')}
            onClick={onClick}
            onDelete={onDelete}
            leftContent={initial.toUpperCase()}
        />
    )
}

export default JobPositionLevelItem
