import { IconCalendarEvent } from '@tabler/icons-react'
import { Calendar } from '@viterbit/web-app/dataAccess/model/Calendar.model'
import i18n from '@viterbit/web-app/i18n'
import SimpleListItem, { SimpleListItemProps } from '@viterbit/web-app/shared/display/SimpleListItem'
import * as React from 'react'

type HolidayItemProps = {
    calendar: Calendar
} & Omit<SimpleListItemProps, 'name' | 'description' | 'leftContent'>

const HolidayItem = ({ calendar, ...rest }: HolidayItemProps) => (
    <SimpleListItem
        name={calendar.year}
        description={i18n.t('settingLocations:configuredDays', { count: calendar.publicHolidays?.length })}
        leftContent={<IconCalendarEvent />}
        {...rest}
    />
)

export default HolidayItem
