/* eslint-disable @typescript-eslint/no-non-null-assertion */
import i18n from '@viterbit/web-app/i18n'
import * as React from 'react'
import Dialog from 'ui/src/components/Dialog'

import CreateForm from '../Forms/Create'
import { LeaveFormFields } from '../Forms/types'

export type CreateDialogProps = {
    onClose?: () => void
    open: boolean
    employeeId?: string
    defaultValues: Partial<LeaveFormFields>
}

const CreateDialog = ({ onClose, employeeId, open, defaultValues }: CreateDialogProps) => (
    <Dialog
        title={i18n.t('leaves:createDialog.title')}
        open={open}
        onClose={onClose}
    >
        <CreateForm
            onSubmitCallBack={onClose}
            employeeId={employeeId}
            defaultValues={defaultValues}
        />
    </Dialog>
)

export default CreateDialog
