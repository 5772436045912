import { useInfiniteQuery } from '@tanstack/react-query'
import { TEAM_QUERY_KEY } from '@viterbit/web-app/dataAccess/cacheKeys'

import { teamList } from '../../service/teamService'

type TeamListConfig = {
    query: Parameters<typeof teamList>[0]
    enabled?: boolean
}

export const useTeamListPaginated = (config?: TeamListConfig) => {
    const { query = { page: 1 }, enabled = true } = config || {}

    return useInfiniteQuery(
        [TEAM_QUERY_KEY, 'list-paginated', query],
        ({ pageParam }) => teamList({ page: pageParam }),
        {
            getNextPageParam: (lastPage) => lastPage ? lastPage.meta.nextPage : 1,
            initialData: () => ({
                pageParams: [1],
                pages: [],
            }),
            enabled,
        }
    )
}

