/* eslint-disable @typescript-eslint/no-explicit-any */
import i18n from '@viterbit/web-app/i18n'
import {
    Allow as _Allow,
    ArrayContains as _ArrayContains,
    ArrayMaxSize as _ArrayMaxSize,
    ArrayMinSize as _ArrayMinSize,
    ArrayNotContains as _ArrayNotContains,
    ArrayNotEmpty as _ArrayNotEmpty,
    ArrayUnique as _ArrayUnique,
    Contains as _Contains,
    Equals as _Equals,
    IsAlpha as _IsAlpha,
    IsAlphanumeric as _IsAlphanumeric,
    IsArray as _IsArray,
    IsAscii as _IsAscii,
    IsBase64 as _IsBase64,
    IsBIC as _IsBIC,
    IsBoolean as _IsBoolean,
    IsBooleanString as _IsBooleanString,
    IsByteLength as _IsByteLength,
    IsCreditCard as _IsCreditCard,
    IsCurrency as _IsCurrency,
    IsDate as _IsDate,
    IsDateString as _IsDateString,
    IsDecimal as _IsDecimal,
    IsDefined as _IsDefined,
    IsDivisibleBy as _IsDivisibleBy,
    IsEmail as _IsEmail,
    IsEmpty as _IsEmpty,
    IsEnum as _IsEnum,
    IsFirebasePushId as _IsFirebasePushId,
    IsFQDN as _IsFQDN,
    IsFullWidth as _IsFullWidth,
    IsHalfWidth as _IsHalfWidth,
    IsHash as _IsHash,
    IsHexadecimal as _IsHexadecimal,
    IsHexColor as _IsHexColor,
    IsIBAN as _IsIBAN,
    IsIn as _IsIn,
    IsInstance as _IsInstance,
    IsInt as _IsInt,
    IsIP as _IsIP,
    IsISBN as _IsISBN,
    IsISIN as _IsISIN,
    IsISO8601 as _IsISO8601,
    IsISO31661Alpha2 as _IsISO31661Alpha2,
    isISO31661Alpha2 as _isISO31661Alpha2,
    IsISO31661Alpha3 as _IsISO31661Alpha3,
    IsISSN as _IsISSN,
    IsJSON as _IsJSON,
    IsJWT as _IsJWT,
    IsLatitude as _IsLatitude,
    IsLatLong as _IsLatLong,
    IsLocale as _IsLocale,
    IsLongitude as _IsLongitude,
    IsLowercase as _IsLowercase,
    IsMACAddress as _IsMACAddress,
    IsMilitaryTime as _IsMilitaryTime,
    IsMobilePhone as _IsMobilePhone,
    IsMongoId as _IsMongoId,
    IsMultibyte as _IsMultibyte,
    IsNegative as _IsNegative,
    IsNotEmpty as _IsNotEmpty,
    IsNotEmptyObject as _IsNotEmptyObject,
    IsNotIn as _IsNotIn,
    IsNumber as _IsNumber,
    IsNumberString as _IsNumberString,
    IsObject as _IsObject,
    IsOptional as _IsOptional,
    IsPhoneNumber as _IsPhoneNumber,
    IsPort as _IsPort,
    IsPositive as _IsPositive,
    IsString as _IsString,
    IsStrongPassword as _IsStrongPassword,
    IsSurrogatePair as _IsSurrogatePair,
    IsUppercase as _IsUppercase,
    IsUrl as _IsUrl,
    IsUUID as _IsUUID,
    IsVariableWidth as _IsVariableWidth,
    Length as _Length,
    Matches as _Matches,
    Max as _Max,
    MaxDate as _MaxDate,
    MaxLength as _MaxLength,
    Min as _Min,
    MinDate as _MinDate,
    MinLength as _MinLength,
    NotContains as _NotContains,
    NotEquals as _NotEquals,
    Validate as _Validate,
    ValidateIf as _ValidateIf,
    ValidateNested as _ValidateNested,
    ValidatePromise as _ValidatePromise,
    ValidationOptions,
    ValidatorConstraint as _ValidatorConstraint,
} from 'class-validator'


type genericFunction = (...args: any) => any

// idea from https://github.com/typestack/class-validator/issues/169#issuecomment-677662476
const addMessageToOptions = <T extends genericFunction> (validator: T, options: ValidationOptions = {}, validatorName: string) => {
    options.message = (validationArgs) => i18n.t('validation:' + validatorName, validationArgs as any) as any

    return options
}

export const ValidatorConstraint = _ValidatorConstraint
export const Validate = _Validate
export const ValidateNested = (...args: Parameters<typeof _ValidateNested>) => _ValidateNested(addMessageToOptions(_ValidateNested, args[0], 'validate_nested'))
export const ValidatePromise = (...args: Parameters<typeof _ValidatePromise>) => _ValidatePromise(addMessageToOptions(_ValidatePromise, args[0], 'validate_promise'))
export const Allow = (...args: Parameters<typeof _Allow>) => _Allow(addMessageToOptions(_Allow, args[0], 'allow'))
export const ValidateIf = (...args: Parameters<typeof _ValidateIf>) => _ValidateIf(args[0], addMessageToOptions(_ValidateIf, args[1], 'validate_if'))
export const IsDefined = (...args: Parameters<typeof _IsDefined>) => _IsDefined(addMessageToOptions(_IsDefined, args[0], 'is_defined'))
export const Equals = (...args: Parameters<typeof _Equals>) => _Equals(addMessageToOptions(_Equals, args[0], 'equals'))
export const NotEquals = (...args: Parameters<typeof _NotEquals>) => _NotEquals(addMessageToOptions(_NotEquals, args[0], 'not_equals'))
export const IsEmpty = (...args: Parameters<typeof _IsEmpty>) => _IsEmpty(addMessageToOptions(_IsEmpty, args[0], 'is_empty'))
export const IsNotEmpty = (...args: Parameters<typeof _IsNotEmpty>) => _IsNotEmpty(addMessageToOptions(_IsNotEmpty, args[0], 'is_not_empty'))
export const IsIn = (...args: Parameters<typeof _IsIn>) => _IsIn(args[0], addMessageToOptions(_IsIn, args[1], 'is_in'))
export const IsNotIn = (...args: Parameters<typeof _IsNotIn>) => _IsNotIn(args[0], addMessageToOptions(_IsNotIn, args[1], 'is_not_in'))
export const IsOptional = (...args: Parameters<typeof _IsOptional>) => _IsOptional(addMessageToOptions(_IsOptional, args[0], 'is_optional'))
export const IsBoolean = (...args: Parameters<typeof _IsBoolean>) => _IsBoolean(addMessageToOptions(_IsBoolean, args[0], 'is_boolean'))
export const IsLatLong = (...args: Parameters<typeof _IsLatLong>) => _IsLatLong(addMessageToOptions(_IsLatLong, args[0], 'is_lat_long'))
export const IsLatitude = (...args: Parameters<typeof _IsLatitude>) => _IsLatitude(addMessageToOptions(_IsLatitude, args[0], 'is_latitude'))
export const IsLongitude = (...args: Parameters<typeof _IsLongitude>) => _IsLongitude(addMessageToOptions(_IsLongitude, args[0], 'is_longitude'))
export const IsDate = (...args: Parameters<typeof _IsDate>) => _IsDate(addMessageToOptions(_IsDate, args[0], 'is_date'))
export const IsNumber = (...args: Parameters<typeof _IsNumber>) => _IsNumber(args[0], addMessageToOptions(_IsNumber, args[1], 'is_number'))
export const IsInt = (...args: Parameters<typeof _IsInt>) => _IsInt(addMessageToOptions(_IsInt, args[0], 'is_int'))
export const IsString = (...args: Parameters<typeof _IsString>) => _IsString(addMessageToOptions(_IsString, args[0], 'is_string'))
export const IsDateString = (...args: Parameters<typeof _IsDateString>) => _IsDateString(args[0], addMessageToOptions(_IsDateString, args[1], 'is_date_string'))
export const IsArray = (...args: Parameters<typeof _IsArray>) => _IsArray(addMessageToOptions(_IsArray, args[0], 'is_array'))
export const IsEnum = (...args: Parameters<typeof _IsEnum>) => _IsEnum(addMessageToOptions(_IsEnum, args[0], 'is_enum'))
export const IsDivisibleBy = (...args: Parameters<typeof _IsDivisibleBy>) => _IsDivisibleBy(args[0], addMessageToOptions(_IsDivisibleBy, args[1], 'is_divisible_by'))
export const IsPositive = (...args: Parameters<typeof _IsPositive>) => _IsPositive(addMessageToOptions(_IsPositive, args[0], 'is_positive'))
export const IsNegative = (...args: Parameters<typeof _IsNegative>) => _IsNegative(addMessageToOptions(_IsNegative, args[0], 'is_negative'))
export const Min = (...args: Parameters<typeof _Min>) => _Min(args[0], addMessageToOptions(_Min, args[1], 'min'))
export const Max = (...args: Parameters<typeof _Max>) => _Max(args[0], addMessageToOptions(_Max, args[1], 'max'))
export const MinDate = (...args: Parameters<typeof _MinDate>) => _MinDate(args[0], addMessageToOptions(_MinDate, args[1], 'min_date'))
export const MaxDate = (...args: Parameters<typeof _MaxDate>) => _MaxDate(args[0], addMessageToOptions(_MaxDate, args[1], 'max_date'))
export const IsBooleanString = (...args: Parameters<typeof _IsBooleanString>) => _IsBooleanString(addMessageToOptions(_IsBooleanString, args[0], 'is_boolean_string'))
export const IsNumberString = (...args: Parameters<typeof _IsNumberString>) => _IsNumberString(args[0], addMessageToOptions(_IsNumberString, args[1], 'is_number_string'))
export const Contains = (...args: Parameters<typeof _Contains>) => _Contains(args[0], addMessageToOptions(_Contains, args[1], 'contains'))
export const NotContains = (...args: Parameters<typeof _NotContains>) => _NotContains(args[0], addMessageToOptions(_NotContains, args[1], 'not_contains'))
export const IsAlpha = (...args: Parameters<typeof _IsAlpha>) => _IsAlpha(args[0], addMessageToOptions(_IsAlpha, args[1], 'is_alpha'))
export const IsAlphanumeric = (...args: Parameters<typeof _IsAlphanumeric>) => _IsAlphanumeric(args[0], addMessageToOptions(_IsAlphanumeric, args[1], 'is_alphanumeric'))
export const IsDecimal = (...args: Parameters<typeof _IsDecimal>) => _IsDecimal(args[0], addMessageToOptions(_IsDecimal, args[1], 'is_decimal'))
export const IsAscii = (...args: Parameters<typeof _IsAscii>) => _IsAscii(addMessageToOptions(_IsAscii, args[0], 'is_ascii'))
export const IsBase64 = (...args: Parameters<typeof _IsBase64>) => _IsBase64(addMessageToOptions(_IsBase64, args[0], 'is_base64'))
export const IsByteLength = ([arg1, arg2, options]: Parameters<typeof _IsByteLength>) => _IsByteLength(arg1, arg2, addMessageToOptions(_IsByteLength, options, 'is_byte_length'))
export const IsCreditCard = (...args: Parameters<typeof _IsCreditCard>) => _IsCreditCard(addMessageToOptions(_IsCreditCard, args[0], 'is_credit_card'))
export const IsCurrency = (...args: Parameters<typeof _IsCurrency>) => _IsCurrency(args[0], addMessageToOptions(_IsCurrency, args[1], 'is_currency'))
export const IsEmail = (...args: Parameters<typeof _IsEmail>) => _IsEmail(args[0], addMessageToOptions(_IsEmail, args[1], 'is_email'))
export const IsFQDN = (...args: Parameters<typeof _IsFQDN>) => _IsFQDN(args[0], addMessageToOptions(_IsFQDN, args[1], 'is_fqdn'))
export const IsFullWidth = (...args: Parameters<typeof _IsFullWidth>) => _IsFullWidth(addMessageToOptions(_IsFullWidth, args[0], 'is_full_width'))
export const IsHalfWidth = (...args: Parameters<typeof _IsHalfWidth>) => _IsHalfWidth(addMessageToOptions(_IsHalfWidth, args[0], 'is_half_width'))
export const IsVariableWidth = (...args: Parameters<typeof _IsVariableWidth>) => _IsVariableWidth(addMessageToOptions(_IsVariableWidth, args[0], 'is_variable_width'))
export const IsHexColor = (...args: Parameters<typeof _IsHexColor>) => _IsHexColor(addMessageToOptions(_IsHexColor, args[0], 'is_hex_color'))
export const IsHexadecimal = (...args: Parameters<typeof _IsHexadecimal>) => _IsHexadecimal(addMessageToOptions(_IsHexadecimal, args[0], 'is_hexadecimal'))
export const IsMACAddress = (...args: Parameters<typeof _IsMACAddress>) => _IsMACAddress(addMessageToOptions(_IsMACAddress, args[0], 'is_mac_address'))
export const IsIP = (...args: Parameters<typeof _IsIP>) => _IsIP(args[0], addMessageToOptions(_IsIP, args[1], 'is_ip'))
export const IsPort = (...args: Parameters<typeof _IsPort>) => _IsPort(addMessageToOptions(_IsPort, args[0], 'is_port'))
export const IsISBN = (...args: Parameters<typeof _IsISBN>) => _IsISBN(args[0], addMessageToOptions(_IsISBN, args[1], 'is_isbn'))
export const IsIBAN = (...args: Parameters<typeof _IsIBAN>) => _IsIBAN(addMessageToOptions(_IsIBAN, args[0], 'is_iban'))
export const IsBIC = (...args: Parameters<typeof _IsBIC>) => _IsBIC(addMessageToOptions(_IsBIC, args[0], 'is_bic'))
export const IsISIN = (...args: Parameters<typeof _IsISIN>) => _IsISIN(addMessageToOptions(_IsISIN, args[0], 'is_isin'))
export const IsISO8601 = (...args: Parameters<typeof _IsISO8601>) => _IsISO8601(args[0], addMessageToOptions(_IsISO8601, args[1], 'is_iso8601'))
export const IsJSON = (...args: Parameters<typeof _IsJSON>) => _IsJSON(addMessageToOptions(_IsJSON, args[0], 'is_json'))
export const IsJWT = (...args: Parameters<typeof _IsJWT>) => _IsJWT(addMessageToOptions(_IsJWT, args[0], 'is_jwt'))
export const IsObject = (...args: Parameters<typeof _IsObject>) => _IsObject(addMessageToOptions(_IsObject, args[0], 'is_object'))
export const IsNotEmptyObject = (...args: Parameters<typeof _IsNotEmptyObject>) => _IsNotEmptyObject(args[0], addMessageToOptions(_IsNotEmptyObject, args[1], 'is_not_empty_object'))
export const IsLowercase = (...args: Parameters<typeof _IsLowercase>) => _IsLowercase(addMessageToOptions(_IsLowercase, args[0], 'is_lowercase'))
export const IsMobilePhone = (...args: Parameters<typeof _IsMobilePhone>) => _IsMobilePhone(args[0], args[1], addMessageToOptions(_IsMobilePhone, args[2], 'is_mobile_phone'))
export const IsPhoneNumber = (...args: Parameters<typeof _IsPhoneNumber>) => _IsPhoneNumber(args[0], addMessageToOptions(_IsPhoneNumber, args[1], 'is_phone_number'))
export const IsISO31661Alpha2 = (...args: Parameters<typeof _IsISO31661Alpha2>) => _IsISO31661Alpha2(addMessageToOptions(_IsISO31661Alpha2, args[0], 'is_iso31661_alpha2'))
export const IsISO31661Alpha3 = (...args: Parameters<typeof _IsISO31661Alpha3>) => _IsISO31661Alpha3(addMessageToOptions(_IsISO31661Alpha3, args[0], 'is_iso31661_alpha3'))
export const IsMongoId = (...args: Parameters<typeof _IsMongoId>) => _IsMongoId(addMessageToOptions(_IsMongoId, args[0], 'is_mongo_id'))
export const IsMultibyte = (...args: Parameters<typeof _IsMultibyte>) => _IsMultibyte(addMessageToOptions(_IsMultibyte, args[0], 'is_multibyte'))
export const IsSurrogatePair = (...args: Parameters<typeof _IsSurrogatePair>) => _IsSurrogatePair(addMessageToOptions(_IsSurrogatePair, args[0], 'is_surrogate_pair'))
export const IsStrongPassword = (...args: Parameters<typeof _IsStrongPassword>) => _IsStrongPassword(args[0], addMessageToOptions(_IsStrongPassword, args[1], 'is_strong_password'))
export const IsUrl = (...args: Parameters<typeof _IsUrl>) => _IsUrl(args[0], addMessageToOptions(_IsUrl, args[1], 'is_url'))
export const IsUUID = (...args: Parameters<typeof _IsUUID>) => _IsUUID(args[0], addMessageToOptions(_IsUUID, args[1], 'is_uuid'))
export const IsFirebasePushId = (...args: Parameters<typeof _IsFirebasePushId>) => _IsFirebasePushId(addMessageToOptions(_IsFirebasePushId, args[0], 'is_firebase_push_id'))
export const IsUppercase = (...args: Parameters<typeof _IsUppercase>) => _IsUppercase(addMessageToOptions(_IsUppercase, args[0], 'is_uppercase'))
export const Length = ([arg1, arg2, options]: Parameters<typeof _Length>) => _Length(arg1, arg2, addMessageToOptions(_Length, options, 'length'))
export const MinLength = (...args: Parameters<typeof _MinLength>) => _MinLength(args[0], addMessageToOptions(_MinLength, args[1], 'min_length'))
export const MaxLength = (...args: Parameters<typeof _MaxLength>) => _MaxLength(args[0], addMessageToOptions(_MaxLength, args[1], 'max_length'))
export const Matches = ([arg1, arg2, options]: Parameters<typeof _Matches>) => _Matches(arg1, arg2, addMessageToOptions(_Matches, options, 'matches'))
export const IsMilitaryTime = (...args: Parameters<typeof _IsMilitaryTime>) => _IsMilitaryTime(addMessageToOptions(_IsMilitaryTime, args[0], 'is_military_time'))
export const IsHash = (...args: Parameters<typeof _IsHash>) => _IsHash(args[0], addMessageToOptions(_IsHash, args[1], 'is_hash'))
export const IsISSN = (...args: Parameters<typeof _IsISSN>) => _IsISSN(args[0], addMessageToOptions(_IsISSN, args[1], 'is_issn'))
export const ArrayContains = (...args: Parameters<typeof _ArrayContains>) => _ArrayContains(args[0], addMessageToOptions(_ArrayContains, args[1], 'array_contains'))
export const ArrayNotContains = (...args: Parameters<typeof _ArrayNotContains>) => _ArrayNotContains(args[0], addMessageToOptions(_ArrayNotContains, args[1], 'array_not_contains'))
export const ArrayNotEmpty = (...args: Parameters<typeof _ArrayNotEmpty>) => _ArrayNotEmpty(addMessageToOptions(_ArrayNotEmpty, args[0], 'array_not_empty'))
export const ArrayMinSize = (...args: Parameters<typeof _ArrayMinSize>) => _ArrayMinSize(args[0], addMessageToOptions(_ArrayMinSize, args[1], 'array_min_size'))
export const ArrayMaxSize = (...args: Parameters<typeof _ArrayMaxSize>) => _ArrayMaxSize(args[0], addMessageToOptions(_ArrayMaxSize, args[1], 'array_max_size'))
export const ArrayUnique = (...args: Parameters<typeof _ArrayUnique>) => _ArrayUnique(args[0], addMessageToOptions(_ArrayUnique, args[1], 'array_unique'))
export const IsInstance = (...args: Parameters<typeof _IsInstance>) => _IsInstance(args[0], addMessageToOptions(_IsInstance, args[1], 'is_instance'))
export const IsLocale = (...args: Parameters<typeof _IsLocale>) => _IsLocale(addMessageToOptions(_IsInstance, args[0], 'is_locale'))
export const isISO31661Alpha2 = _isISO31661Alpha2


