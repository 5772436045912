import { Skeleton } from 'ui/src'
import Tabs from 'ui/src/components/Tabs'

type NavigationTabsLoadingProps = {
    children?: React.ReactNode
}

const NavigationTabsLoading = ({children}: NavigationTabsLoadingProps) => (
    <Tabs variant='underline'>
        <Tabs.TabList className='lg:sticky top-[62px] gap-10 bg-grey-100 z-20 mb-4'>
            {new Array(6).fill(1).map((_, index) => (
                <Skeleton animated className='w-20 h-4 my-3' key={index as any} />
            ))}
        </Tabs.TabList>

        {children}
    </Tabs>
)

export default NavigationTabsLoading
