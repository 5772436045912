/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Employee } from '@viterbit/web-app/dataAccess/model/Employee.model'
import i18n from '@viterbit/web-app/i18n'
import * as React from 'react'
import Dialog from 'ui/src/components/Dialog'

import CreateExtraDayForm from './form/CreateExtraDayForm'

type ExtraDaysDialogProps = {
    employee: Employee
    open: boolean
    onClose: () => void

}

const ExtraDaysDialog = ({ employee, onClose, open }: ExtraDaysDialogProps) => (
    <Dialog title={i18n.t('leaves:editExtraDaysDialog.title')} open={open} onClose={onClose}>
        <CreateExtraDayForm
            employee={employee}
            onCancel={onClose}
            onSuccess={onClose}
        />
    </Dialog>
)

export default ExtraDaysDialog
