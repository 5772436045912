import { Grid } from '@mantine/core'
import * as React from 'react'

type MultiColumnLayoutProps = {
    children?: React.ReactNode
    left?: React.ReactNode
    right?: React.ReactNode
}

const MultiColumnLayout = ({
    left,
    right,
    children,
}: MultiColumnLayoutProps) => (
    <Grid>
        {left && <Grid.Col span={12} md={3}>{left}</Grid.Col>}
        <Grid.Col span={12} md={9}>{children}</Grid.Col>
        {right && <Grid.Col span={12} md={3}>{right}</Grid.Col>}
    </Grid>
)

export default MultiColumnLayout
