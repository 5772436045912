import * as React from 'react'

import { clsxm } from '../../lib/clsxm'

type InfoProps = {
    className?: string
    children?: React.ReactNode
    percent?: number
}

const Info = ({ percent, className, children }: InfoProps) => (
    <span className={clsxm('ltr:ml-2 rtl:mr-2', className)}>
        {children || `${percent}%`}
    </span>
)

export default Info
