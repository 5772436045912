import * as React from 'react'

import { BaseProps } from './util'
import { clsxm } from '../../lib/clsxm'

const classes = {
    thead: 'bg-gray-50 dark:bg-gray-700',
    hover: 'hover:bg-gray-100/50 hover:dark:bg-gray-700/40',
    variants: {
        default: 'bg-gray-50 dark:bg-gray-700',
        flex: 'bg-gray-50 dark:bg-gray-700',
    },
}

export type THeadProps = BaseProps &
    React.HTMLAttributes<HTMLTableSectionElement>

const THead = ({
    className,
    variant = 'default',
    isHoverable = true,
    children,
    ...props
}: THeadProps) => (
    <thead
        className={clsxm(
            classes.thead,
            classes.variants[variant],
            isHoverable && classes.hover,
            className
        )}
        {...props}
    >
        {children}
    </thead>
)

export default THead
