
import { RelevantDate } from '@viterbit/web-app/feature/leave/list/types'
import DateTimeItemTitle from '@viterbit/web-app/shared/display/DateTimeItem/DateTimeItemTitle'
import dayjs from 'dayjs'
import * as React from 'react'

import RelevantDaysTooltip from './RelevantDaysTooltip'

type HolidayProps = {
    relevantDate: RelevantDate
}

const Holiday = ({ relevantDate }: HolidayProps) => {
    const day = dayjs(relevantDate.date).format('DD')
    const monthFormat = dayjs(relevantDate.date).format('MMM')
    return (
        <RelevantDaysTooltip
            title={
                <>
                    <DateTimeItemTitle
                        title={relevantDate.label}
                        color={relevantDate.color}
                    />
                    <div className='flex flex-row'>
                        <p className='pr-1 font-semibold text-black'>{day}</p>{' '}
                        {monthFormat}
                    </div>
                </>
            }
        >
            <span
                style={{
                    border: `1px solid ${relevantDate.color}`,
                }}
                className='flex items-center justify-center bg-transparent rounded-full w-7 h-7'
            >
                {day}
            </span>
        </RelevantDaysTooltip>
    )
}

export default Holiday
