import dayjs from 'dayjs'
import * as React from 'react'

import Header from './Header'
import Month from '../Month'
import { DayProps, payload, view } from '../sharedTypes'
import { isMonthInRange, noop } from '../utils'
import { clsxm } from '../../../lib/clsxm'

const classes = {
    'day-picker': 'w-full',
    pickerHeaderLabelClass:
        'cursor-pointer bg-white  mx-0.5 select-none text-black dark:text-gray-100 text-lg font-semibold hover:text-primary-600',
}

const capitalize = (value?: string) =>
    value ? value.charAt(0).toUpperCase() + value.slice(1) : ''

type formatMonthLabelProps = {
    month: Date
    locale: string
    format: string
}

const formatMonthLabel = ({
    month,
    locale,
    format,
}: formatMonthLabelProps): string =>
    capitalize(dayjs(month).locale(locale).format(format))

type DateProps = {
    className?: string
    dateViewCount: number
    paginateBy: number
    month: Date
    value?: Date | Date[] | null
    locale: string
    minDate?: Date
    maxDate?: Date
    enableHeaderLabel?: boolean
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    daysRefs: React.MutableRefObject<any[][]>
    onMonthChange: (date: Date) => void
    onNextLevel: (view: view) => void
    onDayKeyDown: (
        monthIndex: number,
        onKeyDownPayload: payload,
        event: React.KeyboardEvent<HTMLButtonElement>
    ) => void
    monthLabelFormat?: string
    yearLabelFormat?: string
    weekdayLabelFormat?: string
    preventFocus?: boolean
    renderDay?: (value: Date | undefined) => React.ReactNode
    label: string
    nextLabel: string
    previousLabel: string
    onChange: (date: Date) => void
    dayClassName?: (date?: Date, dayProps?: DayProps) => string | string
    range: Date[] | undefined
    dayStyle?: (date?: Date, dayProps?: DayProps) => React.CSSProperties
    disableOutOfMonth?: boolean
    disableDate?: (date: Date) => boolean
    hideWeekdays?: boolean
    firstDayOfWeek?: 'monday' | 'sunday'
    onDayMouseEnter?: (
        date: Date | undefined,
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => void
    hideOutOfMonthDates?: boolean
    isDateInRange?: (date?: Date, dayProps?: DayProps) => boolean
    isDateFirstInRange?: (date?: Date, dayProps?: DayProps) => boolean
    isDateLastInRange?: (date?: Date, dayProps?: DayProps) => boolean
    weekendDays?: number[]
}

const DateTable = ({
    className,
    dateViewCount,
    paginateBy,
    month,
    value,
    locale,
    minDate,
    maxDate,
    enableHeaderLabel,
    daysRefs,
    onMonthChange,
    onChange,
    onNextLevel,
    onDayKeyDown,
    monthLabelFormat = 'MMMM',
    yearLabelFormat = 'YYYY',
    weekdayLabelFormat,
    preventFocus,
    renderDay,
    label,
    nextLabel,
    previousLabel,
    dayClassName,
    range = [],
    dayStyle,
    disableOutOfMonth,
    disableDate,
    hideWeekdays,
    firstDayOfWeek,
    onDayMouseEnter,
    hideOutOfMonthDates,
    isDateInRange = noop,
    isDateFirstInRange = noop,
    isDateLastInRange = noop,
    weekendDays = [0, 6],
}: DateProps) => {
    const nextMonth = dayjs(month).add(dateViewCount, 'months').toDate()
    const previousMonth = dayjs(month).subtract(1, 'months').toDate()

    const months = Array(dateViewCount)
        .fill(0)
        .map((_, index) => {
            const monthDate = dayjs(month).add(index, 'months').toDate()
            return (
                // eslint-disable-next-line react/no-array-index-key
                <div className={classes['day-picker']} key={index}>
                    <Header
                        label={label}
                        nextLabel={nextLabel}
                        previousLabel={previousLabel}
                        hasNext={
                            index + 1 === dateViewCount &&
                            isMonthInRange({
                                date: nextMonth,
                                minDate,
                                maxDate,
                            })
                        }
                        hasPrevious={
                            index === 0 &&
                            isMonthInRange({
                                date: previousMonth,
                                minDate,
                                maxDate,
                            })
                        }
                        onNext={() =>
                            onMonthChange(
                                dayjs(month).add(paginateBy, 'months').toDate()
                            )
                        }
                        onPrevious={() =>
                            onMonthChange(
                                dayjs(month)
                                    .subtract(paginateBy, 'months')
                                    .toDate()
                            )
                        }
                        className={className}
                        shouldRenderCenter={dateViewCount > 1}
                    >
                        <div>
                            <button
                                type='button'
                                className={clsxm(
                                    classes.pickerHeaderLabelClass
                                )}
                                disabled={!enableHeaderLabel}
                                onClick={() => onNextLevel('month')}
                                tabIndex={index > 0 ? -1 : 0}
                                onMouseDown={(event) =>
                                    preventFocus && event.preventDefault()
                                }
                            >
                                {formatMonthLabel({
                                    month: monthDate,
                                    locale,
                                    format: monthLabelFormat,
                                })}
                            </button>
                            <button
                                type='button'
                                className={clsxm(
                                    classes.pickerHeaderLabelClass
                                )}
                                disabled={!enableHeaderLabel}
                                onClick={() => onNextLevel('year')}
                                tabIndex={index > 0 ? -1 : 0}
                                onMouseDown={(event) =>
                                    preventFocus && event.preventDefault()
                                }
                            >
                                {formatMonthLabel({
                                    month: monthDate,
                                    locale,
                                    format: yearLabelFormat,
                                })}
                            </button>
                        </div>
                    </Header>
                    <Month
                        hideWeekdays={hideWeekdays}
                        month={monthDate}
                        value={value}
                        daysRefs={daysRefs.current[index]}
                        onDayKeyDown={(
                            keyDownPayload: payload,
                            event: React.KeyboardEvent<HTMLButtonElement>
                        ) => onDayKeyDown(index, keyDownPayload, event)}
                        minDate={minDate}
                        maxDate={maxDate}
                        className={className}
                        locale={locale}
                        focusable={index === 0}
                        preventFocus={Boolean(preventFocus)}
                        renderDay={renderDay}
                        weekdayLabelFormat={weekdayLabelFormat}
                        onChange={onChange}
                        range={range}
                        dayClassName={dayClassName}
                        dayStyle={dayStyle}
                        disableOutOfMonth={disableOutOfMonth}
                        disableDate={disableDate}
                        firstDayOfWeek={firstDayOfWeek}
                        onDayMouseEnter={onDayMouseEnter}
                        hideOutOfMonthDates={hideOutOfMonthDates}
                        isDateInRange={isDateInRange}
                        isDateFirstInRange={isDateFirstInRange}
                        isDateLastInRange={isDateLastInRange}
                        weekendDays={weekendDays}
                    />
                </div>
            )
        })

    return <>{months}</>
}

export default DateTable
