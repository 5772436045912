
import { classValidatorResolver } from '@hookform/resolvers/class-validator'
import { Employee } from '@viterbit/web-app/dataAccess/model/Employee.model'
import { JobPosition } from '@viterbit/web-app/dataAccess/model/JobPosition.model'
import { assignJobPositionLevel } from '@viterbit/web-app/dataAccess/service/employeeService'
import i18n from '@viterbit/web-app/i18n'
import SimpleCard from '@viterbit/web-app/shared/display/SimpleCard'
import Form from '@viterbit/web-app/shared/form/Form'
import FormSelectJobPosition from '@viterbit/web-app/shared/form/FormSelectJobPosition'
import SubmitButton from '@viterbit/web-app/shared/form/SubmitButton'
import * as React from 'react'
import { FormInput, FormSelect } from 'ui/src/components/Form'

import { EmployeeContractJobPositionValidator } from './EmployeeContractJobPositionValidator'

type EmployeeContractJobPositionFields = {
    id?: string | null
    employeeId: string
    alias?: string
    position?: string | null
}

export type EmployeeContractJobPositionFormProps = {
    employee: Employee
}

const resolver = classValidatorResolver(EmployeeContractJobPositionValidator)

const getFormDefaultValues = (employee: Employee): EmployeeContractJobPositionFields => ({
    employeeId: employee.id,
    id: employee.jobPositionLevel?.id,
    position: employee.jobPosition?.id,
    alias: employee.jobPositionAlias
})

const EmployeeContractJobPositionForm = (props: EmployeeContractJobPositionFormProps) => {
    const { employee } = props
    const [jobPosition, setJobPosition] = React.useState<JobPosition|null>(employee.jobPosition ?? null)
    const defaultValues = React.useMemo(() => getFormDefaultValues(employee), [employee])

    return (
        <SimpleCard padding>
            <Form<EmployeeContractJobPositionFields>
                smallButtons
                withTransition
                mutationConfig={{
                    mutationFn: (data) => 
                        assignJobPositionLevel({
                            id: employee.id,
                            assignEmployeeJobPositionLevelCommand: {
                                jobPositionLevelId: data.id,
                                alias: data.alias
                            }
                        }),
                }}
                formConfig={{
                    resolver,
                    mode: 'all',
                    defaultValues: defaultValues,
                }}
            >
                {(form) => (
                    <>
                        <FormSelectJobPosition
                            name='position'
                            control={form.control}
                            label={i18n.t('employees:employee.profile.jobPositionInfo.form.jobPosition.label')}
                            placeholder={i18n.t('employees:employee.profile.jobPositionInfo.form.jobPosition.placeholder')}
                            isClearable
                            onSelectItem={jobPosition => {
                                setJobPosition(jobPosition as any)
                                form.setValue('id', null)
                            }}
                            defaultValue={employee.jobPosition}
                        />

                        <FormSelect
                            name='id'
                            control={form.control}
                            options={jobPosition?.jobPositionLevels}
                            isDisabled={!jobPosition}
                            label={i18n.t('employees:employee.profile.jobPositionInfo.form.level.label')}
                            placeholder={i18n.t('employees:employee.profile.jobPositionInfo.form.level.placeholder')}
                            getOptionLabel={jobPositionLevel => jobPositionLevel.name}
                            getOptionValue={jobPositionLevel => jobPositionLevel.id}
                        />

                        <FormInput
                            name='alias'
                            label={i18n.t('employees:employee.profile.jobPositionInfo.form.alias.label')}
                            placeholder={i18n.t('employees:employee.profile.jobPositionInfo.form.alias.placeholder')}
                            control={form.control}
                            defaultValue={employee.jobPositionAlias}
                        />

                        <SubmitButton data-testid="employee-job-position-submit-button">{i18n.t('employees:employee.buttons.save')}</SubmitButton>
                    </>
                )
                }
            </Form>
        </SimpleCard>
    )
}

export default EmployeeContractJobPositionForm
