import * as React from 'react'

import getFieldsErrorsJSON from '../../dataAccess/helper/getFieldsErrorsJSON'

const useResponse400Errors = (response: Parameters<typeof getFieldsErrorsJSON>[0]): ReturnType<typeof getFieldsErrorsJSON> => {
    const errors = React.useMemo<ReturnType<typeof getFieldsErrorsJSON>>(() => response && getFieldsErrorsJSON(response), [response])

    return errors
}

export default useResponse400Errors 