import { Employee } from '@viterbit/web-app/dataAccess/model/Employee.model'
import { PermissionAction } from '@viterbit/web-app/dataAccess/model/PermissionAction.model'
import SimpleCard from '@viterbit/web-app/shared/display/SimpleCard'
import EmployeeAvatar from '@viterbit/web-app/shared/employee/EmployeeAvatar'
import { useContext } from 'react'

import EmployeePersonalAvatarForm from './EmployeePersonalAvatarForm'
import EmployeeContext from '../../EmployeeContext'

type PersonalAvatarSectionProps = {
    employee: Employee
}

const EmployeePersonalAvatar = ({ employee }: PersonalAvatarSectionProps) => {
    const {hasPermission} = useContext(EmployeeContext)
    const hasPermissionToEdit = hasPermission(PermissionAction.CORE_EMPLOYEE_PERSONAL_UPDATE, employee.id)

    return (
        <>
            <SimpleCard >
                <article className='flex flex-col items-center justify-center p-8'>
                    <EmployeeAvatar employee={employee} className='mb-4' size="xl" />
                    {
                        hasPermissionToEdit && (
                            <EmployeePersonalAvatarForm employee={employee}>
                                <div className='text-center truncate'>
                                    <p className='font-medium text-black'>{employee.name}</p>
                                    <p className='font-normal text-gray-r00'>
                                        {employee?.jobPositionLevel?.name || employee?.jobPosition?.name}
                                    </p>
                                </div>
                            </EmployeePersonalAvatarForm>
                        )
                    }
                </article>
            </SimpleCard>
        </>
    )
}
export default EmployeePersonalAvatar
