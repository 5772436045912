export const getClasses = (strokeColor: string) => ({
    progress: 'inline-block',
    'circle:': 'relative w-full',
    'progress-circle': 'relative',
    line: 'relative w-full flex items-center',
    'progress-bg': 'rounded-full transition-all duration-200',
    'progress-inner':
        'relative w-full overflow-hidden rounded-full bg-gray-100 dark:bg-gray-600',
    'progress-wrapper': 'w-full',
    'progress-circle-info':
        'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2',
    'progress-circle-trail': 'stroke-gray-100 text-gray-100 dark:text-black',
    'progress-circle-stroke': `stroke-${strokeColor} transition-all duration-200`,
})
