/* eslint-disable @typescript-eslint/no-explicit-any */
import { UpdateLeaveAdjustmentsCommand } from 'viterbit-api-sdk'

import { IMapper } from './__common'
import { LeaveAdjustment } from '../model/LeaveAdjustment.model'

const leaveAdjustmentMapper: IMapper<LeaveAdjustment, UpdateLeaveAdjustmentsCommand> = {
    toModel: (dto) => ({
        operation: dto.operation,
        reason: dto.reason || '',
        year: dto.year,
    }),

    toDto: (model) => ({
        operation: model.operation,
        reason: model.reason,
        year: model.year,
    }) as any,
}

export default leaveAdjustmentMapper
