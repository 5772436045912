/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import fileMapper from '@viterbit/web-app/dataAccess/mapper/file.mapper'
import { fileCreate } from '@viterbit/web-app/dataAccess/service/filesService'
import i18n from '@viterbit/web-app/i18n'
import * as React from 'react'
import { Controller } from 'react-hook-form'
import ControlledFormControl, {
    ControlledFormControlProps,
} from 'ui/src/components/Form/ControlledFormControl'
import { FormBaseProps } from 'ui/src/components/Form/shared'
import Upload, { UploadProps } from 'ui/src/components/Upload/Upload'

import useUploadFiles, { UploadFilesConfig } from './useUploadFiles'

type FormUploadProps = ControlledFormControlProps &
    FormBaseProps &
    UploadProps &
    UploadFilesConfig

const FormUpload = ({
    name,
    control,
    label,
    labelWidth,
    labelClass,
    labelStyle,
    htmlFor,
    extra,
    style,
    asterisk,
    defaultValues = [],
    employeeId,
    kind,
    multiple,
    onUploadSuccess,
    cleanSuccess,
    maxUploadSize= 10485760,
    mapper = fileMapper,
    createService = fileCreate,
    ...rest
}: FormUploadProps) => {

    const {
        files,
        handleOnChange,
        handleOnFileRemove,
        isLoading,
        retryUploadFile,
        LimitChecker,
    } = useUploadFiles({
        kind,
        employeeId,
        cleanSuccess,
        onUploadSuccess,
        createService,
        mapper,
        defaultValues,
        maxUploadSize,
        multiple,
    })

    return (
        <ControlledFormControl
            control={control}
            name={name}
            label={label}
            labelWidth={labelWidth}
            asterisk={asterisk}
            labelClass={labelClass}
            labelStyle={labelStyle}
            htmlFor={htmlFor}
            extra={extra}
            style={style}
        >
            <Controller
                control={control}
                name={name}
                rules={{
                    validate: (_) =>
                        (!isLoading &&
                            !files.some(
                                (x) => x._meta && x._meta.status !== 'ok'
                            )) ||
                        'required',
                }}
                defaultValue={
                    defaultValues instanceof Array
                        ? Array.from(defaultValues).map((x) => x.id)
                        : defaultValues?.id
                }
                render={({ field }) => (
                    <Upload
                        {...rest}
                        multiple={multiple}
                        files={files}
                        onChange={(files) =>
                            handleOnChange(files, field.onChange)
                        }
                        onFileRemove={(id) =>
                            handleOnFileRemove(id, field.onChange)
                        }
                        onRetryUpload={(file) =>
                            retryUploadFile(file, field.onChange)
                        }
                        beforeUpload={LimitChecker}
                        t={key => i18n.t(`ui:${key}`)}
                    />
                )}
            />
        </ControlledFormControl>
    )
}

export default FormUpload
