import * as React from 'react'
import { Api } from 'types/Api'
import Dialog from 'ui/src/components/Dialog'

import CreateEmployeeForm from './EmployeeCreateForm'

type EmployeeCreateDialogProps = {
    onClose?: () => void
    onSubmitCallBack?: (data: Api.ResponseSuccess) => void
    title?: string
    open: boolean
}

const EmployeeCreateDialog = (props: EmployeeCreateDialogProps) => {
    const { onClose, title = 'Create Employee', open, ...rest } = props

    if (!open) return null

    return (
        <Dialog
            open={open} 
            onClose={onClose} 
            className='p-6'
            title={title}
        >
            {open && <CreateEmployeeForm onCancel={onClose} {...rest} />}
        </Dialog>
    )
}

export default EmployeeCreateDialog
