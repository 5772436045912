/* eslint-disable @typescript-eslint/no-explicit-any */
import pick from 'lodash/pick'
import * as yup from 'yup'
import YupPassword from 'yup-password'
YupPassword(yup as any)
const text = yup.string().nullable()

const required = text.required('required')

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const notEmpty = yup.mixed().test({
    name: 'notEmpty',
    message: 'required',
    test: (value) => {
        if (typeof value === 'string') {
            return value.trim() !== ''
        }
        return value !== null && value !== undefined
    },
})

const maxChar = (number: number) =>
    text.max(number, 'max-character-limit-exceeded')

const maxCharTrimmed = (number: number) => maxChar(number).trim()

const nameRegex =
    /^$|^[A-Za-z-'.ÀÈÌÒÙàèìòùÁÉÍÓÚÝáéíóúýÂÊÎÔÛâêîôûÃÑÕãñõÄËÏÖÜŸäëïöüçÇŒœßØøÅåÆæÞþÐð ]+$/
const lettersOnly = maxCharTrimmed(100).matches(nameRegex, 'letters-only')

const emailRegex =
    /^($)|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$|""/
const email = text
    .trim()
    .required('required')
    .matches(emailRegex, { message: 'incorrect-format-email' })

const requiredLettersOnly = lettersOnly.required('required')

const name = requiredLettersOnly.max(40, 'max-length')
const surname = requiredLettersOnly.max(40, 'max-length')
const position = requiredLettersOnly.max(40, 'max-length')
// TODO use later
// const password = text
//   .required('required')
//   .min(8, 'password-must-contain-8-or-more-characters')
//   .minLowercase(1, 'password-must-contain-at-least-1-lower-case-letter')
//   .minUppercase(1, 'password-must-contain-at-least-1-upper-case-letter')
//   .minNumbers(1, 'password-must-contain-at-least-1-number')
//   .minSymbols(1, 'password-must-contain-at-least-1-special-character')
const password = yup
    .string()
    .required('required')
    .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
        'Invalid password'
    )
const confirmPassword = text
    .required()
    .oneOf([yup.ref('password'), null], 'passwords-must-match')

const commonValidations = {
    name,
    surname,
    email,
    position,
    required,
    password,
    confirmPassword,
    notEmpty,
}

const getSchema = (fields: string[], extraValidations = {}) =>
    yup
        .object()
        .shape({ ...pick(commonValidations, fields), ...extraValidations })

export { commonValidations, getSchema }
