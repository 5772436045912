import * as React from 'react'

const classes = {
    paginationTotal: 'font-semibold mr-3 text-gray-500 dark:text-gray-400',
}

type TotalProps = {
    total: number
}

const Total = ({ total }: TotalProps) => (
    <div data-testid='pagination-total' className={classes.paginationTotal}>
        Total <span>{total}</span> Items
    </div>
)

export default Total
