/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { classValidatorResolver } from '@hookform/resolvers/class-validator'
import { accountLogin, redeemForgotPassword } from '@viterbit/web-app/dataAccess/service/authService'
import Form from '@viterbit/web-app/shared/form/Form'
import SubmitButton from '@viterbit/web-app/shared/form/SubmitButton'
import useJwtToken, { JwtTokenHook } from '@viterbit/web-app/shared/utils/useJwtToken'
import { JwtPayload } from 'jwt-decode'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import Alert from 'ui/src/components/Alert'
import { FormPasswordInput } from 'ui/src/components/Form'
import FormPasswordInputValidated from 'ui/src/components/Form/FormPasswordInputValidated'
import LayoutHeading from 'ui/src/components/LayoutHeading'

import ForgotPasswordRedeemValidator from './ForgotPasswordRedeemValidator'


interface CustomJWT extends JwtPayload {
    email: string
}

interface CustomJwtTokenHook extends JwtTokenHook {
    data: CustomJWT
}

type Props = {
    token: string
    initialData?: CustomJWT
}

export type ForgotPasswordFormFields   = {
    password: string
    confirmPassword: string
}

const resolver = classValidatorResolver(ForgotPasswordRedeemValidator)

const ForgotPasswordRedeemDetail = ({ token, initialData }: Props) => {
    const navigate = useNavigate()
    const { error, isExpired } = useJwtToken(
        token,
        initialData
    ) as CustomJwtTokenHook

    // TODO: (improve) bad request feedback
    if (error) {
        return (
            <Alert title='Error' type='danger'>
                The request is invalid
            </Alert>
        )
    }

    // TODO: (improve) token expired feedback
    if (isExpired) {
        return (
            <Alert title='Error' type='danger'>
                The request is expired
            </Alert>
        )
    }

    return (
        <>
            <LayoutHeading
                title='Password recovery'
                subtitle='Please write a new password'
            />
            
            <Form<ForgotPasswordFormFields>
                withTransition
                mutationConfig={{
                    mutationKey: ['forgot-password'],
                    mutationFn: (data) => redeemForgotPassword({redeemForgotPasswordCommand: {...data, token}}),
                    onSuccess: (data, variables) => accountLogin({
                        logInCommand: 
                        {
                            password: variables.password,
                            username: initialData!.email,
                        }}
                    ).then(() => {
                        navigate('/')
                    }),
                }}
                formConfig={{
                    resolver,
                    mode: 'all',
                }}
            >
                {(form) => (
                    <>
                        <FormPasswordInputValidated
                            asterisk
                            name='password'
                            control={form.control}
                            label='Password'
                            placeholder='Enter your password'
                        />
                        <FormPasswordInput
                            asterisk
                            name='confirmPassword'
                            control={form.control}
                            label='Repeat Password'
                            placeholder='Repeat your password'
                        />

                        <SubmitButton>Save</SubmitButton>
                    </>
                )}
            </Form>
        </>
    )
}

export default ForgotPasswordRedeemDetail
