/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FindTeamQueryResponse } from './FindTeamQueryResponse';
import {
    FindTeamQueryResponseFromJSON,
    FindTeamQueryResponseFromJSONTyped,
    FindTeamQueryResponseToJSON,
} from './FindTeamQueryResponse';
import type { ListMeta } from './ListMeta';
import {
    ListMetaFromJSON,
    ListMetaFromJSONTyped,
    ListMetaToJSON,
} from './ListMeta';

/**
 * 
 * @export
 * @interface ListTeamQueryResponse
 */
export interface ListTeamQueryResponse {
    /**
     * 
     * @type {ListMeta}
     * @memberof ListTeamQueryResponse
     */
    meta: ListMeta;
    /**
     * 
     * @type {Array<FindTeamQueryResponse>}
     * @memberof ListTeamQueryResponse
     */
    data: Array<FindTeamQueryResponse>;
}

/**
 * Check if a given object implements the ListTeamQueryResponse interface.
 */
export function instanceOfListTeamQueryResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "meta" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function ListTeamQueryResponseFromJSON(json: any): ListTeamQueryResponse {
    return ListTeamQueryResponseFromJSONTyped(json, false);
}

export function ListTeamQueryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListTeamQueryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'meta': ListMetaFromJSON(json['meta']),
        'data': ((json['data'] as Array<any>).map(FindTeamQueryResponseFromJSON)),
    };
}

export function ListTeamQueryResponseToJSON(value?: ListTeamQueryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'meta': ListMetaToJSON(value.meta),
        'data': ((value.data as Array<any>).map(FindTeamQueryResponseToJSON)),
    };
}

