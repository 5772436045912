import * as React from 'react'

import StatusIcon from '../StatusIcon'
import { clsxm } from '../../lib/clsxm'

const DEFAULT_TYPE = 'info'

const TYPE_MAP = {
    success: {
        solid: {
            root: 'bg-success-50 text-success-700',
            icon: 'text-success-800',
            title: 'text-success-800',
        },
        outline: {
            root: 'bg-white text-success-600',
            icon: 'text-success-600',
            title: 'text-success-700',
        }
    },
    info: {
        solid: {
            root: 'bg-info-50 text-info-700',
            icon: 'text-info-800',
            title: 'text-info-800',
        },
        outline: {
            root: 'bg-white text-gray-500',
            icon: 'text-gray-500',
            title: 'text-black',
        }
    },
    warning: {
        solid: {
            root: 'bg-warning-50 text-warning-700',
            icon: 'text-warning-800',
            title: 'text-warning-800',
        },
        outline: {
            root: 'bg-white text-warning-600',
            icon: 'text-warning-600',
            title: 'text-warning-700',
        }
    },
    danger: {
        solid: {
            root: 'bg-danger-50 text-danger-700',
            icon: 'text-danger-800',
            title: 'text-danger-800',
        },
        outline: {
            root: 'bg-white text-danger-600',
            icon: 'text-danger-600',
            title: 'text-danger-700',
        }
    },
}

export type AlertProps = {
    type?: 'success' | 'danger' | 'warning' | 'info'
    variant?: 'solid' | 'outline'
    showIcon?: boolean
    icon?: React.ReactNode
    title?: string
    className?: string
    children?: React.ReactNode
    endContent?: React.ReactNode | string
    border?: boolean
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
    (props: AlertProps, ref) => {
        const {
            type = DEFAULT_TYPE,
            className,
            children,
            title,
            showIcon,
            icon,
            endContent,
            variant = 'solid',
            border,
            ...rest
        } = props

        const typeMap = TYPE_MAP[type]?.[variant]

        if (!typeMap) {
            throw new Error(`Invalid type: ${type} or variant: ${variant}`)
        }

        return (
            <div
                ref={ref}
                className={clsxm(
                    'alert',
                    'px-4 py-2 relative flex gap-3 items-center justify-center',
                    typeMap.root,
                    !title ? '' : '',
                    'rounded-lg',
                    border ? 'border border-gray-300' : 'shadow-sm',
                    className
                )}
                {...rest}
            >
                {showIcon && (
                    <StatusIcon
                        iconColor={clsxm(typeMap.icon)}
                        icon={icon}
                        type={type}
                    />
                )}

                <div className={clsxm('grow')}>
                    {title && (
                        <div
                            className={clsxm(typeMap.title, 'font-semibold')}
                        >
                            {title}
                        </div>
                    )}
                    {children}
                </div>

                {endContent}
            </div>
        )
    }
)

export default Alert
