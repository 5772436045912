/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react'

const DropdownContext = React.createContext<any>(null)

export const DropdownContextProvider = DropdownContext.Provider

export const DropdownContextConsumer = DropdownContext.Consumer

export default DropdownContext
