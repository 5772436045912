/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FindLeaveQueryResponse } from './FindLeaveQueryResponse';
import {
    FindLeaveQueryResponseFromJSON,
    FindLeaveQueryResponseFromJSONTyped,
    FindLeaveQueryResponseToJSON,
} from './FindLeaveQueryResponse';

/**
 * 
 * @export
 * @interface DataFindLeaveQueryResponse
 */
export interface DataFindLeaveQueryResponse {
    /**
     * 
     * @type {Array<FindLeaveQueryResponse>}
     * @memberof DataFindLeaveQueryResponse
     */
    data?: Array<FindLeaveQueryResponse>;
}

/**
 * Check if a given object implements the DataFindLeaveQueryResponse interface.
 */
export function instanceOfDataFindLeaveQueryResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DataFindLeaveQueryResponseFromJSON(json: any): DataFindLeaveQueryResponse {
    return DataFindLeaveQueryResponseFromJSONTyped(json, false);
}

export function DataFindLeaveQueryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataFindLeaveQueryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(FindLeaveQueryResponseFromJSON)),
    };
}

export function DataFindLeaveQueryResponseToJSON(value?: DataFindLeaveQueryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(FindLeaveQueryResponseToJSON)),
    };
}

