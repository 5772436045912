/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/function-component-definition */
import { classValidatorResolver } from '@hookform/resolvers/class-validator'
import dateMapper from '@viterbit/web-app/dataAccess/mapper/date.mapper'
import { Planning } from '@viterbit/web-app/dataAccess/model/Planning.model'
import { workScheduleReassign } from '@viterbit/web-app/dataAccess/service/workScheduledService'
import i18n from '@viterbit/web-app/i18n'
import { useCurrentEmployeeId } from '@viterbit/web-app/shared/employee/useCurrentEmployeeId'
import Form from '@viterbit/web-app/shared/form/Form'
import SubmitButton from '@viterbit/web-app/shared/form/SubmitButton'
import dayjs from 'dayjs'
import * as React from 'react'
import Button from 'ui/src/components/Button'
import Dialog from 'ui/src/components/Dialog'

import EmployeePlanningFields, { EmployeePlanningFieldsType } from '../EmployeePlanningFields'
import EmployeePlanningValidator from '../EmployeePlanningValidator'

const resolver = classValidatorResolver(EmployeePlanningValidator)

function EmployeePlanningUpdateDialog({
    isOpen,
    onClose,
    planning,
}:  {
    isOpen: boolean
    onClose?: () => void
    planning: Planning
}) {
    const employeeId = useCurrentEmployeeId()

    const defaultValues = React.useMemo<EmployeePlanningFieldsType>(() => ({
        startDate: dayjs(planning.startDate, 'YYYY-MM-DD').toDate(),
        type: planning.type,
        workScheduleId: planning.isWorkSchedule ? planning.workSchedule.id: null,
    }), [planning])

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            title={i18n.t('buttons.edit')}
        >
            <Form<EmployeePlanningFieldsType> 
                withTransition
                formConfig={{
                    resolver,
                    mode: 'all',
                    defaultValues,
                }}
                mutationConfig={{
                    mutationFn: (data) =>
                        workScheduleReassign({
                            updateAssignedWorkScheduledCommand: {
                                employeeId: employeeId!,
                                startDate: dateMapper.toDate(data.startDate)!,
                                workScheduleRecordId: planning.id,
                            }
                        }),
                    onSuccess: onClose,
                }}
            >
                {
                    (formMethods) => (
                        <>
                            <EmployeePlanningFields formMethods={formMethods} isEdit workScheduleDefaultOption={planning.workSchedule} />
                            <Button onClick={onClose}>{i18n.t('buttons.cancel')}</Button>
                            <SubmitButton data-testid="work-schedule-update-confirm">{i18n.t('buttons.save')}</SubmitButton>
                        </>
                    )
                }
            </Form>
        </Dialog>
    )
}

export default EmployeePlanningUpdateDialog