/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateLeaveReasonCommand
 */
export interface UpdateLeaveReasonCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateLeaveReasonCommand
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateLeaveReasonCommand
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateLeaveReasonCommand
     */
    color?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateLeaveReasonCommand
     */
    timeDiscount?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateLeaveReasonCommand
     */
    needApproval?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateLeaveReasonCommand
     */
    needDocumentation?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateLeaveReasonCommand
     */
    visibleOtherEmployees?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateLeaveReasonCommand
     */
    limitedTo?: number | null;
}

/**
 * Check if a given object implements the UpdateLeaveReasonCommand interface.
 */
export function instanceOfUpdateLeaveReasonCommand(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateLeaveReasonCommandFromJSON(json: any): UpdateLeaveReasonCommand {
    return UpdateLeaveReasonCommandFromJSONTyped(json, false);
}

export function UpdateLeaveReasonCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateLeaveReasonCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'color': !exists(json, 'color') ? undefined : json['color'],
        'timeDiscount': !exists(json, 'timeDiscount') ? undefined : json['timeDiscount'],
        'needApproval': !exists(json, 'needApproval') ? undefined : json['needApproval'],
        'needDocumentation': !exists(json, 'needDocumentation') ? undefined : json['needDocumentation'],
        'visibleOtherEmployees': !exists(json, 'visibleOtherEmployees') ? undefined : json['visibleOtherEmployees'],
        'limitedTo': !exists(json, 'limitedTo') ? undefined : json['limitedTo'],
    };
}

export function UpdateLeaveReasonCommandToJSON(value?: UpdateLeaveReasonCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'color': value.color,
        'timeDiscount': value.timeDiscount,
        'needApproval': value.needApproval,
        'needDocumentation': value.needDocumentation,
        'visibleOtherEmployees': value.visibleOtherEmployees,
        'limitedTo': value.limitedTo,
    };
}

