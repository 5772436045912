import * as React from 'react'

import { LeavesClusters } from '../types'



type ClusterContentContextType = {
    cluster: LeavesClusters
    onChange: (status: LeavesClusters) => void
}

export const ClusterContext = React.createContext<ClusterContentContextType>({
    cluster: LeavesClusters.UPCOMING,
    onChange: () => true,
})

const ClusterProvider = ({ children }: { children: React.ReactNode }) => {
    const [cluster, setCluster] = React.useState<LeavesClusters>(
        LeavesClusters.UPCOMING
    )

    const onChange = (status: LeavesClusters) => {
        setCluster(status)
    }

    return (
        <ClusterContext.Provider value={{ cluster, onChange }}>
            {children}
        </ClusterContext.Provider>
    )
}

export default ClusterProvider
