import { Summary } from '@viterbit/web-app/dataAccess/model/Summary.model'
import * as React from 'react'
import Dropdown from 'ui/src/components/Dropdown'

import SummaryTotals from './SummaryTotals'

type SummaryDetailsTooltipProps = {
    children: React.ReactNode
    summary: Summary
}

const SummaryTotalsTooltip = ({
    children,
    summary,
}: SummaryDetailsTooltipProps) => (
    <Dropdown renderTitle={children} placement='bottom-end' trigger='hover'>
        <SummaryTotals daysConsumedPerReason={summary.daysConsumedPerReason} />
    </Dropdown>
)

export default SummaryTotalsTooltip
