/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PermissionsActionItemResponse } from './PermissionsActionItemResponse';
import {
    PermissionsActionItemResponseFromJSON,
    PermissionsActionItemResponseFromJSONTyped,
    PermissionsActionItemResponseToJSON,
} from './PermissionsActionItemResponse';

/**
 * 
 * @export
 * @interface PermissionsActionResponse
 */
export interface PermissionsActionResponse {
    /**
     * 
     * @type {string}
     * @memberof PermissionsActionResponse
     */
    action: string;
    /**
     * 
     * @type {Array<PermissionsActionItemResponse>}
     * @memberof PermissionsActionResponse
     */
    value: Array<PermissionsActionItemResponse>;
}

/**
 * Check if a given object implements the PermissionsActionResponse interface.
 */
export function instanceOfPermissionsActionResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "action" in value;
    isInstance = isInstance && "value" in value;

    return isInstance;
}

export function PermissionsActionResponseFromJSON(json: any): PermissionsActionResponse {
    return PermissionsActionResponseFromJSONTyped(json, false);
}

export function PermissionsActionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PermissionsActionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'action': json['action'],
        'value': ((json['value'] as Array<any>).map(PermissionsActionItemResponseFromJSON)),
    };
}

export function PermissionsActionResponseToJSON(value?: PermissionsActionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'action': value.action,
        'value': ((value.value as Array<any>).map(PermissionsActionItemResponseToJSON)),
    };
}

