
import { jobPositionCreate } from '@viterbit/web-app/dataAccess/service/jobPositionService'
import i18n from '@viterbit/web-app/i18n'
import BasicCreateFormDialog from '@viterbit/web-app/shared/form/BasicCreateFormDialog'
import ButtonCreate from '@viterbit/web-app/shared/form/ButtonCreate'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'

const JobPositionCreate = () => {
    const [open, setOpen] = React.useState(false)
    const navigate = useNavigate()

    const onSuccess = async (result) => {
        navigate(`/employees/positions/${result.id}/details`)
        onClose()
    }

    const onClose = () => {
        setOpen(false)
    }

    return <>
        <ButtonCreate collapsed title={i18n.t('employees:jobPositions.createButton')} onClick={() => setOpen(true)} />

        <BasicCreateFormDialog
            isOpen={open}
            withTransition
            title={i18n.t('employees:jobPositions.createTitle')}
            onClose={onClose}
            label={i18n.t('employees:jobPositions.createLabel')}
            placeholder={i18n.t('employees:jobPositions.createPlaceholder')}
            mutationConfig={{
                onSuccess,
                mutationFn: (data) => jobPositionCreate({createJobPositionCommand: data}),
            }}
        />

    </>
}

export default JobPositionCreate
