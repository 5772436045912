
import { useCurrentEmployeeId } from '@viterbit/web-app/shared/employee/useCurrentEmployeeId'

import { useEmployeeDetail } from './useEmployeeDetail'

export const useCurrentEmployee = () => {
    const employeeId = useCurrentEmployeeId()
    const { data } = useEmployeeDetail({
        query: {
            id: employeeId!,
        },
        enabled: !!employeeId,
    })

    return data || null
}
