import { useQuery } from '@tanstack/react-query'
import { COMPANY_QUERY_KEY } from '@viterbit/web-app/dataAccess/cacheKeys'
import { companyDetail } from '@viterbit/web-app/dataAccess/service/companyService'

type CompanyDetailConfig = {
    query: Parameters<typeof companyDetail>[0]
    enabled?: boolean
    onSuccess?: () => void
    onError?: () => void
}

export const useCompanyDetail = ({ query, enabled, onSuccess, onError }: CompanyDetailConfig) =>
    useQuery({
        queryFn: () => companyDetail(query), 
        queryKey: [COMPANY_QUERY_KEY, 'detail', query],
        enabled,
        onSuccess,
        onError,
    })

