import { IsNotEmpty, IsString } from 'class-validator'


class CreateLocationValidator {
    @IsString()
    @IsNotEmpty()
    name: string
    @IsString()
    @IsNotEmpty()
    country: string
    @IsString()
    @IsNotEmpty()
    state: string
}

export default CreateLocationValidator