import { useQuery } from '@tanstack/react-query'

import { ACCOUNT_QUERY_KEY } from '../../cacheKeys'
import { accountListAll } from '../../service/accountService'

export type UseAccountListAllConfig = {
    query: Parameters<typeof accountListAll>[0]
    enabled?: boolean
    keepPreviousData?: boolean
}

export const useAccountListAll = (config?: UseAccountListAllConfig) => {
    const { query = { page: 1 }, enabled, keepPreviousData } = config || {}

    return useQuery({
        queryKey: [ACCOUNT_QUERY_KEY, 'list-all-paginated', query],
        queryFn: () => accountListAll(query),
        enabled,
        keepPreviousData,
    })
}

