import { ChangePasswordCommand, ChangeRoleCommand, CreateAccountCommand, FindAccountQueryResponse, FindAccountQueryResponseLocaleEnum, FindAccountQueryResponseTimezoneEnum, InviteCommand, ListAllAccountItemQueryResponse, RedeemInviteCommand, UpdateAccountCommand } from 'viterbit-api-sdk'


export type AccountResponse = FindAccountQueryResponse
export type AccountExtendedResponse = ListAllAccountItemQueryResponse

export type InvitePayload = InviteCommand

export type RedeemInvitePayload = RedeemInviteCommand

export type ChangeAccountRolePayload = ChangeRoleCommand

export type UpdateAccountPayload = UpdateAccountCommand

export type TimezoneEnum = FindAccountQueryResponseTimezoneEnum

export type LocaleEnum = FindAccountQueryResponseLocaleEnum

export const TimezoneSelectOptions = Object.values(FindAccountQueryResponseTimezoneEnum).map(x => ({'label': x, 'value': x}))

export const LocaleSelectOptions = Object.values(FindAccountQueryResponseLocaleEnum).map(x => ({'label': x, 'value': x}))

export type ChangePasswordPayload = ChangePasswordCommand

export type CreateAccountPayload = CreateAccountCommand