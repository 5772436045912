/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react'

import Button from '../Button'

type CheckboxButtonInputProps = {
    children?: React.ReactNode
    name?: string
    value: string
    type?: 'checkbox' | 'radio'
    onClick?: () => void
    className?: string
    disabled?: boolean
}

const CheckboxButtonInput = React.forwardRef<HTMLInputElement, any>(
    (props: CheckboxButtonInputProps, ref) => {
        const { children, name, value, type = 'checkbox', onClick, className, disabled, ...rest } = props
        const id = `input-buttons-option-${name}-${value}}`
        return (
            <div className="contents">
                <input
                    {...rest}
                    type={type}
                    ref={ref}
                    name={name}
                    value={value}
                    id={id}
                    className='peer'
                    hidden
                />
                <Button
                    disabled={disabled}
                    htmlFor={id}
                    component='label'
                    className={`w-full px-0 text-center cursor-pointer peer-checked:border-primary-600 peer-checked:text-primary-600 ${className}`}
                    onClick={onClick}
                >
                    {children}
                </Button>
            </div>
        )
    }
)

export default CheckboxButtonInput
