/* eslint-disable react/no-array-index-key */
import { Avatar } from '@mantine/core'
import * as React from 'react'
import { IFile } from 'ui/src/components/Upload/sharedTypes'

import ChatBarMessageFiles from './ChatMessageFiles'
import { formatTime } from '../../utils/dateFormat'

export type ChatMessage = {
    id: string
    message: string
    employeeName?: string | null
    createdAt: string
    files: IFile[]
}

export type ChatMessageProps = {
    messages: ChatMessage[]
    className?: string
    variant?: 'left' | 'right'
    avatarProps?: React.ComponentPropsWithoutRef<typeof Avatar>
    employeeName?: ChatMessage['employeeName']
}

const styles = {
    root: ''
}

type ChatGroupByDateProps = {
    children: string | React.ReactNode | React.ReactNode[]
    date: string
    variant: 'left' | 'right'
}

const ChatDate = (props) => <span className={`${props.variant === 'right' ? '-translate-x-8': ''} w-0 p-2 pb-0 text-xs text-gray-400 `}>{formatTime(props.date)}</span>


const ChatGroupByDate = (props: ChatGroupByDateProps) => {
    const { children, date, variant } = props
    return (
        <div className={`flex flex-row flex-wrap gap-2 items-start ${variant === 'right' ? 'justify-end': 'justify-start'}`}>
            {variant === 'right' && (<ChatDate date={date} variant={variant} />)}
            {children}
            {variant === 'left' && (<ChatDate date={date} variant={variant} />)}
        </div>
    )
}

const ChatMessage = (props: ChatMessageProps) => {
    const { messages, avatarProps, variant = 'right' } = props
    
    return (
        <div className={`flex items-end ${variant === 'right' && 'justify-end self-end'}`}>
            <div className={`flex flex-col items-start order-2 max-w-sm  mx-2 space-y-2 ${variant === 'right' && 'items-end'}`}>
                {messages.map((item, index) => (
                    <div
                        key={index}
                        className='relative flex flex-col items-end gap-2'
                    >
                        {item.message && (
                            <ChatGroupByDate
                                date={item.createdAt}
                                variant={variant}
                            >
                                <span
                                    data-testid="chat-message"
                                    className={`inline-block px-4 py-2 text-xs tracking-wide font-semibold rounded-lg ${variant === 'left' ? 'rounded-bl-none bg-gray-100 ': 'rounded-br-none bg-primary-100 ' }`}
                                >
                                    {item.message}
                                </span>
                            </ChatGroupByDate>
                        )}

                        {!!item.files.length && (
                            <ChatGroupByDate
                                date={item.createdAt}
                                variant={variant}
                            >
                                <ChatBarMessageFiles files={item.files} variant={variant} className="" />
                            </ChatGroupByDate>
                        )}
                        

                    </div>
                ))}
            </div>
            <Avatar {...avatarProps} radius="xl" size={32} className={variant === 'left' ? 'order-1' : 'order-2'} />
        </div>
    )
}

export default ChatMessage
