import { Employee } from '@viterbit/web-app/dataAccess/model/Employee.model'
import { useTeamMemberList } from '@viterbit/web-app/dataAccess/useCase/team/useTeamMemberList'
import { MRT_PaginationState } from 'mantine-react-table'
import * as React from 'react'

import TeamMemberList from './TeamMemberList'

type TeamMemberViewProps = {
    params: {
        id: string
    },
    onDeleteItem: (employee: Employee) => void

}
const TeamMemberView =  ({params, onDeleteItem}: TeamMemberViewProps) => {
    const [pagination, setPagination] = React.useState<MRT_PaginationState>({
        pageIndex: 1,
        pageSize: 2,
    })

    const { data, isLoading } = useTeamMemberList({
        query: {
            id: params.id,
            page: pagination.pageIndex,
            pageSize: pagination.pageSize,
        }
    })
    
    return (
        <TeamMemberList
            members={data}
            teamId={params.id}
            pagination={pagination}
            onDeleteItem={onDeleteItem}
            loading={isLoading}
            onPaginationChange={setPagination}
        />
    )
}

export default TeamMemberView