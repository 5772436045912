import * as React from 'react'
import clsxm from 'ui/src/lib'

type SimpleCardBodyProps = {
    children: React.ReactNode
    className?: string
}

const SimpleCardBody = ({ children, className }: SimpleCardBodyProps) => (
    <div className={clsxm('overflow-y-auto', className)}>{children}</div>
)

export default SimpleCardBody
