/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/function-component-definition */
import { classValidatorResolver } from '@hookform/resolvers/class-validator'
import i18n from '@viterbit/web-app/i18n'
import * as React from 'react'
import { DeepPartial, FieldValues, Resolver } from 'react-hook-form'
import { Api } from 'types/Api'
import Button from 'ui/src/components/Button'
import Dialog from 'ui/src/components/Dialog'
import FormInput from 'ui/src/components/Form/FormInput'

import { BasicCreateFormValidator } from './BasicCreateFormValidator'
import Form, { FormProps } from './Form'
import SubmitButton from './SubmitButton'


const defaultResolver = classValidatorResolver(BasicCreateFormValidator)

function BasicCreateFormDialog<FormValues extends FieldValues = Api.PayloadBase>({
    isOpen,
    onClose,
    cancelButtonText = 'cancel',
    submitButtonText = 'create',
    title,
    resolver = defaultResolver,
    defaultValues,
    label,
    placeholder,
    ...rest
}: Omit<FormProps<FormValues>, 'children'> & {
    isOpen: boolean
    onClose?: () => void
    title: string
    submitButtonText?: string
    cancelButtonText?: string
    resolver?: Resolver<FormValues, any>,
    defaultValues?: DeepPartial<FormValues>
    label?: string
    placeholder?: string
} ) {
    return (
        <Dialog open={isOpen} onClose={onClose} title={title}>
            <Form<FormValues> 
                withTransition
                formConfig={{
                    resolver,
                    mode: 'all',
                    defaultValues
                }}
                {...rest}
            >
                {
                    (formMethods) => (
                        <>
                            <FormInput
                                asterisk
                                name='name'
                                label={label ?? i18n.t('components.basicCreate.label')}
                                placeholder={placeholder ?? i18n.t('components.basicCreate.placeholder')}
                                control={formMethods.control}
                            />
                            <Button onClick={onClose}>{cancelButtonText !== 'cancel' ? cancelButtonText : i18n.t('buttons.' + cancelButtonText)}</Button>
                            <SubmitButton>{submitButtonText !== 'create' ? submitButtonText : i18n.t('buttons.' + submitButtonText)}</SubmitButton>
                        </>
                    )
                }
            </Form>
        </Dialog>
    )
}

export default BasicCreateFormDialog