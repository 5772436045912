import * as React from 'react'

import { clsxm } from '../../lib/clsxm'

const classes = {
    item: 'flex min-h-[70px]',
    itemLast: 'flex min-h-[70px]',
    itemMedia: 'flex flex-col items-center',
    itemMediaContent: 'my-1',
    itemMediaDefault: 'rounded-full bg-gray-200 dark:bg-gray-600 h-4 w-4',
    itemWrapper: 'flex flex-auto',
    itemContentLast: 'pb-0',
    itemContent: ' ml-4 rtl:mr-4 pb-6 pt-0.5 w-full',
    connect: 'h-full w-[2px] bg-gray-200 dark:bg-gray-600 flex-grow',
}

type TimelineItemProps = {
    media?: React.ReactNode | string
    children?: React.ReactNode
    className?: string
    isLast?: boolean
}

const TimelineItem = React.forwardRef<HTMLLIElement, TimelineItemProps>(
    ({ media, isLast, children, className }, ref) => (
        <li
            className={clsxm(
                classes.item,
                isLast ? classes.itemLast : '',
                className
            )}
            ref={ref}
        >
            <div className={classes.itemWrapper}>
                <div className={classes.itemMedia}>
                    <div className={classes.itemMediaContent}>
                        {media || (
                            <div className={classes.itemMediaDefault} />
                        )}
                    </div>
                    {!isLast && <div className={classes.connect} />}
                </div>
                <div
                    className={clsxm(
                        classes.itemContent,
                        isLast && classes.itemContentLast
                    )}
                >
                    {children}
                </div>
            </div>
        </li>
    )
)
export default TimelineItem
