import { useQuery } from '@tanstack/react-query'

import { ACCOUNT_QUERY_KEY } from '../../cacheKeys'
import { accountDetail } from '../../service/accountService'

type AccountDetailConfig = {
    query: Parameters<typeof accountDetail>[0]
    enabled?: boolean
}

export const useAccountDetail = ({ query, enabled }: AccountDetailConfig) =>
    useQuery({
        queryFn: () => accountDetail(query), 
        queryKey: [ACCOUNT_QUERY_KEY, 'detail', query],
        enabled
    })

