
import { IsNotEmpty, IsString } from '@viterbit/web-app/shared/utils/ClassValidators'

import { ComplaintLoginFields } from './ComplaintLoginFields'

class ComplaintLoginFormValidator implements ComplaintLoginFields {
    @IsNotEmpty()
    @IsString()
    id: string
    @IsNotEmpty()
    @IsString()
    password: string
}

export default ComplaintLoginFormValidator
