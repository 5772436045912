import * as React from 'react'

import { Subscription } from '../../dataAccess/model/Subscription.model'
import formatPrice from '../../shared/utils/formatPrice'

type BillingPricingProps = {
    children?: React.ReactNode
    className?: string
    subscription: Subscription
}

const BillingPricing = (props: BillingPricingProps) => {
    const { subscription } = props

    return (
        <p className="flex items-baseline justify-center mt-2 gap-x-2">
            <span className="text-5xl font-bold tracking-tight text-gray-800" data-testid="billing-pricing-price">
                {formatPrice({ amount: subscription.totalAmount, currency: subscription.currency })}
            </span>
        </p>

    )
}

export default BillingPricing
