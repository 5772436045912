import { useMutation } from '@tanstack/react-query'

import { complaintPrivateUpdate } from '../../service/complaintService'

type ComplaintPrivateUpdateConfig = {
    onSuccess?: () => void
    onError?: () => void
}

export const useComplaintPrivateUpdate = (config: ComplaintPrivateUpdateConfig = {}) =>
    useMutation({
        ...config,
        mutationFn: complaintPrivateUpdate,
        onSuccess: config.onSuccess,
        onError: config.onError,
    })
