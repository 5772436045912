import React from 'react'

import HeaderActionsActions from './HeaderActionsActions'
import { useEmbeddedContent } from '../../behaviour/EmbeddedContentContext'

const HeaderActionsEmbedded = (props) => {
    const ref = useEmbeddedContent()

    return <HeaderActionsActions {...props} ref={ref} />
}

export default HeaderActionsEmbedded