import * as React from 'react'

import { ListItemTextProps } from './List.d'
import clsxm from '../../lib'

const styles = {
    root: 'flex-1 min-w-0 truncate',
    primary: 'text-sm font-medium text-black line-clamp-1',
    secondary: 'text-sm text-black opacity-60 line-clamp-2'
}

const ListItemText = (props: ListItemTextProps) => {
    const { primary, secondary, className, children, onPrimaryTextClick, secondaryClassName, ...rest } = props

    return (
        <div className={clsxm(styles.root, className)} {...rest}>
            <span
                className={clsxm(
                    styles.primary,
                    onPrimaryTextClick && 'hover:text-primary-600 cursor-pointer'
                )}
                onClick={e => {
                    if(onPrimaryTextClick) {
                        e.preventDefault()
                        onPrimaryTextClick()
                    }

                }}
            >{primary}</span>
            <span className={clsxm(styles.secondary, secondaryClassName)}>{secondary}</span>
            {children}
        </div>
    )
}

export default ListItemText
