import { PermissionAction } from '@viterbit/web-app/dataAccess/model/PermissionAction.model'
import { useEmployeeDetail } from '@viterbit/web-app/dataAccess/useCase/employee/useEmployeeDetail'
import { EmployeeContextProvider } from '@viterbit/web-app/feature/employee/EmployeeContext'
import ForbiddenContent from '@viterbit/web-app/shared/display/ForbiddenContent'
import { useUser } from '@viterbit/web-app/shared/employee/useUser'
import useHasPermission from '@viterbit/web-app/shared/permission/useHasPermissions'
import { useMemo } from 'react'

import EmployeeDetailTabList, { EmployeeTabsProps } from './tab/EmployeeDetailTabList'

type EmployeeDetailViewProps = Omit<EmployeeTabsProps, 'employee' | 'tabsPermissions' | 'hasPermission'> & {
    id: string
    basePath: string
}

const EmployeeDetailView = (props: EmployeeDetailViewProps) => {
    const { id, basePath, children } = props
    const user = useUser()
    const { data: employee, isLoading } = useEmployeeDetail({
        query: {
            id,
        }
    })
    const {hasPermission, isLoading: isLoadingPermissions} = useHasPermission({
        CORE_EMPLOYEE_PERSONAL_INFORMATION_FIND: [id],
        CORE_EMPLOYEE_CONTRACT_FIND: [id],
        CORE_HR_LEAVE_LIST: [id],
        CORE_DOCUMENT_FILE_LIST: [id],
        CORE_HR_TIME_TRACKING_LIST: [id],

        CORE_ACCOUNT_CHANGE_ROLE: [id],
        CORE_EMPLOYEE_UPDATE: [id],
        CORE_EMPLOYEE_PERSONAL_UPDATE: [id],
        CORE_EMPLOYEE_CONTRACT_UPDATE: [id],
        CORE_EMPLOYEE_DELETE: [id],
        
        CORE_HR_WORK_SCHEDULES_LIST: [user.organizationId],
        CORE_ACCOUNT_INVITE: [id],
    })

    const tabPermissions = useMemo<EmployeeTabsProps['tabsPermissions']>(() => ({
        personal: hasPermission(PermissionAction.CORE_EMPLOYEE_PERSONAL_INFORMATION_FIND, id),
        profile: true,
        contracts: hasPermission(PermissionAction.CORE_EMPLOYEE_CONTRACT_FIND, id),
        leaves: hasPermission(PermissionAction.CORE_HR_LEAVE_LIST, id),
        documents: hasPermission(PermissionAction.CORE_DOCUMENT_FILE_LIST, id),
        'time-tracking': hasPermission(PermissionAction.CORE_HR_TIME_TRACKING_LIST, id),
        planning: hasPermission(PermissionAction.CORE_HR_WORK_SCHEDULES_LIST, user.organizationId),
    }), [hasPermission, isLoadingPermissions, employee])

    const computedLoading = isLoading || isLoadingPermissions || !employee

    return (
        <EmployeeDetailTabList
            isLoading={computedLoading}
            tabsPermissions={tabPermissions}
            basePath={basePath}
        >
            {!computedLoading && (
                <ForbiddenContent allow={!employee?.isDeleted} >
                    <EmployeeContextProvider value={{ employee, hasPermission, basePath }}>
                        {children}
                    </EmployeeContextProvider>
                </ForbiddenContent>
            )}
        </EmployeeDetailTabList>
    )
}

export default EmployeeDetailView
