import { PermissionAction } from '@viterbit/web-app/dataAccess/model/PermissionAction.model'
import { Team } from '@viterbit/web-app/dataAccess/model/Team.model'
import i18n from '@viterbit/web-app/i18n'
import AsideCardInfo from '@viterbit/web-app/shared/display/AsideCardInfo'
import IsGranted from '@viterbit/web-app/shared/permission/IsGranted'
import * as React from 'react'

import DeleteTeam from './TeamDelete'

type TeamDeleteActionProps = {
    team: Team
}

const TeamDeleteAction = ({ team }: TeamDeleteActionProps) => (
    <IsGranted
        id={team.id}
        action={PermissionAction.CORE_TEAM_DELETE}
    >
        <AsideCardInfo className='my-4' title={i18n.t('dangerZone')}>
            <DeleteTeam
                team={team}
                id={team.id}
            />
        </AsideCardInfo>
    </IsGranted>
)

export default TeamDeleteAction
