
import { useMutation } from '@tanstack/react-query'
import { JOB_POSITION_QUERY_KEY } from '@viterbit/web-app/dataAccess/cacheKeys'
import { jobPositionDelete } from '@viterbit/web-app/dataAccess/service/jobPositionService'
import i18n from '@viterbit/web-app/i18n'
import ApiErrorMessage from '@viterbit/web-app/shared/feedback/ApiErrorMessage'
import DeleteAlert from '@viterbit/web-app/shared/feedback/DeleteAlert'
import { useNotification } from '@viterbit/web-app/shared/feedback/useNotification'
import FormDeleteDialog from '@viterbit/web-app/shared/form/FormDeleteDialog'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import { JobPositionsFormFields } from '../../JobPositionShared'

type JobPositionLevelDeleteProps = {
    position: JobPositionsFormFields
    id: string
}

const JobPositionLevelDelete = ({ id, position}: JobPositionLevelDeleteProps) => {
    const notification = useNotification()
    const navigate = useNavigate()
    const [isDeleting, setIsDeleting] = React.useState(false)
    const onSuccess = () => {
        notification.push(
            'success', 
            i18n.t('common:notification.delete'), 
            i18n.t('common:notification.successDelete'), 
        )
        setIsDeleting(false)
        navigate('/employees/positions', { replace: true })
    }

    const { mutate, isLoading, error } = useMutation({
        mutationKey: [JOB_POSITION_QUERY_KEY],
        onSuccess,
        mutationFn: jobPositionDelete,
    })

    return (
        <>
            <DeleteAlert
                border
                buttonText={i18n.t('employees:jobPositions.delete.title')}
                onConfirm={() => setIsDeleting(true)}
            >
                {i18n.t('employees:jobPositions.delete.alert')}
            </DeleteAlert>

            <FormDeleteDialog
                isOpen={isDeleting}
                onClose={() => setIsDeleting(false)}
                cancelButtonText={i18n.t('common:buttons.cancel')}
                isLoading={isLoading}
                onSubmit={() => mutate({
                    id: id,
                })}
                submitButtonText={i18n.t('common:buttons.confirm')}
                title={i18n.t('employees:jobPositions.delete.title')}
            >
                <ApiErrorMessage error={error} />
            </FormDeleteDialog>
        </>
    )
}

export default JobPositionLevelDelete
