/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { IntegrationOptionGroup } from '@viterbit/web-app/dataAccess/model/IntegrationOption.model'
import i18n from '@viterbit/web-app/i18n'
import Subtitle from '@viterbit/web-app/shared/typography/Subtitle'
import clsxm from 'ui/src/lib'

import AccountSettingsIntegrationItem, { AccountSettingsIntegrationItemProps } from './AccountSettingsIntegrationItem'

type Props = {
    group: IntegrationOptionGroup
    className?: string
}

const AccountSettingsIntegrationsGroup = ({
    group,
    className,
}: Props) => {
    const optionsProps = group.options.map<AccountSettingsIntegrationItemProps>(x => ({
        option: x,
        kind: group.kind,
    }))

    return (
        <div className={clsxm(
            'flex flex-col gap-y-4 pt-3',
            className
        )}>
            <Subtitle bold>{i18n.t(`integrations:groups.${group.kind}.title`)}</Subtitle>
            <div className='grid grid-cols-1 gap-4 sm:grid-cols-2 xl:grid-cols-3'>
                {
                    optionsProps.map(props => (
                        <AccountSettingsIntegrationItem
                            {...props}
                            key={props.option.type}
                        />
                    ))
                }
            </div>
        </div>
    )
}

export default AccountSettingsIntegrationsGroup
