/* eslint-disable react/jsx-key */
import * as React from 'react'
import { Outlet } from 'react-router-dom'
import SideLayout from 'ui/src/components/SideLayout'

import Logo from '../display/Logo'


const PublicLayout = () => (
    <SideLayout
        logo={<Logo color='white'/>}
        author='Marta Luque Vadillos'
        authorPosition='Technical Talent Acquisition Specialist en IZERTIS'
        sidebarContent='Me encanta esta plataforma. La plataforma en sí es super intuitiva y muy muy útil. Ha permitido ahorrar costes a mi empresa ampliando muchas posibilidades de gestión en diferentes partes del reclutamiento. ¡¡¡100% RECOMENDABLE!!!'
    >
        <Outlet />
    </SideLayout>
)


export default PublicLayout
