/* eslint-disable @typescript-eslint/no-non-null-assertion */
import dateMapper from '@viterbit/web-app/dataAccess/mapper/date.mapper'
import i18n from '@viterbit/web-app/i18n'
import EmbeddedContent from '@viterbit/web-app/shared/behaviour/EmbeddedContent'
import YearSelector from '@viterbit/web-app/shared/form/YearSelector'
import { formatMonthWithYear } from '@viterbit/web-app/shared/utils/dateFormat'
import dayjs from 'dayjs'
import * as React from 'react'
import { useParams } from 'react-router-dom'
import clsxm from 'ui/src/lib'

import getDaysFromDate from './getDaysFromDate'
import LeaveTeamGrid from './LeaveTeamGrid'
import LeaveTeamHeaderHeader from './LeaveTeamHeader'
import LeaveTeamPaginated from './LeaveTeamPaginated'
import useLeaveDialog from '../useLeaveDialog'
import EmployeeGenericFilters, { EmployeeGenericFiltersFields } from '../../employee/EmployeeGenericFilters'

const LeaveTeamDetail = () => {
    const { month } = useParams<'month'>()
    const [filters, setFilters] = React.useState<EmployeeGenericFiltersFields>({
        search: null,
        companyId: null,
        locationId: null,
        reporters: false,
    })

    const date = dayjs(month)
    const currentMonth = dateMapper.toYearWithMonth(date)!
    const nextMonth = dateMapper.toYearWithMonth(date.add(1, 'month'))
    const prevMonth = dateMapper.toYearWithMonth(date.subtract(1, 'month'))

    const searchParams = React.useMemo(() => ({
        month: currentMonth,
        search: filters.search || undefined,
        companyId: filters.companyId || undefined,
        locationId: filters.locationId || undefined,
        reporters: filters.reporters || undefined,
    }), [filters, month])

    const parsedSearch = React.useMemo(() => {
        if (!Object.values(filters).some(Boolean)) {
            return ''
        }

        const { search, companyId, locationId, reporters } = searchParams
        const urlParams = new URLSearchParams()

        search && urlParams.set('search', search) 
        companyId && urlParams.set('companyId', companyId) 
        locationId && urlParams.set('locationId', locationId) 
        reporters && urlParams.set('reporters', 'true') 

        return '?' + urlParams.toString()
    }, [searchParams])

    const days = getDaysFromDate(month, { isLoading: true, locale: i18n.resolvedLanguage })
    const { onItemClicked, dialog } = useLeaveDialog()

    return (
        <>
            <EmbeddedContent>
                <EmployeeGenericFilters
                    setFilters={setFilters}
                />
            </EmbeddedContent>

            <LeaveTeamGrid
                daysCount={days.length}
                className={clsxm( 'bg-grey-100')}
            >
                <LeaveTeamHeaderHeader
                    days={days}
                    header={
                        <YearSelector
                            nextLinkProps={{
                                to: `/core-hr/calendar/${nextMonth}?${parsedSearch}`,
                            }}
                            prevLinkProps={{
                                to: `/core-hr/calendar/${prevMonth}?${parsedSearch}`,
                            }}
                        >
                            {formatMonthWithYear(date)}
                        </YearSelector>
                    }
                />
                <LeaveTeamPaginated
                    searchParams={searchParams}
                    daysLoading={days}
                    onItemClicked={(day, employee) => onItemClicked(employee.id, day.leave)}
                />
            </LeaveTeamGrid>

            {dialog}
        </>
    )
}

export default LeaveTeamDetail
