import {
    IsNotEmpty,
    IsOptional,
    IsString,
} from '@viterbit/web-app/shared/utils/ClassValidators'

export class EmployeePersonalInformationValidator {
  @IsNotEmpty()
  @IsString()
  name: string

  @IsString()
  @IsOptional()
  surname?: string

  @IsString()
  @IsOptional()
  pronouns?: string

  @IsString()
  @IsOptional()
  gender?: string

  @IsString()
  @IsOptional()
  nationality?: string

  @IsString()
  @IsOptional()
  phone?: string

  @IsOptional()
  birthday: string | null
}
