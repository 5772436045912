/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react'

const useCallbackRef = (cb: (arg: any) => void) => {
    const cbRef = React.useRef(cb)

    React.useEffect(() => {
        cbRef.current = cb
    })

    return React.useMemo(() => (args: any) => cbRef.current?.(args), [])
}

export default useCallbackRef
