import YearSelector from '@viterbit/web-app/shared/form/YearSelector'
import HeaderActions from '@viterbit/web-app/shared/layout/HeaderActions'
import HeaderActionsActions from '@viterbit/web-app/shared/layout/HeaderActions/HeaderActionsActions'
import HeaderActionsHeader from '@viterbit/web-app/shared/layout/HeaderActions/HeaderActionsHeader'
import HeaderActionsTitle from '@viterbit/web-app/shared/layout/HeaderActions/HeaderActionsTitle'
import * as React from 'react'

type HeaderProps = {
    year: number
    nextYearHref: string
    previousYearHref: string
    title: string
}

const CalendarHeader = ({
    title,
    year,
    nextYearHref,
    previousYearHref,
}: HeaderProps) => (
    <HeaderActions>
        <HeaderActionsHeader>
            <HeaderActionsTitle>{title}</HeaderActionsTitle>
        </HeaderActionsHeader>
        <HeaderActionsActions>
            <YearSelector
                className='flex justify-end '
                nextLinkProps={{ to: nextYearHref }}
                prevLinkProps={{ to: previousYearHref }}
            >
                {year}
            </YearSelector>

        </HeaderActionsActions>
    </HeaderActions>

)

export default CalendarHeader
