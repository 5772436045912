/* eslint-disable @typescript-eslint/no-non-null-assertion */
import SectionLayout from '@viterbit/web-app/shared/layout/SectionLayout'
import React from 'react'

import SettingsLayoutBody, { SettingsLayoutBodyProps } from './SettingsLayoutBody'

type SettingsLayoutProps = {
    title?: string
    actions?: React.ReactNode
} & SettingsLayoutBodyProps

const SettingsLayout = ({ title, actions, ...rest }: SettingsLayoutProps) => {
    return (
        <SectionLayout
            title={title}
            actions={actions}
        >
            <SettingsLayoutBody {...rest} />
        </SectionLayout>
    )
}

export default SettingsLayout
