/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FindWorkScheduleQueryResponse } from './FindWorkScheduleQueryResponse';
import {
    FindWorkScheduleQueryResponseFromJSON,
    FindWorkScheduleQueryResponseFromJSONTyped,
    FindWorkScheduleQueryResponseToJSON,
} from './FindWorkScheduleQueryResponse';

/**
 * 
 * @export
 * @interface WorkScheduleRecord
 */
export interface WorkScheduleRecord {
    /**
     * 
     * @type {string}
     * @memberof WorkScheduleRecord
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof WorkScheduleRecord
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof WorkScheduleRecord
     */
    type: WorkScheduleRecordTypeEnum;
    /**
     * 
     * @type {FindWorkScheduleQueryResponse}
     * @memberof WorkScheduleRecord
     */
    workSchedule?: FindWorkScheduleQueryResponse;
}


/**
 * @export
 */
export const WorkScheduleRecordTypeEnum = {
    Contract: 'CONTRACT',
    WorkSchedule: 'WORK_SCHEDULE'
} as const;
export type WorkScheduleRecordTypeEnum = typeof WorkScheduleRecordTypeEnum[keyof typeof WorkScheduleRecordTypeEnum];


/**
 * Check if a given object implements the WorkScheduleRecord interface.
 */
export function instanceOfWorkScheduleRecord(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "startDate" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function WorkScheduleRecordFromJSON(json: any): WorkScheduleRecord {
    return WorkScheduleRecordFromJSONTyped(json, false);
}

export function WorkScheduleRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkScheduleRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'startDate': json['startDate'],
        'type': json['type'],
        'workSchedule': !exists(json, 'workSchedule') ? undefined : FindWorkScheduleQueryResponseFromJSON(json['workSchedule']),
    };
}

export function WorkScheduleRecordToJSON(value?: WorkScheduleRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'startDate': value.startDate,
        'type': value.type,
        'workSchedule': FindWorkScheduleQueryResponseToJSON(value.workSchedule),
    };
}

