/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CalendarDay2 } from './CalendarDay2';
import {
    CalendarDay2FromJSON,
    CalendarDay2FromJSONTyped,
    CalendarDay2ToJSON,
} from './CalendarDay2';

/**
 * 
 * @export
 * @interface CalendarDayWrapper
 */
export interface CalendarDayWrapper {
    /**
     * 
     * @type {number}
     * @memberof CalendarDayWrapper
     */
    year: number;
    /**
     * 
     * @type {Array<CalendarDay2>}
     * @memberof CalendarDayWrapper
     */
    publicHolidays?: Array<CalendarDay2> | null;
}

/**
 * Check if a given object implements the CalendarDayWrapper interface.
 */
export function instanceOfCalendarDayWrapper(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "year" in value;

    return isInstance;
}

export function CalendarDayWrapperFromJSON(json: any): CalendarDayWrapper {
    return CalendarDayWrapperFromJSONTyped(json, false);
}

export function CalendarDayWrapperFromJSONTyped(json: any, ignoreDiscriminator: boolean): CalendarDayWrapper {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'year': json['year'],
        'publicHolidays': !exists(json, 'publicHolidays') ? undefined : (json['publicHolidays'] === null ? null : (json['publicHolidays'] as Array<any>).map(CalendarDay2FromJSON)),
    };
}

export function CalendarDayWrapperToJSON(value?: CalendarDayWrapper | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'year': value.year,
        'publicHolidays': value.publicHolidays === undefined ? undefined : (value.publicHolidays === null ? null : (value.publicHolidays as Array<any>).map(CalendarDay2ToJSON)),
    };
}

