/* eslint-disable @typescript-eslint/no-explicit-any */
import { AnimatePresence, motion } from 'framer-motion'
import * as React from 'react'
import { Controller } from 'react-hook-form'

import { FormBaseProps } from './shared'
import Switcher from '../Switcher'
import { SwitcherProps } from '../Switcher/Switcher'
import { clsxm } from '../../lib/clsxm'

const classes = {
    base: 'mb-7 relative flex flex-col',
    label: 'flex items-center font-semibold',
    errorLabel: 'text-danger-500',
}

type FormSwitcherProps = {
    label?: string
    labelWidth?: number

    asterisk?: boolean
    labelClass?: string
    labelStyle?: React.CSSProperties
    htmlFor?: string
    extra?: React.ReactNode | string
    style?: any
} & FormBaseProps &
    SwitcherProps & {
        label?: string
    }

const FormSwitcher = (props: FormSwitcherProps) => {
    const {
        control,
        extra,
        asterisk,
        labelClass,
        label,
        labelWidth,
        name,
        style = {},
        htmlFor,
        className,
        onChange,
        rules,
        ...rest
    } = props

    const formItemLabelWidth = labelWidth
    const getFormLabelLayoutClass = label ? `${label && 'pl-2'}` : 'pl-2'

    const formLabelClass = clsxm(
        classes.label,
        label && getFormLabelLayoutClass,
        label && 'mb-2',
        labelClass
    )
    const formLabelStyle = { ...style, ...{ minWidth: formItemLabelWidth } }

    const enterStyle = { opacity: 1, marginTop: 3, bottom: -21 }
    const exitStyle = { opacity: 0, marginTop: -10 }
    const initialStyle = exitStyle

    const handleChange = (
        value: boolean,
        e: any,
        controllerOnChange: (_: unknown) => void
    ) => {
        controllerOnChange(value)
        onChange?.(value, e)
    }

    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            render={({ field, fieldState: { error } }) => (
                <div className={clsxm(classes.base, className)}>
                    <Switcher
                        defaultChecked={field.value}
                        onChange={(value, e) =>
                            handleChange(value, e, field.onChange)
                        }
                        {...rest}
                    />
                    <AnimatePresence mode='wait'>
                        {Boolean(error) && (
                            <motion.div
                                className='absolute z-0 overflow-hidden text-danger-500'
                                initial={initialStyle}
                                animate={enterStyle}
                                exit={exitStyle}
                                transition={{
                                    duration: 0.15,
                                    type: 'tween',
                                }}
                            >
                                {error?.message}
                            </motion.div>
                        )}
                    </AnimatePresence>
                    <label
                        htmlFor={htmlFor}
                        className={formLabelClass}
                        style={formLabelStyle}
                    >
                        {asterisk && (
                            <span className='mr-1 text-danger-500'>
                                        *
                            </span>
                        )}
                        {label}
                        {extra && <span className='ml-1'>{extra}</span>}
                    </label>

                </div>
            )}
        />
    )
}

export default FormSwitcher
