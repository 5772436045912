import dayjs from 'dayjs'

export const parseDateUTC = (rawDate) =>
    dayjs(new Date(rawDate).toUTCString()).format('YYYY-MM-DD')
    
export const parseDatesUTC = (...rawDates: string[]) => rawDates.map(parseDateUTC)

export const isFutureDate = (date: string) => new Date(date) > new Date()

export const getDatesInRange = (startDate, endDate) => {
    const dateArray = new Array<Date>()
    const currentDate = new Date(startDate)

    while (currentDate <= new Date(endDate)) {
        dateArray.push(new Date(currentDate))
        // Use UTC date to prevent problems with time zones and DST
        currentDate.setUTCDate(currentDate.getUTCDate() + 1)
    }

    return dateArray
}

export const isSameDate = (dateA: Date | string, dateB: Date | string): boolean =>
    dayjs(dateA).isSame(dayjs(dateB), 'day')
