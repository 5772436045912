import * as React from 'react'

import FileItemActions from './FileItemActions'
import FileItemThumbnail from './FileItemThumbnail'
import { IFile } from './sharedTypes'
import { FormError } from '../Form/FormErrorMessage'
import ListItem from '../List/ListItem'
import ListItemText from '../List/ListItemText'
import clsxm from '../../lib'


const BYTE = 1000
const getKB = (bytes: number) => Math.round(bytes / BYTE)

export type FileItemProps = {
    file: IFile
    onDelete?: (file: IFile) => void
    onDownload?: (file: IFile) => void
    iconClassName?: string
    onClick?: () => void
    t?: (key: string) => string
    actionsChildren?: React.ReactNode
    secondaryText?: React.ReactNode
    canDelete?: boolean
    error?: any
}

const styles = {
    root: 'py-0 pl-0 min-h-[auto] border rounded-lg overflow-hidden',
    thumbnail: 'flex items-center justify-center w-16 h-16 mr-2 border',
}

const FileItem = ({
    file,
    onDelete,
    onDownload,
    iconClassName = 'bg-primary-300',
    t,
    onClick,
    actionsChildren,
    secondaryText,
    canDelete = true,
    error: _error,
}: FileItemProps) => {
    const { name, size } = file

    const error = file._meta?.error || _error

    return (
        <ListItem className={styles.root}>
            <div className={clsxm(styles.thumbnail, iconClassName)}>
                <FileItemThumbnail file={file} />
            </div>

            <ListItemText primary={name} secondary={!error && !secondaryText && `${getKB(size)}kb`} onPrimaryTextClick={onClick}>
                {
                    error ? (
                        <FormError className='truncate'>
                            {t?.(`ui:upload.errors.server.${error}`) || error}
                        </FormError>
                    ) : (
                        secondaryText
                    )
                }
            </ListItemText>

            <FileItemActions
                file={file}
                onDelete={onDelete}
                onDownload={onDownload}
                canDelete={canDelete}
                t={t}
            >
                {actionsChildren}
            </FileItemActions>
        </ListItem>
    )
}

export default FileItem
