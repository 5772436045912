

import { Calendar } from '@viterbit/web-app/dataAccess/model/Calendar.model'
import { Location } from '@viterbit/web-app/dataAccess/model/Location.model'
import { IsArray, IsNotEmpty, IsNumber, IsOptional, IsString, ValidateIf } from '@viterbit/web-app/shared/utils/ClassValidators'

class LocationInfoFormValidator implements Pick<Location, 'name'> {
    @IsNotEmpty()
    @IsString()
        name: string
}

class LocationHolidaysFormValidator implements Pick<Location, 'calendar'> {
    @IsArray()
    // @Type(() => LocationHolidaysItemFormValidator)
    calendar: Calendar[]
}

class LocationFormValidator implements Pick<Location,  'name'| 'country'| 'state'| 'city'| 'address'| 'postalCode'> {
    @IsNotEmpty()
    @IsString()
    name: string
    @IsNotEmpty()
    country: string
    @IsNotEmpty()
    state: string
    city: string
    @IsOptional()
    address: string
    @IsOptional()
    postalCode: string
}

class DeleteLocationFormValidator {
    @IsString()
    @IsNotEmpty()
        locationId: string
}

class LocationHolidaysLoadFormValidator {
    @IsNumber()
    year: number

    @ValidateIf((o) => !o.isFromScratch)
    @IsString()
    @IsNotEmpty()
    state: string

    @ValidateIf((o) => !o.isFromScratch)
    @IsString()
    @IsNotEmpty()
    country: string

    isFromScratch?: boolean
}

const LocationFormsValidator = {
    Form: LocationFormValidator,
    Info: LocationInfoFormValidator,
    Holidays: LocationHolidaysFormValidator,
    HolidaysLoad: LocationHolidaysLoadFormValidator,
    Delete: DeleteLocationFormValidator
}

export default LocationFormsValidator
