/* eslint-disable @typescript-eslint/no-explicit-any */

import { EmployeeContractDayType } from '@viterbit/web-app/dataAccess/model/Employee.model'
import { IsNotEmpty, IsOptional } from '@viterbit/web-app/shared/utils/ClassValidators'

import { EmployeeContractWorkFields } from './EmployeeContractWorkForm'

export class EmployeeContractWorkValidator implements EmployeeContractWorkFields {
    @IsNotEmpty()
    contractHoursAmount?: any
    @IsOptional()
    contractDayType?: typeof EmployeeContractDayType[number]
}