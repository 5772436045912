/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-array-index-key */

import { FileModel } from '@viterbit/web-app/dataAccess/model/File.model'
import { fileSignedUrl } from '@viterbit/web-app/dataAccess/service/filesService'
import { useFileListPaginated } from '@viterbit/web-app/dataAccess/useCase/file/useFileListPaginated'
import i18n from '@viterbit/web-app/i18n'
import { useOnScreenElement } from '@viterbit/web-app/shared/behaviour/useOnScreenElement'
import IsGranted from '@viterbit/web-app/shared/permission/IsGranted'
import useHasPermission from '@viterbit/web-app/shared/permission/useHasPermissions'
import * as React from 'react'
import FileItem from 'ui/src/components/Upload/FileItem'
import FileItemSkeleton from 'ui/src/components/Upload/FileItemSkeleton'

import DeleteDocument from '../delete/DocumentEmployeeDeleteDialog'
import { FileTypeAction, getActionKeyByDocumentType } from '../DocumentEmloyeeShared'
import { DocumentProps } from '../DocumentEmloyeeShared'

type DocumentItemsPaginatedProps = {
    className?: string
} & DocumentProps

const DocumentEmployeeDetailItems = ({
    type,
    employeeId
}: DocumentItemsPaginatedProps) => {
    const [deleteItem, setDeleteItem] = React.useState<FileModel | null>(null)

    const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } = useFileListPaginated({
        query: { employeeId, type: type as any },
        enabled: !!type && !!employeeId
    })

    const { ref } = useOnScreenElement({
        onVisible: () => {
            if (hasNextPage) {
                fetchNextPage()
            }
        }
    })

    const lastPage = data?.pages[data.pages.length - 1] // for skeleton items
    const skeletonItems = Array.from({ length: lastPage?.meta.nextPageTotal || 0 }, (_, index) => index)
    const deleteAction = getActionKeyByDocumentType(type, FileTypeAction.DELETE)
    const { hasPermission } = useHasPermission({
        [deleteAction]: data?.pages.flatMap((page) => page.data).map((file) => file.id)
    })

    return (
        <>
            {data?.pages.flatMap((page) => (
                page.data.map((file) => (
                    <FileItem
                        canDelete={hasPermission(deleteAction, file.id)}
                        key={file.id}
                        file={file}
                        onDelete={() => {
                            setDeleteItem(file)
                        }}
                        onDownload={async (file: any) => {
                            const signedUrl = await fileSignedUrl({type: file.kind, id: file.id})
                            window.open(signedUrl.url)
                        }}
                        t={i18n.t}
                    />
                ))
            ))}

            {isFetchingNextPage && skeletonItems.map((_, index) => (<FileItemSkeleton key={index} />))}
            <div ref={ref} hidden={isFetchingNextPage} className="h-2" />
            {
                deleteItem && (
                    <IsGranted
                        id={deleteItem.id}
                        action={getActionKeyByDocumentType(deleteItem.kind, FileTypeAction.DELETE)}
                    >
                        <DeleteDocument
                            file={deleteItem}
                            open={!!deleteItem}
                            onSubmitCallback={() => setDeleteItem(null) }
                            onCancelCallback={() => setDeleteItem(null)}
                        />
                    </IsGranted>
                )}
        </>
    )
}

export default DocumentEmployeeDetailItems
