/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RedeemForgotPasswordCommand
 */
export interface RedeemForgotPasswordCommand {
    /**
     * 
     * @type {string}
     * @memberof RedeemForgotPasswordCommand
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof RedeemForgotPasswordCommand
     */
    password: string;
}

/**
 * Check if a given object implements the RedeemForgotPasswordCommand interface.
 */
export function instanceOfRedeemForgotPasswordCommand(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "token" in value;
    isInstance = isInstance && "password" in value;

    return isInstance;
}

export function RedeemForgotPasswordCommandFromJSON(json: any): RedeemForgotPasswordCommand {
    return RedeemForgotPasswordCommandFromJSONTyped(json, false);
}

export function RedeemForgotPasswordCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): RedeemForgotPasswordCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'token': json['token'],
        'password': json['password'],
    };
}

export function RedeemForgotPasswordCommandToJSON(value?: RedeemForgotPasswordCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'token': value.token,
        'password': value.password,
    };
}

