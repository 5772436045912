import i18n from '@viterbit/web-app/i18n'
import LayoutHeading from 'ui/src/components/LayoutHeading'

import ComplaintCreateForm from './ComplaintCreateForm'
import { useComplaintOrganization } from '../useComplaintOrganization'

const ComplaintCreateView =  () => {
    const { data } = useComplaintOrganization()

    return (
        <>
            <LayoutHeading
                title={data?.name}
                subtitle={i18n.t('complaint:create.subtitle')}
            />
            <ComplaintCreateForm />
        </>
    )
}

export default ComplaintCreateView
