/* eslint-disable @typescript-eslint/no-explicit-any */
import { clsxm } from '@viterbit/web-app/shared/utils/clsxm'
import * as React from 'react'

import BodyContentHeader from './BodyContentHeader'
import BodyContentMain from './BodyContentMain'
import Footer from '../Footer'

type BodyContentProps = {
    children?: React.ReactNode
    className?: string
    wrapperClassName?: string
}

const styles = {
    root: [
        'overflow-y-auto min-h-0 flex-1 w-full h-full',
    ],
    content: [
        'w-full max-w-full xl:w-[calc(90vw)] xl:w-[1600px] mx-auto',
        'px-6 pb-8'
    ],
    footer: 'lg:sticky top-full',
}

const BodyContent = (props: BodyContentProps) => {
    const { className, wrapperClassName, ...rest } = props

    return (
        <section className={clsxm(styles.root, wrapperClassName)} {...rest}>
            <div  className={clsxm(styles.content, className)}>
                {props.children}
            </div>
            <Footer className={styles.footer}/>
        </section>)
}

BodyContent.Header = BodyContentHeader
BodyContent.Main = BodyContentMain

export default BodyContent
