import Typography, { TypographySizedProps, TypographySizesStyles, TypographyStyles } from '@viterbit/web-app/shared/typography/Typography'

export const TextStyles = {
    ...TypographyStyles,
    root: `${TypographyStyles.root} ${TypographySizesStyles.text}`,
} as const

const Text = (props: TypographySizedProps) => {
    return (
        <Typography {...props} size='text' />
    )
}

export default Text
