
import { WorkSchedule } from '@viterbit/web-app/dataAccess/model/WorkSchedule.model'

import WorkScheduleDetailForm from './WorkScheduleDetailForm'
import WorkScheduleDelete from '../delete/WorkScheduleDelete'

type WorkScheduleDetailViewProps = {
    workSchedule: WorkSchedule
    showActions?: boolean
}

const WorkScheduleDetail = (props: WorkScheduleDetailViewProps) => {
    const { workSchedule, showActions } = props

    return (
        <>
            <WorkScheduleDetailForm key={workSchedule.id} workSchedule={workSchedule} showActions={showActions} />
            <hr className='mb-4'/>
            {showActions && <WorkScheduleDelete workSchedule={workSchedule} />}
        </>
    )
}

export default WorkScheduleDetail
