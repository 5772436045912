/* eslint-disable @typescript-eslint/no-explicit-any */
import { classValidatorResolver } from '@hookform/resolvers/class-validator'
import { Alert } from '@mantine/core'
import dateMapper from '@viterbit/web-app/dataAccess/mapper/date.mapper'
import { Employee } from '@viterbit/web-app/dataAccess/model/Employee.model'
import { employeeDelete } from '@viterbit/web-app/dataAccess/service/employeeService'
import i18n from '@viterbit/web-app/i18n'
import Form from '@viterbit/web-app/shared/form/Form'
import FormDatePicker from '@viterbit/web-app/shared/form/FormDatePicker'
import FormSelectEmployee from '@viterbit/web-app/shared/form/FormSelectEmployee'
import SubmitButton from '@viterbit/web-app/shared/form/SubmitButton'
import dayjs from 'dayjs'
import * as React from 'react'
import Button from 'ui/src/components/Button'
import Dialog from 'ui/src/components/Dialog'
import { FormInput } from 'ui/src/components/Form'

import { EmployeeDeleteDialogFields } from './EmployeeDeleteDialogShared'
import { EmployeeDeleteValidator } from './EmployeeDeleteValidator'

type EmployeeDeleteDialogProps = {
    isOpen: boolean
    onClose: () => void
    onSubmit?: () => void
    title?: string
    submitButtonText?: string
    cancelButtonText?: string
    children?: React.ReactNode
    employee: Employee
    onSuccess?: () => void
}

const resolver = classValidatorResolver(EmployeeDeleteValidator)

const EmployeeDeleteDialog = ({
    isOpen,
    onClose,
    cancelButtonText,
    onSubmit,
    submitButtonText,
    title,
    children,
    employee,
    onSuccess
}: EmployeeDeleteDialogProps) => {
    const defaultValues = React.useMemo<EmployeeDeleteDialogFields>(() => ({
        endDate: dayjs(employee.deleteInformation?.endDate).toDate(),
        reason: employee.deleteInformation?.reason || '',
        observations: employee.deleteInformation?.observations || '',
        newManagerId: employee.deleteInformation?.newManagerId || null,
        isManager: !!employee.reporters,
    }), [employee])

    return (
        <Dialog open={isOpen} onClose={onClose} title={title}>
            <Form<EmployeeDeleteDialogFields>
                mutationConfig={{
                    mutationFn: (data) => employeeDelete({
                        id: employee.id,
                        deleteEmployeeCommand: {
                            ...data,
                            endDate: dateMapper.toDate(data.endDate) || undefined,
                        }
                    }),
                    onSuccess: onSuccess,
                }}
                formConfig={{
                    mode: 'all',
                    resolver,
                    defaultValues
                }}
            >{(form) => (
                    <>

                        <Alert className='mb-4 whitespace-pre-line'>
                            {i18n.t('employees:employeeDeleteDialog.description')}
                        </Alert>

                        <FormDatePicker
                            asterisk
                            label={i18n.t('employees:employeeDeleteDialog.form.endDate')}
                            placeholder={i18n.t('01/01/2021')}
                            name='endDate'
                            control={form.control}
                        />

                        <FormInput
                            asterisk
                            name='reason'
                            label={i18n.t('employees:employeeDeleteDialog.form.reason')}
                            placeholder={i18n.t('employees:employeeDeleteDialog.form.reason')}
                            control={form.control}
                        />

                        <FormInput
                            textArea
                            name='observations'
                            label={i18n.t('employees:employeeDeleteDialog.form.observations')}
                            placeholder={i18n.t('employees:employeeDeleteDialog.form.observations')}
                            control={form.control}
                        />

                        {!!employee.reporters && (
                            <FormSelectEmployee
                                asterisk
                                label={i18n.t('employees:employeeDeleteDialog.form.successor')}
                                placeholder={i18n.t('employees:employeeDeleteDialog.form.successor')}
                                name='newManagerId'
                                excludeIds={[employee.id]}
                                control={form.control}
                                defaultValue={{
                                    id: employee.deleteInformation?.newManagerId,
                                    name: employee.deleteInformation?.newManagerName,
                                    surname: employee.deleteInformation?.newManagerName,
                                } as any}
                            />
                        )}
                    
                        {children}

                        <hr />

                        <Alert className='mt-4' color='warning'>
                            {i18n.t('employees:employeeDeleteDialog.alertMessage')}
                        </Alert>

                        <Button onClick={onClose} block>
                            {cancelButtonText}
                        </Button>

                        <SubmitButton
                            data-testid='employee-delete-dialog-confirm'
                            onClick={onSubmit}
                            type='submit'
                            variant='solid'
                            className='bg-danger-600 hover:bg-danger-500 active:bg-danger-700'
                        >
                            {submitButtonText}
                        </SubmitButton>
                    </>
                )}
            </Form>
        </Dialog>
    )
}

export default EmployeeDeleteDialog
