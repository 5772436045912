/* eslint-disable @typescript-eslint/no-non-null-assertion */

import cityMapper from '@viterbit/web-app/dataAccess/mapper/city.mapper'
import stateMapper from '@viterbit/web-app/dataAccess/mapper/state.mapper'
import { queryClient } from '@viterbit/web-app/shared/lib/ReactQueryProvider'
import { Configuration, GeoDataApi } from 'viterbit-api-sdk'

import { commonConfig } from './config'
import { GEO_DATA_QUERY_KEY } from '../cacheKeys'

const api = new GeoDataApi(new Configuration(commonConfig))

export const geoDataInvalidate = () =>
    queryClient.invalidateQueries([GEO_DATA_QUERY_KEY])

export const geoDataStateList = (...args: Parameters<typeof api.listStates>) =>
    stateMapper.fromResponse!(api.listStates(...args))

export const geoDataCityList = (...args: Parameters<typeof api.listCities>) =>
    cityMapper.fromResponse!(api.listCities(...args))
