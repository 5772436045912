import * as React from 'react'
import Dialog from 'ui/src/components/Dialog'

type TimeTrackingDialogProps = {
    onClose?: () => void
    open: boolean
    children: React.ReactNode
    title: string
}

const TimeTrackingDialog = (props: TimeTrackingDialogProps) => {
    const { children, open, onClose, title } = props

    return (
        <Dialog
            open={open} 
            onClose={onClose} 
            title={title}
        >
            {children}
        </Dialog>
    )
}

export default TimeTrackingDialog
