import { classValidatorResolver } from '@hookform/resolvers/class-validator'
import { accountRedeemInvite } from '@viterbit/web-app/dataAccess/service/accountService'
import { accountLogin } from '@viterbit/web-app/dataAccess/service/authService'
import Form from '@viterbit/web-app/shared/form/Form'
import SubmitButton from '@viterbit/web-app/shared/form/SubmitButton'
import useJwtToken, { JwtTokenHook } from '@viterbit/web-app/shared/utils/useJwtToken'
import { JwtPayload } from 'jwt-decode'
import { useNavigate } from 'react-router-dom'
import Alert from 'ui/src/components/Alert'
import { FormInput, FormPasswordInput } from 'ui/src/components/Form'
import FormPasswordInputValidated from 'ui/src/components/Form/FormPasswordInputValidated'
import LayoutHeading from 'ui/src/components/LayoutHeading'

import RedeemInvitationValidator from './RedeemInvitationValidator'

interface CustomJWT extends JwtPayload {
    email: string
}

interface CustomJwtTokenHook extends JwtTokenHook {
    data: CustomJWT
}

type RedeemInvitationDetailProps = {
    token: string
    initialData?: CustomJWT
}

export type InvitationFields = {
    email: string
    password: string
    confirmPassword: string
}


const resolver = classValidatorResolver(RedeemInvitationValidator)

const RedeemInvitationDetail = ({ token, initialData }: RedeemInvitationDetailProps) => {
    const { error, data, isExpired } = useJwtToken(
        token,
        initialData
    ) as CustomJwtTokenHook
    const navigate = useNavigate()

    // TODO: (improve) bad request feedback
    if (error) {
        return (
            <Alert title='Error' type='danger'>
                The request is invalid
            </Alert>
        )
    }

    // TODO: (improve) token expired feedback
    if (isExpired) {
        return (
            <Alert title='Error' type='danger'>
                The request is expired
            </Alert>
        )
    }

    return (
        <>
            <LayoutHeading
                title="Welcome 👋,"
                subtitle="Enter your credentials"
            />

            <Form<InvitationFields>
                mutationConfig={{
                    mutationKey: ['InvitationAccept'],
                    onSuccess: (response, variables) => accountLogin({
                        logInCommand: 
                        {
                            password: variables.password,
                            username: data.email,
                        }
                    }
                    ).then(() => {
                        navigate('/')
                    }),
                    mutationFn: ({password}) => 
                        accountRedeemInvite({redeemInviteCommand: {
                            password,
                            token,
                        }}),
                }}
                formConfig={{
                    resolver,
                    mode: 'all',
                    defaultValues: {
                        email: data.email,
                        password: '',
                        confirmPassword: '',
                    },
                }}
            >
                {(form) => (
                    <>
                        <FormInput
                            name='email'
                            control={form.control}
                            label='Email'
                            readOnly
                        />
                        <FormPasswordInputValidated
                            asterisk
                            name='password'
                            control={form.control}
                            label='Password'
                            placeholder='Enter your password'
                        />
                        <FormPasswordInput
                            asterisk
                            name='confirmPassword'
                            control={form.control}
                            label='Repeat Password'
                            placeholder='Repeat your password'
                        />

                        <SubmitButton>Sign in</SubmitButton>
                    </>
                )}
            </Form>
        </>

    )
}

export default RedeemInvitationDetail
