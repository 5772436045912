/* eslint-disable @typescript-eslint/no-explicit-any */

import { useContentChangedDetail } from '@viterbit/web-app/dataAccess/useCase/contentChanged/useContentChangedDetail'
import * as React from 'react'
import clsxm from 'ui/src/lib'

import NavLink, { WrappedLinkProps } from './NavLink'
import useActiveRoute, { UseActiveRouteParams } from './useActiveRoute'

type SegmentNavLinkProps = {
    children?: React.ReactNode
    className?: string
    activeClassName?: string
    Component?: React.ElementType
    force?: boolean
    matchPath?: string
    disabled?: boolean
} & WrappedLinkProps & UseActiveRouteParams

const preventClick = (event: React.MouseEvent) => event?.preventDefault()

const styles = {
    root: [
        'group',
        '[&[disabled]]:pointer-events-none [&[disabled]]:opacity-50',
    ]
}

const SegmentNavLink = ({ activeClassName = 'active', className, path, exact = false, force, matchPath = path, ...rest }: SegmentNavLinkProps) => {
    const isActive = useActiveRoute({ path: matchPath, exact })

    const onClick = force || !isActive ? null: preventClick
    const active = isActive ? activeClassName : ''
    const isInternal = path?.startsWith('#')
    const isExternal = path?.startsWith('http')

    const { data } = useContentChangedDetail()

    if (isExternal || isInternal || data.changes) {
        return (
            <a
                {...rest as any}
                href={path}
                className={clsxm(styles.root, className, active)}
                {...(isExternal && {
                    target: '_blank',
                    rel: 'noopener noreferrer'
                })}
            />
        )
    }

    return (
        <NavLink
            {...rest}
            end={exact}
            onClick={onClick as any}
            className={clsxm(styles.root, className, active)}
            path={path}
        />
    )
}
export default SegmentNavLink
