import TabContent, { TabContentProps } from './TabContent'
import TabList, { TabListProps } from './TabList'
import TabNav, { TabNavProps } from './TabNav'
import BaseTabs, { TabsProps } from './Tabs'

type TabType = React.FunctionComponent<TabsProps> & {
    TabContent: React.FunctionComponent<TabContentProps>
    TabList: React.FunctionComponent<TabListProps>
    TabNav: React.FunctionComponent<TabNavProps>
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Tabs: TabType = BaseTabs as any
Tabs.TabContent = TabContent
Tabs.TabList = TabList
Tabs.TabNav = TabNav

export default Tabs
