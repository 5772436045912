import { TypographySizedProps } from '@viterbit/web-app/shared/typography/Typography'
import * as React from 'react'

import Subtitle from '../../typography/Subtitle'
import Title from '../../typography/Title'

type HeaderActionsTitleProps = {
    children?: React.ReactNode
    className?: string
    titleStyle?: boolean
} & TypographySizedProps

const HeaderActionsTitle = ({ titleStyle, ...rest }: HeaderActionsTitleProps) => {
    const Component = titleStyle ? Title : Subtitle
    return (
        <Component data-testid="header-actions-title" truncate bold {...rest} />
    )
}


export default HeaderActionsTitle
