/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateLeavePolicyCommand,
  FindLeavePolicyQueryResponse,
  ListLeavePolicyQueryResponse,
  ResourceCreated,
  UpdateLeavePolicyCommand,
} from '../models/index';
import {
    CreateLeavePolicyCommandFromJSON,
    CreateLeavePolicyCommandToJSON,
    FindLeavePolicyQueryResponseFromJSON,
    FindLeavePolicyQueryResponseToJSON,
    ListLeavePolicyQueryResponseFromJSON,
    ListLeavePolicyQueryResponseToJSON,
    ResourceCreatedFromJSON,
    ResourceCreatedToJSON,
    UpdateLeavePolicyCommandFromJSON,
    UpdateLeavePolicyCommandToJSON,
} from '../models/index';

export interface CreateLeavesPolicyRequest {
    createLeavePolicyCommand?: CreateLeavePolicyCommand;
}

export interface DeleteLeavePoliciesRequest {
    id: string;
    body?: object;
}

export interface FindLeavePoliciesRequest {
    id: string;
    include?: Array<string>;
}

export interface ListLeavePoliciesRequest {
    page?: number;
}

export interface UpdateLeavePoliciesRequest {
    id: string;
    updateLeavePolicyCommand?: UpdateLeavePolicyCommand;
}

/**
 * 
 */
export class LeavePolicyApi extends runtime.BaseAPI {

    /**
     * Create a leave policy
     * Create a leave policy
     */
    async createLeavesPolicyRaw(requestParameters: CreateLeavesPolicyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core-hr/leave-policies`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateLeavePolicyCommandToJSON(requestParameters.createLeavePolicyCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Create a leave policy
     * Create a leave policy
     */
    async createLeavesPolicy(requestParameters: CreateLeavesPolicyRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.createLeavesPolicyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a leave policy
     * Delete a leave policy
     */
    async deleteLeavePoliciesRaw(requestParameters: DeleteLeavePoliciesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteLeavePolicies.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core-hr/leave-policies/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Delete a leave policy
     * Delete a leave policy
     */
    async deleteLeavePolicies(requestParameters: DeleteLeavePoliciesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.deleteLeavePoliciesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find a leave policy
     * Find a leave policy
     */
    async findLeavePoliciesRaw(requestParameters: FindLeavePoliciesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindLeavePolicyQueryResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling findLeavePolicies.');
        }

        const queryParameters: any = {};

        if (requestParameters.include) {
            queryParameters['include'] = requestParameters.include;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core-hr/leave-policies/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindLeavePolicyQueryResponseFromJSON(jsonValue));
    }

    /**
     * Find a leave policy
     * Find a leave policy
     */
    async findLeavePolicies(requestParameters: FindLeavePoliciesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindLeavePolicyQueryResponse> {
        const response = await this.findLeavePoliciesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch all leave policies for the given company
     * List leave policies
     */
    async listLeavePoliciesRaw(requestParameters: ListLeavePoliciesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListLeavePolicyQueryResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core-hr/leave-policies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListLeavePolicyQueryResponseFromJSON(jsonValue));
    }

    /**
     * Fetch all leave policies for the given company
     * List leave policies
     */
    async listLeavePolicies(requestParameters: ListLeavePoliciesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListLeavePolicyQueryResponse> {
        const response = await this.listLeavePoliciesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a leave policies
     * Update a leave policies
     */
    async updateLeavePoliciesRaw(requestParameters: UpdateLeavePoliciesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceCreated>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateLeavePolicies.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/core-hr/leave-policies/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateLeavePolicyCommandToJSON(requestParameters.updateLeavePolicyCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceCreatedFromJSON(jsonValue));
    }

    /**
     * Update a leave policies
     * Update a leave policies
     */
    async updateLeavePolicies(requestParameters: UpdateLeavePoliciesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceCreated> {
        const response = await this.updateLeavePoliciesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
