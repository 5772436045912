/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateAccountCommand
 */
export interface UpdateAccountCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountCommand
     */
    locale?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountCommand
     */
    timezone?: string | null;
}

/**
 * Check if a given object implements the UpdateAccountCommand interface.
 */
export function instanceOfUpdateAccountCommand(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateAccountCommandFromJSON(json: any): UpdateAccountCommand {
    return UpdateAccountCommandFromJSONTyped(json, false);
}

export function UpdateAccountCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateAccountCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'locale': !exists(json, 'locale') ? undefined : json['locale'],
        'timezone': !exists(json, 'timezone') ? undefined : json['timezone'],
    };
}

export function UpdateAccountCommandToJSON(value?: UpdateAccountCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'locale': value.locale,
        'timezone': value.timezone,
    };
}

