import { Location } from '@viterbit/web-app/dataAccess/model/Location.model'
import i18n from '@viterbit/web-app/i18n'
import * as React from 'react'

import CreateHolidayButton from './CreateHolidayButton'

type HolidaysListProps = {
    children?: React.ReactNode
    className?: string
    location: Location
    onClick?: React.MouseEventHandler<HTMLDivElement>
}

const currentYear = new Date().getFullYear()

const HolidaysList = ({
    location,
    children,
    className,
    onClick,
    
}: HolidaysListProps) => {

    const disabledYears = location.calendar.map(calendar => calendar.year)
    const hideCreateButton = disabledYears.includes(currentYear) && disabledYears.includes(currentYear + 1)
    return (
        <nav className={`${className} `} aria-label='Breadcrumb'>
            <div className='flex flex-col gap-4'>{children}</div>

            {!hideCreateButton && (
                <CreateHolidayButton onClick={onClick}>{i18n.t('settingLocations:configureYourHolidays')}</CreateHolidayButton>
            )}
        </nav>
    )
}


export default HolidaysList