/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    Burger,
    Center,
    createStyles,
    Divider,
    Drawer,
    Group,
    Popover,
    rem,
    ScrollArea,
    SimpleGrid,
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import {
    IconChevronDown,
} from '@tabler/icons-react'
import { User } from '@viterbit/web-app/dataAccess/model/User.model'
import { NavigationItem as INavigationItem } from '@viterbit/web-app/routes'
import SegmentNavLink from '@viterbit/web-app/shared/navigation/SegmentNavLink'
import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'

import { useRouteFilter } from './useRouteEnabled'
import { routeGroup } from './useRouteGroup'
import Logo from '../display/Logo'
import SimpleCard from '../display/SimpleCard'
import Small from '../typography/Small'
import Text, { TextStyles } from '../typography/Text'

const useStyles = createStyles((theme) => ({

    subLink: {
        width: '100%',
        padding: `${theme.spacing.xs} ${theme.spacing.md}`,
        borderRadius: theme.radius.md,

        ...theme.fn.hover({
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
        }),

        '&:active': theme.activeStyles,
    },

    dropdownFooter: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
        margin: `calc(${theme.spacing.md} * -1)`,
        marginTop: theme.spacing.sm,
        padding: `${theme.spacing.md} calc(${theme.spacing.md} * 2)`,
        paddingBottom: theme.spacing.xl,
        borderTop: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1]
        }`,
    },

    hiddenMobile: {
        [theme.fn.smallerThan('sm')]: {
            display: 'none',
        },
    },

    hiddenDesktop: {
        [theme.fn.largerThan('sm')]: {
            display: 'none',
        },
    },
}))

export type NavigationGroup = {
    [key: string]: INavigationItem[]
}

export const HeaderMegaMenuStyles = {
    mainLinks: `flex items-center h-full px-4 ${TextStyles.root} [&.active]:opacity-100 [&.active]:text-primary-600`,
    subMenuButton: 'px-6 py-4 hover:bg-grey-50 flex items-start gap-2 [&.active]:bg-grey-50',
    subMenuDisabled: 'opacity-50 cursor-not-allowed pointer-events-none',
    subMenuGroupLabel: 'flex items-center px-6 py-0 [&>*]:w-4 gap-2',
} as const

export const HeaderMegaMenuLink = ({
    item, 
    children,
    path = '#',
    className = '',
    onClick,
    ...rest
}: {
    item?: INavigationItem,
    user: User,
    children: React.ReactNode,
    path?: string,
    className?: string,
    onClick?: () => void,
} & React.HTMLAttributes<HTMLAnchorElement>) => (
    <SegmentNavLink
        {...rest}
        path={path}
        title={item?.handle.title}
        key={item?.handle.key}
        force={item?.handle.exact}
        exact={item?.handle.exact}
        onClick={onClick}
        className={`${HeaderMegaMenuStyles.mainLinks} ${item?.handle.disabled && HeaderMegaMenuStyles.subMenuDisabled} ${className} hover:opacity-100`}
    >
        {children}
    </SegmentNavLink>
)

type HeaderMegaMenuGroupItem = {
    key: string,
    item?: INavigationItem,
    icon: React.ReactNode,
    title: string,
    description: string,
    href?: string,
    onClick?: () => void,
} & React.HTMLAttributes<HTMLAnchorElement>

export const HeaderMegaMenuGroup = ({
    icon,
    label,
    items = [],
    user,
    cols = 2,
    ...rest
}: {
    icon: React.ReactNode,
    label: string,
    items?: HeaderMegaMenuGroupItem[],
    user: User,
    cols?: number,
} & React.HTMLAttributes<HTMLDivElement> ) => (
    <div className='mb-4' {...rest}>
        <Text opacity className={HeaderMegaMenuStyles.subMenuGroupLabel}>
            {icon}
            {label}
        </Text>

        <SimpleGrid cols={cols} spacing={0} sx={{ position: 'relative'}} p={0}>
            {items?.map((navigationItem) => {
                const { item, key, href, onClick, icon, title, description, ...rest } = navigationItem
                return (
                    <HeaderMegaMenuLink
                        item={item}
                        key={key}
                        path={href}
                        className={`${HeaderMegaMenuStyles.subMenuButton}`}
                        user={user}
                        onClick={onClick}
                        {...rest}
                    >
                        <div className='p-1 border rounded-md text-primary-600'>
                            {icon}
                        </div>
                        <div>
                            <Text bold className='group-hover:text-primary-600 group-[.active]:text-primary-600'>
                                {title}
                            </Text>
                            <Small opacity className='group-[.active]:text-black'>
                                {description}
                            </Small>
                        </div>
                    </HeaderMegaMenuLink>
                )}
            )}
        </SimpleGrid>
    </div>
)

export const HeaderMegaMenuItemDesktop = ({ item, user }: {item: INavigationItem, user: User}) => {
    const { classes, theme } = useStyles()

    const filterRoute = useRouteFilter()

    const [opened, setOpened] = useState(false)
    const {pathname} = useLocation()
    React.useEffect(() => {
        setOpened(false)
    }, [ pathname ])
    
    if (item.handle.showDropdown) {
        const dropdownGroups = routeGroup(item, false, filterRoute)
        return (
            <>
                <Popover
                    opened={opened}
                    onChange={setOpened}
                    key={item.handle.key}
                    width={600}
                    position="bottom"
                    radius="md"
                    shadow="md"
                    withinPortal
                >
                    <Popover.Target>
                        <Center inline sx={{ height: '100%' }}>
                            <SegmentNavLink
                                path="#"
                                onClick={(e) => {
                                    e.preventDefault()
                                    setOpened(!opened)
                                }}
                                title={item.handle.title}
                                key={item.handle.key}
                                className={`${TextStyles.bold} ${HeaderMegaMenuStyles.mainLinks}  opacity-60`}
                                force={item.handle.exact}
                                exact={item.handle.exact}
                                matchPath={item.handle.href}
                            >
                                <span className='mr-1'>
                                    {item.handle.title}
                                </span>
                                <IconChevronDown size={16} />
                            </SegmentNavLink>
                        </Center>
                    </Popover.Target>

                    <Popover.Dropdown sx={{ overflow: 'hidden' }} pt={16} pb={0} px={0}>
                        {dropdownGroups.map((group, index) => (
                            <HeaderMegaMenuGroup
                                key={group.key || index}
                                icon={group.icon}
                                label={group.label}
                                items={group.items.filter(child => child.handle.showInMenu).map((child) => ({
                                    key: child.handle.key,
                                    item: child,
                                    icon: child.handle.icon,
                                    title: child.handle.title!,
                                    description: child.handle.description!,
                                    href: child.handle.href,
                                }))}
                                user={user}
                            />
                        ))}
                    </Popover.Dropdown>
                </Popover>

            </>
        )
    }

    return (
        <HeaderMegaMenuLink
            item={item}
            user={user}
            path={item.handle.href}
            className={`${TextStyles.bold} ${item.handle.renderIcon && 'p-0 pl-4'} opacity-60 ${item.handle.className}`}
        >
            {item.handle.renderIcon ? item.handle.icon : item.handle.title}
        </HeaderMegaMenuLink>
    )
}


export const HeaderMegaMenuItemMobile = ({ item, user }: {item: INavigationItem, user: User}) => {
    const { classes, theme } = useStyles()

    const filterRoute = useRouteFilter()

    const dropdownGroups = routeGroup(item, false, filterRoute)

    if (item.handle.position === 'bottom' && (!item.handle.dropDownGroups || !item.children)) {
        return (
            <>
                {item.handle.icon}
            </>
        )
    }
    
    return (
        <>
            {dropdownGroups.map((group, index) => (
                <HeaderMegaMenuGroup
                    cols={1}
                    key={group.key}
                    icon={group.icon}
                    label={group.label}
                    items={group.items.filter(filterRoute).map((child) => ({
                        key: child.handle.key,
                        item: child,
                        icon: child.handle.icon,
                        title: child.handle.title!,
                        description: child.handle.description!,
                        href: child.handle.href,
                    }))}
                    user={user}
                />
            ))}
        </>
    )
}


export const HeaderMegaMenu = ({ group, user }: {group: NavigationGroup, user: User}) => {
    const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure(false)
    const { classes, theme } = useStyles()

    const filterRoute = useRouteFilter()

    const {pathname} = useLocation()
    React.useEffect(() => closeDrawer(), [ pathname ])

    return (
        <SimpleCard>
            <div className='h-20 bg-white'>
                <div className='w-full max-w-full xl:w-[1600px] mx-auto flex items-center justify-between h-full px-6'>

                    <Logo />

                    <Group sx={{ height: '100%', flex: '1' }} spacing={0} className={classes.hiddenMobile}>
                        {group.top.filter(filterRoute).map((item) => (
                            <HeaderMegaMenuItemDesktop key={item.handle.key} item={item} user={user} />
                        ))}
                    </Group>

                    <Group sx={{ height: '100%' }} spacing={0} className={classes.hiddenMobile}>
                        {group.bottom.filter(filterRoute).map((item) => (
                            <HeaderMegaMenuItemDesktop key={item.handle.key} item={item} user={user} />
                        ))}
                    </Group>

                    <Burger opened={drawerOpened} onClick={toggleDrawer} className={classes.hiddenDesktop} />
                </div>
            </div>

            <Drawer
                opened={drawerOpened}
                onClose={closeDrawer}
                size="100%"
                padding="md"
                title="Navigation"
                className={classes.hiddenDesktop}
                zIndex={1000000}
            >
                <ScrollArea h={`calc(100vh - ${rem(60)})`} mx="-md">

                    {group.top.filter(filterRoute).map((item) => (
                        <HeaderMegaMenuItemMobile key={item.handle.key} item={item} user={user} />
                    ))}

                    {group.bottom.filter(filterRoute).map((item) => (
                        <HeaderMegaMenuItemMobile key={item.handle.key} item={item} user={user} />
                    ))}

                    <Divider my="sm" color={theme.colorScheme === 'dark' ? 'dark.5' : 'gray.1'} />
                </ScrollArea>
            </Drawer>
        </SimpleCard>
    )
}