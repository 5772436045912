/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Employee } from '@viterbit/web-app/dataAccess/model/Employee.model'
import { useAccountResendInvitation } from '@viterbit/web-app/dataAccess/useCase/account/useAccountResendInvitation'
import i18n from '@viterbit/web-app/i18n'
import SubmitButton from '@viterbit/web-app/shared/form/SubmitButton'
import MultiColumnLayout from '@viterbit/web-app/shared/layout/MultiColumnLayout'
import * as React from 'react'
import Alert from 'ui/src/components/Alert'
import clsxm from 'ui/src/lib'

type EmployeeProfileResendInvitationProps = {
    children?: React.ReactNode
    className?: string
    employee: Employee
}

const EmployeeProfileResendInvitation = (props: EmployeeProfileResendInvitationProps) => {
    const { employee, className } = props
    const {isLoading, mutate, isSuccess} = useAccountResendInvitation({})

    if (employee.isDeleted || !employee.hasAccount || employee.account?.active) {
        return null
    }

    return (
        <>
            <MultiColumnLayout
                left={true}
            >
                <Alert
                    type='info'
                    className={clsxm('mb-4', className)}
                    showIcon
                    variant='outline'
                    endContent={
                        <SubmitButton
                            variant='solid'
                            onClick={() => !isSuccess && mutate({resendInvitationCommand: {
                                accountId: employee.account!.id
                            }})}
                            loading={isLoading}
                            success={isSuccess}
                            disabled={isLoading}
                            data-testid='resend-invitation-button'
                            className='bg-primary-600 hover:bg-primary-500 active:bg-primary-700'
                        >
                            {i18n.t('employees:employee.profile.resendInvitation.button')}
                        </SubmitButton>
                    }
                >
                    {i18n.t('employees:employee.profile.resendInvitation.alertText')}
                </Alert>
            </MultiColumnLayout>
        </>
    )
}

export default EmployeeProfileResendInvitation
