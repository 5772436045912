import { EmployeeEmergencyInformation } from '@viterbit/web-app/dataAccess/model/Employee.model'
import { IsEmail, ValidateIf } from '@viterbit/web-app/shared/utils/ClassValidators'

class EmployeePersonalEmergencyValidator implements EmployeeEmergencyInformation {
    name: string
    @ValidateIf((o) => o.email)
    @IsEmail()
    email: string
    phoneNumber: string
}
export default EmployeePersonalEmergencyValidator
