import { useLocationListPaginated } from '@viterbit/web-app/dataAccess/useCase/location/useLocationListPaginated'
import { useOnScreenElement } from '@viterbit/web-app/shared/behaviour/useOnScreenElement'
import BreakpointRedirection from '@viterbit/web-app/shared/navigation/BreakpointRedirection'
import * as React from 'react'
import List from 'ui/src/components/List'

import LocationsItem from './LocationsItem'

const LocationsList = () => {
    const { ref } = useOnScreenElement({
        onVisible: () => {
            hasNextPage && fetchNextPage()
        }
    })
    
    const {
        data,
        fetchNextPage,
        hasNextPage,
    } = useLocationListPaginated()

    const firstLocation = React.useMemo(() => data?.pages[0]?.data[0], [data])

    return (
        <>
            {firstLocation && <BreakpointRedirection matcherPath="/settings/locations" redirectPath={`/settings/locations/${firstLocation?.id}/details`} />}

            <List className='flex flex-col divide-y'>
                {data?.pages?.map(({ data: _data }) =>
                    _data.map((location) => (
                        <LocationsItem
                            location={location}
                            key={location.id}
                        />
                    ))
                )}
            </List>

            <div ref={ref} />
        </>
    )
}

export default LocationsList
