/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Subscription, SubscriptionFrequencies } from '@viterbit/web-app/dataAccess/model/Subscription.model'
import i18n from '@viterbit/web-app/i18n'
import FormButtonGroup from '@viterbit/web-app/shared/form/FormButtonGroup'
import * as React from 'react'

import PriceItem from './PriceItem'


type PriceListProps = {
    children?: React.ReactNode
    className?: string
    prices: Subscription['prices']
    onItemClick: (price: Subscription['prices'][number]) => void
    defaultValue: SubscriptionFrequencies
}

const styles = {
    root: ''
}

const PriceList = (props: PriceListProps) => {
    const { prices, onItemClick, defaultValue } = props

    const findTab = React.useCallback((periodUnit: SubscriptionFrequencies) => 
        prices.find(price => price.periodUnit === periodUnit)!
    , [prices])

    const setTab = React.useCallback((periodUnit: SubscriptionFrequencies) => {
        setActiveTab(findTab(periodUnit))
    }, [prices])

    const [activeTab, setActiveTab] = React.useState<Subscription['prices'][number]>(() => findTab(defaultValue))


    return (
        <div className='flex flex-col items-center gap-4'>
            <FormButtonGroup defaultValue={activeTab.periodUnit} onTabChange={setTab} >
                {prices.map((price) => (
                    <FormButtonGroup.Item key={price.periodUnit} value={price.periodUnit}>
                        {i18n.t(`billing:pricing.periodUnit.${price.periodUnit}`)}
                    </FormButtonGroup.Item>
                ))}
            </FormButtonGroup>

            <PriceItem className='w-full' price={activeTab} onClick={() => onItemClick(activeTab)} />
        </div>
    )
}

export default PriceList
