/* tslint:disable */
/* eslint-disable */
/**
 * Viterbit API
 * This is the documentation for viterbit\'s apps
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DataFindLeaveReasonQueryResponse } from './DataFindLeaveReasonQueryResponse';
import {
    DataFindLeaveReasonQueryResponseFromJSON,
    DataFindLeaveReasonQueryResponseFromJSONTyped,
    DataFindLeaveReasonQueryResponseToJSON,
} from './DataFindLeaveReasonQueryResponse';

/**
 * 
 * @export
 * @interface FindLeavePolicyQueryResponse
 */
export interface FindLeavePolicyQueryResponse {
    /**
     * 
     * @type {string}
     * @memberof FindLeavePolicyQueryResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof FindLeavePolicyQueryResponse
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof FindLeavePolicyQueryResponse
     */
    businessDays?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FindLeavePolicyQueryResponse
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FindLeavePolicyQueryResponse
     */
    _default?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FindLeavePolicyQueryResponse
     */
    dayRange?: Array<FindLeavePolicyQueryResponseDayRangeEnum>;
    /**
     * 
     * @type {number}
     * @memberof FindLeavePolicyQueryResponse
     */
    daysPerYear?: number | null;
    /**
     * 
     * @type {DataFindLeaveReasonQueryResponse}
     * @memberof FindLeavePolicyQueryResponse
     */
    leaveReasons?: DataFindLeaveReasonQueryResponse;
}


/**
 * @export
 */
export const FindLeavePolicyQueryResponseDayRangeEnum = {
    Monday: 'MONDAY',
    Tuesday: 'TUESDAY',
    Wednesday: 'WEDNESDAY',
    Thursday: 'THURSDAY',
    Friday: 'FRIDAY',
    Saturday: 'SATURDAY',
    Sunday: 'SUNDAY'
} as const;
export type FindLeavePolicyQueryResponseDayRangeEnum = typeof FindLeavePolicyQueryResponseDayRangeEnum[keyof typeof FindLeavePolicyQueryResponseDayRangeEnum];


/**
 * Check if a given object implements the FindLeavePolicyQueryResponse interface.
 */
export function instanceOfFindLeavePolicyQueryResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function FindLeavePolicyQueryResponseFromJSON(json: any): FindLeavePolicyQueryResponse {
    return FindLeavePolicyQueryResponseFromJSONTyped(json, false);
}

export function FindLeavePolicyQueryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindLeavePolicyQueryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'businessDays': !exists(json, 'businessDays') ? undefined : json['businessDays'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        '_default': !exists(json, 'default') ? undefined : json['default'],
        'dayRange': !exists(json, 'dayRange') ? undefined : json['dayRange'],
        'daysPerYear': !exists(json, 'daysPerYear') ? undefined : json['daysPerYear'],
        'leaveReasons': !exists(json, 'leaveReasons') ? undefined : DataFindLeaveReasonQueryResponseFromJSON(json['leaveReasons']),
    };
}

export function FindLeavePolicyQueryResponseToJSON(value?: FindLeavePolicyQueryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'businessDays': value.businessDays,
        'description': value.description,
        'default': value._default,
        'dayRange': value.dayRange,
        'daysPerYear': value.daysPerYear,
        'leaveReasons': DataFindLeaveReasonQueryResponseToJSON(value.leaveReasons),
    };
}

