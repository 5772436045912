import { WorkSchedule } from '@viterbit/web-app/dataAccess/model/WorkSchedule.model'
import sumTimeRanges from '@viterbit/web-app/shared/utils/sumTimeRanges'
import { useFormContext } from 'react-hook-form'

const sumScheduleDetailPeriodTotal = (periods, isFlexible = false) => {
    const total = Object.keys(periods).reduce((output, day) => {
        const ranges = isFlexible ? periods[day].map((period) => ({
            from: period.fixed !== '00:00' && '00:00',
            to: period.fixed
        })) : periods[day]
        const { total } = sumTimeRanges(ranges, 'from', 'to', 'HH:mm')
        output += total
        return output
    }, 0)

    return total / 1000 / 60 / 60
}

const useScheduleDetailPeriodTotal = (name) => {
    const context = useFormContext()
    const values = context.watch(name)
    const type: WorkSchedule['type'] = context.watch('type')
    const total = sumScheduleDetailPeriodTotal(values, type === 'FLEXIBLE')
    const value = parseFloat(total.toFixed(2))
    const hours = Math.floor(value)
    const minutes = Math.floor(((value - hours) % 100) * 60)

    return {
        hours: hours ? `${hours}h` : '0h',
        minutes: minutes ? `${minutes}m` : '',
    }
}

export default useScheduleDetailPeriodTotal