import * as React from 'react'

import { BaseProps } from './util'
import { clsxm } from '../../lib/clsxm'

const classes = {
    tbody: 'bg-white dark:bg-gray-800',
    variants: {
        default: 'divide-y divide-gray-200 dark:divide-gray-700',
        flex: 'divide-y divide-gray-200 dark:divide-gray-700',
    },
    compact: '',
    borderlessRow: 'divide-none',
}

export type TBodyProps = BaseProps &
    React.HTMLAttributes<HTMLTableSectionElement> & {
        borderlessRow?: boolean
        compact?: boolean
    }

const TBody = React.forwardRef<HTMLTableSectionElement, TBodyProps>(
    (
        {
            className,
            variant = 'default',
            children,
            borderlessRow,
            compact,
            ...props
        }: TBodyProps,
        ref
    ) => (
        <tbody
            ref={ref}
            className={clsxm(
                classes.tbody,
                classes.variants[variant],
                borderlessRow && classes.borderlessRow,
                compact && classes.compact,
                className
            )}
            {...props}
        >
            {children}
        </tbody>
    )
)

export default TBody
