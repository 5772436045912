import * as React from 'react'

import { clsxm } from '../../lib/clsxm'

const classes = {
    root: 'relative flex items-center gap-2 font-semibold',
    input: 'focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300',
    circle: 'rounded-full',
    square: 'rounded',
}

export type CheckboxProps = JSX.IntrinsicElements['input'] & {
    id?: string
    text?: string | React.ReactNode
    isRadio?: boolean
    className?: string
}

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
    (props, ref) => {
        const { text, isRadio = false, className, name, id, ...rest } = props

        const customId = id || `checkbox-${name}`

        return (
            <label htmlFor={customId} className={clsxm(classes.root, className)}>
                <input
                    {...rest}
                    id={customId}
                    name={name}
                    ref={ref}
                    type={`${isRadio ? 'radio' : 'checkbox'}`}
                    className={clsxm(
                        classes.input,
                        isRadio ? classes.circle : classes.square,
                    )}
                />
                {text && <div>{text}</div>}
            </label>
        )
    }
)

export default Checkbox
