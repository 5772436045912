
import { useCompanyDelete } from '@viterbit/web-app/dataAccess/useCase/company/useCompanyDelete'
import i18n from '@viterbit/web-app/i18n'
import ApiErrorMessage from '@viterbit/web-app/shared/feedback/ApiErrorMessage'
import DeleteAlert from '@viterbit/web-app/shared/feedback/DeleteAlert'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import { CompanyFormFields } from '../detail/CompanyForm'
import FormDeleteDialog from '../../../../shared/form/FormDeleteDialog'

type DeleteCompanyProps = {
    defaultValues: CompanyFormFields
    id: string
}

const DeleteCompany = ({ defaultValues, id }: DeleteCompanyProps) => {
    const [open, setOpen] = React.useState(false)
    const navigate = useNavigate()

    const { isLoading, mutate, error } = useCompanyDelete({
        onSuccess: () => {
            navigate('/settings/companies')
            setOpen(false)
        }
    })

    return (
        <>
            <DeleteAlert
                border
                buttonText={i18n.t('settingCompanies:delete.alertButton')}
                onConfirm={() => setOpen(true)}
            >
                {i18n.t('settingCompanies:delete.alertMessage')}
            </DeleteAlert>

            <FormDeleteDialog
                isOpen={open}
                onClose={() => setOpen(false)}
                isLoading={isLoading}
                onSubmit={() => mutate({ id: id })}
                title={i18n.t('settingCompanies:delete.dialogTitle')}
            >
                <ApiErrorMessage error={error} />
            </FormDeleteDialog>
        </>
    )
}

export default DeleteCompany
