import * as React from 'react'

type Props = {
    activeKey?: string
    onSelect?: (eventKey: string, e: React.MouseEvent) => void
}

const MenuContext = React.createContext<Props | null>(null)

export const MenuContextProvider = MenuContext.Provider

export default MenuContext
