import { useQuery } from '@tanstack/react-query'

import { INTEGRATION_QUERY_KEY } from '../../cacheKeys'
import { integrationOptionListPaginated } from '../../service/integrationService'

export type UseIntegrationOptionListPaginatedConfig = {
    query: Parameters<typeof integrationOptionListPaginated>[0]
    enabled?: boolean
    keepPreviousData?: boolean
}

export const useIntegrationOptionListPaginated = (config?: UseIntegrationOptionListPaginatedConfig) => {
    const { query, enabled, keepPreviousData } = config || {}

    return useQuery({
        queryKey: [INTEGRATION_QUERY_KEY, 'option', 'list-paginated', query],
        queryFn: () => integrationOptionListPaginated(query),
        enabled,
        keepPreviousData,
    })
}

