/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { PublicEmployee } from 'viterbit-api-sdk'

import accountMapper from './account.mapper'
import companyMapper from './company.mapper'
import fileMapper from './file.mapper'
import jobPositionMapper from './jobPosition.mapper'
import jobPositionLevelMapper from './jobPositionLevel.mapper'
import LeaveMapper from './leave.mapper'
import leaveAdjustmentMapper from './leaveAdjustment.mapper'
import leavePolicyMapper from './leavePolicy.mapper'
import locationMapper from './location.mapper'
import planningMapper from './planning.mapper'
import { EmployeeResponse } from '../dto'
import { Employee, Manager } from '../model/Employee.model'
import stringToColor from '../../shared/utils/stringToColor'

export const getHeadLine = (employeeInfo: EmployeeResponse) => {
    if (employeeInfo?.jobPositionAlias) return employeeInfo?.jobPositionAlias
    if (employeeInfo?.jobPosition?.name && employeeInfo?.jobPositionLevel?.name)
        return `${employeeInfo.jobPosition.name} ${employeeInfo.jobPositionLevel.name}`
    return employeeInfo?.company?.name || employeeInfo.email
}

export const getStatus = (employeeInfo: EmployeeResponse): Employee['status'] => {
    if (!employeeInfo?.hasAccount) return 'inactive'
    if (employeeInfo?.isActive) return 'active'
    return 'pending'
}

const employeeMapper = {
    toModel: (dto: EmployeeResponse): Employee => ({
        id: dto.id,
        email: dto.email,
        name: dto.name,
        hasAccount: dto.hasAccount,
        surname: dto.surname ?? undefined,
        pronouns: dto.personalInformation?.pronouns ?? undefined,
        gender: dto.personalInformation?.gender ?? undefined,
        nationality: dto.personalInformation?.nationality ?? undefined,
        phone: dto.personalInformation?.phone ?? undefined,
        birthday: dto.personalInformation?.birthday ?? undefined,
        reporters: dto.personalInformation?.reporters ?? undefined,
        address: dto.address?.address ?? undefined,
        city: dto.address?.city ?? undefined,
        country: dto.address?.country ?? undefined,
        postalCode: dto.address?.postalCode ?? undefined,
        state: dto.address?.state ?? undefined,
        salary: dto.contractInformation?.salary ?? undefined,
        startDate: dto.contractInformation?.startDate,
        endDate: dto.contractInformation?.endDate ?? undefined,
        location: dto.location && locationMapper.toModel(dto.location),
        manager: dto.manager && employeeMapper.managerToModel(dto.manager),
        leavePolicy:
            dto.leavePolicy && leavePolicyMapper.toModel(dto.leavePolicy),
        company: dto.company && companyMapper.toModel(dto.company),
        account: dto.account && accountMapper.toModel(dto.account),
        leaves:
            dto.leaves &&
            dto.leaves.data?.map((leave) => LeaveMapper.toModel(leave)),
        leaveAdjustments: (dto.leaveAdjustments || []).map(
            leaveAdjustmentMapper.toModel
        ),
        bankName: dto.bankInformation?.bankName || undefined,
        bankSwift: dto.bankInformation?.bankSwift || undefined,
        bankNumber: dto.bankInformation?.bankNumber || undefined,
        jobPosition:
            dto.jobPosition && jobPositionMapper.toModel(dto.jobPosition),
        jobPositionLevel:
            dto.jobPositionLevel &&
            jobPositionLevelMapper.toModel(dto.jobPositionLevel),
        jobPositionAlias: dto.jobPositionAlias ?? undefined,
        fiscalInformation: dto.fiscalInformation || {},
        avatar: dto.avatar && fileMapper.toModel(dto.avatar),
        idFile: dto.idFile && fileMapper.toModel(dto.idFile),
        color: stringToColor(dto.name),
        deleteInformation: dto.deleteInformation as any,
        emergencyInformation: {
            name: dto.emergencyInformation?.name || '',
            email: dto.emergencyInformation?.email || '',
            phoneNumber: dto.emergencyInformation?.phoneNumber || '',
        },
        isDeleted: dto.isDeleted,
        contractHoursPeriod: dto.contractInformation?.contractHours,
        contractHoursAmount: dto.contractInformation?.hours || undefined,
        contractModality: dto.contractInformation?.workingModality,
        contractDayType: dto.contractInformation?.workingDayType || undefined,
        contractDays: dto.contractInformation?.schedule || [],
        planning: planningMapper.toModel(dto.workSchedules),
        estimation: {
            estimation: dto.estimation?.estimation || 0,
            worked: dto.estimation?.worked || 0,
            balance: dto.estimation?.balance || 0,
        },
        headline: getHeadLine(dto),
        isActive: dto.isActive,
        status: getStatus(dto),
    }),

    managerToModel: (dto: PublicEmployee): Manager => ({
        id: dto.id,
        email: dto.email,
        name: dto.name,
        surname: dto.surname ?? undefined,
        avatar: dto.avatar && fileMapper.toModel(dto.avatar),
    })
}

export default employeeMapper
