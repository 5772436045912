import dayjs from 'dayjs'
import * as React from 'react'

import Header from './Header'
import { formatYear, isMonthInRange, MAX_YEAR, MIN_YEAR } from '../utils'
import { clsxm } from '../../../lib/clsxm'

const getMonthsNames = (locale: 'en' | 'es', format = 'MMM'): string[] => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const names = new Array<string>()
    const date = new Date(2022, 0, 1)

    for (let i = 0; i < 12; i += 1) {
        names.push(dayjs(date).locale('es').format(format))
        date.setMonth(date.getMonth() + 1)
    }

    return names
}

const classes = {
    'month-cell': 'text-center py-2 rounded-lg font-semibold',
    'month-cell-active': 'month-cell-active bg-primary-600 text-white',
    'month-cell-disabled': 'opacity-30 bg-gray-300 cursor-not-allowed',
    month: 'w-full',
    'month-table': 'grid grid-cols-3 gap-2',
}

type MonthTableProps = {
    className?: string
    value: {
        month: number
        year: number
    }
    onChange: (month: number) => void
    locale?: 'en' | 'es'
    year: number
    onYearChange: (year: number) => void
    onNextLevel: (mont?: string) => void
    minDate?: Date
    maxDate?: Date
    preventFocus?: boolean
    monthLabelFormat?: string
    yearLabelFormat?: string
    nextLabel?: string
    prevLabel?: string
}

const MonthTable = ({
    className,
    value,
    onChange,
    locale = 'en',
    year,
    onYearChange,
    onNextLevel,
    minDate,
    maxDate,
    preventFocus,
    monthLabelFormat,
    yearLabelFormat,
    nextLabel = 'Next year',
    prevLabel = 'Previous year',
}: MonthTableProps) => {
    const monthNames = getMonthsNames(locale, monthLabelFormat)

    const minYear = minDate?.getFullYear() || MIN_YEAR
    const maxYear = maxDate?.getFullYear() || MAX_YEAR

    const months = monthNames.map((month, index) => {
        const isDisabled = !isMonthInRange({
            date: new Date(year, index),
            minDate,
            maxDate,
        })

        const isActive = index === value.month && year === value.year

        return (
            <button
                type='button'
                key={month}
                onClick={() => onChange(index)}
                className={clsxm(
                    classes['month-cell'],
                    isActive && !isDisabled && classes['month-cell-active'],
                    !isActive && !isDisabled && 'hover:bg-gray-100',
                    isDisabled && classes['month-cell-disabled']
                )}
                disabled={isDisabled}
                onMouseDown={(event) => preventFocus && event.preventDefault()}
            >
                {month}
            </button>
        )
    })

    return (
        <div className={clsxm(classes.month, className)}>
            <Header
                label={formatYear(year, yearLabelFormat)}
                hasNext={year < maxYear}
                hasPrevious={year > minYear}
                onNext={() => onYearChange(year + 1)}
                onPrevious={() => onYearChange(year - 1)}
                onNextLevel={onNextLevel}
                className={className}
                nextLabel={nextLabel}
                previousLabel={prevLabel}
                preventFocus={preventFocus}
            />
            <div className={classes['month-table']}>{months}</div>
        </div>
    )
}

export default MonthTable
