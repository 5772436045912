import { IsNotEmpty, IsOptional } from '@viterbit/web-app/shared/utils/ClassValidators'

export class EmployeeContractInformationValidator {
    @IsOptional()
    salary?: number

    @IsNotEmpty()
    startDate: string

    @IsOptional()
    endDate?: string
}