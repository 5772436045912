import { LeaveReason } from '@viterbit/web-app/dataAccess/model/LeaveReason.model'
import { IsBoolean, IsHexColor, IsNotEmpty, IsNumber, IsString, Max, Min, ValidateIf } from '@viterbit/web-app/shared/utils/ClassValidators'


class DetailsLeaveReasonValidator implements LeaveReason {
    id: string
    @IsBoolean()
    needApproval: boolean
    @IsBoolean()
    needDocumentation: boolean
    @IsBoolean()
    visibleOtherEmployees: boolean
    @IsBoolean()
    timeDiscount: boolean
    leavePolicyId: string
    @IsNotEmpty()
    @IsString()
    name: string
    @IsString()
    description: string
    @IsString()
    @IsHexColor()
    color: string
    @ValidateIf(o => o.isLimitedTo)
    @IsNumber()
    @Min(0)
    @Max(366)
    limitedTo: number
    @IsBoolean()
    isLimitedTo: boolean
}

export default DetailsLeaveReasonValidator
