/* eslint-disable @typescript-eslint/no-explicit-any */
import { DateInput, DateInputProps } from '@mantine/dates'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isToday from 'dayjs/plugin/isToday'
import * as React from 'react'
import { Controller } from 'react-hook-form'
import ControlledFormControl from 'ui/src/components/Form/ControlledFormControl'
import { FormControlBaseProps } from 'ui/src/components/Form/FormControl'
import { FormBaseProps } from 'ui/src/components/Form/shared'

import { DATE_FORMAT } from '../utils/dateFormat'



dayjs.extend(isToday)
dayjs.extend(customParseFormat)

type FormDatePickerProps = FormBaseProps &
    DateInputProps &
    FormBaseProps &
    FormControlBaseProps

const getCurrentDay = (date: any): React.CSSProperties => {
    if (dayjs(date).isToday()) {
        return {
            borderWidth: 1,
            borderColor: '#0C80F5',
            borderStyle: 'solid',
        }
    }

    return {}
}


const renderDay = (date) => {
    const day = date.getDate()

    return (
        <span data-testid={`form-datepicker-day-${day}`}>{day}</span>
    )
}

const FormDatePicker = ({
    name,
    control,
    asterisk,
    label,
    extra,
    className,
    rules,
    valueFormat = DATE_FORMAT.DATE,
    ...rest
}: FormDatePickerProps) => (
    <Controller
        control={control}
        name={name}
        key={name}
        rules={rules}
        render={({ field }) => (
            <ControlledFormControl
                control={control}
                label={label}
                asterisk={asterisk}
                extra={extra}
                className={className}
                {...field}
            >
                <DateInput
                    valueFormat={valueFormat}
                    data-testid={`form-datepicker-${name}`}
                    renderDay={renderDay}
                    dateParser={(input) => {
                        const parsed = dayjs(input, valueFormat)
                        return parsed.isValid() ? parsed.toDate() : null
                    }}
                    {...rest}
                    {...field}
                />
            </ControlledFormControl>
        )}
    />
)

export default FormDatePicker
