import { IconExclamationCircle } from '@tabler/icons-react'
import * as React from 'react'
import StatusIcon from 'ui/src/components/StatusIcon'

const FormInfo = ({ info }: { info: string }) => (
    <div className='mb-3 text-center rounded-md bg-primary-100 '>
        <div className='flex items-center px-3 py-3 gap-x-4'>
            <StatusIcon
                iconColor='text-info-400 dark:text-info-100 py-2'
                icon={<IconExclamationCircle className='text-primary-600' />}
                type='info'
            />
            <div className='font-semibold ltr:ml-4 rtl:mr-4 grow'>{info}</div>
        </div>
    </div>
)

export default FormInfo
