/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useWorkScheduleUnassign } from '@viterbit/web-app/dataAccess/useCase/workSchedule/useWorkScheduleUnassign'
import i18n from '@viterbit/web-app/i18n'
import { useCurrentEmployeeId } from '@viterbit/web-app/shared/employee/useCurrentEmployeeId'
import ApiErrorMessage from '@viterbit/web-app/shared/feedback/ApiErrorMessage'
import FormDeleteDialog from '@viterbit/web-app/shared/form/FormDeleteDialog'
import * as React from 'react'

export type EmployeePlanningDeleteProps = {
    isOpen: boolean
    onClose: () => void
    recordId: string | null
}

const EmployeePlanningDelete = (props: EmployeePlanningDeleteProps) => {
    const { isOpen, onClose, recordId } = props

    const employeeId = useCurrentEmployeeId()!

    const { isLoading, mutate, error } = useWorkScheduleUnassign({
        onSuccess: () => onClose()
    })

    return (
        <FormDeleteDialog
            isOpen={isOpen}
            isLoading={isLoading}
            onClose={onClose}
            onSubmit={() => {
                mutate({
                    unassignWorkScheduleCommand: {
                        employeeId,
                        workScheduleRecordId: recordId!
                    }
                })
            }}
            title={i18n.t('settingWorkSchedule:delete.alertButtonText')}
        >
            <ApiErrorMessage error={error} />
        </FormDeleteDialog>

    )
}

export default EmployeePlanningDelete
