import * as React from 'react'

import { clsxm } from '../../lib/clsxm'

type ButtonProps = {
    pagerClass?: Record<'default' | 'inactive' | 'active' | 'disabled', string>
    currentPage: number
    pageCount?: number
    onClick?: React.MouseEventHandler<HTMLSpanElement>
    defaultIcon?: React.ReactNode
    customIcon?: React.ReactNode
    type: 'next' | 'prev'
    buttonClass?: string
}

const Button = (props: ButtonProps) => {
    const {
        currentPage,
        pageCount,
        pagerClass,
        onClick,
        customIcon,
        type,
        buttonClass,
        defaultIcon,
    } = props

    const disabled = {
        next: !pageCount || currentPage === pageCount,
        prev: !pageCount || currentPage <= 1,
    }

    const handleOnClick: React.MouseEventHandler<HTMLSpanElement> = (e) => {
        e.preventDefault()
        if (disabled[type]) return
        onClick?.(e)
    }

    const pagerButtonClass = clsxm(
        pagerClass?.default,
        buttonClass,
        disabled[type] ? pagerClass?.disabled : pagerClass?.inactive
    )

    return (
        <span
            data-testid={`pagination-${type}`}
            className={pagerButtonClass}
            onClick={handleOnClick}
        >
            {customIcon ?? defaultIcon}
        </span>
    )
}

export default Button
