import Small from '@viterbit/web-app/shared/typography/Small'
import Text from '@viterbit/web-app/shared/typography/Text'
import * as React from 'react'
import { clsxm } from 'ui/src/lib/clsxm'

type SummaryDetailsItemProps = {
    children?: React.ReactNode
    className?: string
    used?: number
    total: number
    label: string
}

const SummaryDetailsItem = ({
    label,
    used,
    total,
    children,
    className,
}: SummaryDetailsItemProps) => (
    <div className={clsxm('flex flex-col mb-2', className)}>
        <div className='flex items-baseline grow'>
            <Text className='flex-1 mr-10'>
                {label}
            </Text>

            <Text className=''>
                {used}
                {`${used !== undefined ? '/' : ''}`}
            </Text>
            <Small opacity className=''>{total}</Small>
        </div>

        <Small>{children}</Small>
    </div>
)

export default SummaryDetailsItem
